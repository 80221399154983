<div class="tab-inner-content">
  <h2>Account Type Summary</h2>
  <h5>
    From {{ startDate | date : "MM/d/YYYY" }} to
    {{ endDate | date : "MM/d/YYYY" }}
  </h5>
  <table>
    <tr>
      <th>Account Type</th>
      <th>Debit</th>
      <th>Credit</th>
    </tr>
    <ng-container
      *ngIf="
        accountTypeSummaryReport?.accountTypeSummary?.length > 0;
        else noData
      "
    >
      <ng-container
        *ngFor="let group of accountTypeSummaryReport?.accountTypeSummary"
      >
        <!-- Display group header -->
        <tr class="title">
          <td>{{ group.groupNameFormatted }}</td>
          <td></td>
          <td></td>
        </tr>
        <!-- Loop through accountsList inside each group -->
        <ng-container *ngFor="let item of group.accountsList">
          <tr class="child">
            <td>{{ item.accountTypeFormatted }}</td>
            <td>{{ item.debitFormatted }}</td>
            <td>{{ item.creditFormatted }}</td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
    <!-- Display this if accountTypeSummaryReport.accountTypeSummary is empty -->
    <ng-template #noData>
      <tr>
        <td colspan="3" class="no-data">
          No data available during the selected date range
        </td>
      </tr>
    </ng-template>
  </table>
</div>
