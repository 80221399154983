import { Apollo, gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Observable, from, map } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  private uri = environment.url.hrmsModuleUrl;

  constructor(private apollo: Apollo) {}

  uploadDocument(file: File, type: string): Observable<any> {
    const token = localStorage.getItem('AUTH_TOKEN'); 
    const formData = new FormData();
    formData.append('operations', JSON.stringify({
      query: `
        mutation uploadFile($file: Upload!, $type: String!) {
          uploadFile(file: $file, type: $type) {
            url
          }
        }
      `,
      variables: { file: null, type }
    }));
  
    formData.append('map', JSON.stringify({ '0': ['variables.file'] }));
    formData.append('0', file);
  
    return from(
      fetch(environment.url.hrmsModuleUrl, {
        method: 'POST',
        body: formData, 
        headers: {
          'authorization': token ? `Bearer ${token}` : '', 
          'x-apollo-operation-name': 'uploadFile'
        }
      })
      .then(response => response.json()) 
      .then(result => result.data?.uploadFile)
    );
  }
}
