import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CustomerService } from '../../../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-statements',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule
  ],
  templateUrl: './statements.component.html',
  styleUrl: '../../client-details.component.scss'
})
export class StatementsComponent {
  partnerDetails: any;
  customerId: any;
  statements: any;
  startDate: any;
  endDate: any;

  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.customerId = params.get('id');
      if (this.customerId) {
        this.partnerDetailById(Number(this.customerId));
      }
    });
    const currentDate = new Date();
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    this.startDate = this.datePipe.transform(this.formatDate(firstDay), 'dd/MM/YYYY');
    this.endDate = this.datePipe.transform(this.formatDate(lastDay), 'dd/MM/YYYY');
  }

  formatDate(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }


  partnerDetailById(id: any) {
    this.customerService.partnerDetails(id).subscribe({
      next: (data: any) => {
        this.partnerDetails = data;
        console.log(this.partnerDetails);

      },
      error: (error) => {
        this.toastr.error(error?.message);
      },
    });
  }
}
