import {
    Component,
    Inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    inject
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { EmployeeService } from '../../../../../../features/employees/services/employee.service';
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EmployeeCareerTypeEnum } from '../../../../../../../assets/employeeCareerType';
import { ConfirmDialog } from '../../../../../../shared/components/confirm-dialog/confirm-dialog';
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'app-career-history',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './career-history.html',
    styleUrl: '../../../../employee-details.component.scss'
})
export class CareerHistoryComponent implements OnChanges {
    @Input() employeeData: any;
    profileId: number | undefined;
    public warnings: any[] = [];
    public promotions: any[] = [];
    readonly dialog = inject(MatDialog);
    private isDialogOpen = false;

    constructor(private employeeService: EmployeeService, private toastr: ToastrService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['employeeData'] && this.employeeData) {
            console.log('employeeData', this.employeeData);
            this.profileId = this.employeeData.id;
            if (this.profileId && !this.isDialogOpen) {
                this.fetchEmployeeCareerById(this.profileId);
            }
        }
    }

    fetchEmployeeCareerById(profileId: number) {
        this.employeeService.getEmployeeById(profileId).subscribe({
            next: (profile) => {
                console.log('career data:', profile);
                if (profile && profile.employeeCareerData) {
                    this.promotions = profile.employeeCareerData.filter((data: any) => data.type === 'Promotion').map((data: any) => ({
                        id: data?.id,
                        currentPosition: data?.currentPosition,
                        previousPosition: data?.previousPosition,
                        date: data?.date
                    }));

                    this.warnings = profile.employeeCareerData.filter((data: any) => data.type === 'Warning').map((data: any) => ({
                        id: data?.id,
                        date: data?.date,
                        reason: data?.reason
                    }));
                } else {
                    this.promotions = [];
                    this.warnings = [];
                    console.warn('No career data found for the profile');
                }
            },
            error: (err) => {
                console.error('Error fetching employee data:', err);
            }
        });
    }

    onDeleteCareer(careerId: number): void {
        const dialogRef = this.dialog.open(ConfirmDialog);

        dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
                this.deleteEmployeeCareer(careerId);
            }
        });
    }

    private deleteEmployeeCareer(careerId: number): void {
        const employeeId = this.profileId;
        this.employeeService.removeEmployeeCareer(careerId).subscribe({
            next: () => {
                this.toastr.success('Data deleted successfully!');
                if (employeeId !== undefined && employeeId !== null) {
                    this.fetchEmployeeCareerById(employeeId);
                }
            },
            error: () => {
                this.toastr.error('Failed to delete Data. Please try again.');
            }
        });
    }

    openEditDialog(careerId: number) {
        if (this.isDialogOpen)
            return;



        this.isDialogOpen = true;

        const employeeId = this.profileId;
        this.employeeService.getEmployeeCareerById(careerId).subscribe({
            next: (careerData) => {
                const dialogRef = this.dialog.open(AddCareerDialog, {
                    disableClose: true,
                    data: {
                        careerData,
                        employeeId
                    }
                });

                dialogRef.afterClosed().subscribe((result) => {
                    this.isDialogOpen = false; // Reset flag on close
                    if (result === 'success' && employeeId !== undefined) {
                        this.fetchEmployeeCareerById(employeeId);
                    }
                });
            }
        });
    }

    openAddCareerDialog() {
        if (this.isDialogOpen)
            return;


        this.isDialogOpen = true;
        const employeeId = this.profileId;
        const employeeDesignation = this.employeeData.designation;
        const dialogRef = this.dialog.open(AddCareerDialog, {
            disableClose: true,
            data: {
                employeeId: this.profileId,
                employeeDesignation: employeeDesignation
            }
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.isDialogOpen = false;
            if (result === 'success' && this.profileId !== undefined) {
                this.fetchEmployeeCareerById(this.profileId);
            }
        });
    }
}

@Component({
    selector: 'add-history-dialog',
    templateUrl: 'add-career-dialog.html',
    styleUrls: ['../../../../employee-details.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
    ]
})
export class AddCareerDialog implements OnInit {
    public employeeCareerTypes: {
        label: string;
        value: string
    }[] = [];
    public careerForm !: FormGroup;
    careerData: any;
    loading: boolean = false;
    careerId: number;
    employeeId: number;
    today: Date = new Date();
    designation: any;
    public designationData: any[] = [];
    constructor(private employeeService: EmployeeService, private toastr: ToastrService, private fb: FormBuilder, private dialogRef: MatDialogRef<AddCareerDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private datePipe: DatePipe) { }

    ngOnInit(): void {
        console.log('Dialog Input Data:', this.data);
        this.initForm();
        this.loadDesignationData();

        if (this.data) {
            this.careerData = this.data.careerData
            this.careerId = this.data.careerData?.id
            this.employeeId = this.data.employeeId
            this.designation = this.data.employeeDesignation
        }

        this.loadEmployeeCareerTypes();
        this.initForm();
        this.careerForm.get('type')?.valueChanges.subscribe(value => {
            this.toggleFieldValidators(value);
        });
    }

    // Disable all dates after today
    dateFilter = (d: Date | null): boolean => {
        const currentDate = d || new Date();
        return currentDate <= this.today;
    };

    private initForm() {
        this.careerForm = this.fb.group({
            type: [
                {
                    value: this.careerData?.type || '',
                    disabled: !!this.careerData
                },
                Validators.required
            ],
            currentPosition: [
                this.careerData?.currentPosition || '',
                Validators.required
            ],
            previousPosition: [
                {
                    value: this.careerData?.previousPosition || this.designation?.designationName || '',
                    disabled: false
                },
                Validators.required
            ],
            date: [
                this.careerData?.date ? new Date(Number(this.careerData.date)) : null,
                Validators.required
            ],
            
            
            reason: [this.careerData?.reason || '']
        });
    }

    // private toggleFieldValidators(type: string) {
    //     this.careerForm.patchValue({ currentPosition: '', previousPosition: '', reason: '', date: null });

    //     this.careerForm.get('currentPosition')?.clearValidators();
    //     this.careerForm.get('previousPosition')?.clearValidators();
    //     this.careerForm.get('date')?.clearValidators();
    //     this.careerForm.get('reason')?.clearValidators();

    //     if (type === 'Promotion') {
    //         this.careerForm.patchValue({
    //             previousPosition: this.designation?.designationName
    //         });
    //         this.careerForm.get('currentPosition')?.setValidators(Validators.required);
    //         this.careerForm.get('previousPosition')?.setValidators(Validators.required);
    //         this.careerForm.get('date')?.setValidators(Validators.required);

    //     } else if (type === 'Warning') {
    //         this.careerForm.get('reason')?.setValidators(Validators.required);
    //         this.careerForm.get('date')?.setValidators(Validators.required);
    //     }

    //     this.careerForm.get('currentPosition')?.updateValueAndValidity();
    //     this.careerForm.get('previousPosition')?.updateValueAndValidity();
    //     this.careerForm.get('date')?.updateValueAndValidity();
    //     this.careerForm.get('reason')?.updateValueAndValidity();
    // }

    private toggleFieldValidators(type: string) {
        // Clear all validators
        this.careerForm.get('currentPosition')?.clearValidators();
        this.careerForm.get('previousPosition')?.clearValidators();
        this.careerForm.get('date')?.clearValidators();
        this.careerForm.get('reason')?.clearValidators();

        if (type === 'Promotion') {
            this.careerForm.patchValue({
                previousPosition: this.designation?.designationName
            });
            this.careerForm.get('currentPosition')?.setValidators(Validators.required);
            this.careerForm.get('previousPosition')?.setValidators(Validators.required);
            this.careerForm.get('date')?.setValidators(Validators.required);
        } else if (type === 'Warning') {
            // Do not reset fields to null; retain existing values
            this.careerForm.get('reason')?.setValidators(Validators.required);
            this.careerForm.get('date')?.setValidators(Validators.required);
        }

        // Update validations
        this.careerForm.get('currentPosition')?.updateValueAndValidity();
        this.careerForm.get('previousPosition')?.updateValueAndValidity();
        this.careerForm.get('date')?.updateValueAndValidity();
        this.careerForm.get('reason')?.updateValueAndValidity();
    }



    onSubmit() {
        if (this.careerForm.invalid) {
            console.log("ERRORS-----",this.careerForm.errors);
            this.careerForm.markAllAsTouched();
            this.toastr.warning('Please fill in all required fields.');
            return;
        } else {
            this.loading = true;
            console.log("EMPLOYEE ID:", this.employeeId)

            // Format the date field to 'YYYY-MM-DD'
            const formattedDate = this.datePipe.transform(this.careerForm.value.date, 'yyyy-MM-dd');
            const formData = {
                ...this.careerForm.value,
                date: formattedDate, // Replace the date value with the formatted date
                employeeId: this.employeeId
            };

            const createEmployeeCareerInput = {
                ...formData,
                employeeId: this.employeeId
            };
            const id = this.careerId;
            console.log('career id', id);

            const updateEmployeeCareerInput = {
                id: id,
                ...formData,
                employeeId: this.employeeId
            }
            console.log("create form---", createEmployeeCareerInput)
            console.log("update form----", updateEmployeeCareerInput)
            if (!this.careerData) {
                this.employeeService.createEmployeeCareer(createEmployeeCareerInput).subscribe((response) => {
                    this.toastr.success('Employee career created successfully.');
                    this.dialogRef.close('success');
                    this.loading = false;
                }, (error) => {
                    let errorMessage = 'Failed to create employee career. Please try again.';
                    if (error.error && error.error.message) {
                        errorMessage = error.error.message;
                    } else if (error.message) {
                        errorMessage = error.message;
                    }
                    this.toastr.error(errorMessage);
                    this.loading = false;
                });
            } else {
                this.employeeService.updateEmployeeCareer(updateEmployeeCareerInput).subscribe((response) => {
                    this.toastr.success('Employee career updated successfully.');
                    console.log('Closing dialog after update');
                    this.dialogRef.close('success');
                    this.loading = false;
                }, (error) => {
                    this.loading = false;
                    let errorMessage = 'Failed to update employee career. Please try again.';
                    if (error.error && error.error.message) {
                        errorMessage = error.error.message;
                    } else if (error.message) {
                        errorMessage = error.message;
                    }
                    this.toastr.error(errorMessage);
                    this.loading = false;
                });
            }
        }


    }

    private loadEmployeeCareerTypes() {
        this.employeeCareerTypes = Object.values(EmployeeCareerTypeEnum).map((type) => ({ label: type.label, value: type.value }));
    }
    private loadDesignationData() {
        this.employeeService.getDesignations().subscribe({
            next: (designations) => {
                this.designationData = designations;
            },
            error: (error) => console.error(error)
        });
    }
}
