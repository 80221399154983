<section class="cmn-innerpage-wrapper roles-management">
    <div class="head">
        <div class="search-wrapper">
            <input type="text" class="cmn-header-search" placeholder="Search" />
        </div>
    </div>
    <div class="inner-content">
        <div class="permission-group" *ngFor="let module of modules">
            <h2>{{module?.parentModuleData?.moduleName}}</h2>
            <!-- <label class="switch" *ngIf="!module?.parentModuleData?.childModules">
                <input type="checkbox">
                <span class="slider round"></span>
            </label> -->
            <div class="permission-box" *ngIf="module?.parentModuleData?.childModules">
                <h3>{{module?.parentModuleData?.childModules?.moduleName}}</h3>
                <label class="switch">
                    <input type="checkbox" [checked]="isToggleOn" (change)="toggleAllPermissions($event)"
                        [disabled]="true">
                    <span class="slider round"></span>
                </label>
                <div class="check-roles-inner-wrapper">
                    <button (click)="toggleDropdown($event)" class="permission-view-btn">
                        <span>View,</span>
                        <span>Edit,</span>
                        <span>Create,</span>
                        <span>Delete,</span>
                        <span>Review,</span>
                    </button>
                    <div [ngClass]="{ show: isDropdownOpen }" class="roles-checkpopup filter-position" #dropdown>
                        <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                        <form>
                            <div class="filter-inner-content">
                                <h2>Permissions</h2>
                                <div class="check-wrapper">
                                    <div class="check-box">
                                        <label class="checkbox-wrapper">
                                            <input type="checkbox" />
                                            View
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkbox-wrapper">
                                            <input type="checkbox" />
                                            View
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="check-box">
                                        <label class="checkbox-wrapper">
                                            <input type="checkbox" />
                                            Edit
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="check-box">
                                        <label class="checkbox-wrapper">
                                            <input type="checkbox" />
                                            Create
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="check-box">
                                        <label class="checkbox-wrapper">
                                            <input type="checkbox" />
                                            Delete
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="check-box">
                                        <label class="checkbox-wrapper">
                                            <input type="checkbox" />
                                            Review
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="check-box">
                                        <label class="checkbox-wrapper">
                                            <input type="checkbox" />
                                            View
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="check-box">
                                        <label class="checkbox-wrapper">
                                            <input type="checkbox" />
                                            View
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="check-box">
                                        <label class="checkbox-wrapper">
                                            <input type="checkbox" />
                                            View
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="check-box">
                                        <label class="checkbox-wrapper">
                                            <input type="checkbox" />
                                            View
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="check-box">
                                        <label class="checkbox-wrapper">
                                            <input type="checkbox" />
                                            View
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="btn-wrapper">
                                    <button class="cmn-apply-btn" type="submit">Apply</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="permission-box" *ngIf="!module?.parentModuleData?.childModules">
                <h3>{{module?.parentModuleData?.moduleName}}</h3>
                <label class="switch">
                    <input type="checkbox" [checked]="isToggleOn" (change)="toggleAllPermissions($event)"
                        [disabled]="true">
                    <span class="slider round"></span>
                </label>
                <div class="check-roles-inner-wrapper">
                    <button (click)="toggleDropdown($event, module?.id)" class="permission-view-btn">
                        <span *ngIf="permissionNames.length > 0">
                            <span *ngFor="let permission of permissionNames; let i = index">
                                {{permission}}<span *ngIf="i < permissionNames.length - 1">,</span>
                            </span>
                        </span>
                        <span *ngIf="permissionNames.length === 0">Select permission</span>
                    </button>
                    <div [ngClass]="{ show: isDropdownOpen }" class="roles-checkpopup filter-position" #dropdown>
                        <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                        <!-- <form> -->
                        <div class="filter-inner-content">
                            <h2>Permissions</h2>
                            <div class="check-wrapper" >
                                <div class="check-box" *ngFor="let permission of permissions">
                                    <label class="checkbox-wrapper">
                                        <input type="checkbox" [checked]="permission.checked"
                                            (change)="togglePermission(permission.id, $event)" />
                                        {{permission?.permissionName}}
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            
                        </div>
                        <div class="btn-wrapper">
                            <button class="cmn-apply-btn" (click)="onApply()">Apply</button>
                        </div>
                        <!-- </form> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>