import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { ChartAccountsService } from '../../features/chart-of-accounts/services/chart-accounts.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ChartAccountDialog } from '../chart-accounts/chart-accounts.component';
import { ToastrService } from 'ngx-toastr';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';

@Component({
  selector: 'app-chart-accounts-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    RouterModule,
    PermissionDirective
  ],
  templateUrl: './chart-accounts-details.component.html',
  styleUrl: './chart-accounts-details.component.scss'
})
export class ChartAccountsDetailsComponent {
  selectedTabIndex = 0;
  accountType: any;
  search: any;
  chartAccounts: any;
  parentAccountId: any;
  isSubAccount: any;
  accountId: any;
  accountData: any;
  readonly dialog = inject(MatDialog);
  Permission = Permission;

  selectTab(index: number) {
    this.selectedTabIndex = index;
  }

  constructor(
    private chartAccountService: ChartAccountsService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
  ){}

  ngOnInit(){
    this.fetchAccounts();
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.accountId = Number(id);
      this.onTabChange(this.accountId);
      this.fetchAccountById(this.accountId); 
    }
  }
  
  fetchAccounts() {
    this.chartAccountService.chartOfAccounts(this.accountType, this.search, this.parentAccountId, this.isSubAccount).subscribe({
      next: (accounts: any) => {
        this.chartAccounts = accounts;
      },
      error: (error) => { 
        console.error(error, 'Error');
      }
    })
  }

  fetchAccountById(id: any) {
    this.chartAccountService.chartOfAccountsById(id).subscribe({
      next: (data) => {
        this.accountData = data;
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  onTabChange(index: number): void {
    const accountId = this.chartAccounts[index]?.id;
    if (accountId) {
      this.router.navigate(['/chart-of-accounts/chart-of-accounts-details', accountId]);
      this.fetchAccountById(accountId);
    }
  }

  onEdit() {
    const dialogRef = this.dialog.open(ChartAccountDialog, {
      disableClose: true,
      data: this.accountId,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.success('Account updated successfully', 'Success');
      }
    });
  }

}
