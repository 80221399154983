import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private http: HttpClient) {}

  getImageAsBase64(imageUrl: string): Promise<string> {
    // Set up the headers with the token
    const token = localStorage.getItem('AUTH_TOKEN');
    const headers = new HttpHeaders().set('authorization', `Bearer ${token}`);

    return this.http
      .get(imageUrl, { headers, responseType: 'blob' })
      .toPromise()
      .then((blob: any) => this.convertBlobToBase64(blob));
  }

  private convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result as string;
        resolve(base64data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
}
