import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { LIST_BILL, LIST_BILLS } from '../graphql/queries/bills.query';
import { ADD_BILL_ITEM, CREATE_BILL, CREATE_DOCUMENT, REMOVE_BILL, REMOVE_BILL_ATTACHMENT, REMOVE_BILL_ITEM, UPDATE_BILL } from '../graphql/mutation/bills.mutation';

@Injectable({
  providedIn: 'root',
})
export class BillService {
  constructor(private apollo: Apollo) { }

  fetchBills(search: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: LIST_BILLS,
        variables:
        {
          search
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.bills || [])
      );
  }

  fetchBillById(id: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: LIST_BILL,
        variables:
        {
          id
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.bill || [])
      );
  }

  createBill(
    createBillInput: any,
    employeeId: any,
    billItems: any,
    billAttachments: any
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_BILL,
      variables: {
        createBillInput,
        employeeId,
        billItems,
        billAttachments,
      },
    }).pipe(
      map((result: any) => result.data.createBill || [])
    );
  }

  createBillItem(
    createBillItemInput: any
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: ADD_BILL_ITEM,
      variables: {
        createBillItemInput
      },
    }).pipe(
      map((result: any) => result.data.createBillItem || [])
    );
  }

  updateBill(
    updateBillInput: any,
    employeeId: any,
    billItems: any,
    billAttachments: any
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: UPDATE_BILL,
      variables: {
        updateBillInput,
        employeeId,
        billItems,
        billAttachments,
      },
    }).pipe(
      map((result: any) => result.data.updateBill || [])
    );
  }

  removeBill(id: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_BILL,
      variables: { id },
    });
  }

  removeBillItem(id: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_BILL_ITEM,
      variables: { id },
    });
  }

  removeBillAttachment(id: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_BILL_ATTACHMENT,
      variables: { id },
    });
  }

  createBillAttachment(createBillAttachmentInput: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_DOCUMENT,
      variables: { createBillAttachmentInput },
    }).pipe(
      map((result: any) => result.data.createBillAttachment || [])
    );
  }
}