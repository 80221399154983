<div class="tab-inner-content">
  <h2>Aging Report for Invoice</h2>
  <table>
    <thead>
      <tr>
        <!-- <th>Invoice ID</th> -->
        <th>Invoice No</th>
        <th>Customer Name</th>
        <th>Amount</th>
        <th>Due Date</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="agingInvoiceReport && agingInvoiceReport.length > 0">
        <ng-container *ngFor="let item of agingInvoiceReport">
          <tr class="child">
            <!-- <td>{{ item.invoiceId }}</td> -->
            <td>{{ item.invoiceNo }}</td>
            <td>{{ item.customerName }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.dueDate | date : "mediumDate" }}</td>
            <td>{{ item.status }}</td>
          </tr>
        </ng-container>
      </ng-container>
      <!-- <p *ngIf="!agingInvoiceReport || agingInvoiceReport.length === 0">No data available</p> -->
      
    </tbody>
  </table>
</div>