import { Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { ReportService } from '../../features/reports/services/reports.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  BalanceSheetComponent,
  ProfitLossComponent,
  CashFlowStatementComponent,
  SalesByItemComponent,
  SalesByCustomerComponent,
  AccountTypeSummary,
  JournalReport,
  GeneralLedgerReport,
  CustomerBalanceSummaryReport,
  PaymentReceivedReport,
  VendorBalanceSummary,
  PettycashReportComponent,
  PaymentMadeReport,
  TrialBalanceComponent,
  AgingInvoiceVendorReport,
  AgingInvoiceReport
} from './components/reports-format.component';
import { ToastrService } from 'ngx-toastr';
import { FileExportService } from '../../shared/services/file-export.service';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';
import { AGING_DAYS_VALUE } from '../../helpers/helper-file';

@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatTabsModule,
    MatSelectModule,
    ProfitLossComponent,
    BalanceSheetComponent,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    CashFlowStatementComponent,
    SalesByItemComponent,
    SalesByCustomerComponent,
    AccountTypeSummary,
    JournalReport,
    GeneralLedgerReport,
    CustomerBalanceSummaryReport,
    PaymentReceivedReport,
    PaymentMadeReport,
    VendorBalanceSummary,
    TrialBalanceComponent,
    PettycashReportComponent,
    AgingInvoiceVendorReport,
    AgingInvoiceReport,
    PermissionDirective
  ],
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.scss',
})

export class ReportsComponent implements OnInit {
  isExportOpen = false;
  selectedOption = 'today';
  selectedTabIndex = 0;
  dateRangeForm: FormGroup;
  daysRangeForm: FormGroup;
  filter: any;
  daysFilter: string = 'ALL';
  startDate: any;
  endDate: any;
  exportFormat: string = 'csv';
  Permission = Permission;
  profitLossData: any = {};
  cashFlowReport: any = {};
  balanceSheetReport: any = {};
  salesByItemReport: any = {};
  salesByCustomReport: any = {};
  accountTypeSummaryReport: any = {};
  journalReport: any = {};
  generalLedgerReport: any = {};
  customerBalanceSummaryReport: any = {};
  paymentReceivedReportrData: any = {};
  paymentMadeReportData: any = {};
  vendorBalanceSummaryReport: any = {};
  pettyCash: any = {};
  agingInvoiceReport: any = {};
  agingVendorInvoiceReport: any = {};
  trialBalanceReport: any;
  exportLoading: boolean = false;
  dayTypes: any = AGING_DAYS_VALUE;

  constructor(
    private reportService: ReportService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private fileExportService: FileExportService
  ) { }

  ngOnInit(): void {
    const today = new Date();

    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const formattedStartOfMonth = this.datePipe.transform(
      startOfMonth,
      'yyyy-MM-dd'
    );

    // Get the end of the current month
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const formattedEndOfMonth = this.datePipe.transform(
      endOfMonth,
      'yyyy-MM-dd'
    );

    this.startDate = formattedStartOfMonth;
    this.endDate = formattedEndOfMonth;

    this.dateRangeForm = this.fb.group({
      startDate: [new Date(this.startDate)],
      endDate: [new Date(this.endDate)],
    });

    this.daysRangeForm = new FormGroup({
      days: new FormControl(this.daysFilter),
    });

    this.loadReport(0);
  }

  onDaysFilterChange(event: any) {
    this.daysFilter = event.value;
    
    // Call corresponding API based on selected tab
    if (this.selectedTabIndex === 13) {
      this.getInvoiceAgingReport(this.daysFilter);
    } else if (this.selectedTabIndex === 14) {
      this.getVendorInvoiceAgingReport(this.daysFilter);
    }
  }

  onDateChange(event: any, type: string, selectedTab: number) {
    const formattedDate = this.datePipe.transform(event.value, 'yyyy-MM-dd');
    if (type === 'start') {
      this.startDate = formattedDate;
    } else if (type === 'end') {
      this.endDate = formattedDate;
    }
    if (this.startDate != null && this.endDate != null) {
      this.filter = {
        dateRange: { startDate: this.startDate, endDate: this.endDate },
      };
      this.loadReport(selectedTab);
    }
  }

  loadReport(index: number): void {
    switch (index) {
      case 0:
        // this.reportService.getProfitLossReport().subscribe((data) => {
        //   this.profitLossData = data;
        // });
        this.getProfitLossReport(this.startDate, this.endDate);
        break;
      case 1:
        this.getCashFlowReport(this.startDate, this.endDate);
        break;
      case 2:
        this.getBalanceSheet(this.startDate, this.endDate);
        break;
      case 3:
        this.getSalesByItemReport(this.startDate, this.endDate);
        break;
      case 4:
        this.getSalesByCustomerReport(this.startDate, this.endDate);
        break;
      case 5:
        this.getAccountTypeSummaryReport(this.startDate, this.endDate);
        break;
      case 6:
        this.getJournalReport(this.startDate, this.endDate);
        break;
      case 7:
        this.getGeneralLedger(this.startDate, this.endDate);
        break;
      case 8:
        this.getCustomerBalanceSummaryReport(this.startDate, this.endDate);
        break;
      case 9:
        this.getPaymentReceivedReport(this.startDate, this.endDate);
        break;
      case 10:
        this.getPaymentMadeReport(this.startDate, this.endDate);
        break;
      case 11:
        this.getVendorBalanceSummary(this.startDate, this.endDate);
        break;
      case 12:
        this.getPettyCash(this.startDate, this.endDate)
        break;
      case 13:
        this.getInvoiceAgingReport(this.daysFilter);
        break;
      case 14:
        this.getVendorInvoiceAgingReport(this.daysFilter);
        break;

      default:
        break;
    }
  }

  onTabChange(event: MatTabChangeEvent) {
    this.loadReport(event.index); // Load data for the selected report
  }

  exportReports() {
    // this.exportLoading = true;
    let exportObservable;
    console.log('case---', `${this.exportFormat}-${this.selectedTabIndex}`);
    switch (`${this.exportFormat}-${this.selectedTabIndex}`) {
      // case 'pdf':
      //   exportObservable =
      //     this.reportService.exportEmployeesToPDF(selectedFields);
      //   break;
      // case 'xls':
      //   exportObservable =
      //     this.reportService.exportEmployeesToXLSX(selectedFields);
      //   break;
      case 'csv-0':
        exportObservable = this.reportService.exportProfitLossToCSV(
          this.startDate,
          this.endDate
        )
        break;
      case 'csv-1':
        exportObservable = this.reportService.exportCashFlowToCSV(
          this.startDate,
          this.endDate
        );
        break;
      case 'csv-2':
        exportObservable = this.reportService.exportBalanceSheetToCSV(
          this.startDate,
          this.endDate
        );
        break;
      case 'csv-3':
        exportObservable = this.reportService.exportSalesByItemToCSV(
          this.startDate,
          this.endDate
        );
        break;
      case 'csv-4':
        exportObservable = this.reportService.exportSalesByCustomerToCSV(
          this.startDate,
          this.endDate
        );
        break;
      case 'csv-5':
        exportObservable = this.reportService.exportAccountTypesSummaryToCSV(
          this.startDate,
          this.endDate
        );
        break;
      case 'csv-6':
        exportObservable = this.reportService.exportJournalsReportToCSV(
          this.startDate,
          this.endDate
        );
        break;
      case 'csv-7':
        exportObservable = this.reportService.exportJournalsLedgerToCSV(
          this.startDate,
          this.endDate
        );
        break;
      case 'csv-8':
        exportObservable = this.reportService.exportCustomerBalanceSummaryToCSV(
          this.startDate,
          this.endDate
        );
        break;
      case 'csv-9':
        exportObservable = this.reportService.exportPaymentReceivedToCSV(
          this.startDate,
          this.endDate
        );
        break;
      case 'csv-10':
        exportObservable = this.reportService.exportPaymentMadeToCSV(
          this.startDate,
          this.endDate,
          []
        );
        break;
      case 'csv-11':
        exportObservable = this.reportService.exportVendorBalanceSummary(
          this.startDate,
          this.endDate
        );
        break;
      case 'csv-12':
        exportObservable = this.reportService.exportPettyCashSummary(
          this.startDate,
          this.endDate
        );
        break;
      default:
        this.toastr.error('Invalid export format selected.');
        return;
    }
    // Call the export service method
    exportObservable.subscribe({
      next: (response: Blob) => {
        this.getFile(response, this.exportFormat);
        // this.exportForm.reset();
      },
      error: (error) => {
        this.toastr.error('Failed to export report. Please try again.');
      },
    });
  }

  async getFile(response: any, format: string) {
    try {
      const file = await this.fileExportService.getFileUrlPost(
        response,
        format
      );
    } catch (error) {
      console.error('Error exporting file', error);
    }
  }

  //fetch profit & loss report data
  private getProfitLossReport(startDate: string, endDate: string) {
    this.reportService.fetchProfitLossReport(startDate, endDate).subscribe({
      next: (response) => {
        this.profitLossData = response;
        console.log('ProfitLossData:', this.profitLossData);
      },
      error: (error) => {
        console.log('Error fetching profit loss data:', error);
      },
    });
  }

  //fetch cash flow statement report
  private getCashFlowReport(startDate: string, endDate: string) {
    this.reportService.fetchCashFlowReport(startDate, endDate).subscribe({
      next: (response) => {
        this.cashFlowReport = response;
        console.log('CashFlowReportData:', this.cashFlowReport);
      },
      error: (error) => {
        console.log('Error fetching Cash flow statement data:', error);
      },
    });
  }


  //fetch balance sheet report
  private getBalanceSheet(startDate: string, endDate: string) {
    this.reportService.fetchBalanceSheet(startDate, endDate).subscribe({
      next: (response) => {
        this.balanceSheetReport = response;
        console.log('Balance Sheet Report:', this.balanceSheetReport);
      },
      error: (error) => {
        console.log('Error fetching balance sheet data:', error);
      },
    });
  }

  //fetch sales by item report
  private getSalesByItemReport(startDate: string, endDate: string) {
    this.reportService.fetchSalesByItem(startDate, endDate).subscribe({
      next: (response) => {
        this.salesByItemReport = response;
        console.log('Sales by Item Report:', this.salesByItemReport);
      },
      error: (error) => {
        console.log('Error fetching sales by Item report:', error);
      },
    });
  }

  //fetch sales by customer report
  private getSalesByCustomerReport(startDate: string, endDate: string) {
    this.reportService.fetchsalesByCustomer(startDate, endDate).subscribe({
      next: (response) => {
        this.salesByCustomReport = response;
        console.log('Sales by Custom Report:', this.salesByCustomReport);
      },
      error: (error) => {
        console.log('Error fetching sales by Custom report:', error);
      },
    });
  }

  //fetch account type summary report
  private getAccountTypeSummaryReport(startDate: string, endDate: string) {
    this.reportService
      .fetchAccountTypeSummaryReport(startDate, endDate)
      .subscribe({
        next: (response) => {
          this.accountTypeSummaryReport = response;
          console.log(
            'Account Type Summary Report:',
            this.accountTypeSummaryReport
          );
        },
        error: (error) => {
          console.log('Error fetching account type summary report:', error);
        },
      });
  }

  //fetch journal report
  private getJournalReport(startDate: string, endDate: string) {
    this.reportService.fetchJournalReport(startDate, endDate).subscribe({
      next: (response) => {
        this.journalReport = response;
        console.log('journal Report:', this.journalReport);
      },
      error: (error) => {
        console.log('Error fetching journal report:', error);
      },
    });
  }

  //fetch general ledger report
  private getGeneralLedger(startDate: string, endDate: string) {
    this.reportService.fetchGeneralLedgerReport(startDate, endDate).subscribe({
      next: (response) => {
        this.generalLedgerReport = response;
        console.log('general ledger Report:', this.generalLedgerReport);
      },
      error: (error) => {
        console.log('Error fetching general ledger report:', error);
      },
    });
  }

  //fetch customer balance report
  private getCustomerBalanceSummaryReport(startDate: string, endDate: string) {
    this.reportService
      .fetchCustomerBalanceSummaryReport(startDate, endDate)
      .subscribe({
        next: (response) => {
          const summary = response.summary.filter((item: any) => item.invoicedAmount > 0);
          console.log({ summary })
          this.customerBalanceSummaryReport = { summary, totals: response.totals };
          console.log('ReportCustomer', this.customerBalanceSummaryReport);
        },
        error: (error) => {
          console.log('Error fetching customer balance summary report:', error);
        },
      });
  }

  //fetch payment received report data
  private getPaymentReceivedReport(startDate: string, endDate: string) {
    this.reportService
      .fetchPaymentReceivedReport(startDate, endDate)
      .subscribe({
        next: (response) => {
          this.paymentReceivedReportrData = response;
          console.log(
            'payment received Report:',
            this.paymentReceivedReportrData
          );
        },
        error: (error) => {
          console.log('Error fetching payment received report:', error);
        },
      });
  }

  //fetch payment made report
  private getPaymentMadeReport(startDate: string, endDate: string) {
    this.reportService
      .fetchPaymentMadeReport(startDate, endDate)
      .subscribe({
        next: (response) => {
          this.paymentMadeReportData = response;
          console.log(
            'payment made Report:',
            this.paymentMadeReportData
          );
        },
        error: (error) => {
          console.log('Error fetching payment made report:', error);
        },
      });
  }

  //fetch customer balance report
  private getVendorBalanceSummary(startDate: string, endDate: string) {
    this.reportService.fetchVendorBalanceReport(startDate, endDate).subscribe({
      next: (response) => {
        this.vendorBalanceSummaryReport = response;

        const summary = response.summary.filter((item: any) => item.billedAmount > 0);
        console.log({ summary })
        this.vendorBalanceSummaryReport = { summary, totals: response.totals };
        console.log('ReportCustomer', this.vendorBalanceSummaryReport);

      },
      error: (error) => {
        console.log('Error fetching vendor balance summary report:', error);
      },
    });
  }

  private getTrialBalanceReport(startDate: string, endDate: string) {
    this.reportService.getTrialBalanceReport(startDate, endDate).subscribe({
      next: (response) => {
        this.trialBalanceReport = response;
      },
      error: (error) => {
        console.log('Error fetching trial balance report:', error);
      },
    });
  }

  private getInvoiceAgingReport(daysFilter: string) {
    this.reportService.getInvoiceAgingReport(daysFilter).subscribe({
      next: (response) => {
        this.agingInvoiceReport = response;

        console.log('aging invoice report:', this.agingInvoiceReport);
      },
      error: (error) => {
        console.log('Error fetching trial balance report:', error);
      },
    });
  }

  private getVendorInvoiceAgingReport(daysFilter: string) {
    this.reportService.getVendorInvoiceAgingReport(daysFilter).subscribe({
      next: (response) => {
        this.agingVendorInvoiceReport = response;

        console.log('aging Vendor invoice report:', this.agingVendorInvoiceReport);
      },
      error: (error) => {
        console.log('Error fetching trial balance report:', error);
      },
    });
  }

  private getPettyCash(startDate: string, endDate: string) {
    this.reportService.fetchPettyCash(startDate, endDate).subscribe({
      next: (response: any) => {

        const EmployeeTotal = response.expenseSummary.reduce((sum: number, employee: any) => sum + employee.totalAmount, 0);

        const ProjectTotal = response.projectSummary.reduce((sum: number, project: any) => sum + project.totalAmount, 0);

        const reponseData = {
          projectSummary: response.projectSummary,
          projectTotal: ProjectTotal,
          expenseSummary: response.expenseSummary,
          employeeTotal: EmployeeTotal,
          totalInflow: response.totalInflow,
        }


        this.pettyCash = reponseData;
        console.log('Petty Cash:', this.pettyCash);
      },
      error: (error: any) => {
        console.log('Error fetching petty cash:', error);
      },
    });
  }

  setTab(index: number) {
    this.selectedTabIndex = index;
  }

  toggleExport(event: MouseEvent) {
    event.stopPropagation();
    this.isExportOpen = !this.isExportOpen;
  }
}
