import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';

export interface Breadcrumb {
  name: string;
  path: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbs: Breadcrumb[] = [];

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.createBreadcrumbs())
      )
      .subscribe();
  }

  createBreadcrumbs(): Breadcrumb[] {
    const urlSegments = this.router.url.split('?')[0].split('/').filter((segment) => segment);
    const queryParams = new URLSearchParams(this.router.url.split('?')[1] || '');
    const breadcrumbs: Breadcrumb[] = [];
    let path = '';

    urlSegments.forEach((segment) => {
      // Skip ID-like segments
      if (this.isIdSegment(segment)) {
        return;
      }

      path += `/${segment}`;
      breadcrumbs.push({ name: this.formatSegment(segment), path });
    });

    // Handle query parameters for specific routes
    if (this.router.url.includes('/payroll-list')) {
      const month = queryParams.get('month');
      const year = queryParams.get('year');
      if (month && year) {
        const name = `${month} ${year}`;
        breadcrumbs.push({ name, path: this.router.url }); // Full URL for the path
      }
    }

    this.breadcrumbs = breadcrumbs;
    return this.breadcrumbs;
  }

  getBreadcrumbs(): Breadcrumb[] {
    return this.breadcrumbs;
  }

  private formatSegment(segment: string): string {
    return segment
      .replace(/-/g, ' ') // replace dashes with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // capitalize each word
  }

  // Helper method to check if a segment is an ID
  private isIdSegment(segment: string): boolean {
    return !isNaN(Number(segment)) || /^[0-9a-fA-F]{24}$/.test(segment);
  }
}
