import { Component, Inject, OnInit, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { PAYMENT_TERMS, PartnerTypesEnum, UNITTYPES } from '../../helpers/helper-file';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { CreditDebitNoteService } from '../../features/credit-debit-note/services/credit-debit-note.service';
import { PurchaseOrdereService } from '../../features/purchase-order/services/purchase-orderservice';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import {  nonNegativeValidator } from '../../shared/services/validations';
import { WorkOrderService } from '../../features/workOrder/services/workOrder.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ListBillingAddressDialog } from '../invoice-new/dialog-components/list-billing-address-dialog/list-billing-address-dialog.component';
import { ListShippingAddressDialog } from '../invoice-new/dialog-components/list-shipping-address-dialog/list-shipping-address-dialog.component';
import { BillingAddressDialog } from '../invoice-new/dialog-components/add-billing-address-dialog/billing-address-dialog.component';
import { ShippingAddressDialog } from '../invoice-new/dialog-components/add-shipping-address-dialog/shipping-address-dialog.component';
import { PreferenceService } from '../../shared/services/preference.service';
import { CreateVendorDialog } from '../invoice-new/dialog-components-vendor/create-vendor-dialog/create-vendor-dialog.component';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { MatOptionModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CUSTOM_DATE_FORMATS } from '../../shared/services/date-format';
import { CustomDateAdapter } from '../../shared/services/custom-date-adapter';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';

@Component({
  selector: 'app-purchase-order-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    MatRadioModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatCheckboxModule,
    PermissionDirective
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  templateUrl: './purchase-order-new.component.html',
  styleUrl: './purchase-order-new.component.scss',
})


export class PurchaseOrderNewComponent implements OnInit {
  // public arr = [1, 2, 3, 4, 5, 6, 7];
  currentDate: string;
  public vendorsData: any[] = [];
  public cutomersData: any[] = [];
  public billingAddressList: any[];
  public shippingAddressList: any[];
  accountData: any[] = [];
  paymentTermsValue = PAYMENT_TERMS;
  loading: boolean = false;
  public itemData: any[] = [];
  public filteredItemData: any[] = [];
  purchaseOrderForm: FormGroup;
  purchaseOrderData: any;
  AdminAddressData: any;
  purchaseOrderId: any;
  selectedVendorId: any;
  private searchSubject = new Subject<string>();
  allCurrencies: any;
  workOrders: any;
  filteredWorkOrders: any[] = [];
  workOrderControl = new FormControl('');
  workOrderSearchControl = new FormControl('');
  public selectedBillingAddress: any = {};
  public selectedShippingAddress: any = {};
  isCustomerSelected: boolean = false;
  selectedCustomerId: number | null = null;
  readonly dialog = inject(MatDialog);
  vendorControl = new FormControl();
  customerControl = new FormControl();
  customerSearchSubject = new Subject<string>();
  selectedVendorTRN: any;
  prefix: any;
  purchaseOrderNo: any;
  addedItems: any[] = [];
  selectedCurrencyCode: string = 'AED';
  unitTypes: any = UNITTYPES;
  subSubjectList: string[] = [];
  public filteredSubSubjectList: string[] = [];
  newPurchaseItems: any[] = [];
  profileName: any;
  totalDiscount: any;
  Permission = Permission;
  userName: any;
  private partnerTypes = PartnerTypesEnum;
  //Newly added code //

  subTotal: number;
  totalVat: number;
  grandTotal: number;
  additionalDiscount: number;

  
  constructor(
    private invoiceService: InvoiceService,
    private itemService: InventoryItemService,
    private creditDebitNoteService: CreditDebitNoteService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private purchaseOrderService: PurchaseOrdereService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private workOrderService: WorkOrderService,
    private preferenceService: PreferenceService
  ) {
    this.currentDate = new Date().toISOString().split('T')[0]; // Format as YYYY-MM-DD
    const loggedInUser: any = localStorage.getItem('loggedInUser'); 
    const userData = JSON.parse(loggedInUser);
    this.profileName = userData?.profileData?.profileName;
    this.userName = userData?.firstName;
    this.totalDiscount = 0;
    this.subTotal = 0;
    this.totalVat = 0;
    this.grandTotal = 0;
    this.additionalDiscount = 0;
  }

  ngOnInit(): void {
    this.initForm();
    this.fetchPartners();
    this.fetchWorkOrders();
    this.fetchItems();
    this.listCurrencies();
    this.fetchCustomers();

    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.purchaseOrderId = +id;
        // this.purchaseOrderForm.get('vendorId')?.disable();
        this.loadPurchaseOrderData();
      }
    });
    if (!this.purchaseOrderId) {
      this.setAdminAddress();
      this.preferenceCode();
    }


    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchPartners(null,searchTerm);
      });

    this.workOrderControl.valueChanges
      .pipe(
        debounceTime(600), // Delay in ms before triggering the search
        distinctUntilChanged() // Prevent triggering if search term doesn't change
      )
      .subscribe((searchTerm) => {
        this.fetchWorkOrders(searchTerm);
      });


    this.purchaseOrderForm
      .get('currency')
      ?.valueChanges.subscribe((selectedCurrencyId) => {
        const selectedCurrency = this.allCurrencies.find(
          (currency: { id: any }) => currency.id === selectedCurrencyId
        );
        this.selectedCurrencyCode = selectedCurrency?.currencyCode || 'AED'; // Fallback to AED
      });

    // this.vendorControl.valueChanges.subscribe((searchTerm) => {
    //   console.log('Vendor Control Value:', searchTerm);
    //   if (!searchTerm) {
    //     this.fetchPartners();
    //   } else {
    //     this.searchSubject.next(searchTerm);
    //   }
    // });
  

    // this.purchaseOrderForm.get('isVat')?.valueChanges.subscribe(() => {
    //   this.recalculateVatAndTotal();
    // });

    // this.customerSearchSubject
    //   .pipe(debounceTime(600), distinctUntilChanged())
    //   .subscribe((searchTerm) => {
    //     this.fetchCustomers(searchTerm);
    //   });

    // this.customerControl.valueChanges.subscribe((searchTerm) => {
    //   if (!searchTerm) {
    //     this.fetchCustomers();
    //   } else {
    //     this.searchSubject.next(searchTerm);  // Proceed with the search logic
    //   }
    // });
  }

  onVendorSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  recalculateVatAndTotal(): void {
    const isVatEnabled = this.purchaseOrderForm.get('isVat')?.value;
    const totalAmount = this.getTotalAmount(); // Amount before VAT
    const additionalDiscount = this.getAdditionalDiscount();
    const vatAmount = this.getVAT(); // Get VAT based on the checkbox

    const grandTotal = totalAmount - additionalDiscount + vatAmount;

    this.purchaseOrderForm.patchValue({
      grandTotal: grandTotal,
      vatAmount: vatAmount,
    });

    this.items.controls.forEach((itemGroup: any) => {
      this.calculateAmount(itemGroup);
    });
    this.recalculateTotals();
  }

  initForm() {
    this.purchaseOrderForm = this.fb.group({
      id: [this.purchaseOrderData?.id || ''],
      PurchaseType: ['Supplier'],
      vendorId: [this.purchaseOrderData?.vendorId, Validators.required],
      purchaseOrderNo: ['', [Validators.required]],
      customerId: [],
      workorderId: [null, Validators.required],
      reference: [''],
      paymentTerms: ['', Validators.required],
      currency: [10, Validators.required],
      shipmentPreference: [''],
      subject: [''],
      selectedAddress: [],
      shippingAddressId: this.selectedShippingAddress.id,
      billingAddressId: this.selectedBillingAddress.id,
      organizationAddressId: [],
      items: this.fb.array([]),
      termsConditions: [],
      customerNotes: [],
      billDate: [
        this.purchaseOrderData?.billDate
          ? new Date(Number(this.purchaseOrderData.billDate))
              .toISOString()
              .split('T')[0]
          : '',
        Validators.required,
      ],
      trnNumber: [''],
      additionalDiscount: [0, [Validators.min(0)]],
      discountType: ['flat', Validators.required],
      isVat: [true, Validators.required],
    });

  }


  onVendonSelected(event: MatSelectChange) {
    const selectedVendorId = event.value;
    this.selectedVendorId = selectedVendorId;
  }

  // onSearch(event: Event) {
  //   const input = event.target as HTMLInputElement;
  //   const searchTerm = input.value;

  //   // If the input is empty (after the cross is clicked), reset the vendor list
  //   if (!searchTerm) {
  //     this.fetchPartners(); // Fetch all vendors again when input is cleared
  //   } else {
  //     this.searchSubject.next(searchTerm); // Proceed with the search logic
  //   }
  // }

  private formatToLocalDate(date: any): string {
    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // months are zero-indexed
    const day = String(localDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Return in 'YYYY-MM-DD' format
  }

  recalculateTotals(): void {
    // this.getGrandTotal();
    // this.getNewDiscount();
    // this.getNewGrandTotal();
    
    
  }


  calculateItemRatesAndDiscounts() {

    console.log("Items", this.items);

     console.log("purchased", this.newPurchaseItems);

    const additionalDiscountRaw = this.purchaseOrderForm.get('additionalDiscount')?.value;

    const additionalDiscount = Number(additionalDiscountRaw) || 0;
  
    const itemsArray = (this.items as FormArray);
    
    const totalNetPrice = itemsArray.controls.reduce((total, control: AbstractControl) => {
      const item = control.value;
      const netPrice = item.subTotal  - item.discount;
      return total + netPrice;
    }, 0);
  

    const validatedTotalNetPrice = totalNetPrice !== 0 ? totalNetPrice : 1; 
    
    this.newPurchaseItems.map((item: any) => {
      const netPrice = item.netPrice || 0;
      console.log("netPrice", netPrice);
      
      item.splDiscount = (additionalDiscount * netPrice) / validatedTotalNetPrice;
      console.log("splDiscount", item.splDiscount);
      
      const discount = item.discount + item.splDiscount;
      console.log("item.discount", item.discount);
      
      item.rate = (netPrice - discount) / item.quantity;

      item.netPrice = (item.rate * item.quantity) - item.discount;

      item.vatAmount = item.netPrice * 0.05;
      
    });
 
  // this.purchaseOrderForm.get('additionalDiscount')?.setValue(null, { emitEvent: false });
  }
  
  
  
  preferenceCode() {
    this.preferenceService.preferenceCodes('', 'Purchase Order').subscribe({
      next: (response) => {
        response.forEach((prefix: any) => {
          this.purchaseOrderNo = prefix?.sequenceCode;
        });
        // const randomSuffix = Math.floor(1000 + Math.random() * 9000);
        // this.prefix = `${this.purchaseOrderNo + randomSuffix}`;
        this.prefix = `${this.purchaseOrderNo}`;

        this.purchaseOrderForm.patchValue({ purchaseOrderNo: this.prefix });
      },
      error: (error) => {
        console.error(error, 'Error');
      },
    });
  }
  preventEnterKey(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      console.log('Enter key press prevented.');
    }
  }

  openCreateVendorDialog() {
    const dialogRef = this.dialog.open(CreateVendorDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
      }
      this.fetchPartners();
    });
  }

  onSubSubjectSearch(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredSubSubjectList = this.subSubjectList.filter((sub) =>
      sub.toLowerCase().includes(inputValue)
    );
    // Allow manual input if it doesn't match the filtered list
    if (!this.filteredSubSubjectList.includes(inputValue) && inputValue) {
      this.filteredSubSubjectList.push(inputValue);
    }
  }

  loadPurchaseOrderData() {
    if (this.purchaseOrderId != null) {
      this.purchaseOrderService
        .fetchPurchaseOrder(this.purchaseOrderId)
        .subscribe({
          next: (data) => {
            this.purchaseOrderData = data;
            this.newPurchaseItems = data.purchaseItems;
            const vendor = this.fetchPartners(this.purchaseOrderData?.vendorId, '')

            this.purchaseOrderForm.patchValue({
              PurchaseType: this.purchaseOrderData?.PurchaseType,
              vendorId: this.purchaseOrderData?.vendorId,
              purchaseOrderNo: this.purchaseOrderData?.purchaseOrderNo,
              trnNumber: this.purchaseOrderData?.TRNno,
              reference: this.purchaseOrderData?.reference,
              currency: this.purchaseOrderData?.currencyId,
              workorderId: this.purchaseOrderData?.workorderData?.id,
              paymentTerms: this.purchaseOrderData?.paymentTerms,
              // dueDate: this.purchaseOrderData.dueDate
              //   ? new Date(Number(this.purchaseOrderData.dueDate))
              //   : null, //Date
              shipmentPreference: this.purchaseOrderData?.shippmentInstructions,
              subject: this.purchaseOrderData?.subject,
              customerNotes: this.purchaseOrderData?.customerNote,
              billDate: this.purchaseOrderData.billDate
                ? new Date(Number(this.purchaseOrderData.billDate))
                : null, //Date
              billingAddressId: this.purchaseOrderData?.billingAddressData?.id,
              shippingAddressId:
                this.purchaseOrderData?.shippingAddressData?.id,
              discountType:
                this.purchaseOrderData?.isFlat === true ? 'flat' : 'percentage',
              additionalDiscount: this.purchaseOrderData?.orderDiscount,
              isVat: this.purchaseOrderData?.isVatable,
            });

            this.isCustomerSelected = this.purchaseOrderData?.customerId !== 0;
            if (this.purchaseOrderData?.customerId !== 0) {
              this.selectedBillingAddress =
                this.purchaseOrderData?.billingAddressData;
              this.selectedShippingAddress =
                this.purchaseOrderData?.shippingAddressData;
            }
            if (this.purchaseOrderData?.customerId === 0) {
              this.toggleOrgainization();
            }


            if (data.purchaseItems) {
              data.purchaseItems.forEach((item: any, index: any) => {
                this.newPurchaseItems[index].vatAmount = item.vat;
                this.newPurchaseItems[index].itemName = item.name;
                this.calculateLogic();
                
              });
            }
          },
        });
    }
  }

  get items(): FormArray {
    return this.purchaseOrderForm.get('items') as FormArray;
  }

  // addItem(): void {
  //   const itemGroup = this.fb.group({
  //     selectItem: [null],
  //     itemName: ['', Validators.required], // Add itemName field
  //     isManual: [true],
  //     account: [null],
  //     unit: ['', Validators.required],
  //     quantity: [
  //       1,
  //       [
  //         Validators.required,
  //         Validators.min(1),
  //         Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
  //       ],
  //     ],
  //     vatAmount: [0, [Validators.min(0), Validators.pattern(/^\d+(\.\d+)?$/)]],
  //     rate: [
  //       null,
  //       [
  //         Validators.required,
  //         Validators.min(0),
  //         Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
  //       ],
  //     ],
  //     discount: [0, [Validators.min(0), Validators.pattern(/^\d+(\.\d+)?$/)]],
  //     subTotal: [0, [Validators.min(0), Validators.pattern(/^\d+(\.\d+)?$/)]],
  //     total: [0, [Validators.required, nonNegativeValidator]],
  //     netPrice: [''],
  //     subject: [],

  //   });

  //   this.items.push(itemGroup);

  //   itemGroup.get('isManual')?.valueChanges.subscribe((isManual) => {
  //     const itemIdControl = itemGroup.get('selectItem');
  //     if (isManual) {
  //       itemIdControl?.clearValidators();
  //       itemIdControl?.setValue(null); // Set itemId to null when manual
  //     } else {
  //       itemIdControl?.setValidators(Validators.required); // Set back to required when not manual
  //     }
  //     itemIdControl?.updateValueAndValidity();
  //   });

  //   // Trigger validation checks after adding the group
  //   itemGroup.updateValueAndValidity();
  //   this.items.updateValueAndValidity();

  //   // Subscribe to changes in 'selectItem' to autofill other fields
  //   itemGroup.get('selectItem')?.valueChanges.subscribe((itemId) => {
  //     const selectedItem = this.itemData.find((item) => item.id === itemId);
  //     if (selectedItem) {
  //       itemGroup.patchValue({
  //         quantity: 1,
  //         rate: selectedItem.costPrice,
  //         unit: selectedItem.unit,
  //         discount: selectedItem.discount,
  //         total: +selectedItem.quantity * +selectedItem.costPrice,
  //         subTotal: +selectedItem.quantity * +selectedItem.costPrice,
  //       });
  //       this.calculateAmount(itemGroup);
  //     }
  //   });

  //   itemGroup
  //     .get('rate')
  //     ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
  //   itemGroup
  //     .get('quantity')
  //     ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
  //   itemGroup
  //     .get('discount')
  //     ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
  // }

  private fetchItems(search: string = '', filter: any = {}) {
    const updatedFilter = {
      ...filter,
      isPurchaseActive: true,
    };

    this.itemService.fetchItems(search, updatedFilter).subscribe({
      next: (items) => {
        this.itemData = items;
        this.filteredItemData = items;
      },
      error: (error) => console.error(error),
    });
  }

  // Method to handle vendor selection and update TRN number
  onVendorChange(vendorId: string) {
    const selectedVendor = this.vendorsData.find(
      (vendor) => vendor.id === vendorId
    );
    if (selectedVendor) {
      this.selectedVendorTRN = selectedVendor.trnNumber || '';
      this.purchaseOrderForm.get('trnNumber')?.setValue(this.selectedVendorTRN);
    }
  }
    // FETCH VENDORS
  private fetchPartners(id: number| null = null, search: string = '') {
    this.purchaseOrderService.fetchCustomers(id,search, this.partnerTypes.Vendor).subscribe({
      next: (partners) => {
        this.vendorsData = partners
          .map((data: any) => ({
            id: data.id || '--',
            name: data.displayName || '--',
            addresses: data.addresses || [],
            trnNumber: data.trnNumber,
          }));
      },
      error: (error) => console.error(error),
    });
  }

  removeItem(index: number): void {
    this.items.removeAt(index);
  }

  private fetchCustomers(search: string = '') {
    this.invoiceService.fetchCustomers(search, this.partnerTypes.Customer).subscribe({
      next: (partners) => {
        this.cutomersData = partners
          .map((data: any) => ({
            id: data.id || '--',
            name: data.displayName || '--',
            addresses: data.addresses || [],
          }));
        if (this.purchaseOrderData) {
          this.purchaseOrderForm.patchValue({
            customerId: this.purchaseOrderData.customerId,
          });
        }
      },
      error: (error) => console.error(error),
    });
  }

  getAdditionalDiscount(): number {
    const totalAmount = this.getTotalAmount(); // Amount before VAT
    const additionalDiscount = parseFloat(
      this.purchaseOrderForm.get('additionalDiscount')?.value || '0'
    );
    const discountType = this.purchaseOrderForm.get('discountType')?.value;

    if (discountType === 'percentage') {
      return (totalAmount * additionalDiscount) / 100;
    }

    return additionalDiscount; // Flat discount
  }

  getTotalAmount(): number {
    // Calculate total for newly added items
    const newItemsTotal = this.items.controls.reduce((total, itemGroup) => {
      const rate = parseFloat(itemGroup.get('rate')?.value || '0');
      const quantity = parseFloat(itemGroup.get('quantity')?.value || '0');
      const discount = parseFloat(itemGroup.get('discount')?.value || '0');
      const subTotal = rate * quantity;
      return total + (subTotal - discount); // Total after discount
    }, 0);

    // Calculate total for already added items in purchaseOrderData
    const existingItemsTotal = this.purchaseOrderData?.purchaseItems.reduce(
      (total: any, item: any) => {
        const rate = parseFloat(item.rate || '0');
        const quantity = parseFloat(item.quantity || '0');
        const discount = parseFloat(item.discount || '0');
        const subTotal = rate * quantity;
        return total + (subTotal - discount); // Total after discount
      },
      0
    );

    // Return combined total
    return newItemsTotal + (existingItemsTotal || 0);
  }

  getSubTotal(): number {
    // Calculate subtotal for newly added items
    const newItemsSubtotal = this.items.controls.reduce((subtotal, item) => {
      const quantity = parseFloat(item.get('quantity')?.value || '0');
      const rate = parseFloat(item.get('rate')?.value || '0');
      return subtotal + quantity * rate;
    }, 0);

    // Calculate subtotal for already added items in purchaseOrderData
    const existingItemsSubtotal = this.purchaseOrderData?.purchaseItems.reduce(
      (subtotal: any, item: any) => {
        const quantity = parseFloat(item.quantity || '0');
        const rate = parseFloat(item.rate || '0');
        return subtotal + quantity * rate;
      },
      0
    );

    // Return the total subtotal
    return newItemsSubtotal + (existingItemsSubtotal || 0);
  }

  getDiscount(): number {
    // Calculate discount for newly added items
    const newItemsDiscount = this.items.controls.reduce(
      (discount, itemGroup) => {
        return discount + parseFloat(itemGroup.get('discount')?.value || '0');
      },
      0
    );

    // Calculate discount for already added items
    const existingItemsDiscount = this.purchaseOrderData?.purchaseItems.reduce(
      (discount: any, item: any) => {
        return discount + parseFloat(item.discount || '0');
      },
      0
    );

    // Return total discount
    return newItemsDiscount + (existingItemsDiscount || 0);
  }

  getVAT(): number {
    const isVatEnabled = this.purchaseOrderForm.get('isVat')?.value;
    if (!isVatEnabled) {
      return 0.0;
    }
    const totalAfterDiscount = this.getTotalAmount();
    return (totalAfterDiscount * 5) / 100;
  }

  getGrandTotal(): number {
    const totalAfterDiscount =
      this.getTotalAmount() - this.getAdditionalDiscount();
    const vat = this.getVAT();
    return totalAfterDiscount + vat; // Grand Total = Discounted Total + VAT
  }

  calculateAmount(itemGroup: FormGroup): void {
    const rate = parseFloat(itemGroup.get('rate')?.value || '0');
    const quantity = parseFloat(itemGroup.get('quantity')?.value || '0');
    const discount = parseFloat(itemGroup.get('discount')?.value || '0');

    const subTotal = rate * quantity; // Subtotal = rate * quantity
    let netPrice = subTotal - discount; // Total after discount

    const vatRate = 5;
    let vatAmount = 0;
    if (this.purchaseOrderForm.get('isVat')?.value) {
      vatAmount = parseFloat((netPrice * 0.05).toFixed(2)); 
    }

    const total = netPrice + Number(vatAmount); 
    itemGroup.patchValue({ subTotal, vatAmount, total, netPrice });
  }


  setAdminAddress() {
    this.purchaseOrderService.fetchAdminAdress().subscribe({
      next: (data) => {
        if (data && data.length > 0) {
          this.selectedCustomerId = data[0]?.partnerId ?? null;
        } else {
          console.error('No admin address found or empty address array.');
        }
        this.selectedBillingAddress = data.find(
          (address: any) => address.addressType === 'BILLING'
        );
        this.selectedShippingAddress = data.find(
          (address: any) => address.addressType === 'SHIPPING'
        );
        this.purchaseOrderForm.patchValue({
          billingAddressId: this.selectedBillingAddress?.id || null,
          shippingAddressId: this.selectedShippingAddress?.id || null,
        });
      },
      error: (err) => {
        console.error('Error fetching admin adrress:', err); // Handle errors gracefully
      },
    });
  }

  toggleCustomer() {
    this.isCustomerSelected = true;
    this.selectedBillingAddress = '';
    this.selectedShippingAddress = '';
  }

  toggleOrgainization() {
    this.isCustomerSelected = false;
    this.selectedBillingAddress = '';
    this.selectedShippingAddress = '';
    this.setAdminAddress();
  }
  openSelectBillingAddressDialog() {
    const selectedCustomerId = this.purchaseOrderData?.customerId;

    console.log({selectedCustomerId});
    

    const type = 'BILLING';

    const dialogRef = this.dialog.open(ListBillingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });

    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        this.selectedBillingAddress = address;
        this.purchaseOrderForm.patchValue({ billingAddressId: address.id });
      }
    });
  }

  openSelectShippingAddressDialog() {
    const selectedCustomerId =  this.purchaseOrderData?.customerId;
    const type = 'SHIPPING';

    const dialogRef = this.dialog.open(ListShippingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });
    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        this.selectedShippingAddress = address;
        this.purchaseOrderForm.patchValue({ shippingAddressId: address.id });
      }
    });
  }

  openBillingAddressDialog(type: any) {
    const selectedClientId = this.selectedCustomerId;
    this.dialog.open(BillingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }

  openShippingAddressDialog(type: any) {
    const selectedClientId = this.selectedCustomerId;
    this.dialog.open(ShippingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }

  onCustomerChange(event: MatSelectChange): void {
    this.selectedCustomerId = event.value; // Get the selected customer ID
    const selectedCustomer = this.cutomersData.find(
      (customer) => customer.id === this.selectedCustomerId
    );

    if (selectedCustomer && selectedCustomer.addresses) {
      // Find billing address
      const billingAddress = selectedCustomer.addresses.find(
        (address: any) => address.addressType === 'BILLING'
      );
      this.selectedBillingAddress = billingAddress || null;

      // Find shipping address
      const shippingAddress = selectedCustomer.addresses.find(
        (address: any) => address.addressType === 'SHIPPING'
      );
      this.selectedShippingAddress = shippingAddress || null;

      // Update the form with the selected addresses
      this.purchaseOrderForm.patchValue({
        billingAddressId: this.selectedBillingAddress?.id || null,
        shippingAddressId: this.selectedShippingAddress?.id || null,
      });
    } else {
      console.error('No addresses found for the selected customer.');
      this.selectedBillingAddress = null;
      this.selectedShippingAddress = null;
    }
  }

  onItemSearch(event: Event): void {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;

    if (searchTerm) {
      this.fetchItems(searchTerm);
    } else {
      this.filteredItemData = this.itemData;
    }
  }

  onCancel(): void {
    this.purchaseOrderForm.reset();
    this.router.navigate(['/purchase-order']);
  }

  fetchWorkOrders(search: string | null = ''): void {
    const searchTerm = search ?? ''; // Convert null to empty string
    this.workOrderService.getAllWorkOrders(searchTerm).subscribe({
      next: (workorders) => {
        this.filteredWorkOrders = workorders;
      },
      error: (error) => console.error(error),
    });
  }

  saveAsDraft(): void {
    // this.status = 'DRAFT';
    this.createPurchaseOrder('purchase-order/purchase-order-details');
  }

  saveAndSend(): void {
    // this.status = 'DRAFT';
    this.createPurchaseOrder('purchase-order/purchase-order-details/mail');
  }

  createPurchaseOrder(navigateTo: string): void {
    if (this.purchaseOrderForm.invalid) {
      this.purchaseOrderForm.markAllAsTouched();
      const invalidFields: any = [];
      Object.keys(this.purchaseOrderForm.controls).forEach((field) => {
        const control = this.purchaseOrderForm.get(field);
        if (control && control.invalid) {
          invalidFields.push(field);
        }
      });
      this.toastr.warning('Please fill in all required fields.');
      console.log('this.purchaseOrderForm.controls',this.purchaseOrderForm.controls)
      return;
    } else 
    {


      if (!this.purchaseOrderId) {
        // Only check for items if you're creating a new purchase order
        if (
          !this.purchaseOrderForm.value.items ||
          this.purchaseOrderForm.value.items.length === 0
        ) {
          this.toastr.warning(
            'Please add at least one item to the purchase order.'
          );
          return;
        }
      }
      const formValues = this.purchaseOrderForm.value;
      console.log(formValues);


      // Process subSubjectList from items
      const subSubjectList = formValues.items
        .flatMap((item: any) => item?.subject || [])
        .filter((sub: string) => sub) 
        .join(','); 

      
      const createPurchaseOrderInput = {
        vendorId: formValues.vendorId,
        isLocal: false,
        tax: this.totalVat,
        subTotal: this.subTotal,
        totalPrice: this.grandTotal,
        adjustment: 0.0,
        TRNno: formValues.trnNumber,
        workorderId: formValues.workorderId,
        customerId: formValues.customerId ? formValues.customerId : 0,
        currencyId: formValues.currency,
        paymentTerms: formValues.paymentTerms,
        purchaseOrderNo: formValues?.purchaseOrderNo,
        billingAddressId: formValues?.billingAddressId,
        shippingAddressId: formValues.shippingAddressId,
        billDate: this.formatToLocalDate(formValues.billDate),
        shippmentInstructions: formValues.shipmentPreference,
        // dueDate: formValues.dueDate,
        reference: formValues.reference,
        subject: formValues.subject,
        customerNote: formValues.customerNotes,
        orderDiscount: formValues.additionalDiscount,
        isFlat: formValues.discountType === 'flat' ? true : false,
        // isVatable: formValues.isVat,
        subSubjectList: subSubjectList,
        createdByName: this.userName
      };

      const newPurchaseItemInput = this.newPurchaseItems.map((item: any) => {
        console.log({item});
        
        return {
          itemId: item.itemId ? item.itemId : item.isManual ?  null : item.selectItem, // Manual entry, so set itemId to null
          name: item.itemName, // Set the manually typed item name
          isManual: item.itemId ? false: item.isManual,
          purchaseAccountId: null,
          unit: item.unit,
          quantity: item.quantity.toString(),
          balanceQuantity: item.quantity.toString(),
          rate: item?.rate.toString(),
          discount: item.discount || 0,
          amount: ((item?.rate * item?.quantity) - item?.discount) + item?.vatAmount,
          subjectList: item.itemId ? item.subjectList : item?.subject,
          vat: item?.vatAmount,
          measure: item.itemId ? item.measure : item?.measurement,
          actualRate:item.actualRate,
          splDiscount: item?.splDiscount
        };
      });
      
      this.loading = true;
      if (this.purchaseOrderId) {

        if(newPurchaseItemInput.length === 0 ){ 
          this.toastr.warning('Please add at least one item to the purchase order.' );
          this.loading = false;
          return;
        }
        const updatePurchaseOrderInput = {
          id: this.purchaseOrderId,
          ...createPurchaseOrderInput,
        };
        this.purchaseOrderService
          .updatePurchaseOrder(
            updatePurchaseOrderInput,
            newPurchaseItemInput
          )
          .subscribe({
            next: (response) => {
              this.toastr.success('Purchase order updated successfully.');
              const purchaseId = response?.data?.updatePurchaseOrder?.id;
              if (purchaseId) {
                this.purchaseOrderService
                  .generatepurchasepdf(purchaseId)
                  .subscribe({
                    next: (resp) => {
                      this.loading = false;
                      this.router.navigate([`${navigateTo}`], {
                        queryParams: { id: purchaseId },
                      });
                      const pdfUrl = resp?.generatepurchasepdf;
                      if (pdfUrl) {
                        this.toastr.success(
                          resp.message || 'Successfully generated pdf.'
                        );
                        // this.router.navigate([`${navigateTo}/${purchaseId}`]);
                      }
                    },
                    error: (error) => {
                      this.toastr.error(
                        error.message || 'Failed to generate pdf.'
                      );
                    },
                  });
              } else {
                console.error('Purchase order id is missing');
              }
            },
            error: (error) => {
              this.loading = false;
              console.error('Error updating purchase order :', error);
              this.toastr.error(
                error.message || 'Failed to update purchase order. '
              );
            },
          });
      } else {

        if(newPurchaseItemInput.length === 0 ){ 
          this.toastr.warning('Please add at least one item to the purchase order.' );
          this.loading = false;
          return;
        }
        this.purchaseOrderService
          .createpurchaseorder(createPurchaseOrderInput, newPurchaseItemInput)
          .subscribe({
            next: (response) => {
              this.toastr.success('Purchase order created successfully');
              const purchaseId = response?.data?.createPurchaseOrder?.id;
              if (purchaseId) {
                // this.purchaseOrderService.markAsIssued(purchaseId);
                this.purchaseOrderService
                  .generatepurchasepdf(purchaseId)
                  .subscribe({
                    next: (resp) => {
                      this.loading = false;
                      // this.router.navigate([`${navigateTo}/${purchaseId}`]);
                      const pdfurl = resp?.generatepurchasepdf;
                      if (pdfurl) {
                        this.toastr.success(
                          resp.message || 'Successfully generated pdf.'
                        );
                      }
                      this.router.navigate([`${navigateTo}`], {
                        queryParams: { id: purchaseId },
                      });
                    },
                    error: (err) => {
                      this.toastr.error(
                        err.message || 'Failed to generate pdf.'
                      );
                    },
                  });
              }
            },
            error: (error) => {
              this.loading = false;
              this.toastr.error(
                error.message || 'Failed to create purchase-order.'
              );
            },
          });
      }
    }
  }

  listCurrencies(search: string = '', filter: any = {}) {
    this.purchaseOrderService.listcurrencies(search, filter).subscribe({
      next: (currencies) => {
        this.allCurrencies = currencies;
      },
      error: (error) => console.error(error),
    });
  }

  getUpdatePurchaseOrderItemInput() {
    return this.items.controls.map((itemGroup) => {
      const id = itemGroup.get('id')?.value;
      const itemId = itemGroup.get('selectItem')?.value;
      const isManual = itemGroup.get('isManual')?.value;
      const name = itemGroup.get('itemName')?.value;
      const quantity = itemGroup.get('quantity')?.value.toString();
      const balanceQuantity = itemGroup.get('quantity')?.value.toString();
      const rate = itemGroup.get('rate')?.value.toString();
      const discount = itemGroup.get('discount')?.value;
      const amount = itemGroup.get('total')?.value;
      const purchaseAccountId = null;
      const unit = itemGroup.get('unit')?.value;
      const vat = itemGroup.get('vatAmount')?.value;
      const subjectList = itemGroup.get('subject')?.value;

      const existingPurchaseOrderItem =
        this.purchaseOrderData?.purchaseItems?.find(
          (purchaseorderItem: any) => purchaseorderItem.itemId === itemId
        );

      return {
        // id: existingPurchaseOrderItem ? existingPurchaseOrderItem.id : null, // Existing item gets id; new item gets null
        itemId: itemId || null, // Ensure itemId is set correctly
        name:
          name ||
          (existingPurchaseOrderItem ? existingPurchaseOrderItem.name : ''),
        isManual: !!isManual,
        quantity,
        balanceQuantity,
        unit,
        rate,
        discount,
        amount,
        purchaseAccountId,
        subjectList,
        vat,
      };
    });
  }

  onRemoveInvoiceItem(index: number, itemId: number): void {
    if (itemId) {
      const dialogRef = this.dialog.open(ConfirmDialog);
      dialogRef.afterClosed().subscribe((response) => {
        if (response === true) {
          this.removeItemFormLits(itemId, index);
        }
      });
    } else {
      console.error('Item ID is null or undefined');
      this.items.removeAt(index);
    }
  }

  private removeItemFormLits(itemId: number, index: number): void {
    this.purchaseOrderService.removePurchaseOrderItem(itemId).subscribe({
      next: () => {
        this.toastr.success('Purchase Order removed successfully.');
        console.log('Purchase Order  item removed successfully');

        // Ensure `purchaseOrderData` and `purchaseItems` are replaced immutably
        this.purchaseOrderData = {
          ...this.purchaseOrderData, // Clone the parent object
          purchaseItems: [
            ...this.purchaseOrderData.purchaseItems.slice(0, index),
            ...this.purchaseOrderData.purchaseItems.slice(index + 1),
          ],
        };
      },
      error: () => {
        this.toastr.error('Failed to remove purchase order item.');
        console.log('Failed to remove purchase order item.');
      },
    });
  }

  openDialog() {
    const itemsArray = this.purchaseOrderForm.get('items') as FormArray;
    const uniqueSubjects = [
      ...new Set(itemsArray.value.map((item: any) => item.subject)),
    ];

    const dialogRef = this.dialog.open(AddItemsDialog, {
      data: { uniqueSubjects, isLocalPurchaseOrder: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log({result});
        this.newPurchaseItems.push(result);
        this.calculateLogic();
        this.getNewDiscount();
        // Push the result into the items FormArray
        const itemsArray = this.purchaseOrderForm.get('items') as FormArray;
        itemsArray.push(this.fb.group(result)); // Add result as a FormGroup

        console.log('Updated items array:', itemsArray.value);
      }
    });
  }

  findOriginalNetPrice(items: any[]): number {
    let originalNetPrice = 0;
    items.forEach((item: any) => {
      originalNetPrice += (item.actualRate * item.quantity) - item.discount;
    });
    return originalNetPrice;
  }

  calculateLogic() {
    this.subTotal = 0;
    this.totalDiscount = 0;
    this.totalVat = 0;
    this.grandTotal = 0;
    const originalNetPrice = this.findOriginalNetPrice(this.newPurchaseItems);
    const additionalDiscountRaw = this.purchaseOrderForm.get('additionalDiscount')?.value || 0;
    this.additionalDiscount = additionalDiscountRaw;
    this.newPurchaseItems.forEach((item: any) => {
      console.log({item});
      
      this.subTotal = this.subTotal + (item.actualRate * item.quantity);
      this.totalDiscount = this.totalDiscount + (item.discount || 0);
      if(additionalDiscountRaw > 0){
        const ItemNetPrice = (item.actualRate * item.quantity) - item.discount;
        item.splDiscount = (additionalDiscountRaw * (ItemNetPrice / originalNetPrice)) / item.quantity;
        item.rate = item.actualRate - item.splDiscount;
        item.amount = ((item.rate * item.quantity) - item.discount) + item.vatAmount;
      } else {
        item.rate = item.actualRate;
        item.splDiscount = 0;
        item.amount = ((item.rate * item.quantity) - item.discount) + item.vatAmount;
      }
      
      item.vatAmount = item.vatAmount > 0? 0.05 * ((item.rate * item.quantity) - item.discount) : 0;
     // item.vatAmount = item.vat;
      this.totalVat += item.vatAmount;
      


    });
    this.totalDiscount = this.totalDiscount + additionalDiscountRaw;
    const netPrice = (this.subTotal - this.totalDiscount);
    this.grandTotal = netPrice + this.totalVat;
  }

  removeNewPurchaseItem(index: number): void {
    if (index > -1) {
      this.newPurchaseItems.splice(index, 1); // Removes the item at the specified index
    }
    this.calculateLogic();
  }

  getNewTotalAmount(): number {
    return this.newPurchaseItems.reduce((total, item) => {
      const rate = parseFloat(item.rate) || 0;
      const quantity = parseFloat(item.quantity) || 0;
      const discount = item.discount || 0;
      return total + (rate * quantity - discount);
    }, 0);
  }

  getNewSubTotal(): number {
    const newItemsSubtotal = this.newPurchaseItems.reduce((subtotal, item) => {
      const quantity = parseFloat(item.quantity || '0');
      const rate = parseFloat(item.actualRate || '0');
     return subtotal + quantity * rate;
    }, 0);

    const existingItemsSubtotal = this.purchaseOrderData?.purchaseItems.reduce(
      (subtotal: any, item: any) => {
        const quantity = parseFloat(item.quantity || '0');
        const rate = parseFloat(item.rate || '0');
        return subtotal + quantity * rate;
      },
      0
    );

    return newItemsSubtotal + (existingItemsSubtotal || 0);
  }

  getNewDiscount(): void {
    const newItemsDiscount = this.newPurchaseItems.reduce((discount, item) => {
      return discount + (parseFloat(item.discount) || 0);
    }, 0);
  
    const existingItemsDiscount = this.purchaseOrderData?.purchaseItems.reduce(
      (discount: any, item: any) => {
        return discount + parseFloat(item.discount || '0');
      },
      0
    );
  
    // Get the additional discount from the form and log it
    const additionalDiscount = parseFloat(this.purchaseOrderForm.get('additionalDiscount')?.value || '0');
    // Add the additional discount to the total discount
   this.totalDiscount =  newItemsDiscount + (existingItemsDiscount || 0) + additionalDiscount;
  }
  

  getNewVat(): number {
    const vatRate = 5; // VAT percentage (5%)

    // Calculate VAT for new items
    const newItemsVat = this.newPurchaseItems.reduce((total, item) => {
      const quantity = parseFloat(item.quantity) || 0;
      const rate = parseFloat(item.rate) || 0;
      const discount = parseFloat(item.discount) || 0;
      const vatAmount = parseFloat(item.vatAmount) || 0; // vatAmount field to check if VAT should be applied
      const grossPrice = quantity * rate - discount;

      // If vatAmount > 0, apply VAT, else skip VAT calculation
      const itemVatAmount = vatAmount > 0 ? (grossPrice * vatRate) / 100 : 0;
      return total + itemVatAmount;
    }, 0);

    // Calculate VAT for existing items (if any)
    const existingItemsVat = this.purchaseOrderData?.purchaseItems.reduce(
      (total: number, item: any) => {
        const quantity = parseFloat(item.quantity) || 0;
        const rate = parseFloat(item.rate) || 0;
        const discount = parseFloat(item.discount) || 0;
        const vatAmount = parseFloat(item.vat) || 0; // vatAmount field to check if VAT should be applied
        const grossPrice = quantity * rate - discount;

        // If vatAmount > 0, apply VAT, else skip VAT calculation
        const itemVatAmount = vatAmount > 0 ? (grossPrice * vatRate) / 100 : 0;
        return total + itemVatAmount;
      },
      0
    );

    return newItemsVat + (existingItemsVat || 0); // Return the total VAT for all items
  }

  getNewGrandTotal(): number {
    const additionalDiscount = parseFloat(
      this.purchaseOrderForm.get('additionalDiscount')?.value || '0'
    );
    const vatPercentage = 5; // VAT percentage (e.g., 5%)

    // Calculate the total of new purchase items (gross price after discount)
    const newItemsTotal = this.newPurchaseItems.reduce((total, item) => {
      const rate = parseFloat(item.rate) || 0;
      const quantity = parseFloat(item.quantity) || 0;
      const discount = parseFloat(item.discount) || 0;
      const grossPrice = rate * quantity - discount; // Gross price after discount
      return total + grossPrice;
    }, 0);

    // Calculate the total of existing items (gross price after discount)
    const existingItemsTotal =
      this.purchaseOrderData?.purchaseItems.reduce(
        (total: number, item: any) => {
          const rate = parseFloat(item.rate || '0');
          const quantity = parseFloat(item.quantity || '0');
          const discount = parseFloat(item.discount || '0');
          const grossPrice = rate * quantity - discount; // Gross price after discount
          return total + grossPrice;
        },
        0
      ) || 0;
    const netPrice = newItemsTotal + existingItemsTotal;
    const discountedPrice = Math.max(0, netPrice - additionalDiscount);

    const vatAmount = this.getNewVat();
    const grandTotal = discountedPrice + vatAmount;

    return grandTotal;
  }
}

@Component({
  selector: 'add-items-dialog',
  templateUrl: './add-items-dialog.html',
  styleUrls: ['./purchase-order-new.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    CommonModule,
    NgxMatSelectSearchModule,
    MatIconModule,
    MatAutocompleteModule,
  ],
})
export class AddItemsDialog {
  showFirstField: boolean = true;
  itemData: any;
  filteredItemData: any;
  itemGroup: FormGroup;
  selectedItem: string = '';
  formGroup!: FormGroup;
  allItems: any[];
  unitTypes: any = UNITTYPES;
  subjects: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddItemsDialog>,
    private fb: FormBuilder,
    private itemService: InventoryItemService,
    private purchaseOrderService: PurchaseOrdereService
  ) {
    this.allItems = [];
    this.subjects = (data.uniqueSubjects || []).filter(
      (subject: string) => subject && subject.trim() !== ''
    )
    console.log(data);
    
    this.itemGroup = this.fb.group(
      {
        selectItem: [null],
        itemName: ['', Validators.required],
        // quantity: [
        //   1,
        //   [
        //     Validators.required,
        //     Validators.min(1),
        //     // Validators.pattern('^[0-9]+(.[0-9]{1,3})?$'), // Updated for decimal validation
        //     Validators.pattern('^[1-9][0-9]*$')
        //   ],
        // ],
        quantity: [
          1,
          this.data.isLocalPurchaseOrder
            ? [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{1,2})?$')]  // decimal validation
            : [Validators.required, Validators.min(1), Validators.pattern('^[1-9][0-9]*$')] // no decimal
        ],
        isManual: [true],
        unit: ['', Validators.required],
        rate: [
          0,
          [
            Validators.required,
            Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'), // Updated for decimal validation
            Validators.min(0), // Ensure rate is not negative
          ],
        ],
        subTotal: [
          0,
          [
            Validators.required,
            Validators.min(0),
            Validators.pattern(/^\d+(\.\d+)?$/)// Added decimal validation
          ],
        ],
        discount: [
          0,
          [
            Validators.pattern(/^\d+(\.\d+)?$/), this.discountLessThanOrEqualToSubTotal()// Added decimal validation
          ],
        ],
        netPrice: [0,  Validators.required],
        vatAmount: [
          0,
          [
            
            Validators.pattern(/^\d+(\.\d+)?$/), // Added decimal validation
          ],
        ],
        total: [0, [Validators.required]],
        subject: [],
        measurement:[''],
        actualRate:[],
        splDiscount:[0]
      },
    );
    
  }

  ngOnInit(): void {
    this.fetchItems();
    // this.fetchSubjects();

    // const savedSubjects = localStorage.getItem('subjects');
    // if (savedSubjects) {
    //   this.subjects = JSON.parse(savedSubjects); // Parse and set subjects from localStorage
    // }

    this.itemGroup.get('quantity')?.valueChanges.subscribe(() => {
      this.calculateAmount(this.itemGroup);
    });

    this.itemGroup.get('rate')?.valueChanges.subscribe(() => {
      this.calculateAmount(this.itemGroup);
    });

    this.itemGroup.get('discount')?.valueChanges.subscribe(() => {
      this.calculateAmount(this.itemGroup); // Recalculate when discount changes
    });

    this.itemGroup.get('vatAmount')?.valueChanges.subscribe((value) => {
      this.calculateAmount(this.itemGroup, true); // Mark VAT as manually updated
    });

    this.itemGroup.get('discount')?.valueChanges.subscribe(() => {
      this.itemGroup.updateValueAndValidity(); // Trigger validation manually
    });
    
  }

  onItemSearch(event: Event): void {
    const searchValue = (event.target as HTMLInputElement).value.trim();

    this.filteredItemData = this.allItems?.filter((item: any) =>
      item.itemName.toLowerCase().includes(searchValue.toLowerCase())
    );

    if (
      !this.filteredItemData.some((item: any) => item.itemName === searchValue)
    ) {
      this.itemGroup.patchValue({
        selectItem: null,
      });
    }
  }
// Custom Validator: Discount should be less than or equal to SubTotal
discountLessThanOrEqualToSubTotal(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const group = control as any;
      const discount = this.itemGroup?.get('discount')?.value;
      const subTotal = this.itemGroup?.get('subTotal')?.value;
  
      // Return an error if discount is greater than subTotal
      if (discount !== null && subTotal !== null && discount > subTotal) {
        return { discountTooHigh: true };
      }
  
      return null; // No error
    };
  }
  onSelectItem(event: MatAutocompleteSelectedEvent): void {
    const selectedItemName = event.option.value; // Get the selected item's name
    const selectedItem = this.filteredItemData.find(
      (item: any) => item.itemName === selectedItemName
    );

    if (selectedItem) {
      this.itemGroup.patchValue({
        selectItem: selectedItem.id,
        itemName: selectedItem.itemName,
        rate: selectedItem.costPrice,
        quantity: 1,
        isManual: false,
        unit: "nos"
      });

      this.calculateAmount(this.itemGroup);
    }
  }

  calculateAmount(itemGroup: FormGroup, manualVat = false): void {
    const rate = parseFloat(itemGroup.get('rate')?.value) || 0;
    const quantity = parseFloat(itemGroup.get('quantity')?.value) || 0;
    const discount = parseFloat(itemGroup.get('discount')?.value) || 0;
    let vatAmount = parseFloat(itemGroup.get('vatAmount')?.value) || 0;

    const subTotal = rate * quantity;
    const netPrice = subTotal - discount;

    if (!manualVat) {
      const vatRate = 5;
      vatAmount = parseFloat((netPrice * (vatRate / 100)).toFixed(2));
    }

    const total = vatAmount === 0 ? netPrice : netPrice + vatAmount;
    itemGroup.patchValue(
      { subTotal, vatAmount, total, netPrice },
      { emitEvent: false } // Prevent infinite loop
    );
  }

  private fetchItems(search: string = '', filter: any = {}) {
    const updatedFilter = {
      ...filter,
      isPurchaseActive: true,
    };

    this.itemService.fetchItems(search, updatedFilter).subscribe({
      next: (items) => {
        this.itemData = items;
        this.filteredItemData = items;
      },
      error: (error) => console.error(error),
    });
  }

  onSubmit(): void {
    if (this.itemGroup.valid) {
      const subjectValue = this.itemGroup.get('subject')?.value;
      const rateValue = Number(this.itemGroup.get('rate')?.value);
      this.itemGroup.patchValue({ actualRate: rateValue });

      if (subjectValue) {
        this.subjects.push(subjectValue);
        // localStorage.setItem('subjects', JSON.stringify(this.subjects));
      }
      this.dialogRef.close(this.itemGroup.value);
      this.itemGroup.patchValue({ subject: '' });
    }
  }

  private getPurchaseItems() {
    return this.itemGroup.value;
  }
  onCancel(): void {
    this.dialogRef.close();
  }
  toggleField(): void {
    if (!this.showFirstField) {
      this.itemGroup.get('subject')?.setValue(this.subjects[0] || '');
    }
    this.showFirstField = !this.showFirstField;
  }

  // fetchSubjects(): void {
  //   this.purchaseOrderService.fetchsubjects().subscribe({
  //     next: (response) => {
  //       const subs = response
  //         .map((subList: string) => subList.split(','))
  //         .flat()
  //         .filter((sub: string) => sub.trim() !== ''); // Exclude empty strings
  
  //       this.subjects = [...this.subjects, ...subs];
  //       this.subjects = Array.from(new Set(this.subjects)); // Remove duplicates for strings
  
  //       console.log("Updated Subjects:", this.subjects);
  //     },
  //     error: (error) => console.error('Error fetching subjects:', error),
  //   });
  // }
  
  
  preventEnterKey(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      console.log('Enter key press prevented.');
    }
  }
}
