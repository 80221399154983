import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';

import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PaymentsItemService } from '../../features/payments/services/payments.service';
import {
  PAYMENT_MODES,
  PartnerTypesEnum,
  PaymentTypesEnum,
} from '../../helpers/helper-file';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UploadFileService } from '../../shared/services/file-upload.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { pymentGenerateNo } from '../../helpers/helper';
import { CreateClientDialog } from '../invoice-new/dialog-components/create-customer-dialog/create-client-dialog.component';
import { BankingService } from '../../features/banking/services/banking.service';
import { PreferenceService } from '../../shared/services/preference.service';

@Component({
  selector: 'app-payment-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
  ],
  templateUrl: './payment-new.component.html',
  styleUrl: './payment-new.component.scss',
})
export class PaymentNewComponent implements OnInit {
  paymentNewItemForm: FormGroup;
  documentFile: any = null;
  documentFileName: any = null;
  documentFileId: any = null;
  documentFileUrl: any = null;

  partnersList: any;
  paymentModeList: any = PAYMENT_MODES;
  getAccountList: any;
  partnerInvoiceData: any;
  private partnerTypes = PartnerTypesEnum;
  private paymentType = PaymentTypesEnum;
  readonly dialog = inject(MatDialog);
  fullAmount: number = 0.0;
  splitAmounts: number[] = []; // Array to store split values
  totalSplitAmount = 0; // Variable to store the total
  isLoading = false;
  customerSearchControl = new FormControl();
  maxDate: Date = new Date();
  globalInvoiceId: number;
  bankId: any;
  categoryId: any;
  paymentInfo: any[];
  invoiceId: number;
  private searchSubject = new Subject<string>();
  customerIdFromCustomerDetailPage: number | null = null;
  paymentNo: any;

  constructor(
    private fb: FormBuilder,
    private itemService: PaymentsItemService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private documentUploadService: UploadFileService,
    private router: Router,
    private route: ActivatedRoute,
    private bankingService: BankingService,
    private preferenceService: PreferenceService,
  ) {
    this.customerIdFromCustomerDetailPage = Number(
      history.state?.['customerId'] ?? 
      this.router.getCurrentNavigation()?.extras.state?.['customerId'] ?? 
      null
  );
  console.log("customerIdFromCustomerDetailPage",this.customerIdFromCustomerDetailPage);
    this.fetchPartners();
    this.fetchGetAccount();
    this.paymentInfo = [];
  }

  //payment-rec-new
  ngOnInit(): void {
    this.initForm();
  
    this.route.queryParams.subscribe((params) => {
      const id = params['id'];
      this.invoiceId = id;
      const customerId = params['customerId'];
      const invoiceId = params['invoiceId'];
      const expense = params['expense'];
      this.bankId = expense;
      const category = params['category'];
      this.categoryId = category;
      this.globalInvoiceId = parseInt(invoiceId);
      if (id) {
        this.paymentNewItemForm.get('id')?.setValue(id);
        this.loadInvoiceData();
      } else {
        this.preferenceCode();
        // const generatedId = pymentGenerateNo();
        // this.paymentNewItemForm.get('payment')?.setValue(generatedId);
        this.paymentNewItemForm.get('id')?.setValue('');
      }
      this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchPartners(null,searchTerm);
      });
      // this.customerSearchControl.valueChanges
      //   .pipe(debounceTime(300), distinctUntilChanged())
      //   .subscribe((searchText) => {
      //     this.fetchPartners(null,searchText);
      //   });

      if (customerId && invoiceId) {
        this.getDueInvoicesByPartner(parseInt(customerId));
        this.paymentNewItemForm
          .get('customerName')
          ?.setValue(parseInt(customerId));
      }
    });

    // Recalculate splits and total whenever amountReceived changes
    // this.paymentNewItemForm
    //   .get('amountReceived')
    //   ?.valueChanges.subscribe(() => {
    //     this.calculateSplits();
    //   });

    // Initial calculation
    //this.calculateSplits();

    //calculateExcessAmount
    this.paymentNewItemForm
      .get('amountReceived')
      ?.valueChanges.subscribe(() => {
        this.calculateExcessAmount();
        this.usedAmount();
      });
  }

  preferenceCode() {
    this.preferenceService.preferenceCodes('', 'Payment Received').subscribe({
      next: (response: any) => {
        console.log(response);
        
        response.forEach((prefix: any) => {
          this.paymentNo = prefix?.sequenceCode  ;
        })
        const prefix = `${this.paymentNo}`;
        this.paymentNewItemForm.patchValue({ payment: prefix });
      },

      error: (error: any) => {
        console.error(error, 'Error');
      },
    });
  }

  private loadInvoiceData(): void {
    const id = this.paymentNewItemForm.get('id')?.value;
    if (id) {
      this.itemService.fetchPayment(parseInt(id)).subscribe({
        next: (data) => {

          
          this.paymentInfo = data.paymentInfo;
          this.documentFileName = data?.paymentAttachments?.[0]?.fileName;
          this.documentFileId = data?.paymentAttachments?.[0]?.id;
          this.documentFileUrl = data?.paymentAttachments?.[0]?.fileUrl;
          this.fetchPartners(data?.partnerId);
          this.paymentNewItemForm.patchValue({
            id: data?.id,
            customerName: data?.partnerId,
            bankCharges: data?.bankCharge,
            amountReceived: data.amount,
            // paymentDate: new Date(+data?.paymentDate),
            paymentDate: data?.paymentDate
            ? new Date(Number(data?.paymentDate))
            : null, //Date
            chequeNo: data.chequeNo,
            chequeDate: data.chequeDate,
            payment: data.paymentNo,
            paymentMode: data.paymentMode,
            depositTo: data.depositId,
            reference: data.referenceNo,
            note: data.note,
          });
         // Optional: Log to debug and verify
          this.getDueInvoicesByPartner(data?.partnerId);


        },
        error: (error) => console.error(error),
      });
    }
  }

  openCreateClientDialog() {
    this.dialog.open(CreateClientDialog);
  }

  initForm(): void {
    this.paymentNewItemForm = this.fb.group({
      id: [''],
      customerName: ['', Validators.required],
      bankCharges: ['', Validators.pattern(/^\d+(\.\d{1,2})?$/)],
      amountReceived: ['',[Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      paymentDate: ['', Validators.required],
      payment: ['', Validators.required],
      paymentMode: ['', Validators.required],
      depositTo: ['', Validators.required],
      reference: [''],
      chequeNo: [''],
      chequeDate: [''],
      // tax: ['', Validators.required],
      note: [''],
      isFullAmount: [false],
    });
  }

  onCustomerSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }
  private fetchPartners(id: number | null = null,search: string = '') {
    console.log("this.customerIdFromCustomerDetailPage",this.customerIdFromCustomerDetailPage);
    console.log("id",id);
    
    if(this.customerIdFromCustomerDetailPage && !id) {
      id = this.customerIdFromCustomerDetailPage
    }
    this.itemService
      .fetchPartners(id,search, this.partnerTypes.Customer)
      ?.subscribe({
        next: (partners: any) => {
          this.partnersList = partners;
           // Check if the retrieved list contains the customer ID and set it as default
      if (this.customerIdFromCustomerDetailPage) {
        const selectedPartner = this.partnersList.find(
          (partner: any) => Number(partner.id) === Number(this.customerIdFromCustomerDetailPage)
        );

        if (selectedPartner) {
          console.log("Setting default selected partner:", selectedPartner);
          this.paymentNewItemForm.patchValue({ customerName: selectedPartner.id });
          this.paymentNewItemForm.get('customerName')?.updateValueAndValidity();
        }
      }
        },
        error: (error: any) => console.error(error),
      });
  }
  private fetchGetAccount() {
    this.itemService.fetchDepositTo('', true, false)?.subscribe({
      next: (getAccount: any) => {
        this.getAccountList = getAccount;
      },
      error: (error: any) => console.error(error),
    });
  }
  private getDueInvoicesByPartner(id: number) {
    this.itemService.getDueInvoicesByPartner(id, {})?.subscribe({
      next: (invoiceData: any) => {
        if (this.globalInvoiceId) {
          this.partnerInvoiceData = invoiceData.filter(
            (item: any) => item.id === this.globalInvoiceId
            
          );
          
        } else {
          this.partnerInvoiceData = invoiceData;
        }
        this.calculateSplits(false);

        if(this.invoiceId) {
          this.partnerInvoiceData.forEach((invoice: any) => {
            this.splitAmounts[invoice.id] = 0;
          });
          
          // Update splitAmounts with paymentInfo amounts
          this.paymentInfo.forEach((payment) => {
            if (this.splitAmounts.hasOwnProperty(payment.refId)) {
              this.splitAmounts[payment.refId] = payment.amount;
              this.totalSplitAmount += payment.amount;
            }
          });
        }
        this.fullAmount = this.partnerInvoiceData?.reduce(
          (total: number, item: { balance: number }) => total + item.balance,
          0
        );
      },
      error: (error: any) => console.error(error),
    });
  }


  

  onInput(index: number, event: any, balance: number): void {
    // console.log("hitted");
    const inputValue = parseFloat(event.target.value);
    if (inputValue > balance) {
      // console.log({index, inputValue, balance})
      event.target.value = balance; // Set the input value to the balance
      this.splitAmounts[index] = balance; // Update the model
    }
    this.totalSplitAmount = this.splitAmounts.reduce((acc, curr) => +acc + +curr, 0);
    this.paymentNewItemForm.get('amountReceived')?.setValue(this.totalSplitAmount.toFixed(2));
  }

  calculateSplits(isFullAmount: boolean): void {
    this.partnerInvoiceData.forEach((item: any, index: number) => {
      // console.log({item});
      if(isFullAmount) {
        this.splitAmounts[item?.id] = item.balance;
      } else {
        this.splitAmounts[item?.id] = 0;
      }
    })


  }

  onCustomerSelected(event: MatSelectChange) {
    const selectedCustomerId = event.value;
    
    this.paymentNewItemForm.get('amountReceived')?.setValue('');
    this.paymentNewItemForm.get('isFullAmount')?.setValue(false);
    this.getDueInvoicesByPartner(selectedCustomerId);
  }

  // onFileSelected(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   if (input.files && input.files.length > 0) {
  //     const file = input.files[0];
  //     this.documentFileName = file.name;
  //     this.documentFile = file;
  //   }
  // }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
  
      // Allowed file types (MIME types)
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/gif',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ];
  
      // Check if the file type is allowed
      if (allowedTypes.includes(file.type)) {
        // File type is allowed, proceed with file selection
        this.documentFileName = file.name;
        this.documentFile = file;
      } else {
        // File type is not allowed, reset the input and show an error message
        this.resetFileSelection();
        this.toastr.error('This file type is not allowed.');
      }
    } else {
      // No file was selected, reset file selection
      this.resetFileSelection();
    }
  }
  
  
  // Helper function to reset file input
  private resetFileSelection(): void {
    this.documentFileName = undefined;
    this.documentFile = undefined;
  
    // Reset the file input field
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Clears the file input field
    }
  }
  
  clearDocument() {
    this.documentFile = null;
    this.documentFileName = null;
  }

  toggleFullAmount(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.paymentNewItemForm.get('amountReceived')?.setValue(this.fullAmount.toFixed(2));
      this.calculateSplits(true);
      this.totalSplitAmount = this.fullAmount;
    } else {
      this.paymentNewItemForm.get('amountReceived')?.setValue('');
      this.calculateSplits(false);
      this.totalSplitAmount = 0;
    }
  }

  onPaymentMadeChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    if(this.fullAmount < parseFloat(inputValue)){
      this.paymentNewItemForm.get('amountReceived')?.setValue(this.fullAmount.toFixed(2));
      this.calculatePriority(this.fullAmount);
      this.toastr.warning('Cant exceed full amount');
    } else {
      this.calculatePriority(+inputValue);
    }  
  }

  calculatePriority(amount: number) {
    this.partnerInvoiceData.forEach((item: any, index: number) => {
      // console.log(item.balance, amount)
      if(amount > 0) {
        if(amount >= item.balance ) {
          this.splitAmounts[item?.id] = item.balance;
          amount = amount - this.splitAmounts[item?.id]; 
        } else {
          this.splitAmounts[item?.id] = +amount.toFixed(2);
          amount = 0;
        }
      } else {
        this.splitAmounts[item?.id] = 0;
      }
    });
    this.totalSplitAmount = this.splitAmounts.reduce((acc, curr) => +acc + +curr, 0);
  }

  // calculateSplits(): void {
  //   const amountReceived =
  //     this.paymentNewItemForm.get('amountReceived')?.value || 0;
  //   const totalEntries = this.partnerInvoiceData?.length;
  //   const splitAmount = totalEntries > 0 ? amountReceived / totalEntries : 0;

  //   // Populate the splitAmounts array
  //   this.splitAmounts = Array(totalEntries).fill(splitAmount);

  //   // Calculate the total of split amounts
  //   this.totalSplitAmount = this.splitAmounts.reduce(
  //     (acc, curr) => acc + curr,
  //     0
  //   );
  // }

  // calculateSplits(): void {
  //   const amountReceived =
  //     this.paymentNewItemForm.get('amountReceived')?.value || 0;
  //   const totalEntries = this.partnerInvoiceData?.length || 0;
  //   if (totalEntries > 0) {
  //     const totalBalance = this.partnerInvoiceData.reduce(
  //       (sum: any, invoice: any) => sum + (invoice.balance || 0),
  //       0
  //     );
  //     this.splitAmounts = this.partnerInvoiceData.map((invoice: any) => {
  //       const balance = invoice.balance || 0;
  //       return totalBalance > 0 ? (balance / totalBalance) * amountReceived : 0;
  //     });
  //         // Calculate the total of split amounts
  //     this.totalSplitAmount = this.splitAmounts.reduce(
  //     (acc, curr) => acc + curr,
  //     0
  //   );
  //   } else {
  //     this.splitAmounts = [];
  //   }
  // }

  calculateExcessAmount(): number {
    const amountReceived =
      this.paymentNewItemForm.get('amountReceived')?.value || 0;
      return this.fullAmount - amountReceived;
  }

  usedAmount(): number {
    const amountReceived =
      this.paymentNewItemForm.get('amountReceived')?.value || 0;
      
    return amountReceived;
  }

  onCreatePayment(): void {
    if (this.paymentNewItemForm.invalid) {
      this.paymentNewItemForm.markAllAsTouched();
      return;
    }

    this.documentUploadService
      .uploadDocument(this.documentFile, 'payment-received')
      .subscribe({
        next: (url) => {
          if (url) {
            this.documentFileUrl = url?.url;
          }
          const data = this.paymentNewItemForm.value;
          // const invoiceIds = this.partnerInvoiceData?.map(
          //   (item: any) => item?.id
          // );

          let invoiceIds: any = [];

          this.partnerInvoiceData.forEach((item: any, index: number) => {
            if(this.splitAmounts[item?.id] > 0) {
              invoiceIds.push({"invoiceId": item?.id, "amount": +this.splitAmounts[item?.id]})
            }
            
          })

          const itemInputData = {
            partnerId: data?.customerName,
            bankCharge: parseFloat(data?.bankCharges),
            paymentType: this.paymentType.INVOICE,
            amount: parseFloat(data?.amountReceived),
            paymentDate:
              this.datePipe.transform(data?.paymentDate, 'yyyy/MM/dd') || '--',
            paymentNo: data?.payment,
            referenceNo: data?.reference,
            depositId: data?.depositTo,
            paymentMode: data?.paymentMode,
            totalAmount: parseFloat(data?.amountReceived),
            chequeNo: data?.chequeNo,
            chequeDate: data?.chequeDate ? this.datePipe.transform(data?.chequeDate, 'yyyy/MM/dd') : null,
            // receiptUrl: url?.url,
            note: data?.note,
          };

          const createPaymentAttachment = {
            fileUrl: this.documentFileUrl,
            fileName: this.documentFileName,
          };

          const invoiceIDAmountInput = invoiceIds;
          const billIDAmountInput: any = null;
          this.isLoading = true;

          if (data?.id) {
            //updateing
            const itemInputUpdateData = {
              id: data?.id,
              ...itemInputData,
            };

            const createPaymentUpdateAttachment = {
              id: this.documentFileId ?? null,
              ...createPaymentAttachment,
            };

            this.itemService
              .updatePayment(
                itemInputUpdateData,
                createPaymentUpdateAttachment,
                invoiceIDAmountInput,
                billIDAmountInput
              )
              .subscribe({
                next: () => {
                  this.isLoading = false;
                  this.generatePDF(data?.id, 'Invoice');
                  this.toastr.success('Payment Received updated successfully.');
                  if (this.bankId) {
                    this.createTransaction();
                  } else {
                    const This = this;
                    setTimeout(() => {
                      This.router.navigate(['/payment-received']);
                    }, 1000);
                  }
                },
                error: (error) => {
                  this.isLoading = false;
                  this.toastr.error(error.message || 'Failed to update item.');
                },
              });
          } else {
            this.itemService
              .createPayment(
                itemInputData,
                createPaymentAttachment,
                invoiceIDAmountInput,
                billIDAmountInput
              )
              .subscribe({
                next: (data: any) => {
                  // console.log({data})
                  this.isLoading = false;
                  this.generatePDF(data?.data?.createPayment?.id, 'Invoice');
                  this.toastr.success('Payment Received Created successfully.');
                  // if (this.bankId) {
                    this.createTransaction();
                  // } else {
                    const This = this;
                    setTimeout(() => {
                      This.router.navigate(['/payment-received']);
                    }, 1000);
                  // }
                },
                error: (error) => {
                  this.isLoading = false;
                  this.toastr.error(error.message || 'Failed to update item.');
                },
              });
          }
        },
        error: (error) => {
          this.toastr.error('upload failed', error);
        },
      });
  }

  generatePDF(id: number, type: string) {
    this.itemService.generatePaymentPdf(id, type).subscribe({
      next: () => {},
      error: (error: any) =>
        console.error('Error fetching employee types:', error),
    });
  }

  createTransaction() {
    const transactionData = {
      date: this.datePipe.transform(
        this.paymentNewItemForm.value.paymentDate,
        'yyyy-MM-dd'
      ),
      transactionStatus: 'Manual',
      referenceNumber: this.paymentNewItemForm.value.payment,
      amount: Number(this.paymentNewItemForm.value.amountReceived),
      description: this.paymentNewItemForm.value.note,
      flow: 'Credit',
      offsetAccountId: this.bankId ? Number(this.bankId) : this.paymentNewItemForm.value.depositTo,
      transactionCategoryId: Number(this.categoryId),
    };

    this.bankingService.createTransaction(transactionData).subscribe({
      next: (data) => {
        if(this.bankId){
          this.router.navigate([`/banking/banking-details/${this.bankId}`]);
        } else{
          return;
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      },
    });
  }
}
