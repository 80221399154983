import {gql} from 'apollo-angular';

export const CREATE_INVENTORY_ITEM = gql `
  mutation createItem($createItemInput: CreateItemInput!) {
    createItem(createItemInput: $createItemInput) {
      id
      itemType
      skuCode
      imageUrl
      isSalesActive
      isPurchaseActive
      quantity
      unit
      itemName
      sellingPrice
      salesAccount
      salesDescription
      status
      notes
      costPrice
      purchaseAccount
      purchaseDescription
      vendorId
      salesAccountId
      purchaseAccountId
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const UPDATE_ITEM = gql `
  mutation updateItem($updateItemInput: UpdateItemInput!) {
    updateItem(updateItemInput: $updateItemInput) {
      id
      itemType
      skuCode
      imageUrl
      isSalesActive
      isPurchaseActive
      quantity
      unit
      itemName
      sellingPrice
      salesAccount
      salesDescription
      status
      notes
      costPrice
      purchaseAccount
      purchaseDescription
      vendorId
      vendorData {
      id
      partnerType
      customerType
      displayName
      paymentTerm
      companyName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      primaryContactId
    }
      salesAccountId
      salesAccountData {
        id
        accountType
        accountName
        accountCode
      }
      purchaseAccountId
      purchaseAccountData {
        id
        accountType
        accountName
        accountCode
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const REMOVE_ITEM_BY_ID = gql `
  mutation removeItem($id: Int!) {
    removeItem(id: $id)
  }
`;
