import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { filter, map } from 'rxjs';
import { AssetService } from '../../../../features/assets/services/asset.service';

@Component({
  selector: 'app-assets',
  standalone: true,
  imports: [CommonModule, AgGridAngular],
  templateUrl: './assets.component.html',
  styleUrl: '../../employee-details.component.scss',
})
export class AssetsComponent implements OnInit {
  public themeClass: string = 'ag-theme-quartz';
  public loading: boolean = true;
  rowData: any[] = [];
  search: any;

  public columnDefs: ColDef[] = [
    { headerName: 'Asset Name', field: 'assetName' },
    { headerName: 'Asset ID', field: 'assetId', flex: 1 },
    { headerName: 'Assigned Date', field: 'purchaseDate', flex: 1 },
    { headerName: 'Condition', field: 'condition', flex: 1 },
    {
      headerName: 'Status',
      field: 'assetStatus',
      flex: 1,
      cellRenderer: (params: any) => {
        const status = params.data.assetStatus;
        const statusClass =
          status === 'Active'
            ? 'active'
            : status === 'Available'
            ? 'available'
            : status === 'Under Maintenance'
            ? 'under-maintenance'
            : status === 'Transferred'
            ? 'transferred'
            : '';
        return `<span class="${statusClass}">${status}</span>`;
      },
    },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public paginationPageSize = 25;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];

  constructor(private assetService: AssetService) {}

  ngOnInit(): void {
    this.fetchAssetsForEmployee();
  }

  fetchAssetsForEmployee() {
    this.loading = true;
    this.assetService.getAssetsForEmployee().subscribe({
      next: (assets) => {
        this.rowData = assets.map((asset: any) => {
          return {
            assetName: asset.assetName,
            assetId: asset.id,
            purchaseDate: asset.purchaseDate,
            condition: asset.condition,
            assetStatus: asset.assetStatus,
          };
        });
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching assets:', err);
        this.loading = false;
      },
    });
  }
}
