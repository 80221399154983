import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ACCOUNTS, CHART_ACCOUNTS, CHART_ACCOUNTS_BY_ID } from '../graphql/queries/chart-accounts.query';
import { CREATE_CHART_ACCOUNTS, REMOVE_CHART_ACCOUNTS, UPDATE_CHART_ACCOUNTS } from '../graphql/mutations/chart-accounts.mutation';

@Injectable({
  providedIn: 'root',
})
export class ChartAccountsService {
  constructor(private apollo: Apollo) {}

  chartOfAccounts(accountType: any, search: any, parentAccountId: any, isSubAccount: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: CHART_ACCOUNTS,
        variables: { 
            accountType,
            search,
            parentAccountId,
            isSubAccount
         },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.chartOfAccounts || [])
      );
  }

  chartOfAccountsById(id:any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: CHART_ACCOUNTS_BY_ID,
        variables: { 
            id
         },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.chartOfAccount || [])
      );
  }

  fetchAccounts(accountType: any, search: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: ACCOUNTS,
        variables: { 
            accountType,
            search
         },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.accounts || [])
      );
  }

  createChartAccounts(createChartOfAccountInput: any, parentAccountId: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate({
        mutation: CREATE_CHART_ACCOUNTS,
        variables: { 
          accountType: createChartOfAccountInput.accountType,
          accountName: createChartOfAccountInput.accountName,
          parentAccountId,
          accountCode: createChartOfAccountInput.accountCode,
          description: createChartOfAccountInput.description,
          isSubAccount: createChartOfAccountInput.isSubAccount
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((result: any) => result.data.createChartOfAccount || [])
      );
  }

  updateChartOfAccount(id: any, updateChartOfAccountInput: any, parentAccountId: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate({
        mutation: UPDATE_CHART_ACCOUNTS,
        variables: { 
          id,
          accountType: updateChartOfAccountInput.accountType,
          accountName: updateChartOfAccountInput.accountName,
          parentAccountId,
          accountCode: updateChartOfAccountInput.accountCode,
          description: updateChartOfAccountInput.description,
          isSubAccount: updateChartOfAccountInput.isSubAccount
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((result: any) => result.data.updateChartOfAccount || [])
      );
  }

  removeChartOfAccount(id: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate({
        mutation: REMOVE_CHART_ACCOUNTS,
        variables: { 
          id
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((result: any) => result.data.removeChartOfAccount || [])
      );
  }
  

}
