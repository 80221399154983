import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';

@Component({
  selector: 'app-leave-policy',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    AgGridAngular
  ],
  templateUrl: './leave-policy.component.html',
  styleUrl: './leave-policy.component.scss'
})
export class LeavePolicyComponent {

  constructor(private router: Router) { }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  rowData = [
    {
      name: "Darlene Robertson",
      id: "MU0230021",
      designation: "Project Manager",
      date: "26/05/2024",
      status: "Probation",

    },
    {
      name: "Floyd Miles",
      id: "MU0230235",
      designation: "Site Supervisor",
      date: "12/02/2024",
      status: "Confirmed",
    },

  ];

  columnDefs = [

    { headerName: 'Employee Name', field: 'name', flex: 1 },
    { headerName: 'Employee ID', field: 'id', flex: 1 },
    { headerName: 'Designation', field: 'designation', flex: 1 },
    { headerName: 'Joining Date', field: 'date', flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      cellRenderer: (params: any) => {
        const status = params.data.status;
        const statusClass = status === "Probation" ? 'on-leave' : 'working'; // Apply class if "On Leave"
        return `<span class="${statusClass}">${status}</span>`;
      }
    },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

}
