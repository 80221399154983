<div class="tab-inner-content">
  <h2>Sales by Customer</h2>
  <h5>
    From {{ startDate | date : "MM/d/YYYY" }} to
    {{ endDate | date : "MM/d/YYYY" }}
  </h5>
  <table>
    <tr>
      <th>Customer Name</th>
      <th>Invoice Count</th>
      <th>Sales</th>
      <th>Total</th>
    </tr>
    <tr *ngFor="let item of salesByCustomReport.salesByCustomer" class="child">
      <td>{{ item.partnerName }}</td>
      <td>{{ item.totalInvoices }}</td>
      <td>{{ item.totalSales }}</td>
    </tr>
    <tr class="sub-total">
      <td>Total Invoice Count</td>
      <td></td>
      <td></td>
      <td>{{ salesByCustomReport?.totalInvoiceCount }}</td>
    </tr>
    <tr class="sub-total">
      <td>Total Sale Amount</td>
      <td></td>
      <td></td>
      <td>{{ salesByCustomReport?.totalSalesAmount }}</td>
    </tr>
  </table>
</div>
