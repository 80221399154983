<mat-dialog-content>

    <section class="asset-add-assets-wrapper">
        <form [formGroup]="assetsForm" (ngSubmit)="onSubmit()">
            <h2 class="cmn-popup-title">{{ assetId ? 'Edit Asset' : 'Add Asset' }}</h2>
            <button class="cmn-close" mat-dialog-close></button>


            <div class="form-wrapper grid-form-wrapper">
               
               
                <div class="">
                    <mat-form-field>
                        <input matInput placeholder="Asset Name" formControlName="name" />
                    </mat-form-field>
                    <mat-error *ngIf="assetsForm.get('name')!.touched && 
                    assetsForm.get('name')!.hasError('required')">
                        *Asset name isrequired.
                    </mat-error>
                </div>


                <div>
                    <mat-form-field>
                        <mat-select placeholder="Type" formControlName="type">
                            <mat-option *ngFor="let type of assetTypes" [value]="type">
                                {{ type }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="assetsForm.get('type')!.touched && 
                    assetsForm.get('type')!.hasError('required')">
                        *Asset type isrequired.
                    </mat-error>
                </div>

                <!-- <div>
                <mat-form-field>
                    <mat-select placeholder="Category">
                        <mat-option value="Single">Item 1</mat-option>
                        <mat-option value="Married">Item 2</mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->


                <div *ngIf="assetId">
                    <mat-form-field>
                        <mat-select placeholder="Status" formControlName="status">
                            <mat-option *ngFor="let status of assetStatuses" [value]="status">
                                {{ status }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="assetsForm.get('status')!.touched && 
                    assetsForm.get('status')!.hasError('required')">
                        *Asset status isrequired.
                    </mat-error>
                </div>

                <!-- <div class="">
                    <mat-form-field>
                        <input matInput placeholder="Hourly Rate" formControlName="purchaseCost" type="number" />
                    </mat-form-field>
                    <mat-error *ngIf="assetsForm.get('purchaseCost')!.touched && 
                    assetsForm.get('purchaseCost')!.hasError('required')">
                        *Hourly Rate is required.
                    </mat-error>
                </div> -->
                <div class="">
                    <mat-form-field>
                        <input matInput placeholder="Serial/Hardware Number" formControlName="serialNumber" />
                    </mat-form-field>
                    <mat-error *ngIf="assetsForm.get('serialNumber')!.touched && 
                    assetsForm.get('serialNumber')!.hasError('required')">
                        *Serial number isrequired.
                    </mat-error>
                </div>

                <div>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker2" placeholder="Purchase Date"
                            formControlName="purchaseDate"  [max]="today" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>

                    </mat-form-field>
                    <mat-error *ngIf="assetsForm.get('purchaseDate')!.touched && 
                    assetsForm.get('purchaseDate')!.hasError('required')">
                        *Purchase date isrequired.
                    </mat-error>
                </div>


                <div>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker1" placeholder="Warranty Expiry Date"
                            formControlName="warrantyExpiryDate" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>

                    </mat-form-field>
                    <mat-error *ngIf="assetsForm.get('warrantyExpiryDate')!.touched && 
                    assetsForm.get('warrantyExpiryDate')!.hasError('required')">
                        *Warranty Expiry Date is required.
                    </mat-error>
                </div>




                <!-- <div class="span-2">
                <mat-form-field>
                    <input matInput placeholder="Serial/Hardware Number" />
                </mat-form-field>
            </div> -->
                <div>
                    <mat-form-field>
                        <mat-select placeholder="Branch" formControlName="branchId" >
                            <mat-option *ngFor="let branch of allBranches" [value]="branch.id" >
                                {{ branch.branchName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="assetsForm.get('branchId')!.touched && 
                    assetsForm.get('branchId')!.hasError('required')">
                        *Branch is required.
                    </mat-error>
                </div>


                <!-- <div>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker1" placeholder="Expiry Date" />
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>

                </mat-form-field>
            </div> -->

                <div>
                    <mat-form-field>
                        <mat-select placeholder="Condition" formControlName="condition">
                            <mat-option *ngFor="let condition of assetConditions" [value]="condition">
                                {{ condition }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="assetsForm.get('condition')!.touched && 
                    assetsForm.get('condition')!.hasError('required')">
                        *Condition is required.
                    </mat-error>
                </div>


                <div *ngIf="assetsForm.get('type')?.value === 'Vehicle'">
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker5" placeholder="RC Expiry Date "
                            formControlName="rcExpiryDate" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker5"></mat-datepicker-toggle>
                        <mat-datepicker #picker5></mat-datepicker>

                    </mat-form-field>
                </div>

            

                <div *ngIf="assetsForm.get('type')?.value === 'Vehicle'">
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker3" placeholder="Insurance Expiry Date "
                            formControlName="insuranceExpiryDate" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>

                    </mat-form-field>
                </div>

                <div *ngIf="assetsForm.get('type')?.value === 'Vehicle'">
                    <!-- <mat-checkbox (change)="toggleVehicleIsPrivate($event.checked)">Make this a sub-account</mat-checkbox> -->
                      
                      <!-- New Is Private Checkbox -->
                      <mat-checkbox formControlName="isPrivate">Is Private?</mat-checkbox>
                      
                </div>

                <!-- <div *ngIf="assetsForm.get('type')?.value === 'Vehicle'">
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker4" placeholder="Polution Expiry Date"
                            formControlName="polutionExpiryDate" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker4"></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>

                    </mat-form-field>
                </div> -->


            </div>



            <div class="btn-wrapper">


                <button class="cmn-next-btn" [disabled]="loading" >
                    <span *ngIf="!loading">Submit</span>
                    <span *ngIf="loading">Loading...</span>
                  </button>

            </div>
        </form>
    </section>

</mat-dialog-content>