import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CreateClientDialog } from '../invoice-new/dialog-components/create-customer-dialog/create-client-dialog.component';
import {
  emailValidator,
  generateProjectCode,
  maxLimitValidator,
  noHtmlSpecialChars,
  nonNegativeNumber,
  noSpaceAtStartOrEnd,
  percentageValidator,
} from '../../helpers/helper';
import { ProjectService } from '../../features/projects/services/projects.service';

@Component({
  selector: 'app-project-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
  ],
  templateUrl: './project-new.component.html',
  styleUrls: ['./project-new.component.scss'],
})
export class ProjectNewComponent {
  projectForm: FormGroup;
  partnersData: any[] = [];
  employeeData: any[] = [];
  private searchSubject = new Subject<string>();
  private employeeSearch = new Subject<string>();
  readonly dialog = inject(MatDialog);
  branchesList: any[] = [];
  loading: boolean = false;
  projectId: number | null = null;
  billingSelected: string = 'FixedCostForProject';
  actualCost: any;

  constructor(
    private toastr: ToastrService,
    private invoiceService: InvoiceService,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService
  ) {
    this.projectForm = this.createProjectForm();
  }

  ngOnInit() {
    this.fetchPartners();
    this.loadEmployeeTypeData('');
    this.loadBranches();
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (!id) {
        this.addUsersRow();
        this.addProjectTasksRow();
      } else {
        this.projectId = +id;
        this.fetchSingleProject(+id);
      }
    });

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchPartners(searchTerm);
      });
    this.employeeSearch
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.loadEmployeeTypeData(searchTerm);
      });

    this.projectForm.get('threshold')?.valueChanges.subscribe((value) => {
      this.findSum();
    });

    this.projectForm.get('projectCost')?.valueChanges.subscribe((value) => {
      this.findSum();
    });
  }

  /** Create project form with all validators */
  private createProjectForm(): FormGroup {
    return new FormGroup({
      projName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        noHtmlSpecialChars,
        noSpaceAtStartOrEnd,
      ]),
      projCode: new FormControl(
        this.projectId ? '' : generateProjectCode('PR-'),
        [Validators.required]
      ),
      customerId: new FormControl('', [Validators.required]),
      billingMethod: new FormControl(this.billingSelected, [
        Validators.required,
      ]),
      description: new FormControl('', [noHtmlSpecialChars]),
      projectCost: new FormControl('', [
        Validators.required,
        nonNegativeNumber,
        maxLimitValidator(100000000),
      ]),
      costCenter: new FormControl('', [Validators.required]),
      costBudget: new FormControl(0, [
        Validators.required,
        nonNegativeNumber,
        maxLimitValidator(100000000),
        (control) => {
          const projectCost = this.projectForm?.get('projectCost')?.value;
          return +control.value <= +projectCost
            ? null
            : { costBudgetExceedsProjectCost: true };
        },
      ]),
      revenuBudget: new FormControl(0, [
        Validators.required,
        nonNegativeNumber, // Custom validator
        maxLimitValidator(100000000), // 100 crore
      ]),
      threshold: new FormControl(70.0, [
        Validators.required,
        percentageValidator, // Custom validator
      ]),
      users: new FormArray([]),
      projectTasks: new FormArray([]),
    });
  }

  private fetchSingleProject(id: number) {
    this.projectService.getOneProject(id).subscribe({
      next: (details) => {
        if (details?.projectTasks?.length) {
          details?.projectTasks?.map((el: any) =>
            this.updateProjectTasksRowF(el)
          );
        } else {
          this.addProjectTasksRow();
        }
        if (details?.projectResources?.length) {
          details?.projectResources?.map((el: any) => {
            this.updateUsersRow(el);
          });
        } else {
          this.addUsersRow();
        }
        this.projectForm.patchValue({
          projName: details.projectName,
          projCode: details.projectCode,
          customerId: details.partnerId,
          billingMethod: details.billingMethod,
          description: details?.description,
          projectCost: details?.totalProjectCost,
          costCenter: details?.costCenterId,
          costBudget: details?.costBudget,
          revenuBudget: details?.revenueBudget,
          threshold: details?.threshold,
        });
      },
      error: (error) => console.error('Error fetching employee types:', error),
    });
  }

  get users(): FormArray {
    return this.projectForm.get('users') as FormArray;
  }

  findSum() {
    const projectCost = this.projectForm.get('projectCost')?.value;
    const threshold = this.projectForm.get('threshold')?.value;
    this.actualCost = ((projectCost * threshold) / 100).toFixed(2);
  }

  addUsersRow() {
    const usersGroup = new FormGroup({
      user: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, emailValidator]),
    });
    this.users.push(usersGroup);
  }

  updateUsersRow(data: any) {
    const usersGroup = new FormGroup({
      id: new FormControl(data?.id),
      user: new FormControl(data?.employeeId, [Validators.required]),
      email: new FormControl(data?.employeeData?.email, [
        Validators.required,
        emailValidator,
      ]),
    });
    this.users.push(usersGroup);
  }

  removeUsersRow(index: number) {
    const userGroup = this.users.at(index) as FormGroup;
    const values = userGroup?.value;
    if (values?.id) {
      this.projectService.deleteProjectResource(values?.id).subscribe({
        next: (response) => {
          this.users.removeAt(index);
        },
        error: (error) => {
          this.toastr.error(error.message || 'Failed to remove user');
          this.loading = false;
        },
      });
    } else {
      this.users.removeAt(index);
    }
  }

  get projectTasks(): FormArray {
    return this.projectForm.get('projectTasks') as FormArray;
  }

  addProjectTasksRow() {
    const projectTasksGroup = new FormGroup({
      milestone: new FormControl('', [
        Validators.required,
        Validators.maxLength(500),
      ]),
      description: new FormControl('', [noHtmlSpecialChars]),
    });
    this.projectTasks.push(projectTasksGroup);
  }

  updateProjectTasksRowF(data: any) {
    const projectTasksGroup = new FormGroup({
      id: new FormControl(data?.id),
      milestone: new FormControl(data?.taskName, [
        Validators.required,
        Validators.maxLength(500),
      ]),
      description: new FormControl(data?.description, [noHtmlSpecialChars]),
    });
    this.projectTasks.push(projectTasksGroup);
  }

  removeProjectTasksRow(index: number) {
    const taskGroup = this.projectTasks.at(index) as FormGroup;
    const values = taskGroup?.value;
    if (values?.id) {
      this.projectService.deleteProjectTask(values?.id).subscribe({
        next: () => {
          this.projectTasks.removeAt(index);
        },
        error: (error) => {
          this.toastr.error(error.message || 'Failed to remove user');
          this.loading = false;
        },
      });
    } else {
      this.projectTasks.removeAt(index);
    }
  }

  private fetchPartners(search: string = '') {
    this.invoiceService.fetchClients(search).subscribe({
      next: (partners) => {
        this.partnersData = partners
          .filter((data: any) => data.partnerType === 'Customer')
          .map((data: any) => ({
            id: data.id || '--',
            name: data.displayName || '--',
            addresses: data.addresses || [],
          }));
        console.log('partnersDatapartnersDatapartnersData', this.partnersData);
      },
      error: (error) => console.error(error),
    });
  }

  private loadEmployeeTypeData(search: string) {
    this.projectService.getEmployees(search ?? '', {}).subscribe({
      next: (employeeTypes) => {
        this.employeeData = employeeTypes;
      },
      error: (error) => console.error('Error fetching employee types:', error),
    });
  }

  private loadBranches() {
    this.projectService.getBranches().subscribe({
      next: (branches) => {
        this.branchesList = branches;
      },
      error: (error) => console.error('Error fetching employee types:', error),
    });
  }

  openCreateClientDialog() {
    const dialogRef = this.dialog.open(CreateClientDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.fetchPartners();
      }
    });
  }

  onCustomerSelected(event: MatSelectChange) {
    const selectedCustomerId = event.value;
    this.projectForm.get('customerId')?.setValue(selectedCustomerId);
  }

  onEployeeChange(event: MatSelectChange, index: number) {
    const selectedEmployyeId = event.value;
    const usersList = this.projectForm.get('users') as FormArray;
    const userGroup = this.users.at(index) as FormGroup;
    const isExist = usersList?.value?.filter(
      (el: any) => el?.user === +selectedEmployyeId
    );
    if (isExist?.length > 1) {
      const userControl = userGroup.get('user');
      userControl?.setErrors({ customError: 'User is already taken' });
      return;
    } else {
      const selectedUser = this.employeeData?.find(
        (el: any) => el?.id === +selectedEmployyeId
      );
      // Get the specific FormGroup
      if (userGroup) {
        userGroup.patchValue({
          user: selectedEmployyeId,
          email: selectedUser?.email,
        });
      }
    }
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchSubject.next(input.value);
  }

  onSearchEmployee(event: Event) {
    const input = event.target as HTMLInputElement;
    this.employeeSearch.next(input.value);
  }

  onSubmit() {
    console.log('this.projectFormthis.projectForm', this.projectForm);

    if (this.projectForm.invalid) {
      this.projectForm.markAllAsTouched();
      // this.validationError = true;
      this.toastr.warning('Please fill mandatory fields!', 'Warning');
      return;
    }
    const values = this.projectForm?.value;
    let projectResources: any;
    let projectTasks: any;
    let createProjectModuleInput: any = {
      projectCode: values?.projCode ?? '',
      projectName: values?.projName ?? '',
      totalProjectCost: values?.projectCost ?? '',
      description: values?.description ?? '',
      // budget: values?.projName ?? '',
      threshold: values?.threshold ? +values.threshold : '',
      billingMethod: values?.billingMethod ?? '',
      costBudget: values?.costBudget ?? '',
      revenueBudget: values?.revenuBudget ?? '',
      branchId: values?.costCenter ?? '',
      costCenterId: values?.costCenter ?? '',
      partnerId: values?.customerId ?? '',
    };
    if (!this.projectId) {
      if (values.users?.length) {
        projectResources = values.users?.map((el: any) => ({
          employeeId: el?.user,
        }));
      }
      if (values.projectTasks?.length) {
        projectTasks = values?.projectTasks?.map((el: any) => ({
          taskName: el?.milestone,
          description: el?.description,
        }));
      }
    }
    if (this.projectId) {
      createProjectModuleInput = {
        id: +this.projectId,
        ...createProjectModuleInput,
      };
      let userWithId: any = [];
      let userWithOutId: any = [];
      let taskWithId: any = [];
      let taskWithoutId: any = [];

      values.users?.map((el: any) => {
        if (el?.id) {
          const obj = {
            id: el?.id,
            employeeId: el?.user,
          };
          userWithId = [...userWithId, obj];
        } else {
          const obj = {
            projectId: this.projectId,
            employeeId: el?.user,
          };
          userWithOutId = [...userWithOutId, obj];
        }
      });

      values?.projectTasks?.map((el: any) => {
        if (el?.id) {
          const obj = {
            id: el?.id,
            taskName: el?.milestone,
            description: el?.description,
          };
          taskWithId = [...taskWithId, obj];
        } else {
          const obj = {
            projectId: this.projectId,
            taskName: el?.milestone,
            description: el?.description,
          };
          taskWithoutId = [...taskWithoutId, obj];
        }
      });

      if (userWithId?.length) {
        projectResources = userWithId;
      }

      if (taskWithId?.length) {
        projectTasks = taskWithId;
      }
      if (taskWithoutId?.length) {
        this.projectService.createProjectResource(userWithOutId).subscribe({
          next: (response) => {
            console.log('responseresponse', response);
          },
          error: (error) => {
            this.toastr.error(error.message || 'Resource creation failed');
            this.loading = false;
          },
        });
      }
      if (taskWithoutId?.length) {
        this.projectService.createProjectTasks(taskWithoutId).subscribe({
          next: (response) => {
            console.log('responseresponse', response);
          },
          error: (error) => {
            this.toastr.error(error.message || 'Task creation failed');
            this.loading = false;
          },
        });
      }
      this.projectService
        .updateProject(createProjectModuleInput, projectTasks, projectResources)
        .subscribe({
          next: (response) => {
            this.toastr.success('Project Update successfully');
            this.router.navigate(['/projects']);
            this.loading = false;
          },
          error: (error) => {
            this.toastr.error(error.message || 'Project creation failed.');
            this.loading = false;
          },
        });
    } else {
      this.projectService
        .createProject(createProjectModuleInput, projectTasks, projectResources)
        .subscribe({
          next: (response) => {
            console.log('responseresponse', response);
            this.toastr.success('Project created successfully');
            this.router.navigate(['/projects']);
            this.loading = false;
          },
          error: (error) => {
            this.toastr.error(error.message || 'Project creation failed.');
            this.loading = false;
          },
        });
    }
  }

  onClearForm() {
    this.projectForm.reset();
  }

  checkUserExistOrNot(id: any) {
    const usersList = this.projectForm.get('users') as FormArray;
    const isExist = usersList?.value?.find((el: any) => el.user == id);
    if (isExist) {
      return true;
    }
    return false;
  }
}
