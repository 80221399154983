import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CustomerService } from '../../../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-overview',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule
  ],
  templateUrl: './overview.component.html',
  styleUrl: '../../client-details.component.scss'
})
export class OverviewComponent {
  partnerDetails: any;
  customerId: any;

  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ){}

  ngOnInit(){
    this.route.paramMap.subscribe(params => {
      this.customerId = params.get('id');
      if (this.customerId) {
        this.partnerDetailById(Number(this.customerId));
        }
      });
  }

  
  partnerDetailById(id: any) {
    this.customerService.partnerDetails(id, '', '').subscribe({
      next: (data: any) => {
        this.partnerDetails = data;
      },
      error: (error) => {
        this.toastr.error(error?.message);
      },
    });
  }

}
