import { Component, Inject, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';
import { CreditDebitNoteService } from '../../features/credit-debit-note/services/credit-debit-note.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { fetch_PDF } from '../../helpers/helper';

@Component({
  selector: 'app-credit-note-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    ReactiveFormsModule
  ],
  templateUrl: './credit-note-details.component.html',
  styleUrl: './credit-note-details.component.scss',
})
export class CreditNoteDetailsComponent {
  allItems: any[] = [];
  itemId: any;
  itemData: any = {};
  commentForm : FormGroup;
  comment: any;
  private searchSubject = new Subject<string>();
  journalData: any;

  constructor(
    private router: Router,
    private itemService: CreditDebitNoteService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb : FormBuilder
  ) {}

  ngOnInit(): void {
    this.fetchNotes();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchNotes(searchTerm);
      });

    this.route.queryParams.subscribe((params) => {
      const id = params['id'];
      this.itemId = +id;
    });

    this.commentForm = this.fb.group({comment: ['', Validators.required]});
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  navigateToEdit(path: string) {
    const selectedIteam = this.allItems[this.selectedTabIndex];
    const itemId = selectedIteam.id;
    this.router.navigate([path], { queryParams: { id: itemId } });
  }

  openCreditNoteApplyCreditDialog() {
    const selectedIteam = this.allItems[this.selectedTabIndex];
    const itemId = selectedIteam.id;
    const dialogCreditNoteApplyCreditDialogItemRef = this.dialog.open(
      CreditNoteApplyCreditDialog, { data: { noteId: this.itemId }, }
    );
  }

  selectedOption = 'today';

  selectedTabIndex = 0; // Default to the first tab

  selectTab(index: number) {
    this.selectedTabIndex = index;
    this.itemData = this.allItems[index];
    this.journalData = this.itemData?.journalData;
    if (this.itemData) {
      this.router.navigate(['credit-note/credit-note-details'], {
        queryParams: { id: this.itemData.id },
      });
    }
  }
  calculateTotalDebit(entries: any[]): number {
    return entries?.reduce((total, entry) => total + (entry.debit || 0), 0) || 0;
  }
  
  calculateTotalCredit(entries: any[]): number {
    return entries?.reduce((total, entry) => total + (entry.credit || 0), 0) || 0;
  }
  
  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(UploadCreditNoteDialog);
  }

  isDropdownOpen = false;
  isCommentsOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleComments(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
      
    }
    this.isCommentsOpen = !this.isCommentsOpen;
  }

  fetchNotes(search: string = '') {
    this.itemService.fetchNotes('Credit', search).subscribe({
      next: (items) => {
        this.allItems = items;
        if (this.allItems.length > 0) {
          this.selectTab(0);
        }

        if (this.itemId) {
          const targetId = +this.itemId;
          this.selectedTabIndex = this.allItems.findIndex((c: any) => {
            return c.id === targetId;
          });
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
      },
      error: (err) => {
        console.error('Error fetching all items:', err);
      },
    });
  }

  navigateToMail(): void {
    const noteId = this.itemData.id;
    this.router.navigate([`credit-note/credit-note-details/mail`], {
      queryParams: { noteId },
    });
  }

  downloadPdf(): void {
    const noteId = this.itemData.id;
    const type = 'Credit';
    this.itemService.generateNotePdf(noteId, type).subscribe({
      next: (items) => {
        const url = items;
        const fileName = 'Credit-note';
        fetch_PDF(url, fileName);
      },
      error: (err) => {
        this.toastr.error(err.message, 'failed');
      },
    });
  }

  createComment(): void {
    console.log('createComment called');
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee ?. employeeData ?. id;
    const createCommentInput = {
      employeeId: employeeId, 
      refId:this.itemData.id, 
      type: 'CreditNote', 
      activityType: 'Comment', 
      note: this.commentForm.value.comment,
    };
    console.log(this.commentForm.value);
    
    this.itemService.createcomment(createCommentInput).subscribe({
      next: (data) => {
          if (data) {
              this.toastr.success('Comment added successfully!', 'Success');
              this.commentForm.reset();
          }
      },
      error: (error) => {
          console.error(error, 'Error');
      }
  });

  }

  // changestatusnote
  changeStatusNote(): void{
    console.log("change status called");
    
    const noteId = this.itemData.id;
    const status = "Open";
   this.itemService.changestatusnote(noteId,status).subscribe({
    next: (data) => {
      if(data) {
        this.toastr.success('Success');
        this.fetchNotes();
      }
    },
    error: (error) => {
      console.error(error, 'Error');
  }
   })
  }
}


@Component({
  selector: 'upload-credit-note-dialog',
  templateUrl: 'upload-credit-note-dialog.html',
  styleUrls: ['./credit-note-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadCreditNoteDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}

@Component({
  selector: 'credit-note-apply-credit-dialog',
  templateUrl: 'credit-note-apply-credit-dialog.html',
  styleUrls: ['./credit-note-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
})
export class CreditNoteApplyCreditDialog implements OnInit {
  itemId: number;
  noteDetails: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private itemService: CreditDebitNoteService,
  ){}

   
  ngOnInit(): void{
    if(this.data){
      this.itemId = this.data.noteId;
      this.fetchNote();
    }
  }

  fetchNote() {
    this.itemService.fetchNote(this.itemId).subscribe({
      next: (note) => {
        this.noteDetails = note;
      },
      error: (err) =>{
        console.error('Error fetching note:', err); 
      }
    });
  }
}

