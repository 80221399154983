
    <section class="cmn-innerpage-wrapper add-project-inventory">
        <div class="head">
            <h2 class="cmn-inner-heading">Add Inventory</h2>
            <button class="cmn-upload-btn" (click)="openDialog()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload</span>
            </button>
        </div>

        <form>
            <div class="chart-account-form-wrapper">
                <div class="form-wrapper">
                    <label for="">Select Milestone</label>
                    <mat-form-field>
                        <mat-select placeholder="Select">
                            <mat-option value="Single">Single</mat-option>
                            <mat-option value="Married">Married</mat-option>
                            <mat-option value="Divorced">Divorced</mat-option>
                            <mat-option value="Widowed">Widowed</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Select category</label>
                    <mat-form-field>
                        <mat-select placeholder="Select">
                            <mat-option value="Single">Single</mat-option>
                            <mat-option value="Married">Married</mat-option>
                            <mat-option value="Divorced">Divorced</mat-option>
                            <mat-option value="Widowed">Widowed</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Estimated Cost</label>
                    <mat-form-field>
                        <input matInput placeholder="AED" />
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Start Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker1" placeholder="Select date" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">End Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker2" placeholder="Select date" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Remark</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter remark"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="submit-btn-wrapper">
                <button class="cmn-cancel-btn">Cancel</button>
                <button class="cmn-next-btn">Add Resource</button>
            </div>
        </form>
        <section class="project-list-view">
            <div class="list-Items">
                <div class="list-number">
                    <h6>1.</h6>
                </div>
                <div class="">
                    <p>Projects</p>
                    <h6>Dans Interior Desing</h6>
                </div>
                <div class="">
                    <p>Milestone</p>
                    <h6>Interior Painting</h6>
                </div>
                <div class="">
                    <p>Man Power</p>
                    <h6>Sr. Supervisor</h6>
                </div>
                <div class="">
                    <p>Duration</p>
                    <h6>28/08/2024 - 15/09/2024</h6>
                </div>
                <div class="">
                    <p>Expected Amount</p>
                    <h6>AED 5,000 </h6>
                </div>
                <div class="remarks-wrapper">
                    <p>Remarks</p>
                    <h6>Painting for 105 sq.ft on the 2nd floor of the building</h6>
                </div>
                <div class="controls-wrapper">
                    <div>
                        <img src="../../../assets/images/icons/icon-close.svg" alt="">
                    </div>
                    <div>
                        <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="">
                    </div>
                </div>
            </div>
        </section>
    </section>
