
  <section class="cmn-innerpage-wrapper add-employee">
    <div class="employee-tab-wrapper">
      <mat-tab-group
        animationDuration="0ms"
        [(selectedIndex)]="selectedTabIndex"
      >
        <!-- Mat Tab One -->
        <mat-tab [disabled]="!tabStates[0]">
          <ng-template mat-tab-label>
            <h2 class="cmn-tab tab-1">Personal Info</h2>
          </ng-template>
          <app-personal-info
            [employeeData]="employeeData"
            (formSubmit)="collectPersonalInfo($event, 1)"
            [employee]="selectedEmployee"
            [isEditMode]="isEditMode"
          ></app-personal-info>
        </mat-tab>
        <!-- Mat Tab Two -->
        <mat-tab [disabled]="!tabStates[1]">
          <ng-template mat-tab-label>
            <h2 class="cmn-tab tab-2">Professional Info</h2>
          </ng-template>
          <app-professional-info
            [employeeData]="employeeData"
            (formSubmit)="collectProfessionalInfo($event, 2)"
            [employee]="selectedEmployee"
            [isEditMode]="isEditMode"
          ></app-professional-info>
        </mat-tab>
        <!-- Mat Tab Three -->
        <mat-tab [disabled]="!tabStates[2]">
          <ng-template mat-tab-label>
            <h2 class="cmn-tab tab-3">Documents</h2>
          </ng-template>
          <app-documents
            [employeeData]="employeeData"
            (formSubmit)="collectDocuments($event, 3)"
            [employee]="selectedEmployee"
            [isEditMode]="isEditMode"
          ></app-documents>
        </mat-tab>
        <!-- Mat Tab Four -->
        <mat-tab [disabled]="!tabStates[3]">
          <ng-template mat-tab-label>
            <h2 class="cmn-tab tab-4">Branch Access</h2>
          </ng-template>
          <app-branch-access
            [employeeData]="employeeData"
            (formSubmit)="collectBranchAccess($event, 4)"
            [employee]="selectedEmployee"
            [isEditMode]="isEditMode"
          ></app-branch-access>
        </mat-tab>
        <!-- Mat Tab Five -->
        <mat-tab [disabled]="!tabStates[4]">
          <ng-template mat-tab-label>
            <h2 class="cmn-tab tab-5">Salary Details</h2>
          </ng-template>
          <app-salary-details
            [employeeData]="employeeData"
            (formSubmit)="collectSalaryDetails($event, 5)"
            [employee]="selectedEmployee"
            [salaryDetails]="selectedEmployeeSalaryDetails"
            [isEditMode]="isEditMode"
            [currentCTC]="employeeData.currentCTC"
          ></app-salary-details>
        </mat-tab>
      </mat-tab-group>
    </div>
  </section>

