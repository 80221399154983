import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { CustomerService } from '../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { UploadVendorDetailsDialog } from '../vendor-details/vendor-details.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-vendors',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    AgGridAngular,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './vendors.component.html',
  styleUrl: './vendors.component.scss'
})
export class VendorsComponent {
  search: any;
  public rowData: any[] | null = null;
  isDropdownOpen: boolean;
  readonly dialog = inject(MatDialog);
  searchInputControl: FormControl = new FormControl();

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private toastr: ToastrService,
  ) { }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  ngOnInit() {
    this.searchInputControl.valueChanges
    .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.search = value;
        this.fetchPartners();
      });
    this.fetchPartners();
  }

  columnDefs = [
    {
      headerName: 'Vendor Name',
      field: 'name',
      flex: 1,
      cellRenderer: (params: any) => {
        return `
          <div style="display: flex; align-items: center;">
            <span>${params.data.name}</span>
          </div>
        `;
      }
    },
    { headerName: 'Company Name', field: 'company', flex: 1 },
    { headerName: 'Email', field: 'email', flex: 1 },
    { headerName: 'Work Phone', field: 'phone', flex: 1 },
    {
      headerName: 'Amount', field: 'amount', flex: 1,
      cellRenderer: (params: any) => {
        return `
        <div style="display: flex; align-items: center;">
          <span>AED ${params.data.amount}</span>
        </div>
      `;
      }
    },
    {
      headerName: 'Unused Credits', field: 'credits', flex: 1,
      cellRenderer: (params: any) => {
        return `
        <div style="display: flex; align-items: center;">
          <span>AED ${params.data.credits}</span>
        </div>
      `;
      }
    }, 
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const editIcon = document.createElement('img');
        editIcon.src = 'assets/images/icons/icon-edit.svg';
        editIcon.style.cursor = 'pointer';
        editIcon.setAttribute('data-action', 'edit');

        const deleteIcon = document.createElement('img');
        deleteIcon.src = 'assets/images/icons/icon-delete.svg';
        deleteIcon.style.cursor = 'pointer';
        deleteIcon.setAttribute('data-action', 'delete');
        container.appendChild(editIcon);
        container.appendChild(deleteIcon);
        return container;
      },
      floatingFilter: false,
      filter: false,
    },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';


  fetchPartners() {
    this.customerService.fetchPartners(this.search).subscribe({
      next: (data: any) => {
        this.rowData = data
          .map((data: any) => {
            if (data.partnerType === 'Vendor') {
              return {
                id: data?.id || '--',
                name: data?.displayName || '--',
                company: data?.companyName || '--',
                email: data?.email || '--',
                phone: data?.phone || '--',
                amount: data?.totalBillAmount || '0.00',
                credits: data?.credits || '0.00',
              };
            }
            return null; 
          })
          .filter((item: any) => item !== null); 
      },
      error: (error) => {
        this.toastr.error(error?.message);
      },
    });
  }  

  onCellClicked(params: any) {
    const vendorId = params.data?.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.onEdit(vendorId);
      } else if (action === 'delete') {
        this.onConfirmDelete(vendorId);
      }
    } else {
      this.router.navigate([`/vendors/vendor-details/${vendorId}`]);
    }
  }

  
  openDialog() {
    const dialogRef = this.dialog.open(UploadVendorDetailsDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.fetchPartners();
    });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.search = input.value;
    this.fetchPartners();
  }

  toggleDropdown(event: MouseEvent) {
    event.stopPropagation();
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onEdit(id: any) {
    this.router.navigate([`/vendors/vendor-edit/${id}`]);
  }

  onConfirmDelete(id: number): void {
    console.log(id);
    
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onDelete(id);
      }
    });
  }

  onDelete(id: any) {
    this.customerService.removePartner(id).subscribe({
      next: (result) => {
        if (result) {
          this.toastr.success("Vendor deleted successfully", 'Success');
          this.fetchPartners();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }

}

