
    <section class="cmn-innerpage-wrapper local-purchase-details">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)" />
            </div>
            <button class="cmn-upload-btn" (click)="openDialog()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload</span>
            </button>
            <button class="cmn-add-btn" (click)="navigateTo('/local-purchase-order/local-purchase-order-new')">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New  Purchase
            </button>
            <!-- <button class="cmn-filter-btn" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }">
                <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" /> -->
                <!-- <span *ngIf="selectedCount > 0">{{ selectedCount }}</span> -->
            <!-- </button> -->
            <!-- Filter -->
            <!-- <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <form>
                    <h6 class="cmn-popup-heading">
                        Filter <span>Clear All</span>
                    </h6>
                    <div class="filter-inner-content">
                        <h2>Branch</h2>
                        <div class="check-wrapper">
                            <div class="check-box">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" />
                                    test
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="submit">Apply</button>
                    </div>
                </form>
            </div> -->
            <!-- Filter -->
        </div>
        <div class="sub-head">
            <ul>
                <li class="main">All Orders</li>
                <li class="edit" (click)="navigateToSendMail()"><img src="assets/images/icons/icon-send.svg" alt="Mail"> Send to mail</li>
                <li class="edit" (click)="toggleComments($event)"><img src="assets/images/icons/icon-comment.svg" alt="Comment"> Comments & History</li>
                <li class="edit" (click)="onEdit()"><img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit"> Edit</li>
                <li class="edit" (click)="convertToBill(purchaseOderId)"><img src="assets/images/icons/icon-record.svg" alt="Convert"> Convert to Bill</li>
                <li class="edit" (click)="onViewDocument()"> <img src="assets/images/icons/icon-pdf.svg" alt="PDF" />  PDF </li>
            </ul>
             <!-- Comments Dropdown -->
             <div [ngClass]="{ show: isCommentsOpen }" class="common-dropdown filter-position" #dropdown2>
                <button class="close-dropdown" (click)="toggleComments($event)"></button>
                <form [formGroup]="commentForm" (ngSubmit)="onCreateComment()">
                    <h6 class="cmn-popup-heading">
                        Comments
                    </h6>
                    <div class="filter-inner-content">
                        <div class="form-wrapper">
                            <mat-form-field>
                                <textarea matInput placeholder="Add Your Comment Here..." formControlName="comment" ></textarea>
                            </mat-form-field>
                        </div>
                        <div class="btn-wrapper">
                            <button class="cmn-apply-btn" type="submit">Comment</button>
                        </div>
                        <div class="timelinne-wrapper">
                            <!-- ngFor -->
                            <div class="timeline-box" *ngFor="let comment of allComments">
                                <div class="timeline-head">
                                  <ul>
                                    <li>{{ comment?.employee?.firstName || "--" }}</li>
                                    <li>{{ comment?.createdAt | date : "dd MMM yyyy" }}</li>
                                  </ul>
                                  <h3>{{ comment?.createdAt | date : "hh:mm a" }}</h3>
                                </div>
                                <div class="timeline-content">
                                  <h4>{{ comment?.note || "--" }}</h4>
                                </div>
                              </div>
                            <!-- ngFor -->
                        </div>
                    </div>
                </form>
            </div>
            <!-- Comments Dropdown -->
            <div class="mat-menu-wrapper">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="onMarkAsIssued(purchaseOderId)">
                        <span>Mark as issued</span>
                    </button>
                    <button mat-menu-item (click)="onMarkAsCanceled(purchaseOderId)">
                        <span>Mark as canceled</span>
                    </button>
                    <!-- <button mat-menu-item>
                        <span>Credit Available</span>
                    </button>
                    <button mat-menu-item>
                        <span>Create Credit Note</span>
                    </button>
                    <button mat-menu-item>
                        <span>Credit Applied</span>
                    </button>
                    <button mat-menu-item>
                        <span>Clone</span>
                    </button> -->
                    <button mat-menu-item (click)="onDelete(purchaseOderId)">
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </div>
        </div>
        <div class="manual-journal-details-tab-wrapper">
            <div class="tab-left">
                <div class="tab-box-main-wrapper">
                    <button *ngFor="let order of allOrders; let i = index" class="tab-custom-button"
                    [class.active]="selectedTabIndex === i" (click)="selectTab(i)">
                    <div class="inn-box">
                        <h2>{{ order?.vendor?.displayName || "---" }}</h2>
                        <h3> {{ order?.purchaseOrderNo || "---" }} </h3>
                    </div>
                    <div class="inn-box">
                        <h6>{{ order?.billDate ? (order?.billDate | date: 'MM/dd/yyyy') : "---" }}</h6>
                        <h5>AED <span>{{ order?.totalPrice || "---" }}</span></h5>
                    </div>
                    <div class="inn-box">
                        <h4 [ngClass]="{
                            'blue': order?.purchaseStatus === 'Open',
                            'grey': order?.purchaseStatus === 'Draft',
                            'green': order?.purchaseStatus === 'Closed'
                          }">
                            {{ order?.purchaseStatus || "---" }}
                        </h4>
                    </div>
                </button>
                </div>
            </div>
            <div class="manual-journal-details-content-wrapper">
                <div>
                    <div class="chart-tab-content">
                        <div class="tab-inner">
                            <div class="note-header">
                                <div class="left-set">
                                    <h4>{{ orderDetails?.vendor?.addresses?.addressLine1  || "Munawel Group" }}</h4>
                                    <h3>{{ orderDetails?.vendor?.addresses?.addressLine2 || "Dubai Industrial City, Saih Shuaib 4" }}</h3>
                                    <h3>{{ orderDetails?.vendor?.addresses?.country || "United Arab Emirates - 117293" }} </h3>
                                </div>
                                <div class="right-set">
                                    <h2>LOCAL PURCHASE ORDER</h2>
                                    <h3>{{ orderDetails?.purchaseOrderNo || "___" }}</h3>
                                    <h3>{{ orderDetails?.billDate | date: 'dd/MM/YYYY' || "___" }}</h3>
                                </div>
                            </div>

                            <div class="journal-details">
                                <div class="left-set">
                                    <div class="details-box">
                                        <h4>Bill To </h4>
                                        <h5>:</h5>
                                        <h6>{{ orderDetails?.vendor?.displayName || "___" }}</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Subject</h4>
                                        <h5>:</h5>
                                        <h6>Regarding Purchase</h6>
                                    </div>
                                </div>
                                <!-- <div class="right-set">
                                    <div class="details-box">
                                        <h4>Expiry Date</h4>
                                        <h5>:</h5>
                                        <h6>14/08/24</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Reference</h4>
                                        <h5>:</h5>
                                        <h6>PO-5315615</h6>
                                    </div>
                                </div> -->
                            </div>
                            <div class="crdit-note-page-table">
                            <table>
                                <tr>
                                    <th>Item/Service</th>
                                    <th>Quantity</th>
                                    <th>Rate</th>
                                    <th>Discount</th>
                                    <th>Amount</th>
                                </tr>
                                 <tr *ngFor="let item of orderDetails?.purchaseItems">
                                    <td>{{ item?.item?.itemName || "___" }}</td>
                                    <!-- <td>Patch 120 sq.ft</td> -->
                                    <td>{{  item?.quantity  || "___" }} </td>
                                    <td>{{ item?.rate || "___" }} </td>
                                    <td>{{ item?.discount || "0.00" }} </td>
                                    <td>{{ item?.amount || "___" }} </td>
                                </tr>
                            </table>
                        </div>
                            <div class="page-footer-content">
                                <div class="left-set">
                                    <div class="details-box">
                                        <h4>Customer Notes :</h4>
                                        <h6>{{ orderDetails?.customerNote || "NA" }}</h6>
                                    </div>
                                    <!-- <div class="details-box">
                                        <h4>Terms & Conditions :</h4>
                                        <h6>Privacy Policy</h6>
                                    </div> -->
                                </div>
                                <div class="right-set">
                                    <div class="details-box">
                                        <h4>Sub total</h4>
                                        <h5>:</h5>
                                        <h6>{{ orderDetails?. subTotal || "____"}}</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>VAT</h4>
                                        <h5>:</h5>
                                        <h6>5 %</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Total</h4>
                                        <h5>:</h5>
                                        <h6>{{ orderDetails?. totalPrice  || "___"}}</h6>
                                    </div>
                                    <!-- <div class="details-box">
                                        <h4>Credits Used</h4>
                                        <h5>:</h5>
                                        <h6>PO-5315615</h6>
                                    </div>
                                    <div class="details-box bold">
                                        <h4>Credits Remaining</h4>
                                        <h5>:</h5>
                                        <h6>AED 590 </h6>
                                    </div> -->
                                </div>
                            </div>

                            <p class="signature">Authorized Signature <span></span></p>
                            
                            <span class="published-note"><img src="assets/images/img-draft.svg" alt="Draft"></span>
                        </div>
                    </div>
                </div>
                <!-- <div *ngIf="selectedTabIndex === 1">
                    <div class="chart-tab-content">
                        content 2
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 2">
                    <div class="chart-tab-content">
                        content 3
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 3">
                    <div class="chart-tab-content">
                        content 4
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 4">
                    <div class="chart-tab-content">
                        content 5
                    </div>
                </div> -->
            </div>
        </div>
    </section>
