import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { EmployeeService } from '../../../../features/employees/services/employee.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-branch-access',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
  ],
  templateUrl: './branch-access.component.html',
  styleUrl: '../../add-employee.component.scss',
})
export class BranchAccessComponent implements OnInit, OnChanges {
  branchAccessForm: FormGroup;
  public userRolesData: any[] = [];
  public branchData: any[] = [];
  public isPortalUserEnabled: boolean = false;
  public initialIsPortalUser: boolean = false; // Track initial portal status for edit mode


  @Input() employee: any;
  @Input() isEditMode: boolean = false;
  @Input() employeeData: any = {};
  @Output() formSubmit = new EventEmitter<any>();
  approversData: any = [];
  approverList: any[] = [];
  approverNames: any[] = [];
  employeeId: any;
  approverNamesData: any;
  indexType: string;
  isApproverSelected = false;
  populatedApprover: any;

  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
    this.branchAccessForm = this.fb.group({
      branchName: ['', Validators.required],
      isPortalUser: [false],
      employeeApprover: [
        '',
      ],
      userRole: [''],
      // userName: [''],
      password: [''],
      approvers: this.fb.array([this.createApprover()])  // Initial approver field
    });
  }

  createApprover(): FormGroup {
    return this.fb.group({
      approverId: ['']
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      console.log(id);

      if (id) {
        this.employeeId = +id;
        this.getEmployeeApprover();
      }
    })
    this.loadUserRoles();
    this.loadBranches();
    this.loadEmployeeApprovers();


    // Observe changes to isPortalUser checkbox and apply validation logic
    this.branchAccessForm.get('isPortalUser')?.valueChanges.subscribe((isPortalUser: boolean) => {
      this.togglePortalUserFields(isPortalUser);
    });

    // Ensure validation logic is applied initially
    this.togglePortalUserFields(this.branchAccessForm.get('isPortalUser')?.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['employee'] && this.isEditMode && this.employee) {
      this.populateFormWithEmployeeData();
      this.initialIsPortalUser = this.employee.isPortalUser; // Store initial portal status
      this.togglePortalUserFields(this.employee.isPortalUser); // Apply validation logic immediately
    }
  }


  private loadUserRoles() {
    this.employeeService.getUserRoles().subscribe({
      next: (userRoles) => {
        this.userRolesData = userRoles;
      },
      error: (error) => console.error(error),
    });
  }

  getEmployeeApprover() {
    this.employeeService.getEmployeeApprover(Number(this.employeeId)).subscribe({
      next: (data) => {
        if (data?.employeeApprovers) {
          const levels = ['levelOneData', 'levelTwoData', 'levelThreeData', 'levelFourData'];
          const approvers = data.employeeApprovers[0];
  
          // Get the form array and clear any existing controls
          const approversArray = this.branchAccessForm.get('approvers') as FormArray;
          approversArray.clear();
  
          // Always add Level 1 Approver field, even if levelOneData is null
          const levelOneApprover = approvers?.levelOneData || { id: '', firstName: '', lastName: '' };
          approversArray.push(
            this.fb.group({
              approverId: [levelOneApprover.id],
            })
          );
  
          // Add other levels only if data exists
          levels.slice(1).forEach((level) => {
            const currentApprover = approvers?.[level];
            if (currentApprover) {
              approversArray.push(
                this.fb.group({
                  approverId: [currentApprover.id],
                })
              );
            }
          });
          console.log('Populated Approvers Array:', approversArray.value); // Debug log
          this.populatedApprover = approversArray.value;
        }
      },
      error: (error) => {
        console.error(error, 'Error');
      },
    });
  }
  
  
  loadEmployeeApprovers() {
    const search = "";
    const filter = {};
    this.employeeService.getEmployees(search, filter).subscribe((employees: any[]) => {
      this.approversData = employees.filter((approver: any) => approver?.user?.profileData?.profileName === 'manager');
    });
  }

  private loadBranches() {
    this.employeeService.getBranches().subscribe({
      next: (branches) => {
        this.branchData = branches;
      },
      error: (error) => console.error(error),
    });
  }

  populateFormWithEmployeeData(): void {
    this.branchAccessForm.patchValue({
      branchName: this.employee.branch?.id,
      employeeApprover: this.employee.employeeApprover,
      isPortalUser: this.employee.isPortalUser,
      userRole: this.employee.user?.profileId,
    });
    this.isPortalUserEnabled = this.employee.isPortalUser;
    this.togglePortalUserFields(this.employee.isPortalUser);
    console.log('employee.isPortalUser::', this.employee.isPortalUser);
  }

  getApprovers(): FormGroup[] {
    return (this.branchAccessForm.get('approvers') as FormArray).controls as FormGroup[];
  }
  

  onAddApprover() {
    const approversArray = this.branchAccessForm.get('approvers') as FormArray;
    const newApproverGroup = this.fb.group({
      approverId: [
        ''
      ]
    });
    approversArray.push(newApproverGroup);
  }

  onRemoveApprover(index: number): void {
    const approversArray: any = this.branchAccessForm.get('approvers') as FormArray;
    if (approversArray.length > 1) {
      const removedApproverId = approversArray.at(index).value.approverId;
      if(index === 0){
        this.indexType = 'LEVELONE'
      } else if (index === 1){
        this.indexType = 'LEVELTWO'
      } else if (index === 2){
        this.indexType = 'LEVELTHREE'
      } else if (index === 3){
        this.indexType = 'LEVELFOUR'
      }
      console.log('Populated array', this.populatedApprover)
      console.log('Removed Approver ID:', removedApproverId);
      console.log('after removal check approval list', this.approverList);
      
      if(this.isEditMode && this.indexType != 'LEVELONE' && this.populatedApprover?.includes(removedApproverId)){
        this.employeeService.removeEmployeeApprover(Number(this.employeeId), this.indexType).subscribe({
          next: (data) => {
            if(data) {
              approversArray.removeAt(index);
              this.toastr.success('Employee Approver deleted successfully','Error');
              this.getEmployeeApprover();
            }
          },
          error: (error) => {
            this.toastr.error(error, 'Error');
          }
        })
      }
        else {
          approversArray.removeAt(index);
        }
      if (!this.approverList[0]) {
        this.approverList[0] = {
          employeeId: this.employeeId,
          levelOne: null,
          levelTwo: null,
          levelThree: null,
          levelFour: null
        };
      }
      const updatedApprover = {
        employeeId: this.employeeId,
        levelOne: approversArray.at(0)?.value.approverId || null,
        levelTwo: approversArray.at(1)?.value.approverId || null,
        levelThree: approversArray.at(2)?.value.approverId || null,
        levelFour: approversArray.at(3)?.value.approverId || null,
      };
      this.approverList = [updatedApprover];
      console.log('Updated approverList after removal:', this.approverList);
    } else {
      this.toastr.warning('At least one approver is required.', 'Warning');
    }
  }


  onApproverSelected(event: any, index: number): void {
    const approversArray = this.branchAccessForm.get('approvers') as FormArray;
    const selectedApprover = event.value;
    const employeeId = Number(this.employeeId);

    if (!this.approverList[0]) {
      this.approverList[0] = {
        employeeId: employeeId,
        levelOne: null,
        levelTwo: null,
        levelThree: null,
        levelFour: null,
      };
    }

    const approverExistsInList = this.approverList.some(approver =>
      approver.levelOne === selectedApprover ||
      approver.levelTwo === selectedApprover ||
      approver.levelThree === selectedApprover ||
      approver.levelFour === selectedApprover
    );

    if (approverExistsInList) {
      this.toastr.warning('Approver is already selected in a level.', 'Warning');
      return;
    }

    const levelMap = ['levelOne', 'levelTwo', 'levelThree', 'levelFour'];
    const levelKey = levelMap[index];

    if (levelKey) {
      this.approverList[0][levelKey] = selectedApprover;
      approversArray.at(index).patchValue({ approverId: selectedApprover });
    } else {
      this.toastr.warning('Invalid level selected.', 'Warning');
      return;
    }

    const updatedApprover = {
      employeeId: employeeId,
      levelOne: this.approverList[0]?.levelOne || approversArray.at(0)?.value.approverId || null,
      levelTwo: this.approverList[0]?.levelTwo || approversArray.at(1)?.value.approverId || null,
      levelThree: this.approverList[0]?.levelThree || approversArray.at(2)?.value.approverId || null,
      levelFour: this.approverList[0]?.levelFour || approversArray.at(3)?.value.approverId || null,
    };


    this.approverList = [updatedApprover];
    console.log('Updated approverList:', this.approverList);

    this.employeeService.getEmployeeById(selectedApprover).subscribe((employee) => {
      if (employee?.firstName) {
        this.approverNames[index] = employee.firstName;
      }
    });

    this.isApproverSelected = true; 
  }


  // Function to toggle validators for portal user fields
  private togglePortalUserFields(isPortalUser: boolean) {
    this.isPortalUserEnabled = isPortalUser;

    if (isPortalUser) {
      this.branchAccessForm.get('userRole')?.setValidators([Validators.required]);
      if (!this.isEditMode || !this.initialIsPortalUser) {
        this.branchAccessForm.get('password')?.setValidators([Validators.required]);
      } else {
        this.branchAccessForm.get('password')?.clearValidators(); // Password not required if portal user initially
      }
    } else {
      this.branchAccessForm.get('userRole')?.clearValidators();
      this.branchAccessForm.get('password')?.clearValidators();
    }

    // Update validity after applying or clearing validators
    this.branchAccessForm.get('userRole')?.updateValueAndValidity();
    this.branchAccessForm.get('password')?.updateValueAndValidity();
  }

  onNext(): void {
    this.approverList = this.approverList.map(approver => {
      Object.keys(approver).forEach(key => {
        if (approver[key] === null) {
          delete approver[key];
        }
      });
      return approver;
    });
    
    console.log('this.approverList', this.approverList);
    
    if (this.branchAccessForm.invalid) {
      this.branchAccessForm.markAllAsTouched();
      console.log('Form is Invalid',this.branchAccessForm.controls);
    } else {
      const formValue = this.branchAccessForm.value;
      if (!formValue.isPortalUser) {
        formValue.userRole = null;
        formValue.password = null;
      }
      console.log('Branch Data Submitted-----:', formValue);
      // this.formSubmit.emit({ formValue, approverList: this.approverList });
      if (this.isApproverSelected) {
        this.formSubmit.emit({ formValue, approverList: this.approverList });
        this.isApproverSelected = false; // Reset the flag after emitting.
      } else {
        this.formSubmit.emit({formValue, approverList: null});
      }
    }
  }

  onCancel(): void {
    // this.branchAccessForm.reset();
    this.router.navigate(['/employees']);
  }
}
