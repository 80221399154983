import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GENERATE_PDF,
  GET_ALL_WORK_ORDER,
  GET_ONE_WORK_ORDER,
} from '../graphql/queries/workOrder.queries';
import {
  REMOVE_WORK_ORDER,
  UPDATE_WORK_ORDER,
} from '../graphql/mutation/workOrder.mutation';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderService {
  constructor(private apollo: Apollo) {}

  getWorkOrderById(id: number): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_ONE_WORK_ORDER,
        variables: {
          id: id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.workOrder || []));
  }

  getAllWorkOrders(): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_ALL_WORK_ORDER,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.workOrders || []));
  }

  updateWorkOrder(
    updateWorkOrderInput: any,
    workOrderItems: any,
    fileAttachments: any
  ): Observable<any> {
    return this.apollo
      .use('project')
      .mutate({
        mutation: UPDATE_WORK_ORDER,
        variables: {
          updateWorkOrderInput,
          workOrderItems,
          fileAttachments,
        },
      })
      .pipe(map((result: any) => result.data.updateworkOrder || []));
  }

  getAllWorkOrder(search: string): Observable<any> {
    return this.apollo
      .use('project')
      .query<any>({
        query: GET_ALL_WORK_ORDER,
        fetchPolicy: 'network-only',
        variables: {
          search,
        },
      })
      .pipe(map((result) => result.data?.workOrders || []));
  }

  removeWorkOrder(id: number): Observable<any> {
    return this.apollo
      .use('project')
      .mutate({
        mutation: REMOVE_WORK_ORDER,
        variables: {
          id: id,
        },
      })
      .pipe(map((result: any) => result.data.removeWorkOrder || []));
  }

  gereratePdf(id: number): Observable<any> {
    return this.apollo
      .use('project')
      .query<any>({
        query: GENERATE_PDF,
        variables: {
          workOrderId: id,
          withPrice: true,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data?.generateWorkOrderPdf || []));
  }
}
