import gql from 'graphql-tag';

export const CREATE_BILL = gql`
  mutation createBill(
    $createBillInput: CreateBillInput!, 
    $employeeId: Int, 
    $billItems: [CreateBillItemInput], 
    $billAttachments: [CreateBillAttachmentInput]
    ) {
    createBill(
    createBillInput: $createBillInput
    employeeId: $employeeId, 
    billItems: $billItems, 
    billAttachments: $billAttachments
        ) {
    id
    billNo
    orderNo
    billDate
    paymentTerm
    dueDate
    subject
    customerNote
    billPaymentStatus
    subTotal
    tax
    adjustment
    totalPrice
    balance
    billStatus
    status
    createdAt
    updatedAt
    deletedAt
    billItems {
      id
      billId
      description
      quantity
      rate
      discount
      amount
      status
      createdAt
      updatedAt
      deletedAt
    }
    billAttachments {
      id
      billId
      fileUrl
      status
      createdAt
      updatedAt
      deletedAt
    }
    projectId
    projectData {
      id
      projectCode
      projectName
      totalProjectCost
      description
      startDate
      endDate
      status
      budget
      threshold
      ApprovedQuoteDocumentURL
      proofDocument
      billingMethod
      costBudget
      revenueBudget
      partnerId
    }
    partnerId
    partnerData {
      id
      partnerType
      customerType
      displayName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      createdAt
      updatedAt
      deletedAt
    }
    billingAddressId
    billingAddressData {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
      
    }
    shippingAddressId
    shippingAddressData {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
    journalId
    journalData {
      id
      date
      journalNo
      reference
      notes
      currencyId
      inCash
      status
      createdAt
      updatedAt
      deletedAt
    }
    paymentInfos {
      id
      paymentId
      refType
      refId
      amount
      createdAt
      updatedAt
    }
  }
  }
`;

export const UPDATE_BILL = gql`
  mutation updateBill(
    $updateBillInput: UpdateBillInput!, 
    $employeeId: Int, 
    $billItems: [UpdateBillItemInput], 
    $billAttachments: [UpdateBillAttachmentInput]
    ) {
      updateBill(
        updateBillInput: $updateBillInput
    employeeId: $employeeId, 
    billItems: $billItems, 
    billAttachments: $billAttachments
        )  {
    id
    billNo
    orderNo
    billDate
    paymentTerm
    dueDate
    subject
    customerNote
    billPaymentStatus
    subTotal
    tax
    adjustment
    totalPrice
    balance
    billStatus
    status
    createdAt
    updatedAt
    deletedAt
    billItems {
      id
      billId
      itemId
      description
      quantity
      rate
      discount
      amount
      status
      createdAt
      updatedAt
      deletedAt
    }
    billAttachments {
      id
      billId
      fileUrl
      status
      createdAt
      updatedAt
      deletedAt
    }
    projectId
    projectData {
      id
      projectCode
      projectName
      totalProjectCost
      description
      startDate
      endDate
      status
      budget
      threshold
      ApprovedQuoteDocumentURL
      proofDocument
      billingMethod
      costBudget
      revenueBudget
      partnerId
    }
    partnerId
    partnerData {
      id
      partnerType
      customerType
      displayName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      createdAt
      updatedAt
      deletedAt
    }
    billingAddressId
    billingAddressData {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
    shippingAddressId
    shippingAddressData {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
    journalId
    journalData {
      id
      date
      journalNo
      reference
      notes
      currencyId
      inCash
      status
      createdAt
      updatedAt
      deletedAt
    }
    paymentInfos {
      id
      paymentId
      refType
      refId
      amount
      createdAt
      updatedAt
    }
  }
  }
`;

export const ADD_BILL_ITEM = gql`
  mutation createBillItem($createBillItemInput: CreateBillItemInput!) {
    createBillItem(createBillItemInput: $createBillItemInput){
    id
    billId
    billData {
      id
      billNo
      orderNo
      billDate
      paymentTerm
      dueDate
      subject
      customerNote
      billPaymentStatus
      subTotal
      tax
      adjustment
      totalPrice
      balance
      billStatus
      status
      createdAt
      updatedAt
      deletedAt
    }
    itemId
    itemData {
      id
      itemType
      skuCode
      imageUrl
      isSalesActive
      isPurchaseActive
      name
      quantity
      unit
      itemName
      sellingPrice
      salesAccount
      salesDescription
      status
      notes
      costPrice
      purchaseAccount
      purchaseDescription
      salesAccountId
    }
    description
    quantity
    rate
    discount
    amount
    status
    createdAt
    updatedAt
    deletedAt
  }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation createBillAttachment($createBillAttachmentInput: CreateBillAttachmentInput!) {
    createBillAttachment(createBillAttachmentInput: $createBillAttachmentInput){
    id
    billId
    billData {
      id
      billNo
      orderNo
      billDate
      paymentTerm
      dueDate
      subject
      customerNote
      billPaymentStatus
      subTotal
      tax
      adjustment
      totalPrice
      balance
      billStatus
      status
      createdAt
      updatedAt
      deletedAt
    }
    fileUrl
    fileName
    status
    createdAt
    updatedAt
    deletedAt
  }
  }
`;
export const REMOVE_BILL = gql`
  mutation removeBill($id: Int!) {
    removeBill(id: $id)
  }
`;

export const REMOVE_BILL_ITEM = gql`
  mutation removeBillItem($id: Int!) {
    removeBillItem(id: $id)
  }
`;

export const REMOVE_BILL_ATTACHMENT = gql`
mutation removeBillAttachment($id: Int!) {
  removeBillAttachment(id: $id)
  }

`;