import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { VacationHistoryComponent } from './components/vacation-history/vacation-history.component';
import { VacationApplyComponent } from './components/vacation-apply/vacation-apply.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vacation',
  standalone: true,
  imports: [
    CommonModule,
    MatTabGroup,
    MatTabsModule,
    VacationHistoryComponent,
    VacationApplyComponent
  ],
  templateUrl: './vacation.component.html',
  styleUrl: '../../employee-details.component.scss',
})
export class VacationComponent {
  @ViewChild('vacationHistory') vacationHistoryComponent: any;
  public themeClass: string = 'ag-theme-quartz';
  public paginationPageSize = 25;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  activeTabIndex = 0; 
  employeeId: number;

  constructor( 
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
      if (id) {
        this.employeeId = Number(id);
      }
  }

  onTabChange(event: MatTabChangeEvent) {
    if (event.index === 0) {
        if (this.vacationHistoryComponent) {
          this.vacationHistoryComponent.fetchVacationsById(this.employeeId);
        }
    }
  }

}
