<mat-dialog-content>
    <section class="upload-employees-wrapper">
      <h2 class="cmn-popup-title">Upload Employees</h2>
      <button class="cmn-close" mat-dialog-close></button>
      <div class="cmn-upload-box">
        <img src="assets/images/upload-image.png" alt="Upload Image" />
        <h3>Drag and drop your file here</h3>
        <h4>or</h4>
        <input
          #fileInput
          type="file"
          (change)="onFileSelected($event)"
          style="display: none"
           accept=".csv"
        />
        <button class="cmn-choose-file-btn" (click)="fileInput.click()">
          <img
            src="assets/images/icons/icon-add-color.svg"
            alt="Icon Add"
          />Choose a File
        </button>
      </div>
      <div class="uploaded-file" *ngIf="file">
        <h6>
          {{file?.name}}
          <img
            src="assets/images/icons/icon-close.svg"
            alt="Close Icon"
            
          />
        </h6>
      </div>
      <div class="upload-info">
        <h5>CSV Format only supported</h5>
        <a
          href="assets/sample-files/employees.csv"
          class="download-sample"
          download="employees.csv"
          >Download Sample CSV</a
        >
      </div>
  
      <button class="cmn-upload-submit-btn active">
        Upload Employees
      </button>
    </section>
  </mat-dialog-content>