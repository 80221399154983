import gql from 'graphql-tag';

export const CREATE_BRANCH = gql`
  mutation createBranch($input: CreateBranchInput!) {
    createBranch(createBranchInput: $input) {
      id
      branchName
      address
      city
      state
      country
      zipCode
      phoneNumber
      branchCode
      isHeadOffice
      email
      createdAt
      updatedAt
      deletedAt
      employee {
        id
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        email
        phoneNumber
        alternatePhoneNumber
        address
        permanentAddress
        dateOfJoining
        employmentType
        employeeType
        employeeStatus
        profilePicture
        maritalStatus
        nationality
        passportNumber
        visaType
        visaIssueDate
        visaExpiryDate
        salary
        bankName
        bankAccountNumber
        IFSCCode
        departmentId
      }
      projects {
        id
        projectCode
        projectName
        totalProjectCost
        description
        startDate
        endDate
        status
        budget
        threshold
        ApprovedQuoteDocumentURL
        proofDocument
        billingMethod
        costBudget
        revenueBudget
      }
    }
  }
`;

export const UPDATE_BRANCH = gql`
  mutation updateBranch($updateBranchInput: UpdateBranchInput!) {
    updateBranch(updateBranchInput: $updateBranchInput)  {
    id
    branchName
    address
    city
    state
    country
    zipCode
    phoneNumber
    branchCode
    isHeadOffice
    email
    createdAt
    updatedAt
    deletedAt
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      employeeApprovers{
        employee{
          id
          firstName
          lastName
        }
      }
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      departmentId
    }
    projects {
      id
      projectCode
      projectName
      totalProjectCost
      description
      startDate
      endDate
      status
      budget
      threshold
      ApprovedQuoteDocumentURL
      proofDocument
      billingMethod
      costBudget
      revenueBudget
      branchId
      quoteId
    }
  }
  }
`;

export const REMOVE_BRANCH_BY_ID = gql`
mutation removeBranch($id: Int!) {
  removeBranch(id: $id)
}
`;
