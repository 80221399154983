import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { BillService } from '../../features/bills/services/bills.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { CustomerService } from '../../features/customers/services/customer.service';

@Component({
  selector: 'app-bill-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './bill-details.component.html',
  styleUrl: './bill-details.component.scss'
})
export class BillDetailsComponent {
  selectedTabIndex = 0;
  readonly dialog = inject(MatDialog);
  isDropdownOpen = false;
  billId: any;
  billDetails: any;
  search: any;
  allBills: any;
  mailForm: FormGroup
  mails: any;
  isMailDropdownOpen = false;
  filterForm: FormGroup;
  filter: string[];

  constructor(
    private router: Router,
    private billService: BillService,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(){
    this.fetchBills();
    this.route.paramMap.subscribe(params => {
      this.billId = params.get('id');
      if (this.billId) {
        this.fetchBills();
        this.fetchBillById();
        this.fetchMails();
        }
      });
      this.mailForm = this.fb.group({
        mails: ['']
      });
      this.filterForm = this.fb.group({
        status: this.fb.array([]),
      });
  }

  get statusFormArray() {
    return this.filterForm.get('status') as FormArray;
  }
  
  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  openBiilsApplyCreditDialog() {
    const dialogBiilsApplyCreditDialogItemRef = this.dialog.open(BiilsApplyCreditDialog);
  }

  selectTab(index: number) {
    this.selectedTabIndex = index;
    const selectedBill = this.allBills[index];
    if (selectedBill) {
      this.router.navigate([`/vendor-invoice/vendor-invoice-details/${selectedBill.id}`]);
    }
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.search = input.value;
    this.fetchBills();
  }

  onSubmit() {
    const selectedStatus = [];
    const formValues = this.filterForm.value;
    for (const leaveType in formValues) {
      if (formValues[leaveType]) {
        selectedStatus.push(leaveType);
      }
    }
    this.filter = selectedStatus;
    this.isDropdownOpen = false;
  }

  clearFilter(){
    this.filterForm.reset();
  }


  openDialog() {
    const dialogRef = this.dialog.open(UploadBillDetailsDialog);
  }

  onEdit(){
    this.router.navigate([`/vendor-invoice/vendor-invoice-edit/${this.billId}`]);
  }

  editJournal(){
    this.router.navigate([`/manual-journals/edit/${this.billDetails?.journalId}`]);
  }

  onRecordPayment(){
    const vendorId = this.billDetails?.partnerId;
    const billId = this.billId;
    this.router.navigate(['/payments-made/bill-payment-new'], {
      queryParams: { vendorId, billId },
    });
  }

  onConfirmDelete(): void {
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteBill(this.billId);
      }
    });
  }

  deleteBill(id: any){
    this.billService.removeBill(Number(id)).subscribe({
      next: (data) => {
        if(data){
          this.toastr.success('Bill deleted successfully', 'Error');
          this.fetchBills();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      }
    })
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleMailDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isMailDropdownOpen = !this.isMailDropdownOpen;
  }

  fetchBills(){
    this.billService.fetchBills(this.search).subscribe({
      next: (data) => {
        this.allBills = data;
        if (this.billId) {
          const targetId = +this.billId;
          this.selectedTabIndex = this.allBills.findIndex((c: any) => {
            return c.id === targetId;
          });
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  fetchBillById(){
    this.billService.fetchBillById(Number(this.billId)).subscribe({
      next: (data) => {
        this.billDetails = data;
        this.fetchBills();
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  fetchMails(){
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const conditions = {
      employeeId: employeeId,
      refId: Number(this.billId),
      type: 'Vendor',
      activityType: 'Mail'
    }
    this.customerService.comments(conditions).subscribe({
      next: (data) => {
        if(data){
          this.mails = data;
        }
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  markAsSent(){
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const billInput = {
      id: Number(this.billId),
      billStatus: 'Open'
    };
    const billItems: any = [];
    const billAttachments: any = [];
    this.billService.updateBill(billInput, employeeId, billItems, billAttachments).subscribe({
      next: (data: any) => {
        if (data) {
          this.toastr.success('Bill marked as sent!', 'Success');
          this.router.navigate([`/vendor-invoice/vendor-invoice-details/${this.billId}`]);
        }
      },
      error: (error: any) => {
        this.toastr.error(error, 'Error');
      }
    })
  }

  onMail(){
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const mailData = {
      employeeId: employeeId,
      refId: Number(this.billId),
      type: 'Vendor',
      activityType: 'Mail',
      note: this.mailForm.value.mails
    }
    this.customerService.createComment(mailData).subscribe({
      next: (data) => {
        if(data){
          this.toastr.success('Mail added successfully!', 'Success');
          this.mailForm.reset();
          this.fetchMails();
        }
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

}



@Component({
  selector: 'upload-bill-details-dialog',
  templateUrl: 'upload-bill-details-dialog.html',
  styleUrls: ['./bill-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadBillDetailsDialog {

  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }

}


@Component({
  selector: 'bills-apply-credits-dialog',
  templateUrl: 'bills-apply-credits-dialog.html',
  styleUrls: ['./bill-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule],
})



export class BiilsApplyCreditDialog {
}
