import { gql } from "apollo-angular";

export const PUNCH_IN = gql`
  mutation punchIn($date: DateTime!, $time: String!) {
    punchIn(date: $date, time: $time) {
    id
    date
    checkInTime
    checkOutTime
    totalHoursWorked
    overtimeHours
    attendanceStatus
    markedBy
    shift
    notes
    employeeId
    employee {
      id
      employeeCode
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      nativeAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      passportIssueDate
      passportExpiryDate
      emiratesIDNo
      laborCardExpiryDate
      drivingLicenseNumber
      promotionDate
      previousDesignation
      warningLetterIssuedDate
      warningLetterReasons
      visaType
      visaNumber
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      status
      departmentId
    }
  }
  }
`;

export const PUNCH_OUT = gql`
  mutation punchOut($date: DateTime!, $time: String!) {
    punchOut(date: $date, time: $time) {
    id
    date
    checkInTime
    checkOutTime
    totalHoursWorked
    overtimeHours
    attendanceStatus
    markedBy
    shift
    notes
    employeeId
    employee {
      id
      employeeCode
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      nativeAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      passportIssueDate
      passportExpiryDate
      emiratesIDNo
      laborCardExpiryDate
      drivingLicenseNumber
      promotionDate
      previousDesignation
      warningLetterIssuedDate
      warningLetterReasons
      visaType
      visaNumber
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      status
      departmentId
    }
  }
  }
`;