import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { LIST_ACCOUNTS_CASH_AND_BANK, NET_AMOUNT_CASH_AND_BANK, TRANSACTION_CATEGORIES, TRANSACTIONS_OF_CHART_ACCOUNTS } from '../graphql/queries/banking.query';
import { CREATE_BANK, CREATE_TRANSACTION } from '../graphql/mutation/banking.mutation';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BankingService {
  constructor(private apollo: Apollo) { }

  fetchAccountsOfBankAndCash(search: any): Observable<any> {
    console.log('search', search);

    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: LIST_ACCOUNTS_CASH_AND_BANK,
        variables:
        {
          search
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.listAllChartOfAccountsOfBankAndCash || [])
      );
  }

  transactionCategories(): Observable<any> {
    return this.apollo
      .use('accounts')
      .query<any>({
        query: TRANSACTION_CATEGORIES,
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((result) => result.data?.transactionCategories || [])
      );
  }

  transactionOfChartOfAccount(offsetAccountId: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: TRANSACTIONS_OF_CHART_ACCOUNTS,
        variables: {
          offsetAccountId
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.findAllTransactionsAndUncategorizedTransactions || [])
      );
  }

  getNetAmountOfBankAndCash(): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: NET_AMOUNT_CASH_AND_BANK,
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.getNetAmountOfChartOfAccountsOfBankAndCash || [])
      );
  }

  createBank(createBankInput: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_BANK,
      variables: {
        createBankInput: createBankInput,
      },
    }).pipe(
      map((result: any) => result.data.createBankInput || [])
    );
  }

  bulkCreateTransactions(file: File, offsetAccountId: any): Observable<any> {
    const token = localStorage.getItem('AUTH_TOKEN');
    const formData = new FormData();
    formData.append('operations', JSON.stringify({
      query: `
          mutation bulkCreateStatements($file: Upload!, $offsetAccountId: Int!) {
            bulkCreateStatements(file: $file, offsetAccountId: $offsetAccountId)
          }
          `,
      variables: { file: null, offsetAccountId }
    }));

    formData.append('map', JSON.stringify({ '0': ['variables.file'] }));
    formData.append('0', file);

    return from(
      fetch(environment.url.accountsUrl, {
        method: 'POST',
        body: formData,
        headers: {
          'authorization': token ? `Bearer ${token}` : '',
          'x-apollo-operation-name': 'uploadFile'
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(result => result.data?.bulkCreateTransactions || result)
    );
  }

  createTransaction(createTransactionInput: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_TRANSACTION,
      variables: {
        createTransactionInput
      },
    }).pipe(
      map((result: any) => result.data.createTransaction || [])
    );
  }

}