<mat-dialog-content class="mat-typography">
  <section class="add-items-dialog-wrapper">
    <h2 class="cmn-popup-title">Rejection Note</h2>
    <button class="cmn-close" mat-dialog-close></button>
    <form>
      <div class="branch-form-wrapper">
        <div class="form-wrapper w-full">
          <label for="">Notes</label>
          <mat-form-field>
            <textarea
              matInput
              placeholder="Reason for rejection"
              formControlName="note"
            ></textarea>
          </mat-form-field>
          <!-- <div class="custom-error">
                      <span>
                        <mat-error *ngIf="projectForm.get('description')?.hasError('required')">Note is
                          required</mat-error>
                      </span>
                    </div> -->
        </div>
      </div>
      <div class="btn-wrapper">
        <!-- <button type="submit" class="cmn-popup-btn">
            {{branchData?.id ? 'Save':'Add Branch'}}
          </button> -->
        <button
          class="cmn-cancel-btn reject-btn"
        >
          Reject
        </button>
      </div>
    </form>
  </section>
</mat-dialog-content>
