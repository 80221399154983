import { ChangeDetectorRef, Component, Inject, inject, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';
import { QuotationService } from '../../features/quotation/services/quotation.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { PaymentsItemService } from '../../features/payments/services/payments.service';
import { QUOTESTATUS } from '../../helpers/helper-file';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';

@Component({
  selector: 'app-quotation-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    PermissionDirective
  ],
  templateUrl: './quotation-details.component.html',
  styleUrl: './quotation-details.component.scss',
})
export class QuotationDetailsComponent implements OnInit {
  selectedTabIndex = 0;
  readonly dialog = inject(MatDialog);
  isDropdownOpen = false;
  expenseDetails: any = {};
  detailsLoading: any = false;
  loading: boolean = false;
  pdfLoading: boolean = false;
  allQuotes: any = [];
  quoteData: any = {};
  parentQuoteId: any;
  parentQuoteData: any = {};
  parentQuoteStatus: string;
  allParentQuotes: any[] = [];
  customerId: any;
  selectedParentQuote: any;
  selectedQuote: any = {};
  private searchSubject = new Subject<string>();
  Permission = Permission;
  isAttacheFile = false;

  selectedOption = 'J-000102 - 3';
  selectedOption2 = 'J-000102 - 3';
  isCommentsOpen = false;
  commentsList: any;
  commentsForm: FormGroup;
  // profileName: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private quoteService: QuotationService,
    private commentService: PaymentsItemService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    // const loggedInUser: any = localStorage.getItem('loggedInUser');
    // const userData = JSON.parse(loggedInUser);
    // this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit(): void {
    this.fetchAllParentQuotes();
    this.route.queryParamMap.subscribe((queryParams) => {
      this.parentQuoteId = queryParams.get('id');

      if (this.parentQuoteId) {
        const id = +this.parentQuoteId;
        this.quoteService.fetchParentQuoteById(id).subscribe({
          next: (data) => {
            this.customerId = data.partnerData.id;
            this.parentQuoteData = data;
            this.selectedQuote = this.parentQuoteData?.quoteData[0];
            // this.fetchAllParentQuotes();
            if (!data?.quoteData || data.quoteData.length === 0) {
              this.router.navigate(['/quotation']);
            }
          },
          error: (err) => {
            console.error('Error fetching parent quote by ID:', err);
          },
        });
      }
      // else {
      //   this.fetchAllParentQuotes();
      // }
    });

    if (this.selectedQuote?.quoteItems) {
      this.selectedQuote.quoteItems = this.selectedQuote.quoteItems.map((item: any) => ({
        ...item,
        grossAmount: item.quantity * item.rate || 0,
        netAmount: (item.quantity * item.rate || 0) - (item.discount || 0),
      }));
    }

    // Initialize search functionality
    this.searchSubject.pipe(debounceTime(600), distinctUntilChanged()).subscribe((searchTerm) => {
      this.fetchAllParentQuotes(searchTerm);
    });

    // Initialize the comments form
    this.commentsForm = this.fb.group({
      comments: ['', Validators.required],
    });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  selectTab(index: number): void {
    this.selectedTabIndex = index;
    console.log("selcted index:", this.selectedTabIndex);
    this.parentQuoteData = this.allParentQuotes[index];
    this.parentQuoteStatus = this.parentQuoteData.parentQuoteStatus;

    // Select the first quote in the selected tab
    if (Array.isArray(this.parentQuoteData?.quoteData) && this.parentQuoteData?.quoteData?.length > 0) {
      this.selectedQuote = this.parentQuoteData?.quoteData[0];
    } else {
      this.selectedQuote = null;
    }

    if (this.parentQuoteData) {
      this.router.navigate(['quotation/quotation-details'], {
        queryParams: { id: this.parentQuoteData.id },
      });
    }
  }

  private initializeSelectedQuote(): void {
    if (Array.isArray(this.parentQuoteData?.quoteData) && this.parentQuoteData.quoteData.length > 0) {
      this.selectedQuote = this.parentQuoteData.quoteData[0];
      console.log("Selcted quote:", this.selectedQuote);
    }
    //  else {
    //   this.selectedQuote = {};
    // }
  }

  onQuoteSelectionChange(selectedQuote: any): void {
    this.selectedQuote = selectedQuote;
  }

  getTotalDiscount(): number {
    return this.selectedQuote?.quoteItems
      ?.reduce((total: any, item: any) => total + (item.discount || 0), 0) || 0;
  }

  fetchAllParentQuotes(search: string = ''): void {
    this.loading = true;

    this.quoteService.fetchParentQuotes(search).subscribe({
      next: (parentQuotes) => {
        this.allParentQuotes = parentQuotes;

        if (this.parentQuoteId) {
          const targetId = +this.parentQuoteId;
          this.selectedTabIndex = this.allParentQuotes.findIndex(
            (quote: any) => quote.id === targetId
          );
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }

        // this.parentQuoteData = this.allParentQuotes[this.selectedTabIndex] || {};
        this.parentQuoteStatus = this.parentQuoteData.parentQuoteStatus;
        this.initializeSelectedQuote();
      },
      error: (err) => {
        console.error('Error fetching all parent quotes:', err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  quoteMarkAsSent() {
    const parentQuoteId = +this.selectedQuote?.parentQuoteId;
    const quoteId = +this.selectedQuote?.id;
    const quoteStatusKey = Object.keys(QUOTESTATUS).find(
      key => QUOTESTATUS[key as keyof typeof QUOTESTATUS] === QUOTESTATUS.SENT
    );

    if (!quoteStatusKey) {
      this.toastr.error('Invalid quote status');
      return;
    }

    this.quoteService.quoteMarkAsSent(quoteId, parentQuoteId, quoteStatusKey).subscribe({
      next: (response) => {
        this.toastr.success('Quotation sent');
        this.fetchAllParentQuotes()
      },
      error: (error) => {
        this.toastr.error('Failed to sent quotation');
      }
    });
  }

  quoteRevision(): void {
    if (!this.selectedQuote?.id) {
      this.toastr.warning('Please select a valid parent quote and quote.');
      return;
    }

    this.loading = true;
    const parentId = +this.parentQuoteId
    this.quoteService.revisionQuote(this.selectedQuote?.parentQuoteId, this.selectedQuote.id).subscribe({
      next: (response) => {
        this.loading = false;
        this.toastr.success('Quote revised successfully.');
        const revisedQuoteId = response?.data?.revisionQuote?.id;
        if (revisedQuoteId) {
          this.router.navigate([`quotation/quotation-edit/${revisedQuoteId}`]);
        }
        else {
          this.toastr.error('Failed to get the revised quote ID.');
        }
      },
      error: (err) => {
        this.loading = false;
        console.error('Error revising quote:', err);
        this.toastr.error(err);
      }
    });
  }

  reviseQuote(): void {
    if (!this.selectedQuote?.id) {
      this.toastr.warning('Please select a valid parent quote and quote.');
      return;
    }

    this.loading = true;
    const parentId = +this.parentQuoteId
    this.quoteService.reviseQuoteVariation(this.selectedQuote?.parentQuoteId, this.selectedQuote.id).subscribe({
      next: (response) => {
        this.loading = false;
        this.toastr.success('Quote revised successfully.');
        const revisedQuoteId = response?.data?.reviseQuoteVariation?.id;
        if (revisedQuoteId) {
          this.router.navigate([`quotation/quotation-edit/${revisedQuoteId}`]);
        }
        else {
          this.toastr.error('Failed to get the revised quote ID.');
        }
      },
      error: (err) => {
        this.loading = false;
        console.error('Error revising quote:', err);
        this.toastr.error(err);
      }
    });
  }

  onDeleteQuoteById(quoteId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.removeQuoteById(quoteId);
      }
    });
  }

  private removeQuoteById(quoteId: number): void {
    this.quoteService.removeQuote(quoteId).subscribe({
      next: () => {
        this.toastr.success("Quotation removed successfully.");
        this.fetchAllParentQuotes();
      },
      error: (error) => {
        console.log("quote remove error", error)
        this.toastr.error(error.message ||
          'Failed to remove quotation. Please try again.'
        );
      },
    });
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadQuotationDetailsDialog);
  }

  openQuotaionDialog() {
    const dialogQuotaionRef = this.dialog.open(QuotaionRejectDialog);
  }

  openQuotaionApprovalDialog() {
    if (!this.selectedQuote?.id) {
      this.toastr.warning('Please select a quote to approve.');
      return;
    }

    const dialogQuotaionRef = this.dialog.open(QuotaionApproveDialog, {
      // data: { quoteId: this.selectedQuote.id },
      data: {
        quoteId: this.selectedQuote.id,
        projectId: this.selectedQuote.projectId,
      },
    });

    dialogQuotaionRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.success('Approval confirmed.');
      }
    });
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleComments(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    const selectedItem = this.allParentQuotes[this.selectedTabIndex];
    const itemId = selectedItem.id
    this.fetchComments(itemId);
    this.isCommentsOpen = !this.isCommentsOpen;
  }

  navigateToSendMail(id: number) {
    const quoteId = +id;
    // this.router.navigate([`quotation/send-mail/${quoteId}`]);
    this.router.navigate([`quotation/quotation-details/mail`], {
      queryParams: { quoteId },
    });
  }

  toggleAttachFile(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isAttacheFile = !this.isAttacheFile;
  }

  viewAttachments(url: any) {
    const token = localStorage.getItem('AUTH_TOKEN');
    fetch(url, {
      method: 'GET',
      headers: {
        'authorization': token ? `Bearer ${token}` : '',
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      })
      .catch(error => console.error('Error:', error));
  }

  onRemoveDocument(id: any) {
    if (id) {
      const dialogRef = this.dialog.open(ConfirmDialog);
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.quoteService.removeQuoteAttachmentById(
            Number(id)
          ).subscribe({
            next: (data) => {
              if (data) {
                this.toastr.success('Attachment removed successfully', 'Success');

                this.quoteService.fetchParentQuoteById(+this.parentQuoteId).subscribe({
                  next: (data) => {
                    this.customerId = data.partnerData.id;
                    this.parentQuoteData = data;
                    this.selectedQuote = this.parentQuoteData?.quoteData[0];
                    if (!data?.quoteData || data.quoteData.length === 0) {
                      this.router.navigate(['/quotation']);
                    }
                  },
                  error: (err) => {
                    console.error('Error fetching parent quote by ID:', err);
                  },
                });
              }
            },
            error: (error) => this.toastr.error(error, 'Error'),
          });
        }
      })
    }
  }

  onViewDocument() {
    const url = this.selectedQuote.quoteUrl;
    this.pdfLoading = true;
    if (url) {
      const token = localStorage.getItem('AUTH_TOKEN');
      fetch(url, {
        method: 'GET',
        headers: {
          'authorization': token ? `Bearer ${token}` : ''
        }
      }).then((response: any) => response.blob()).then((blob: any) => {
        this.pdfLoading = false;
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      }).catch((error: any) => {
        console.error('Error:', error)
      });
    } else {
      this.quoteService.generateQuotePDF(this.selectedQuote?.id).subscribe({
        next: (pdfResponse) => {
          this.pdfLoading = false;
          const pdfUrl = pdfResponse.data.getQuotePDF.url;
          const token = localStorage.getItem('AUTH_TOKEN');
          fetch(pdfUrl, {
            method: 'GET',
            headers: {
              'authorization': token ? `Bearer ${token}` : ''
            }
          }).then((response: any) => response.blob()).then((blob: any) => {
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
          }).catch((error: any) => {
            console.error('Error:', error)
          });
        },
        error: (pdfError) => {
          console.error('Error generating PDF:', pdfError);
          this.toastr.error(
            pdfError.message || 'Failed to generate PDF.'
          );
        }
      })
    }
  }

  fetchComments(refId: number) {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee.employeeId;

    const value = {
      employeeId: employeeId,
      refId: refId,
      activityType: 'Comment',
      type: 'Quote',
    };
    this.loading = true;
    this.commentService.comments(value).subscribe({
      next: (items) => {
        this.commentsList = items;
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching all comments:', err);
        this.loading = false;
      },
    });
  }

  onCommentSubmit(): void {
    if (this.commentsForm.invalid || !this.commentsForm.value.comments.trim()) {
      this.commentsForm.markAllAsTouched();
      this.toastr.warning('Please enter a comment before submitting.');
      return;
    }

    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee.employeeId;
    const selectedIteam = this.allParentQuotes[this.selectedTabIndex];
    const refId = selectedIteam.id;

    const data = this.commentsForm.value;

    const createCommentInput = {
      employeeId: employeeId,
      refId: refId,
      type: 'Quote',
      activityType: 'Comment',
      note: data?.comments,
    };

    this.commentService.createComment(createCommentInput).subscribe({
      next: () => {
        this.loading = false;
        const selectedIteam = this.allParentQuotes[this.selectedTabIndex];
        const itemId = selectedIteam.id;
        this.fetchComments(itemId);
        this.toastr.success('Comments added');
        this.commentsForm.reset();
      },
      error: (error) => {
        this.loading = false;
        this.toastr.error(error.message || 'Failed to add comments.');
      },
    });
  }

  navigateToEdit(quoteId: string): void {
    if (quoteId) {
      this.router.navigate([`quotation/quotation-edit/${quoteId}`]);
    } else {
      console.error('No quote ID selected');
    }
  }

}

@Component({
  selector: 'upload-quotation-details-dialog',
  templateUrl: 'upload-quotation-details-dialog.html',
  styleUrls: ['./quotation-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadQuotationDetailsDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}

@Component({
  selector: 'quotaion-reject-dialog',
  templateUrl: 'quotation-reject-dialog.html',
  styleUrls: ['./quotation-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class QuotaionRejectDialog { }

@Component({
  selector: 'quotaion-approve-dialog',
  templateUrl: 'quotation-approval-dialog.html',
  styleUrls: ['./quotation-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class QuotaionApproveDialog implements OnInit {
  quoteId: number;
  projectId: number;
  confirmLoading: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { quoteId: number, projectId: number },
    private dialogRef: MatDialogRef<QuotaionApproveDialog>,
    private quoteService: QuotationService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.quoteId = this.data?.quoteId;
    this.projectId = this.data?.projectId;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  saveWorkOrder(id: number): void {
    // Call saveWorkOrder with the quoteId
    this.quoteService.saveWorkOrder(id).subscribe({
      next: (response) => {
        this.toastr.success('Work order saved successfully.');
        this.dialogRef.close(true);
        const createWOrkOrderID = response.data.saveWorkOrder;
        this.router.navigate([
          `work-order/work-order-edit/${createWOrkOrderID}`,
        ]);
        this.confirmLoading = true;
      },
      error: (err) => {
        console.error('Error saving work order:', err);
        this.toastr.error(err);
        this.confirmLoading = true;
      },
    });
  }

  onApprove(): void {
    if (!this.quoteId) {
      this.toastr.error('No quote ID provided.', 'Error');
      return;
    }
    this.confirmLoading = true;
    if (!this.projectId) {
      //Creating quote project
      this.quoteService.createQuoteProject(this.quoteId).subscribe({
        next: (response) => {
          if (response?.data?.createQuoteProject) {
            this.toastr.success('Project created successfully.');
            this.saveWorkOrder(this.quoteId);
          }
          this.confirmLoading = true;
        },
        error: (err) => {
          console.error('Error creating project:', err);
          this.toastr.error(err);
          this.confirmLoading = true;
        }
      })
    }
    else {
      this.saveWorkOrder(this.quoteId);
    }
  }
}
