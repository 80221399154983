import {
    Component,
    OnInit,
    HostListener,
    ViewChild,
    OnDestroy
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './core/header/header.component';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { LoadingSpinnerComponent } from './shared/components/loading-spinner/loading-spinner.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { BreadcrumbService } from './core/services/breadcrumb.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        HeaderComponent,
        SidebarComponent,
        CommonModule,
        LoadingSpinnerComponent,
        RouterLink,
        RouterOutlet
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit,
    OnDestroy {

    breadcrumbs: Array<any> = [];
    private subscriptions = new Subscription();
    title = 'Munawel-frontend';

    dataFromLayout = false;
    isCollapsed = false;

    isSpecialRoute: boolean;

    constructor(private router: Router, private breadcrumbService: BreadcrumbService) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) { // Define routes that should be styled similarly to /login
                const specialRoutes = ['/login', '/forgot-password', '/reset-password'];
                this.isSpecialRoute = specialRoutes.includes(this.router.url);
            }
        });
    }

    @ViewChild(SidebarComponent) sidebarComponent: SidebarComponent;

    ngOnInit(): void {
        this.checkWindowSize();

        this.subscriptions.add(this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    goBack(): void {
        if (this.breadcrumbs.length > 1) {
            const previousBreadcrumb = this.breadcrumbs[this.breadcrumbs.length - 2];
            this.router.navigate([previousBreadcrumb.path]);
        }
    }

    shouldShowBackButton(): boolean {
        return this.breadcrumbs.length > 1;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
        this.checkWindowSize();
    }

    toggleSidebar() {
        this.isCollapsed = !this.isCollapsed;
        this.dataFromLayout = !this.dataFromLayout;
        // Close all expansion panels when toggling the sidebar

        this.sidebarComponent.toggleExpansionPanel();
    }

    checkWindowSize(): void {
        const width = window.innerWidth;
        this.dataFromLayout = width > 768 && width < 1100;
        this.isCollapsed = width > 768 && width < 1100;
    }

    triggerSidebarOnce() {
        if (this.isCollapsed) { // Check if sidebar is collapsed
            this.isCollapsed = false;
            this.dataFromLayout = false;
        }
    }
}
