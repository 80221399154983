import { Component, Inject, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { Observable, Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { noSpaceAtStartOrEnd } from '../../helpers/helper';

@Component({
  selector: 'app-inventory-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    AgGridAngular,
    PermissionDirective,
  ],
  templateUrl: './inventory-details.component.html',
  styleUrl: './inventory-details.component.scss',
})
export class InventoryDetailsComponent implements OnInit {
  selectedTabIndex = 0; // Default to the first tab
  itemId: any;
  itemData: any = {};
  allItems: any[] = [];
  public loading: boolean = true;
  private searchSubject = new Subject<string>();
  public Permission: any = Permission;

  constructor(
    private router: Router,
    private itemService: InventoryItemService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.fetchAllItems();
    this.route.paramMap.subscribe((params) => {
      this.itemId = params.get('id');
      if (this.itemId) {
        this.fetchAllItems();
        const id = +this.itemId;
        this.itemService.fetchItemById(id).subscribe({
          next: (data) => {
            console.log(data);
          },
        });
      }
    });
    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchAllItems(searchTerm);
      });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  fetchAllItems(search: string = '', filter: any = {}) {
    this.loading = true;
    this.itemService.fetchItems(search, filter).subscribe({
      next: (items) => {
        this.allItems = items;
        if (this.itemId) {
          const targetId = +this.itemId;
          this.selectedTabIndex = this.allItems.findIndex((c: any) => {
            return c.id === targetId;
          });
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
        this.itemData = this.allItems[this.selectedTabIndex];
      },
      error: (err) => {
        console.error('Error fetching all items:', err);
      },
    });
  }

  navigateToAddNew() {
    this.router.navigate(['/inventory/inventory-add']);
  }

  navigateToEditPage(): void {
    const selectedJournal = this.allItems[this.selectedTabIndex];
    this.itemId = selectedJournal.id;

    this.router.navigate([`inventory/inventory-edit/${this.itemId}`]);
  }

  selectTab(index: number) {
    this.selectedTabIndex = index;
    this.itemData = this.allItems[index];
    if (this.itemData) {
      this.router.navigate([
        `inventory/inventory-details/${this.itemData?.id}`,
      ]);
    }
  }

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(UploadInventoryDetailsDialog);
  }

  openAddStockDialog() {
    const dialogRef = this.dialog.open(AddStockDetailsDialog);
  }

  rowData = [
    {
      date: '24/08/2024',
      quoteNumber: 'QT-021552002',
      customerName: 'Darlene Robertson',
      quantity: '100',
      price: 'AED 260',
      total: 'AED 26,000',
      status: 'Sent',
    },
    {
      date: '24/08/2024',
      quoteNumber: 'QT-021552002',
      customerName: 'Darlene Robertson',
      quantity: '100',
      price: 'AED 260',
      total: 'AED 26,000',
      status: 'Invoiced',
    },
    {
      date: '24/08/2024',
      quoteNumber: 'QT-021552002',
      customerName: 'Darlene Robertson',
      quantity: '100',
      price: 'AED 260',
      total: 'AED 26,000',
      status: 'Declined',
    },
  ];

  columnDefs = [
    {
      headerName: 'Date',
      field: 'date',
      flex: 1,
    },
    { headerName: 'Quote Number', field: 'quoteNumber', flex: 1 },
    { headerName: 'Customer Name', field: 'customerName', flex: 1 },
    { headerName: 'Quantity', field: 'quantity', flex: 1 },
    { headerName: 'Price', field: 'price', flex: 1 },
    { headerName: 'Total', field: 'total', flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        let color = '#000'; // Default black
        switch (params.value) {
          case 'Sent':
            color = '#EFBE12';
            break;
          case 'Invoiced':
            color = '#00A860';
            break;
          case 'Declined':
            color = '#AB0010';
            break;
        }
        return `<p style="display: block; line-height: 1.5; color:${color};">${params.value}</p>`;
      },
    },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
}

@Component({
  selector: 'upload-inventory-details-dialog',
  templateUrl: 'upload-inventory-details-dialog.html',
  styleUrls: ['./inventory-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadInventoryDetailsDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}

@Component({
  selector: 'add-stock-dialog',
  templateUrl: 'add-stock-dialog.html',
  styleUrls: ['./inventory-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
})
export class AddStockDetailsDialog {
  addStockForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log(this.data);

    if (!this.data) {
      this.addStockForm = this.fb.group({
        quantity: ['', [Validators.required, noSpaceAtStartOrEnd]],
      });
    } else {
      this.addStockForm = this.fb.group({
        quantity: ['', [Validators.required, noSpaceAtStartOrEnd]],
      });
    }
  }
}
