<section class="cmn-innerpage-wrapper inventory-details">
  <div class="head">
    <div class="search-wrapper">
      <input type="text" class="cmn-header-search" placeholder="Search" />
    </div>
  </div>
  <div class="inventory-main-tab-wrapper">
    <div class="tab-left">
      <div class="tab-box-main-wrapper">
        <button class="tab-custom-button active">
          <div class="inn-box">
            <h2>Item A</h2>
            <h6>SKU12345</h6>
          </div>
          <div class="inn-box">
            <h6>10 kg</h6>
            <h5>Type 1</h5>
          </div>
        </button>

        <button class="tab-custom-button">
          <div class="inn-box">
            <h2>Item B</h2>
            <h6>SKU67890</h6>
          </div>
          <div class="inn-box">
            <h6>5 L</h6>
            <h5>Type 2</h5>
          </div>
        </button>

        <button class="tab-custom-button">
          <div class="inn-box">
            <h2>Item C</h2>
            <h6>SKU54321</h6>
          </div>
          <div class="inn-box">
            <h6>20 pcs</h6>
            <h5>Type 3</h5>
          </div>
        </button>
      </div>
    </div>
    <div class="inventory-main-tab-content-wrapper">
      <h4 class="heading">
        African Queen Lamer Dubai
        <span>24/08/2024</span>
      </h4>
      <div *ngIf="isNewRequest" class="chart-account-form-wrapper">
        <div class="form-wrapper">
          <label for="project">Project</label>
          <mat-form-field>
            <mat-select placeholder="Search Project" id="location">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search Project"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option value="hai">Hai</mat-option>
              <mat-option value="test">Test</mat-option>
              <mat-option value="new">New</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Project Id<span class="red">*</span></label>
          <mat-form-field>
            <mat-select
              placeholder="Choose Project Id"
              formControlName="projectId"
            >
              <mat-option value="A">A</mat-option>
              <mat-option value="B">B</mat-option>
              <mat-option value="C">C</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="!isNewRequest" class="client-details-overview-wrapper">
        <div class="details-wrapper">
          <h2>Project</h2>
          <h3>A</h3>
        </div>
        <div class="details-wrapper">
          <h2>Project Id</h2>
          <h3>A123</h3>
        </div>
      </div>
      <div class="item-service-adding-table-wrapper" formArrayName="items">
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
              <th>Return</th>
              <th>Unit</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <label class="checkbox-wrapper">
                  <input type="checkbox" />
                  <span class="checkmark cheakmark-rqst-item"></span>
                </label>
                hau
              </td>
              <td>110</td>
              <td>50</td>
              <td>NOS</td>
              <td>
                <div class="action-wrapper">
                  <img
                    src="../../../assets/images/icons/icon-edit.svg"
                    alt="Edit"
                  />
                  <img
                    src="../../../assets/images/icons/icon-delete.svg"
                    alt="Delete"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="isNewRequest" class="submit-btn-wrapper">
        <button class="cmn-cancel-btn">Cancel</button>
        <button class="cmn-next-btn" (click)="sentRequest()">Sent Request</button>
      </div>
      <div *ngIf="!isNewRequest" class="submit-btn-wrapper">
        <button class="cmn-cancel-btn reject-btn" (click)="openApprovalRejectDialog()">Reject</button>
        <button class="cmn-next-btn" (click)="sentRequest()">Approve</button>
      </div>
    </div>
  </div>
</section>
