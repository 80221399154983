import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute } from '@angular/router';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';

@Component({
  selector: 'app-request-item-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    AgGridAngular,
    PermissionDirective,
    MatOptionModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
  ],
  templateUrl: './request-item-details.component.html',
  styleUrl: './request-item-details.component.scss',
})
export class RequestItemDetailsComponent implements OnInit {
  selectedTabIndex = 0; // Default to the first tab
  itemId: any;
  itemData: any = {};
  allItems: any[] = [];
  public loading: boolean = true;
  private searchSubject = new Subject<string>();
  public Permission: any = Permission;
  isNewRequest: boolean = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.itemId = params.get('id');
    });
    this.searchSubject.pipe(debounceTime(600), distinctUntilChanged());

    this.route.queryParams.subscribe((params) => {
      this.isNewRequest = params['newRequest'] === 'true';
    });
  }

  onCellClicked(event: any) {
    this.isNewRequest = false; // Show p tag with status
  }

  getStatusClass(status: string): string {
    return status === 'Completed' ? 'text-completed' : 'text-pending';
  }

  rowData = [
    {
      itemName: 'Ultratech Cement',
      skuCode: 'CM12034562',
      rate: 'AED 2,500',
      unit: 'Kg',
    },
    {
      itemName: 'Ultratech Cement',
      skuCode: 'CM12034562',
      rate: 'AED 2,500',
      unit: 'Kg',
    },
  ];

  columnDefs = [
    {
      headerName: 'Name',
      field: 'itemName',
      flex: 1,
    },
    { headerName: 'SKU', field: 'skuCode', flex: 1 },
    { headerName: 'Rate', field: 'rate', flex: 1 },
    { headerName: 'Unit', field: 'unit', flex: 1 },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
}
