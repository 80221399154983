import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ChartAccountsService } from '../../features/chart-of-accounts/services/chart-accounts.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ThisReceiver } from '@angular/compiler';
import { Router } from '@angular/router';
import { noSpaceAtStartOrEnd } from '../../helpers/helper';

@Component({
  selector: 'app-new-chart-account',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './new-chart-account.component.html',
  styleUrl: './new-chart-account.component.scss'
})
export class NewChartAccountComponent {
  accountType: any;
  search: any;
  accounts: any;
  isSubAccount: boolean = false;
  parentAccountId: any;
  chartAccounts: any;
  subAccount: any;
  createAccountForm: FormGroup
  chartAccountInput: any;

  constructor(
    private chartAccountService : ChartAccountsService,
    private fb : FormBuilder,
    private toastr : ToastrService,
    private route : Router,
  ){}

  ngOnInit(){
    this.fetchAccounts();
    this.fetchChartAccounts();

    this.createAccountForm = this.fb.group({
      accountType: ['', Validators.required],
      accountName: ['', [Validators.required, noSpaceAtStartOrEnd]],
      parentAccountId: [''],
      accountCode: ['', [Validators.required, noSpaceAtStartOrEnd]],
      description: [''],
      isSubAccount: [false],
    })
  }

  toggleSubAccount(checked: boolean) {
    this.isSubAccount = checked;
    this.createAccountForm.patchValue({
      isSubAccount: checked
    });
  }

  fetchAccounts(){
    this.chartAccountService.fetchAccounts(this.accountType, this.search).subscribe({
      next: (accounts: any) => {
        this.accounts = accounts;
        console.log(this.accounts);
        
      },
      error: (error) => {}
    })
  }

  fetchChartAccounts() {
    this.chartAccountService.chartOfAccounts(this.accountType, this.search, this.parentAccountId, this.subAccount).subscribe({
      next: (data) => {
        this.chartAccounts = data;
      },
      error: (err) => {
        console.error('Error fetching chart accounts:', err);
      }
    });
  }

  onSave(){
    if (this.createAccountForm.invalid) {
      this.createAccountForm.markAllAsTouched();
      return;
    } 
    else if(this.createAccountForm.value.parentAccountId){
      this.parentAccountId = Number(this.createAccountForm.value.parentAccountId);
    }
    this.chartAccountInput = this.createAccountForm.value;
    this.chartAccountService.createChartAccounts(this.chartAccountInput, this.parentAccountId).subscribe({
      next: (data) => {
        if(data){
          this.createAccountForm.reset();
          this.toastr.success("Account created successfully", 'Success');
          this.route.navigate(['/chart-of-accounts'])
        }
      },
      error: (error) => {
        this.toastr.error(error?.message, 'Error')
      }
    })
  }

  onCancel(){
    this.createAccountForm.reset();
  }

}
