
  <section class="cmn-innerpage-wrapper attendance">
    <div class="head">
      <div class="search-wrapper" *appHasPermission="['22', Permission.List]">
        <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)" />
      </div>
      <div class="form-wrapper" *appHasPermission="['22', Permission.List]">
        <form [formGroup]="dateRangeForm">
          <mat-form-field>
            <mat-date-range-input [rangePicker]="picker" [dateFilter]="dateFilter">
              <input matStartDate placeholder="Start date" formControlName="startDate"
                (dateInput)="onDateChange($event, 'start')">
              <input matEndDate placeholder="End date" formControlName="endDate"
               (dateInput)="onDateChange($event, 'end')" />
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </form>
      </div>
      <button class="cmn-filter-btn" *appHasPermission="['22', Permission.List]" (click)="toggleDropdown($event)" [ngClass]="{ 'class-clicked': isDropdownOpen }">
        <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
      </button>
      <!-- Filter Popup -->
      <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
        <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
        <div>
          <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
            <h6 class="cmn-popup-heading">
              Filter <span (click)="clearFilter()">Clear All</span>
            </h6>
            <div class="filter-inner-content">
              <h2>Employee Type</h2>
              <div formArrayName="employeeTypes" class="check-wrapper">
                <div class="check-box" *ngFor="let employeeType of employeeTypes; let i = index">
                  <label class="checkbox-wrapper">
                    <input type="checkbox" [formControlName]="i" />
                    {{ employeeType?.employeeType }}
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>

              <h2>Employee Status</h2>
              <div formArrayName="employeeStatus" class="check-wrapper">
                <div class="check-box" *ngFor="let status of employeeStatus; let i = index">
                  <label class="checkbox-wrapper">
                    <input type="checkbox" [formControlName]="i" />
                    {{ status?.employeeStatus }}
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>

            <div class="btn-wrapper">
              <button class="cmn-apply-btn" type="submit">Apply</button>
            </div>
          </form>
        </div>
      </div>
      <!-- Filter Popup -->
      <button class="cmn-upload-btn" *appHasPermission="['22', Permission.Upload]" (click)="openDialog()">
        <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
        <span>Upload</span>
      </button>
    </div>
    <div class="inner-content" *appHasPermission="['22', Permission.List]">
      <ag-grid-angular style="width: 100%" [rowData]="rowData" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
        [rowSelection]="rowSelection" [suppressRowClickSelection]="true" [pagination]="true"
        [paginationPageSize]="limit" [paginationPageSizeSelector]="paginationPageSizeSelector"
        [paginationAutoPageSize]="false" (paginationChanged)="onPaginationChanged($event)" [class]="themeClass" />
    </div>
  </section>
