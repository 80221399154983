import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PermissionState } from './permission.reducer';

export const selectPermissionState = createFeatureSelector<PermissionState>('permissions');

export const selectPermissions = createSelector(
  selectPermissionState,
  (state: PermissionState) => state.permissions
);

export const selectMyProfile = createSelector(
  selectPermissionState,
  (state: PermissionState) => state.myProfile
);

export const selectHeaderTitle = createSelector(
  selectPermissionState,
  (state: PermissionState) => state.headerTitle
);

export const hasPermission = (permission: any) =>
  createSelector(selectPermissions, (permissions: any[]) =>
    permissions.includes(permission)
  );
