import gql from 'graphql-tag';

export const CREATE_DESIGNATION = gql`
  mutation createDesignation($input: CreateDesignationInput!) {
    createDesignation(createDesignationInput: $input) {
      id
      designationName
      employee {
        id
        employeeCode
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        email
        phoneNumber
        alternatePhoneNumber
        address
        permanentAddress
        nativeAddress
        dateOfJoining
        employmentType
        employeeType
        employeeStatus
        profilePicture
        maritalStatus
        nationality
        passportNumber
        passportIssueDate
        passportExpiryDate
        emiratesIDNo
        laborCardExpiryDate
        drivingLicenseNumber
        promotionDate
        previousDesignation
        warningLetterIssuedDate
        warningLetterReasons
        visaType
        visaNumber
        visaIssueDate
        visaExpiryDate
        salary
        bankName
        bankAccountNumber
        IFSCCode
        SWIFTCode
        phoneStdCode
        nativeStdCode
        isPortalUser
        isApprover
        status
        departmentId
      }
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const UPDATE_DESIGNATION = gql`
  mutation updateDesignation($updateDesignationInput: UpdateDesignationInput!) {
    updateDesignation(updateDesignationInput: $updateDesignationInput) {
      id
      designationName
      employee {
        id
        employeeCode
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        email
        phoneNumber
        alternatePhoneNumber
        address
        permanentAddress
        nativeAddress
        dateOfJoining
        employmentType
        employeeType
        employeeStatus
        profilePicture
        maritalStatus
        nationality
        passportNumber
        passportIssueDate
        passportExpiryDate
        emiratesIDNo
        laborCardExpiryDate
        drivingLicenseNumber
        promotionDate
        previousDesignation
        warningLetterIssuedDate
        warningLetterReasons
        visaType
        visaNumber
        visaIssueDate
        visaExpiryDate
        salary
        bankName
        bankAccountNumber
        IFSCCode
        SWIFTCode
        phoneStdCode
        nativeStdCode
        isPortalUser
        isApprover
        status
      }
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const REMOVE_DESIGNATION_BY_ID = gql`
  mutation removeDesignation($id: Int!) {
    removeDesignation(id: $id)
  }
`;

export const CREATE_EXPENSE = gql`
  mutation createExpense(
    $createExpenseInput: CreateExpenseInput!
    $expenseItems: [CreateExpenseItemInput!]
    $fileAttachments: [CreateExpenseAttachmentInput!]
  ) {
    createExpense(
      createExpenseInput: $createExpenseInput
      expenseItems: $expenseItems
      fileAttachments: $fileAttachments
    ) {
      id
      date
      accountId
      accountData {
        id
        accountName
        accountType
        createdAt
        updatedAt
        deletedAt
      }
      amount
      currencyId
      currencyData {
        id
        currencyCode
        currencyName
        currencySymbol
        exchangeRate
        status
      }
      vendorId
      vendorData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
      }
      customerId
      customerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
      }
      invoiceNo
      note
      isBillable
      projectId
      attachedFiles {
        attachmentId
        expenseId
        fileName
        fileUrl
        mimeType
        fileSize
        uploadedAt
      }
    }
  }
`;

export const UPDATE_EXPENSE = gql`
  mutation updateExpense(
    $updateExpenseInput: UpdateExpenseInput!
    $expenseItems: [UpdateExpenseItemInput!]
    $fileAttachments: [UpdateExpenseAttachmentInput!]
  ) {
    updateExpense(
      updateExpenseInput: $updateExpenseInput
      expenseItems: $expenseItems
      fileAttachments: $fileAttachments
    ) {
      id
      date
      accountId
      accountData {
        id
        accountName
        accountType
        createdAt
        updatedAt
        deletedAt
      }
      amount
      currencyId
      currencyData {
        id
        currencyCode
        currencyName
        currencySymbol
        exchangeRate
        status
      }
      vendorId
      vendorData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
      }
      customerId
      customerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
      }
      invoiceNo
      note
      isBillable
      projectId
      attachedFiles {
        attachmentId
        expenseId
        fileName
        fileUrl
        mimeType
        fileSize
        uploadedAt
      }
    }
  }
`;

export const REMOVE_EXPENSE = gql`
  mutation removeExpense($id: Int!) {
    removeExpense(id: $id) {
      id
      date
      accountId
      accountData {
        id
        accountName
        accountType
        createdAt
        updatedAt
        deletedAt
      }
      amount
      currencyId
      currencyData {
        id
        currencyCode
        currencyName
        currencySymbol
        exchangeRate
        status
      }
      vendorId
      vendorData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
      }
      customerId
      customerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
      }
      invoiceNo
      note
      isBillable
      projectId
      attachedFiles {
        attachmentId
        expenseId
        fileName
        fileUrl
        mimeType
        fileSize
        uploadedAt
      }
    }
  }
`;

export const REMOVE_EXPENSE_ITEM = gql`
  mutation removeExpenseItem($id: Int!) {
    removeExpenseItem(id: $id)
  }
`;

export const CREATE_EXPENSE_ITEM = gql`
  mutation createExpenseItems(
    $createExpenseItemInputs: [CreateExpenseItemInput!]!
  ) {
    createExpenseItems(createExpenseItemInputs: $createExpenseItemInputs) {
      itemId
      expenseId
    }
  }
`;
