import { gql } from "apollo-angular";

export const PUNCH_IN_INFO = gql`
  query getPunchInInfo {
  getPunchInInfo {
    id
    date
    checkInTime
    checkOutTime
    totalHoursWorked
    overtimeHours
    attendanceStatus
    markedBy
    shift
    notes
    employeeId
    employee {
      id
      employeeCode
    }
    status
  }
}
`;