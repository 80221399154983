import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { CreditDebitNoteService } from "../../../features/credit-debit-note/services/credit-debit-note.service";
import { ToastrService } from "ngx-toastr";
import { InvoiceService } from "../../../features/invoice/services/invoice.service";

@Component({
  selector: 'apply-credits-from-invoice-dialog',
  templateUrl: 'apply-credits-from-invoice-dialog.html',
  styleUrls: ['./apply-credits-from-invoice-dialog.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
})
export class ApplyCreditsFromInvoiceDialog implements OnInit {
  invoiceId: number;
  invoiceDetails: any = null;
  partnerId: any;
  allNotes: any = [];
  invoiceForm: FormGroup;
  totalAmountToCredit: number = 0;
  invoiceBalanceDue: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private itemService: CreditDebitNoteService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<ApplyCreditsFromInvoiceDialog>,
    private invoiceService: InvoiceService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    if (this.data) {
      this.invoiceId = +this.data.noteId;
      // this.fetchNote();
      this.fetchInvoiceById();
    }
  }

  initForm(): void {
    const controls = this.allNotes.map((_: any) => this.fb.control(0));
    this.invoiceForm = this.fb.group({
      credits: this.fb.array(controls),
    });
  }

  get credits() {
    return this.invoiceForm.get('credits') as FormArray;
  }

  getCreditControl(index: number): FormControl {
    const invoiceBalance = this.allNotes[index]?.balance || 0;
    if (!this.credits.at(index)) {
      this.credits.insert(index, new FormControl(0, [Validators.max(invoiceBalance), Validators.min(0)]));
    }
    return this.credits.at(index) as FormControl;
  }

  get invoices() {
    return this.invoiceForm.get('invoices') as FormArray;
  }

  initializeCreditControls(): void {
    this.allNotes.forEach((creditNote: any, index: number) => {
      console.log("creditNote", creditNote);
      const creditControl = this.fb.control(
        '',
        [Validators.max(creditNote.balance)]
      );
      this.credits.push(creditControl);
    });
  }

  fetchInvoiceById(): void {
    this.invoiceService.fetchInvoiceById(this.invoiceId).subscribe({
      next: (invoice) => {
        this.invoiceDetails = invoice;
        console.log("Invoice partner details----", this.invoiceDetails.partnerData);
        this.partnerId = this.invoiceDetails?.partnerData?.id;

        if(this.partnerId) {
          this.invoiceService.fetchNotesByCustomerOrVendor(this.partnerId, 'Customer').subscribe({
            next: (response) => {
              this.allNotes = response || []
              this.initForm();
            },
            error: (error) => {
              console.error(error);
            }
          })
        }
      },
      error: (err) => {
        console.error('Error fetching invoice:', err);
      }
    })
  }

  calculateInvoiceBalanceDue(): number {
    return this.allNotes.reduce((totalBalanceDue: number, creditNote: any, index: number) => {
      const creditApplied = this.credits.at(index)?.value || 0; 
      const creditBalance = creditNote.balance || 0; 
      const remainingBalance = Math.max(0, creditBalance - creditApplied);
      return totalBalanceDue + remainingBalance;
    }, 0);
  }

  // calculateAmountToCredit(): void {
  //   const availableCredits = this.invoiceDetails?.subTotal || 0; // Total Invoice Amount
  //   let totalAppliedCredits = 0;
  
  //   this.credits.controls.forEach((control, index) => {
  //     const creditBalance = this.allNotes[index]?.balance || 0; // Note Balance
  //     const appliedCredit = control.value || 0;
  
  //     // Validate against individual credit note balance
  //     if (appliedCredit > creditBalance) {
  //       control.setErrors({ ...control.errors, exceedsNoteBalance: true });
  //     } else if (control.hasError('exceedsNoteBalance')) {
  //       const { exceedsNoteBalance, ...errors } = control.errors || {};
  //       control.setErrors(Object.keys(errors).length ? errors : null);
  //     }
  
  //     totalAppliedCredits += appliedCredit;
  //   });
  
  //   // Validate against total invoice amount
  //   if (totalAppliedCredits > availableCredits) {
  //     this.credits.controls.forEach((control) => {
  //       control.setErrors({ ...control.errors, exceedsInvoiceAmount: true });
  //     });
  //   } else {
  //     this.credits.controls.forEach((control) => {
  //       if (control.hasError('exceedsInvoiceAmount')) {
  //         const { exceedsInvoiceAmount, ...errors } = control.errors || {};
  //         control.setErrors(Object.keys(errors).length ? errors : null);
  //       }
  //     });
  //   }
  
  //   this.totalAmountToCredit = Math.min(totalAppliedCredits, availableCredits);
  //   this.invoiceBalanceDue = this.calculateInvoiceBalanceDue();
  // }

  calculateAmountToCredit(): void {
    const availableCredits = this.invoiceDetails?.totalPrice || 0; // Total Invoice Amount
    let totalAppliedCredits = 0;
  
    this.credits.controls.forEach((control, index) => {
      const creditBalance = this.allNotes[index]?.balance || 0; // Note Balance
      const appliedCredit = control.value || 0;
  
      // Validate against individual credit note balance
      if (appliedCredit > creditBalance) {
        control.setErrors({ exceedsNoteBalance: true });
      } else {
        control.setErrors(null);
      }
  
      totalAppliedCredits += appliedCredit;
    });
  
    // Validate against total invoice amount
    if (totalAppliedCredits > availableCredits) {
      this.credits.controls.forEach((control) => {
        control.setErrors({ exceedsInvoiceAmount: true });
      });
    }
  
    this.totalAmountToCredit = Math.min(totalAppliedCredits, availableCredits);
    this.invoiceBalanceDue = this.calculateInvoiceBalanceDue();
  }

  onCancel(): void {
    this.dialogRef.close();
  }  

  applyCreditsToInvoices(): void {

    if (this.invoiceForm.invalid) {
      this.invoiceForm.markAllAsTouched();
      this.toastr.error("Please fix validation errors before submitting.");
      return;
    }

    const notes = this.credits.controls.map((control, index) => {
      const appliedCredit = control.value || 0;
      const noteDetail = this.allNotes[index];

      if (appliedCredit <= 0) {
        return null;
      }

      return {
        invoiceId: this.invoiceDetails.id,
        refId: noteDetail.id,
        type: 'CN',
        amount: appliedCredit,
      };
    }).filter(note => note !== null);

    if (notes.length === 0) {
      console.error('No credits to apply.');
      return;
    }


    const payload = {
      notes,
    };

    this.itemService.applytoinvoice(payload).subscribe({
      next: (response) => {
        this.toastr.success('Credits applied to invoices successfully.');
        this.dialogRef.close();
      },

      error: (error) => {
        this.toastr.error(error?.message || 'Failed to apply credits to invoices.');
        console.log("Apply invoice error:", error);

      },
    });
  }

}