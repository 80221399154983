<div class="tab-inner-content">
    <h2>Payment Made Report</h2>
    <h5>
      From {{ startDate | date : "MM/d/YYYY" }} to
      {{ endDate | date : "MM/d/YYYY" }}
    </h5>
  
    <table>
      <thead>
        <tr>
          <th>Payment Number</th>
          <th>Payment Mode</th>
          <th>Bill Date</th>
          <th>Bill Number</th>
          <th>Vendor Name</th>
          <th>Notes</th>
          <th>Paid Through</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of paymentMadeReportData" class="child">
          <td>{{ report?.paymentNo }}</td>
          <td>{{ report?.paymentMode }}</td>
          <td>{{ report?.billDate | date : "mediumDate" }}</td>
          <td>{{ report?.billNo }}</td>
          <td>{{ report?.vendorName }}</td>
          <td>{{ report?.note }}</td>
          <td>{{ report?.paidThrough }}</td>
          <td>{{ report?.status }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  