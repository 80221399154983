<section class="login-wrapper">
  <div class="left-set">
    <div class="img-wrapper">
      <img src="assets/images/login-img.jpg" alt="Login Image" />
    </div>
  </div>
  <div class="right-set">
    <div class="logo-wrapper">
      <img src="assets/images/logo.svg" alt="Logo" />
    </div>
    <div class="form-wrapper">
      <h2 class="cmn-heading-1">Login</h2>
      <div class="form-content">
        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <div class="form-group">
            <mat-form-field class="example-full-width">
              <mat-label>Email Address</mat-label>
              <input
                matInput
                placeholder=""
                id="email"
                formControlName="email"
              />
              <mat-error
                *ngIf="
                  loginForm.get('email')?.invalid
                "
              >
                <span *ngIf="loginForm.get('email')?.errors?.['invalidEmail']">
                  Please enter a valid email address.
                </span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input
                id="password"
                formControlName="password"
                matInput
                [type]="hide() ? 'password' : 'text'"
              />
              <button
                mat-icon-button
                matSuffix
                (click)="clickEvent($event)"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide()"
                type="button"
              >
                <mat-icon>{{
                  hide() ? "visibility_off" : "visibility"
                }}</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-sub-set">
            <label class="checkbox-wrapper">
              <input type="checkbox" formControlName="rememberMe" />Remember Me
              <span class="checkmark"></span>
            </label>
            <h3 routerLink="/forgot-password" class="frgt-btn">
              Forgot Password?
            </h3>
          </div>
          <div class="btn-wrapper">
            <button type="submit" [disabled]="loading">
              <span *ngIf="!loading">Login</span>
              <span *ngIf="loading">Loading...</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
