import { Component, HostListener, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { GRNServices } from '../../features/grn/services/grn.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';
import * as selectPermissions from '../../core/permissions/permission.selector';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-grn',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    AgGridAngular,
    FormsModule,
    ReactiveFormsModule,
    PermissionDirective
  ],
  templateUrl: './grn.component.html',
  styleUrl: './grn.component.scss'
})
export class GrnComponent {
  public viewPortSize: boolean = false;
  public rowData: any[] | null = null;
  search: any;
  searchInputControl: FormControl = new FormControl();
  vendorControl = new FormControl();
  readonly dialog = inject(MatDialog);
  columnDefs: ({ headerName: string; field: string; flex: number; cellRenderer?: undefined; floatingFilter?: undefined; filter?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer: (params: any) => string; floatingFilter?: undefined; filter?: undefined; } | { field: string; headerName: string; flex: number; cellRenderer: (params: any) => HTMLDivElement; floatingFilter: boolean; filter: boolean; })[];
  // profileName: any;
  Permission = Permission;

  constructor(
    private router: Router,
    private grnService: GRNServices,
    private toastr: ToastrService,
    private store: Store,
  ) { 
    // const loggedInUser: any = localStorage.getItem('loggedInUser');
    // const userData = JSON.parse(loggedInUser);
    // this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit() {
    this.listGoodsReceived();
    this.searchInputControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.search = value;
        this.listGoodsReceived();
      });

    this.checkViewportSize();
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {
    this.columnDefs = [
      {
        headerName: 'Vendor Name',
        field: 'vendorName',
        flex: this.viewPortSize ? 1 : 0,

      },
      { headerName: 'Project Name', field: 'projectName', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'PO Amount', field: 'POAmount', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'PO Number', field: 'PONumber', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Status', field: 'status', flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const status = params.data.status;
          const statusClass = status === 'Completed' ? 'green' : status === 'Pending' ? 'yellow' : 'red';;
          return `<span class="${statusClass}">${status}</span>`;
        }
      },
      {
        field: 'action',
        headerName: 'Actions',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.alignItems = 'center';
          container.style.gap = '20px';

          const editIcon = document.createElement('img');
          editIcon.src = 'assets/images/icons/icon-edit.svg';
          editIcon.style.cursor = 'pointer';
          editIcon.setAttribute('data-action', 'edit');
          // editIcon.addEventListener('click', (event) => {
          //   event.stopPropagation();
          //   this.onEdit(params?.data?.id, params?.data);
          // });

          this.store.select(selectPermissions.hasPermission('16', Permission.Edit)).subscribe((canEdit) => {
            if (canEdit) {
              container.appendChild(editIcon);
            }
          });

          const deleteIcon = document.createElement('img');
          deleteIcon.src = 'assets/images/icons/icon-delete.svg';
          deleteIcon.style.cursor = 'pointer';
          deleteIcon.setAttribute('data-action', 'delete');

          this.store.select(selectPermissions.hasPermission('16', Permission.Delete)).subscribe((canDelete) => {
            if (canDelete) {
              container.appendChild(deleteIcon);
            }
          });
          // deleteIcon.addEventListener('click', (event) => {
          //   event.stopPropagation();
          //   this.onConfirmDelete(params?.data?.id);
          // });

          // container.appendChild(editIcon);

          // if(this.profileName === 'admin'){
          //   container.appendChild(deleteIcon);
          // }


          return container;
        },
        floatingFilter: false,
        filter: false,
      },
    ];
  }

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';


  listGoodsReceived() {
    this.grnService.listGoodsReceived(this.search).subscribe({
      next: (data) => {
        this.rowData = data
          .map((data: any) => {
            return {
              id: data?.id || '--',
              vendorName: data?.purchaseOrderData?.customer?.displayName || '--',
              projectName: data?.purchaseOrderData?.project?.projectName || '--',
              POAmount: data?.purchaseOrderData?.totalPrice
                ? `AED ${data.purchaseOrderData.totalPrice}.00`
                : 'AED 0.00',
              PONumber: data?.purchaseOrderData?.purchaseOrderNo || '--',
              status: data?.grnStatus || '--',
            };
          })
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  onCellClicked(params: any) {
    const grnId = params.data?.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.onEdit(grnId);
      } else if (action === 'delete') {
        this.onConfirmDelete(grnId);
      }
    } else {
      this.router.navigate([`/goods-received-note/grn-details/${grnId}`]);
    }
  }

  onConfirmDelete(id: number): void {
    console.log(id);

    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onDelete(id);
      }
    });
  }

  onDelete(id: any) {
    this.grnService.deleteGoodsReceived(id).subscribe({
      next: (result) => {
        if (result) {
          this.toastr.success("GRN deleted successfully", 'Success');
          this.listGoodsReceived();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }

  onEdit(id: any) {
    this.router.navigate([`/goods-received-note/grn-edit/${id}`]);
  }

}
