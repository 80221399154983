import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit, inject } from '@angular/core';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { Store } from '@ngrx/store';
import * as selectPermissions from '../../core/permissions/permission.selector';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';

@Component({
  selector: 'app-request-item',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    AgGridAngular,
    PermissionDirective,
    MatTabsModule,
  ],
  templateUrl: './request-item.component.html',
  styleUrl: './request-item.component.scss',
})
export class RequestItemComponent implements OnInit {
  public viewPortSize: boolean = false;
  public loading: boolean = true;
  public error: any = null;
  readonly dialog = inject(MatDialog);
  columnDefs: any[] = [];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10];
  public themeClass: string = 'ag-theme-quartz';
  Permission = Permission;
  // profileName: any;
  isNewRequest: boolean = false;
  constructor(private router: Router, private store: Store) {}

  ngOnInit(): void {
    this.checkViewportSize();
  }
  onNewRequestClick() {
    this.isNewRequest = true;
    this.router.navigate(['request-item/request-item-details'], {
      queryParams: { newRequest: true },
    });
  }
  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.updateColumnDefs();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  rowData = [
    {
      project: 'African Queen Lamer Dubai',
      requestor: 'Robert Allen',
      date: '24/08/2024',
      status: 'Completed',
    },
    {
      project: 'African Queen Lamer Dubai',
      requestor: 'Robert Allen',
      date: '24/08/2024',
      status: 'Pending',
    },
  ];

  updateColumnDefs(): void {
    this.columnDefs = [
      {
        headerName: 'Project',
        field: 'project',
        flex: this.viewPortSize ? 1 : 0,
        valueFormatter: (params: any) => {
          if (!params.value) return '';
          return (
            params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          );
        },
      },
      {
        headerName: 'Requestor',
        field: 'requestor',
        flex: this.viewPortSize ? 1 : 0,
      },
      { headerName: 'Date', field: 'date', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Status',
        field: 'status',
        sortable: true,
        filter: true,
        cellRenderer: (params: any) => {
          let color = '#000'; // Default black
          switch (params.value) {
            case 'Completed':
              color = '#00A860';
              break;
            case 'Pending':
              color = '#EFBE12';
              break;
          }
          return `<p style="display: block; line-height: 1.5; color:${color};">${params.value}</p>`;
        },
      },
    ];

    this.columnDefs.push({
      field: 'action',
      headerName: 'Actions',
      floatingFilter: false,
      filter: false,
      flex: this.viewPortSize ? 1 : 0,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const editIcon = document.createElement('img');
        editIcon.src = 'assets/images/icons/icon-edit.svg';
        editIcon.style.cursor = 'pointer';
        editIcon.setAttribute('data-action', 'edit');
        // this.canUpdate$.subscribe(canUpdate => {
        this.store
          .select(selectPermissions.hasPermission('2', Permission.Edit))
          .subscribe((canEdit) => {
            if (canEdit) {
              container.appendChild(editIcon);
            }
          });

        const deleteIcon = document.createElement('img');
        deleteIcon.src = 'assets/images/icons/icon-delete.svg';
        deleteIcon.style.cursor = 'pointer';
        deleteIcon.setAttribute('data-action', 'delete');
        // this.canDelete$.subscribe(canDelete => {
        this.store
          .select(selectPermissions.hasPermission('2', Permission.Delete))
          .subscribe((canDelete) => {
            if (canDelete) {
              container.appendChild(deleteIcon);
            }
          });

        // })

        return container;
      },
    });
  }
  onCellClicked(params: any) {
    this.router.navigate([`request-item/request-item-details`]);
  }
}
