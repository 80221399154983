<section class="cmn-innerpage-wrapper inventory-details">
  <div class="head">
    <div class="search-wrapper">
      <input type="text" class="cmn-header-search" placeholder="Search" />
    </div>
    <div class="btn-wrapper">
      <button class="cmn-add-btn" (click)="onNewRequestClick()">
        <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add New
      </button>
    </div>
  </div>
  <div class="inventory-main-tab-wrapper">
    <div class="inventory-main-tab-content-wrapper">
      <div>
        <div class="chart-tab-content">
          <div class="common-noimage-tab-wrapper">
            <mat-tab-group animationDuration="0ms">
              <!-- Mat Tab One -->
              <mat-tab>
                <ng-template mat-tab-label>
                  <h2 class="leave-tab-title">Pending</h2>
                </ng-template>
                <div class="inventory-tab-inner-content">
                  <ag-grid-angular
                    style="width: 100%"
                    [rowData]="rowData"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [rowSelection]="rowSelection"
                    [suppressRowClickSelection]="true"
                    [pagination]="true"
                    [paginationPageSize]="paginationPageSize"
                    [paginationPageSizeSelector]="paginationPageSizeSelector"
                    [class]="themeClass"
                    (cellClicked)="onCellClicked($event)"
                  />
                </div>
              </mat-tab>
              <!-- Mat Tab Two -->
              <mat-tab>
                <ng-template mat-tab-label>
                  <h2 class="leave-tab-title">Completed</h2>
                </ng-template>
                <div class="inventory-tab-inner-content">
                  <ag-grid-angular
                    style="width: 100%"
                    [rowData]="rowData"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [rowSelection]="rowSelection"
                    [suppressRowClickSelection]="true"
                    [pagination]="true"
                    [paginationPageSize]="paginationPageSize"
                    [paginationPageSizeSelector]="paginationPageSizeSelector"
                    [class]="themeClass"
                    (cellClicked)="onCellClicked($event)"
                  />
                </div>
              </mat-tab>
              <!-- Mat Tab Three -->
              <mat-tab>
                <ng-template mat-tab-label>
                  <h2 class="leave-tab-title">All</h2>
                </ng-template>
                <div class="inventory-tab-inner-content">
                  <ag-grid-angular
                    style="width: 100%"
                    [rowData]="rowData"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [rowSelection]="rowSelection"
                    [suppressRowClickSelection]="true"
                    [pagination]="true"
                    [paginationPageSize]="paginationPageSize"
                    [paginationPageSizeSelector]="paginationPageSizeSelector"
                    [class]="themeClass"
                    (cellClicked)="onCellClicked($event)"
                  />
                </div>
              </mat-tab>
              <!-- Mat Tab Four -->
              <mat-tab>
                <ng-template mat-tab-label>
                  <h2 class="leave-tab-title">My Requests</h2>
                </ng-template>
                <div class="inventory-tab-inner-content">
                  <ag-grid-angular
                    style="width: 100%"
                    [rowData]="rowData"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [rowSelection]="rowSelection"
                    [suppressRowClickSelection]="true"
                    [pagination]="true"
                    [paginationPageSize]="paginationPageSize"
                    [paginationPageSizeSelector]="paginationPageSizeSelector"
                    [class]="themeClass"
                    (cellClicked)="onCellClicked($event)"
                  />
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
