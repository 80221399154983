import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { CustomerService } from '../../../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-statements',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './statements.component.html',
  styleUrl: '../../vendor-details.component.scss'
})
export class StatementsComponent {
  partnerDetails: any;
  customerId: any;
  statements: any;
  startDate: any = '';
  endDate: any = '';
  dateRangeForm: FormGroup;
  // filter: { dateRange: { startDate: any; endDate: any; }; };

  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    const currentDate = new Date();
    this.startDate = new Date(currentDate.setHours(0, 0, 0, 0));
    this.endDate = new Date(currentDate.setHours(23, 59, 59, 999));

    this.route.paramMap.subscribe(params => {
      this.customerId = params.get('id');
      if (this.customerId) {
        this.partnerDetailById(Number(this.customerId), this.startDate, this.endDate);
      }
    });

    this.dateRangeForm = this.fb.group({
      dateFilter: ['Today'],
      startDate: [''],
      endDate: ['']
    });


  }

  onGeneratePdf() {
    this.customerService.generatePartnerStatementPdf(Number(this.customerId), this.startDate, this.endDate).subscribe({
      next: (url) => {
        console.log('url------>', url);
        this.toastr.success('PDF generated successfully', 'Success');
        const token = localStorage.getItem('AUTH_TOKEN');
        fetch(url, {
          method: 'GET',
          headers: {
            'authorization': token ? `Bearer ${token}` : '',
          }
        })
          .then(response => response.blob())
          .then(blob => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a'); // Create a hidden <a> element
            a.href = url;
            a.download = `statement.pdf`; // Set the filename
            document.body.appendChild(a);
            a.click(); // Trigger the download
            document.body.removeChild(a); // Clean up
            URL.revokeObjectURL(url); // Revoke the object URL
            // window.open(url, '_blank');
          })
          .catch(error => console.error('Error:', error));
      },
      error: (error: any) =>
        console.error('Error fetching employee types:', error),
    });
  }

  onDateSelected(event: MatSelectChange) {
    const selectedFilter = event.value;
    console.log('selectedFilter', selectedFilter);
    if (selectedFilter === 'Today') {
      const now = new Date();
      this.startDate = new Date(now.setHours(0, 0, 0, 0));;
      this.endDate = new Date(now.setHours(23, 59, 59, 999));;
    }
    else if (selectedFilter === 'This Week') {
      const now = new Date();
      const dayOfWeek = now.getDay(); //Sunday to Saturday

      this.startDate = new Date(now);
      this.startDate.setDate(now.getDate() - dayOfWeek); // Move back to Sunday
      this.startDate.setHours(0, 0, 0, 0); // Set to 12:00 AM

      this.endDate = new Date(this.startDate);
      this.endDate.setDate(this.startDate.getDate() + 6); // Move forward to Saturday
      this.endDate.setHours(23, 59, 59, 999); // Set to 11:59:59 PM
    }
    else if (selectedFilter === 'This Month') {
      const now = new Date();
      this.startDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
      this.endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
    }
    else if (selectedFilter === 'This Year') {
      const now = new Date();
      const year = now.getFullYear();

      this.startDate = new Date(year, 0, 1, 0, 0, 0, 0);
      this.endDate = new Date(year, 11, 31, 23, 59, 59, 999);
    }
    this.partnerDetailById(this.customerId, this.startDate, this.endDate);
  }

  dateFilter = (date: Date | null): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Remove time component
    return date ? date <= today : false;
  };

  formatDate(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  onDateChange(event: any, type: string) {
    // const formattedDate = this.datePipe.transform(event.value, 'yyyy-MM-dd');
    if (type === 'start') {
      this.startDate = event.value;
    } else if (type === 'end') {
      this.endDate = event.value;
    }
    if (this.startDate != null && this.endDate != null) {
      // this.filter = { dateRange: { startDate: this.startDate, endDate: this.endDate } };
      // this.partnerDetailById(Number(this.customerId));
      console.log('startDate', this.startDate);
      console.log('endDate', this.endDate);
      this.partnerDetailById(this.customerId, this.startDate, this.endDate);
    }
  }


  partnerDetailById(id: any, startDate: any, endDate: any) {
    // const startDate = '';
    // const endDate = '';
    this.customerService.partnerDetails(Number(id), startDate, endDate).subscribe({
      next: (data: any) => {
        this.partnerDetails = data;
        console.log(this.partnerDetails);

      },
      error: (error) => {
        this.toastr.error(error?.message);
      },
    });
  }
}