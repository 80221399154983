import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit, inject } from '@angular/core';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import * as selectPermissions from '../../core/permissions/permission.selector';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';

@Component({
  selector: 'app-warehouse',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    AgGridAngular,
    PermissionDirective,
  ],
  templateUrl: './warehouse.component.html',
  styleUrl: './warehouse.component.scss',
})
export class WarehouseComponent implements OnInit {
  public viewPortSize: boolean = false;
  public loading: boolean = true;
  public error: any = null;
  readonly dialog = inject(MatDialog);
  columnDefs: any[] = [];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10];
  public themeClass: string = 'ag-theme-quartz';
  Permission = Permission;

  constructor(
    private router: Router,
    private itemService: InventoryItemService,
    private toastr: ToastrService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.updateColumnDefs();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  rowData = [
    {
      itemName: 'Lorem Ipsum',
      code: 'WH00123',
      location: 'Dubai, Abu Dhabi',
      contact: 'Employee',
      contactNumber: '+971 1234 1234',
    },
    {
      itemName: 'Lorem Ipsum',
      code: 'WH00123',
      location: 'Dubai, Abu Dhabi',
      contact: 'Employee',
      contactNumber: '+971 1234 1234',
    },
    {
      itemName: 'Lorem Ipsum',
      code: 'WH00123',
      location: 'Dubai, Abu Dhabi',
      contact: 'Employee',
      contactNumber: '+971 1234 1234',
    },
    {
      itemName: 'Lorem Ipsum',
      code: 'WH00123',
      location: 'Dubai, Abu Dhabi',
      contact: 'Employee',
      contactNumber: '+971 1234 1234',
    },
  ];

  updateColumnDefs(): void {
    this.columnDefs = [
      {
        headerName: 'Name',
        field: 'itemName',
        flex: this.viewPortSize ? 1 : 0,
        valueFormatter: (params: any) => {
          if (!params.value) return '';
          return (
            params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          );
        },
      },
      { headerName: 'Code', field: 'code', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Location',
        field: 'location',
        flex: this.viewPortSize ? 1 : 0,
      },
      {
        headerName: 'Contact',
        field: 'contact',
        flex: this.viewPortSize ? 1 : 0,
      },
      {
        headerName: 'Contact Number',
        field: 'contactNumber',
        flex: this.viewPortSize ? 1 : 0,
      },
    ];

    this.columnDefs.push({
      field: 'action',
      headerName: 'Actions',
      floatingFilter: false,
      filter: false,
      flex: this.viewPortSize ? 1 : 0,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const editIcon = document.createElement('img');
        editIcon.src = 'assets/images/icons/icon-edit.svg';
        editIcon.style.cursor = 'pointer';
        editIcon.setAttribute('data-action', 'edit');
        this.store
          .select(selectPermissions.hasPermission('2', Permission.Edit))
          .subscribe((canEdit) => {
            if (canEdit) {
              container.appendChild(editIcon);
            }
          });

        const deleteIcon = document.createElement('img');
        deleteIcon.src = 'assets/images/icons/icon-delete.svg';
        deleteIcon.style.cursor = 'pointer';
        deleteIcon.setAttribute('data-action', 'delete');
        this.store
          .select(selectPermissions.hasPermission('2', Permission.Delete))
          .subscribe((canDelete) => {
            if (canDelete) {
              container.appendChild(deleteIcon);
            }
          });

        return container;
      },
    });
  }
  onCellClicked(params: any) {
    const itemId = params.data?.id;
    this.router.navigate([`warehouse/warehouse-details`]);
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }
}
