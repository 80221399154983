<section class="login-wrapper">
  <div class="left-set">
    <div class="img-wrapper">
      <img src="assets/images/login-img.jpg" alt="Login Image" />
    </div>
  </div>
  <div class="right-set">
    <div class="logo-wrapper">
      <img src="assets/images/logo.svg" alt="Logo" />
    </div>
    <div class="form-wrapper">
      <h2 class="cmn-heading-1">Forgot Password ?</h2>
      <div class="form-content">
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <mat-form-field class="example-full-width">
              <mat-label>Email Address</mat-label>
              <input
                matInput
                placeholder=""
                id="email"
                formControlName="email"
              /><mat-error
                *ngIf="
                  forgotPasswordForm.get('email')?.invalid &&
                  forgotPasswordForm.get('email')?.touched
                "
              >
                <span
                  *ngIf="forgotPasswordForm.get('email')?.errors?.['invalidEmail']"
                >
                  Please enter a valid email address.
                </span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-sub-set">
            <h3 class="frgt-btn">
              Back to <span routerLink="/login">Login</span>
            </h3>
          </div>
          <div class="btn-wrapper">
            <button
              type="submit"
              [disabled]="forgotPasswordForm.invalid || loading"
            >
              Send Reset Instructions
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
