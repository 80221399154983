<ag-grid-angular
style="width: 100%; height: 500px"
class="ag-theme-quartz"
[rowData]="rowData"
[columnDefs]="columnDefs"
[defaultColDef]="defaultColDef"
[pagination]="true"
[paginationPageSize]="paginationPageSize"
[paginationPageSizeSelector]="paginationPageSizeSelector"
[rowSelection]="rowSelection"
[animateRows]="true"
></ag-grid-angular>