import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  CHART_ACCOUNTS,
  GET_ITEMS,
  GET_NOTE_DETAILS,
  GET_PARTNERS,
  NOTE,
  NOTES,
} from '../graphql/credit-debit-note.queries';
import {
  CHANGE_NOTE_STATUS,
  CREATE_COMMENT,
  CREATE_NOTE,
  GENERATE_NOTE_PDF,
  REMOVE_NOTE,
  SEND_NOTE_MAIL,
  UPDATE_NOTE,
} from '../graphql/credit-debit-note.mutations';

@Injectable({
  providedIn: 'root',
})
export class CreditDebitNoteService {
  constructor(private http: HttpClient, private apollo: Apollo) {}

  // munawel-project
  fetchPartners(search: string, type: string): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        variables: {
          search: search,
          partnerType: type,
        },
        query: GET_PARTNERS,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((response) => response.data?.getPartnersDropDownItems)
      );
  }

  // munawel-accounts
  fetchNotes(type: string, search: string): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        variables: {
          noteType: type,
          search: search,
        },
        query: NOTES,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.notes));
  }
  // munawel-accounts
  fetchNote(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        variables: {
          id: id,
        },
        query: NOTE,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.note));
  }

  fetchItems(search: string = '', filter: {}): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_ITEMS,
        variables: { search, filter },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.items));
  }

  // munawel-accounting
  removeNote(id: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_NOTE,
      fetchPolicy: 'no-cache',
      variables: {
        id: id,
      },
    });
  }
  
  // munawel-accounting
  createcomment(createCommentInput: any ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_COMMENT,
      fetchPolicy: 'no-cache',
      variables: {
        createCommentInput: createCommentInput
      }
    })
  }
  // munawel-accounting
  updatenote(updateNoteInput: any , noteItems: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: UPDATE_NOTE,
      fetchPolicy: 'no-cache',
      variables: {
        updateNoteInput: updateNoteInput,
        noteItems: noteItems
      }
    })
  }

  // munawel-accounting
  changestatusnote(noteId: number, newStatus: string ){
    return this.apollo.use('accounts').mutate({
      mutation: CHANGE_NOTE_STATUS,
      fetchPolicy: 'no-cache',
      variables: {
        noteId: noteId,
        newStatus: newStatus
      }
    })
  }

  // munawel-accounting
  createnote(createNoteInput: any, noteItems: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_NOTE,
      fetchPolicy: 'no-cache',
      variables: {
        createNoteInput: createNoteInput,
        noteItems: noteItems,
      },
    });
  }

  // munawel-accounts
  chartOfAccounts(
    accountType: any,
    search: any,
    parentAccountId: any,
    isSubAccount: any
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: CHART_ACCOUNTS,
        variables: {
          accountType,
          search,
          parentAccountId,
          isSubAccount,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.chartOfAccounts || []));
  }

  // munawel-accounts
  getNoteDetails(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_NOTE_DETAILS,
        variables: { id: id },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.getNoteDetails));
  }

  sendNoteMail(
    noteId: any,
    to: any[],
    cc: any[],
    subject: any,
    body: any,
    attachment?: any,
    isAttachment?: boolean
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: SEND_NOTE_MAIL,
      variables: {
        noteId: noteId,
        to: to,
        cc: cc,
        subject: subject,
        body: body,
        attachment: attachment,
        isAttachment: isAttachment,
      },
      context: {
        headers: {
          'x-apollo-operation-name': 'file-upload',
          'apollo-require-preflight': 'true',
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  generateNotePdf(noteId: any, noteType: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GENERATE_NOTE_PDF,
        fetchPolicy: 'no-cache',
        variables: {
          noteId: noteId,
          noteType: noteType,
        },
      })
      .valueChanges.pipe(map((response) => response.data?.generateNotePdf));
  }
}
