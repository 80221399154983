<mat-dialog-content>
    <section class="asset-add-assets-wrapper">
        <form [formGroup]="assignAssetForm" (ngSubmit)="onSubmit()" >
        <h2 class="cmn-popup-title">Assign Assets</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <!-- <ul>
            <li>Vehicle Name: Maruthi Jimny</li>
            <li>Vehicle Number: A 12345</li>
        </ul> -->


        <!-- <h3> {{ assetName }}</h3>
        <h4> {{ vehicleNumber }}</h4> -->


          <div  class="form-wrapper" >

            <mat-form-field>
                <mat-select placeholder="Search Asset" #singleSelect formControlName="assetId">
                    <mat-option>
                        <ngx-mat-select-search (input)="onSearchInput($event)"
                        [placeholderLabel]="'Search'"
                        [noEntriesFoundLabel]="'No data found'"></ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let asset of assetsList" [value]="asset.id">
                         {{ asset?.assetName }}
                    </mat-option>
                </mat-select>
                <div class="custom-error">
                    <span>
                      <mat-error *ngIf="
                      assignAssetForm.get('assetId')!.touched &&
                      assignAssetForm.get('assetId')!.hasError('required')
                      ">*Asset  is required.</mat-error>
                    </span>
                  </div>
            </mat-form-field>


        </div>
        <div class="form-wrapper">
            <label for="assignee">Employee <span class="red">*</span></label>
            <!-- <mat-form-field>
              <mat-select placeholder="Select Employee" formControlName="employeeId">
                <mat-option *ngFor="let employee of allEmployees" [value]="employee.id">
                  {{ employee.firstName }}  {{ employee.lastName }} 
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error *ngIf="assignAssetForm.get('employeeId')?.hasError('required')">
              Amount is required.
            </mat-error> -->
            <mat-form-field>
              <mat-select placeholder="Search Employee" formControlName="employeeId"
                  (selectionChange)="onCustomerSelected($event)">
                  
                  <!-- SEARCH INPUT -->
                  <mat-option>
                      <ngx-mat-select-search [formControl]="employeeSearchControl"
                          placeholderLabel="Search Employee" [noEntriesFoundLabel]="'No options found'">
                      </ngx-mat-select-search>
                  </mat-option>
  
                  <!-- DROPDOWN OPTIONS -->
                  <mat-option *ngFor="let employee of allEmployees" [value]="employee.id">
                      {{ employee.firstName }} {{ employee.lastName }}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <mat-error *ngIf="assignAssetForm.get('employeeId')?.touched && assignAssetForm.get('employeeId')?.hasError('required')">
            Employee is required.
          </mat-error>
          </div>
          

        <!-- <div class="form-wrapper">
            <label for="">Date <span class="red">*</span></label>
            <mat-form-field>
                <input matInput [matDatepicker]="date" placeholder="Select date" formControlName="date" />
                <mat-datepicker-toggle matIconSuffix [for]="date"></mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
              </mat-form-field>
        </div> -->

        <!-- <div class="form-wrapper">
            <label for="">Task <span class="red">*</span> </label>
            <mat-form-field>
                <textarea matInput placeholder="Enter description" formControlName="task"></textarea>
            </mat-form-field>
        </div> -->

        <div class="btn-wrapper">
            
                <!-- Submit -->
                <button class="cmn-next-btn" [disabled]="loading">
                  <span *ngIf="!loading">Submit</span>
                  <span *ngIf="loading">Loading...</span>
                </button>

        </div>
    </form>
    </section>
</mat-dialog-content>