import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { LeaveService } from '../../../../features/leave/services/leave.service';
import { ToastrService } from 'ngx-toastr';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { LeaveApplyComponent } from '../../../employee-details/components/leaves/components/leave-apply/leave-apply.component';
import { Router } from '@angular/router';
import { ConfirmDialog } from '../../../../shared/components/confirm-dialog/confirm-dialog';
import { ImageService } from '../../../../shared/services/image.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { UploadFileService } from '../../../../shared/services/file-upload.service';

@Component({
  selector: 'app-pending',
  standalone: true,
  imports: [CommonModule, AgGridAngular],
  templateUrl: './pending.component.html',
  styleUrl: '../../leave-management.component.scss',
})
export class PendingComponent {
  @Input() searchQuery: string = '';
  @Input() filter: any = [];
  filterObj: any;
  public rowData: any[] | null = null;

  constructor(
    private leaveService: LeaveService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private route: Router,
    // private dialog: MatDialog,
    private imageService: ImageService
  ) { }

  ngOnInit() {
    this.fetchPendingLeaves();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchQuery']) {
      this.fetchPendingLeaves();
    } else if (changes['filter']) {
      this.fetchPendingLeaves();
    }
  }

  getRowHeight = (params: any) => {
    const approvers = [
      params.data?.approver1,
      params.data?.approver2,
      params.data?.approver3,
      params.data?.approver4,
    ];
    const approverCount = approvers.filter((approver) => !!approver).length;
    return 40 + (approverCount > 1 ? (approverCount - 1) * 20 : 0);
  };

  columnDefs = [
    {
      headerName: 'Applied By',
      field: 'name',
      flex: 1,
      cellRenderer: (params: any) => {
        return `
                <div style="display: flex; align-items: center;">
                  <img src="${params.data.imageUrl}" alt="Profile Image" style="width: 35px; height: 35px; margin-right: 10px; border-radius: 50%; object-fit: cover;">
                  <span>${params.data.name}</span>
                </div>
              `;
      },
    },
    { headerName: 'Leave Type', field: 'type', flex: 1 },
    { headerName: 'Duration', field: 'duration', flex: 1 },
    { headerName: 'Reason', field: 'reason', flex: 1 },
    {
      headerName: 'Approvers',
      flex: 1,
      cellRenderer: (params: any) => {
        const approver1 = params.data?.approver1
          ? `<span class= yellow style="display: block; line-height: 1.5;">${params.data.approver1}</span>`
          : '';
        const approver2 = params.data?.approver2
          ? `<span class= yellow style="display: block; line-height: 1.5;">${params.data.approver2}</span>`
          : '';
        const approver3 = params.data?.approver3
          ? `<span class= yellow style="display: block; line-height: 1.5;">${params.data.approver3}</span>`
          : '';
        const approver4 = params.data?.approver4
          ? `<span class= yellow style="display: block; line-height: 1.5;">${params.data.approver4}</span>`
          : '';

        return `
              <div>
                ${approver1}
                ${approver2}
                ${approver3}
                ${approver4}
              </div>
            `;
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const editIcon = document.createElement('img');
        editIcon.src = 'assets/images/icons/icon-edit.svg';
        editIcon.style.cursor = 'pointer';
        editIcon.setAttribute('data-action', 'edit');

        const deleteIcon = document.createElement('img');
        deleteIcon.src = 'assets/images/icons/icon-delete.svg';
        deleteIcon.style.cursor = 'pointer';
        deleteIcon.setAttribute('data-action', 'delete');
        
        container.appendChild(editIcon);
        container.appendChild(deleteIcon);
        return container;
      },
      floatingFilter: false,
      filter: false,
    },
  ];
  readonly dialog = inject(MatDialog);

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  fetchPendingLeaves() {
    this.filterObj = { leaveStatus: 'Pending', search: this.searchQuery };
    if (Array.isArray(this.filter) && this.filter.length > 0) {
      this.filterObj.leaveType = this.filter;
    }

    this.leaveService.fetchLeaves(this.filterObj).subscribe({
      next: async (data) => {
        this.rowData = await Promise.all(
          data.map(async (data: any) => {
            // console.log('dataaaaaaa',data.supportingDocuments);

            let profilePicture;
            if (data?.employee?.profilePicture) {
              try {
                profilePicture = await this.imageService.getImageAsBase64(
                  data?.employee.profilePicture
                );
              } catch (error) {
                profilePicture = 'assets/images/dummy-profile-image.jpg';
              }
            } else {
              profilePicture = 'assets/images/dummy-profile-image.jpg';
            }
            return {
              id: data?.id || '--',
              employeeId: data?.employeeId || '--',
              name:
                data?.employee?.firstName + ' ' + data?.employee?.lastName ||
                '--',
              imageUrl:
                profilePicture || 'assets/images/dummy-profile-image.jpg',
              type:
                data?.leaveType + ' (' + data?.totalLeaveDays + ' days)' ||
                '--',
              startDate: this.datePipe.transform(data?.startDate, 'MM/dd/YYYY'),
              endDate: this.datePipe.transform(data?.endDate, 'MM/dd/YYYY'),
              leaveType: data?.leaveType,
              supportingDocumentFile: data.supportingDocuments,
              duration:
                this.datePipe.transform(data?.startDate, 'MMM d') +
                ' - ' +
                this.datePipe.transform(data?.endDate, 'MMM d') || '--',
              reason: data?.employeeComment || '--',
              approver1:
                data?.employee?.employeeApprovers[0]?.levelOneData?.firstName +
                ' ' +
                data?.employee?.employeeApprovers[0]?.levelOneData
                  ?.lastName || '--',

              ...(data?.employee?.employeeApprovers[0]?.levelTwoData && {
                approver2:
                  data?.employee?.employeeApprovers[0]?.levelTwoData
                    ?.firstName +
                  ' ' +
                  data?.employee?.employeeApprovers[0]?.levelTwoData?.lastName,
              }),

              ...(data?.employee?.employeeApprovers[0]?.levelThreeData && {
                approver3:
                  data?.employee?.employeeApprovers[0]?.levelThreeData
                    ?.firstName +
                  ' ' +
                  data?.employee?.employeeApprovers[0]?.levelThreeData
                    ?.lastName,
              }),

              ...(data?.employee?.employeeApprovers[0]?.levelFourData && {
                approver4:
                  data?.employee?.employeeApprovers[0]?.levelFourData
                    ?.firstName +
                  ' ' +
                  data?.employee?.employeeApprovers[0]?.levelFourData?.lastName,
              }),
            };
          })
        );
      },
      error: (error: any) => {
        this.toastr.error(error?.message);
      },
    });
  }

  onConfirmDelete(leaveId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onDelete(leaveId);
      }
    });
  }

  onEdit(leaveId: any, leaveData: any) {
    const dialogRef = this.dialog.open(LeaveEditDialog, {
      disableClose: true,
      data: { leaveId: leaveId, leaveData: leaveData },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
        if (result && !result.errors) {
          this.fetchPendingLeaves();
          this.toastr.success('Leave updated successfully!', 'Success');
        } else {
          this.toastr.error(result.errors[0].message, 'Error');
        }
      }
    });
  }

  onDelete(leaveId: any) {
    this.leaveService.removeLeave(leaveId).subscribe({
      next: (data) => {
        this.fetchPendingLeaves();
        this.toastr.success('Leave request deleted successfully', 'Success');
      },
      error: (error) => {
        this.toastr.error('Error deleting leave request', 'Error');
      },
    });
  }

  onRowClicked(event: any) {
    const employeeId = event.data.id;
    console.log('eve', event);

    // this.route.navigate([`leaves/approvals/${employeeId}`]);
  }

  onCellClicked(params: any) {
    const employeeId = params.data.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.onEdit(params?.data?.id, params?.data);
      } else if (action === 'delete') {
        this.onConfirmDelete(params?.data?.id);
      }
    } else {
      this.route.navigate([`leaves/approvals/${employeeId}`]);
    }
  }
}

@Component({
  selector: 'leave-edit-dialog',
  templateUrl: '../leave-edit-dialog.html',
  styleUrls: ['../../leave-management.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class LeaveEditDialog {
  leaveEditForm: FormGroup;
  csvFile: File[] = [];
  url: string = '';
  leaveId: any;

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private leaveService: LeaveService,
    private toastr: ToastrService,
    public dialog: MatDialogRef<LeaveEditDialog>,
    private documentUploadService: UploadFileService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.leaveId = this.data?.leaveId;
    this.initForm();
  }

  initForm() {
    this.leaveEditForm = this.fb.group({
      id: [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      leaveType: ['', Validators.required],
      employeeComment: [''],
      file: [''],
    });

    this.patchValueData();
  }

  patchValueData() {
    if (this.data.leaveData?.supportingDocumentFile) {
      this.csvFile.push(this.data.leaveData?.supportingDocumentFile);
      console.log('this.csvFile', this.csvFile);

    }
    const leaveTypeMap: any = {
      'Local Leave': 'LocalLeave',
      'Medical Leave': 'MedicalLeave',
    };
    this.leaveEditForm.patchValue({
      id: this.data?.id,
      startDate: new Date(this.data.leaveData?.startDate) || '',
      endDate: new Date(this.data.leaveData?.endDate) || '',
      leaveType: leaveTypeMap[this.data.leaveData?.leaveType] || '',
      employeeComment: this.data.leaveData?.reason || '',
      file: this.data.leaveData?.supportingDocumentFile || '',
      // firstSession: ['', Validators.required],
      // secondSession: ['', Validators.required],
    });
  }

  removeFile(index: any) {
    this.csvFile.splice(index, 1);
    // this.file = null;
    // this.fileName = '';
    // this.fileUrl = null;

    const input = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (input) {
      input.value = '';
    }

  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.csvFile.push(file);
    }
    this.uploadDocuments(this.csvFile);
  }

  uploadDocuments(csvFile: any) {
    console.log(csvFile);
    csvFile.forEach((file: any) => {
      this.documentUploadService.uploadDocument(file, 'leaves').subscribe({
        next: (url) => {
          this.url = url;
          file.url = url;
        },
        error: (error) => {
          console.log(error);
          this.toastr.error(error, 'File upload failed');
        },
      });
    });
  }

  onViewDocument(url: any) {
    console.log(url);

    const token = localStorage.getItem('AUTH_TOKEN');
    // const url = file?.url?.url;
    // console.log(url);
    
    fetch(url, {
      method: 'GET',
      headers: {
        'authorization': token ? `Bearer ${token}` : '',
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      })
      .catch(error => console.error('Error:', error));
  }

  onEdit() {
    if (this.leaveEditForm.invalid) {
      this.leaveEditForm.markAllAsTouched();
      return;
    }

    const startDate: any = new Date(this.leaveEditForm.value.startDate);
    const endDate: any = new Date(this.leaveEditForm.value.endDate);

    const isDateValid =
      startDate && endDate
        ? new Date(endDate) >= new Date(startDate)
          ? true
          : this.toastr.error(
            'To date cannot be earlier than from date',
            'Error'
          )
        : this.toastr.error('Choose a valid date', 'Error');

    if (isDateValid === true) {
      this.leaveEditForm.patchValue({
        startDate: this.datePipe.transform(startDate, 'YYYY-MM-dd'),
        endDate: this.datePipe.transform(endDate, 'YYYY-MM-dd'),
        id: Number(this.leaveId),
      });

      const leaveData = this.leaveEditForm.value;

      console.log('leaveData', leaveData);

      const csvFile = this.csvFile ? this.csvFile : [];
      if (leaveData) {
        this.leaveService.updateLeave(leaveData, csvFile).subscribe(
          (response) => {
            if (response) {
              this.leaveEditForm.reset();
              this.dialog.close(response);
              this.csvFile = [];
            } else if (response?.errors) {
              this.toastr.error(response?.errors[0]?.message, 'Error');
            }
          },
          (error) => {
            this.toastr.error(error?.message, 'Error');
          }
        );
      }
    }
  }

  onClose() {
    this.dialog.close();
  }
}
