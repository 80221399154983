import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeService } from '../../../../../../features/employees/services/employee.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-branch-access-profile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './branch-access-profile.html',
  styleUrls: ['../../../../employee-details.component.scss'],
})
export class BranchAccessProfileComponent implements OnChanges {
  @Input() employeeData: any;
  @Input() approverData: any;

  value: string = '123456';
  hiddenValue: string = '******';
  isVisible: boolean = false;
  profileId: number | undefined;
  profileName: string | undefined;
  employeeId: string;
  approvers: any;

  constructor(
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    console.log('inside');

    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');

      if (id) {
        this.employeeId = id;
        this.getEmployeeApprover(this.employeeId);
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['employeeData'] && this.employeeData) {
      this.profileId = this.employeeData.user?.profileId;
      if (this.profileId) {
        this.fetchProfileName(this.profileId);
        console.log('employeeeeeee', this.employeeData.employeeApprovers);
      }
    }
  }

  fetchProfileName(profileId: number) {
    this.employeeService.getUserRoleById(profileId).subscribe({
      next: (profile) => {
        this.profileName = profile?.profileName;
        console.log('Profile Name:', this.profileName);
      },
      error: (error) => {
        console.error('Error fetching profile:', error);
      },
    });
  }

  getEmployeeApprover(id: any) {
    this.employeeService.getEmployeeApprover(Number(id)).subscribe({
      next: (data) => {
        if (data?.employeeApprovers) {
          const approverData = data?.employeeApprovers[0]
          console.log('approver data:', data?.employeeApprovers[0]);
          this.approvers = approverData;
        }
      },
      error: (error) => {
        console.error(error, 'Error');
      },
    });
  }

  toggleClass() {
    this.isVisible = !this.isVisible;
  }
}
