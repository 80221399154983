import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { GET_ASSETS_FOR_EMPLOYEE } from '../graphql/querries/assets.querries';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(private apollo: Apollo) {}

  getAssetsForEmployee(): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_ASSETS_FOR_EMPLOYEE,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data?.assetsForEmployee || [])
      );
  }
}
