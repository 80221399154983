import { createReducer, on } from '@ngrx/store';
import * as PermissionActions from './permission.action';

export interface PermissionState {
  permissions: any[];
  myProfile: any; 
  headerTitle: string; 
}

export const initialState: PermissionState = {
  permissions: [],
  myProfile: null ,
  headerTitle: '' 
};

export const permissionReducer = createReducer(
  initialState,
  on(PermissionActions.setPermissions, (state, { permissions }) => ({ ...state, permissions })),
  on(PermissionActions.clearPermissions, state => ({ ...state, permissions: [] })),
  on(PermissionActions.setMyProfile, (state, { myProfile }) => ({ ...state, myProfile })), 
  on(PermissionActions.clearMyProfile, state => ({ ...state, myProfile: null })),
  on(PermissionActions.setHeaderTitle, (state, { headerTitle }) => ({ ...state, headerTitle })), 
  on(PermissionActions.clearHeaderTitle, state => ({ ...state, headerTitle: '' })) 
);
