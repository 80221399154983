import { gql } from 'apollo-angular';

export const SEND_MAIL_INVOICE = gql`
  mutation sendEmailWithTemplate(
    $invoiceId: Int!
    $to: [String!]!
    $cc: [String!]
    $subject: String!
    $body: String!
    $attachment: [Upload!]
    $isAttachment: Boolean
  ) {
    sendEmailWithTemplate(
      invoiceId: $invoiceId
      to: $to
      cc: $cc
      subject: $subject
      body: $body
      attachment: $attachment
      isAttachment: $isAttachment
    )
  }
`;

export const SEND_MAIL_QUOTATION = gql`
  mutation sendQuoteMail(
    $quoteId: Int!
    $sendQuoteMailInput: SendQuoteMailInput!
  ) {
    sendQuoteMail(quoteId: $quoteId, sendQuoteMailInput: $sendQuoteMailInput){
    success
    message
    }
  }
`;

export const SEND_MAIL_PURCHASE_ORDER = gql`
 mutation sendPurchaseOrderMail(
    $purchaseOrderId: Int!
    $to: [String!]!
    $cc: [String!]
    $subject: String!
    $body: String!
    $attachment: [Upload!]
    $isAttachment: Boolean
  ) {
    sendPurchaseOrderMail(
      purchaseOrderId: $purchaseOrderId
      to: $to
      cc: $cc
      subject: $subject
      body: $body
      attachment: $attachment
      isAttachment: $isAttachment
    )
  }
`;
