<section class="cmn-innerpage-wrapper invoice-new">
    <h2 class="cmn-inner-heading">
        {{ noteId ? "Update Credit Note": "New Credit Note" }}
    </h2>
    <form [formGroup]="FormGroupItems">
        <div class="invoice-new-form-wrapper">
            <div class="form-wrapper invoice-form-wrapper">
                <!-- Client Name Section -->
                <div class="cmn-Search-select-container">
                    <label for="clientName">Customer Name</label>
                    <mat-form-field>
                        <mat-select placeholder="Customer Name" id="clientName" formControlName="customerName"
                            [ngClass]="{
                                'mat-select-error':
                                FormGroupItems.get('customerName')!.touched &&
                                FormGroupItems.get('customerName')!.hasError('required')
                            }" (selectionChange)="onCustomerSelected($event)">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="customerSearchControl"
                                    placeholderLabel="Search Customer"
                                    noEntriesFoundLabel="No customer found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let partners of partnersList" [value]="partners.id">
                                {{ partners?.displayName }}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            FormGroupItems.get('customerName')!.touched &&
                            FormGroupItems.get('customerName')!.hasError('required')
                        ">Customer Name is required.</mat-error>
                        </span>
                    </div>
                    <button *appHasPermission="['4', Permission.Create]" (click)="openCreateClientDialog()">
                        <div>
                            <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add client" />
                        </div>
                        <span>Add Customer</span>
                    </button>
                </div>
                <div class="cmn-Search-select-container" *ngIf="filteredInvoiceList">
                    <label for="clientName">Invoices</label>
                    <mat-form-field>
                        <mat-select placeholder="Invoice" id="clientName" formControlName="invoiceId" [ngClass]="{
                                'mat-select-error':
                                FormGroupItems.get('invoiceId')!.touched &&
                                FormGroupItems.get('invoiceId')!.hasError('required')
                            }" (selectionChange)="onInvoiceSelected($event)">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="invoiceSearchControl"
                                    placeholderLabel="Search Invoice"
                                    noEntriesFoundLabel="No Invoice found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let inv of filteredInvoiceList" [value]="inv.id">
                                {{ inv?.invoiceNo }}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            FormGroupItems.get('customerName')!.touched &&
                            FormGroupItems.get('customerName')!.hasError('required')
                        ">Customer Name is required.</mat-error>
                        </span>
                    </div>
                </div>
                <!-- Invoice Number Section -->
                <div>
                    <label for="invoiceNumber">Credit Note#</label>
                    <mat-form-field>
                        <input matInput placeholder="Credit note number" id="invoiceNumber"
                            formControlName="creditNoteNo" readonly/>
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                FormGroupItems.get('creditNoteNo')!.touched &&
                FormGroupItems.get('creditNoteNo')!.hasError('required')
              ">Credit Note is required.</mat-error>
                        </span>
                    </div>
                </div>

                <!-- Order Number Section -->
                <div>
                    <label for="reference">Reference#</label>
                    <mat-form-field>
                        <input matInput placeholder="Reference" id="reference" formControlName="reference" />
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="FormGroupItems.get('reference')?.hasError('maxlength')">Reference cannot
                                exceed 10 characters</mat-error>
                        </span>
                    </div>
                </div>

                <!-- Invoice Date Section -->
                <div>
                    <label for="creditNoteDate">Credit Note Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="invoiceDatePicker" placeholder="Select Date"
                            [matDatepickerFilter]="disableFutureDates" id="creditNoteDate"
                            formControlName="creditNoteDate" />
                        <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #invoiceDatePicker></mat-datepicker>
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                FormGroupItems.get('creditNoteDate')!.touched &&
                FormGroupItems.get('creditNoteDate')!.hasError('required')
              ">Credit Note Date is required.</mat-error>
                        </span>
                        <span>
                            <mat-error *ngIf="
                        FormGroupItems.get('creditNoteDate')!.hasError('matDatepickerFilter')
                      ">Future dates are not allowed.</mat-error>
                        </span>
                    </div>
                </div>


                <div>
                    <label for="subject">Subject</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Subject" id="subject" formControlName="subject" />

                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            FormGroupItems.get('subject')!.touched &&
                            FormGroupItems.get('subject')!.hasError('required')
                          ">Subject is required.</mat-error>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="billing-shipping-address-wrapper" *ngIf="selectedCustomerId">
            <div>
                <div class="header-wrapper">
                    <h5>Billing Address</h5>
                    <div (click)="openSelectBillingAddressDialog()">
                        <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="" />
                    </div>
                </div>
                <div *ngIf="selectedBillingAddress">
                    <h6>{{ selectedBillingAddress.attention || "___" }}</h6>
                    <ul>
                        <li>{{ selectedBillingAddress.addressLine1 || "___" }}</li>
                        <!-- <li>{{ selectedBillingAddress.addressLine2 || "___"  }}</li> -->
                        <li>{{ selectedBillingAddress.city || "___" }}</li>
                        <li>{{ selectedBillingAddress.state || "___" }}</li>
                        <li>{{ selectedBillingAddress.country || "___" }}</li>
                        <li>{{ selectedBillingAddress.phone || "___" }}</li>
                    </ul>
                </div>
                <button type="button" (click)="openBillingAddressDialog('add')">
                    <span>Add Address</span>
                </button>
            </div>

            <div>
                <div class="header-wrapper">
                    <h5>Shipping Address</h5>
                    <div (click)="openSelectShippingAddressDialog()">
                        <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="" />
                    </div>
                </div>
                <div *ngIf="selectedShippingAddress">
                    <h6>{{ selectedShippingAddress.attention || "___" }}</h6>
                    <ul>
                        <li>{{ selectedShippingAddress.addressLine1 || "___" }}</li>
                        <!-- <li>{{ selectedShippingAddress.addressLine2 || "___"  }}</li> -->
                        <li>{{ selectedShippingAddress.city || "___" }}</li>
                        <li>{{ selectedShippingAddress.state || "___" }}</li>
                        <li>{{ selectedShippingAddress.country || "___" }}</li>
                        <li>{{ selectedShippingAddress.phone || "___" }}</li>
                    </ul>
                </div>
                <button type="button" (click)="openShippingAddressDialog('add')">
                    <span>Add Address</span>
                </button>
            </div>
        </div>

        <div class="item-service-adding-table-wrapper" formArrayName="items">
            <table>
                <thead>
                    <th>Item/Service </th>
                    <th>Quantity </th>
                    <!-- <th>Unit <span class="red">*</span></th> -->
                    <th>Rate</th>
                    <th>Gross Price</th>
                    <th>Discount</th>
                    <th>Net Price</th>
                    <th>VAT(5%)</th>
                    <th>Amount</th>
                </thead>

                <tbody>
                    <tr *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
                      <!-- Item description data -->
                      <td>
                        <div class="form-wrapper">
                          <div>
                            <mat-form-field>
                              <input matInput placeholder="Select or Type Item" [matAutocomplete]="auto"
                                formControlName="itemName" (input)="onItemSearch($event)" />
                              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectItem($event)">
                                <mat-option *ngFor="let item of filteredItemData" [value]="item.id">
                                  {{ item.itemName }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <div class="custom-error" *ngIf="item.get('itemName')?.touched">
                              <span>
                                <mat-error *ngIf="item.get('itemName')?.errors?.['required']">
                                  Item is required.
                                </mat-error>
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <!-- Quanitity data -->
                      <td>
                        <div class="form-wrapper">
                          <mat-form-field>
                            <input matInput placeholder="quantity" formControlName="quantity" />
                          </mat-form-field>
                          <div class="custom-error">
                            <span>
                              <mat-error *ngIf="
                                  item.get('quantity')?.touched &&
                                  item.get('quantity')?.hasError('required')
                                ">Quantity is required.</mat-error>
                            </span>
                            <span>
                              <mat-error *ngIf="item.get('quantity')?.hasError('nonNegative')">Quantity must be greater than
                                0.</mat-error>
                            </span>
                          </div>
                        </div>
                      </td>
                      <!-- unit data -->
                      <!-- <td>
                        <div class="form-wrapper min-width-field">
                          <mat-form-field>
                            <mat-select placeholder="Choose unit" formControlName="unit" maxlength="7">
                              <mat-option *ngFor="let unit of unitTypes" [value]="unit.value">
                                {{ unit.value }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
          
                          <div class="custom-error">
                            <span>
                              <mat-error *ngIf="
                                  item.get('unit')!.touched &&
                                  item.get('unit')!.hasError('required')
                                ">Unit is required.</mat-error>
                            </span>
                          </div>
                        </div>
                      </td> -->
                      <!-- Rate data -->
                      <td>
                        <div class="form-wrapper">
                          <mat-form-field>
                            <input matInput placeholder="rate" formControlName="rate" />
                          </mat-form-field>
                          <div class="custom-error">
                            <span>
                              <mat-error *ngIf="
                                  item.get('rate')?.touched &&
                                  item.get('rate')?.hasError('required')
                                ">Rate is required.</mat-error>
                            </span>
                            <span>
                              <mat-error *ngIf="item.get('rate')?.hasError('nonNegative')">Rate cannot be negative.</mat-error>
                            </span>
                          </div>
                        </div>
                      </td>
                      <!-- Gross Amount Data -->
                      <td>
                        <div class="form-wrapper">
                          <mat-form-field>
                            <input matInput [value]="getItemGrossAmount(item.value)" readonly />
                          </mat-form-field>
                        </div>
                      </td>
                      <!-- Discount data -->
                      <td>
                        <div class="form-wrapper">
                          <div class="split-parent">
                            <div class="left-set">
                              <mat-form-field>
                                <input matInput placeholder="0.00" formControlName="discount" type="number" />
                              </mat-form-field>
                              <div class="custom-error">
                                <span>
                                  <mat-error *ngIf="item.get('discount')?.hasError('nonNegative')">Discount cannot be negative.
                                  </mat-error>
                                </span>
                                <span>
                                  <mat-error *ngIf="
                                      item.get('discount')?.hasError('pattern') &&
                                      item.get('discount')?.touched
                                    ">Please enter a valid discount amount.
                                  </mat-error>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <!-- Net amount data -->
                      <td>
                        <div class="form-wrapper">
                          <mat-form-field>
                            <input matInput placeholder="0.00" readonly formControlName="netAmount" type="number" />
                          </mat-form-field>
                        </div>
                      </td>
                      <!-- Vat tax Data -->
                      <td>
                        <div class="form-wrapper">
                          <mat-form-field>
                            <input matInput placeholder="VAT" formControlName="vat" type="number" (input)="updateItemTotal(i)" />
                          </mat-form-field>
                          <div class="custom-error">
                            <span>
                              <mat-error *ngIf="
                                  item.get('vat')?.touched &&
                                  item.get('vat')?.hasError('required')
                                ">VAT is required.</mat-error>
                            </span>
                            <span>
                              <mat-error *ngIf="item.get('vat')?.hasError('nonNegative')">VAT cannot be negative.</mat-error>
                            </span>
                          </div>
                        </div>
                      </td>
                      <!-- Total amount data -->
                      <td>
                        <div class="form-wrapper">
                          <mat-form-field>
                            <input matInput placeholder="Total Amount" formControlName="amount" type="number" readonly />
                          </mat-form-field>
                          <div class="custom-error">
                            <span>
                              <mat-error *ngIf="
                                  item.get('amount')?.touched &&
                                  item.get('amount')?.hasError('required')
                                ">
                                Amount is required.
                              </mat-error>
                            </span>
                          </div>
                        </div>
                        <span class="delete">
                          <img src="../../../assets/images/icons/icon-close.svg" alt="Delete"
                            (click)="onRemoveNewlyAddedItems(i)" />
                        </span>
                      </td>
                    </tr>
                  </tbody>

            </table>
            <button type="button" (click)="addItem()">
                <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add" (click)="addItem()" />
                Add
            </button>
        </div>

        <div class="quotation-table-container" *ngIf="NoteData?.noteItems.length > 0">

            <div>
                <table>

                    <thead>
                        <tr>
                            <th>SL No. </th>
                            <th>Item/Service</th>
                            <th>Account</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Discount</th>
                            <th>VAT</th>
                            <th>Amount</th>
                            <th>Action </th>
                        </tr>
                    </thead>

                    <tbody *ngIf="itemData">

                        <tr *ngFor="let item of NoteData.noteItems; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ item?.itemData?.itemName }}</td>
                            <td>{{ item?.accountData?.accountName}}</td>
                            <td>{{ item?.quantity }}</td>
                            <td>{{ item?.rate }}</td>
                            <td>{{ item.discount }}</td>
                            <td> {{((item?.rate * item?.quantity - item?.discount) * 5) / 100 }}</td>

                            <td>{{ item?.amount }}</td>
                            <img src="assets/images/icons/icon-delete.svg" alt="Delete Item" style="text-align: left"
                                (click)="onRemoveInvoiceItem(i, item?.id)" />
                        </tr>


                    </tbody>
                </table>
            </div>

        </div>

        <div class="notes-tatal-amount-container">
            <div class="form-container">
                <div class="form-wrapper">
                    <label for="customerNotes">Customer Notes</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter your notes here..."
                            formControlName="customerNote"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="termsConditions">Terms & Conditions</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter the terms & conditions"
                            formControlName="termsConditions"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="amount-table-div">
                <div>
                    <h6>
                        Total Amount <span>: AED {{ getSubTotal().toFixed(2) | number:'1.2-2' }}</span>
                    </h6>
                    <h6>
                        Discount (-) <span>: AED {{ getDiscount().toFixed(2) | number:'1.2-2' }}</span>
                    </h6>
                    <h6>
                        VAT (5%) <span>: AED {{ getVAT().toFixed(2) | number:'1.2-2' }}</span>
                    </h6>
                </div>
                <div>
                    <h4>
                        Grand Total <span>: AED {{ getGrandTotal().toFixed(2) | number:'1.2-2' }}</span>
                    </h4>
                </div>
            </div>
        </div>

        <div class="submit-btn-wrapper">
            <button class="cmn-cancel-btn" type="reset" (click)="onCancel()">Cancel</button>
            <button class="cmn-draft-btn" type="button" (click)="saveAsDraft()">Save as Draft</button>
            <button class="cmn-next-btn" type="button" (click)="saveAsDraft()">Save as Open</button>
        </div>
    </form>
</section>