export const environment = {
  production: false,
  authUrl: 'https://api-access-gateway.techlabz.in/munawel-be-staging',
  // authUrl: 'https://shahid-4200.tl-workspace.techwarelab.com',
  url: {
    defaultUrl:
      'https://api-access-gateway.techlabz.in/munawel-be-staging/graphql',
    hrmsModuleUrl:
      'https://api-access-gateway.techlabz.in/munawel-hrms-staging/graphql',
      // 'https://shahid-82.tl-workspace.techwarelab.com/graphql',
      // 'https://shyam-82.twl-workspace.techwarelab.com/graphql',
    accountsUrl:
      'https://api-access-gateway.techlabz.in/munawel-accounting-staging/graphql',
      // 'https://shahid-81.tl-workspace.techwarelab.com/graphql',
      // 'https://sharon-81.twl-workspace.techwarelab.com/graphql',
      // 'https://shyam-4200.twl-workspace.techwarelab.com/graphql',
    projectModuleUrl:
      'https://api-access-gateway.techlabz.in/munawel-project-staging/graphql',
      // 'https://shahid-3000.tl-workspace.techwarelab.com/graphql',
      // 'https://sharon-82.twl-workspace.techwarelab.com/graphql',
    communicationModuleUrl:
      'https://api-access-gateway.techlabz.in/munawel-communication-staging/graphql',
  },
};
