import gql from 'graphql-tag';

export const CREATE_BANK = gql`
  mutation createBank($createBankInput: CreateBankInput!) {
    createBank(createBankInput: $createBankInput) {
    id
    bankType
    accountName
    accountCode
    accountNumber
    bankName
    routingNumber
    description
    isPrimary
    currencyId
    currency {
      id
      currencyCode
      currencyName
      currencySymbol
      exchangeRate
      status
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
    status
  }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation createTransaction($createTransactionInput: CreateTransactionInput!) {
    createTransaction(createTransactionInput: $createTransactionInput) {
    id
    date
    referenceNumber
    transactionStatus
    amount
    flow
    description
    paidVia
    createdAt
    updatedAt
    deletedAt
    status
    offsetAccountId
    offsetAccount {
      id
      accountType
      accountName
      accountCode
      description
      amount
      parentAccountId
      isSubAccount
      isEditable
      status
      createdAt
      updatedAt
      deletedAt
    }
    transactionCategoryId
    transactionCategory {
      id
      categoryName
      categoryStatus
      createdAt
      updatedAt
      deletedAt
      status
    }
    transactionReconcilations {
      id
      statementId
      createdAt
      updatedAt
      deletedAt
      status
    }
  }
  }
`;