<div class="common-noimage-tab-wrapper">
    <mat-tab-group (selectedTabChange)="onTabChange($event)" animationDuration="0ms">
        <!-- Mat Tab One -->
        <mat-tab>
            <ng-template mat-tab-label>
                <h2 class="leave-tab-title">Vacation History</h2>
            </ng-template>
            <div class="leave-history-table">
                <app-vacation-history *ngIf="activeTabIndex === 0" #vacationHistory></app-vacation-history>
            </div>

        </mat-tab>
        <!-- Mat Tab Two -->
        <mat-tab>
            <ng-template mat-tab-label>
                <h2 class="leave-tab-title">Apply Vacation</h2>
            </ng-template>
            <app-vacation-apply (cancelEvent)="goToHistoryTab()"></app-vacation-apply>
        </mat-tab>
    </mat-tab-group>
</div>