<mat-dialog-content>
  <section class="invoice-apply-credits-wrapper">
    <h2 class="cmn-popup-title">Item Details</h2>
    <button class="cmn-close" mat-dialog-close></button>

    <div class="form-main-content-wrapper">
      <ul>
        <li>
          <h6>Credit Note#</h6>
          <p>Projects</p>
        </li>
        <li>
          <h6>Credit Note Date</h6>
          <p>Milestone</p>
        </li>
        <li>
          <h6>Credit Amount</h6>
          <p>Man Power</p>
        </li>
        <li>
          <h6>Credit Available</h6>
          <p>Duration</p>
        </li>
        <li>
          <h6>Amount To credit</h6>
          <div class="form-wrapper">
            <mat-form-field>
              <input matInput />
            </mat-form-field>
          </div>
        </li>
        <li>
          <div>
            <h6>Amount to Credit</h6>
            <p>: &nbsp;&nbsp; 0.00</p>
          </div>
          <div>
            <h6>Invoice Balance Due</h6>
            <p>: &nbsp;&nbsp; AED 300.00</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="btn-wrapper">
      <button class="cmn-cancel-btn">Cancel</button>
      <button class="cmn-next-btn">Transfer Item</button>
    </div>
  </section>
</mat-dialog-content>
