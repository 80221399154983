<section class="cmn-innerpage-wrapper currency-adjustments">
  <div class="head">
    <div class="search-wrapper" *appHasPermission="['30', Permission.List]">
      <input
        type="text"
        class="cmn-header-search"
        placeholder="Search"
        (input)="onSearch($event)"
      />
    </div>
    <div class="btn-wrapper">
      <button
        class="cmn-upload-btn"
        *appHasPermission="['30', Permission.Upload]"
        (click)="openUploadDialog()"
      >
        <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
        <span>Upload</span>
      </button>
      <button
        class="cmn-add-btn"
        *appHasPermission="['30', Permission.Create]"
        (click)="openAddCurrencyDialog()"
      >
        <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add
        Currency
      </button>
    </div>
  </div>
  <div class="inner-content" *appHasPermission="['30', Permission.List]">
    <ag-grid-angular
      style="width: 100%"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowSelection]="rowSelection"
      [pagination]="true"
      [paginationPageSize]="paginationPageSize"
      [paginationPageSizeSelector]="paginationPageSizeSelector"
      [class]="themeClass"
      [animateRows]="true"
      (cellClicked)="onCellClicked($event)"
    />
  </div>
</section>
