import { Component, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentsItemService } from '../../features/payments/services/payments.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PAYMENT_MODES } from '../../helpers/helper-file';
import { fetch_PDF } from '../../helpers/helper';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';

@Component({
  selector: 'app-payments-made-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    PermissionDirective
  ],
  templateUrl: './payments-made-details.component.html',
  styleUrl: './payments-made-details.component.scss',
})
export class PaymentsMadeDetailsComponent {
  allItems: any[] = [];
  itemData: any = {};
  isLoading = false;
  private searchSubject = new Subject<string>();
  paymentModeList: any = PAYMENT_MODES;
  selectedModes: string[] = [];
  selectedCount: number = 0;
  itemId: any;
  journalData: any;
  vendorAddresses: any;
  billingAddress: any;
  Permission = Permission;
  pdfLoading: boolean = false;

  constructor(
    private router: Router,
    private itemService: PaymentsItemService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.fetchItems();
    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchItems(searchTerm);
      });

    this.route.queryParams.subscribe((params) => {
      const id = params['id'];
      this.itemId = id;
    });

  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  navigateToEdit(path: string) {
    const selectedIteam = this.allItems[this.selectedTabIndex];
    const itemId = selectedIteam.id;
    this.router.navigate([path], { queryParams: { id: itemId } });
  }

  selectedOption = 'today';

  selectedTabIndex = 0; // Default to the first tab

  selectTab(index: number) {
    this.selectedTabIndex = index;
    this.itemData = this.allItems[index];
    this.journalData = this.itemData?.journal;
  // Filter addresses with addressType as 'BILLING'
    this.vendorAddresses = this.itemData.partner?.addresses.filter(
    (address: any) => address.addressType === 'BILLING'
    ) || [];
    this.billingAddress = this.vendorAddresses.find(
    (address: any) => address.addressType === 'BILLING'
  ) || null;
    console.log('Vendor Addresses:', this.vendorAddresses);
    if (this.itemData) {
      this.router.navigate(['payments-made/payments-made-details'], {
        queryParams: { id: this.itemData.id },
      });
    }
  }
  calculateTotalDebit(entries: any[]): number {
    return entries?.reduce((total, entry) => total + (entry.debit || 0), 0) || 0;
  }
  
  calculateTotalCredit(entries: any[]): number {
    return entries?.reduce((total, entry) => total + (entry.credit || 0), 0) || 0;
  }
  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(UploadPaymentsMadeDetailsDialog);
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  navigateToMail(): void {
    const paymentID = this.itemData.id;
    const paymentMadeId = this.itemData.id;
    const ids =
      this.itemData?.paymentInfo?.map((info: any) => info?.bill?.id) || [];

    // this.router.navigate([`payments-made/payments-made-details/mail`], {
    //   queryParams: { paymentID, ids },
    // });

    this.router.navigate([`payments-made/payments-made-details/mail`], {
      queryParams: { paymentMadeId, ids },
    });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  fetchItems(search: string = '', filter: any = { paymentType: 'Bill' }) {
    this.itemService.fetchPayments(search, filter).subscribe({
      next: (items) => {
        this.allItems = items;
        if (this.itemId) {
          const targetId = +this.itemId;
          this.selectedTabIndex = this.allItems.findIndex((c: any) => {
            return c.id === targetId;
          });
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
        this.selectTab(this.selectedTabIndex);
      },
      error: (err) => {
        console.error('Error fetching all items:', err);
      },
    });
  }

  onApplyFilters() {
    this.fetchItems('', {
      paymentType: 'Bill',
      paymentMode: this.selectedModes,
    });
    this.toggleDropdown();
  }

  clearFilters() {
    this.selectedModes = [];
    this.fetchItems('', { paymentType: 'Bill', paymentMode: [] });
    this.toggleDropdown();
    this.selectedCount = 0;
  }

  toggleMode(mode: string) {
    if (this.selectedModes.includes(mode)) {
      this.selectedModes = this.selectedModes.filter((m) => m !== mode);
    } else {
      this.selectedModes.push(mode);
    }
    this.selectedCount = this.selectedModes?.length;
  }

  downloadPdf(): void {
    const paymentID = this.itemData.id;
    const paymentType = 'Bill';
    this.itemService.generatePaymentPdf(paymentID, paymentType).subscribe({
      next: (items) => {
        const url = items;
        fetch_PDF(url, 'payment-made');
      },
      error: (err) => {
        this.toastr.error(err.message, 'failed');
      },
    });
  }

  onViewDocument() {
    console.log("sdfsdfdsf")
    const selectedIteam = this.allItems[this.selectedTabIndex];
    const url = selectedIteam.receiptUrl;
    this.pdfLoading = true;
    if (url) {
      const token = localStorage.getItem('AUTH_TOKEN');
      fetch(url, {
        method: 'GET',
        headers: {
          'authorization': token ? `Bearer ${token}` : ''
        }
      }).then((response: any) => response.blob()).then((blob: any) => {
        this.pdfLoading = false;
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      }).catch((error: any) => {
        console.error('Error:', error)
      });
    }
  }

}

@Component({
  selector: 'upload-payments-made-details-dialog',
  templateUrl: 'upload-payments-made-details-dialog.html',
  styleUrls: ['./payments-made-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadPaymentsMadeDetailsDialog {
  file: any = null;
  url: any = null;

  constructor(
    private toastr: ToastrService,
    private itemService: PaymentsItemService,
    private dialogRef: MatDialogRef<UploadPaymentsMadeDetailsDialog>
  ) {}

  ngOnInit() {
    document.addEventListener('dragover', this.preventDefaultBehavior);
    document.addEventListener('drop', this.preventDefaultBehavior);
  }

  preventDefaultBehavior(event: DragEvent) {
    event.preventDefault();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer?.files.length) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }

  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.file = file;
    }
  }

  uploadDocuments(csvFile: any) {
    this.itemService.bulkCreatePayment(csvFile).subscribe({
      next: (response) => {
        if (response == true) {
          this.toastr.success('Bulk updated successfully!');
          this.dialogRef.close('refresh');
        }
      },
      error: (error) => {
        this.toastr.error(error.message, 'Bulk updated failed');
        this.dialogRef.close();
      },
    });
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  onUpload() {
    this.uploadDocuments(this.file);
  }

  ngOnDestroy() {
    document.removeEventListener('dragover', this.preventDefaultBehavior);
    document.removeEventListener('drop', this.preventDefaultBehavior);
  }
}
