import { gql } from 'apollo-angular';

export const EXPORT_SALES_BY_CUSTOMER_TO_CSV = gql`
  mutation exportSalesByCustomerToCSV($startDate: String, $endDate: String) {
    exportSalesByCustomerToCSV(startDate: $startDate, endDate: $endDate)
  }
`;

export const EXPORT_SALES_BY_ITEM_TO_CSV = gql`
  mutation exportSalesByItemToCSV($startDate: String, $endDate: String) {
    exportSalesByItemToCSV(startDate: $startDate, endDate: $endDate)
  }
`;

export const EXPORT_CUSTOMER_BALANCE_SUMMARY_TO_CSV = gql`
  mutation exportCustomerBalanceSummaryToCSV($startDate: DateTime, $endDate: DateTime) {
    exportCustomerBalanceSummaryToCSV(startDate: $startDate, endDate: $endDate)
  }
`;

export const EXPORT_PROFIT_LOSS_TO_CSV = gql`
  mutation exportProfitLossToCSV($startDate: String, $endDate: String) {
    exportProfitLossToCSV(startDate: $startDate, endDate: $endDate)
  }
`;

export const EXPORT_PAYMENT_RECEIVED_TO_CSV = gql`
  mutation exportPaymentReceivedToCSV($startDate: DateTime!, $endDate: DateTime!) {
    exportPaymentReceivedToCSV(startDate: $startDate, endDate: $endDate)
  }
`;

export const EXPORT_PAYMENT_MADE_TO_CSV = gql`
  mutation exportPaymentMadeToCSV($startDate: DateTime!, $endDate: DateTime!, $fields: [String!]) {
    exportPaymentMadeToCSV(startDate: $startDate, endDate: $endDate, fields: $fields)
  }
`;

export const EXPORT_BALANCE_SHEET_TO_CSV = gql`
  mutation exportBalanceSheetToCSV($startDate: DateTime!, $endDate: DateTime!) {
    exportBalanceSheetToCSV(startDate: $startDate, endDate: $endDate)
  }
`;

export const EXPORT_CASH_FLOW_TO_CSV = gql`
  mutation exportCashFlowToCSV($startDate: String, $endDate: String) {
    exportCashFlowToCSV(startDate: $startDate, endDate: $endDate)
  }
`;

export const EXPORT_PETTY_CASH_SUMMARY_TO_CSV = gql`
mutation exportPettyCashSummary($startDate: String!, $endDate: String!) {
    exportPettyCashSummary(startDate: $startDate, endDate: $endDate)
  }
`;

export const EXPORT_VENDOR_BALANCE_SUMMARY_TO_CSV = gql`
  mutation exportVendorBalanceSummaryToCSV($startDate: String!, $endDate: String!) {
    exportVendorBalanceSummaryToCSV(startDate: $startDate, endDate: $endDate)
  }
`;

export const EXPORT_ACCOUNT_TYPE_SUMMARY_TO_CSV = gql`
  mutation exportAccountTypesSummaryToCSV($startDate: String, $endDate: String) {
    exportAccountTypesSummaryToCSV(startDate: $startDate, endDate: $endDate)
  }
`;

export const EXPORT_GENERAL_LEDGER_TO_CSV = gql`
  mutation exportJournalsLedgerToCSV($startDate: String, $endDate: String) {
    exportJournalsLedgerToCSV(startDate: $startDate, endDate: $endDate)
  }
`;

export const EXPORT_JOURNALS_REPORT_TO_CSV = gql`
  mutation exportJournalsReportToCSV($startDate: String, $endDate: String) {
    exportJournalsReportToCSV(startDate: $startDate, endDate: $endDate)
  }
`;