<section class="cmn-innerpage-wrapper expense">
  <div class="head">
    <div class="search-wrapper" *appHasPermission="['13', Permission.List]">
      <input type="text" class="cmn-header-search" placeholder="Search" />
    </div>
    <button class="cmn-upload-btn" *appHasPermission="['13', Permission.Upload]" (click)="openDialog()">
      <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
      <span>Upload</span>
    </button>
    <button class="cmn-add-btn" *appHasPermission="['13', Permission.Create]" (click)="navigateTo('/expense/expense-new')">
      <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add Expense
    </button>
  </div>
  <div class="inner-content" *appHasPermission="['13', Permission.List]">
    <ag-grid-angular
      style="width: 100%"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowSelection]="rowSelection"
      [suppressRowClickSelection]="true"
      [pagination]="true"
      [paginationPageSize]="paginationPageSize"
      [paginationPageSizeSelector]="paginationPageSizeSelector"
      [class]="themeClass"
      (cellClicked)="onCellClicked($event)"
    />
  </div>
</section>
