<section class="cmn-innerpage-wrapper inventory-details">
  <div class="head">
    <div class="search-wrapper">
      <input
        type="text"
        class="cmn-header-search"
        placeholder="Search"
        (input)="onSearch($event)"
      />
    </div>
    <div class="btn-wrapper">
      <button
        class="cmn-add-btn"
        (click)="navigateToAddNew()"
      >
        <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add New
      </button>
    </div>
  </div>
  <div class="sub-head">
    <ul>
      <li class="main">Warehouse Details</li>
      <li
        class="edit"
        (click)="navigateToAddNew()"
      >
        <img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit" />
        Edit
      </li>
    </ul>
  </div>
  <div class="inventory-main-tab-wrapper">
    <div class="tab-left">
      <div class="tab-box-main-wrapper">
        <button class="tab-custom-button active">
          <div class="inn-box">
            <h2>Item A</h2>
            <h6>SKU12345</h6>
          </div>
          <div class="inn-box">
            <h6>10 kg</h6>
            <h5>Type 1</h5>
          </div>
        </button>

        <button class="tab-custom-button">
          <div class="inn-box">
            <h2>Item B</h2>
            <h6>SKU67890</h6>
          </div>
          <div class="inn-box">
            <h6>5 L</h6>
            <h5>Type 2</h5>
          </div>
        </button>

        <button class="tab-custom-button">
          <div class="inn-box">
            <h2>Item C</h2>
            <h6>SKU54321</h6>
          </div>
          <div class="inn-box">
            <h6>20 pcs</h6>
            <h5>Type 3</h5>
          </div>
        </button>
      </div>
    </div>
    <div class="inventory-main-tab-content-wrapper">
      <div *ngIf="itemData">
        <div class="chart-tab-content">
          <div class="common-noimage-tab-wrapper">
            <mat-tab-group animationDuration="0ms">
              <!-- Mat Tab One -->
              <mat-tab>
                <ng-template mat-tab-label>
                  <h2 class="leave-tab-title">Details</h2>
                </ng-template>
                <div class="inventory-tab-inner-content">
                  <!-- Group 1 -->
                  <h6>Details</h6>
                  <div class="overview-box">
                    <h3>Name</h3>
                    <h4>:</h4>
                    <h5>Lorem Ipsum</h5>
                  </div>
                  <div class="overview-box">
                    <h3>Code</h3>
                    <h4>:</h4>
                    <h5>CM0216515</h5>
                  </div>
                  <div class="overview-box">
                    <h3>Location</h3>
                    <h4>:</h4>
                    <h5>Dubai, Abu Dhabi</h5>
                  </div>
                  <div class="overview-box">
                    <h3>Contact</h3>
                    <h4>:</h4>
                    <h5>Employee</h5>
                  </div>
                  <div class="overview-box">
                    <h3>About</h3>
                    <h4>:</h4>
                    <h5>Lorem ipsum lorem ipsum</h5>
                  </div>
                  <h6>Location Information</h6>
                  <div class="overview-box">
                    <h3>Stack</h3>
                    <h4>:</h4>
                    <h5>AED 1,200.00</h5>
                  </div>
                  <div class="overview-box">
                    <h3>Row</h3>
                    <h4>:</h4>
                    <h5>Cost of Goods Sold</h5>
                  </div>
                  <div class="overview-box">
                    <h3>Column</h3>
                    <h4>:</h4>
                    <h5>From vendor Dan Bizler’s partner</h5>
                  </div>
                  <h6>Stock Adjustment</h6>
                  <div class="overview-box">
                    <h3>Quantity</h3>
                    <h4>:</h4>
                    <h5>12</h5>
                  </div>
                </div>
              </mat-tab>
              <!-- Mat Tab Two -->
              <mat-tab>
                <ng-template mat-tab-label>
                  <h2 class="leave-tab-title">Storage</h2>
                </ng-template>
                <div class="inventory-tab-inner-content">
                  <button
                    class="add-stock"
                    type="button"
                    (click)="openAddStorageDialog()"
                  >
                    <img
                      src="assets/images/icons/icon-add-color.svg"
                      alt="Add Stock"
                    />
                    Add Item location
                  </button>
                  <div class="inventory-tab-inner-content">
                    <ag-grid-angular
                      style="width: 100%"
                      [rowData]="addedRowData"
                      [columnDefs]="addedColumnDefs"
                      [defaultColDef]="defaultColDef"
                      [rowSelection]="rowSelection"
                      [suppressRowClickSelection]="true"
                      [pagination]="true"
                      [paginationPageSize]="paginationPageSize"
                      [paginationPageSizeSelector]="paginationPageSizeSelector"
                      [class]="themeClass"
                    />
                  </div>
                </div>
              </mat-tab>
              <!-- Mat Tab Three -->
              <mat-tab>
                <ng-template mat-tab-label>
                  <h2 class="leave-tab-title">Items</h2>
                </ng-template>
                <div class="inventory-tab-inner-content">
                  <ag-grid-angular
                    style="width: 100%"
                    [rowData]="rowData"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [rowSelection]="rowSelection"
                    [suppressRowClickSelection]="true"
                    [pagination]="true"
                    [paginationPageSize]="paginationPageSize"
                    [paginationPageSizeSelector]="paginationPageSizeSelector"
                    [class]="themeClass"
                  />
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
