import { Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { PayrollService } from '../../features/payroll/services/payroll.service';
import { ToastrService } from 'ngx-toastr';
import { ImageService } from '../../shared/services/image.service';
import { PayrollPrecessingDialog } from '../payroll/payroll.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { nonNegativeValidator } from '../../shared/services/validations';
import { noSpaceAtStartOrEnd } from '../../helpers/helper';

@Component({
  selector: 'app-payroll-individual',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatDialogModule,
    RouterModule
  ],
  templateUrl: './payroll-individual.component.html',
  styleUrl: './payroll-individual.component.scss'
})
export class PayrollIndividualComponent {
  data: any;
  employeeId: any;
  employeeData: any;
  profilePicture: string;
  selectedMonth: any;
  selectedYear: any;
  totalSalary: number;
  totalDeduction: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private payrollService: PayrollService,
    private toastr: ToastrService,
    private imageService: ImageService,
  ) { }

  readonly dialog = inject(MatDialog);

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      console.log(id);
      this.employeeId = id;
      this.employeePayrollById(id);
    }

    this.route.queryParams.subscribe(params => {
      this.selectedMonth = params['month'];
      this.selectedYear = params['year'];
    });
  }

  employeePayrollById(id: any) {
    console.log('inside employee payroll by id');
    
    this.payrollService.employeePayrollById(Number(id)).subscribe({
      next:  (data) => {
        let profilePicture;
        console.log("profilePicture", profilePicture);
        if (data?.employees.profilePicture) {
          try {
            profilePicture =  this.imageService.getImageAsBase64(
              data?.employees.profilePicture
            );
          } catch (error) {
            profilePicture = 'assets/images/dummy-profile-image.jpg';
          }
        } else {
          profilePicture = 'assets/images/dummy-profile-image.jpg';
        }
        this.data = data;
        console.log('this.data', this.data);
        
        let totalSalary = 0;
        let totalDeduction = 0;
        if (this.data?.payrollDeductions) {
          for (const deduction of this.data.payrollDeductions) {
            totalDeduction += deduction.amount || 0;
          }
        }
        this.totalDeduction = totalDeduction;
        if (this.data?.payrollBonuses) {
          for (const bonus of this.data.payrollBonuses) {
            totalSalary += bonus.amount || 0;
          }
        }
        this.totalSalary = totalSalary;

        // this.profilePicture = profilePicture;
      },
      error: (error) => {
        this.toastr.error('Error fetching payroll details', 'Error')
      }
    })
  }

  openDialog(status: any) {
    if (status === true) {
      const dialogRef = this.dialog.open(PayrollAdjustmentDialog, {
        data: {
          deduction: true,
          reimbursement: false,
          employeePayrollId: this.employeeId
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.employeePayrollById(this.employeeId);
      })
    } else {
      const dialogRef = this.dialog.open(PayrollAdjustmentDialog, {
        data: {
          deduction: false,
          reimbursement: true,
          employeePayrollId: this.employeeId
        }
      });
      dialogRef.afterClosed().subscribe(() => {
          this.employeePayrollById(this.employeeId);
      })
    }
  }

  onProcessPayment() {
    console.log(this.data);

    const dialogRef = this.dialog.open(PayrollPrecessingDialog, {
      data: {
        id: this.data.id,
        employeeId: this.data?.employeeId,
        payrollId: this.data?.payrollId,
        month: this.selectedMonth,
        year: this.selectedYear
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('individual payroll');
      
      if (result) {
        this.dialog.open(PayrollPrecessingDialog,{
          data: {
            month: this.selectedMonth, // Pass selectedMonth
            year: this.selectedYear,  // Pass selectedYear
            isSuccess: true,          // A flag to indicate success
          },
        })
      }
      else {
        console.error('Employee salary details not updated');
      }
    });
  }

  onCancel(){
    if (this.selectedMonth && this.selectedYear) {
      this.router.navigate(['/payroll/'], {
        queryParams: { month: this.selectedMonth, year: this.selectedYear }
      });
    }
  }
}



@Component({
  selector: 'payroll-adjustment-dialog',
  templateUrl: 'payroll-adjustment-dialog.html',
  styleUrls: ['./payroll-individual.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule
  ],
})
export class PayrollAdjustmentDialog {
  addAmountForm: FormGroup;
  totalDeduction: number;
  totalSalary: number;
  profilePicture: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<PayrollAdjustmentDialog>,
  ) { }

  ngOnInit() {
    if (this.data) {
      console.log(this.data);
    }
    if (this.data?.deduction) {
      this.addAmountForm = this.fb.group({
        employeePayrollId: [''],
        deductionType: ['', [Validators.required, noSpaceAtStartOrEnd]],
        amount: [0, [Validators.required, nonNegativeValidator]],
        description: [''],
      })
    }
    if (this.data?.reimbursement) {
      this.addAmountForm = this.fb.group({
        employeePayrollId: [''],
        bonusType: ['', [Validators.required, noSpaceAtStartOrEnd]],
        amount: [0, [Validators.required, nonNegativeValidator]],
        description: [''],
      })
    }

  }

  onSubmit() {
    console.log(this.addAmountForm);
    
    if (this.addAmountForm.invalid) {
      this.addAmountForm.markAllAsTouched();
      return;
    }
    if (this.data?.deduction) {
      this.addAmountForm.patchValue({
        ...this.addAmountForm.value,
        amount: Number(this.addAmountForm.value.amount),
        employeePayrollId: Number(this.data?.employeePayrollId),
      })
      const amountData = this.addAmountForm.value;
      this.payrollService.createPayrollDeduction(amountData).subscribe({
        next: (data) => {
            this.toastr.success('Deduction added successfully', 'Success');
            this.addAmountForm.reset();
            this.dialogRef.close('success');
        },
        error: (error) => {
          console.error(error, 'Error')
        }
      })
    }
    if (this.data?.reimbursement) {
      this.addAmountForm.patchValue({
        ...this.addAmountForm.value,
        amount: Number(this.addAmountForm.value.amount),
        employeePayrollId: Number(this.data?.employeePayrollId),
      })
      const amountData = this.addAmountForm.value;
      this.payrollService.createPayrollBonus(amountData).subscribe({
        next: (data) => {
            this.toastr.success('Reimbursement added successfully', 'Success');
            this.addAmountForm.reset();
            this.dialogRef.close('success');
        },
        error: (error) => {
          console.error(error, 'Error')
        }
      })
    }
  }
}