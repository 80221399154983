<div class="tab-inner-content">
  <h2>Cash Flow Statement</h2>
  <h5>
    From {{ startDate | date : "MM/d/YYYY" }} to
    {{ endDate | date : "MM/d/YYYY" }}
  </h5>
  <table>
    <tr>
      <th>Account</th>
      <th></th>
      <th>Total</th>
    </tr>
    <tr class="title">
      <td>Beginning Cash Balance</td>
      <td></td>
      <td>
        {{ cashFlowReport?.beginningCashBalance | number }}
      </td>
    </tr>
    <tr class="title">
      <td>Cash Flow from Operating Activities</td>
      <td></td>
      <td></td>
    </tr>
    <ng-container
      *ngFor="let item of cashFlowReport?.operatingActivities?.details"
    >
      <tr class="child">
        <td>{{ item.accountName }}</td>
        <td></td>
        <td>{{ item.amount | number }}</td>
      </tr>
    </ng-container>
    <tr class="sub-total">
      <td>Total for Operating Activities</td>
      <td></td>
      <td>
        {{ cashFlowReport?.operatingActivities?.total | number }}
      </td>
    </tr>

    <tr class="title">
      <td>Cash Flow from Investing Activities</td>
      <td></td>
      <td>
        {{ cashFlowReport?.investingActivities?.total | number }}
      </td>
    </tr>
    <ng-container
      *ngFor="let item of cashFlowReport?.investingActivities?.details"
    >
      <tr class="child">
        <td>{{ item.description }}</td>
        <td></td>
        <td>{{ item.amount | number }}</td>
      </tr>
    </ng-container>
    <tr class="sub-total">
      <td>Total for Investing Activities</td>
      <td></td>
      <td>
        {{ cashFlowReport?.investingActivities?.total | number }}
      </td>
    </tr>

    <tr class="title">
      <td>Cash Flow from Financing Activities</td>
      <td></td>
      <td>
        {{ cashFlowReport?.financingActivities?.total | number }}
      </td>
    </tr>
    <ng-container
      *ngFor="let item of cashFlowReport?.financingActivities?.details"
    >
      <tr class="child">
        <td>{{ item.description }}</td>
        <td></td>
        <td>{{ item.amount | number }}</td>
      </tr>
    </ng-container>
    <tr class="sub-total">
      <td>Total for Financing Activities</td>
      <td></td>
      <td>
        {{ cashFlowReport?.financingActivities?.total | number }}
      </td>
    </tr>

    <tr class="total">
      <td></td>
      <td>Net Change in Cash</td>
      <td>{{ cashFlowReport?.netChangeInCash | number }}</td>
    </tr>
    <tr class="total">
      <td></td>
      <td>Ending Cash Balance</td>
      <td>
        {{ cashFlowReport?.endingCashBalance | number }}
      </td>
    </tr>
  </table>
</div>
