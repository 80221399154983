import { Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { HolidayCalendarService } from '../../../features/holiday-calendar/services/holiday-calendar.service';

@Component({
  selector: 'app-holiday-list',
  standalone: true,
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    AgGridAngular,
  ],
  templateUrl: './holiday-list.component.html',
  styleUrl: '../../holiday/holiday.component.scss',
})

export class HolidayListComponent implements OnInit {
  isDropdownOpen = false;
  rowData: any[] = [];
  public loading: boolean = true;
  public error: any = null;

  columnDefs = [
    { headerName: 'Occasion', field: 'holidayName', flex: 1 },
    { headerName: 'Date', field: 'date', flex: 1 },
    { headerName: 'Day', field: 'day', flex: 1 },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  constructor(
    private holidayService: HolidayCalendarService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.fetchHolidayCalendar();
  }

  fetchHolidayCalendar(
    holidayDate: string = '',
    year: string = '',
    search: string = ''
  ) {
    this.loading = true;
    this.holidayService
      .getHolidayCalendarList(holidayDate, year, search)
      .subscribe({
        next: (holidays: any) => {
          this.rowData = holidays.map((holiday: any) => {
            return {
              ...holiday,
              date: this.datePipe.transform(holiday.holidayDate, 'dd/MM/yyyy'),
              day: new Date(holiday.holidayDate).toLocaleDateString('en-US', {
                weekday: 'long',
              }),
            };
          });
          this.loading = false;
        },
        error: (error: Error) => {
          this.error = error;
          this.loading = false;
        },
      });
  }

}
