import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CurrencyAdjustmentService } from '../../features/currency-adjustments/services/currency-adjustment.service';
import { BankingService } from '../../features/banking/services/banking.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-bank-account',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './add-bank-account.component.html',
  styleUrl: './add-bank-account.component.scss'
})
export class AddBankAccountComponent {
  addBankAccountForm: FormGroup
  search: string | undefined;
  filter: {};
  currencyData: any;

  constructor(
    private fb: FormBuilder,
    private currencyService: CurrencyAdjustmentService,
    private bankingService: BankingService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.addBankAccountForm = this.fb.group({
      bankType: ['Bank', Validators.required],
      accountName: ['', Validators.required],
      currencyId: [1, Validators.required],
      routingNumber: ['', Validators.required],
      // BIC: ['', Validators.required],
      accountCode: [''],
      accountNumber: [''],
      bankName: [''],
      description: [''],
    })
    this.fetchCurrencies();
  }


  fetchCurrencies() {
    this.currencyService.fetchCurrencies(this.search, this.filter).subscribe({
      next: (currency) => {
        this.currencyData = currency;
        console.log(this.currencyData);

      },
      error: (error) => console.error(error),
    });
  }

  onCancel() {
    this.addBankAccountForm.reset({
      bankType: this.addBankAccountForm.get('bankType')?.value 
    });
   }

  onSave() {
    if (this.addBankAccountForm.invalid) {
      this.addBankAccountForm.markAllAsTouched();
    } else {
    const bankAccountData = this.addBankAccountForm.value;
    this.bankingService.createBank(bankAccountData).subscribe({
      next: (data) => {
        if (data) {
          this.addBankAccountForm.reset({
            bankType: this.addBankAccountForm.get('bankType')?.value,
          });
          this.toastr.success('Bank created successfully', 'Success');
        }
      },
      error: (error) => { 
        this.toastr.error(error, 'Error')
      }
    })
  }
}
}
