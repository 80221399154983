import {
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ConfigureService } from '../../features/configure/services/configure.service';
import { noSpaceAtStartOrEnd } from '../../helpers/helper';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';
import { Store } from '@ngrx/store';
import * as selectPermissions from '../../core/permissions/permission.selector';


@Component({
  selector: 'app-configure',
  standalone: true,
  imports: [CommonModule, LayoutComponent, AgGridAngular, RouterModule, FormsModule, ReactiveFormsModule, PermissionDirective],
  templateUrl: './configure.component.html',
  styleUrl: './configure.component.scss',
})
export class ConfigurationComponent implements OnInit {
  public viewPortSize: boolean = false;
  search: any;
  Permission = Permission;
  columnDefs: ({ headerName: string; field: string; flex: number; cellRenderer?: undefined; floatingFilter?: undefined; filter?: undefined; } | { field: string; headerName: string; flex: number; cellRenderer: (params: any) => HTMLDivElement; floatingFilter: boolean; filter: boolean; })[];
  constructor(
    private router: Router,
    private configureService: ConfigureService,
    private store: Store,
  ) { }

  public rowData: any[] | null = null;
  searchInputControl: FormControl = new FormControl();
  public loading: boolean = true;
  public error: any = null;
  private searchSubject = new Subject<string>();

  readonly dialog = inject(MatDialog);

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {

    this.columnDefs = [
      { headerName: 'Module Name', field: 'category', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Prefix',
        field: 'prefix',
        flex: this.viewPortSize ? 1 : 0,
      },
      {
        field: 'action',
        headerName: 'Actions',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.alignItems = 'center';
          container.style.gap = '20px';

          const editIcon = document.createElement('img');
          editIcon.src = 'assets/images/icons/icon-edit.svg';
          editIcon.style.cursor = 'pointer';
          editIcon.setAttribute('data-action', 'edit');

          // container.appendChild(editIcon);

          this.store.select(selectPermissions.hasPermission('33', Permission.Edit)).subscribe((canEdit) => {
            if (canEdit) {
              container.appendChild(editIcon);}
          });
          return container;
        },
        floatingFilter: false,
        filter: false,
      },
    ];
  }

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 20;
  public paginationPageSizeSelector: number[] | boolean = [20, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  ngOnInit(): void {
    this.preferenceCodes(this.search);

    this.searchInputControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.search = value;
        this.preferenceCodes(this.search);
      });

    this.checkViewportSize();
  }


  preferenceCodes(search: any) {
    this.loading = true;
    this.configureService.preferenceCodes(search).subscribe({
      next: (preferences) => {
        this.rowData = preferences.map((preference: any) => {
          return {
            id: preference?.id,
            category: preference?.category,
            prefix: preference?.prefix,
          };
        });
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching currencies:', err);
        this.loading = false;
      },
    });
  }

  openEditDialog(currencyData: any) {
    const dialogRef = this.dialog.open(UpdateConfiguration, {
      data: currencyData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.preferenceCodes('');
      }
      dialogRef.close();
    });
  }

  onCellClicked(params: any) {
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.openEditDialog(params?.data);
      }
      else {
        this.router.navigate([
          `/configure`,
        ]);
      }
    }
  }
}

@Component({
  selector: 'app-update-configure',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDialogModule,
  ],
  templateUrl: './update-configure-dialog.html',
  styleUrl: './update-configure-dialog.scss',
  encapsulation: ViewEncapsulation.None,
})
export class UpdateConfiguration implements OnInit {
  updateConfigure: FormGroup;
  successMessage: string | null = null;
  errorMessage: string | null = null;
  loading: boolean = false;
  configureData: any;

  constructor(
    private configureService: ConfigureService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpdateConfiguration>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.configureData = this.data;
      console.log(this.configureData);

    }
    this.initForm();
  }

  initForm(): void {
    this.updateConfigure = this.fb.group({
      id: [this.configureData?.id || ''],
      category: [
        this.configureData?.category || '',
        Validators.required,
      ],
      prefix: [
        this.configureData?.prefix || '',
        [Validators.required, noSpaceAtStartOrEnd]
      ],
    });
  }

  onSubmit(): void {
    if (this.updateConfigure.invalid) {
      this.updateConfigure.markAllAsTouched();
      console.log(this.updateConfigure.controls);

      return;
    }
    const id = this.data?.id;
    const updateInput = this.updateConfigure.value;
    this.configureService.updatePreferenceCode(id, updateInput).subscribe(
      (response) => {
        this.toastr.success(
          'Configuration updated successfully.',
          'Success'
        );
        this.dialogRef.close('success');
      },
      (error) => {
        this.toastr.error(error, 'Error')
        this.loading = false;
      }
    );
  }

  onCancel() {
    this.updateConfigure.reset();
  }
}
