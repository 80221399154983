<div class="statements-content-wrapper">
    <div class="form-wrapper">
        <div>
            <form [formGroup]="dateRangeForm">
                <mat-form-field>
                    <mat-select placeholder="Select Date Filter" formControlName="dateFilter"
                        (selectionChange)="onDateSelected($event)">
                        <mat-option value="Today">Today</mat-option>
                        <mat-option value="This Week">This Week</mat-option>
                        <mat-option value="This Month">This Month</mat-option>
                        <mat-option value="This Year">This Year</mat-option>
                        <mat-option value="Custom">Custom</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="dateRangeForm.get('dateFilter')?.value === 'Custom'">
                    <mat-date-range-input [rangePicker]="picker" [dateFilter]="dateFilter">
                        <input matStartDate placeholder="Start date" formControlName="startDate"
                            (dateInput)="onDateChange($event, 'start')">
                        <input matEndDate placeholder="End date" formControlName="endDate"
                            (dateInput)="onDateChange($event, 'end')" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </form>
        </div>
        <div class="edit" (click)="onGeneratePdf()" style="cursor: pointer">
            <img src="assets/images/icons/icon-pdf.svg" alt="PDF" />
            PDF
        </div>
    </div>
    <div class="tab-inner">
        <div class="note-header">
            <div>
                <div class="left-set" *ngIf="partnerDetails?.partner?.addresses[0]?.addressLine1 != '' ">
                    <h4>{{partnerDetails?.partner?.addresses[0]?.attention}}</h4>
                    <h3>{{partnerDetails?.partner?.addresses[0]?.addressLine1}}</h3>
                    <h3>{{partnerDetails?.partner?.addresses[0]?.addressLine2}}</h3>
                    <h3>{{partnerDetails?.partner?.addresses[0]?.state}}</h3>
                    <h3>{{partnerDetails?.partner?.addresses[0]?.country}}</h3>
                    <h3>{{partnerDetails?.partner?.addresses[0]?.pinCode}}</h3>
                </div>
            </div>
            <div class="right-set">
                <h2>STATEMENT OF ACCOUNTS</h2>
                <h3>{{ startDate | date:'dd-MM-YYYY' }} to {{ endDate | date:'dd-MM-YYYY' }}</h3>
            </div>
        </div>

        <div class="journal-details">
            <div class="left-set">
                <div class="details-box">
                    <h4>Bill To </h4>
                    <h5>:</h5>
                    <h6>{{partnerDetails?.partner?.displayName}}</h6>
                </div>
                <div class="details-box">
                    <h4>Subject</h4>
                    <h5>:</h5>
                    <h6>Regarding Purchase</h6>
                </div>
            </div>
            <!-- <div class="right-set">
                <div class="details-box">
                    <h5>Subject</h5>
                    <h6>Regarding Purchase</h6>
                </div>
            </div> -->
        </div>
        <div class="page-footer-content">
            <div class="right-set">
                <h1>Account Summary</h1>
                <div class="details-box">
                    <h4>Opening Balance</h4>
                    <h5>:</h5>
                    <h6>AED {{partnerDetails?.partner?.openingBalance | number: '1.2-2'}}</h6>
                </div>
                <div class="details-box">
                    <h4>Billed Amount</h4>
                    <h5>:</h5>
                    <h6>AED {{partnerDetails?.billTotal | number: '1.2-2'}}</h6>
                </div>
                <div class="details-box">
                    <h4>Amount Paid</h4>
                    <h5>:</h5>
                    <h6>AED {{partnerDetails?.paymentTotal | number: '1.2-2'}}</h6>
                </div>
                <div class="details-box border">
                    <h4>Balance Due</h4>
                    <h5>:</h5>
                    <h6>AED {{partnerDetails?.balanceDue | number: '1.2-2'}}</h6>
                </div>
            </div>
        </div>
        <div class="crdit-note-page-table">
            <table *ngIf="partnerDetails?.combinedTransactions.length > 0; else noData">
                <tr>
                    <th>Date</th>
                    <th>Transaction</th>
                    <th>Ref No</th>
                    <th>Details</th>
                    <th>Amount</th>
                    <th>Balance</th>
                </tr>
                <tr *ngFor="let partnerDetail of partnerDetails?.combinedTransactions">
                    <td>{{partnerDetail?.date | date: 'dd/MM/YYYY'}}</td>
                    <td>{{partnerDetail?.type || '--'}}</td>
                    <td>{{partnerDetail?.refNo || '--'}}</td>
                    <td>{{ partnerDetail?.description ? (partnerDetail.description | slice:0:10) +
                        (partnerDetail.description.length > 10 ? '...' : '')
                        : '--' }}</td>
                    <td>AED {{partnerDetail?.totalPrice || '0.00'}}</td>
                    <td>AED {{partnerDetail?.balance || '0.00'}}</td>
                </tr>
            </table>
        </div>
        <ng-template #noData>
            <h4>No Transactions Found</h4>
        </ng-template>
        <div class="page-footer-content"
            *ngIf="partnerDetails?.partner?.payments.length > 0 || partnerDetails?.partner?.bills.length > 0">
            <div class="right-set">
                <div class="details-box bold">
                    <h4>Balance Due</h4>
                    <h5>:</h5>
                    <h6>AED {{partnerDetails?.balanceDue | number: '1.2-2'}}</h6>
                </div>
            </div>
        </div>
    </div>
</div>