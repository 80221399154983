import { gql } from "apollo-angular";

export const LIST_PARTNERS = gql`
  query partners($search: String ) {
    partners(search: $search) {
    id
    partnerType
    customerType
    displayName
    companyName
    email
    phone
    mobile
    paymentStatus
    status
    totalBillAmount
    totalPaymentAmount
    balanceDue
    totalInvoiceAmount
  }
  }
`;

export const FETCH_PARTNER = gql`
  query partner($id: Int! ) {
    partner(id: $id)  {
    id
    partnerType
    customerType
    displayName
    companyName
    email
    phone
    mobile
    currency
    emirateID
    openingBalance
    paymentStatus
    paymentTerm
    status
    primaryContactId
    primaryContact {
      id
      salutation
      firstName
      lastName
      email
      phone
      mobile
      primaryContact
      status
      partnerId
    }
    contacts {
      id
      salutation
      firstName
      lastName
      email
      phone
      mobile
      primaryContact
      status
      partnerId
    }
    invoices {
      id
      # projectId
      quoteId
      partnerId
      billingAddressId
      shippingAddressId
      invoiceNo
      orderNo
      invoiceDate
      paymentTerm
      paymentStatus
      reason
      dueDate
      subject
      customerNote
      isPayment
      subTotal
      tax
      adjustment
      totalPrice
      status
      createdAt
      updatedAt
      deletedAt
    }
    sales {
      id
      # projectId
      partnerId
      shippingAddressId
      billingAddressId
      quoteId
      orderId
      reference
      orderDate
      expectedDate
      paymentTerm
      customerNote
      subTotal
      tax
      adjustment
      totalPrice
      status
      createdAt
      updatedAt
      deletedAt
    }
    remarks {
      id
      remarkContent
      status
      createdAt
      updatedAt
      deletedAt
    }
    addresses {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
    documents {
      id
      partnerId
      documentName
      documentUrl
      createdAt
      updatedAt
      deletedAt
    }
  }
  }
`;

export const PARTNER_DETAILS = gql`
  query partnerDetails($id: Int!) {
    partnerDetails(id: $id)   {
    partner {
      id
      partnerType
      customerType
      displayName
      companyName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      paymentTerm
      status
      primaryContactId
      documents{
        id
        documentName
        documentUrl
      }
      # primaryContact
      contacts{
        salutation
        firstName
        lastName
        phone
        mobile
        email
      }
      invoices{
        balance
        totalPrice
        adjustment
        subTotal
        dueDate
        paymentStatus
        orderNo
        invoiceDate
        invoiceNo
      }
      # sales
      # projects
      remarks {
      remarkContent
      }
      addresses{
        attention
        addressType
        addressLine1
        addressLine2
        state
        country
        pinCode
      }
      # documents
      bills{
        id
        billNo
        orderNo
        totalPrice
        balance
        billStatus
        billDate
        partnerData{
          displayName
        }
        status
      }
      payments{
        id
        paymentNo
        referenceNo
        totalAmount
        bankCharge
        paymentMode
        paymentDate
        note
      }
      JournalEnteries{
        journalId
        description
        debit
        credit
        createdAt
      }
      # emailLogs
    }
    billTotal
    paymentTotal
    balanceDue
    invoiceTotal
    combinedTransactions {
      type
      date
      totalPrice
      balance
    }

  }
  }
`;

export const COMMENTS = gql`
  query comments($conditions: CommentConditionsInput) {
    comments(conditions: $conditions) {
    id
    employeeId
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      nativeAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      reportingManagerId
      # reportingManager
      # comments
    }
    refId
    type
    activityType
    note
    createdAt
    updatedAt
    deletedAt
  }
  }
`;