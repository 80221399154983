import { Component, numberAttribute } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { CustomerService } from '../../features/customers/services/customer.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { GRNServices } from '../../features/grn/services/grn.service';
import { ToastrService } from 'ngx-toastr';
import { PurchaseOrdereService } from '../../features/purchase-order/services/purchase-orderservice';
import { PreferenceService } from '../../shared/services/preference.service';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';

@Component({
  selector: 'app-grn-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    RouterModule,
    PermissionDirective
  ],
  templateUrl: './grn-new.component.html',
  styleUrl: './grn-new.component.scss'
})
export class GrnNewComponent {
  public arr = [1, 2, 3, 4, 5, 6, 7]
  vendorControl = new FormControl();
  vendorSearchControl = new FormControl();
  purchaseControl = new FormControl();
  purchaseSearchControl = new FormControl();
  search: any;
  vendors: any;
  filteredVendors: any = [];
  filteredPurchase: any = [];
  newGRNForm: FormGroup;
  itemServiceForm: FormGroup;
  subTotal: any;
  totalPrice: any;
  grnId: any;
  allOrders: any;
  purchaseOrder: any[] = [];
  purchaseItems: any[] = [];
  purchaseItem: any[] = [];
  grnData: any;
  totalQuantity: any;
  mails: any;
  grnNumber: any;
  prefix: string;
  today: Date = new Date();
  Permission = Permission;

  // This filter disables all dates after today
  dateFilter = (d: Date | null): boolean => {
    const currentDate = d || new Date();
    return currentDate <= this.today;
  };

  constructor(
    private customerService: CustomerService,
    private fb: FormBuilder,
    private itemService: InventoryItemService,
    private datePipe: DatePipe,
    private grnServices: GRNServices,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private purchaseOrderService: PurchaseOrdereService,
    private preferenceService: PreferenceService
  ) { }

  ngOnInit() {
    this.fetchVendors();
    this.vendorSearchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((searchText) => {
        this.filterVendors(searchText);
      });
    this.purchaseSearchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((searchText) => {
        this.filterPurchase(searchText);
      });

    this.route.paramMap.subscribe(params => {
      this.grnId = params.get('id');
      if (this.grnId) {
        this.fetchGrnById(this.grnId);
      }
    });
    this.initForm();
    this.preferenceCode();
    // this.generateGrnNumber();
  }

  onVendorChange(event: any): void {
    const selectedVendorId = event.value;
    console.log('Selected Vendor ID:', selectedVendorId);
    this.fetchAllPurchaseOrder(selectedVendorId);
  }

  onPOChange(event: any): void {
    const selectedPO = event.value;
    console.log('Selected Vendor ID:', selectedPO);
    this.fetchWorkOrder(selectedPO);
  }

  initForm() {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;

    const date = new Date();
    if (!this.grnData) {
      this.newGRNForm = this.fb.group({
        vendorName: ['', Validators.required],
        receivedById: [employeeId, Validators.required],
        grnNumber: ['', Validators.required],
        purchaseOrderId: ['', Validators.required],
        goodsReceivedDate: [date || '', Validators.required],
        workOrderNumber: ['', Validators.required],
        items: this.fb.array([]),
      });
    } else {
      this.newGRNForm = this.fb.group({
        vendorName: [this.grnData?.purchaseOrderData?.customer?.displayName],
        receivedById: [employeeId, Validators.required],
        grnNumber: [this.grnData?.grnNumber],
        purchaseOrderId: [this.grnData?.purchaseOrderData?.purchaseOrderNo],
        goodsReceivedDate: [new Date(Number(this.grnData?.goodsReceivedDate))],
        workOrderNumber: [this.grnData?.purchaseOrderData?.workorderData?.workOrderNo],
        items: this.fb.array([]),
      });
    }
  }

  preferenceCode() {
    this.preferenceService.preferenceCodes('', 'GRN').subscribe({
      next: (response) => {
        response.forEach((prefix: any) => {
          this.grnNumber = prefix?.sequenceCode;
        })
        this.prefix = `${this.grnNumber}`;
        this.newGRNForm.patchValue({ grnNumber: this.prefix });
      },
      error: (error) => {
        console.error(error, 'Error');
      },
    });
  }

  get items(): FormArray {
    return this.newGRNForm.get('items') as FormArray;
  }

  createItemForm(item: any): FormGroup {
    console.log(item);

    if (!this.grnData) {
      const quantity = item?.balanceQuantity || item?.quantity || 0;
      const rate = item?.rate || 0;
      const amount = rate * quantity;

      const formGroup = this.fb.group({
        purchaseItemId: [item?.id || '', Validators.required],
        grnId: [item.grnId || ''],
        itemName: [item?.item?.itemName || '', Validators.required],
        description: [item.description || ''],
        remark: [item.remark || ''],
        totalQuantity: item?.balanceQuantity || item?.quantity,
        orderedQuantity: item?.quantity,
        quantity: [
          item?.balanceQuantity || item?.quantity,
          [
            Validators.required,
            Validators.pattern('^[0-9]*$'),
            Validators.maxLength(6),
            this.quantityValidator(item?.balanceQuantity || item?.quantity),
          ],
        ],
        rate: [rate || 0],
        amount: [amount || 0],
        itemStatus: ["Completed"],
        isManual: [item.isManual || false],
      });
      this.updateTotalPrice();

      formGroup.get('quantity')?.valueChanges.subscribe((quantity) => {
        const rate = formGroup.get('rate')?.value || 0;
        const totalQuantity = formGroup.get('totalQuantity')?.value || 0;
        if (quantity < totalQuantity) {
          formGroup.patchValue({ itemStatus: 'Partial' }, { emitEvent: false });
        } else if (quantity === totalQuantity) {
          formGroup.patchValue({ itemStatus: 'Completed' }, { emitEvent: false });
        }
        const amount = rate * quantity;
        formGroup.patchValue({ amount }, { emitEvent: false });
        this.updateTotalPrice();
      });

      return formGroup;
    }
    else {
      const quantity = item?.quantity || 1;
      const rate = item?.purchaseItemData?.rate || 0;
      const amount = rate * quantity;

      const formGroup = this.fb.group({
        purchaseItemId: [item?.purchaseItemData?.id || '', Validators.required],
        grnId: [item.grnId || ''],
        itemId: [item.id || ''],
        itemName: [item?.purchaseItemData?.item?.itemName || '', Validators.required],
        description: [item?.purchaseItemData?.description || ''],
        remark: [item.remark || ''],
        quantity: [
          item?.quantity || '1',
          [
            Validators.required,
            Validators.pattern('^[0-9]*$'),
            Validators.maxLength(6),
            this.quantityValidatorUpdate(item?.quantity, item?.purchaseItemData?.balanceQuantity),
          ],
        ],
        // totalQuantity: this.totalQuantity,
        orderedQuantity: item?.quantity,
        totalQuantity: item?.purchaseItemData?.balanceQuantity,
        rate: [rate || 0],
        amount: [amount || 0],
        itemStatus: [item?.grnItemStatus || ''],
        isManual: [item.isManual || false],
      });

      this.updateTotalPrice();

      formGroup.get('quantity')?.valueChanges.subscribe((quantity) => {
        const rate = formGroup.get('rate')?.value || 0;
        const totalQuantityAvailable =
          Number(item?.purchaseItemData?.balanceQuantity || 0) +
          Number(item?.quantity || 0);
        if (Number(quantity) < Number(totalQuantityAvailable)) {
          formGroup.patchValue({ itemStatus: 'Partial' }, { emitEvent: false });
        } else if (Number(quantity) === Number(totalQuantityAvailable)) {
          formGroup.patchValue({ itemStatus: 'Completed' }, { emitEvent: false });
        }

        const amount = rate * quantity;
        formGroup.patchValue({ amount }, { emitEvent: false });
        this.updateTotalPrice();
      });

      return formGroup;
    }

  }

  updateTotalPrice(): void {
    const items = this.items.controls as FormGroup[];
    console.log(`Total groups: ${items.length}`); 
    let subTotal = 0;

    items.forEach((group, index) => {
      const amount = group.get('amount')?.value || 0;
      console.log(`Group ${index + 1} Amount: ${amount}`); // Debugging log
      subTotal += parseFloat(amount);
    });

    this.subTotal = subTotal;
    this.totalPrice = subTotal;

    
  }


  quantityValidator(totalQuantity: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const quantity = control.value;
      if (Number(quantity) <= 0) {
        return { lessThanZero: true };
      }
      if (Number(quantity) > Number(totalQuantity)) {
        return { exceedsTotal: true };
      }
      return null;
    };
  }

  quantityValidatorUpdate(quantityLeft: number, quantityInput: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const quantity = control.value;
      const totalQuantityAvailable = Number(quantityLeft) + Number(quantityInput);
      console.log('quantity--->', quantity);
      console.log('totalQuantityAvailable---->', totalQuantityAvailable);

      if (Number(quantity) <= 0) {
        return { lessThanZero: true };
      }
      if (Number(quantity) > totalQuantityAvailable) {
        return { exceedsTotal: true };
      }

      return null;
    };
  }

  filterVendors(searchText: string) {
    if (searchText) {
      this.filteredVendors = this.vendors.filter((vendor: any) =>
        vendor.displayName.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      this.filteredVendors = [...this.vendors];
    }
  }

  filterPurchase(searchText: string) {
    if (searchText) {
      this.filteredPurchase = this.allOrders.filter((purchase: any) =>
        purchase.purchaseOrderNo.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      this.filteredPurchase = [...this.allOrders];
    }
    console.log(this.filteredPurchase);

  }

  fetchVendors() {
    this.customerService.fetchPartners(this.search).subscribe({
      next: (data) => {
        this.vendors = data.filter((item: any) => item.partnerType === 'Vendor');
        this.filteredVendors = [...this.vendors];
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  fetchAllPurchaseOrder(id: any) {
    let purchaseStatus: any = []
    if (!this.grnId) {
      purchaseStatus = ['ISSUED']
    }
    this.grnServices.fetchAllPurchaseOrder(id, purchaseStatus).subscribe({
      next: (orders) => {
        this.allOrders = orders;
        this.filteredPurchase = [...this.allOrders];
      },
      error: (err) => {
        console.error('Error fetching all purchase-orders:', err);
      },
    })

  }

  fetchWorkOrder(id: any) {
    this.purchaseOrderService.fetchPurchaseOrder(id).subscribe({
      next: (workOrder) => {
        const workOrderNo = workOrder?.workorderData?.workOrderNo;
        this.newGRNForm.patchValue({
          workOrderNumber: workOrderNo
        })
        const purchaseItems = workOrder?.purchaseItems || [];
        this.items.clear(); // Clear existing items in the FormArray

        if (!this.grnData) {
          purchaseItems.forEach((item: any) => {
            this.items.push(this.createItemForm(item));
            // }
          });
          this.updateTotalPrice();
        }
      },
      error: (err) => {
        console.error('Error fetching all purchase-orders:', err);
      },
    })

  }

  fetchGrnById(id: any) {
    this.grnServices.goodsReceived(Number(id)).subscribe({
      next: (data) => {
        this.grnData = data;
        this.initForm();
        const purchaseItems = data?.goodsReceivedItems;
        purchaseItems.forEach((item: any) => {
          this.items.push(this.createItemForm(item));
        });
        this.updateTotalPrice();
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }



  onSaveAsDraft() {
    if (this.newGRNForm.invalid) {
      console.log(this.newGRNForm.value);
      this.newGRNForm.markAllAsTouched();

      return;
    }
    if (!this.grnData) {
      const createGoodsReceivedInput = {
        receivedById: this.newGRNForm.value.receivedById,
        grnNumber: this.newGRNForm.value.grnNumber,
        goodsReceivedDate: this.datePipe.transform(this.newGRNForm.value.goodsReceivedDate, 'YYYY-MM-dd'),
        grnStatus: 'Draft',
        purchaseOrderId: this.newGRNForm.value.purchaseOrderId,
      }

      this.purchaseItem = [];
      const grnItems = this.items.value;
      if (grnItems.length > 0) {
        grnItems.map((item: any) => {
          console.log(item);
          if (item?.quantity !== "0") {
            const grnItem = {
              quantity: String(item?.quantity),
              purchaseItemId: item?.purchaseItemId,
              grnItemStatus: item?.itemStatus,
              remark: item?.remark
            }
            this.purchaseItem.push(grnItem);
          }
        })
      }
      if (grnItems.length > 0) {
        this.grnServices.createGoodsReceivedWithItems(createGoodsReceivedInput, this.purchaseItem).subscribe({
          next: (data: any) => {
            if (data) {
              this.toastr.success('GRN created successfully!', 'Success');
              this.newGRNForm.reset();
              this.subTotal = 0;
              this.totalPrice = 0;
              this.router.navigate([`/goods-received-note`]);
            }
          },
          error: (error: any) => {
            this.toastr.error(error, 'Error');
          }
        })
      }
    } else if (this.grnData) {
      const updateGoodsReceivedInput = {
        id: Number(this.grnId),
        receivedById: this.newGRNForm.value.receivedById,
        grnNumber: this.newGRNForm.value.grnNumber,
        goodsReceivedDate: this.datePipe.transform(this.newGRNForm.value.goodsReceivedDate, 'YYYY-MM-dd'),
        grnStatus: 'Draft',
        purchaseOrderId: Number(this.grnData?.purchaseOrderData?.id),
      }

      this.purchaseItem = [];
      const grnItems = this.items.value;
      if (grnItems.length > 0) {
        grnItems.map((item: any) => {
          console.log(item);
          if (item?.quantity !== "0") {
            const grnItem = {
              id: Number(item?.itemId),
              quantity: String(item?.quantity),
              purchaseItemId: item?.purchaseItemId,
              grnItemStatus: item?.itemStatus,
              remark: item?.remark
            }
            this.purchaseItem.push(grnItem);
          }
        })
      }
      if (grnItems.length > 0) {
        this.grnServices.editGoodsReceived(updateGoodsReceivedInput, this.purchaseItem).subscribe({
          next: (data: any) => {
            if (data) {
              this.toastr.success('GRN updated successfully!', 'Success');
              this.newGRNForm.reset();
              this.subTotal = 0;
              this.totalPrice = 0;
              this.router.navigate([`/goods-received-note`]);
            }
          },
          error: (error: any) => {
            this.toastr.error(error, 'Error');
          }
        })
      }
    }
  }

  onCreateGRN() {
    if (this.newGRNForm.invalid) {
      console.log(this.newGRNForm.controls);
      this.newGRNForm.markAllAsTouched();
      return;
    }
    if (!this.grnData) {
      const createGoodsReceivedInput = {
        receivedById: this.newGRNForm.value.receivedById,
        grnNumber: this.newGRNForm.value.grnNumber,
        goodsReceivedDate: this.datePipe.transform(this.newGRNForm.value.goodsReceivedDate, 'YYYY-MM-dd'),
        grnStatus: 'Completed',
        purchaseOrderId: this.newGRNForm.value.purchaseOrderId,
      }

      this.purchaseItem = [];
      const grnItems = this.items.value;
      if (grnItems.length > 0) {
        grnItems.map((item: any) => {
          console.log(item);
          if (item?.quantity !== "0") {
            const grnItem = {
              quantity: String(item?.quantity),
              purchaseItemId: item?.purchaseItemId,
              grnItemStatus: item?.itemStatus,
              remark: item?.remark
            }
            this.purchaseItem.push(grnItem);
          }
        })
      }
      if (grnItems.length > 0) {
        this.grnServices.createGoodsReceivedWithItems(createGoodsReceivedInput, this.purchaseItem).subscribe({
          next: (data: any) => {
            if (data) {
              this.toastr.success('GRN created successfully!', 'Success');
              this.newGRNForm.reset();
              this.subTotal = 0;
              this.totalPrice = 0;
              this.router.navigate([`/goods-received-note`]);
            }
          },
          error: (error: any) => {
            this.toastr.error(error, 'Error');
          }
        })
      }
    } else if (this.grnData) {
      const updateGoodsReceivedInput = {
        id: Number(this.grnId),
        receivedById: this.newGRNForm.value.receivedById,
        grnNumber: this.newGRNForm.value.grnNumber,
        goodsReceivedDate: this.datePipe.transform(this.newGRNForm.value.goodsReceivedDate, 'YYYY-MM-dd'),
        grnStatus: 'Completed',
        purchaseOrderId: Number(this.grnData?.purchaseOrderData?.id),
      }

      this.purchaseItem = [];
      const grnItems = this.items.value;
      if (grnItems.length > 0) {
        grnItems.map((item: any) => {
          console.log(item);
          if (item?.quantity !== "0") {
            const grnItem = {
              id: Number(item?.itemId),
              quantity: String(item?.quantity),
              purchaseItemId: item?.purchaseItemId,
              grnItemStatus: item?.itemStatus,
              remark: item?.remark
            }
            this.purchaseItem.push(grnItem);
          }
        })
      }
      if (grnItems.length > 0) {
        this.grnServices.editGoodsReceived(updateGoodsReceivedInput, this.purchaseItem).subscribe({
          next: (data: any) => {
            if (data) {
              this.toastr.success('GRN updated successfully!', 'Success');
              this.newGRNForm.reset();
              this.subTotal = 0;
              this.totalPrice = 0;
              this.router.navigate([`/goods-received-note`]);
            }
          },
          error: (error: any) => {
            this.toastr.error(error, 'Error');
          }
        })
      }
    }
  }

  onCancel(){
    this.router.navigate(['/goods-received-note']);
  }
}
