import { gql } from 'apollo-angular';

export const GET_DEPARTMENTS = gql`
  query departments($search: String, $status: Int) {
    departments(search: $search, status: $status) {
      id
    departmentName
    status
    createdAt
    updatedAt
    deletedAt
    }
  }
`;
