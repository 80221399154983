<div class="tab-inner-content">
  <h2>Sales by Item</h2>
  <h5>
    From {{ startDate | date : "MM/d/YYYY" }} to
    {{ endDate | date : "MM/d/YYYY" }}
  </h5>

  <table>
    <tr>
      <th>Item Name</th>
      <th>SKU</th>
      <th>Quantity Sold</th>
      <th>Amount</th>
      <th>Average Price</th>
      <th>Total</th>
    </tr>
    <tr *ngFor="let item of salesByItemReport.items" class="child">
      <td>{{ item.itemName }}</td>
      <td>{{ item.skuCode }}</td>
      <td>{{ item.quantitySold }}</td>
      <td>{{ item.amount | currency }}</td>
      <td>{{ item.averagePrice | currency }}</td>
    </tr>
    <tr class="sub-total">
      <td>Total Quantity</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>{{ salesByItemReport?.totalQuantity }}</td>
    </tr>
    <tr class="sub-total">
      <td>Total Amount</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>{{ salesByItemReport?.totalAmount }}</td>
    </tr>
  </table>
</div>
