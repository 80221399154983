import { Component, HostListener, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { PaymentsItemService } from '../../features/payments/services/payments.service';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DialogRef } from '@angular/cdk/dialog';
import { PAYMENT_MODES } from '../../helpers/helper-file';
import { FormsModule } from '@angular/forms';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';


@Component({
  selector: 'app-payment-received',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    AgGridAngular,
    FormsModule,
    PermissionDirective
  ],
  templateUrl: './payment-received.component.html',
  styleUrl: './payment-received.component.scss',
})
export class PaymentReceivedComponent {
  public viewPortSize: boolean = false;
  public loading: boolean = true;
  public rowData: any[] = [];
  public paginationPageSizeSelector: number[] | boolean = [10];
  private searchSubject = new Subject<string>();
  paymentModeList: any = PAYMENT_MODES;
  selectedModes: string[] = [];
  selectedCount: number = 0;
  columnDefs: { headerName: string; field: string; flex: number; }[];
  Permission = Permission;

  constructor(
    private router: Router,
    private itemService: PaymentsItemService,
    private datePipe: DatePipe,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.fetchItems();
    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchItems(searchTerm);
      });

    this.checkViewportSize();
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadPaymentReceivedDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'refresh') {
        this.fetchItems();
      }
    });
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {

    this.columnDefs = [
      {
        headerName: 'Date',
        field: 'data',
        flex: this.viewPortSize ? 1 : 0,
      },
      { headerName: 'Payment', field: 'paymentNo', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Reference No', field: 'referenceNo', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Customer Name', field: 'name', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Invoice', field: 'invoice', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Mode', field: 'mode', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Amount', field: 'amount', flex: this.viewPortSize ? 1 : 0 },
    ];
  }

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  // public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  fetchItems(
    search: string = '',
    filter: any = { paymentType: 'Invoice', paymentMode: [] }
  ) {
    this.loading = true;
    this.itemService.fetchPayments(search, filter).subscribe({
      next: (items) => {
        this.rowData = items.map((item: any) => {
          return {
            id: item?.id,
            data:
              this.datePipe.transform(item?.paymentDate, 'dd/MM/YYYY') || '--',
            paymentNo: item?.paymentNo || '--',
            referenceNo: item?.referenceNo || '--',
            name: item?.partner?.displayName || '--',
            invoice: item?.paymentInfo?.[0]?.invoice?.invoiceNo || '--',
            mode: item?.paymentMode || '--',
            amount: item?.amount || '--',
          };
        });
        // Update pagination options based on the rowData length
        this.paginationPageSizeSelector =
          PaginationHelper.getPaginationPageSizeOptions(this.rowData);
        this.loading = false;
      },
    });
  }

  toggleMode(mode: string) {
    if (this.selectedModes.includes(mode)) {
      this.selectedModes = this.selectedModes.filter((m) => m !== mode);
    } else {
      this.selectedModes.push(mode);
    }
    this.selectedCount = this.selectedModes?.length;
  }

  onApplyFilters() {
    this.fetchItems('', {
      paymentType: 'Invoice',
      paymentMode: this.selectedModes,
    });
    this.toggleDropdown();
  }

  clearFilters() {
    this.selectedModes = [];
    this.fetchItems('', { paymentType: 'Invoice', paymentMode: [] });
    this.toggleDropdown();
    this.selectedCount = 0;
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  onCellClicked(params: any) {
    // this.router.navigate([`payment-received/payment-received-details`]);
    const id = params.data?.id;
    this.router.navigate([`payment-received/payment-received-details`], {
      queryParams: { id: id },
    });
  }
}

@Component({
  selector: 'upload-payment-received-dialog',
  templateUrl: 'upload-payment-received-dialog.html',
  styleUrls: ['./payment-received.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadPaymentReceivedDialog {
  file: any = null;
  url: any = null;

  constructor(
    private toastr: ToastrService,
    private itemService: PaymentsItemService,
    private dialogRef: MatDialogRef<UploadPaymentReceivedDialog>
  ) { }

  ngOnInit() {
    document.addEventListener('dragover', this.preventDefaultBehavior);
    document.addEventListener('drop', this.preventDefaultBehavior);
  }

  preventDefaultBehavior(event: DragEvent) {
    event.preventDefault();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer?.files.length) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }

  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.file = file;
    }
  }

  uploadDocuments(csvFile: any) {
    this.itemService.bulkCreatePayment(csvFile).subscribe({
      next: (response) => {
        if (response == true) {
          this.toastr.success('Bulk updated successfully!');
          this.dialogRef.close('refresh');
        }
      },
      error: (error) => {
        this.toastr.error(error.message, 'Bulk updated failed');
        this.dialogRef.close();
      },
    });
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  onUpload() {
    this.uploadDocuments(this.file);
  }

  ngOnDestroy() {
    document.removeEventListener('dragover', this.preventDefaultBehavior);
    document.removeEventListener('drop', this.preventDefaultBehavior);
  }
}
