import { gql } from "apollo-angular";

export const CREATE_LEAVE = gql`
  mutation createLeave( $createLeaveInput: CreateLeaveManagementInput!) {
  createLeave(createLeaveInput: $createLeaveInput) {
    id
    employeeId
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      employeeApprovers{
        employee{
          firstName
          lastName
        }
      }
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      departmentId
    }
    leaveType
    startDate
    endDate
    totalLeaveDays
    leaveStatus
    employeeComment
    supportingDocuments
    createdAt
    updatedAt
    deletedAt
  }
}
`;
export const UPDATE_LEAVE = gql`
  mutation updateLeave( $updateLeaveInput: UpdateLeaveManagementInput!) {
    updateLeave(updateLeaveInput: $updateLeaveInput) 
  {
    id
    employeeId
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      employeeApprovers{
        employee{
          firstName
        }
      }
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      departmentId
    }
    leaveType
    startDate
    endDate
    totalLeaveDays
    leaveStatus
    employeeComment
    supportingDocuments
    createdAt
    updatedAt
    deletedAt
  }
}
`;
export const REMOVE_LEAVE = gql`
  mutation removeLeave( $id: Int!) {
    removeLeave(id: $id)
}
`;
export const APPROVE_LEAVE = gql`
  mutation updateLeaveStatus( $id: Int!, $status: LeaveApproverApprovalStatusEnum!, $comment:String!) {
    updateLeaveStatus(id: $id, status: $status, comment: $comment)
}
`;

export const UPDATE_MY_LEAVE = gql`
  mutation updateMyLeaveStatus( $id: Int!, $status: LeaveApproverApprovalStatusEnum!) {
    updateMyLeaveStatus(id: $id, status: $status)
}
`;
