import { gql } from 'apollo-angular';

export const GET_CURRENCIES = gql`
  query currencies($search: String, $filter: CurrencyFilter) {
    currencies(search: $search, filter: $filter) {
      id
      currencyCode
      currencyName
      currencySymbol
      exchangeRate
      status
      isPrimary
    }
  }
`;

export const GET_CURRENCY_BY_ID = gql`
  query currency($id: Int!) {
    currency(id: $id) {
      id
      currencyCode
      currencyName
      currencySymbol
      exchangeRate
      status
      isPrimary
    }
  }
`;

export const GET_EXCHANGE_LOG_BY_CURRENCY = gql`
  query getExchangeRateLogByCurrency($currencyId: Int!) {
    getExchangeRateLogByCurrency(currencyId: $currencyId) {
      id
      currencyId
      oldExhangeRate
      newExhangeRate
      changedAt
      createdBy
    }
  }
`;
