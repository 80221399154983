import { gql } from 'apollo-angular';

export const CREATE_HOLIDAY_CALENDAR = gql`
  mutation createHolidayCalendar(
    $createHolidayCalendarInput: CreateHolidayCalendarInput!
  ) {
    createHolidayCalendar(
      createHolidayCalendarInput: $createHolidayCalendarInput
    ) {
      id
      holidayName
      holidayDate
      holidayType
      isMoon
      status
      description
    }
  }
`;

export const UPDATE_HOLIDAY_CALENDAR = gql`
  mutation updateHolidayCalendar(
    $updateHolidayCalendarInput: UpdateHolidayCalendarInput!
  ) {
    updateHolidayCalendar(
      updateHolidayCalendarInput: $updateHolidayCalendarInput
    ) {
      id
      holidayName
      holidayDate
      holidayType
      isMoon
      status
      description
    }
  }
`;

export const REMOVE_HOLIDAY_CALENDAR = gql`
  mutation removeHolidayCalendar($id: Int!) {
    removeHolidayCalendar(id: $id)
  }
`;
