import { EmployeeService } from './../../features/employees/services/employee.service';
import { InventoryItemService } from './../../features/inventory/services/inventory.service';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { QuotationService } from '../../features/quotation/services/quotation.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadFileService } from '../../shared/services/file-upload.service';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { MatDialog } from '@angular/material/dialog';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { CreateClientDialog } from '../invoice-new/dialog-components/create-customer-dialog/create-client-dialog.component';
import { BillingAddressDialog } from '../invoice-new/dialog-components/add-billing-address-dialog/billing-address-dialog.component';
import { ShippingAddressDialog } from '../invoice-new/dialog-components/add-shipping-address-dialog/shipping-address-dialog.component';
import { ListBillingAddressDialog } from '../invoice-new/dialog-components/list-billing-address-dialog/list-billing-address-dialog.component';
import { ListShippingAddressDialog } from '../invoice-new/dialog-components/list-shipping-address-dialog/list-shipping-address-dialog.component';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import {
  nonNegativeValidator,
  trnValidator,
} from '../../shared/services/validations';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { PreferenceService } from '../../shared/services/preference.service';

@Component({
  selector: 'app-quotation-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule,
  ],
  templateUrl: './quotation-new.component.html',
  styleUrl: './quotation-new.component.scss',
})
export class QuotationNewComponent implements OnInit {
  public arr = [1, 2, 3, 4, 5, 6, 7];
  filter: {} = {};
  search: string = '';
  loading: boolean = false;
  quoteForm: FormGroup;
  quoteData: any;
  quoteId: number | null = null;
  subTotalValue: number;
  public selectedBillingAddress: any = {};
  public selectedShippingAddress: any = {};
  public billingAddressList: any[];
  public shippingAddressList: any[];
  public partnersData: any[] = [];
  public itemData: any[] = [];
  private searchSubject = new Subject<string>();
  public projectData: any[] = [];
  private projectSearchSubject = new Subject<string>();
  public branchData: any[] = [];
  private branchSearchSubject = new Subject<string>();
  selectedCustomerId: any;
  public filteredItemData: any[] = [];
  readonly dialog = inject(MatDialog);

  file: any = null;
  csvFile: File[] = [];
  url: string = '';
  fileDetailsArray: {
    fileName: string;
    fileUrl: string;
    id?: number;
    // isNew: boolean;
  }[] = [];

  unitTypes = [
    {
      label: 'cm',
      value: 'cm',
    },
    {
      label: 'm',
      value: 'm',
    },
    {
      label: 'kg',
      value: 'kg',
    },
    {
      label: 'g',
      value: 'g',
    },
    {
      label: 'l',
      value: 'l',
    },
    {
      label: 'ml',
      value: 'ml',
    },
    {
      label: 'pc',
      value: 'pc',
    },
    {
      label: 'box',
      value: 'box',
    },
    {
      label: 'ft',
      value: 'ft',
    },
    {
      label: 'km',
      value: 'km',
    },
    {
      label: 'mg',
      value: 'mg',
    },
  ];

  @ViewChild('fileInput') fileInput!: ElementRef;
  currentFiles: File[] = [];
  private status: 'DRAFT' | 'SENT' = 'SENT';
  prefix: any;
  quoteNumber: any;

  constructor(
    private quoteService: QuotationService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private itemService: InventoryItemService,
    private documentUploadService: UploadFileService,
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
    private employeeService: EmployeeService,
    private preferenceService: PreferenceService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.preferenceCode();
    this.fetchPartners();
    this.fetchItems();
    this.generateQuoteNumber();
    // this.listProjects();
    this.listBranches();

    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.quoteId = +id;
        this.loadQuoteData();
      }
    });

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchPartners(searchTerm);
      });

    this.projectSearchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        // this.listProjects(searchTerm);
      });

    this.branchSearchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.listBranches(searchTerm);
      });

    this.items.valueChanges.subscribe(() => {
      this.getTotalAmount();
      this.getSubTotalAmount();
      this.getTotalDiscount();
    });
    if (!this.quoteId) {
      this.addItem();
      this.addExclusion();
    }
  }

  initForm(): void {
    this.quoteForm = this.fb.group({
      id: [this.quoteData?.id || ''],
      partnerId: [this.quoteData?.partnerId, Validators.required],
      shippingAddressId: this.selectedShippingAddress.id,
      billingAddressId: this.selectedBillingAddress.id,
      TRN: [this.quoteData?.TRN, [Validators.required, trnValidator]],
      quoteCode: [this.quoteData?.quoteCode, Validators.required],
      tax: [this.quoteData?.tax],
      quoteTitle: [this.quoteData?.quoteTitle, Validators.required],
      refNumber: [this.quoteData?.refNumber],
      quoteDate: [this.quoteData?.quoteDate || new Date(), Validators.required],
      expiryDate: [this.quoteData?.expiryDate],
      projectId: [this.quoteData?.projectId],
      branchId: [this.quoteData?.branchId],
      SOW: [this.quoteData?.SOW],
      subject: [this.quoteData?.subject],
      paymentTerm: [this.quoteData?.paymentTerm],
      deliveryTerm: [this.quoteData?.deliveryTerm],
      validity: [this.quoteData?.validity],
      termsCondition: [this.quoteData?.termsCondition],
      items: this.fb.array([]),
      customerNote: [''],
      exclusions: this.fb.array([]),
      quoteAttachments: this.fb.array([]),
    });
  }

  preferenceCode() {
    this.preferenceService.preferenceCodes('', 'Quote').subscribe({
      next: (response) => {
        response.forEach((prefix: any) => {
          this.quoteNumber = prefix?.prefix;
        });
        const randomSuffix = Math.floor(1000 + Math.random() * 9000);
        this.prefix = `${this.quoteNumber + randomSuffix}`;
        this.quoteForm.patchValue({ quoteCode: this.prefix });
      },
      error: (error) => {
        console.error(error, 'Error');
      },
    });
  }

  disableBeforeQuoteDate = (date: Date | null): boolean => {
    const quoteDate = this.quoteForm.get('quoteDate')?.value;
    if (!quoteDate || !date) {
      return true;
    }
    return date >= new Date(quoteDate);
  };

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  onProjectSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.projectSearchSubject.next(searchTerm);
  }

  onBranchSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.branchSearchSubject.next(searchTerm);
  }

  // onCustomerSelected(event: MatSelectChange) {
  //   const selectedCustomerId = event.value;
  //   this.selectedCustomerId = selectedCustomerId;
  // }

  onCustomerSelected(event: MatSelectChange): void {
    const selectedCustomerId = event.value;
    this.selectedCustomerId = event.value;
    const selectedPartner = this.partnersData.find(
      (partner) => partner.id === selectedCustomerId
    );

    if (selectedPartner && selectedPartner.partnerProjects) {
      this.projectData = selectedPartner.partnerProjects.map(
        (project: any) => ({
          id: project.id,
          projectName: project.projectName,
          projectCode: project.projectCode,
          totalProjectCost: project.totalProjectCost,
          description: project.description,
        })
      );
    } else {
      this.projectData = [];
    }
  }

  get items(): FormArray {
    return this.quoteForm.get('items') as FormArray;
  }

  get exclusions(): FormArray {
    return this.quoteForm.get('exclusions') as FormArray;
  }

  loadQuoteData(): void {
    if (this.quoteId !== null) {
      this.quoteService.fetchQuoteById(this.quoteId).subscribe({
        next: (data) => {
          this.quoteData = data;
          console.log('quoteData', this.quoteData);
          this.quoteForm.patchValue({
            partnerId: this.quoteData.partnerId,
            shippingAddressId: this.quoteData.shippingAddressId,
            billingAddressId: this.quoteData.billingAddressId,
            TRN: this.quoteData.TRN,
            quoteCode: this.quoteData.quoteCode,
            quoteTitle: this.quoteData.quoteTitle,
            tax: this.quoteData.tax,
            refNumber: this.quoteData.refNumber,
            quoteDate: this.quoteData.quoteDate
              ? new Date(Number(this.quoteData.quoteDate))
              : null,
            expiryDate: this.quoteData.expiryDate
              ? new Date(Number(this.quoteData.expiryDate))
              : null,
            projectId: this.quoteData.projectId,
            branchId: this.quoteData.branchId,
            SOW: this.quoteData.SOW,
            paymentTerm: this.quoteData.paymentTerm,
            deliveryTerm: this.quoteData.deliveryTerm,
            validity: this.quoteData.validity
              ? new Date(Number(this.quoteData.validity))
              : null,
            termsCondition: this.quoteData?.termsCondition,
            subject: this.quoteData.subject,
            customerNote: this.quoteData.customerNote,
          });

          if (data.quoteItems) {
            data.quoteItems.forEach((item: any) => {
              const itemForm = this.fb.group({
                id: [item.id || null],
                description: [item.description, Validators.required],
                quantity: [item.quantity, Validators.required],
                unit: [item.unit, Validators.required],
                rate: [item.rate, Validators.required],
                amount: [item.amount, Validators.required],
                discount: [item.discount, Validators.pattern(/^\d+(\.\d+)?$/)],
              });
              this.items.push(itemForm);
              this.calculateAmount(itemForm);
            });
          }

          this.setAddresses(this.quoteData.partnerId);
          this.selectedCustomerId = this.quoteData.partnerData.id;

          if (this.quoteData?.exclusions) {
            this.quoteData.exclusions.forEach((exclusion: any) => {
              const exclusionGroup = this.fb.group({
                exclusion: [exclusion.exclusion || ''],
              });
              this.exclusions.push(exclusionGroup);
            });
          }
          if (data.quoteAttachment) {
            data.quoteAttachment.forEach((attachment: any) => {
              this.fileDetailsArray.push({
                id: attachment.id,
                fileName: attachment.fileName,
                fileUrl: attachment.fileUrl,
              });
            });
          }
        },
      });
    }
  }

  // getItemTotalAmount(item: any): number {
  //   const quantity = item.quantity || 0;
  //   const rate = item.rate || 0;
  //   const discount = item.discount || 0;

  //   // Calculate the total amount after discount
  //   const totalBeforeDiscount = quantity * rate;
  //   const discountAmount = (totalBeforeDiscount * discount) / 100;
  //   return totalBeforeDiscount;
  // }

  // getTotalAmount(): number {
  //   return this.items.controls.reduce((total, itemGroup) => {
  //     return total + itemGroup.get('amount')?.value * 1.05 || 0;
  //   }, 0);
  // }

  // getTotalDiscount(): number {
  //   if (!this.items || !Array.isArray(this.items.controls)) {
  //     return 0;
  //   }

  //   return this.items.controls.reduce((total, itemGroup) => {
  //     const discountValue = parseFloat(itemGroup.get('discount')?.value) || 0; 
  //     return total + discountValue;
  //   }, 0);
  // }

  // getSubTotalAmount(): number {
  //   return this.items.controls.reduce(
  //     (total, item) => total + this.getItemTotalAmount(item.value),
  //     0
  //   );
  // }

  calculateAmount(itemGroup: FormGroup): void {
    const rate = parseFloat(itemGroup.get('rate')?.value || '0');
    const quantity = parseFloat(itemGroup.get('quantity')?.value || '0');
    const discount = itemGroup.get('discount')?.value || 0;
    let amount = rate * quantity;
    if (discount > 0) {
      amount -= (amount * discount) / 100;
    }
    itemGroup.patchValue({ amount });
  }

  getSubTotalAmount(): number {
    return this.items.controls.reduce((subtotal, item) => {
      const quantity = item.get('quantity')?.value || 0;
      const rate = item.get('rate')?.value || 0;
      return subtotal + quantity * rate;
    }, 0);
  }

  getTotalDiscount(): number {
    return this.items.controls.reduce((totalDiscount, item) => {
      const totalAmount = this.getItemTotalAmount(item.value);
      const netAmount = item.get('amount')?.value || 0;
      return totalDiscount + (totalAmount - netAmount);
    }, 0);
  }

  getVAT(): number {
    const subTotal = this.getSubTotalAmount();
    return subTotal * 0.05; // Assuming VAT is 5%
  }
  
  getTotalAmount(): number {
    const subTotal = this.getSubTotalAmount();
    const totalDiscount = this.getTotalDiscount();
    const vat = this.getVAT();
    return subTotal - totalDiscount + vat;
  }
  
  getItemTotalAmount(item: any): number {
    const quantity = item.quantity || 0;
    const rate = item.rate || 0;
    return quantity * rate;
  }
  

  addItem(): void {
    const itemGroup = this.fb.group({
      // itemId: [null],
      description: ['', Validators.required],
      quantity: [
        '1',
        [
          Validators.required,
          Validators.pattern(/^\d+(\.\d+)?$/),
          nonNegativeValidator,
        ],
      ],
      unit: ['', Validators.required],
      rate: ['0', [Validators.required, nonNegativeValidator]],
      discount: [
        0,
        [Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.max(100)],
      ],
      amount: [0, [Validators.required, nonNegativeValidator]],
    });

    this.items.push(itemGroup);

    // Trigger validation checks after adding the group
    itemGroup.updateValueAndValidity();
    this.items.updateValueAndValidity();

    // Recalculate amount on rate, quantity, or discount changes
    itemGroup
      .get('rate')
      ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
    itemGroup
      .get('quantity')
      ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
    itemGroup
      .get('discount')
      ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
  }

  addExclusion(): void {
    const exclusionGroup = this.fb.group({
      exclusion: [''],
    });

    this.exclusions.push(exclusionGroup);
  }

  setAddresses(partnerId: number): void {
    const selectedPartner = this.partnersData.find((p) => p.id === partnerId);

    if (selectedPartner) {
      if (
        Array.isArray(selectedPartner.addresses) &&
        selectedPartner.addresses.length > 0
      ) {
        this.selectedBillingAddress =
          selectedPartner.addresses.find(
            (addr: any) => addr.addressType === 'BILLING'
          ) || null;
        this.selectedShippingAddress =
          selectedPartner.addresses.find(
            (addr: any) => addr.addressType === 'SHIPPING'
          ) || null;

        this.quoteForm.patchValue({
          billingAddressId: this.selectedBillingAddress?.id || null,
          shippingAddressId: this.selectedShippingAddress?.id || null,
        });
      } else {
        console.error(
          `No addresses found or addresses is not an array for partnerId: ${partnerId}`
        );
      }
    } else {
      console.error(`Partner with ID ${partnerId} not found in partnersData.`);
    }
  }

  getUpdateQuoteItemInput(): any[] {
    return this.items.controls.map((itemGroup) => {
      const id = itemGroup.get('id')?.value;
      const description = itemGroup.get('description')?.value;
      const quantity = itemGroup.get('quantity')?.value;
      const unit = itemGroup.get('unit')?.value;
      const rate = itemGroup.get('rate')?.value;
      const amount = itemGroup.get('amount')?.value;
      const discount = itemGroup.get('discount')?.value;

      const existingQuoteItem = this.quoteData?.quoteItems?.find(
        (quoteItem: any) => quoteItem.id === id
      );

      return {
        id: existingQuoteItem ? existingQuoteItem.id : null, // Existing item gets id; new item gets null
        description,
        quantity,
        unit,
        rate,
        discount,
        amount,
      };
    });
  }

  saveAsDraft(): void {
    this.status = 'DRAFT';
    this.createQuote('quotation/quotation-details');
  }

  saveAndSend(): void {
    this.status = 'DRAFT';
    // this.createQuote('quotation/send-mail');
    this.createQuote('quotation/quotation-details/mail');
  }

  createQuote(navigateTo: string): void {
    if (this.quoteForm.invalid) {
      this.quoteForm.markAllAsTouched();
      this.toastr.warning('Please fill in all required fields.');
      return;
    } else {
      const formValues = this.quoteForm.value;

      const loginEmployee: any = localStorage.getItem('loggedInUser');
      const employee = JSON.parse(loginEmployee);
      const employeeId = employee.employeeId;

      const subTotal = this.getSubTotalAmount(); // Calculates the subtotal
      const totalAmount = this.getTotalAmount();

      const createQuoteInput = {
        partnerId: formValues.partnerId,
        billingAddressId: formValues.billingAddressId,
        shippingAddressId: formValues.shippingAddressId,
        quoteCode: formValues.quoteCode,
        quoteTitle: formValues.quoteTitle,
        tax: 5,
        TRN: formValues.TRN,
        SOW: formValues.SOW,
        refNumber: formValues.refNumber,
        quoteDate: formValues.quoteDate,
        expiryDate: formValues.expiryDate,
        // approvalDate: formValues.approvalDate,
        projectId: formValues.projectId,
        branchId: formValues.branchId,
        paymentTerm: formValues.paymentTerm,
        deliveryTerm: formValues.deliveryTerm,
        validity: formValues.validity,
        termsCondition: formValues.termsCondition,
        // subject: formValues.subject,
        customerNote: formValues.customerNote,
        createdBy: employeeId,
        subTotal,
        totalAmount
      };
      const createQuoteItemInput = this.quoteForm.value.items.map(
        (item: any) => {
          // if (item.isManual) {
          return {
            description: item.description,
            quantity: item.quantity,
            unit: item.unit,
            rate: item.rate,
            discount: item.discount,
            amount: item.amount,
          };
        }
      );

      const createQuoteAttachmentInput = this.fileDetailsArray
        .filter((file) => !file.id)
        .map((file) => ({
          // id: null,
          fileName: file.fileName,
          fileUrl: file.fileUrl,
        }));

      const updateQuoteAttachmentInput = this.fileDetailsArray.map((file) => ({
        id: file.id || null, // Retain the `id` for existing files or set `null` for new files
        fileName: file.fileName,
        fileUrl: file.fileUrl,
      }));

      const createExclusionInput = this.exclusions.value.map(
        (exclusion: any) => ({
          exclusion: exclusion.exclusion,
        })
      );

      const updateExclusion = [{}];

      const updateQuoteInput = {
        id: this.quoteId,
        ...createQuoteInput,
      };

      const quoteItemsInput = this.getUpdateQuoteItemInput();

      this.loading = true;
      if (this.quoteId) {
        this.quoteService
          .updateQuotation(
            updateQuoteInput,
            quoteItemsInput,
            updateQuoteAttachmentInput,
            updateExclusion
          )
          .subscribe({
            next: (response) => {
              this.loading = false;
              this.toastr.success('Quotation updated successfully.');
              const quoteId = response?.data?.updateQuote?.id;
              if (quoteId) {
                // this.router.navigate([`${navigateTo}/${quoteId}`]);
                this.quoteService.generateQuotePDF(quoteId).subscribe({
                  next: (pdfResponse) => {
                    console.log('PDF generated successfully', pdfResponse);
                    this.router.navigate([`${navigateTo}`], {
                      queryParams: { quoteId },
                    });
                  },
                  error: (pdfError) => {
                    console.error('Error generating PDF:', pdfError);
                    this.toastr.error(
                      pdfError.message || 'Failed to generate PDF.'
                    );
                  },
                });
              } else {
                console.error('Quote ID is missing');
              }
            },
            error: (error) => {
              this.loading = false;
              console.error('Error updating Quotation:', error);
              this.toastr.error(error.message || 'Failed to update Quotation.');
            },
          });
      } else {
        this.quoteService
          .createQuote(
            createQuoteInput,
            createQuoteItemInput,
            createExclusionInput,
            createQuoteAttachmentInput
          )
          .subscribe({
            next: (response) => {
              this.toastr.success('Quote created successfully');
              const quoteId = response?.data?.createQuotes?.id;
              if (quoteId) {
                // this.router.navigate([`${navigateTo}/${quoteId}`]);
                this.quoteService.generateQuotePDF(quoteId).subscribe({
                  next: (pdfResponse) => {
                    console.log('PDF generated successfully', pdfResponse);
                    this.router.navigate([`${navigateTo}`], {
                      queryParams: { quoteId },
                    });
                  },
                  error: (pdfError) => {
                    console.error('Error generating PDF:', pdfError);
                    this.toastr.error(
                      pdfError.message || 'Failed to generate PDF.'
                    );
                  },
                });
              }
              // this.router.navigate([navigateTo]);
              this.loading = false;
            },
            error: (error) => {
              this.toastr.error(error.message || 'Failed to create Quote.');
              this.loading = false;
            },
          });
      }
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const newFiles = Array.from(input.files);

      if (this.fileDetailsArray.length + newFiles.length > 10) {
        this.toastr.warning('You can only upload a maximum of 10 files.');
        return;
      }

      newFiles.forEach((newFile) => {
        const allowedExtensions = ['pdf', 'doc', 'jpg', 'jpeg', 'png'];
        const fileExtension = newFile.name.split('.').pop()?.toLowerCase();

        if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
          this.toastr.warning('Only PDF, DOC, and image files are allowed.');
          return;
        }

        if (
          !this.fileDetailsArray.some((file) => file.fileName === newFile.name)
        ) {
          this.fileDetailsArray.push({ fileName: newFile.name, fileUrl: '' });
        }
      });

      this.uploadDocuments(newFiles);
    }
  }

  uploadDocuments(files: File[]): void {
    files.forEach((file: File) => {
      this.documentUploadService.uploadDocument(file, 'items').subscribe({
        next: (response) => {
          const uploadedFile = this.fileDetailsArray.find(
            (f) => f.fileName === file.name
          );
          if (uploadedFile) {
            uploadedFile.fileUrl = response.url;
          }
        },
        error: (error) => {
          this.toastr.error(error, 'File upload failed');
        },
      });
    });
    this.fileInput.nativeElement.value = '';
  }

  removeFile(index: number): void {
    const file = this.fileDetailsArray[index];
    if (file.id) {
      this.onRemoveQuoteAttachment(file.id);
    } else {
      // New file - simply remove from array
      this.fileDetailsArray.splice(index, 1);
    }
  }

  onRemoveQuoteAttachment(quoteAttachmentId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((response) => {
      if (response === true) {
        this.quoteService
          .removeQuoteAttachmentById(quoteAttachmentId)
          .subscribe({
            next: () => {
              this.fileDetailsArray = this.fileDetailsArray.filter(
                (file) => file.id !== quoteAttachmentId
              );
            },
            error: () => {
              this.toastr.error('Failed to remove quote attachment.');
            },
          });
      }
    });
  }

  onCancel(): void {
    this.quoteForm.reset();
    this.router.navigate(['/quotation']);
  }
  onRemoveQuoteItem(quoteItemId: number, index: number): void {
    if (quoteItemId) {
      const dialogRef = this.dialog.open(ConfirmDialog);
      dialogRef.afterClosed().subscribe((response) => {
        if (response === true) {
          this.removeItem(quoteItemId, index);
        }
      });
    } else {
      console.error('Item ID is null or undefined');
      this.items.removeAt(index);
    }
  }

  private removeItem(quoteItemId: number, index: number): void {
    this.quoteService.removeQuoteItemById(quoteItemId).subscribe({
      next: () => {
        console.log('Quote item removed successfully');
        this.items.removeAt(index);
      },
      error: () => {
        console.log('Failed to remove quote item.');
      },
    });
  }

  generateQuoteNumber(): void {
    const randomSuffix = Math.floor(1000 + Math.random() * 9000);
    const invoiceNo = `QT-${randomSuffix}`;
    this.quoteForm.patchValue({ invoiceNo });
  }

  private fetchPartners(search: string = '') {
    this.invoiceService.fetchClients(search).subscribe({
      next: (partners) => {
        this.partnersData = partners
          .filter((data: any) => data.partnerType === 'Customer')
          .map((data: any) => ({
            id: data.id || '--',
            name: data.displayName || '--',
            addresses: data.addresses || [],
            partnerProjects: data.partnerProjects || [],
          }));
        this.billingAddressList = partners
          .flatMap((partner: any) => partner.addresses || [])
          .filter((address: any) => address.addressType === 'BILLING');
        this.shippingAddressList = partners
          .flatMap((partner: any) => partner.addresses || [])
          .filter((address: any) => address.addressType === 'SHIPPING');
      },
      error: (error) => console.error(error),
    });
  }

  // fetchPartners(search: string): void {
  //   this.invoiceService.fetchClients(search).subscribe({
  //     next: (response) => {
  //       this.partnersData = response.data.getPartnersDropDownItems
  //         .filter((data: any) => data.partnerType === 'Customer')
  //         .map((partner: any) => ({
  //           id: partner.id,
  //           displayName: partner.displayName || partner.companyName,
  //           partnerProjects: partner.partnerProjects || [],
  //         }));
  //     },
  //     error: (err) => {
  //       console.error('Error fetching partners:', err);
  //     },
  //   });
  // }

  // private listProjects(search: string = '') {
  //   this.quoteService.listProjects(search).subscribe({
  //     next: (projects) => {
  //       this.projectData = projects.map((data: any) => ({
  //         id: data.id || '--',
  //         projectName: data.projectName || '--',
  //       }));
  //     },
  //     error: (error) => console.error(error),
  //   });
  // }

  private listBranches(search: string = '') {
    this.employeeService.getBranches().subscribe({
      next: (branches) => {
        this.branchData = branches.map((data: any) => ({
          id: data.id || '--',
          branchName: data.branchName || '--',
        }));
      },
      error: (error) => console.error(error),
    });
  }

  private fetchItems(search: string = '', filter: any = {}) {
    const updatedFilter = {
      ...filter,
      isSalesActive: true,
    };

    this.itemService.fetchItems(search, updatedFilter).subscribe({
      next: (items) => {
        this.itemData = items;
        this.filteredItemData = items;
      },
      error: (error) => console.error(error),
    });
  }

  openCreateClientDialog() {
    const dialogRef = this.dialog.open(CreateClientDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
      }
      this.fetchPartners();
    });
  }

  openBillingAddressDialog(type: any) {
    const selectedClientId = this.quoteForm.get('partnerId')?.value;
    this.dialog.open(BillingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }

  openShippingAddressDialog(type: any) {
    const selectedClientId = this.quoteForm.get('partnerId')?.value;
    this.dialog.open(ShippingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }

  openSelectBillingAddressDialog() {
    const selectedCustomerId = this.selectedCustomerId;
    const type = 'BILLING';

    const dialogRef = this.dialog.open(ListBillingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });

    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        this.selectedBillingAddress = address;
        this.quoteForm.patchValue({ billingAddressId: address.id });
      }
    });
  }

  openSelectShippingAddressDialog() {
    const selectedCustomerId = this.selectedCustomerId;
    const type = 'SHIPPING';

    const dialogRef = this.dialog.open(ListShippingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });
    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        console.log;
        this.selectedShippingAddress = address;
        this.quoteForm.patchValue({ shippingAddressId: address.id });
      }
    });
  }
}
