<mat-dialog-content>
    <section class="asset-add-assets-wrapper">
        <h2 class="cmn-popup-title">Dispose Asset</h2>
        <button class="cmn-close" mat-dialog-close></button>


        <ul>
            <li>Power Drill</li>
            <li>123456789</li>
            <li>Munawel Interior Decors</li>
        </ul>

        <div class="form-wrapper grid-form-wrapper">

         


            <div class="span-2">
                <mat-form-field>
                    <input matInput [matDatepicker]="picker2" formControlName="" placeholder="Purchase Date" />
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>

                </mat-form-field>
            </div>



            <div class="span-2">
                <mat-form-field>
                    <textarea matInput placeholder="Enter item description"></textarea>
                </mat-form-field>
            </div>




        </div>



        <div class="btn-wrapper">

            <button class="cmn-next-btn ">
                Dispose Asset
            </button>

        </div>
    </section>
</mat-dialog-content>