import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_EMPLOYEE_VACATION, GET_VACATIONS, GET_VACATION_BY_ID } from '../graphql/queries/vacation.query';
import { APPROVE_VACATION, CREATE_VACATION, REMOVE_VACATION, UPDATE_VACATION } from '../graphql/mutation/vacation.mutation';

@Injectable({
  providedIn: 'root',
})
export class VacationService {
  constructor(private apollo: Apollo) {}

  fetchVacations(filter: {}): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_VACATIONS,
        variables: { filter },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.vacationRequests || [])
      );
  }

  fetchVacationById(id: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_VACATION_BY_ID,
        variables: { 
          id
        },
      })
      .valueChanges.pipe(
        map((result) => result.data?.vacationRequest || [])
      );
  }

  employeeVacationRequests(employeeId: any, filter: {}): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_EMPLOYEE_VACATION,
        variables: { 
          employeeId,
          filter 
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.employeeVacationRequests || [])
      );
  }

  removeVacationRequest(id: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: REMOVE_VACATION,
        variables: { 
          id: id
        },
      })
      .pipe(
        map((result: any) => result.data.removeVacationRequest || [])
      );
  }

  createVacationRequest(createVacationRequestInput: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: CREATE_VACATION,
        variables: { 
          createVacationRequestInput
        },
      })
      .pipe(
        map((result: any) => result.data.createVacationRequest || [])
      );
  }

  updateVacationRequest(updateVacationRequestInput: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: UPDATE_VACATION,
        variables: { 
          updateVacationRequestInput
        },
      })
      .pipe(
        map((result: any) => result.data.updateVacationRequest || [])
      );
  }

  updateVacationRequestStatus(id: any, status: any, employeeComment: string): Observable<any> {
    const vacationId = Number(id);
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: APPROVE_VACATION,
        variables: { 
          id: vacationId,
          status: status,
          comment: employeeComment
        },
      })
      .pipe(
        map((result: any) => result.data.updateVacationRequestStatus || [])
      );
  }
}