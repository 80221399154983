<section class="cmn-innerpage-wrapper invoice-new">
    <h2 class="cmn-inner-heading">  {{ purchaseOrderId ? "Update Local Purchase Order" : "New Local Purchase Order" }}</h2>
    <form [formGroup]="purchaseOrderForm" (keydown)="preventEnterKey($event)">
      <div class="invoice-new-form-wrapper">
        <div class="form-wrapper invoice-form-wrapper">
          <!-- Client Name Section -->
          <div class="cmn-Search-select-container">
            <label for="clientName">Vendor Name <span class="red">*</span></label>
            <mat-form-field>
              <mat-select placeholder="Search vendor" id="clientName" formControlName="vendorId"
                (selectionChange)="onVendorChange($event.value)" >
                <mat-option >
                  <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No options found'"
                  (input)="onVendorSearch($event)"
                    ></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let vendor of vendorsData" [value]="vendor.id">
                  {{ vendor.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="custom-error">
              <span>
                <mat-error *ngIf="
                purchaseOrderForm.get('vendorId')!.touched &&
                purchaseOrderForm.get('vendorId')!.hasError('required')
              ">*Vendor is required.</mat-error>
              </span>
            </div>
            <button *appHasPermission="['12', Permission.Create]" (click)="openCreateVendorDialog()">
            <!-- <button *appHasPermission="['12', Permission.Create]" (click)="openCreateVendorDialog()"> -->
              <div>
                <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add client" />
              </div>
              <span>Add vendor</span>
            </button>
          </div>
  
          <!-- Invoice Number Section -->
          <div>
            <label for="purchaseOrderNo"> Local Purchase Order <span class="red">*</span></label>
            <mat-form-field>
              <input matInput placeholder="Purchase order number" id="purchaseOrderNo" formControlName="purchaseOrderNo"
                readonly />
            </mat-form-field>
            <div class="custom-error">
              <span>
                <mat-error *ngIf="
                  purchaseOrderForm.get('purchaseOrderNo')!.touched &&
                  purchaseOrderForm.get('purchaseOrderNo')!.hasError('required')
                ">*Purchase order number is required.</mat-error>
              </span>
            </div>
          </div>
          <div>
            <label for="trnNumber">TRN Number</label>
            <mat-form-field>
              <input matInput placeholder="TRN number" id="trnNumber" formControlName="trnNumber"
                [value]="selectedVendorTRN" />
            </mat-form-field>
          </div>
  
          <div>
            <label for="reference">Reference</label>
            <mat-form-field>
              <input matInput placeholder="Reference" id="reference" formControlName="reference" />
            </mat-form-field>
            <div class="custom-error">
              <span>
                <mat-error *ngIf="
                purchaseOrderForm.get('reference')!.touched &&
                purchaseOrderForm.get('reference')!.hasError('maxlength')
                ">*Reference exceeds limit (max limit is 15)</mat-error>
              </span>
            </div>
  
          </div>
  
          <div class="cmn-Search-select-container">
            <label for="workorderId">Work order number <span class="red">*</span></label>
            <mat-form-field>
              <mat-select placeholder="Search work order" id="workorderId" formControlName="workorderId">
                <!-- Search functionality -->
                <mat-option>
                  <ngx-mat-select-search [placeholderLabel]="'Search work order'"
                    [noEntriesFoundLabel]="'No options found'" [formControl]="workOrderControl">
                  </ngx-mat-select-search>
                </mat-option>
                <!-- Work Order Options -->
                <mat-option *ngFor="let workorder of filteredWorkOrders" [value]="workorder.id">
                  {{ workorder?.workOrderNo }}-{{ workorder?.projectData?.projectName ?
                  workorder?.projectData?.projectName : "NA" }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="custom-error">
              <span>
                <mat-error *ngIf="purchaseOrderForm.get('workorderId')!.touched && 
                purchaseOrderForm.get('workorderId')!.hasError('required')">*Work order number is required.</mat-error>
              </span>
            </div>
          </div>
          <div>
            <label for="currency">Currency <span class="red">*</span></label>
            <mat-form-field>
              <mat-select placeholder="Select currency" id="currency" formControlName="currency">
                <mat-option *ngFor="let currency of allCurrencies" [value]="currency.id">{{ currency?.currencyCode
                  }}</mat-option>
              </mat-select>
              <!-- <input matInput placeholder="Select currency" id="currency" formControlName="currency"
              readonly/> -->
            </mat-form-field>
            <div class="custom-error">
              <span>
                <mat-error *ngIf="
                purchaseOrderForm.get('currency')!.touched &&
                purchaseOrderForm.get('currency')!.hasError('required')
              ">Currency is required.</mat-error>
              </span>
            </div>
  
          </div>
          <div>
            <label for="date">Bill Date <span class="red">*</span></label>
            <mat-form-field>
              <!-- <input matInput [matDatepicker]="currentDatePicker" id="date"
                formControlName="billDate" placeholder="Select date" (dateChange)="onDateChange($event)"/> -->
              <input matInput [matDatepicker]="currentDatePicker" placeholder="Select date" formControlName="billDate" />
              <!-- <input matInput [value]="currentDate" [matDatepicker]="currentDatePicker" id="date"
                formControlName="billDate" placeholder="Select date" /> -->
              <mat-datepicker-toggle matIconSuffix [for]="currentDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #currentDatePicker></mat-datepicker>
            </mat-form-field>
            <div class="custom-error">
              <span>
                <mat-error *ngIf="
                purchaseOrderForm.get('billDate')!.touched &&
                purchaseOrderForm.get('billDate')!.hasError('required')
              ">*Date is required.</mat-error>
              </span>
            </div>
            
          </div>
  
  
          <div>
            <label for="shipmentPreference">Shipment Preference</label>
            <mat-form-field>
              <input matInput placeholder="Shipment preference" id="shipmentPreference"
                formControlName="shipmentPreference" />
            </mat-form-field>
          </div>
  
          <div>
            <label for="paymentTerms">Payment Terms <span class="red">*</span></label>
            <mat-form-field appearance="fill">
              <textarea matInput placeholder="Enter payment terms" id="paymentTerms" formControlName="paymentTerms"
                rows="3"></textarea>
            </mat-form-field>
            <div class="custom-error">
              <span>
                <mat-error *ngIf="
                purchaseOrderForm.get('paymentTerms')!.touched &&
                purchaseOrderForm.get('paymentTerms')!.hasError('required')
              ">*Payment terms is required.</mat-error>
              </span>
            </div>
            
          </div>
        </div>
        <br />
        <br />
        <div><label for="deliveryAddress"><strong>Delivery Address</strong></label>
  
          <div class="service-address-wrapper">
  
            <div class="type-selection">
              <label class="radiobtn-wrapper">
                <input type="radio" name="address" (click)="toggleOrgainization()" [checked]="!isCustomerSelected" />
                <span class="radiomark"></span>
                Organization
              </label>
              <label class="radiobtn-wrapper">
                <input type="radio" name="address" (click)="toggleCustomer()" [checked]="isCustomerSelected" />
                <span class="radiomark"></span>
                Customer
              </label>
            </div>
  
            <!-- Customer List -->
            <div class="form-wrapper invoice-form-wrapper" *ngIf="isCustomerSelected === true">
              <div class="cmn-Search-select-container">
                <mat-form-field>
                  <mat-select placeholder="Search customer" id="clientName" formControlName="customerId"
                    (selectionChange)="onCustomerChange($event)">
                    <mat-option>
                      <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No options found'"
                        [formControl]="customerControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let customer of cutomersData" [value]="customer.id">
                      {{ customer.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
  
            <div class="billing-shipping-address-wrapper">
              <div>
                <div class="header-wrapper">
                  <h5>Billing Address <span class="red">*</span></h5>
                  <div (click)="openSelectBillingAddressDialog()">
                    <img src="/assets/images/icons/icon-edit-accounts.svg" alt="" />
                  </div>
                </div>
                <div>
                  <h6>{{ selectedBillingAddress?.attention}}</h6>
                  <ul>
                    <li>{{ selectedBillingAddress?.addressLine1 }}</li>
                    <!-- <li>{{ selectedBillingAddress?.addressLine2 }}</li> -->
                    <li>{{ selectedBillingAddress?.city }}</li>
                    <li>{{ selectedBillingAddress?.state }}</li>
                    <li>{{ selectedBillingAddress?.country }}</li>
                    <li>{{ selectedBillingAddress?.phone }}</li>
                    <li>{{ selectedBillingAddress?.fax }}</li>
                  </ul>
                  <input type="hidden" formControlName="billingAddressId" [value]="selectedBillingAddress?.id" />
                  <button (click)="openBillingAddressDialog('add')">
                    <span>Add Address</span>
                  </button>
  
                </div>
              </div>
              <div>
                <div class="header-wrapper">
                  <h5>Shipping Address <span class="red">*</span></h5>
                  <div (click)="openSelectShippingAddressDialog()">
                    <img src="/assets/images/icons/icon-edit-accounts.svg" alt="" />
                  </div>
                </div>
                <div>
                  <h6>{{ selectedShippingAddress?.attention }}</h6>
                  <ul>
                    <li>{{ selectedShippingAddress?.addressLine1 }}</li>
                    <!-- <li>{{ selectedShippingAddress?.addressLine2 }}</li> -->
                    <li>{{ selectedShippingAddress?.city }}</li>
                    <li>{{ selectedShippingAddress?.state }}</li>
                    <li>{{ selectedShippingAddress?.country }}</li>
                    <li>{{ selectedShippingAddress?.phone }}</li>
                    <li>{{ selectedShippingAddress?.fax }}</li>
                  </ul>
                  <input type="hidden" formControlName="shippingAddressId" [value]="selectedShippingAddress?.id" />
                  <button (click)="openShippingAddressDialog('add')">
                    <span>Add Address</span>
                  </button>
                </div>
              </div>
            </div>
  
          </div>
        </div>
     
        <div class="form-wrapper">
          <label for="subject">Subject</label>
          <mat-form-field>
            <textarea matInput placeholder="Enter Subject" id="subject" formControlName="subject"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="item-service-adding-table-wrapper">
   
      </div>
  
      <div class="quotation-table-container">
        <div>
          <table>
            <thead>
              <tr>
                <th>SL No. </th>
                <th>Description</th>
                <th>Unit </th>
                <th>QTY </th>
                <th>Rate </th>
                <!-- <th>Adjusted</th> -->
                <th>Gross Price</th>
                <th>Discount</th>
                <th>Net Price</th>
                 <th>Adjusted</th>
                <th>VAT</th>
                <th>Amount</th>
                <th>Subject</th>
                <th>Action </th>
                <th></th>
              </tr>
            </thead>
            
            <tbody *ngIf="newPurchaseItems && newPurchaseItems.length > 0">
              <tr *ngFor="let item of newPurchaseItems; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item?.itemName }}</td>
                <td>{{ item?.unit }}</td>
                <td>{{ item?.quantity }}</td>
                <td> {{item?.actualRate.toFixed(2) }}</td>
                <!-- <td>{{ item?.rate.toFixed(2) }}</td> -->
                <td>{{ (item?.actualRate * item?.quantity)?.toFixed(2) }}</td>
                <td>{{ item?.discount ? item?.discount : 0.00 }}</td>
                <td>{{ ((item?.actualRate * item?.quantity) - item?.discount)?.toFixed(2) }}</td>
                <td>{{ ((item?.rate * item?.quantity) - item?.discount)?.toFixed(2) }}</td>
                <td>{{ (item?.vatAmount)?.toFixed(2) }}</td>
                <td>{{ (((item?.rate * item?.quantity) - item?.discount) + item?.vatAmount)?.toFixed(2)  }}</td>
                <td>{{ item.itemId ? item?.subjectList : item?.subject }}</td>
                <td>
                  <img src="assets/images/icons/icon-delete.svg" alt="Delete Item" style="text-align: left"
                    (click)="removeNewPurchaseItem(i)" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
  <div class="item-service-adding-table-wrapper">
    <button (click)="openDialog()">
      <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add" />Add Items
    </button>
  </div>
  
      <div class="notes-tatal-amount-container">
        <div>
          <div class="form-container">
            <div class="form-wrapper">
              <label for="customerNotes">Terms And Conditions</label>
              <mat-form-field>
                <textarea matInput placeholder="Enter your notes here..." formControlName="customerNotes"></textarea>
              </mat-form-field>
            </div>
          </div>
  
        <!-- ADDITIONAL DISCOUNT -->
        <div class="form-container">
          <div  class="form-wrapper">
            <label >Additional Discount</label>
            <mat-form-field>
              <mat-label>Additional Discount</mat-label>
              <input matInput type="number" formControlName="additionalDiscount" (blur) = "calculateLogic()" />
            </mat-form-field>
          </div>
        </div>
      </div>
        <div class="amount-table-div">
          <div>
            <h6>
              Total Amount <span>: {{ selectedCurrencyCode }} {{ subTotal.toFixed(2) | number:'1.2-2' }}</span>
            </h6>
            <h6>
              Discount (-) <span>: {{ selectedCurrencyCode }} {{(totalDiscount - additionalDiscount) | number:'1.2-2'}}</span>
            </h6>
            <h6>
              Additional Discount (-) <span>: {{ selectedCurrencyCode }} {{additionalDiscount | number:'1.2-2'}}</span>
            </h6>
            <h6>
              VAT (5%) <span>: {{ selectedCurrencyCode }} {{ totalVat.toFixed(2) | number:'1.2-2'}}</span>
            </h6>
          </div>
          <div>
            <h4>
              Grand Total <span>: {{ selectedCurrencyCode }} {{ grandTotal.toFixed(2) | number:'1.2-2'}}</span>
            </h4>
          </div>
        </div>
  
      </div>
      <div class="submit-btn-wrapper">
        <button class="cmn-cancel-btn" type="button" (click)="onCancel()">
          Cancel
        </button>
        <button class="cmn-draft-btn" [disabled]="loading" type="button" (click)="saveAsDraft()">
          <span *ngIf="!loading">Save As Draft</span>
          <span *ngIf="loading">Loading...</span>
         
        </button>
        <!-- <button class="cmn-next-btn" type="submit">Save and Send</button> -->
        <button class="cmn-next-btn" type="button" (click)="saveAndSend()" [disabled]="loading">
          <span *ngIf="!loading">Save and Send</span>
          <span *ngIf="loading">Loading...</span>
        </button>
      </div>
    </form>
  </section>