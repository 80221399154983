<div class="profile-inner-main-wrapper">
  <div class="details-wrapper">
    <h2>Branch Name</h2>
    <h3>{{employeeData?.branch?.branchName}}</h3>
  </div>
  <div *ngIf="employeeData?.isPortalUser; else notPortalUser">
    <div class="details-wrapper">
      <h2>Is portal user?</h2>
      <h3>Yes</h3>
    </div>
    <div class="details-wrapper">
      <h2>User Role</h2>
      <h3>{{profileName}}</h3>
    </div>
    <div class="details-wrapper">
      <h2>Employee Approvers</h2>
      <div class="details-sub">
        <p>L1 Approver:</p> <span>{{approvers?.levelOneData ? ' ' + approvers?.levelOneData?.firstName + ' ' +
          approvers?.levelOneData?.lastName : '--'}}</span>
      </div>
      <div class="details-sub">
        <p>L2 Approver:</p> <span>{{approvers?.levelTwoData ? ' ' + approvers?.levelTwoData?.firstName + ' ' +
          approvers?.levelTwoData?.lastName : '--'}}</span>
      </div>
      <div class="details-sub">
        <p>L3 Approver:</p> <span>{{approvers?.levelThreeData ? ' ' + approvers?.levelThreeData?.firstName + ' ' +
          approvers?.levelThreeData?.lastName : '--'}}</span>
      </div>
      <div class="details-sub">
        <p>L4 Approver:</p> <span>{{approvers?.levelFourData ? ' ' + approvers?.levelFourData?.firstName + ' ' +
          approvers?.levelFourData?.lastName : '--'}}</span>
      </div>
      <!-- <div class="details-wrapper password">
      <h2>Password</h2>
      <h3>
        <span>{{ isVisible ? value : hiddenValue }}</span>
        <button (click)="toggleClass()" [class.active]="isVisible"></button>
      </h3>
    </div> -->
    </div>
    </div>
    <ng-template #notPortalUser>
      <div class="details-wrapper">
        <h2>Is portal user?</h2>
        <h3>No</h3>
      </div>
    </ng-template>
  </div>