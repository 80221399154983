
<section class="cmn-innerpage-wrapper branch">
    <div class="head">
      <div class="search-wrapper" *appHasPermission="['31', Permission.List]">
        <input
          type="text"
          class="cmn-header-search"
          placeholder="Search"
          (input)="onSearch($event)"
        />
      </div>
      <div class="right-set" *appHasPermission="['31', Permission.Create]">
        <button class="cmn-add-btn" (click)="openDialog()">
          <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add
          Location
        </button>
      </div>
    </div>
    <div class="inner-content" *appHasPermission="['31', Permission.List]">
      <ag-grid-angular
        style="width: 100%"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowSelection]="rowSelection"
        [suppressRowClickSelection]="true"
        [pagination]="true"
        [paginationPageSize]="paginationPageSize"
        [paginationPageSizeSelector]="paginationPageSizeSelector"
        [class]="themeClass"
      />
    </div>
  </section>

