import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { AttendanceService } from '../../../../features/attendance/services/attendance.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { FormatTimePipe } from '../../../../shared/pipes/format-time.pipe';

@Component({
  selector: 'app-attendance',
  standalone: true,
  imports: [
    CommonModule,
    AgGridAngular,
  ],
  templateUrl: './attendance.component.html',
  styleUrl: '../../employee-details.component.scss',
  providers: [ FormatTimePipe ]
})
export class AttendanceComponent {
    public loading: boolean = true;
    rowData: any[] = [];
    filter: any;
    search: any;
    public themeClass: string = 'ag-theme-quartz';

    public columnDefs: ColDef[] = [
      { headerName: 'Date', field: "date", flex:1},
      { headerName: 'Check In', field: "checkin", flex:1},
      { headerName: 'Check Out', field: "checkout", flex:1},
      { headerName: 'Working Hours', field: "hours", flex:1},
    ];
    public defaultColDef: ColDef = {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
    public paginationPageSize = 25;
    public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];

    constructor(
      private attendanceService: AttendanceService,
      private toastr: ToastrService,
      private datePipe: DatePipe, 
      private route: ActivatedRoute, 
      private formatTimePipe: FormatTimePipe
    ){}

    ngOnInit(){
      this.route.params.subscribe((params) => {
        const index = params['id'];  
        if(index){
          this.filter = {employeeId : Number(index)}
          this.fetchAttendance();
        }
      });
    }

    fetchAttendance() {
      this.loading = true;
      this.attendanceService.fetchAttendance(this.search, this.filter).subscribe({
        next: (attendance) => {
          this.rowData = attendance
          .map((attendance: any) => {
            return {
              date: this.datePipe.transform((attendance?.date), 'MMMM d, yyyy') || '--',
              checkin: this.formatTimePipe.transform(attendance?.checkInTime) || '--',
              checkout: this.formatTimePipe.transform(attendance?.checkOutTime) || '--',
              hours: attendance?.totalHoursWorked != null 
              ? (attendance?.totalHoursWorked < 1 
                  ? `${Math.round(attendance?.totalHoursWorked * 60)} mins` 
                  : `${attendance?.totalHoursWorked} Hrs`) 
              : '--',
            };
          });
        this.loading = false;
        },
        error: (error) => {
          this.toastr.error('Failed to load attendance. Please try again.','Error');
          this.loading = false;
        },
      });
    }
}
