<section class="security-main-wrapper">
  <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
    <div class="security-inputs">
      <div class="form-wrapper">
        <label for="">Current Password</label>
        <mat-form-field>
          <input
            id="currentPassword"
            placeholder="Enter Current Password"
            matInput
            [type]="hideCurrentPassword() ? 'password' : 'text'"
            formControlName="currentPassword"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="toggleCurrentPasswordVisibility($event)"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hideCurrentPassword()"
            type="button"
          >
            <mat-icon>{{
              hideCurrentPassword() ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">New Password</label>
        <mat-form-field>
          <input
            id="newPassword"
            placeholder="Enter New Password"
            matInput
            [type]="hideNewPassword() ? 'password' : 'text'"
            formControlName="newPassword"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="toggleNewPasswordVisibility($event)"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hideNewPassword()"
            type="button"
          >
            <mat-icon>{{
              hideNewPassword() ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
          <!-- Error handling for required and custom validators -->
          <mat-error
            *ngIf="changePasswordForm.get('newPassword')?.hasError('required')"
          >
            Password is required
          </mat-error>
          <mat-error
            *ngIf="changePasswordForm.get('newPassword')?.hasError('minlength')"
          >
            Password must be at least 6 characters long
          </mat-error>
          <mat-error
            *ngIf="
              changePasswordForm.get('newPassword')?.hasError('passwordStrength')
            "
          >
            Must include uppercase letters, lowercase letters, digits.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Confirm Password</label>
        <mat-form-field>
          <input
            id="confirmPassword"
            placeholder="Confirm New Password"
            matInput
            [type]="hideConfirmPassword() ? 'password' : 'text'"
            formControlName="confirmPassword"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="toggleConfirmPasswordVisibility($event)"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hideConfirmPassword()"
            type="button"
          >
            <mat-icon>{{
              hideConfirmPassword() ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" type="button" (click)="onCancel()">
        Cancel
      </button>
      <button class="cmn-next-btn" type="submit">Save</button>
    </div>
  </form>
</section>
