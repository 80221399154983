import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { BillPaymentComponent } from './components/bill-payment/bill-payment.component';
import { VendorAdvanceComponent } from './components/vendor-advance/vendor-advance.component';

@Component({
  selector: 'app-bill-payment-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    BillPaymentComponent,
    VendorAdvanceComponent
  ],
  templateUrl: './bill-payment-new.component.html',
  styleUrl: './bill-payment-new.component.scss'
})
export class BillPaymentNewComponent {

}
