<div class="tab-inner-content">
  <h2>General Ledger Report</h2>
  <h5>
    From {{ startDate | date : "MM/d/YYYY" }} to
    {{ endDate | date : "MM/d/YYYY" }}
  </h5>
  <table>
    <tr>
      <th>Account</th>
      <th>Partner</th>
      <th>Debit</th>
      <th>Credit</th>
    </tr>
    <ng-container *ngFor="let group of generalLedgerReport">
      <tr class="title">
        <td>{{ group.journalDate }} {{ group.journalId }}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <ng-container *ngFor="let item of group.entries">
        <tr class="child">
          <td>{{ item.accountName }}</td>
          <td>{{ item.partnerDisplayName }}</td>
          <td>{{ item.debit | number : "1.2-2" }}</td>
          <td>{{ item.credit | number : "1.2-2" }}</td>
        </tr>
      </ng-container>
      <!-- <tr class="sub-total">
          <td>Total</td>
          <td>{{ group.totalDebit | number:'1.2-2' }}</td>
          <td>{{ group.totalCredit | number:'1.2-2' }}</td>
        </tr> -->
    </ng-container>
  </table>
</div>
