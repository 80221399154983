
    <section class="cmn-innerpage-wrapper bill-payment-new">
            <div class="common-noimage-tab-wrapper">
                <mat-tab-group animationDuration="0ms">
                    <!-- Mat Tab One -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Bill Payment</h2>
                        </ng-template>
                        <div class="assets-tab-inner-wrapper">
                           <app-bill-payment></app-bill-payment>
                        </div>
                    </mat-tab>
                    <!-- Mat Tab Two -->
                    <!-- <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Vendor Advance</h2>
                        </ng-template>
                        <div class="assets-tab-inner-wrapper">
                            <app-vendor-advance></app-vendor-advance>
                        </div>
                    </mat-tab> -->
                </mat-tab-group>
            </div>
    </section>
