import gql from 'graphql-tag';

export const CREATE_DEPARTMENT = gql`
  mutation createDepartment($input: CreateDepartmentInput!) {
    createDepartment(createDepartmentInput: $input) {
      id
    departmentName
    status
    createdAt
    updatedAt
    deletedAt
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation updateDepartment($updateDepartmentInput: UpdateDepartmentInput!) {
    updateDepartment(updateDepartmentInput: $updateDepartmentInput)  {
      id
    departmentName
    status
    createdAt
    updatedAt
    deletedAt
  }
  }
`;

export const REMOVE_DEPARTMENT_BY_ID = gql`
mutation removeDepartment($id: Int!) {
  removeDepartment(id: $id)
}
`;
