import { Component, Inject, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridAngular } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { BankingService } from '../../features/banking/services/banking.service';
import { DialogRef } from '@angular/cdk/dialog';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ChartAccountsService } from '../../features/chart-of-accounts/services/chart-accounts.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CustomerService } from '../../features/customers/services/customer.service';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { UpdateBankingDetailsComponent } from './update-transaction.component';
import { CreateTransactionDialog } from './create-transaction-dialog.component';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';

@Component({
  selector: 'app-banking-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    AgGridAngular,
    MatTabsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    UpdateBankingDetailsComponent,
    PermissionDirective
  ],
  templateUrl: './banking-details.component.html',
  styleUrl: './banking-details.component.scss'
})

export class BankingDetailsComponent {

  selectedTabIndex = 0;
  selectedTransaction = 'default';
  selectedOption = 'today';
  isDropdownOpen = false;
  showUncategorize = false;
  showAllTransaction = false;
  showAddTransaction = false;
  readonly dialog = inject(MatDialog);
  bankId: number;
  transactionData: any;
  transactions: any;
  withdrawal: any;
  deposit: any;
  transactionAmount: any;
  parentAccountId: any;
  isSubAccount: any;
  chartOfAccounts: any;
  transactionForm: FormGroup;
  categorizeForm: FormGroup;
  commentForm: FormGroup;
  accountType: any;
  search: any = '';
  selectedDate: any;
  file: any;
  statementAmount: any;
  statements: any;
  categoryId: number;
  categories: any[] = [];
  moneyInCategoriesExist: boolean = false;
  creditCards: any;
  matchedData: any;
  reconcilationIds: any[] = [];
  statementId: any;
  transactionIds: any[] = [];
  isSelectedAll: boolean = false;
  edit: boolean = false;
  isCreate: boolean = false;
  totalAmount: number = 0;
  pendingAmount: number = 0;
  statement: any;
  flow: string;
  categorySelection: any;
  manualAmount: any;
  comments: any;
  transactionDetail: any;
  Permission = Permission;
  // profileName: any;

  constructor(
    private route: ActivatedRoute,
    private bankingService: BankingService,
    private chartAccountService: ChartAccountsService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private router: Router,
    private customerService: CustomerService,
  ) { 
    // const loggedInUser: any = localStorage.getItem('loggedInUser');
    // const userData = JSON.parse(loggedInUser);
    // this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.bankId = Number(id);
      this.transactionOfChartOfAccount(this.bankId);
      this.fetchChartAccounts();
      this.fetchTrasactionCategories();
      this.checkMoneyInCategories();
      this.initForm();
    }
  }


  private checkMoneyInCategories(): void {
    this.moneyInCategoriesExist = this.categories?.some(category => category?.categoryStatus === 'In');
    console.log('moneyInCategoriesExist', this.moneyInCategoriesExist);

  }

  

  initForm() {
    this.transactionForm = this.fb.group({
      offsetAccountId: this.bankId,
      fromAccount: [this.bankId], 
      chartOfAccount: [''],
      receivedVia: [''], 
      tds: [''], 
      payment: [''], 
      paid: [''], 
      deposit: [''], 
      customer: [''], 
      date: ['', Validators.required],
      amount: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]], 
      invoice: [''],
      description: [''],
      file: [''], 
      flow: 'Debit',
      status: 'Manual'
    });
    

    this.categorizeForm = this.fb.group({
      category: ['', Validators.required],
      date: ['', Validators.required],
      reference: [''],
      description: [''],
    })

    this.commentForm = this.fb.group({
      comments: ['']
    });
  }

  updateValidatorsBasedOnTransaction() {
    const selectedTransaction = this.selectedTransaction;
  
    // Reset validators
    this.transactionForm.get('fromAccount')?.clearValidators();
    this.transactionForm.get('tds')?.clearValidators();
    this.transactionForm.get('payment')?.clearValidators();
    this.transactionForm.get('paid')?.clearValidators();
    this.transactionForm.get('deposit')?.clearValidators();
    this.transactionForm.get('chartOfAccount')?.clearValidators();
  
    // Add validators conditionally for 'fromAccount'
    if (['card payment', 'owner drawings', 
         'transfer from another account', 'other income', 'owner contribution']
        .includes(selectedTransaction)) {
      this.transactionForm.get('fromAccount')?.setValidators(Validators.required);
    } 
  
    // Add validators conditionally for 'chartOfAccount' (To Account)
    if (['transfer to another account', 'card payment']
        .includes(selectedTransaction)) {
      this.transactionForm.get('chartOfAccount')?.setValidators(Validators.required);
    }
  
    // Add validators conditionally for other fields
    if (selectedTransaction === 'vendorAdvance') {
      this.transactionForm.get('tds')?.setValidators(Validators.required);
      this.transactionForm.get('payment')?.setValidators(Validators.required);
      this.transactionForm.get('paid')?.setValidators(Validators.required);
      this.transactionForm.get('deposit')?.setValidators(Validators.required);
    }
  
    // Update the validators for all fields
    this.transactionForm.get('fromAccount')?.updateValueAndValidity();
    this.transactionForm.get('tds')?.updateValueAndValidity();
    this.transactionForm.get('payment')?.updateValueAndValidity();
    this.transactionForm.get('paid')?.updateValueAndValidity();
    this.transactionForm.get('deposit')?.updateValueAndValidity();
    this.transactionForm.get('chartOfAccount')?.updateValueAndValidity();
  }
  
  selectTab(index: number) {
    this.selectedTabIndex = index;
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadBankingDetailsDialog, {
      disableClose: true,
      data: this.bankId
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.transactionOfChartOfAccount(this.bankId);
    });
  }

  onCreate() {
    let applyTo = '';

    if (this.pendingAmount > 0) {
      applyTo = 'Deposits';

    } else if (this.pendingAmount < 0) {
      applyTo = 'Withdrawals';
    }

    const dialogRef = this.dialog.open(CreateTransactionDialog, {
      disableClose: true,
      data: {
        applyTo: applyTo,
        bankId: this.bankId,
        pendingAmount: Number(this.pendingAmount),
        statementDate: this.statement?.date
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.listMatchedPaymentsForTransaction(this.statement?.id);
    });
  }


  transactionOfChartOfAccount(id: any) {
    this.bankingService.transactionOfChartOfAccount(id, this.search).subscribe({
      next: (data) => {
        this.transactionData = data;
        this.statements = data?.statements;
        this.statementAmount = this.statements.map((statement: any) => ({
          ...statement,
          deposit: statement.flow === 'Credit' ? statement.amount : null,
          withdrawal: statement.flow === 'Debit' ? statement.amount : null,
        }));

        this.transactions = data?.transactions;
        this.transactionAmount = this.transactions.map((transaction: any) => ({
          ...transaction,
          deposit: transaction.flow === 'Credit' ? transaction.amount : null,
          withdrawal: transaction.flow === 'Debit' ? transaction.amount : null,
        }));
        console.log(this.transactionAmount);
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  fetchChartAccounts() {
    this.chartAccountService.chartOfAccounts(this.accountType, this.search, this.parentAccountId, this.isSubAccount).subscribe({
      next: (data) => {
        this.chartOfAccounts = data;
        this.creditCards = data.filter((account: any) => account.accountType === 'Credit');
      },
      error: (error) => {
        console.error('Error fetching chart accounts:', error);
      }
    });
  }


  fetchTrasactionCategories() {
    this.bankingService.transactionCategories().subscribe({
      next: (data) => {
        if (data) {
          this.categories = data;
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleCategorize(event?: MouseEvent, statement?: any) {
    if (event) {
      event.stopPropagation();
    }
    if (statement?.id) {
      this.statementId = statement?.id;
      this.statement = statement;
      this.manualAmount = statement?.amount
      this.totalAmount = 0;
      this.transactionIds = [];
      this.pendingAmount = 0;
      this.isCreate= false;
      if (this.statement?.flow === 'Credit') {
        this.pendingAmount += this.statement?.amount || 0;
      } else if (this.statement?.flow === 'Debit') {
        this.pendingAmount -= this.statement?.amount || 0;
      }
      this.listMatchedPaymentsForTransaction(statement?.id);
    }
    this.showUncategorize = !this.showUncategorize;
  }

  listMatchedPaymentsForTransaction(id: any) {
    this.categorizeForm.reset();
    this.bankingService.listMatchedPaymentsForTransaction(Number(id)).subscribe({
      next: (data) => {
        this.matchedData = data;
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  toggleAllTransaction(event?: MouseEvent, transaction?: any) {
    console.log('inside');

    if (event) {
      event.stopPropagation();
    }
    if (transaction?.id) {
      this.getTransactionDetails(transaction?.id);
    }
    if (this.transactionDetail?.id) {
      this.getTransactionDetails(this.transactionDetail?.id)
    }
    this.transactionOfChartOfAccount(this.bankId);
    this.fetchComments();
    this.edit = false;
    this.showAllTransaction = !this.showAllTransaction;
  }

  onEdit() {
    // this.getTransactionDetails(this.transactionDetail?.id)
    this.edit = true;
  }

  onComment() {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const commentData = {
      employeeId: employeeId,
      refId: Number(this.bankId),
      type: 'Transaction',
      activityType: 'Comment',
      note: this.commentForm.value.comments
    }
    this.customerService.createComment(commentData).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Comment added successfully!', 'Success');
          this.commentForm.reset();
          this.fetchComments();
        }
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  fetchComments() {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const conditions = {
      employeeId: employeeId,
      refId: Number(this.bankId),
      type: 'Transaction',
      activityType: 'Comment'
    }
    this.customerService.comments(conditions).subscribe({
      next: (data) => {
        if (data) {
          this.comments = data;
        }
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  getTransactionDetails(id: any) {
    this.bankingService.getTransactionDetails(Number(id)).subscribe({
      next: (data) => {
        this.transactionDetail = data;
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  toggleAddTransaction(event: MatSelectChange) {
    const { name, id } = event.value;
    this.selectedTransaction = name;
    this.categoryId = id;
  
    const routesMap: any = {
      expense: ['/expense/expense-new'],
      'vendor payment': ['/payments-made/bill-payment-new'],
      'customer payment': ['/payment-received/payment-new'],
    };
  
    if (routesMap[name]) {
      this.router.navigate(routesMap[name], { queryParams: { expense: this.bankId, category: id } });
    } else if (name === 'card payment') {
      this.fetchChartAccounts();
    } else if (['transfer from another account', 'other income', 'owner contribution'].includes(name)) {
      this.transactionForm.patchValue({ fromAccount: '', chartOfAccount: this.bankId });
    }
  
    this.showAddTransaction = !this.showAddTransaction;
    this.transactionForm.reset();
  }
  

  selectionChangeCategory(event: any) {
    const selectedTransaction = event.value;
    const flow = selectedTransaction.flow;
    this.categorySelection = selectedTransaction.id;
    console.log('Selected category status:', flow);
    if (flow === 'Out') {
      this.flow = 'Debit'
    } else if (flow === 'In') {
      this.flow = 'Credit'
    }
  }

  // categorize manually
  onCategorizeManually() {
    if (this.categorizeForm.invalid) {
      this.categorizeForm.markAllAsTouched();
      return;
    }
    const statusType = 'categorize';
    const transactionIds = null;
    const reconcilationIds = null;
    const createTransactionInput = {
      transactionStatus: 'Categorized',
      flow: this.flow,
      offsetAccountId: this.bankId,
      amount: this.statement?.amount,
      transactionCategoryId: this.categorizeForm.value.category?.id,
      date: this.datePipe.transform(this.categorizeForm.value.date, 'yyyy-MM-dd'),
      referenceNumber: this.categorizeForm.value.reference,
      description: this.categorizeForm.value.description,
    }

    this.bankingService.uncategorizeTransactions(
      statusType,
      this.statement?.id,
      createTransactionInput,
      transactionIds,
      reconcilationIds
    ).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Transaction categorized successfully', 'Success');
          this.showUncategorize = !this.showUncategorize;
          this.transactionOfChartOfAccount(this.bankId);
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }

  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.file = file;
    }
  }

  closeDropdown() {
    this.showAddTransaction = !this.showAddTransaction;
    this.selectedTransaction = 'default';
  }

  // on best match
  onBestMatch(id: any) {
    const statusType = 'match';
    this.transactionIds.push(id);
    const amount = Number();
    const createTransactionInput = {
      transactionStatus: 'Matched',
      flow: '',
      offsetAccountId: this.bankId,
      amount: amount,
    }
    this.bankingService.matchOrUnmatchStatementToTransactions(
      statusType,
      this.reconcilationIds,
      this.statementId,
      this.transactionIds,
      createTransactionInput
    ).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Transaction matched successfully');
          this.showUncategorize = !this.showUncategorize;
          this.transactionOfChartOfAccount(this.bankId);
        }
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  toggleTransaction(data: any, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.totalAmount=0;
      
      if (data.flow === 'Credit') {
        this.totalAmount += data.amount || 0;
      } else if (data.flow === 'Debit') {
        this.totalAmount -= data.amount || 0;
      }
      this.transactionIds.push(data.id);
      console.log('pendingAmount', this.pendingAmount);
      console.log('totalAmount', this.totalAmount);
      console.log('statement?.amount', this.statement?.amount);
      
      this.pendingAmount = (this.pendingAmount - this.totalAmount)
      // this.isCreate = this.totalAmount !== this.statement?.amount && this.totalAmount !== 0;
      this.isCreate = true;

    } else {
      this.transactionIds = this.transactionIds.filter((id) => id !== data.id);
      this.totalAmount=0;
      if (data.flow === 'Credit') {
        this.totalAmount += data.amount || 0;
      } else if (data.flow === 'Debit') {
        this.totalAmount -= data.amount || 0;
      }
      this.pendingAmount = (this.pendingAmount + this.totalAmount)
      // this.isCreate = this.totalAmount !== this.statement?.amount && this.totalAmount !== 0;
    }
  }

  selectAll() {
    if (this.isSelectedAll) {
      // Deselect all
      this.transactionIds = [];
      this.totalAmount = 0; // Reset the total amount
      this.isCreate = false;
      this.pendingAmount = this.totalAmount - this.statement?.amount;
    } else {
      // Select all
      this.transactionIds = this.matchedData?.possibleMatches?.map((data: any) => data.id) || [];
      this.totalAmount = this.matchedData?.possibleMatches?.reduce((sum: number, data: any) => sum + (data.amount || 0), 0) || 0;
      this.isCreate = true;
      this.pendingAmount = this.totalAmount - this.statement?.amount
    }
    this.isSelectedAll = !this.isSelectedAll;
  }

  // on possible match
  onMatch() {
    console.log('totalAmount', this.totalAmount);
    console.log('statementamount', this.statement?.amount);

    if (this.pendingAmount === 0 && this.transactionIds?.length > 0) {
      const transactionIds = [...this.transactionIds];
      const statusType = 'match';
      const amount = Number();
      const createTransactionInput = {
        transactionStatus: 'Matched',
        flow: '',
        offsetAccountId: this.bankId,
        amount: amount,
      }
      this.bankingService.matchOrUnmatchStatementToTransactions(
        statusType,
        [],
        this.statementId,
        transactionIds,
        createTransactionInput
      ).subscribe({
        next: (data) => {
          if (data) {
            this.toastr.success('Transaction matched successfully', 'Success')
            this.showUncategorize = !this.showUncategorize;
            this.transactionOfChartOfAccount(this.bankId);
          }
        },
        error: (error) => {
          this.toastr.error(error?.message, 'Error')
        }
      })
    } else if (this.totalAmount === 0) {
      this.toastr.warning('Please select any transaction to match', 'Warning')
    } else {
      this.toastr.warning('The total sum of the matched transactions is not equal to the amount in the statement. Create an adjustment to match the pending amount', 'Balance mismatch!')
    }
  }

  onUnmatch() {
    console.log('this.statementId', this.transactionDetail?.id);

    const transactionIds: any[] = [];
    const statusType = 'unmatch';
    this.transactionDetail?.transactionReconcilations.map((reconcilitionId: any) => {
      this.reconcilationIds.push(reconcilitionId?.id)
    })
    const createTransactionInput = {}
    this.bankingService.matchOrUnmatchStatementToTransactions(
      statusType,
      this.reconcilationIds,
      this.transactionDetail?.transactionReconcilations[0]?.statementId,
      transactionIds,
      createTransactionInput
    ).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Transaction unmatched successfully', 'Success')
          this.showAllTransaction = !this.showAllTransaction;
          this.reconcilationIds = []
          this.transactionOfChartOfAccount(this.bankId);
        }
      },
      error: (error) => {
        this.toastr.error(error?.message, 'Error')
      }
    })
  }

  onUncategorized() {
    const statusType = 'uncategorize';
    const transactionId: any = this.transactionDetail?.id;
    const reconcilationId: number = this.transactionDetail?.transactionReconcilations[0]?.id;
    const statementId: number = this.transactionDetail?.transactionReconcilations[0]?.statementId;
    const createTransactionInput = {
      transactionStatus: 'Uncategorized',
      flow: this.transactionDetail?.flow,
      offsetAccountId: this.bankId,
      amount: this.transactionDetail?.amount,
      transactionCategoryId: this.categorizeForm.value.category?.id,
      date: this.datePipe.transform(this.categorizeForm.value.date, 'yyyy-MM-dd'),
      referenceNumber: this.categorizeForm.value.reference,
      description: this.categorizeForm.value.description,
    }

    this.bankingService.uncategorizeTransactions(
      statusType,
      statementId,
      createTransactionInput,
      transactionId,
      reconcilationId
    ).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Transaction uncategorized successfully', 'Success');
          this.showAllTransaction = !this.showAllTransaction;
          this.transactionOfChartOfAccount(this.bankId);
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }

  onDateChange(event: any) {
    this.selectedDate = this.datePipe.transform(event.value, "yyyy-MM-dd");
  }

  onCancel() {
    this.transactionForm.reset();
  }

  onConfirmDelete(): void {
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteTransaction(this.transactionDetail?.id);
      }
    });
  }

  deleteTransaction(id: any) {
    this.bankingService.removeTransaction(Number(id)).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Transaction deleted successfully', 'Error');
          this.showAllTransaction = !this.showAllTransaction;
          this.transactionOfChartOfAccount(this.bankId);
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      }
    })
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  // Add transaction
  onSave() {
    console.log('this.transactionForm', this.transactionForm.controls);
    this.updateValidatorsBasedOnTransaction();
    if (this.transactionForm.invalid) {
      this.transactionForm.markAllAsTouched();
      return;
    }

    const fromAccount = this.transactionForm.value.fromAccount;
    const chartOfAccount = this.transactionForm.value.chartOfAccount;
    console.log(this.selectedTransaction);

    if (this.selectedTransaction === 'card payment' || this.selectedTransaction === 'transfer to another account') {
      // First call   with `fromAccount` and flow as 'Debit'
      this.createTransaction(this.bankId, 'Debit', () => {
        // Second call with `chartOfAccount` and flow as 'Credit'
        this.createTransaction(chartOfAccount, 'Credit', () => {
          this.transactionOfChartOfAccount(this.bankId);
          this.toastr.success('Transaction added successfully', 'Success');
        });
      });
    } else if (this.selectedTransaction === 'owner drawings') {
      this.createTransaction(fromAccount, 'Debit', () => {
        this.transactionOfChartOfAccount(this.bankId);
        this.toastr.success('Transaction added successfully', 'Success');
      });
    } else if (this.selectedTransaction === 'Interest income') {
      this.createTransaction(this.bankId, 'Credit', () => {
        this.transactionOfChartOfAccount(this.bankId);
        this.toastr.success('Transaction added successfully', 'Success');
      });
    }
    else if (this.selectedTransaction === 'transfer from another account' || this.selectedTransaction === 'other income' || this.selectedTransaction === 'owner contribution') {
      // First call with `fromAccount` and flow as 'Debit'
      this.createTransaction(fromAccount, 'Debit', () => {
        // Second call with `chartOfAccount` and flow as 'Credit'
        this.createTransaction(this.bankId, 'Credit', () => {
          this.transactionOfChartOfAccount(this.bankId);
          this.toastr.success('Transaction added successfully', 'Success');
        });
      });
    }

    this.showAddTransaction = !this.showAddTransaction;
    this.transactionOfChartOfAccount(this.bankId);
  }

  createTransaction(offsetAccountId: number, flow: 'Debit' | 'Credit', callback: () => void) {
    const transactionData = {
      date: this.datePipe.transform(this.transactionForm.value.date, 'yyyy-MM-dd'),
      transactionStatus: 'Manual',
      referenceNumber: this.transactionForm.value.invoice,
      amount: Number(this.transactionForm.value.amount),
      description: this.transactionForm.value.description,
      flow: flow, // Dynamic flow based on account type
      offsetAccountId: offsetAccountId, // Dynamic offsetAccountId
      transactionCategoryId: Number(this.categoryId),
    };

    this.bankingService.createTransaction(transactionData).subscribe({
      next: (data) => {
        if (data) {
          callback();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      },
    });
  }


  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.search = input.value;
    this.transactionOfChartOfAccount(this.bankId);
  }

}


@Component({
  selector: 'upload-banking-details-dialog',
  templateUrl: 'upload-banking-details-dialog.html',
  styleUrls: ['./banking-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadBankingDetailsDialog {
  file: any = null;
  accounts: any;
  search: any;

  constructor(
    private bankingService: BankingService,
    private toastr: ToastrService,
    private dialogRef: DialogRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    document.addEventListener('dragover', this.preventDefaultBehavior);
    document.addEventListener('drop', this.preventDefaultBehavior);
  }

  fetchAccountsOfBankAndCash() {
    this.bankingService.fetchAccountsOfBankAndCash(this.search).subscribe({
      next: (accounts: any) => {
        this.accounts = accounts;
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  preventDefaultBehavior(event: DragEvent) {
    event.preventDefault();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer?.files.length) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }

  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  onUpload() {
    const accountId = this.data;
    this.bankingService.bulkCreateTransactions(this.file, accountId).subscribe({
      next: (response) => {
        if (response && !response.errors) {
          this.toastr.success('Transactions uploaded successfully!', 'Success');
          console.log(accountId);
          this.dialogRef.close(accountId);
        }
        else if (response?.errors) {
          this.toastr.error(response?.errors[0]?.message, 'Error');
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Bulk transaction creation failed');
        this.dialogRef.close();
      },
    });
  }
}