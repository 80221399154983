<div class="tab-inner-content">
  <h2>Vendor Balance Summary</h2>
  <h5>
    From {{ startDate | date : "MM/d/YYYY" }} to
    {{ endDate | date : "MM/d/YYYY" }}
  </h5>
  <table>
    <thead>
      <tr>
        <th>Vendor Name</th>
        <th>Billed Amount</th>
        <th>Amount Paid</th>
        <th>Pending Balance</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of vendorBalanceSummaryReport.summary">
        <tr class="child">
          <td>{{ item.vendorName }}</td>
          <td>{{ item.billedAmount | number : "1.2-2" }}</td>
          <td>{{ item.amountPaid | number : "1.2-2" }}</td>
          <td>{{ item.closingBalance | number : "1.2-2" }}</td>
        </tr>
      </ng-container>
      <tr class="sub-total">
        <td><strong>Total</strong></td>
        <td>
          {{
            vendorBalanceSummaryReport.totals.totalBilledAmount
              | number : "1.2-2"
          }}
        </td>
        <td>
          {{
            vendorBalanceSummaryReport.totals.totalAmountPaid | number : "1.2-2"
          }}
        </td>
        <td>
          {{
            vendorBalanceSummaryReport.totals.totalClosingBalance
              | number : "1.2-2"
          }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
