import { gql } from 'apollo-angular';

export const GET_INVOICE_DETAILS = gql`
  query getInvoiceDetails($id: Int!) {
    getInvoiceDetails(id: $id) {
      id
      projectId
      partnerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        contacts {
          id
          salutation
          firstName
          lastName
          email
          phone
          mobile
          primaryContact
          status
          partnerId
        }
        primaryContact {
          id
          salutation
          firstName
          lastName
          email
          phone
          mobile
          primaryContact
          status
          partnerId
        }
      }
      billingAddressId
      shippingAddressId
      invoiceNo
      orderNo
      invoiceDate
      paymentTerm
      paymentStatus
      reason
      dueDate
      subject
      customerNote
      isPayment
      subTotal
      tax
      adjustment
      totalPrice
      balance
      status
      createdAt
      updatedAt
      deletedAt
      invoiceUrl
      invoiceTemplate {
        id
        templateName
        templateSubject
        templateBody
        templateType
      }
    }
  }
`;

export const GET_QUOTATION_DETAILS = gql`
  query getQuoteDetails($id: Int!) {
    getQuoteDetails(id: $id) {
      id
      parentQuoteId
      parentQuoteData {
        id
        parentCode
        quoteCode
        quoteDate
        partnerId
      }
      quoteCode
      partnerId
      partnerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        contacts {
          id
          salutation
          firstName
          lastName
          email
          phone
          mobile
          primaryContact
          status
          partnerId
        }
        primaryContact {
          id
          salutation
          firstName
          lastName
          email
          phone
          mobile
          primaryContact
          status
          partnerId
        }
      }
      TRN
      SOW
      quoteDate
      expiryDate
      approvalDate
      createdBy
      branchId
      billingAddressId
      shippingAddressId
      refNumber
      projectId
      quoteTitle
      subTotal
      tax
      adjustment
      totalAmount
      paymentTerm
      deliveryTerm
      validity
      termsCondition
      customerNote
      additionalNote
      quoteUrl
      status
      createdAt
      updatedAt
      deletedAt
      quoteTemplate {
        id
        templateName
        templateSubject
        templateBody
        templateType
        emailLogs {
          id
          from
          sentDate
          sentTo
          cc
          status
          subject
        }
      }
    }
  }
`;

export const GET_PURCHASE_ORDER_DETAILS = gql`
  query getPurchaseOrderDetails($id: Int!) {
    getPurchaseOrderDetails(id: $id) {
      id
      vendorId
      vendor {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
        primaryContactId
        createdAt
        updatedAt
        deletedAt
      }
      projectId
      customerId
      customer {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
        primaryContactId
        createdAt
        updatedAt
        deletedAt
      }
      billingAddressId
      shippingAddressId
      purchaseOrderNo
      reference
      dueDate
      billDate
      paymentTerms
      customerNote
      shippmentInstructions
      status
      billStatus
      purchaseStatus
      isLocal
      tax
      purchaseUrl
      totalPrice
      subTotal
      adjustment
      currencyId
      workorderId
      purchaseOrderTemplate {
        id
        templateName
        templateSubject
        templateBody
        templateType
      }
    }
  }
`;
