import { ColDef } from 'ag-grid-community';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import { LayoutComponent } from '../../core/layout/layout.component';
import { CurrencyAdjustmentService } from '../../features/currency-adjustments/services/currency-adjustment.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-currency-exchange-log',
  templateUrl: 'currency-exchange-log.component.html',
  styleUrls: ['./currency-exchange-log.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    AgGridAngular,
    LayoutComponent,
  ],
})
export class CurrencyExchangeLogsComponent {
  public rowData: any[] | null = null;
  public loading: boolean = true;
  public error: any = null;

  public columnDefs = [
    {
      headerName: 'Currency Code',
      field: 'currencyCode',
      flex: 1,
    },
    { headerName: 'Old Exchange Rate', field: 'oldExhangeRate', flex: 1 },
    { headerName: 'New Exchange rate', field: 'newExhangeRate', flex: 1 },
    { headerName: 'Created By', field: 'createdBy', flex: 1 },
    { headerName: 'Exchanged Date', field: 'changedAt', flex: 1 },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  currencyId!: number;
  currencyCode: string | null = null;
  constructor(
    private currencyService: CurrencyAdjustmentService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    const logId = this.route.snapshot.paramMap.get('id') || '';
    this.currencyId = +logId;
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.getCurrencyCodeById(this.currencyId);
    }
  }
  getCurrencyCodeById(currencyId: number): void {
    this.currencyService.fetchCurrencyById(currencyId).subscribe({
      next: (currency) => {
        this.currencyCode = currency.currencyCode;
        console.log('Currency Code:', this.currencyCode);
        this.fetchExchangeLogData();
      },
      error: (err) => {
        console.error('Error fetching currency code:', err);
      },
    });
  }

  fetchExchangeLogData() {
    this.loading = true;
    this.currencyService.fetchExchangeLog(this.currencyId).subscribe({
      next: (exchangeLogs) => {
        this.rowData = exchangeLogs.map((log: any) => {
          const formattedChangedAt = new Date(
            Number(log?.changedAt)
          ).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
          const oldExhangeRate = log?.oldExhangeRate ? (Number(log.oldExhangeRate).toFixed(2)) : "0.00";
          const newExhangeRate = log?.newExhangeRate ? (Number(log.newExhangeRate).toFixed(2)): "0.00";

          return {
            currencyCode: this.currencyCode,
            oldExhangeRate,
            newExhangeRate,
            createdBy: log?.createdBy,
            changedAt: formattedChangedAt,
          };
        });
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching exchange logs:', err);
        this.loading = false;
      },
    });
  }
}
