<div class="tab-inner-content">
  <h2>Trial Balance</h2>
  <h5>
    From {{ startDate | date : "MM/d/YYYY" }} to
    {{ endDate | date : "MM/d/YYYY" }}
  </h5>
  <table>
    <tr>
      <th>Account</th>
      <th>Net Debit</th>
      <th>Net Credit</th>
    </tr>
    <ng-container *ngFor="let category of trialBalanceReport?.trialBalanceData">
    <tr class="title">
      <td colspan="2">{{ category.category }}</td>
      <td></td>
    </tr>
      <!-- Accounts Under Category -->
      <tr class="sub-total" *ngFor="let account of category.accounts">
        <td>{{ account.accountName }}</td>
        <td>{{ account.netDebit | number:'1.2-2' }}</td>
        <td>{{ account.netCredit | number:'1.2-2' }}</td>
      </tr>
    </ng-container>
    <tr class="title">
      <td>Total for Trial Balance</td>
      <td>{{trialBalanceReport?.totalDebits | number:'1.2-2'}}</td>
      <td>{{trialBalanceReport?.totalCredits | number:'1.2-2'}}</td>
    </tr>
  </table>
</div>
