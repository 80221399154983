
    <section class="cmn-innerpage-wrapper client-details">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)"/>
            </div>
            <!-- <button class="cmn-upload-btn" (click)="openDialog()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload</span>
            </button> -->
            <button class="cmn-add-btn" *appHasPermission="['12', Permission.Create]" (click)="navigateTo('/vendors/vendor-new')">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add Vendor
            </button>
        </div>
        <div class="sub-head">
            <ul>
                <li class="main">All vendors</li>
                <!-- <li class="edit"><img src="assets/images/icons/icon-mail.svg" alt="Mail Icon"> Send to mail</li> -->
                <li class="edit" (click)="toggleDropdown($event)"><img src="assets/images/icons/icon-comment.svg"
                        alt="Comments Icon"> Comments & History
                </li>
                <li class="edit" *appHasPermission="['12', Permission.Edit]" (click)="onEdit()"><img src="assets/images/icons/icon-edit-accounts.svg"
                        alt="Edit Icon"> Edit</li>
                <!-- <li class="edit"><img src="assets/images/icons/icon-export-accounts.svg" alt="Export Icon"> Export</li> -->
                <li class="edit" (click)="toggleAttachFile($event)"><img src="assets/images/icons/icon-attach.svg"
                        alt="Attach">{{partnerDetails?.partner?.documents?.length}}</li>
            </ul>
            <!-- <div class="branch-wrapper">
                <form>
                    <mat-form-field>
                        <mat-select [(value)]="selectedOption">
                            <mat-option value="accounts"> All Accounts </mat-option>
                            <mat-option value="active"> Active Accounts </mat-option>
                            <mat-option value="inActive"> Inctive Accounts </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div> -->
            <!-- Comments Dropdown -->
            <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <form [formGroup]="commentForm" (ngSubmit)="onComment()">
                    <h6 class="cmn-popup-heading">
                        Comments
                    </h6>
                    <div class="filter-inner-content">
                        <div class="form-wrapper">
                            <mat-form-field>
                                <textarea matInput placeholder="Add Your Comment Here..."
                                    formControlName="comments"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="btn-wrapper">
                            <button class="cmn-apply-btn" type="submit">Comment</button>
                        </div>
                        <div class="timelinne-wrapper">
                            <!-- ngFor -->
                            <div class="timeline-box" *ngFor="let comment of comments">
                                <div class="timeline-head">
                                    <ul>
                                        <li>{{comment?.employee?.firstName || '--'}}</li>
                                        <li>{{comment?.createdAt | date: 'dd/MM/YYYY'}}</li>
                                    </ul>
                                    <h3>{{comment?.createdAt | date: 'hh:mm a'}}</h3>
                                </div>
                                <div class="timeline-content">
                                    <h4>{{comment?.note || '--'}}</h4>
                                </div>
                            </div>
                            <!-- ngFor -->
                        </div>
                    </div>
                </form>
            </div>
            <!-- Comments Dropdown -->
            <!-- Attach File Dropdown -->
            <div [ngClass]="{ show: isAttacheFile }" class="attach-file-dropdown" #dropdown2>
                <button class="close-dropdown" (click)="toggleAttachFile($event)"></button>
                <form [formGroup]="uploadForm">
                    <h6 class="cmn-popup-heading">
                        Attachments
                    </h6>
                    <div class="filter-inner-content " *ngFor="let detail of partnerDetails?.partner?.documents">
                        <div class="uploaded-box">
                            <div class="box">
                                <h2 (click)="viewDocument(detail?.documentUrl)" style="cursor: pointer">{{detail?.documentName}}</h2>
                                <h3>File Size: 2kb</h3>
                                <button (click)="onRemoveDocument(detail?.id)">
                                    <img src="assets/images/icons/icon-close.svg" alt="">
                                </button>
                            </div>
                        </div>
                    </div>
                    <input #fileInput type="file" style="display: none" formControlName="file"
                        (change)="onFileSelected($event)" />
                    <button class="cmn-upload-btn" *appHasPermission="['12', Permission.Upload]" (click)="fileInput.click()">
                        <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                        <span>Upload File</span>
                    </button>
                </form>
            </div>
            <!-- Attach File Dropdown -->
            <div class="mat-menu-wrapper">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item [routerLink]="['/vendor-invoice/vendor-invoice-new']">
                        <span>Bill</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/payments-made/bill-payment-new']">
                        <span>Bill Payment</span>
                    </button>
                </mat-menu>
            </div>
        </div>
        <div class="manual-journal-details-tab-wrapper">
            <div class="tab-left">
                <div class="tab-box-main-wrapper">
                    <button class="tab-custom-button" *ngFor="let vendor of allVendors; let i = index" [class.active]="selectedTabIndex === i" (click)="selectTab(i)">
                        <div class="inn-box">
                            <h2>{{vendor?.displayName}}</h2>
                            <h6>
                                {{ vendor?.openingBalance == null || vendor?.openingBalance === 0
                                ? 'AED 0.00'
                                : 'AED ' + vendor.openingBalance.toFixed(2) }}
                            </h6>
                        </div>
                    </button>
                </div>
            </div>
            <div class="manual-journal-details-content-wrapper">
                <div>
                    <div class="common-noimage-tab-wrapper">
                        <mat-tab-group animationDuration="0ms">
                            <!-- Mat Tab One -->
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <h2 class="leave-tab-title">Overview</h2>
                                </ng-template>
                                <div class="client-cmn-inner-content-wrapper">
                                    <app-overview></app-overview>
                                </div>
                            </mat-tab>
                            <!-- Mat Tab Two -->
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <h2 class="leave-tab-title">Transactions</h2>
                                </ng-template>
                                <div class="client-cmn-inner-content-wrapper">
                                    <app-transactions></app-transactions>
                                </div>
                            </mat-tab>
                            <!-- Mat Tab Three -->
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <h2 class="leave-tab-title">Statements</h2>
                                </ng-template>
                                <div class="client-cmn-inner-content-wrapper">
                                    <app-statements></app-statements>
                                </div>
                            </mat-tab>
                            <!-- Mat Tab Four -->
                            <!-- <mat-tab>
                                <ng-template mat-tab-label>
                                    <h2 class="leave-tab-title">Timeline</h2>
                                </ng-template>
                                <div class="client-cmn-inner-content-wrapper">
                                    <app-timeline></app-timeline>
                                </div>
                            </mat-tab> -->
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </section>
