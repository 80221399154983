import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { AssetService } from '../../../../features/assets/services/asset.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { AssetTransferAssetDialog } from '../../assets.component';

@Component({
  selector: 'app-assets-transfer',
  standalone: true,
  imports: [
    CommonModule,
    AgGridAngular,
  ],
  templateUrl: './assets-transfer.html',
  styleUrl: '../../assets.component.scss'
})
export class AssetsTranfer implements OnInit, OnChanges{
  @Input() searchQuery: string = '';
  public rowData: any[] | null = null;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
  readonly dialog = inject(MatDialog);

  columnDefs = [
    
    // { headerName: 'Asset Name', field: 'name',   flex: 1, },
    // { headerName: 'ID', field: 'id', flex: 1 },
    { headerName: 'Asset Name', field: 'assetName', flex: 1 },
    // { headerName: 'Category', field: 'category', flex: 1 },
    { headerName: 'From Branch', field: 'fromBranch', flex: 1 },
    { headerName: 'To Branch', field: 'toBranch', flex: 1 },
    { headerName: 'Type', field: 'type', flex: 1 },
    { headerName: 'Transfered By', field: 'transferedBy', flex: 1 },
    // {  headerName: 'Status', field: 'status',flex: 1,
    //   cellRenderer: (params: any) => {
    //     const status = params.data.status;
    //     const statusClass = status === "On Site" ? 'green' : 'red'; // Apply class if "On Leave"
    //     return `<span class="${statusClass}">${status}</span>`;
    //   }
    // },
    {
      headerName: 'Transfer Date',
      field: 'transferDate',
      flex: 1,
      cellRenderer: (params: any) => {
        const date = params.data.transferDate;
        return this.datePipe.transform(date, 'dd/MM/yyyy');
      },
    },
    // {
    //   field: 'action',
    //   headerName: 'Actions',
    //   flex: 1,
    //   cellRenderer: (params: any) => {
    //     const container = document.createElement('div');
    //     container.style.display = 'flex';
    //     container.style.alignItems = 'center';
    //     container.style.gap = '20px';

    //     const edit = document.createElement('img');
    //     edit.src = 'assets/images/icons/icon-edit.svg';
    //     edit.style.cursor = 'pointer';
    //     edit.setAttribute('data-action', 'edit');
    //     container.appendChild(edit);
    //     return container;
    //   },
    //   floatingFilter: false,
    //   filter: false,
    // },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };

  constructor(
    private assetsService: AssetService,
    private toaster: ToastrService,
    private datePipe: DatePipe
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchQuery']) {
      this.listAssetTransfer(null,null,this.searchQuery);
    }
  }
  ngOnInit(): void {
    this.listAssetTransfer();

    this.assetsService.dataUpdated$.subscribe((updated) => {
      if (updated) {
        this.listAssetTransfer(); // Reload data when notified
      }
    });
  }

    
 listAssetTransfer(assetId?: number | null, branchId?: number | null, search?: string){
        this.assetsService.listAssetTransfer(assetId, branchId, search).subscribe({
          next: (response) => {
            this.rowData = response.map((asset: any)=>{
              return {
                id: asset?.id,
                assetID: asset?.assetId,
                assetName: asset?.assetData?.assetName,
                fromBranch: asset?.fromBranchData?.branchName,
                toBranch: asset?.toBranchData?.branchName,
                type: asset?.assetData?.assetType,
                transferDate:  this.datePipe.transform(asset?.transferDate),
                status:asset?.assetData?.assetStatus,
                frombranchID: asset?.fromBranch,
                tobranchID: asset?.toBranch,
                transferedBy: asset?.transferredByData?.firstName,
           }
        })
       }
    })
 }

 onCellClicked(params: any) {
  if (   params.column.colId === 'action' && params.event.target.dataset.action ) {
    let action = params.event.target.dataset.action;
    if (action === 'edit') {
      const rowData = params.data;
      this.dialog.open(AssetTransferAssetDialog,{
        data: {
          ...rowData,
          isEdit: true
        }
      });
    } 
  }
}
}