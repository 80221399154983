import { CommonModule } from "@angular/common";
import { Component, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { PayrollService } from "../../features/payroll/services/payroll.service";
import { ToastrService } from "ngx-toastr";
import { PayrollListTableComponent } from "../../shared/components/payroll-list-table/payroll-list-table";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";

@Component({
  selector: 'initiate-payroll-dialog',
  templateUrl: 'upload-payroll.component.html',
  styleUrls: ['./payroll.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule
  ],
})
export class initiatePayrollDialog {
  @ViewChild(PayrollListTableComponent) payrollDetailData!: PayrollListTableComponent;
  file: any;
  uploadForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private payrollService: PayrollService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<initiatePayrollDialog>,
  ) { }

  ngOnInit() {
    this.uploadForm = this.fb.group({
      file: [null, Validators.required],
    });
    document.addEventListener('dragover', this.preventDefaultBehavior);
    document.addEventListener('drop', this.preventDefaultBehavior);
  }

  preventDefaultBehavior(event: DragEvent) {
    event.preventDefault();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer?.files.length) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }

  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.file = file;
    }
    this.uploadForm.get('file')!.updateValueAndValidity();
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
    this.uploadForm.get('file')!.updateValueAndValidity();
  }

  initiatePayroll() {
    if (this.uploadForm.invalid && !this.file) {
      this.uploadForm.markAllAsTouched();
      return;
    } 
          if(this.file){
            this.payrollService.bulkCreateEmployeePayroll(this.file, String(this.data?.year), this.data?.month).subscribe({
              next: (response) => {
                console.log(response);
                if (response && !response.errors) {
                  this.toastr.success('Employee payroll initiated successfully!', 'Success');
                  this.dialogRef.close(response);
                }
                else if (response?.errors) {
                  this.toastr.error(response?.errors[0]?.message, 'Error');
                }
              },
              error: (error) => {
                this.toastr.error(error, 'Bulk payroll creation failed');
                this.dialogRef.close();
              },
            });
          }
        }
}