import { gql } from "@apollo/client";

export const GET_FACE_ID_DEVICES = gql`
  query getAllDevices {
    getAllDevices {
      id
      deviceName
      androidId
      code
      locationId
      location {
        id
        locationName
        latitude
        longitude
        status
      }
      status
    }
  }
`;
