import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Observable, map, tap } from 'rxjs';
// import { selectPermissions } from '../permissions/permission.selector';
// import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  // hasInventoryPermission$: Observable<boolean>;
  constructor(private authService: AuthService, private router: Router,
    // private store: Store,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      map(() => {
        const authToken = localStorage.getItem('AUTH_TOKEN');
        const isLoggedIn = !!authToken;

        if (isLoggedIn) {
          const rolesAndPermision: any = localStorage.getItem('rolesAndPermision');
          const rolesPermision = JSON.parse(rolesAndPermision);
          const allowedRoles = route.data['allowedRoles'] as { moduleId: number; permissionIds: number[] };

          console.log('rolesPermision',rolesPermision);
          console.log('allowedRoles',allowedRoles);
  
          if (allowedRoles) {
            const hasPermission = rolesPermision.some((role: any) => {
              return (
                role.moduleId === allowedRoles.moduleId &&
                role.permissions.some((permission: any) =>
                  allowedRoles.permissionIds.includes(permission.permissionId || permission.id)
                  // allowedRoles.permissionIds.includes(permission.permissionId)
                )
              );
          }
            );
            if (!hasPermission) {
              this.router.navigate(['/page-not-found']);
              return false;
            }
          }
        }
        return isLoggedIn;
      }
    ),
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          localStorage.removeItem('AUTH_TOKEN');
          localStorage.removeItem('loggedInUser');
          sessionStorage.removeItem('AUTH_TOKEN');
          this.router.navigate(['/login']);
        }
      })
    );
  }
}