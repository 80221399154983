<mat-dialog-content class="mat-typography">
  <section class="add-branch-dialog-wrapper">
    <h2 class="cmn-popup-title">
      {{ locationsData?.id ? "Edit" : "Add" }} Location
    </h2>
    <button class="cmn-close" mat-dialog-close></button>
    <form [formGroup]="locationForm" (ngSubmit)="onAddLocation()">
      <div class="branch-form-wrapper">
        <div class="form-wrapper">
          <label for="">Location Name</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter Location Name"
              formControlName="locationName"
            />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error
                *ngIf="
                  locationForm.get('locationName')!.touched &&
                  locationForm.get('locationName')!.hasError('required')
                "
                >Location Name is required.</mat-error
              >
            </span>
          </div>

          <label for="">Latitude</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter Latitude"
              formControlName="latitude"
            />
          </mat-form-field>
          <!-- <div class="custom-error">
            <span>
              <mat-error
                *ngIf="
                  locationForm.get('latitude')!.touched &&
                  locationForm.get('latitude')!.hasError('required')
                "
                >Latitude is required.</mat-error
              >
            </span>
          </div> -->

          <label for="">Longitude</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter Longitude"
              formControlName="longitude"
            />
          </mat-form-field>
          <!-- <div class="custom-error">
            <span>
              <mat-error
                *ngIf="
                  locationForm.get('longitude')!.touched &&
                  locationForm.get('longitude')!.hasError('required')
                "
                >Longitude is required.</mat-error
              >
            </span>
          </div> -->

          <div
            (click)="openGoogleMaps()"
            style="cursor: pointer"
            class="open-maps"
          >
            Open Maps
          </div>

          <div *ngIf="isMapVisible" id="map" style="height: 400px; width: 100%"></div>
        </div>
      </div>
      <div class="btn-wrapper">
        <button class="cmn-next-btn" type="submit" [disabled]="loading">
          <span *ngIf="!loading">{{
            locationsData?.id ? "Save" : "Add Location"
          }}</span>
          <span *ngIf="loading">Loading...</span>
        </button>
      </div>
    </form>
  </section>
</mat-dialog-content>
