import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { LayoutComponent } from '../../core/layout/layout.component';
import { AddEmployeeComponent } from '../add-employee/add-employee.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AttendanceComponent } from './components/attendance/attendance.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { LeavesComponent } from './components/leaves/leaves.component';
import { ExitEmployeeComponent } from './components/exit-employee/exit-employee.component';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../features/employees/services/employee.service';
import { AssetsComponent } from './components/assets/assets.component';
import { SecurityComponent } from './components/security/security.component';
import { VacationComponent } from './components/vacation/vacation.component';
import { ImageService } from '../../shared/services/image.service';

@Component({
  selector: 'app-employee-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatTabsModule,
    AddEmployeeComponent,
    ProfileComponent,
    AttendanceComponent,
    ProjectsComponent,
    LeavesComponent,
    ExitEmployeeComponent,
    AssetsComponent,
    SecurityComponent,
    VacationComponent
  ],
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss'], // Corrected to 'styleUrls'
})
export class EmployeeDetailsComponent implements OnInit {
  source: 'myProfile' | 'employee' = 'myProfile'; // Initialize default source
  selectedTabIndex = 0;  // Default is 0, but can be programmatically set
  employeeId!: string;
  employeeData: any = {};
  firstName: string = '';
  lastName: string = '';
  profilePicture: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private employeeService: EmployeeService,
    private imageService: ImageService,
  ) {
    this.employeeId = this.route.snapshot.paramMap.get('id') || '';
  }

  ngOnInit(): void {
    this.checkSourceFromUrl();
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.loadEmployeeData(+id); 
    }
  }

  checkSourceFromUrl(): void {
    const currentUrl = this.router.url;
    if (currentUrl.includes('/my-profile')) {
      this.source = 'myProfile';
    } else if (currentUrl.includes('/employees')) {
      this.source = 'employee';
    }
  }

  loadEmployeeData(employeeId: number): void {
    this.employeeService.getEmployeeById(employeeId).subscribe(async (data) => {
      try {
        this.employeeData = data;
        this.firstName = this.employeeData.firstName;
        this.lastName = this.employeeData.lastName;
  
        let profilePicturePromise;
  
        if (this.employeeData.profilePicture) {
          profilePicturePromise = this.imageService.getImageAsBase64(
            this.employeeData.profilePicture
          ).catch(error => {
            console.error("Error loading profile picture:", error);
            return 'assets/images/dummy-profile-image.jpg'; 
          });
        } else {
          profilePicturePromise = Promise.resolve('assets/images/dummy-profile-image.jpg');
        }
  
        const [profilePicture] = await Promise.all([profilePicturePromise]);
        this.profilePicture = profilePicture;
      } catch (error) {
        console.error("Error loading employee data:", error);
      }
    });
  }
  

  navigateToEditProfile() {
    this.router.navigate([`/employees/edit/${this.employeeId}`]);
  }

  setTab(index: number) {
    this.selectedTabIndex = index;
  }
  
}
