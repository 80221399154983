<mat-dialog-content>
  <section class="quotation-rejection-wrapper">
    <h2 class="cmn-popup-title">Reject quotation</h2>
    <button class="cmn-close" mat-dialog-close></button>

    <div class="form-wrapper">
      <label for="">
        <span>Notes</span>
        <mat-form-field>
          <textarea matInput placeholder="Reason for rejection"></textarea>
        </mat-form-field>
      </label>
    </div>

    <button class="cmn-reject-btn">Reject</button>
  </section>
</mat-dialog-content>
