import { Component, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AttendanceService } from '../../features/attendance/services/attendance.service';
import { ToastrService } from 'ngx-toastr';
import { FormatTimePipe } from '../../shared/pipes/format-time.pipe';
import { DialogRef } from '@angular/cdk/dialog';
import { EmployeeService } from '../../features/employees/services/employee.service';
import * as Papa from 'papaparse';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ImageService } from '../../shared/services/image.service';
import { HolidayCalendarService } from '../../features/holiday-calendar/services/holiday-calendar.service';
import { DateAdapter, MatNativeDateModule, NativeDateAdapter  } from '@angular/material/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY', // Define the format for parsing
  },
  display: {
    dateInput: 'DD/MM/YYYY', // Define the format for display
    monthYearLabel: 'MMM YYYY', // Format for month/year picker
    dateA11yLabel: 'DD/MM/YYYY', // Accessibility label
    monthDayA11yLabel: 'DD MMM', // Month/day a11y label
  },
};

@Component({
  selector: 'app-attendance-management',
  standalone: true,
  imports: [
    LayoutComponent,
    CommonModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    AgGridAngular,
    ReactiveFormsModule,
    FormsModule,
    MatNativeDateModule,
  ],
  templateUrl: './attendance-management.component.html',
  styleUrl: './attendance-management.component.scss',
  // providers: [FormatTimePipe]
  providers: [
    FormatTimePipe,
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMATS, // Use the custom format
    }
  ],
})
export class AttendanceManagementComponent {
  public limit: number = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  offset: number = 1;
  search: string = '';
  filter: any;
  isDropdownOpen = false;
  startDate: any;
  endDate: any;
  employeeTypes: any;
  employeeStatus: any;
  selectedEmployeeTypes: any;
  selectedEmployeeStatus: any;
  filterForm: FormGroup;
  dateRangeForm: FormGroup;
  public rowData: any[] | null = null;
  // today: Date = new Date();

  // dateFilter = (d: Date | null): boolean => {
  //   const currentDate = d || new Date();
  //   return currentDate <= this.today
  // };

  constructor(
    private attendanceService: AttendanceService,
    private employeeService: EmployeeService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private formatTimePipe: FormatTimePipe,
    private fb: FormBuilder,
    private imageService: ImageService,
  ) {
    this.filterForm = this.fb.group({
      employeeTypes: this.fb.array([]),
      employeeStatus: this.fb.array([]),
    });


  }

  ngOnInit() {
    const startDate = new Date();
    const endDate = new Date();
    const formatDate = (date: Date) => {
      return date.toLocaleDateString('en-CA');
    };

    this.filter = {
      dateRange: {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate)
      }
    }

    this.dateRangeForm = this.fb.group({
      startDate: [new Date(this.filter.dateRange.startDate)],
      endDate: [new Date(this.filter.dateRange.endDate)]
    });
    this.fetchAttendance();
    this.loadEmployeeTypes();
    this.loadEmployeeStatus();
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }


  openDialog() {
    const dialogRef = this.dialog.open(UploadAttendanceDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.fetchAttendance();
    });
  }

  readonly dialog = inject(MatDialog);

  columnDefs = [
    {
      headerName: 'Employee Name',
      field: 'name',
      flex: 1,
      cellRenderer: (params: any) => {
        return `
          <div style="display: flex; align-items: center;">
            <img src="${params.data.imageUrl}" alt="Profile Image" style="width: 35px; height: 35px; margin-right: 10px; border-radius: 50%; object-fit: cover;">
            <div>
              <span>${params.data.name}</span>
            </div>
          </div>
        `;
      }
    },
    { headerName: 'Date', field: 'date', flex: 1 },
    { headerName: 'Designation', field: 'designation', flex: 1 },
    { headerName: 'Employee Code', field: 'employeeCode', flex: 1 },
    { headerName: 'Check In', field: 'in', flex: 1 },
    { headerName: 'Check Out', field: 'out', flex: 1 },
    { headerName: 'Work Hrs', field: 'hrs', flex: 1 },
    { headerName: 'Overtime', field: 'overtime', flex: 1 },
    { headerName: 'Type', field: 'type', flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      cellRenderer: (params: any) => {
        const status = params.data.status;
        const statusClass = status === "On Leave" ? 'on-leave' : 'working'; // Apply class if "On Leave"
        return `<span class="${statusClass}">${status}</span>`;
      }
    },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public themeClass: string = 'ag-theme-quartz';

  get employeeTypesFormArray() {
    return this.filterForm.get('employeeTypes') as FormArray;
  }

  // Getter for employeeStatus form array
  get employeeStatusFormArray() {
    return this.filterForm.get('employeeStatus') as FormArray;
  }

  fetchAttendance() {
    this.attendanceService.fetchAttendance(this.search, this.filter).subscribe({
      next: async (data) => {
        this.rowData = await Promise.all(
          data.map(async (item: any) => {
            let profilePicture;
            console.log("profilePicture", profilePicture);
            if (item?.employee?.profilePicture) {
              try {
                profilePicture = await this.imageService.getImageAsBase64(
                  item?.employee.profilePicture
                );
              } catch (error) {
                profilePicture = 'assets/images/dummy-profile-image.jpg';
              }
            } else {
              profilePicture = 'assets/images/dummy-profile-image.jpg';
            }
            return {
              date: this.datePipe.transform(item?.date, 'dd/MM/yyyy') || '--',
              name: item?.employee?.firstName + ' ' + item?.employee?.lastName || '--',
              designation: item?.employee?.designation?.designationName || '--',
              imageUrl: profilePicture,
              id: item?.employeeId || '--',
              employeeCode: item?.employee?.employeeCode || '--',
              in: this.formatTimePipe.transform(item?.checkInTime) || '--',
              out: this.formatTimePipe.transform(item?.checkOutTime) || '--',
              hrs: item?.totalHoursWorked != null 
              ? (item?.totalHoursWorked < 1 
                  ? `${Math.round(item?.totalHoursWorked * 60)} mins` 
                  : `${item?.totalHoursWorked} Hrs`) 
              : '--',
              overtime: (item?.overtimeHours || 0) + ' Hrs',
              type: item?.employee?.employeeType || '--',
              status: item?.employee?.employeeStatus || '--',
            };
          })
        );
      },
      error: (error) => {
        this.toastr.error(error?.message);
      },
    });
  }

  loadEmployeeTypes() {
    this.employeeService.getEmployeeTypes().subscribe({
      next: (data) => {
        this.employeeTypes = data;
        console.log('types',this.employeeTypes);
        
        this.addControls('employeeTypes', this.employeeTypes);
      },
      error: (error) => {
        this.toastr.error(error?.message);
      }
    });
  }

//   loadEmployeeTypes() {
//     this.employeeTypes = Object.values(EmployeeTypesEnum).map((type) => ({label: type.label, value: type.value}));
//     this.addControls('employeeTypes', this.employeeTypes);
//     console.log('employeeTypes', this.employeeTypes);
    
// }

  private addControls(formArrayName: string, data: any[]) {
    const formArray = this.filterForm.get(formArrayName) as FormArray;
    formArray.clear();

    data.forEach(() => {
      formArray.push(new FormControl(false));
    });
  }

  loadEmployeeStatus() {
    this.employeeService.getEmployeeStatus().subscribe({
      next: (data) => {
        this.employeeStatus = data;
        this.addControls('employeeStatus', this.employeeStatus);
      },
      error: (error) => {
        this.toastr.error(error?.message);
      }
    });
  }

  findAllAttendancesWithPagination() {
    if (this.offset < 0) {
      this.offset = 0;
    }
    this.attendanceService.findAllAttendancesWithPagination(this.limit, this.offset, this.search, this.filter).subscribe({
      next: (data) => {
        this.rowData = data?.attendances
          .map((data: any) => {
            const workHrs = data?.totalHoursWorked || 0; 
            const hrs =
              workHrs < 1
                ? `${Math.round(workHrs * 60)} mins` 
                : `${workHrs} Hrs`;
          
            return {
              date: this.datePipe.transform(data?.date, 'dd/MM/yyyy') || '--',
              name: data?.employee?.firstName + ' ' + data?.employee?.lastName || '--',
              designation: data?.designation || '--',
              imageUrl: data?.employee?.profilePicture || '--',
              id: data?.employeeId || '--',
              in: this.formatTimePipe.transform(data?.checkInTime) || '--',
              out: this.formatTimePipe.transform(data?.checkOutTime) || '--',
              hrs: hrs,
              overtime: data?.overtimeHours + ' ' + 'Hrs' || '--',
              type: data?.employee?.employeeType || '--',
              status: data?.employee?.employeeStatus || '--',
            };
          });
      },
      error: (error) => {
        this.toastr.error(error?.message);
      },
    });
  }

  onSubmit() {
    const selectedEmployeeStatus = this.filterForm.value.employeeStatus
      .map((checked: boolean, i: number) =>
        checked ? this.employeeStatus[i].employeeStatus : null
      )
      .filter((value: string | null) => value !== null);

    const selectedEmployeeTypes = this.filterForm.value.employeeTypes
      .map((checked: boolean, i: number) =>
        checked ? this.employeeTypes[i].employeeType : null
      )
      .filter((value: string | null) => value !== null);

    const startDate = new Date();
    const endDate = new Date();
    const formatDate = (date: Date) => {
      return date.toLocaleDateString('en-CA');
    };
    if (!this.startDate && !this.endDate) {
      this.startDate = formatDate(startDate);
      this.endDate = formatDate(endDate);
    }

    console.log(selectedEmployeeStatus);
    

    this.filter = {
      dateRange: { startDate: this.startDate, endDate: this.endDate },
      employeeStatus: selectedEmployeeStatus,
      employeeType: selectedEmployeeTypes,
    }
    this.fetchAttendance();
    this.toggleDropdown();
  }

  onDateChange(event: any, type: string) {
    const formattedDate = this.datePipe.transform(event.value, 'yyyy-MM-dd');
    if (type === 'start') {
      this.startDate = formattedDate;
    } else if (type === 'end') {
      this.endDate = formattedDate;
    }
    if (this.startDate != null && this.endDate != null) {
      this.filter = { dateRange: { startDate: this.startDate, endDate: this.endDate } };
      this.fetchAttendance();
    }
  }

  clearFilter(){
    this.filterForm.reset();
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.search = input.value;
    this.fetchAttendance();
  }

  onPaginationChanged(event: any): void {

    const currentPage = event.api.paginationGetCurrentPage();
    // this.limit = event.api.paginationGetPageSize(); 
    // this.offset = currentPage * this.limit;
    // if (this.offset < 0) {
    //   this.offset = 0;
    // }
    // this.findAllAttendancesWithPagination();
  }
}




@Component({
  selector: 'upload-attendance-dialog',
  templateUrl: 'upload-attendance-dialog.html',
  styleUrls: ['./attendance-management.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [FormatTimePipe]
})
export class UploadAttendanceDialog {
  csvFile: any = null;
  url: any;
  selectedDate: any;
  search: string | undefined;
  filter: {};
  uploadForm: FormGroup;
  today: Date = new Date();
  holidays: any;


  dateFilter = (d: Date | null): boolean => {
    this.fetchHolidays();
    
    const currentDate = d || new Date();
    if (!this.holidays || this.holidays.length === 0) {
      return currentDate <= this.today;
    }
  
    const isHoliday = this.holidays.some(
      (holidayDate: Date) => holidayDate.getTime() === currentDate.getTime()
    );
    
    return currentDate <= this.today && !isHoliday;
  };

  constructor(
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private attendanceService: AttendanceService,
    private dialogRef: DialogRef,
    private formatTimePipe: FormatTimePipe,
    private fb: FormBuilder,
    private holidayService: HolidayCalendarService,
  ) { }

  ngOnInit() {
    this.uploadForm = this.fb.group({
      date: [this.today, Validators.required],
      file: ['', Validators.required],
    });
    this.selectedDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    document.addEventListener('dragover', this.preventDefaultBehavior);
    document.addEventListener('drop', this.preventDefaultBehavior);
    this.fetchHolidays();
  }

  preventDefaultBehavior(event: DragEvent) {
    event.preventDefault();
  }

  onDateChange(event: any) {
    this.selectedDate = this.datePipe.transform(event.value, 'yyyy-MM-dd');
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer?.files.length) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }


  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.csvFile = file;
    }
  }

  fetchAttendance() {
    this.attendanceService.fetchAttendance(this.search, this.filter).subscribe({
      next: (data) => {
        data
          .map((data: any) => {
            return {
              name: data?.employee?.firstName + ' ' + data?.employee?.lastName || '--',
              designation: data?.designation || '--',
              imageUrl: data?.employee?.profilePicture || '--',
              id: data?.employeeId || '--',
              in: this.formatTimePipe.transform(data?.checkInTime) || '--',
              out: this.formatTimePipe.transform(data?.checkOutTime) || '--',
              hrs: data?.totalHoursWorked + ' ' + 'Hrs' || '--',
              overtime: data?.overtimeHours + ' ' + 'Hrs' || '--',
              type: data?.employee?.employeeType || '--',
              status: data?.employee?.employeeStatus || '--',
            };
          });
      },
      error: (error) => {
        this.toastr.error(error?.message);
      },
    });
  }

  uploadDocuments(csvFile: any, selectedDate: any) {
    const reader = new FileReader();

    reader.readAsText(csvFile);
    reader.onload = () => {
      const csvData = reader.result as string;
      Papa.parse(csvData, {
        header: true,
        complete: (parsedData) => {
          const rows = parsedData.data;
          const modifiedRows = rows.map((row: any) => {
            row['date'] = selectedDate;
            return row;
          });

          const modifiedCsv = Papa.unparse(modifiedRows);

          // Create a Blob for the new CSV file
          const modifiedBlob = new Blob([modifiedCsv], { type: 'text/csv' });
          const modifiedFile = new File([modifiedBlob], csvFile.name, { type: 'text/csv' });
          console.log(modifiedCsv);
          
          this.attendanceService.bulkCreateAttendance(modifiedFile).subscribe({
            next: (response) => {
              console.log(response);

              if (response && !response.errors) {
                this.toastr.success('Attendance uploaded successfully!', 'Success');
                this.dialogRef.close();
              }
              else if (response?.errors) {
                this.toastr.error(response?.errors[0]?.message, 'Error');
              }
            },
            error: (error) => {
              this.toastr.error(error, 'Bulk attendance creation failed');
              this.dialogRef.close();
            },
          });
        },
        error: (error: any) => {
          this.toastr.error(error.message, 'Error parsing CSV file');
        }
      });
    };
    reader.onerror = (error) => {
      this.toastr.error('Error reading file');
    };
  }

  fetchHolidays(){
    this.holidayService.getHolidayCalendarList().subscribe({
      next: (data) => {
        this.holidays = data.map((holiday: any) => new Date(holiday.holidayDate));
      },
      error: (error) => {
        console.error(error,'Error');
      }
    })
  }


  onUpdate(){
    if (this.uploadForm.invalid && !this.csvFile) {
      this.uploadForm.markAllAsTouched();
      return;
    }
    if (this.csvFile && this.selectedDate) {
      this.uploadDocuments(this.csvFile, this.selectedDate);
    }
  }

  removeFile(): void {
    this.csvFile = null;
    const input = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  ngOnDestroy() {
    document.removeEventListener('dragover', this.preventDefaultBehavior);
    document.removeEventListener('drop', this.preventDefaultBehavior);
  }
}
