import {gql} from 'apollo-angular';

export const GET_ITEMS = gql `
  query items($search: String, $filter: ItemFilter) {
    items(search: $search, filter: $filter) {
      id
      itemType
      skuCode
      imageUrl
      isSalesActive
      isPurchaseActive
      quantity
      unit
      itemName
      sellingPrice
      salesAccount
      salesDescription
      status
      notes
      costPrice
      purchaseAccount
      purchaseDescription
      vendorId
      vendorData {
      id
      partnerType
      customerType
      displayName
      paymentTerm
      companyName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      primaryContactId
    }
      salesAccountId
      salesAccountData {
        id
        accountType
        accountName
        accountCode
        description
        parentAccountId
        isSubAccount
        createdAt
        updatedAt
        deletedAt
      }
      purchaseAccountId
      purchaseAccountData {
        id
        accountType
        accountName
        accountCode
        description
        parentAccountId
        isSubAccount
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_ITEM_BY_ID = gql `
  query item($id: Int!) {
    item(id: $id) {
      id
      itemType
      skuCode
      imageUrl
      isSalesActive
      isPurchaseActive
      quantity
      unit
      itemName
      sellingPrice
      salesAccount
      salesDescription
      status
      notes
      costPrice
      purchaseAccount
      purchaseDescription
      vendorId
      vendorData {
      id
      partnerType
      customerType
      displayName
      paymentTerm
      companyName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      primaryContactId
    }
    salesAccountId
      salesAccountData {
        id
        accountType
        accountName
        accountCode
        description
      }
      purchaseAccountId
      purchaseAccountData {
        id
        accountType
        accountName
        accountCode
        description
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_ITEMS_PAGINATED = gql`
  query findAllItemsWithPagination($limit: Float!, $offset: Float!, $filter: ItemFilter) {
    findAllItemsWithPagination(limit: $limit, offset: $offset, filter: $filter) {
      items {
        id
        itemType
        skuCode
        imageUrl
        isSalesActive
        isPurchaseActive
        quantity
        unit
        itemName
        sellingPrice
        salesAccount
        salesDescription
        status
        notes
        costPrice
        purchaseAccount
        purchaseDescription
        vendorId
        vendorData {
          id
          partnerType
          customerType
          displayName
          paymentTerm
          companyName
          email
          phone
          mobile
          currency
          emirateID
          openingBalance
          paymentStatus
          status
          primaryContactId
        }
        salesAccountId
        salesAccountData {
          id
          accountType
          accountName
          accountCode
          description
          parentAccountId
          isSubAccount
          createdAt
          updatedAt
          deletedAt
        }
        purchaseAccountId
        purchaseAccountData {
          id
          accountType
          accountName
          accountCode
          description
          parentAccountId
          isSubAccount
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      total
      limit
      offset
    }
  }
`;

export const GET_VENDORS = gql `
  query getVendorDropdownItems {
    getVendorDropdownItems {
      id
      vendorName
      companyName
      email
      phoneNumber
    }
  }
`;
