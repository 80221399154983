export class PaginationHelper {
  static getPaginationPageSizeOptions(rowData: any[]): number[] {
    const length = rowData.length;

    if (length === 0) {
      return [10];
    } else if (length <= 10) {
      return [10];
    } else if (length <= 25) {
      return [10, 25];
    } else if (length <= 50) {
      return [10, 25, 50];
    } else if (length <= 100) {
      return [10, 25, 50, 100];
    } else {
      return [10, 25, 50, 100, length];
    }
  }
}
