<div class="tab-inner-content">
  <h2>Customer Balance Summary</h2>
  <h5>
    From {{ startDate | date : "MM/d/YYYY" }} to
    {{ endDate | date : "MM/d/YYYY" }}
  </h5>
  <table>
    <thead>
      <tr>
        <th>Customer Name</th>
        <th>Invoiced Amount</th>
        <th>Amount Received</th>
        <th>Closing Balance</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of customerBalanceSummaryReport.summary">
        <tr class="child">
          <td>{{ item.customerName }}</td>
          <td>{{ item.invoicedAmount | number : "1.2-2" }}</td>
          <td>{{ item.amountReceived | number : "1.2-2" }}</td>
          <td>{{ item.closingBalance | number : "1.2-2" }}</td>
        </tr>
      </ng-container>
      <tr class="sub-total">
        <td><strong>Total</strong></td>
        <td>
          {{
            customerBalanceSummaryReport.totals.totalInvoicedAmount
              | number : "1.2-2"
          }}
        </td>
        <td>
          {{
            customerBalanceSummaryReport.totals.totalAmountReceived
              | number : "1.2-2"
          }}
        </td>
        <td>
          {{
            customerBalanceSummaryReport.totals.totalClosingBalance
              | number : "1.2-2"
          }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
