<section class="cmn-innerpage-wrapper add-project-inventory">
    <h2 class="cmn-inner-heading">Create Warehouse</h2>
    <form [formGroup]="newWarehouseForm" (ngSubmit)="onSubmit()">
        <div class="chart-account-form-wrapper">
            <div class="form-wrapper">
                <label for="">Name <span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter Name" formControlName="name" />
                    <!-- <mat-error *ngIf="newWarehouseForm.get('firstName')!.hasError('required')">
                            First name is required.
                          </mat-error> -->
                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error *ngIf="newWarehouseForm.get('name')!.hasError('maxlength')">
                            Name must be at most 50 characters long.
                        </mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper">
                <label for="">Code <span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter code" formControlName="code" />
                </mat-form-field>
                <div *ngIf="newWarehouseForm.get('code')!.touched" class="custom-error">
                    <span>
                        <mat-error
                            *ngIf="newWarehouseForm.get('code')!.hasError('minlength') && !newWarehouseForm.get('code')!.hasError('required')"
                            class="error">
                            Code must be at least 8 digits.
                        </mat-error>
                    </span>
                    <span>
                        <!-- Max Length Error - only if value is present but above maximum length -->
                        <mat-error
                            *ngIf="newWarehouseForm.get('code')!.hasError('maxlength') && !newWarehouseForm.get('code')!.hasError('required')"
                            class="error">
                            Code cannot exceed 15 digits.
                        </mat-error>
                    </span>
                    <span>
                        <!-- Pattern Error - only if value is present but invalid pattern -->
                        <mat-error
                            *ngIf="newWarehouseForm.get('code')!.hasError('pattern') && !newWarehouseForm.get('code')!.hasError('required')"
                            class="error">
                            Only digits are allowed.
                        </mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper">
                <label for="location">Location</label>
                <mat-form-field>
                    <mat-select placeholder="Search Location" id="location">
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search Location"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option value="hai">Hai</mat-option>
                        <mat-option value="test">Test</mat-option>
                        <mat-option value="new">New</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-wrapper">
                <label for="">Contact <span class="red">*</span></label>
                <mat-form-field>
                    <mat-select placeholder="Employee" formControlName="contact">
                        <mat-option value="">A</mat-option>
                        <mat-option value="">B</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="custom-error">
                    <span>
                        <mat-error
                            *ngIf="newWarehouseForm.get('contact')!.touched && newWarehouseForm.get('contact')!.hasError('required')">
                            Contact is required.
                        </mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper">
                <label for="">Contact Number <span class="red">*</span></label>
                <mat-form-field>
                    <input matInput placeholder="Enter number" formControlName="contactNumber" />
                </mat-form-field>
                <div *ngIf="newWarehouseForm.get('contactNumber')!.touched" class="custom-error">
                    <span>
                        <mat-error
                            *ngIf="newWarehouseForm.get('contactNumber')!.hasError('minlength') && !newWarehouseForm.get('contactNumber')!.hasError('required')"
                            class="error">
                            Contact number must be at least 8 digits.
                        </mat-error>
                    </span>
                    <span>
                        <!-- Max Length Error - only if value is present but above maximum length -->
                        <mat-error
                            *ngIf="newWarehouseForm.get('contactNumber')!.hasError('maxlength') && !newWarehouseForm.get('contactNumber')!.hasError('required')"
                            class="error">
                            Contact number cannot exceed 15 digits.
                        </mat-error>
                    </span>
                    <span>
                        <!-- Pattern Error - only if value is present but invalid pattern -->
                        <mat-error
                            *ngIf="newWarehouseForm.get('contactNumber')!.hasError('pattern') && !newWarehouseForm.get('contactNumber')!.hasError('required')"
                            class="error">
                            Only digits are allowed.
                        </mat-error>
                    </span>
                </div>
            </div>
            <div class="form-wrapper">
                <label for="">About</label>
                <mat-form-field>
                  <textarea matInput placeholder="Type Something" formControlName="description"></textarea>
                </mat-form-field>
              </div>
        </div>

        <div class="submit-btn-wrapper">
            <button class="cmn-cancel-btn" type="button" (click)="onCancel()">Cancel</button>
            <button class="cmn-next-btn" type="submit">Save</button>
        </div>
    </form>
</section>