<div class="tab-inner-content">
  <h2>Aging Report for Vendor Invoice</h2>
  <table>
    <thead>
      <tr>
        <!-- <th>Vendor Invoice ID</th> -->
        <th>Vendor Invoice No</th>
        <th>Vendor Name</th>
        <th>Amount</th>
        <th>Due Date</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="agingVendorInvoice && agingVendorInvoice.length > 0">
        <ng-container *ngFor="let item of agingVendorInvoice">
          <tr class="child">
            <!-- <td>{{ item.billId }}</td> -->
            <td>{{ item.billNo }}</td>
            <td>{{ item.vendorName }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.dueDate | date : "mediumDate" }}</td>
            <td>{{ item.status }}</td>
          </tr>
        </ng-container>
      </ng-container>
      <!-- <p *ngIf="!agingVendorInvoice || agingVendorInvoice.length === 0">No data available</p> -->
      
    </tbody>
  </table>
</div>