
<section class="cmn-innerpage-wrapper inventory ag-grid-type-1">
  <div class="head">
    <div class="search-wrapper" *appHasPermission="['2', Permission.List]">
      <input
        type="text"
        class="cmn-header-search"
        placeholder="Search"
        (input)="onSearch($event)"
      />
    </div>
    <!-- <button class="cmn-upload-btn"  (click)="openDialog()">
      <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
      <span>Upload</span>
    </button>
    <button class="cmn-add-btn" (click)="navigateToAddNew()">
      <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add New
    </button> -->
    <button class="cmn-upload-btn" *appHasPermission="['2', Permission.Upload]" (click)="openDialog()">
      <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
      <span>Upload</span>
    </button>
    <button class="cmn-add-btn" *appHasPermission="['2', Permission.Create]" (click)="navigateToAddNew()">
      <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add New
    </button>
  </div>
  <div class="inner-content" *appHasPermission="['2', Permission.List]">
    <ag-grid-angular
      style="width: 100%"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowSelection]="rowSelection"
      [suppressRowClickSelection]="true"
      [pagination]="true"
      [paginationPageSize]="paginationPageSize"
      [paginationPageSizeSelector]="paginationPageSizeSelector"
      [class]="themeClass"
      (cellClicked)="onCellClicked($event)"
    />
  </div>
</section>

