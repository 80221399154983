
    <section class="cmn-innerpage-wrapper banking">
        <div class="head">
            <div class="search-wrapper" *appHasPermission="['1', Permission.List]">
                <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)"/>
            </div>
            <button class="cmn-upload-btn" *appHasPermission="['1', Permission.Upload]" (click)="openDialog()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Import Statement</span>
            </button>
            <button class="cmn-add-btn" *appHasPermission="['1', Permission.Create]" [routerLink]="'/banking/add-bank-account'">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon"/> Add Bank/Credit Card
            </button>
            <!-- <button class="cmn-filter-btn" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }">
                <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
            </button> -->
            <!-- <span *ngIf="selectedCount > 0">{{ selectedCount }}</span> -->
            <!-- Filter -->
            <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <form>
                    <h6 class="cmn-popup-heading">
                        Filter <span>Clear All</span>
                    </h6>
                    <div class="filter-inner-content">
                        <h2>Branch</h2>
                        <div class="check-wrapper">
                            <div class="check-box">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" />
                                    test
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="submit">Apply</button>
                    </div>
                </form>
            </div>
            <!-- Filter -->
        </div>
        <div class="sub-head">
            <!-- <div class="branch-wrapper">
                <form>
                    <mat-form-field>
                        <mat-select [(value)]="selectedOption">
                            <mat-option value="allAccounts"> All Accounts </mat-option>
                            <mat-option value="active"> Active Accounts </mat-option>
                            <mat-option value="inactive"> Inactive Accounts </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div> -->
        </div>
        <div class="cash-details-wrapper">
            <div class="left-set">
                <div class="cmn-box cash-box">
                    <div class="img-wrapper">
                        <img src="assets/images/cash.svg" alt="Cash Balance">
                    </div>
                    <div class="detail-box">
                        <h2 [ngClass]="accounts?.cashNetValue > 0 ? 'up' : 'down'">
                            AED <span>{{ accounts?.cashNetValue }}</span>
                          </h2>
                        <h3>Cash in Hand</h3>
                    </div>
                </div>
                <div class="cmn-box bank-box">
                    <div class="img-wrapper">
                        <img src="assets/images/bank.svg" alt="Bank Balance">
                    </div>
                    <div class="detail-box">
                        <!-- <h2 class="up">AED <span>{{ accounts?.bankNetValue }}</span></h2> -->
                        <h2 [ngClass]="accounts?.bankNetValue > 0 ? 'up' : 'down'">
                            AED <span>{{ accounts?.bankNetValue }}</span>
                          </h2>
                        <h3>Bank Balance</h3>
                    </div>
                </div>
            </div>
            <div class="right-set">
                <div class="uncategorized-wrapper">
                    <div class="number">
                        <span>{{ accounts?.totalUncategorizedCount }}</span>
                    </div>
                    <div class="details">
                        <h2>Uncategorized Transactions</h2>
                        <h3 [routerLink]="'/banking/categorize'">Categorize now</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="inner-content" *appHasPermission="['1', Permission.List]" >
            <div class="banking-table-wrapper" *ngIf="accounts?.results.length > 0; else noData" >
                <table>
                    <thead>
                        <tr>
                            <th>Account Details</th>
                            <th>Uncategorized</th>
                            <th>Pending Checks</th>
                            <th>Amount in Bank</th>
                            <th>Action</th>
                            <!-- <th>Amount in Munawel</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let account of accounts?.results" (click)="goToAccountDetail(account, $event)">
                            <td class="title" *ngIf="account?.chartOfAccount?.accountType === 'Bank'"><div><img src="assets/images/icons/icon-bank-account.svg" alt="Bank Account">{{ account?.chartOfAccount?.accountName + ' - ' + account?.chartOfAccount?.accountCode }}</div></td>
                            <td class="title" *ngIf="account?.chartOfAccount?.accountType !== 'Bank'"><div><img src="assets/images/icons/icon-bank-account.svg" alt="Bank Account">{{ account?.chartOfAccount?.accountName }}</div></td>
                            <td class="red">{{ account?.uncategorized }} Transactions</td>
                            <td>{{ account?.pending }}</td>
                            <td>AED {{ account?.amount }}</td>
                            <td>
                                <button (click)="onEdit(account?.chartOfAccount?.id)">
                                    <img src="assets/images/icons/icon-edit.svg" alt="Edit Icon" />
                                  </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ng-template #noData>
                <div class="no-data-message">
                    No data found.
                </div>
            </ng-template>
        </div>
    </section>
