<div class="statements-content-wrapper">
    <div class="tab-inner">
        <div class="note-header">
            <div *ngFor="let partnerDetail of partnerDetails?.partner?.addresses">
                <div class="left-set">
                    <h4>Munawel Group</h4>
                    <h3>Dubai Industrial City</h3>
                    <h3>United Arab Emirates</h3>
                </div>
            </div>
            <div class="right-set">
                <h2>STATEMENT OF ACCOUNTS</h2>
                <h3>{{startDate + ' to ' + endDate}}</h3>
            </div>
        </div>

        <div class="journal-details">
            <div class="left-set">
                <div class="details-box">
                    <h4>Bill To </h4>
                    <h5>:</h5>
                    <h6>{{partnerDetails?.partner?.displayName}}</h6>
                </div>
                <div class="details-box">
                    <h4>Subject</h4>
                    <h5>:</h5>
                    <h6>Regarding Purchase</h6>
                </div>
            </div>
            <!-- <div class="right-set">
                <div class="details-box">
                    <h5>Subject</h5>
                    <h6>Regarding Purchase</h6>
                </div>
            </div> -->
        </div>
        <div class="page-footer-content">
            <div class="right-set">
                <h1>Account Summary</h1>
                <div class="details-box">
                    <h4>Opening Balance</h4>
                    <h5>:</h5>
                    <h6>AED {{partnerDetails?.partner?.openingBalance.toFixed(2)  || '0.00'}}</h6>
                </div>
                <div class="details-box">
                    <h4>Billed Amount</h4>
                    <h5>:</h5>
                    <h6>AED {{partnerDetails?.billTotal.toFixed(2)  || '0.00'}}</h6>
                </div>
                <div class="details-box">
                    <h4>Amount Paid</h4>
                    <h5>:</h5>
                    <h6>AED {{partnerDetails?.paymentTotal.toFixed(2)  || '0.00'}}</h6>
                </div>
                <div class="details-box border">
                    <h4>Balance Due</h4>
                    <h5>:</h5>
                    <h6>AED {{partnerDetails?.balanceDue.toFixed(2)  || '0.00'}}</h6>
                </div>
            </div>
        </div>
        <div class="crdit-note-page-table">
            <table *ngIf="partnerDetails?.combinedTransactions.length > 0; else noData">
                <tr>
                    <th>Date</th>
                    <th>Transaction</th>
                    <th>Details</th>
                    <th>Amount</th>
                    <th>Payments</th>
                    <th>Balance</th>
                </tr>
                <tr *ngFor="let partnerDetail of partnerDetails?.combinedTransactions">
                    <td>{{partnerDetail?.date | date: 'dd/MM/YYYY'}}</td>
                    <td>{{partnerDetail?.type || '--'}}</td>
                    <td>{{partnerDetail?.note || '--'}}</td>
                    <td>AED {{partnerDetail?.totalPrice || '0.00'}}</td>
                    <td>AED {{partnerDetail?.paymentAmount || '0.00'}}</td>
                    <td>AED {{partnerDetail?.valance || '0.00'}}</td>
                </tr>
            </table>
        </div>
        <ng-template #noData>
            <h4>No Transactions Found</h4>
        </ng-template>
        <div class="page-footer-content"
            *ngIf="partnerDetails?.partner?.payments.length > 0 || partnerDetails?.partner?.bills.length > 0">
            <div class="right-set">
                <div class="details-box bold">
                    <h4>Balance Due</h4>
                    <h5>:</h5>
                    <h6>AED {{partnerDetails?.balanceDue.toFixed(2) || '0.00'}}</h6>
                </div>
            </div>
        </div>
    </div>
</div>