<mat-dialog-content>
    <section class="asset-add-assets-wrapper">
        <h2 class="cmn-popup-title">Add Asset</h2>
        <button class="cmn-close" mat-dialog-close></button>


        <div class="form-wrapper grid-form-wrapper">


            <div>
                <mat-form-field>
                    <mat-select placeholder="Type">
                        <mat-option value="Single">Item 1</mat-option>
                        <mat-option value="Married">Item 2</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-select placeholder="Category">
                        <mat-option value="Single">Item 1</mat-option>
                        <mat-option value="Married">Item 2</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="">
                <mat-form-field>
                    <input matInput placeholder="Asset Name" />
                </mat-form-field>
            </div>
            <div class="">
                <mat-form-field>
                    <input matInput placeholder="Hourly Rate" />
                </mat-form-field>
            </div>
            <div class="">
                <mat-form-field>
                    <input matInput placeholder="Serial/Hardware Number" />
                </mat-form-field>
            </div>

            <div>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker2" placeholder="Purchase Date" />
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>

                </mat-form-field>
            </div>
            
          

            <div class="span-2">
                <mat-form-field>
                    <input matInput placeholder="Serial/Hardware Number" />
                </mat-form-field>
            </div>

            <div>
                <mat-form-field>
                    <mat-select placeholder="Branch">
                        <mat-option value="Single">Item 1</mat-option>
                        <mat-option value="Married">Item 2</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker1" placeholder="Expiry Date" />
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>

                </mat-form-field>
            </div>

            <div>
                <mat-form-field>
                    <mat-select placeholder="Condition">
                        <mat-option value="Single">Item 1</mat-option>
                        <mat-option value="Married">Item 2</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


        </div>



        <div class="btn-wrapper">

            <button class="cmn-next-btn ">
                Submit
            </button>

        </div>
    </section>
</mat-dialog-content>