import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    output
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import {MatRippleModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {alphabeticValidator, customEmailValidator, middleNameValidator, numbersOnlyValidator} from '../../../../shared/services/validations';
import {UploadFileService} from '../../../../shared/services/file-upload.service';
import {ToastrService} from 'ngx-toastr';
import {ImageService} from '../../../../shared/services/image.service';
import {Router} from '@angular/router';
import {EmployeeService} from '../../../../features/employees/services/employee.service';

@Component({
    selector: 'app-personal-info',
    standalone: true,
    imports: [
        CommonModule,
        MatTabsModule,
        MatRippleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    templateUrl: './personal-info.component.html',
    styleUrl: '../../add-employee.component.scss'
})
export class PersonalInfoComponent implements OnChanges {
    profilePhoto : any = null;
    personalInfoForm : FormGroup;
    countries : any[] = [];
    countryCodes : any[] = [];
    today : Date = new Date();
    updatedImage : any = null;
    url : any = null;
    selectedCountryCode : string = '';
    selectedNativeCountryCode : string = '';

    // This filter disables all dates after today
    dateFilter = (d : Date | null): boolean => {
        const currentDate = d || new Date();
        return currentDate <= this.today;
    };

    @Input()employee : any;
    @Input()isEditMode : boolean = false;
    @Input()employeeData : any = {};
    @Output()formSubmit = new EventEmitter<any>();
    fifteenYearsAgo : Date;


    constructor(private fb : FormBuilder, private employeeService : EmployeeService, private http : HttpClient, private documentUploadService : UploadFileService, private toastr : ToastrService, private imageService : ImageService, private router : Router) {
        this.personalInfoForm = this.fb.group({
            profilePicture: [''],
            firstName: [
                '',
                [
                    Validators.required, alphabeticValidator()
                ]
            ],
            middleName: [
                '', middleNameValidator()
            ],
            lastName: [
                '',
                [
                    Validators.required, alphabeticValidator()
                ]
            ],
            phoneStdCode: [
                '',
            ],
            phoneNumber: [
                '',
                [
                    Validators.minLength(8), Validators.maxLength(15), numbersOnlyValidator(),
                ],
            ],
            nativeStdCode: [
                '',
            ],
            alternatePhoneNumber: [
                '',
                [
                    Validators.minLength(8), Validators.maxLength(15), numbersOnlyValidator(),
                ],
            ],
            email: [
                '',
                [
                    customEmailValidator()
                ]
            ],
            dateOfBirth: [
                '',
                [
                    Validators.required, this.dateOfBirthValidator()
                ]
            ],
            maritalStatus: [
                '', Validators.required
            ],
            gender: [
                '', Validators.required
            ],
            nationality: [
                '', Validators.required
            ],
            address: [
                '', Validators.required
            ],
            nativeAddress: ['']
        });
        // Calculate the date that is 15 years before today
        this.fifteenYearsAgo = new Date();
        this.fifteenYearsAgo.setFullYear(this.today.getFullYear() - 15);
    }
    // This filter disables dates within the last 15 years (already in place)
    dateOfBirthFilter = (d : Date | null): boolean => {
        const selectedDate = d || new Date();
        return selectedDate <= this.fifteenYearsAgo;
    };

    // Custom validator to check if date of birth is valid
    dateOfBirthValidator(): ValidatorFn {
        return(control : AbstractControl) : ValidationErrors | null => {
            const selectedDate = control.value;
            if (selectedDate && selectedDate > this.fifteenYearsAgo) {
                return {invalidDate: true}; // Return error if date is invalid
            }
            return null;
        };
    }

    ngOnChanges(changes : SimpleChanges): void {
        if (changes['employee'] && this.isEditMode && this.employee) {
            this.populateFormWithEmployeeData();
        }
    }

    async populateFormWithEmployeeData() {
        if (this.employee) {
            if (this.employee ?. profilePicture) {
                let profilePicture;
                if (this.employee ?. profilePicture) {
                    try {
                        profilePicture = await this.imageService.getImageAsBase64(this.employee ?. profilePicture);
                    } catch (error) {
                        profilePicture = 'assets/images/dummy-profile-image.jpg';
                    }
                } else {
                    profilePicture = 'assets/images/dummy-profile-image.jpg';
                }
                this.updatedImage = profilePicture;
            }
            this.personalInfoForm.patchValue({
                profilePicture: this.employee ?. profilePicture,
                firstName: this.employee.firstName,
                middleName: this.employee.middleName,
                lastName: this.employee.lastName,
                phoneStdCode: this.employee.phoneStdCode,
                phoneNumber: this.employee.phoneNumber,
                alternatePhoneNumber: this.employee.alternatePhoneNumber,
                nativeStdCode: this.employee.nativeStdCode,
                email: this.employee.email,
                dateOfBirth: this.employee.dateOfBirth ? new Date(Number(this.employee.dateOfBirth)) : null,
                maritalStatus: this.employee.maritalStatus,
                gender: this.employee.gender,
                nationality: this.employee.nationality,
                address: this.employee.address,
                nativeAddress: this.employee.nativeAddress
            });
            console.log('Form value after patch:', this.personalInfoForm.value);
        }
    }

    onFileSelected(event : Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const file = input.files[0];
            // Handle the file, e.g., upload it or read it
            console.log('Selected file:', file);
            this.profilePhoto = file;
            const reader = new FileReader();
            reader.readAsDataURL(this.profilePhoto);
            reader.onload = (event : any) => {
                this.updatedImage = event.target.result;
            };
        }
    }

    ngOnInit(): void {
        this.loadCountries();
        this.getCountryCodes();
        this.populateFormWithEmployeeData();
        // Set the default date of birth to 15 years ago if the form doesn't have a value
        if (!this.personalInfoForm.get('dateOfBirth') ?. value) {
            this.personalInfoForm.patchValue({dateOfBirth: this.fifteenYearsAgo});
        }
    }

    forceLowercase(controlName: string): void {
        const control = this.personalInfoForm.get(controlName);
        if (control) {
          const value = control.value || '';
          control.setValue(value.toLowerCase(), { emitEvent: false });
        }
      }
      

    loadCountries(): void {
        this.http.get<any[]>('assets/json/countries.json').subscribe((data) => {
            this.countries = data;
        }, (error) => {
            console.log('Error loading country data----', error);
        });
    }

    // Method to fetch the countries data
    getCountryCodes(): void {
        this.http.get<any[]>('assets/json/country-codes.json').subscribe((data) => {
            this.countryCodes = data;
        }, (error) => {
            console.log('Error loading country data----', error);
        });
    }

    // checkEmailExistence(): void {
    //     if (this.isEditMode) {
    //         return;
    //     }

    //     const emailControl = this.personalInfoForm.get('email');
    //     if (emailControl && emailControl.valid) {
    //         this.employeeService.getEmployeeByEmail(emailControl.value).subscribe((response : any) => {
    //             console.log('Email existence check response:', response);
    //             if (response === true) {
    //                 emailControl.setErrors({emailExists: true});
    //             } else {
    //                 emailControl.setErrors(null);
    //             }
    //         }, (error) => {
    //             console.error('Error checking email:', error);
    //         });
    //     }
    // }

    checkEmailExistence(): void {
        if (this.isEditMode) {
            return;
        }
    
        const emailControl = this.personalInfoForm.get('email');
        if (emailControl) {
            const emailValue = emailControl.value?.trim();
            if (!emailValue) {
                emailControl.setErrors(null);
                return;
            }
    
            if (emailControl.valid) {
                this.employeeService.getEmployeeByEmail(emailValue).subscribe(
                    (response: any) => {
                        console.log('Email existence check response:', response);
                        if (response === true) {
                            emailControl.setErrors({ emailExists: true });
                        } else {
                            emailControl.setErrors(null);
                        }
                    },
                    (error) => {
                        console.error('Error checking email:', error);
                    }
                );
            }
        }
    }
    


    nextConsole(): void {
        console.log('clicked')
    }


    onNext(): void {
        if (this.personalInfoForm.invalid) {
            this.personalInfoForm.markAllAsTouched();
            console.log('Form is Invalid');
        } else {
            if (this.profilePhoto) {
                this.documentUploadService.uploadDocument(this.profilePhoto, 'profile-images').subscribe({
                    next: (url) => {
                        this.url = url ?. url;
                        if (this.url) {
                            console.log('this.personalInfoForm', this.personalInfoForm.controls);

                            console.log(this.url);
                            this.personalInfoForm.patchValue({profilePicture: this.url});
                            this.formSubmit.emit(this.personalInfoForm.value);
                        }
                    },
                    error: (error) => {
                        console.error(error);
                        this.toastr.error('Profile upload failed', error);
                    }
                });
            } else {
                console.log('Personal Info Submitted:', this.personalInfoForm.value);
                this.formSubmit.emit(this.personalInfoForm.value);
            }
        }
    }

    onCancel(): void { // this.personalInfoForm.reset();
        this.router.navigate(['/employees']);
    }
}
