<div class="tab-content-wrapper">
  <form [formGroup]="salaryDetailForm" (ngSubmit)="onNext()">
    <div class="employee-form-wrapper">
      <div class="form-wrapper">
        <label for="">Basic Salary <span class="red">*</span></label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Basic Salary"
            formControlName="basicSalary"
            type="number"
          /><mat-error
            *ngIf="
              salaryDetailForm.get('basicSalary')!.touched &&
              salaryDetailForm.get('basicSalary')!.hasError('required')
            "
            >Basic salary is required.</mat-error
          ><mat-error
            *ngIf="
              salaryDetailForm.get('basicSalary')?.hasError('salaryExceedsCTC')
            "
          >
            Basic salary cannot exceed current CTC of
            {{ currentCTC }}. </mat-error
          ><mat-error
            *ngIf="salaryDetailForm.get('basicSalary')?.hasError('nonNegative')"
          >Basic salary cannot be negative.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">House Rent Allowance <span class="red">*</span></label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Housing Allowance"
            formControlName="hra"
            type="number"
          /><mat-error
            *ngIf="
              salaryDetailForm.get('hra')!.touched &&
              salaryDetailForm.get('hra')!.hasError('required')
            "
            >House rent allowance is required.</mat-error
          ><mat-error
            *ngIf="salaryDetailForm.get('hra')?.hasError('nonNegative')"
          >Housing rent allowance cannot be negative.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Allowances <span class="red">*</span></label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Allowances"
            formControlName="allowances"
            type="number"
          /><mat-error
            *ngIf="
              salaryDetailForm.get('allowances')!.touched &&
              salaryDetailForm.get('allowances')!.hasError('required')
            "
            >Allowances is required.</mat-error
          ><mat-error
            *ngIf="salaryDetailForm.get('allowances')?.hasError('nonNegative')"
          >Allowances cannot be negative.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Deductions <span class="red">*</span></label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter deductions"
            formControlName="deductions"
            type="number"
          />
          <mat-error
            *ngIf="
              salaryDetailForm.get('deductions')!.touched &&
              salaryDetailForm.get('deductions')!.hasError('required')
            "
            >Deductions required.</mat-error
          >
          <mat-error
            *ngIf="salaryDetailForm.get('deductions')?.hasError('nonNegative')"
          >Deductions cannot be negative.
          </mat-error>
          <mat-error
            *ngIf="
              salaryDetailForm
                .get('deductions')
                ?.hasError('deductionsExceedBasic')
            "
          >Deductions cannot exceed Basic Salary.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-wrapper">
        <label for="">Gross Salary</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Gross Salary"
            formControlName="grossSalary"
          />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Net Salary</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Net Salary"
            formControlName="netSalary"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
      <button class="cmn-next-btn" type="submit">Save</button>
    </div>
  </form>
</div>
