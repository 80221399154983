<section class="cmn-innerpage-wrapper invoice-details">
  <div class="head">
    <div class="search-wrapper">
      <input
        type="text"
        class="cmn-header-search"
        placeholder="Search"
        (input)="onSearch($event)"
      />
    </div>
    <button class="cmn-upload-btn" (click)="openDialog()">
      <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
      <span>Upload</span>
    </button>
    <button class="cmn-add-btn" (click)="navigateTo('invoice/invoice-add')">
      <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New Invoice
    </button>
    <!-- <button
        class="cmn-filter-btn"
        (click)="toggleDropdown($event)"
        [ngClass]="{ 'class-clicked': isDropdownOpen }"
      >
        <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
      </button> -->
    <!-- Filter -->
    <div
      [ngClass]="{ show: isDropdownOpen }"
      class="common-dropdown filter-position"
      #dropdown
    >
      <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
      <form>
        <h6 class="cmn-popup-heading">Filter <span>Clear All</span></h6>
        <div class="filter-inner-content">
          <h2>Branch</h2>
          <div class="check-wrapper">
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" />
                test
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="btn-wrapper">
          <button class="cmn-apply-btn" type="submit">Apply</button>
        </div>
      </form>
    </div>
    <!-- Filter -->
  </div>
  <div class="sub-head">
    <ul>
      <li class="main">All Invoices</li>
      <li class="edit" (click)="navigateToSendMail()">
        <img src="assets/images/icons/icon-send.svg" alt="Mail" /> Send to mail
      </li>
      <li class="edit" (click)="toggleComments($event)">
        <img src="assets/images/icons/icon-comment.svg" alt="Comment" />
        Comments & History
      </li>
      <li class="edit" (click)="navigateToEditPage()">
        <img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit" />
        Edit
      </li>
      <li class="edit" (click)="navigateToRecordPayment(customerId, invoiceId)">
        <img src="assets/images/icons/icon-record.svg" alt="Record" />
        Record Payment
      </li>
      <li class="edit" (click)="onViewDocument()">
        <img src="assets/images/icons/icon-pdf.svg" alt="PDF" />
        PDF
      </li>
    </ul>
    <!-- Comments Dropdown -->
    <div
      [ngClass]="{ show: isCommentsOpen }"
      class="common-dropdown filter-position"
      #dropdown2
    >
      <button class="close-dropdown" (click)="toggleComments($event)"></button>
      <form [formGroup]="commentForm" (ngSubmit)="onComment()">
        <h6 class="cmn-popup-heading">Comments</h6>
        <div class="filter-inner-content">
          <div class="form-wrapper">
            <mat-form-field>
              <textarea
                matInput
                placeholder="Add Your Comment Here..."
                formControlName="comments"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="btn-wrapper">
            <button class="cmn-apply-btn" type="submit">Comment</button>
          </div>
          <div class="timelinne-wrapper">
            <!-- ngFor -->
            <div class="timeline-box" *ngFor="let comment of comments">
              <div class="timeline-head">
                <ul>
                  <li>{{ comment?.employee?.firstName || "--" }}</li>
                  <li>{{ comment?.createdAt | date : "dd MMM yyyy" }}</li>
                </ul>
                <h3>{{ comment?.createdAt | date : "hh:mm a" }}</h3>
              </div>
              <div class="timeline-content">
                <h4>{{ comment?.note || "--" }}</h4>
              </div>
            </div>
            <!-- ngFor -->
          </div>
        </div>
      </form>
    </div>
    <!-- Comments Dropdown -->
    <div class="mat-menu-wrapper">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="markASSent()">
          <span>Mark as Sent</span>
        </button>
        <button mat-menu-item>
          <span>Credit Available</span>
        </button>
        <button mat-menu-item (click)="openInvoiceApplyCredits()">
          <span>Create Credit Note</span>
        </button>
        <button mat-menu-item>
          <span>Credit Applied</span>
        </button>
        <!-- <button mat-menu-item>
          <span>Clone</span>
        </button> -->
        <!-- <button mat-menu-item>
          <span>Delete</span>
        </button> -->
      </mat-menu>
    </div>
  </div>
  <div class="manual-journal-details-tab-wrapper">
    <div class="tab-left">
      <div class="tab-box-main-wrapper">
        <button
          *ngFor="let invoice of allInvoices; let i = index"
          class="tab-custom-button"
          [class.active]="selectedTabIndex === i"
          (click)="selectTab(i)"
        >
          <div class="inn-box">
            <h2>{{ invoice?.partnerData?.displayName }}</h2>
            <h3>{{ invoice?.invoiceNo }}</h3>
          </div>
          <div class="inn-box">
            <h6>{{ invoice?.invoiceDate | date : "dd MMM yyyy" }}</h6>
            <h5>{{ invoice?.status }}</h5>
          </div>
          <div class="inn-box">
            <h4 class="grey">{{ invoice?.orderNo }}</h4>
          </div>
        </button>
      </div>
    </div>
    <div class="manual-journal-details-content-wrapper">
      <div *ngIf="invoiceData">
        <div class="chart-tab-content">
          <div class="tab-main-container">
            <div class="tab-inner">
              <div class="note-header">
                <div class="journal-details">
                  <div class="right-set">
                    <h2>INVOICE</h2>
                    <h3>{{ invoiceData?.invoiceNo }}</h3>
                    <div class="details-box">
                      <h4>Invoice Date</h4>
                      <h5>:</h5>
                      <h6>
                        {{ invoiceData?.invoiceDate | date : "dd MMM yyyy" }}
                      </h6>
                    </div>
                    <div class="details-box">
                      <h4>Terms</h4>
                      <h5>:</h5>
                      <h6>
                        {{ invoiceData?.paymentTerm || "--" }}
                      </h6>
                    </div>
                    <div class="details-box">
                      <h4>Due Date</h4>
                      <h5>:</h5>
                      <h6>
                        {{ invoiceData?.dueDate | date : "dd MMM yyyy" }}
                      </h6>
                    </div>
                  </div>
                </div>

                <div class="left-set">
                  <div>
                    <h4>Bill To:</h4>
                    <h3>
                      <span>{{ invoiceData?.partnerData?.displayName }}</span>
                    </h3>
                    <h3>{{ invoiceData?.billingAddressData?.attention }}</h3>
                    <h3>{{ invoiceData?.billingAddressData?.addressLine1 }}</h3>
                    <h3>{{ invoiceData?.billingAddressData?.addressLine2 }}</h3>
                    <h3>
                      {{ invoiceData?.billingAddressData?.city }},
                      {{ invoiceData?.billingAddressData?.state }},
                      {{ invoiceData?.billingAddressData?.country }}
                    </h3>
                    <h3>{{ invoiceData?.billingAddressData?.phone }}</h3>
                    <h3>{{ invoiceData?.billingAddressData?.fax }}</h3>
                  </div>
                  <div>
                    <h4>Ship To:</h4>
                    <h3>{{ invoiceData?.shippingAddressData?.attention }}</h3>
                    <h3>
                      {{ invoiceData?.shippingAddressData?.addressLine1 }}
                    </h3>
                    <h3>
                      {{ invoiceData?.shippingAddressData?.addressLine2 }}
                    </h3>
                    <h3>
                      {{ invoiceData?.shippingAddressData?.city }},
                      {{ invoiceData?.shippingAddressData?.state }},
                      {{ invoiceData?.shippingAddressData?.country }}
                    </h3>
                    <h3>{{ invoiceData?.shippingAddressData?.phone }}</h3>
                    <h3>{{ invoiceData?.shippingAddressData?.fax }}</h3>
                  </div>
                </div>
              </div>

              <div class="journal-details">
                <div class="left-set">
                  <!-- <div class="details-box">
                    <h4>Bill To</h4>
                    <h5>:</h5>
                    <h6>
                      {{ invoiceData?.partnerData?.displayName }}
                    </h6>
                  </div> -->
                  <div class="details-box">
                    <h4>Subject</h4>
                    <h5>:</h5>
                    <h6>{{ invoiceData?.subject }}</h6>
                  </div>
                </div>
              </div>
              <div class="crdit-note-page-table">
                <table>
                  <tr>
                    <th>Item/Service</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Discount</th>
                    <th>Amount</th>
                  </tr>
                  <tr *ngFor="let item of invoiceData?.invoiceItems">
                    <td>{{ item.itemData?.itemName }}</td>
                    <td>{{ item.quantity || "--" }}</td>
                    <td>{{ item.rate || "--" }}</td>
                    <td>{{ item?.discount || 0 }}%</td>
                    <td>{{ item.amount || "--" }}</td>
                  </tr>
                </table>
              </div>
              <div class="page-footer-content">
                <div class="left-set">
                  <!-- <div class="details-box">
                      <h4>Customer Notes :</h4>
                      <h6>Looking forward for your business.</h6>
                    </div>
                    <div class="details-box">
                      <h4>Terms & Conditions :</h4>
                      <h6>Privacy Policy</h6>
                    </div> -->
                </div>
                <div class="right-set">
                  <div class="details-box">
                    <h4>Sub total</h4>
                    <h5>:</h5>
                    <h6>AED {{ getSubTotal() | number : "1.2-2" }}</h6>
                  </div>
                  <div class="details-box">
                    <h4>Total</h4>
                    <h5>:</h5>
                    <h6>AED {{ getSubTotal() | number : "1.2-2" }}</h6>
                  </div>
                </div>
              </div>

              <p class="signature">Note: {{ invoiceData?.customerNote }}</p>

              <!-- <span class="published-note"
                  ><img src="assets/images/img-draft.svg" alt="Draft"
                /></span> -->
            </div>
          </div>
          <div class="credit-note-footer-table-wrapper">
            <table>
              <tr class="title">
                <td>Journal</td>
              </tr>

              <tr>
                <td>
                  Date:
                  {{ invoiceData?.journalData?.date | date : "dd MMM yyyy" }}
                </td>
              </tr>
              <tr>
                <td>Journal No: {{ invoiceData?.journalData?.journalNo }}</td>
              </tr>
              <tr>
                <td>Account</td>
                <td>Debit</td>
                <td>Credit</td>
              </tr>
              <tr
                class="bold"
                *ngFor="let entry of invoiceData?.journalData.journalEnteries"
              >
                <td>{{ entry?.ChartOfAccount?.accountName }}</td>
                <td>AED {{ entry.debit | number : "1.2-2" }}</td>
                <td>AED {{ entry.credit | number : "1.2-2" }}</td>
              </tr>
              <tr class="bold">
                <td></td>
                <td>AED {{ getDebitTotal() | number : "1.2-2" }}</td>
                <td>AED {{ getCreditTotal() | number : "1.2-2" }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <button type="button" (click)="openInvoiceApplyCredits()">Dialog</button> -->
</section>
