import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { QuillModule } from 'ngx-quill';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Location } from '@angular/common';
import { MAIL_ALLOWED_FILE_TYPES } from '../../helpers/helper-file';
import { SendToMailService } from '../../features/send-to-mail/services/send-to-mail.service';
import { QuotationService } from '../../features/quotation/services/quotation.service';
import { PaymentsItemService } from '../../features/payments/services/payments.service';
import { CreditDebitNoteService } from '../../features/credit-debit-note/services/credit-debit-note.service';

@Component({
  selector: 'app-send-mail',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    QuillModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
  ],
  templateUrl: './send-to-mail.component.html',
  styleUrl: './send-to-mail.component.scss',
})
export class SendToMailComponent implements OnInit {
  ID: number;
  noteId: number;
  invoiceId: number;
  quoteId: number;
  purchaseId: number;
  public loading: boolean = true;
  invoiceTemplateControl = new FormControl();
  emailForm: FormGroup;
  getEmailData: any = null;

  documentFile: File[] = [];
  documentFileNames: string[] = [];
  allowedFileTypes: string[] = MAIL_ALLOWED_FILE_TYPES;
  isAttachInvoice: boolean = true;

  isLoading: boolean = false;

  constructor(
    private itemService: PaymentsItemService,
    private noteService: CreditDebitNoteService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private sendMailService: SendToMailService,
    private invoiceService: InvoiceService,
    private location: Location
  ) {
    this.emailForm = this.fb.group({
      toEmailControl: ['', [Validators.required, Validators.email]],
      ccEmailControl: ['', Validators.email],
      bccEmailControl: ['', Validators.email],
      subject: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {

      //payment recieved/made 
      const id = params['paymentID'];
      const invoiceOrBill_ids = params['ids'];
      if (id && invoiceOrBill_ids) {
        this.ID = +id;
        this.getPaymentDetails(invoiceOrBill_ids?.map((item: string) => +item));
      }

      // Credit/Debit Note Service
      const noteId = params['noteId'];
      if (noteId) {
        this.noteId = +noteId;
        this.getNoteDetails(+noteId);
      }

      //invoice
      const invoiceId = params['invoiceId'];
      if (invoiceId) {
        this.invoiceId = +invoiceId;
        this.getInvoiceDetails(+invoiceId)
      }

      //quotation
      const quoteId = params['quoteId'];
      if (quoteId) {
        this.quoteId = +quoteId;
        this.getQuoteDetails(+this.quoteId)
      }

      //purchase order and local purchase order
      const purchaseId = params['purchaseId'];
      if (purchaseId) {
        this.purchaseId = +purchaseId;
        this.getPurchaseDetails(+this.purchaseId);
      }
    });
  }

  toEmails: string[] = [];
  ccEmails: string[] = [];
  bccEmails: string[] = [];

  toEmailControl = new FormControl('');
  ccEmailControl = new FormControl('');
  bccEmailControl = new FormControl('');

  showBcc = false;

  toggleInvoice(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.isAttachInvoice = input.checked;
  }

  private getPaymentDetails(invoiceValue: any): void {
    if (this.ID !== null) {
      this.itemService.getPaymentDetails(this.ID, invoiceValue).subscribe({
        next: (data) => {
          this.getEmailData = data;
          this.toEmails = [data?.partner?.email];

          this.invoiceTemplateControl.setValue(
            data?.paymentTemplate?.templateBody
          );

          this.emailForm.patchValue({
            subject: data?.paymentTemplate?.templateSubject,
          });

          if (
            data.partner.primaryContact &&
            data.partner.primaryContact.email
          ) {
            this.ccEmails = [data.partner.primaryContact.email];
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  private getNoteDetails(id: number): void {
    if (this.ID !== null) {
      this.noteService.getNoteDetails(id).subscribe({
        next: (data) => {
          this.getEmailData = data;
          this.toEmails = [data?.partnerData?.email];

          this.invoiceTemplateControl.setValue(
            data?.emailTemplate?.templateBody
          );

          this.emailForm.patchValue({
            subject: data?.emailTemplate?.templateSubject,
          });

          if (
            data.partner.primaryContact &&
            data.partner.primaryContact.email
          ) {
            this.ccEmails = [data.partner.primaryContact.email];
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  private getInvoiceDetails(id: number): void {
    if (this.invoiceId !== null) {
      this.sendMailService.getInvoiceDetails(this.invoiceId).subscribe({
        next: (data) => {
          this.getEmailData = data;
          this.toEmails = [this.getEmailData?.partnerData?.email];
          this.invoiceTemplateControl.setValue(
            this.getEmailData?.invoiceTemplate?.templateBody
          );
          this.emailForm.patchValue({
            subject:
              this.getEmailData?.invoiceNo + ' ' + this.getEmailData?.invoiceTemplate?.templateSubject,
          });

          if (
            this.getEmailData.partnerData.primaryContact &&
            this.getEmailData.partnerData.primaryContact.email
          ) {
            this.ccEmails = [this.getEmailData.partnerData.primaryContact.email];
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  private getQuoteDetails(id: number): void {
    if (this.quoteId !== null) {
      this.sendMailService.getQuoteDetails(this.quoteId).subscribe({
        next: (data) => {
          this.getEmailData = data;
          this.toEmails = [this.getEmailData?.partnerData?.email];
          this.invoiceTemplateControl.setValue(
            this.getEmailData?.quoteTemplate?.templateBody
          );
          this.emailForm.patchValue({
            subject:
              this.getEmailData?.quoteCode + ' ' + this.getEmailData?.quoteTemplate?.templateSubject,
          });

          if (
            this.getEmailData.partnerData.primaryContact &&
            this.getEmailData.partnerData.primaryContact.email
          ) {
            this.ccEmails = [this.getEmailData.partnerData.primaryContact.email];
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  private getPurchaseDetails(id: number): void {
    if (this.purchaseId !== null) {
      this.sendMailService.getPurchaseDetails(this.purchaseId).subscribe({
        next: (data) => {
          this.getEmailData = data;
          this.toEmails = [this.getEmailData?.vendor?.email];
          this.invoiceTemplateControl.setValue(
            this.getEmailData?.purchaseOrderTemplate?.templateBody
          );
          this.emailForm.patchValue({
            subject:
              this.getEmailData?.quoteCode + ' ' + this.getEmailData?.purchaseOrderTemplate?.templateSubject,
          });

          if (
            this.getEmailData.partnerData.primaryContact &&
            this.getEmailData.partnerData.primaryContact.email
          ) {
            this.ccEmails = [this.getEmailData.partnerData.primaryContact.email];
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  sendEmail() {
    this.isLoading = true;
    const body = this.invoiceTemplateControl?.value;
    const fromEmail = 'admin@munawel.com';
    const templateId = 2;

    // console.log('Form values:', this.emailForm.value);

    // Ensure to filter out any empty or invalid emails
    const toEmails = [
      ...this.toEmails,
      ...this.emailForm.value.toEmailControl
        .split(',')
        .map((email: any) => email.trim())
        .filter((email: any) => this.isValidEmail(email)),
    ];

    const ccEmails = [
      ...this.ccEmails,
      ...this.emailForm.value.ccEmailControl
        .split(',')
        .map((email: any) => email.trim())
        .filter((email: any) => this.isValidEmail(email)),
    ];

    const subject = this.emailForm.value?.subject;

    if (this.noteId) {
      this.noteService
        .sendNoteMail(
          this.noteId,
          toEmails,
          ccEmails,
          subject,
          body,
          this.documentFile,
          this.isAttachInvoice
        )
        .subscribe({
          next: (result) => {
            this.isLoading = false;
            console.log('Email sent successfully:', result);
            this.toastr.success('Email sent successfully');
            this.location.back();
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error sending email:', error);
            this.toastr.error('Failed to send email');
          },
        });
    } else if (this.invoiceId) {
      this.sendMailService.sendInvoiceMail(
        this.invoiceId,
        toEmails,
        ccEmails,
        subject,
        body,
        this.documentFile,
        this.isAttachInvoice
      )
        .subscribe({
          next: (result) => {
            this.isLoading = false;
            console.log('Email sent successfully:', result);
            this.toastr.success('Email sent successfully');
            this.location.back();
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error sending email:', error);
            this.toastr.error('Failed to send email');
          },
        });
    } else if (this.quoteId) {
      const sendMailQuoteInput = {
        to: toEmails,
        cc: ccEmails,
        subject,
        body,
        isAttachment: this.isAttachInvoice,
        attachments: this.documentFile,
      };
      console.log("SendQUoteMailInput----", sendMailQuoteInput)
      this.sendMailService.sendQuotationMail(
        this.quoteId,
        sendMailQuoteInput
      )
        .subscribe({
          next: (result) => {
            this.isLoading = false;
            console.log('Email sent successfully:', result);
            this.toastr.success('Email sent successfully');
            this.location.back();
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error sending email:', error);
            this.toastr.error('Failed to send email');
          },
        });
    } else if (this.purchaseId) {
      this.sendMailService.sendPurchaseMail(
        this.purchaseId,
        toEmails,
        ccEmails,
        subject,
        body,
        this.documentFile,
        this.isAttachInvoice
      )
        .subscribe({
          next: (result) => {
            this.isLoading = false;
            console.log('Email sent successfully:', result);
            this.toastr.success('Email sent successfully');
            this.location.back();
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error sending email:', error);
            this.toastr.error('Failed to send email');
          },
        });
    } else {
      this.itemService
        .sendPaymentRecievedMail(
          this.ID,
          toEmails,
          ccEmails,
          subject,
          body,
          this.documentFile,
          this.isAttachInvoice
        )
        .subscribe({
          next: (result) => {
            this.isLoading = false;
            console.log('Email sent successfully:', result);
            this.toastr.success('Email sent successfully');
            this.location.back();
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error sending email:', error);
            this.toastr.error('Failed to send email');
          },
        });
    }
  }

  addEmail(type: 'to' | 'cc' | 'bcc') {
    let control;
    let emailList;

    switch (type) {
      case 'to':
        control = this.toEmailControl;
        emailList = this.toEmails;
        break;
      case 'cc':
        control = this.ccEmailControl;
        emailList = this.ccEmails;
        break;
      case 'bcc':
        control = this.bccEmailControl;
        emailList = this.bccEmails;
        break;
      default:
        return;
    }

    // Only proceed if control.value is defined and not empty
    const email = control?.value?.trim();

    if (email && this.isValidEmail(email) && !emailList.includes(email)) {
      emailList.push(email); // Add the new email to the list
      console.log(`${type.toUpperCase()} Emails:`, emailList); // Log for debugging
      control.reset(); // Clear the input field after adding
    } else if (email && !this.isValidEmail(email)) {
      console.error('Invalid email format:', email);
    }
  }

  removeEmail(email: string, type: 'to' | 'cc' | 'bcc') {
    let emailList;

    switch (type) {
      case 'to':
        emailList = this.toEmails;
        break;
      case 'cc':
        emailList = this.ccEmails;
        break;
      case 'bcc':
        emailList = this.bccEmails;
        break;
    }

    const index = emailList.indexOf(email);
    if (index >= 0) {
      emailList.splice(index, 1);
    }
  }

  toggleBcc() {
    this.showBcc = !this.showBcc;
  }

  private isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const filesArray = Array.from(input.files);
      const validFiles = filesArray.filter((file) =>
        this.allowedFileTypes.includes(file.type)
      );

      if (validFiles.length < filesArray.length) {
        alert('Some files were not accepted due to unsupported file types.');
      }

      this.documentFileNames.push(...validFiles.map((file) => file.name));
      this.documentFile.push(...validFiles);
    }
  }

  removeFile(index: number): void {
    this.documentFileNames.splice(index, 1);
    this.documentFile.splice(index, 1);
  }

  clearDocument(fileName: string): void {
    this.documentFileNames = this.documentFileNames.filter(
      (name: string) => name !== fileName
    );
    this.documentFile = this.documentFile.filter(
      (name: any) => name !== fileName
    );
  }

  backTo(): void {
    this.location.back();
  }
}

