<section class="cmn-innerpage-wrapper branch">
    <div class="inner-content">
        <ag-grid-angular
            style="width: 100%"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowSelection]="rowSelection"
            [suppressRowClickSelection]="true"
            [pagination]="true"
            [paginationPageSize]="paginationPageSize"
            [paginationPageSizeSelector]="paginationPageSizeSelector"
            class="ag-theme-quartz"
        />
    </div>
</section>
