import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { EMPLOYEE_PAYROLL, EMPLOYEE_PAYROLL_BY_ID, EMPLOYEE_SALARIES, FIND_ALL_PAYROLL_AG_GRID, GET_PAYROLLS } from '../graphql/query/payroll.queries';
import { CREATE_PAYROLL_BONUS, CREATE_PAYROLL_DEDUCTION, EMPLOYEE_PROCESS_PAYROLL, INITIATE_PAYROLL, PROCESS_PAYROLL, UPDATE_PAYROLL } from '../graphql/mutation/payroll.mutation';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PayrollService {
  constructor(private apollo: Apollo) {}

  employeeSalaries(): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: EMPLOYEE_SALARIES
      })
      .valueChanges.pipe(
        map((result) => result.data?.employeeSalaries || [])
      );
  }

  employeePayrollById(id: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .query<any>({
        query: EMPLOYEE_PAYROLL_BY_ID,
        variables:{
          id
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((result) => result.data?.employeePayroll || [])
      );
  }
  
  getPayrolls(search: any, filter: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_PAYROLLS,
        variables: {
          search,
          filter
        }
      })
      .valueChanges.pipe(
        map((result) => result.data?.payrolls || [])
      );
  }

  updatePayroll(updatePayrollInput: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: UPDATE_PAYROLL,
        variables: {
          updatePayrollInput
        }
      })
      .pipe(
        map((result: any) => result.data?.updatePayroll || [])
      );
  }

  processEmployeePayroll(id: any, employeeId: any, payrollId: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: EMPLOYEE_PROCESS_PAYROLL,
        variables: {
          id,
          employeeId,
          payrollId
        }
      })
      .pipe(
        map((result: any) => result.data?.processEmployeePayroll || [])
      );
  }

  processPayroll(processPayroll: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: PROCESS_PAYROLL,
        variables: {
          year: processPayroll.year,
          month: processPayroll.month,
        }
      })
      .pipe(
        map((result: any) => result.data?.processPayroll || [])
      );
  }

  initiatePayroll(initiatePayroll: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: INITIATE_PAYROLL,
        variables: {
          year: initiatePayroll.year,
          month: initiatePayroll.month,
        }
      })
      .pipe(
        map((result: any) => result.data?.initiatePayroll || [])
      );
  }

  createPayrollDeduction(createPayrollDeductionInput: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: CREATE_PAYROLL_DEDUCTION,
        variables: {
          createPayrollDeductionInput
        }
      })
      .pipe(
        map((result: any) => result.data?.createPayrollDeduction || [])
      );
  }

  createPayrollBonus(createPayrollBonusInput: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: CREATE_PAYROLL_BONUS,
        variables: {
          createPayrollBonusInput
        }
      })
      .pipe(
        map((result: any) => result.data?.createPayrollBonus || [])
      );
  }

  findAllPayrollWithAGGrid(startRow: any, endRow: any, sortModel: any, filterModel: any, search: String): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: FIND_ALL_PAYROLL_AG_GRID,
        variables: {
          startRow,
          endRow,
          sortModel,
          filterModel,
          search
        }
      })
      .valueChanges.pipe(
        map((result) => result.data?.findAllPayrollWithAGGrid || [])
      );
  }

  getPayrollByMonthYear(year: any, month: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .query<any>({
        query: EMPLOYEE_PAYROLL,
        variables: {
          year,
          month
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((result) => result.data?.getPayrollByMonthYear || [])
      );
  }

  bulkCreateEmployeePayroll(file: File, year: any, month: any): Observable<any> {
    const token = localStorage.getItem('AUTH_TOKEN'); 
    const formData = new FormData();
    formData.append('operations', JSON.stringify({
      query: `
      mutation bulkCreateEmployeePayroll($file: Upload!, $year: String!, $month: PayrollMonths!) {
        bulkCreateEmployeePayroll(file: $file, year: $year, month: $month)
      }
      `,
      variables: { file: null, year, month }
    }));
  
    formData.append('map', JSON.stringify({ '0': ['variables.file'] }));
    formData.append('0', file);
  
    return from(
      fetch(environment.url.hrmsModuleUrl, {
        method: 'POST',
        body: formData, 
        headers: {
          'authorization': token ? `Bearer ${token}` : '', 
          'x-apollo-operation-name': 'uploadFile'
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(result => result.data?.bulkCreateEmployeePayroll || result)
    );
  }
}