import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_DEPARTMENTS } from '../graphql/queries/departments.query';
import {
  CREATE_DEPARTMENT,
  REMOVE_DEPARTMENT_BY_ID,
  UPDATE_DEPARTMENT,
} from '../graphql/mutation/departments.mutation';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsService {
  constructor(private apollo: Apollo) {}

  fetchDepartments(search: string = '', status: number = 1): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_DEPARTMENTS,
        variables: { search, status },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.departments || []));
      
  }

  createDepartments(createBranchInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: CREATE_DEPARTMENT,
      variables: {
        input: createBranchInput,
      },
    });
  }

  updateDepartment(departmentsData: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: UPDATE_DEPARTMENT,
      variables: {
        updateDepartmentInput: departmentsData,
      },
    });
  }

  // Remove branch
  removeDepartmentByid(id: number): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: REMOVE_DEPARTMENT_BY_ID,
      variables: { id },
    });
  }
}
