import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
  selector: 'app-assets',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    AgGridAngular,
    MatTabsModule
  ],
  templateUrl: './assets.component.html',
  styleUrl: './assets.component.scss'
})
export class AssetsComponent {

  readonly dialog = inject(MatDialog);

  openAssetsAddAssetsDialog() {
    const dialogAssetsAddAssetsDialogItemRef = this.dialog.open(AssetsAddAssetsDialog);
  }

  openAssetTransferAssetDialog() {
    const dialogAssetTransferAssetDialogItemRef = this.dialog.open(AssetTransferAssetDialog);
  }

  openAssetsDisposeAssetDialog() {
    const dialogAssetsDisposeAssetDialogItemRef = this.dialog.open(AssetsDisposeAssetDialog);
  }

  openAssetsHistoryAssetDialog() {
    const dialogAssetsDisposeAssetDialogItemRef = this.dialog.open(AssetsHistoryAssetDialog);
  }







  rowData = [
    {
      name: 'Brooklyn Simmons',
      imageUrl: 'https://via.placeholder.com/30',
      id: "124335111",
      branch: "Creative Spaces",
      designation: "Marketing Coordinator",
      type: "Office",
      status: "Working",

    },
    {
      name: 'Darlene Robertson',
      imageUrl: 'https://via.placeholder.com/30',
      id: "124335112",
      branch: " Artisan Interiors ",
      designation: "Site Supervisor",
      type: "Outsourced",
      status: "On Leave",
    },

  ];

  columnDefs = [

    {
      headerName: 'Employee Name',
      field: 'name',
      flex: 1,
      cellRenderer: (params: any) => {
        return `
          <div style="display: flex; align-items: center;">
            <img src="${params.data.imageUrl}" alt="Profile Image" style="width: 35px; height: 35px; margin-right: 10px; border-radius: 50%; object-fit: cover;">
            <span>${params.data.name}</span>
          </div>
        `;
      }
    },
    { headerName: 'Employee ID', field: 'id', flex: 1 },
    { headerName: 'Branch Name', field: 'branch', flex: 1 },
    { headerName: 'Designation', field: 'designation', flex: 1 },
    { headerName: 'Type', field: 'type', flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      cellRenderer: (params: any) => {
        const status = params.data.status;
        const statusClass = status === "On Leave" ? 'on-leave' : 'working'; // Apply class if "On Leave"
        return `<span class="${statusClass}">${status}</span>`;
      }
    },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

}



@Component({
  selector: 'assets-add-asset-dialog',
  templateUrl: 'assets-add-asset-dialog.html',
  styleUrls: ['./assets.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, MatDatepickerModule],
})



export class AssetsAddAssetsDialog {

}

@Component({
  selector: 'assets-tranfer-asset-dialog',
  templateUrl: 'assets-tranfer-asset-dialog.html',
  styleUrls: ['./assets.component.scss'],
  standalone: true,

  imports: [CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, MatDatepickerModule, NgxMatSelectSearchModule],
})



export class AssetTransferAssetDialog {

}


@Component({
  selector: 'asset-dispose-asset-dialog',
  templateUrl: 'asset-dispose-asset-dialog.html',
  styleUrls: ['./assets.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, MatDatepickerModule],
})



export class AssetsDisposeAssetDialog {

}

@Component({
  selector: 'asset-history-asset-dialog',
  templateUrl: 'asset-history-asset-dialog.html',
  styleUrls: ['./assets.component.scss'],
  standalone: true,
  imports: [    AgGridAngular,CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, MatDatepickerModule],
})



export class AssetsHistoryAssetDialog {

  rowData = [
    {
      name: 'Brooklyn Simmons',
      imageUrl: 'https://via.placeholder.com/30',
      id: "124335111",
      branch: "Creative Spaces",
      designation: "Marketing Coordinator",
      type: "Office",
      status: "Working",

    },
    {
      name: 'Darlene Robertson',
      imageUrl: 'https://via.placeholder.com/30',
      id: "124335112",
      branch: " Artisan Interiors ",
      designation: "Site Supervisor",
      type: "Outsourced",
      status: "On Leave",
    },

  ];

  columnDefs = [

    {
      headerName: 'Employee Name',
      field: 'name',
      flex: 1,
      cellRenderer: (params: any) => {
        return `
          <div style="display: flex; align-items: center;">
            <img src="${params.data.imageUrl}" alt="Profile Image" style="width: 35px; height: 35px; margin-right: 10px; border-radius: 50%; object-fit: cover;">
            <span>${params.data.name}</span>
          </div>
        `;
      }
    },
    { headerName: 'Employee ID', field: 'id', flex: 1 },
    { headerName: 'Branch Name', field: 'branch', flex: 1 },
    { headerName: 'Designation', field: 'designation', flex: 1 },
    { headerName: 'Type', field: 'type', flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      cellRenderer: (params: any) => {
        const status = params.data.status;
        const statusClass = status === "On Leave" ? 'on-leave' : 'working'; // Apply class if "On Leave"
        return `<span class="${statusClass}">${status}</span>`;
      }
    },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

}