import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkOrderService } from '../../features/workOrder/services/workOrder.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'app-work-order-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './work-order-details.component.html',
  styleUrl: './work-order-details.component.scss',
})
export class WorkOrderDetailsComponent {
  allWorkOrders: any[] = [];
  loading: boolean = false;
  selectedTabIndex = 0; // Default to the first tab
  singleWorkOrder: any;
  detailsLoading: boolean = false;
  private searchSubject = new Subject<string>();
  constructor(
    private router: Router,
    private workorderService: WorkOrderService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private location: Location,
    private http: HttpClient
  ) {}

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  navigateToPath(route: string) {
    const url = `${route}/${this.singleWorkOrder?.id}`;
    this.router.navigate([url]);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const id = params['id']; // Get the 'id' parameter
      if (id) {
        this.selectedTabIndex = +id;
        this.loading = true;
        this.fetchOrderList();
        this.loadWorkorder(+id);
      }
      this.searchSubject
        .pipe(debounceTime(600), distinctUntilChanged())
        .subscribe((searchTerm) => {
          this.fetchOrderList(searchTerm);
        });
    });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  private fetchOrderList(search: string = '') {
    this.workorderService.getAllWorkOrder(search).subscribe({
      next: (orders: any) => {
        // Sort the designations by 'id' in descending order so the newest branch comes first
        this.allWorkOrders = orders
          .map((order: any) => order)
          .sort((a: any, b: any) => b.id - a.id);
      },
      error: (error: any) => {
        this.toastr.error(error, 'Error');
        this.loading = false;
      },
    });
  }

  private loadWorkorder(id: number) {
    this.detailsLoading = true;
    this.workorderService.getWorkOrderById(id).subscribe({
      next: (details) => {
        console.log('workOrderFormworkOrderForm', details);
        try {
          this.singleWorkOrder = details;
          this.detailsLoading = false;
        } catch (error) {
          this.detailsLoading = false;
          this.router.navigate(['/']);
          console.error('Error loading employee data:', error);
        }
      },
      error: (error) => console.error('Error fetching employee types:', error),
    });
  }

  calculateAmount(item: any): void {
    return item?.workOrderSOWs?.length
      ? item?.workOrderSOWs?.reduce(
          (sum: any, item: any) => sum + Number(item.netPrice),
          0
        )
      : 0;
  }

  genPdf() {
    if (this.singleWorkOrder?.id) {
      this.workorderService.gereratePdf(this.singleWorkOrder?.id).subscribe({
        next: (details) => {
          this.toastr.success('Successfully Generate PDF');
          this.downloadPDF(details);
        },
        error: (error) =>
          console.error('Error fetching employee types:', error),
      });
    }
  }

  downloadPDF(url: string) {
    const token = localStorage.getItem('AUTH_TOKEN'); // Get token from localStorage
    const fileName = url.substring(url.lastIndexOf('/') + 1);
    const headers = new HttpHeaders({
      Authorization: token ? `Bearer ${token}` : '',
    });
    // Replace with your PDF URL
    this.http.get(url, { headers, responseType: 'blob' }).subscribe(
      (blob) => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = fileName; // Name for the downloaded file
        a.click();
        URL.revokeObjectURL(objectUrl); // Clean up after download
      },
      (error) => {
        console.error('Error downloading PDF', error);
      }
    );
  }
  // selectTab(index: number) {
  //   this.selectedTabIndex = index;
  // }

  selectTab(index: number) {
    if (this.selectedTabIndex !== index) {
      this.selectedTabIndex = index;
      this.location.replaceState(`work-order/work-order-details/${index}`);
      this.loadWorkorder(+index);
    }
  }

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(UploadworkDetailsDialog);
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}

@Component({
  selector: 'upload-work-details-dialog',
  templateUrl: 'upload-work-details-dialog.html',
  styleUrls: ['./work-order-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadworkDetailsDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}
