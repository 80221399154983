import { gql } from "apollo-angular";

export const LIST_ACCOUNTS_CASH_AND_BANK = gql`
  query listAllChartOfAccountsOfBankAndCash($search: String ) {
    listAllChartOfAccountsOfBankAndCash(search: $search) {
    results {
      chartOfAccount{
        id
        accountType
        accountName
      }
      uncategorized
      pending
      categorized
      amount
    }
    bankNetValue
    cashNetValue
    totalUncategorizedCount
  }
  }
`;

export const TRANSACTION_CATEGORIES = gql`
  query transactionCategories {
    transactionCategories{
    id
    categoryName
    categoryStatus
    createdAt
    updatedAt
    deletedAt
    status
    transactions {
      id
      date
      referenceNumber
      transactionStatus
      amount
      flow
      description
      paidVia
      createdAt
      updatedAt
      deletedAt
      status
      offsetAccountId
      # offsetAccount
      transactionCategoryId
      # transactionCategory
      # transactionReconcilations
    }
  }
  }
`;

export const NET_AMOUNT_CASH_AND_BANK = gql`
  query getNetAmountOfChartOfAccountsOfBankAndCash {
    getNetAmountOfChartOfAccountsOfBankAndCash  {
    bank
    cash
    uncategorizedCount
  }
  }
`;

export const TRANSACTIONS_OF_CHART_ACCOUNTS = gql`
  query findAllTransactionsAndUncategorizedTransactions($offsetAccountId: Int!) {
    findAllTransactionsAndUncategorizedTransactions(offsetAccountId: $offsetAccountId)  {
    transactions {
      id
      date
      referenceNumber
      transactionStatus
      amount
      flow
      description
      paidVia
      createdAt
      updatedAt
      deletedAt
      status
      offsetAccountId
      offsetAccount{
        accountName
      }
      transactionCategoryId
      transactionCategory{
        categoryName
      }
      # transactionReconcilations
    }
    statements {
      id
      offsetAccountId
      offsetAccount{
        accountName
      }
      date
      amount
      flow
      statementRecordStatus
      referenceNumber
      payee
      description
      createdAt
      updatedAt
      deletedAt
      status
      # statementReconcilations
    }
    netValue
    # offsetAccount{
    #     accountName
    #   }
  }
  }
`;