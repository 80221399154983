import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SEND_MAIL_INVOICE, SEND_MAIL_PURCHASE_ORDER, SEND_MAIL_QUOTATION } from '../graphql/send-to-mail.mutation';
import { GET_INVOICE_DETAILS } from '../../invoice/graphql/invoice.queries';
import { GET_PURCHASE_ORDER_DETAILS, GET_QUOTATION_DETAILS } from '../graphql/send-to-mail.queries';

@Injectable({ providedIn: 'root' })
export class SendToMailService {
  constructor(private http: HttpClient, private apollo: Apollo) { }

  sendInvoiceMail(
    invoiceId: any,
    to: any[],
    cc: any[],
    subject: any,
    body: any,
    attachment?: any,
    isAttachment?: boolean
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: SEND_MAIL_INVOICE,
      variables: {
        invoiceId: invoiceId,
        to: to,
        cc: cc,
        subject: subject,
        body: body,
        attachment: attachment,
        isAttachment: isAttachment,
      },
      context: {
        headers: {
          'x-apollo-operation-name': 'file-upload',
          'apollo-require-preflight': 'true',
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  sendPurchaseMail(
    purchaseOrderId: any,
    to: any[],
    cc: any[],
    subject: any,
    body: any,
    attachment?: any,
    isAttachment?: boolean
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: SEND_MAIL_PURCHASE_ORDER,
      variables: {
        purchaseOrderId: purchaseOrderId,
        to: to,
        cc: cc,
        subject: subject,
        body: body,
        attachment: attachment,
        isAttachment: isAttachment,
      },
      context: {
        headers: {
          'x-apollo-operation-name': 'file-upload',
          'apollo-require-preflight': 'true',
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  sendQuotationMail(
    quoteId: any,
    sendQuoteMailInput: any
  ): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: SEND_MAIL_QUOTATION,
      variables: {
        quoteId: quoteId,
        sendQuoteMailInput: sendQuoteMailInput
      },
      context: {
        headers: {
          'x-apollo-operation-name': 'file-upload',
          'apollo-require-preflight': 'true',
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  getInvoiceDetails(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_INVOICE_DETAILS,
        variables: {
          id: id,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.getInvoiceDetails));
  }

  getQuoteDetails(id: number): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_QUOTATION_DETAILS,
        variables: {
          id: id,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.getQuoteDetails));
  }

  getPurchaseDetails(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_PURCHASE_ORDER_DETAILS,
        variables: {
          id: id,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.getPurchaseOrderDetails));
  }
}
