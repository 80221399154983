
    <section class="cmn-innerpage-wrapper roles">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)"/>
            </div>
            <button class="cmn-add-btn" (click)="openDialog()">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add Role
            </button>
        </div>
        <div class="inner-content">
            <ag-grid-angular style="width: 100%;" [rowData]="rowData" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [pagination]="true" [paginationPageSize]="paginationPageSize"
                [paginationPageSizeSelector]="paginationPageSizeSelector" [class]="themeClass" (cellClicked)="onCellClicked($event)"/>
        </div>
    </section>
