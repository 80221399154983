import {
  GET_DEPARTMENTS,
  GET_DESIGNATIONS,
  GET_EMPLOYEES_LIST,
  GET_EMPLOYEE_APPROVER,
  GET_EMPLOYEE_APPROVERS,
  GET_EMPLOYEE_BY_ID,
  GET_EMPLOYEE_CAREER,
  GET_EMPLOYEE_CAREER_BY_ID,
  GET_EMPLOYEE_EMAIL_EXISTENCE,
  GET_EMPLOYEE_EXIT_BY_ID,
  GET_EMPLOYEE_SALARY_BY_ID,
  GET_USER_ROLES,
  GET_USER_ROLE_BY_ID,
} from './../graphql/queries/employee.queries';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import {
  GET_BRANCH_NAMES,
  GET_EMPLOYEES,
} from '../graphql/queries/employee.queries';
import {
  CREATE_EMPLOYEE,
  CREATE_EMPLOYEE_APPROVER,
  CREATE_EMPLOYEE_CAREER,
  CREATE_EMPLOYEE_EXIT,
  CREATE_EMPLOYEE_SALARY,
  CREATE_PORTAL_ACCESS,
  EXPORT_EMPLOYEES_TO_CSV,
  EXPORT_EMPLOYEES_TO_PDF,
  EXPORT_EMPLOYEES_TO_XLSx,
  REMOVE_EMPLOYEE,
  REMOVE_EMPLOYEE_APPROVER,
  REMOVE_EMPLOYEE_CAREER,
  REMOVE_PORTAL_ACCESS,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_APPROVER,
  UPDATE_EMPLOYEE_CAREER,
  UPDATE_EMPLOYEE_EXIT,
  UPDATE_EMPLOYEE_SALARY,
  UPDATE_PORTAL_ACCESS,
} from '../graphql/mutation/employee.mutation';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
  constructor(private http: HttpClient, private apollo: Apollo) { }

  // fetch employees
  getEmployees(search: string, filter: {}): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_EMPLOYEES_LIST,
        variables: {
          search,
          filter,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((result) => result.data?.getEmployeeList || []));
  }

  // fetch employee by ID
  getEmployeeById(id: number): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_EMPLOYEE_BY_ID,
        variables: {
          id: id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.getEmployee || []));
  }

  getEmployeeApprover(id: number): Observable<any> {
    return this.apollo
      .use('hrms')
      .query<any>({
        query: GET_EMPLOYEE_APPROVER,
        variables: {
          id: id,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data?.getEmployeeApprover || []));
  }

  // get employee exit by id
  getEmployeeExitById(id: number): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_EMPLOYEE_EXIT_BY_ID,
        variables: {
          id: id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.employeeExit || []));
  }

  getEmployeeCareerById(id: number): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_EMPLOYEE_CAREER,
        variables: {
          id: id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.employeeCareer || []));
  }

  // create employee
  createEmployee(
    createEmployeeInput: any,
    createUserDTO: any
  ): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: CREATE_EMPLOYEE,
      variables: {
        createEmployeeInput: createEmployeeInput,
        createUserDTO: createUserDTO,
      },
    });
  }

  // create employee career
  createEmployeeCareer(createEmployeeCareerInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: CREATE_EMPLOYEE_CAREER,
      variables: {
        createEmployeeCareerInput: createEmployeeCareerInput,
      },
    });
  }

  // update employee career
  updateEmployeeCareer(updateEmployeeCareerInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: UPDATE_EMPLOYEE_CAREER,
      variables: {
        updateEmployeeCareerInput: updateEmployeeCareerInput,
      },
    });
  }

  //update portal access
  updatePortalAccess(updatePortalUserInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: UPDATE_PORTAL_ACCESS,
      variables: {
        updatePortalUserInput: updatePortalUserInput,
      },
    });
  }

  // update employee
  updateEmployee(
    id: number,
    updateEmployeeInput: any,
    createUserDTO: any
  ): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: UPDATE_EMPLOYEE,
      variables: {
        id: id,
        updateEmployeeInput: updateEmployeeInput,
        createUserDTO: createUserDTO,
      },
    });
  }

  // fetch userRole by id
  getUserRoleById(id: number): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_USER_ROLE_BY_ID,
        variables: {
          id: id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.profile));
  }

  // fetch employee salary
  getEmployeeSalaryById(id: number): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_EMPLOYEE_SALARY_BY_ID,
        variables: {
          id: id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.employeeSalary || []));
  }

  // create employee exit
  submitEmployeeExit(createEmployeeExitInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: CREATE_EMPLOYEE_EXIT,
      variables: {
        createEmployeeExitInput: createEmployeeExitInput,
      },
    });
  }

  // create employee salary
  createEmployeeSalary(createEmployeeSalaryInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: CREATE_EMPLOYEE_SALARY,
      variables: {
        input: createEmployeeSalaryInput,
      },
    });
  }

  // update employee salary
  updateEmployeeSalary(updateEmployeeSalaryInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: UPDATE_EMPLOYEE_SALARY,
      variables: {
        updateEmployeeSalaryInput: updateEmployeeSalaryInput,
      },
    });
  }

  // update employee exit
  updateEmployeeExit(updateEmployeeExitInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: UPDATE_EMPLOYEE_EXIT,
      variables: {
        updateEmployeeExitInput: updateEmployeeExitInput,
      },
    });
  }

  // Remove employee
  removeEmployee(id: number): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: REMOVE_EMPLOYEE,
      variables: {
        id,
      },
    });
  }

  //remove portal access
  removePortalAccess(employeeId: number): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: REMOVE_PORTAL_ACCESS,
      variables: {
        employeeId,
      },
    })
  }

  // Remove employee approver
  removeEmployeeApprover(employeeId: number, level: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: REMOVE_EMPLOYEE_APPROVER,
      variables: {
        employeeId,
        level,
      },
    });
  }

  // remove employee caree
  removeEmployeeCareer(id: number): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: REMOVE_EMPLOYEE_CAREER,
      variables: {
        id,
      },
    });
  }

  // Fetch branches
  getBranches(): Observable<any[]> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({ query: GET_BRANCH_NAMES, fetchPolicy: 'network-only' })
      .valueChanges.pipe(map((result) => result?.data?.branches || []));
  }

  // get email exist or not
  getEmployeeByEmail(email: string): Observable<any[]> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_EMPLOYEE_EMAIL_EXISTENCE,
        variables: {
          email: email,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((result) => result?.data?.getEmployeeByEmail || [])
      );
  }

  // Fetch designations
  getDesignations(search: string = '', status: number = 1): Observable<any[]> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_DESIGNATIONS,
        variables: {
          search: search,
          status: status,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result?.data?.designations || []));
  }

  // Fetch departments
  getDepartments(search: string = '', status: number = 1): Observable<any[]> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_DEPARTMENTS,
        variables: {
          search: search,
          status: status,
        },
      })
      .valueChanges.pipe(map((result) => result?.data?.departments || []));
  }

  // Fetch user roles
  getUserRoles(search: string = ''): Observable<any[]> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_USER_ROLES,
        variables: {
          search: search,
        },
      })
      .valueChanges.pipe(map((result) => result?.data?.profiles || []));
  }

  bulkCreateEmployees(file: File): Observable<any> {
    const token = localStorage.getItem('AUTH_TOKEN');
    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query: `
      mutation($file: Upload!) {
        bulkCreateEmployees(file: $file)
      }      
      `,
        variables: {
          file: null,
        },
      })
    );

    formData.append('map', JSON.stringify({ '0': ['variables.file'] }));
    formData.append('0', file);

    return from(
      fetch(environment.url.hrmsModuleUrl, {
        method: 'POST',
        body: formData,
        headers: {
          authorization: token ? `Bearer ${token}` : '',
          'x-apollo-operation-name': 'uploadFile',
        },
      })
        .then((response) => response.json())
        .then((result) => result)
    );
  }

  getEmployeeApprovers(): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_EMPLOYEE_APPROVERS,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((result) => result.data?.getEmployeeApprovers || [])
      );
  }

  // Fetch employee types
  getEmployeeTypes(): Observable<any[]> {
    return this.http.get<any[]>('assets/json/employee-types.json');
  }

  // Fetch employee exit types
  getEmployeeExitTypes(): Observable<any[]> {
    return this.http.get<any[]>('assets/json/employeeExit-types.json');
  }

  // Fetch employee clearance status
  getEmployeeClearanceStatus(): Observable<any[]> {
    return this.http.get<any[]>('assets/employee-clearance-status.json');
  }

  // Fetch employee settlement status
  getSettlementStatus(): Observable<any[]> {
    return this.http.get<any[]>('assets/json/employee-settlement-status.json');
  }

  // Fetch employment types
  getEmploymentTypes(): Observable<any[]> {
    return this.http.get<any[]>('assets/json/employment-types.json');
  }

  // Fetch employee status
  getEmployeeStatus(): Observable<any[]> {
    return this.http.get<any[]>('assets/json/employee-status.json');
  }

  // Fetch Visa Types
  getVisaTypes(): Observable<any[]> {
    return this.http.get<any[]>('assets/json/visa-types.json');
  }

  // exporting employee data to PDF
  exportEmployeesToPDF(fields: string[]): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate<any>({
        mutation: EXPORT_EMPLOYEES_TO_PDF,
        variables: {
          fields,
        },
      })
      .pipe(map((result) => result.data?.exportEmployeesToPDF));
  }

  // exporting employee data to CSV
  exportEmployeesToCSV(fields: string[]): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate<any>({
        mutation: EXPORT_EMPLOYEES_TO_CSV,
        variables: {
          fields,
        },
      })
      .pipe(map((result) => result.data?.exportEmployeesToCSV));
  }

  // exporting employee data to CSV
  exportEmployeesToXLSX(fields: string[]): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate<any>({
        mutation: EXPORT_EMPLOYEES_TO_XLSx,
        variables: {
          fields,
        },
      })
      .pipe(map((result) => result.data?.exportEmployeesToXlsx));
  }

  //create portal access
  createPortalAccess(createPortalUserInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: CREATE_PORTAL_ACCESS,
      variables: {
        createPortalUserInput,
      },
    })
  }

  createEmployeeApprover(createEmployeeApproverInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: CREATE_EMPLOYEE_APPROVER,
      variables: {
        createEmployeeApproverInput,
      },
    });
  }

  updateEmployeeApprover(updateEmployeeApproverInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: UPDATE_EMPLOYEE_APPROVER,
      variables: {
        updateEmployeeApproverInput,
      },
    });
  }
}
