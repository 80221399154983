
    <section class="cmn-innerpage-wrapper purchase-order-details">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search"  (input)="onSearch($event)"/>
            </div>
            <!-- <button class="cmn-upload-btn" (click)="openDialog()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload</span>
            </button> -->
            <button class="cmn-add-btn" *appHasPermission="['14', Permission.Create]" (click)="navigateTo('purchase-order/purchase-order-new')">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New  Purchase
            </button>
            <!-- <button class="cmn-filter-btn" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }">
                <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" /> -->
                <!-- <span *ngIf="selectedCount > 0">{{ selectedCount }}</span> -->
            <!-- </button> -->
            <!-- Filter -->
            <!-- <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <form>
                    <h6 class="cmn-popup-heading">
                        Filter <span>Clear All</span>
                    </h6>
                    <div class="filter-inner-content">
                        <h2>Branch</h2>
                        <div class="check-wrapper">
                            <div class="check-box">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" />
                                    test
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="submit">Apply</button>
                    </div>
                </form>
            </div> -->
            <!-- Filter -->
        </div>
        <div class="sub-head">
            <ul>
                <li class="main">All Orders</li>
                <li class="edit" (click)="navigateToSendMail()"><img src="assets/images/icons/icon-send.svg" alt="Mail"> Send to mail</li>
                <li class="edit" (click)="toggleComments($event)"><img src="assets/images/icons/icon-comment.svg" alt="Comment"> Comments & History</li>
                <li class="edit" *ngIf="orderDetails?.purchaseStatus === 'DRAFT'" (click)="onEdit()">
                    <ng-container *appHasPermission="['14', Permission.Edit]">
                        <img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit" />
                        Edit
                      </ng-container>
                </li>
                <li class="edit" (click)="convertToBill(purchaseOderId)"><img src="assets/images/icons/icon-record.svg" alt="Record" > Convert to Bill</li>
                <li class="edit" (click)="onViewDocument()"> <img src="assets/images/icons/icon-pdf.svg" alt="PDF" />  PDF </li>
            </ul>

            
             <!-- Comments Dropdown -->
             <div [ngClass]="{ show: isCommentsOpen }" class="common-dropdown filter-position" #dropdown2>
                <button class="close-dropdown" (click)="toggleComments($event)"></button>
                <form [formGroup]="commentForm" (ngSubmit)="onCreateComment()">
                    <h6 class="cmn-popup-heading">
                        Comments
                    </h6>
                    <div class="filter-inner-content">
                        <div class="form-wrapper">
                            <mat-form-field>
                                <textarea matInput placeholder="Add Your Comment Here..." formControlName="comment" ></textarea>
                            </mat-form-field>
                        </div>
                        <div class="btn-wrapper">
                            <button class="cmn-apply-btn" type="submit">Comment</button>
                        </div>
                        <div class="timelinne-wrapper">
                            <!-- ngFor -->
                            <div class="timeline-box" *ngFor="let comment of allComments">
                                <div class="timeline-head">
                                  <ul>
                                    <li>{{ comment?.employee?.firstName || "--" }}</li>
                                    <li>{{ comment?.createdAt | date : "dd MMM yyyy" }}</li>
                                  </ul>
                                  <h3>{{ comment?.createdAt | date : "hh:mm a" }}</h3>
                                </div>
                                <div class="timeline-content">
                                  <h4>{{ comment?.note || "--" }}</h4>
                                </div>
                              </div>
                            <!-- ngFor -->
                        </div>
                    </div>
                </form>
            </div>
            
            <div class="mat-menu-wrapper">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *ngIf="orderDetails?.purchaseStatus != 'ISSUED'" mat-menu-item (click)="onMarkAsIssued(purchaseOderId)">
                        <span>Mark as issued</span>
                    </button>
                    <button mat-menu-item (click)="onMarkAsCanceled(purchaseOderId)">
                        <span>Mark as canceled</span>
                    </button>
                    <!-- <button mat-menu-item (click)="onDelete(purchaseOderId)">
                        <span>Delete</span>
                    </button> -->
                </mat-menu>
            </div>
        </div>
        <div class="manual-journal-details-tab-wrapper">
            <div class="tab-left">
                <div class="tab-box-main-wrapper">
                    <button *ngFor="let order of allOrders; let i = index" class="tab-custom-button"
                    [class.active]="selectedTabIndex === i" (click)="selectTab(i)">
                    <div class="inn-box">
                        <h2>{{ order?.vendor?.displayName || "---" }}</h2>
                        <h3> {{ order?.purchaseOrderNo || "---" }} </h3>
                    </div>
                    <div class="inn-box">
                        <h6>{{ order?.billDate ? (order?.billDate | date: 'dd/MM/yyyy') : "---" }}</h6>
                        <h5>{{order?.currencyData?.currencyCode}} <span>{{ order?.totalPrice | number : '1.2-2' }}</span></h5>
                    </div>
                    <div class="inn-box">
                        <h4 [ngClass]="{
                            'grey': order?.purchaseStatus === 'DRAFT',
                            'blue': order?.purchaseStatus === 'ISSUED',
                            'green': order?.purchaseStatus === 'CLOSED',
                            'red': order?.purchaseStatus === 'CANCELLED'
                          }">
                            {{ order?.purchaseStatus || "---" }}
                        </h4>
                    </div>
                </button>
                </div>
            </div>
            <div class="manual-journal-details-content-wrapper">
                <div  *ngIf="OrderData && allOrders.length > 0">
                    <div class="chart-tab-content">
                        <div class="tab-inner">
                            <div class="note-header">
                                <div class="left-set">
                                    <div class="details-box">
                                        <h3>To,</h3>
                                        <h3>{{ orderDetails?.vendor?.displayName || "___" }}</h3>
                                    </div>
                                    <h4>{{ orderDetails?.vendor?.addresses?.addressLine1 }}</h4>
                                    <!-- <h3>{{ orderDetails?.vendor?.addresses?.addressLine2  }}</h3> -->
                                    <h3>{{ orderDetails?.vendor?.addresses?.country }} </h3>
                                    <h3>Vendor TRN number: {{ orderDetails?.TRNno || "NA" }}</h3>
                                    <h3>Phone: {{ orderDetails?.vendor?.phone || "NA" }}</h3>
                                    <h3>Email: {{ orderDetails?.vendor?.email || "NA" }}</h3>
                                </div>
                                <div class="right-set">
                                    <h2>PURCHASE ORDER</h2>
                                    <h3>LPO No: {{ orderDetails?.purchaseOrderNo || "___" }}</h3>
                                    <h3>Date: {{ orderDetails?.billDate | date: 'dd/MM/yyyy' : 'UTC' }}</h3>
                                    <h3>Reference: {{ orderDetails?.reference || "___" }}</h3>
                                    <h3>Munawel TRN: 100291397600003</h3>
                                    <h3>Project: {{ orderDetails?.workorderData?.workOrderNo  || "___" }}-{{ orderDetails?.project?.projectName  || "___" }}  </h3>
                                </div>
                            </div>
                            <div class="journal-details">
                                <div class="left-set">
                                    
                                    <!-- <div class="details-box">
                                        <h4>Deliver To</h4>
                                        <h5>:</h5>
                                        <h6>{{ orderDetails?.shippingAddressData?.attention }}</h6>
                                        <h6>{{ orderDetails?.shippingAddressData?.addressLine1}}</h6>
                                        <h6>{{ orderDetails?.shippingAddressData?.addressLine2 }}</h6>
                                        <h6>{{ orderDetails?.shippingAddressData?.city }}</h6>
                                        <h6>{{ orderDetails?.shippingAddressData?.state }}</h6>
                                        <h6>{{ orderDetails?.shippingAddressData?.country }}</h6>
                                    </div> -->
                                </div>
                                <!-- <div class="right-set">
                                    <div class="details-box">
                                        <h4>Expiry Date</h4>
                                        <h5>:</h5>
                                        <h6>14/08/24</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Reference</h4>
                                        <h5>:</h5>
                                        <h6>PO-5315615</h6>
                                    </div>
                                </div> -->
                            </div>

                            <!-- <div class="crdit-note-page-table">
                                <table>
                                    <tr>
                                        <th>Sl.No</th>
                                        <th>Description</th>
                                        <th>Qty</th>
                                        <th>Unit</th>
                                        
                                        <th>Rate</th>
                                        <th>Gross Price</th>
                                        <th>Discount</th>
                                        <th>Net Price</th>

                                        <th>VAT &#64; (5%)</th>
                                        <th>Amount</th>
                                    </tr>
                                    <tr>
                                        <td colspan="10" style="text-align: center;text-transform: uppercase;">Subject: {{ orderDetails?.subject || "___" }}</td>
                                    </tr>
                                    <tr *ngFor="let item of orderDetails?.purchaseItems; index as i">
                                        <td>{{ i + 1 }}</td>
                                        <td class="description-width">{{ item?.item?.itemName || "" }}</td>
                                        <td>{{ item?.quantity || "" }}</td>
                                        <td>{{ item?.unit || "" }}</td>
                                       
                                        <td>{{ item?.rate | number: '1.2-2' : "" }}</td>
                                        <td>{{ (item?.rate * item?.quantity) | number: '1.2-2' 
                                        : "" }}</td>
                                        <td>{{ item?.discount  | number: '1.2-2' : "" }}</td>

                                        <td>{{ item?.rate * item?.quantity - item?.discount  | number: '1.2-2' : "" }}</td>

                                        <td>
                                            {{  item?.vat }}
                                          </td>
                                          
                                        <td>
                                            {{ 
                                              ((item?.rate * item?.quantity - item?.discount) * 5) / 100 + 
                                              (item?.rate * item?.quantity - item?.discount)  | number: '1.2-2' : "" 
                                            }}
                                          </td>
                                       
                                        

                                    </tr>
                                </table>
                            </div> -->



                            <div class="crdit-note-page-table">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Sl.No</th>
                                      <th>Description</th>
                                      <th>Qty</th>
                                      <th>Unit</th>
                                      <th>Rate</th>
                                      <th>Gross Price</th>
                                      <th>Discount</th>
                                      <th>Net Price</th>
                                      <th>Adjusted</th>
                                      <th>VAT &#64; (5%)</th>
                                      <th>Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                        <td colspan="10" style="text-align: center;text-transform: uppercase;">Subject: {{ orderDetails?.subject || "___" }}</td>
                                    </tr>
                                    <ng-container *ngFor="let subject of objectKeys(categorizedPurchaseItems); let subjectIndex = index">
                                      <!-- Subject Header -->
                                      <tr *ngIf="subject !== 'NoSubject'">
                                        <td colspan="10" style="text-transform: uppercase;">
                                          {{ subject || 'NoSubject' }}
                                        </td>
                                      </tr>
                              
                                      <!-- Items for Each Subject -->
                                      <ng-container *ngFor="let item of categorizedPurchaseItems[subject]; let itemIndex = index">
                                        <tr>
                                          <!-- Calculate Sl.No as (subjectIndex + itemIndex + 1) -->
                                          <td>{{ getSerialNo(subjectIndex, itemIndex) }}</td>
                                          <td class="description-width">{{ item?.item?.itemName || '' }}</td>
                                          <td>{{ item?.quantity || '' }}</td>
                                          <td>{{ item?.unit || '' }}</td>
                                          <td>{{ item?.actualRate | number: '1.2-2' }}</td>
                                          <td>{{ (item?.actualRate * item?.quantity) | number: '1.2-2' }}</td>
                                          <td>{{ item?.discount | number: '1.2-2' }}</td>
                                          <td>{{ item?.actualRate * item?.quantity - item?.discount | number: '1.2-2' }}</td>
                                          <td>{{ item?.rate * item?.quantity - item?.discount | number: '1.2-2' }}</td>
                                          <td>{{ (item?.vat)?.toFixed(2) }}</td>
                                          <!-- <td>
                                            {{
                                              (item?.rate * item?.quantity - item?.discount) +
                                              ((item?.rate * item?.quantity - item?.discount) * item?.vat) / 100 | number: '1.2-2'
                                            }}
                                          </td> -->
                                          <td>{{ item.amount?.toFixed(2)}} </td>
                                        </tr>
                                      </ng-container>
                                    </ng-container>
                                  </tbody>
                                </table>
                              </div>
                              
                            

                              
                            
                            <div class="page-footer-content">
                                
                                <div class="right-set">
                                    <div class="details-box">
                                        <h4>Sub total</h4>
                                        <h5>:</h5>
                                        <h6>{{orderDetails?.currencyData?.currencyCode}} {{ orderDetails?.subTotal != null ? (orderDetails.subTotal | number:'1.2-2') : "____" }}</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Discount (-)</h4>
                                        <h5>:</h5>
                                        <h6> {{orderDetails?.currencyData?.currencyCode}} {{ (orderDetails?.subTotal + orderDetails?.tax) - orderDetails?.totalPrice | number:'1.2-2'}}</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>VAT(5%)</h4>
                                        <h5>:</h5>
                                        <h6> {{orderDetails?.currencyData?.currencyCode}} {{ orderDetails?.tax != null ? (orderDetails.tax | number:'1.2-2') : "____"}}</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Total</h4>
                                        <h5>:</h5>
                                        <h6>{{orderDetails?.currencyData?.currencyCode}} {{ orderDetails?.totalPrice != null ? (orderDetails.totalPrice | number:'1.2-2') : "___"}}</h6>
                                    </div>
                                    <!-- <div class="details-box">
                                        <h4>Credits Used</h4>
                                        <h5>:</h5>
                                        <h6>PO-5315615</h6>
                                    </div> -->
                                    <!-- <div class="details-box bold">
                                        <h4>Credits Remaining</h4>
                                        <h5>:</h5>
                                        <h6>AED 590 </h6>
                                    </div> -->
                                </div>
                            </div>
                            <div class="termsandconditions">
                                <!-- <div class="details-box">
                                    <h4>Customer Notes :</h4>
                                    <h6>Looking forward for your business.</h6>
                                </div> -->
                                <div class="details-box" *ngIf="paymentTerms && paymentTerms.length > 0">
                                    <h4>Payment Terms:</h4>
                                    <ul style="padding-right: 5px;">
                                        <li *ngFor="let note of paymentTerms">
                                          {{ note }}
                                        </li>
                                      </ul>
                                </div>
                                <div class="details-box" *ngIf="termsAndConditions && termsAndConditions.length > 0">
                                    <h4>Terms And Conditions:</h4>
                                    <!-- <h6>{{orderDetails?.customerNote || "NA"}}</h6> -->
                                   <ul style="padding-right: 5px;">
                                        <li *ngFor="let note of termsAndConditions">
                                          {{ note }}
                                        </li>
                                      </ul>
                                </div>

                                <div class="details-box">
                                    <h4>Prepared By:</h4>
                                    <h6 >{{orderDetails?.createdByName || "__"}}</h6>
                                </div>
                            </div>

                            <!-- <p class="signature">Authorized Signature <span></span></p> -->
                            

                            <span class="published-note" *ngIf="orderDetails?. purchaseStatus === 'CLOSED'"><img
                                src="assets/images/img-closed.svg" alt="canceled"></span>
                        <span class="published-note" *ngIf="orderDetails?. purchaseStatus === 'DRAFT'"><img
                                src="assets/images/img-draft.svg" alt="Draft"></span>
                                <span class="published-note" *ngIf="orderDetails?. purchaseStatus === 'CANCELLED'"><img
                                    src="assets/images/img-cancelled.svg" alt="canceled"></span>
                            <span class="published-note" *ngIf="orderDetails?. purchaseStatus === 'ISSUED'"><img
                                    src="assets/images/img-issued.svg" alt="Draft"></span>
                        </div>
                    </div>
                </div>
                <!-- <div *ngIf="selectedTabIndex === 1">
                    <div class="chart-tab-content">
                        content 2
                    </div>
                </div> -->
                <!-- <div *ngIf="selectedTabIndex === 2">
                    <div class="chart-tab-content">
                        content 3
                    </div>
                </div> -->
                <!-- <div *ngIf="selectedTabIndex === 3">
                    <div class="chart-tab-content">
                        content 4
                    </div>
                </div> -->
                <!-- <div *ngIf="selectedTabIndex === 4">
                    <div class="chart-tab-content">
                        content 5
                    </div>
                </div> -->
            </div>
        </div>
    </section>
