<div class="profile-inner-main-wrapper">
  <div class="details-wrapper">
    <h2>First Name</h2>
    <h3>{{ employeeData?.firstName }}</h3>
  </div>
  <div class="details-wrapper" *ngIf="employeeData?.middleName">
    <h2>Middle Name</h2>
    <h3>{{ employeeData?.middleName }}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Last Name</h2>
    <h3>{{ employeeData?.lastName }}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Mobile Number</h2>
    <h3>{{employeeData?.phoneStdCode}} {{employeeData?.phoneNumber}}</h3>
  </div>
  <div class="details-wrapper" *ngIf="employeeData?.alternatePhoneNumber">
    <h2>Home Country Number</h2>
    <h3>{{employeeData?.nativeStdCode}} {{employeeData?.alternatePhoneNumber}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Email Address</h2>
    <h3>{{employeeData?.email}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Date of Birth</h2>
    <h3>{{ employeeData?.dateOfBirth | date: 'MMMM d, yyyy' }}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Marital Status</h2>
    <h3>{{ employeeData?.maritalStatus }}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Gender</h2>
    <h3>{{employeeData?.gender}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Nationality</h2>
    <h3>{{employeeData?.nationality}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Address</h2>
    <h3>{{employeeData?.address}}</h3>
  </div>
  <div class="details-wrapper" *ngIf="employeeData?.nativeAddress">
    <h2>Native Address</h2>
    <h3>{{employeeData?.nativeAddress}}</h3>
  </div>
</div>
