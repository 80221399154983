import { gql } from 'apollo-angular';

export const CREATE_CURRENCY = gql`
  mutation createCurrency($createCurrencyInput: CreateCurrencyInput!) {
    createCurrency(createCurrencyInput: $createCurrencyInput) {
      id
      currencyCode
      currencyName
      currencySymbol
      exchangeRate
      status
      isPrimary
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const UPDATE_CURRENCY_ADJUSTMENT = gql`
  mutation updateCurrency($updateCurrencyInput: UpdateCurrencyInput!) {
    updateCurrency(updateCurrencyInput: $updateCurrencyInput) {
      id
      currencyCode
      currencyName
      currencySymbol
      exchangeRate
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const REMOVE_CURRENCY_BY_ID = gql`
  mutation removeCurrency($id: Int!) {
    removeCurrency(id: $id)
  }
`;
