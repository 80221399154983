import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { PayrollListTableComponent } from '../../shared/components/payroll-list-table/payroll-list-table';

@Component({
  selector: 'app-payroll-list',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    PayrollListTableComponent
  ],
  templateUrl: './payroll-list.component.html',
  styleUrl: './payroll-list.component.scss'
})
export class PayrollListComponent {
  selectedMonth: any;
  selectedYear: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    ){}

    ngOnInit(){
      this.route.queryParams.subscribe(params => {
        this.selectedMonth = params['month'];
        this.selectedYear = params['year'];
      });
    }

    navigateTo(route: string) {
      this.router.navigate([route]);
    }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

}
