import { Component, Inject, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PayrollListTableComponent } from '../../shared/components/payroll-list-table/payroll-list-table';
import { PayrollService } from '../../features/payroll/services/payroll.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApexFill, NgApexchartsModule } from "ng-apexcharts";
import { ApexNonAxisChartSeries, ApexResponsive, ApexChart } from "ng-apexcharts";
import { initiatePayrollDialog } from './upload-payroll.component';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};

@Component({
  selector: 'app-payroll',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatTabGroup,
    MatDialogModule,
    MatTabsModule,
    MatButtonToggleModule,
    PayrollListTableComponent,
    DatePipe,
    ReactiveFormsModule,
    FormsModule,
    NgApexchartsModule,
    PermissionDirective
  ],
  templateUrl: './payroll.component.html',
  styleUrl: './payroll.component.scss',
  providers: [DatePipe]
})
export class PayrollComponent {
  @ViewChild(PayrollListTableComponent) payrollDetailData!: PayrollListTableComponent;
  currentDate: any = null;
  startDate: any = null;
  endDate: any = null;
  payrollData: any;
  month: any = null;
  public months: any;
  selectedTabIndex: number;
  statusForm: FormGroup;
  filter: any;
  Permission = Permission;

  readonly dialog = inject(MatDialog);
  selectedMonth: any;
  selectedYear: any;
  totalEmployees: any;
  lockPayroll: any = null;
  lockInputs: any = null;

  public chartOptions: {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    labels: string[];
    responsive: ApexResponsive[];
    fill: ApexFill; // Ensure the 'fill' type is here
  };
  netPay: any;
  grossPay: any;
  deduction: any;
  updatePayrollData: any;
  updatePayrollInput: any;
  isDropdownOpen = false;
  isInitiated: boolean = false;
  isProcessedPayroll: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private payrollService: PayrollService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
  ) {
  }

  // Function to calculate dynamic colors
  getDynamicColors(series: number[]): string[] {
    return ['#8377D0', '#BC96D9', '#A463A3'];
  }

  ngOnInit() {
    this.getTabList();
    this.selectedYear = this.datePipe.transform(this.currentDate, 'yyyy');
    this.selectedMonth = this.datePipe.transform(this.currentDate, 'MMMM');
    this.payrollDetails();
    this.startDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
    this.endDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
    this.statusForm = this.fb.group({
      pending: [false],
      completed: [false]
    });

    this.route.queryParams.subscribe(params => {
      if (params['month'] && params['year']) {
        this.selectedMonth = params['month'];
        this.selectedYear = params['year'];
        this.payrollDetails();
        // Find the matching tab index
        const selectedTab = this.months.findIndex(
          (month: any) => month.longName?.toLowerCase() === this.selectedMonth?.toLowerCase() &&
            month.year?.toString() === this.selectedYear
        );
        if (selectedTab !== -1) {
          this.selectedTabIndex = selectedTab;
        }

      }
    });
  }

  getTabList() {
    const currentDate = new Date();
    this.currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const startMonth = new Date(currentDate.setMonth(currentDate.getMonth() - 2));
    const endMonth = new Date(currentDate.setMonth(currentDate.getMonth() + 12));

    this.months = [];
    for (let d = new Date(startMonth); d <= endMonth; d.setMonth(d.getMonth() + 1)) {
      const isCurrentMonth = d.getMonth() === currentMonth && d.getFullYear() === currentYear;
      this.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
      this.endDate = new Date(d.getFullYear(), d.getMonth() + 1, 0);

      this.months.push({
        name: d.toLocaleString('default', { month: 'short' }),
        longName: d.toLocaleString('default', { month: 'long' }),
        year: d.getFullYear(),
        isLocked: d < new Date(),
        isUnlocked: isCurrentMonth,
        startDate: this.startDate,
        endDate: this.endDate
      });

      if (isCurrentMonth) {
        this.selectedTabIndex = this.months.length - 1;
      }
    }
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  payrollDetails() {
    if (this.selectedMonth && this.selectedYear) {
      console.log(this.selectedMonth, this.selectedYear);
      this.payrollService.getPayrollByMonthYear(Number(this.selectedYear), this.selectedMonth).subscribe({
        next: (data: any) => {
          this.payrollData = data;
          this.netPay = data?.netPay || 0,
            this.grossPay = data?.grossPay || 0,
            this.deduction = data?.deductions || 0,
            this.lockPayroll = data?.lockPayroll ?? null;
          this.lockInputs = data?.lockInputs ?? null;
          const netPay = data?.netPay ? data?.netPay : 0;
          const grossPay = data?.grossPay ? data?.grossPay : 0;
          const deduction = data?.deductions ? data?.deductions : 0;

          console.log('lockInput', this.lockInputs);
          console.log('lockPayroll', this.lockPayroll);
          
          if (netPay || grossPay || deduction) {
            this.chartOptions = {
              series: [netPay, grossPay, deduction],
              chart: {
                type: 'donut',
                width: 400,
              },
              fill: {
                colors: this.getDynamicColors([netPay, grossPay, deduction]),
              },
              labels: ['Net Pay', 'Gross Pay', 'Deduction'],
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
              ],
            } as any;
          } else {
            this.chartOptions = {
              series: [],
              chart: {
                type: 'donut',
                width: 400,
              },
              fill: { colors: [] },
              labels: [],
              responsive: []
            } as any;
          }

        },
        error: (error: any) => {
          this.payrollData = [];
          this.chartOptions = {
            series: [],
            chart: {
              type: 'donut',
              width: 400,
            },
            fill: { colors: [] },
            labels: [],
            responsive: []
          } as any;

          this.lockPayroll = null;
          this.lockInputs = null;
          this.toastr.warning(error?.message);
        }
      });
    }
  }

  applyFilter() {
    const statusFilter = [];

    if (this.statusForm.value.pending) {
      statusFilter.push('Pending');
    }
    if (this.statusForm.value.completed) {
      statusFilter.push('Completed');
    }
    this.filter = statusFilter.length ? { statusFilter } : {};
  }

  onTabChange(event: any) {
    const selectedIndex = event.index;
    const selectedMonthData = this.months[selectedIndex];

    this.selectedMonth = selectedMonthData.longName;
    this.selectedYear = selectedMonthData.year;
    this.startDate = selectedMonthData.startDate;
    this.endDate = selectedMonthData.endDate;
    this.payrollDetails();
  }

  onToggleLockInputs(value: boolean) {
    if (this.lockInputs !== null) {
      this.lockInputs = value;
      this.updatePayrollInput = {
        id: this.payrollData.id,
        lockInputs: value,
      };

      this.payrollService.updatePayroll(this.updatePayrollInput).subscribe({
        next: (data) => {
          if (data) {
            const message = value ? 'Payroll input locked successfully' : 'Payroll input unlocked successfully';
            this.toastr.success(message);
          }
        },
        error: (error) => {
          this.toastr.error('Error in updating payroll input', 'Error');
        }
      });
    }
  }

  onToggleLockPayroll(value: boolean) {
    if (this.lockInputs === true && this.lockPayroll !== null) {
      this.lockPayroll = value;

      this.updatePayrollData = {
        id: this.payrollData.id,
        lockPayroll: value
      };

      this.payrollService.updatePayroll(this.updatePayrollData).subscribe({
        next: (data) => {
          if (data) {
            const message = value ? 'Payroll locked successfully' : 'Payroll unlocked successfully';
            this.toastr.success(message);
          }
        },
        error: (error) => {
          this.toastr.error('Error in updating payroll', 'Error');
        }
      });
    } else if (!this.lockInputs) {
      this.toastr.error('You must lock Payroll Inputs before locking Payroll');
    }
  }

  openDialog() {
    if (this.lockInputs) {
      const dialogRef = this.dialog.open(PayrollPrecessingDialog, {
        data: {
          id: this.payrollData.id,
          year: this.payrollData.year,
          month: this.payrollData.month,
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (this.payrollDetailData?.employeeSalaryDetails) {
            this.payrollDetailData.employeeSalaryDetails();
            this.payrollDetails();
            this.isProcessedPayroll = true;
            this.dialog.open(PayrollPrecessingDialog, {
              data: {
                isSuccess: true,
              }
            })
          }
          else {
            console.error('Employee payroll status not updated');
          }
        }
      });
    } else {
      this.toastr.warning('Please lock the payroll input & payroll to process.', 'Warning')
    }
  }

  initiatePayroll() {
    const dialogRef = this.dialog.open(initiatePayrollDialog, {
      disableClose: true,
      data: {
        id: this.payrollData.id,
        year: this.selectedYear,
        month: this.selectedMonth,
        isInitiated: false,
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.payrollDetailData) {
          this.payrollDetailData.employeeSalaryDetails();
          this.payrollDetails();
          this.isInitiated = true;
        }
        else {
          console.error('Employee payroll status not updated');
        }
      }
    });
  }

  navigateToPayrollList() {
    if (this.selectedMonth && this.selectedYear) {
      this.router.navigate(['/payroll/payroll-list'], {
        queryParams: { month: this.selectedMonth, year: this.selectedYear }
      });
    }
  }
}


@Component({
  selector: 'payroll-processing-dialog',
  templateUrl: 'payroll-processing-dialog.html',
  styleUrls: ['./payroll.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatProgressBarModule,
  ],
})
export class PayrollPrecessingDialog {
  processData: any;
  lockEmployeePayroll: any;
  selectedMonth: any;
  selectedYear: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private payrollService: PayrollService,
    private toastr: ToastrService,
    private router: Router,
    public dialogRef: MatDialogRef<PayrollPrecessingDialog>,
    // public dialogRefIndividual: MatDialogRef<PayrollPrecessingDialog>,
  ) { }

  ngOnInit() {
    this.onProcessPayroll();
  }

  onProcessPayroll() {
    if (this.data && !this.data.payrollId && !this.data?.isSuccess) {
      this.processData = {
        id: this.data.id,
        year: Number(this.data.year),
        month: this.data.month,
      }

      this.payrollService.processPayroll(this.processData).subscribe({
        next: (data) => {
          if (data) {
            this.dialogRef.close(data);
          }
        },
        error: (error) => {
          this.dialogRef.close();
          this.toastr.error(error, 'Error');
        }
      });
    }
    if (this.data && this.data.payrollId) {
      const id = this.data?.id
      const employeeId = this.data?.employeeId
      const payrollId = this.data?.payrollId
      this.selectedMonth = this.data?.month
      this.selectedYear = this.data?.year
      this.payrollService.processEmployeePayroll(id, employeeId, payrollId).subscribe({
        next: (data) => {
          // if (data) {

          this.dialogRef.close('success');
          // }
        },
        error: (error) => {
          this.dialogRef.close();
          this.toastr.error(error, 'Error');
        }
      });
    }
  }

  onCancel() {
    if (this.data.month && this.data.year) {
      this.router.navigate(['/payroll/'], {
        queryParams: { month: this.data.month, year: this.data.year }
      });
    }
    this.dialogRef.close();
  }

}