<section class="accordion-section">

    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Invoices</mat-panel-title>
                <mat-panel-description>
                    <button class="add-btn" *appHasPermission="['8', Permission.Create]" 
             
                    (click)="navigateTo('/invoice/invoice-add')"
                    >
                        <img src="assets/images/icons/icon-add-color.svg" alt="Add" /> New
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="table-wrapper" *ngIf="partnerDetails?.partner?.invoices.length > 0; else noData">
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Invoice#</th>
                            <th>Order No</th>
                            <th>Customer Name</th>
                            <th>Amount</th>
                            <th>Balance</th>
                            <th>status</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let partnerDetail of partnerDetails?.partner?.invoices">
                        <tr *ngIf="partnerDetail">
                            <td>{{partnerDetail?.invoiceDate | date: 'dd/MM/YYYY'}}</td>
                            <td>
                                <!-- {{partnerDetail?.invoiceNo || '--' }} -->
                                <a (click)="navigateToInvoiceDetails(partnerDetail?.id)" class="invoice-link" >
                                    {{partnerDetail?.invoiceNo || '--' }}
                                </a>
                            </td>
                            <td>{{partnerDetail?.orderNo || '--' }}</td>
                            <td>{{partnerDetails?.partner?.displayName || '--' }}</td>
                            <!-- <td>{{partnerDetail?.totalPrice ? 'AED ' + partnerDetail?.totalPrice  : '--'}}</td>
                            <td>{{partnerDetail?.balance ? 'AED ' + partnerDetail?.balance : '--' }}</td> -->
                            <td>{{ partnerDetail?.totalPrice ? 'AED ' + (partnerDetail?.totalPrice | number:'1.2-2') : '--' }}</td>
                            <td>{{ partnerDetail?.balance ? 'AED ' + (partnerDetail?.balance | number:'1.2-2') : '--' }}</td>
                            <td class="redTxt">{{partnerDetail?.paymentStatus || '--'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ng-template #noData>
                <h4>No Invoices Found</h4>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Invoice Payment</mat-panel-title>
                <mat-panel-description>
                    <button class="add-btn" *appHasPermission="['9', Permission.Create]"
                    (click)="navigateTo('/payment-received/payment-new')" >
                        <img src="assets/images/icons/icon-add-color.svg" alt="Add" /> New
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="table-wrapper" *ngIf="partnerDetails?.partner?.payments.length > 0; else noData2">
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Payment#</th>
                            <!-- <th>Reference No</th> -->
                            <th>Total Amount</th>
                            <!-- <th>Bank Charge</th> -->
                            <th>Payment Mode</th>
                            <th>Deposit To</th>
                        </tr>
                    </thead>
                    <tbody  *ngFor="let partnerDetail of partnerDetails?.partner?.payments">
                        <tr>
                            <td>{{partnerDetail?.paymentDate | date: 'dd/MM/YYYY' }}</td>
                            <td>
                                <!-- {{partnerDetail?.paymentNo || '--'}} -->
                                <a (click)="navigateToPaymentDetails(partnerDetail?.id,partnerDetail?.branchId)" class="invoice-link" >
                                    {{partnerDetail?.paymentNo || '--'}}
                                </a>
                            </td>
                            <!-- <td>{{partnerDetail?.referenceNo || '--'}}</td> -->
                            <td>{{partnerDetail?.totalAmount ? 'AED ' + (partnerDetail?.totalAmount | number:'1.2-2') : '--'}}</td>
                            <!-- <td>{{partnerDetail?.bankCharge ? 'AED ' + (partnerDetail?.bankCharge | number:'1.2-2') : '--'}}</td> -->
                            <td>{{partnerDetail?.paymentMode || '--'}}</td>
                            <td>{{partnerDetail?.deposit?.accountName || '--'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ng-template #noData2>
                <h4>No Invoice Payment Found</h4>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Journals</mat-panel-title>
                <mat-panel-description>
                    <button class="add-btn" *appHasPermission="['31', Permission.Create]" [routerLink]="['/manual-journals/add']">
                        <img src="assets/images/icons/icon-add-color.svg" alt="Add" /> New
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="table-wrapper" *ngIf="partnerDetails?.partner?.JournalEnteries.length > 0; else noData3">
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Journal Id</th>
                            <th>Description</th>
                            <th>Debit</th>
                            <th>Credit</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let partnerDetail of partnerDetails?.partner?.JournalEnteries">
                        <tr>
                            <td>{{partnerDetail?.createdAt | date: 'dd/MM/YYYY' }}</td>
                            <td>{{partnerDetail?.journalId || '--'}}</td>
                            <td>{{partnerDetail?.description || '--' }}</td>
                            <td>{{partnerDetail?.debit ? 'AED ' + (partnerDetail?.debit | number:'1.2-2') : '--'}}</td>
                            <td>{{partnerDetail?.credit ? 'AED ' +( partnerDetail?.credit | number:'1.2-2') : '--'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ng-template #noData3>
                <h4>No Journals Found</h4>
            </ng-template>
        </mat-expansion-panel>


    </mat-accordion>
</section>