import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../../../../shared/components/confirm-dialog/confirm-dialog';
import { VacationService } from '../../../../features/vacation/services/vacation.service';
import { Router } from '@angular/router';
import { ImageService } from '../../../../shared/services/image.service';
import { EditVacationDialog } from '../../../employee-details/components/vacation/components/vacation-history/vacation-history.component';

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [
    CommonModule,
    AgGridAngular,
  ],
  templateUrl: './summary.component.html',
  styleUrl: '../../vacation-management.component.scss'
})
export class SummaryComponent {
  @Input() searchQuery: string = '';
  @Input() filter: any = '';
  public rowData: any[] | null = null;
  filterObj: any;
  vacationData: any;

  constructor(
    private vacationService: VacationService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private route: Router,
    private imageService: ImageService,
  ) { }

  ngOnInit() {
    this.fetchVacations();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchQuery']) {
      this.fetchVacations();
    }
    if (changes['filter']) {
      this.fetchVacations();
    }
  }

  getRowHeight = (params: any) => {
    const approvers = [
      params.data?.approver1,
      params.data?.approver2,
      params.data?.approver3,
      params.data?.approver4
    ];
    const approverCount = approvers.filter((approver) => !!approver).length;
    return 40 + (approverCount > 1 ? (approverCount - 1) * 20 : 0);
  };

  columnDefs = [
    {
      headerName: 'Applied By',
      field: 'name',
      flex: 1,
      cellRenderer: (params: any) => {
        return `
                <div style="display: flex; align-items: center;">
                  <img src="${params.data.imageUrl}" alt="Profile Image" style="width: 35px; height: 35px; margin-right: 10px; border-radius: 50%; object-fit: cover;">
                  <span>${params.data.name}</span>
                </div>
              `;
      }
    },
    { headerName: 'Return Date', field: 'returnDate', flex: 1 },
    { headerName: 'Duration', field: 'duration', flex: 1 },
    { headerName: 'Total Days', field: 'totalDays', flex: 1 },
    {
      headerName: 'Approvers',
      flex: 1,
      cellRenderer: (params: any) => {
        const approver1 = params.data?.approver1 ?
          `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.approver1}</p>` : '';
        const approver2 = params.data?.approver2 ?
          `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.approver2}</p>` : '';
        const approver3 = params.data?.approver3 ?
          `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.approver3}</p>` : '';
        const approver4 = params.data?.approver4 ?
          `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.approver4}</p>` : '';

        return `
          <div>
            ${approver1}
            ${approver2}
            ${approver3}
            ${approver4}
          </div>
        `;
      },
    },
    {
      headerName: 'Status', field: 'status', flex: 1,
      cellRenderer: (params: any) => {
        const status = params.data?.status === "Approved"
          ? params.data?.vacationStatus || '--'
          : params.data?.status || '--';

        const statusClass =
          status === "Pending"
            ? 'yellow'
            : status === "Rejected"
              ? 'red'
              : status === params.data?.vacationStatus
                ? 'green'
                : '';

        return `<span class="${statusClass}">${status}</span>`;
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const editIcon = document.createElement('img');
        editIcon.src = 'assets/images/icons/icon-edit.svg';
        editIcon.style.cursor = 'pointer';
        editIcon.addEventListener('click', (event) => {
          event.stopPropagation();
          this.onEdit(params?.data?.id, params?.data?.employeeId)
        });

        const deleteIcon = document.createElement('img');
        deleteIcon.src = 'assets/images/icons/icon-delete.svg';
        deleteIcon.style.cursor = 'pointer';
        deleteIcon.addEventListener('click', (event) => {
          event.stopPropagation();
          this.onConfirmDelete(params?.data?.id)
        });

        container.appendChild(editIcon);
        container.appendChild(deleteIcon);
        return container;
      },
      floatingFilter: false,
      filter: false,
    },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
  readonly dialog = inject(MatDialog);


  fetchVacations() {
    this.filterObj = { search: this.searchQuery}
    if (Array.isArray(this.filter) && this.filter.length > 0) {
      this.filterObj.status = this.filter;
    }
    this.vacationService.fetchVacations(this.filterObj).subscribe({
      next: async (data) => {
        this.rowData = await Promise.all(
          data
            .map(async (data: any) => {
              const approvers = data?.employee?.employeeApprovers?.[0] || null;
              let profilePicture;
              if (data?.employee?.profilePicture) {
                try {
                  profilePicture = await this.imageService.getImageAsBase64(
                    data?.employee.profilePicture
                  );
                } catch (error) {
                  profilePicture = 'assets/images/dummy-profile-image.jpg';
                }
              } else {
                profilePicture = 'assets/images/dummy-profile-image.jpg';
              }
              return {
                id: data?.id || '--',
                employeeId: data?.employeeId || '--',
                name: data?.employee?.firstName + ' ' + data?.employee?.lastName || '--',
                imageUrl: profilePicture || 'assets/images/dummy-profile-image.jpg',
                totalDays: data?.totalDays + ' days' || '--',
                returnDate: this.datePipe.transform((data?.endDate), 'MMM d') || '--',
                status: (data?.status === 'Pending' || data?.status === 'Rejected')
                  ? data?.status
                  : (data?.status === 'Approved' ? data?.vacationStatus : '--'),
                duration: this.datePipe.transform((data?.startDate), 'MMM d') + ' - ' +
                  this.datePipe.transform((data?.endDate), 'MMM d') || '--',
                approver1: approvers?.levelOneData ? approvers.levelOneData.firstName + ' ' + approvers.levelOneData.lastName : null,
                approver2: approvers?.levelTwoData ? approvers.levelTwoData.firstName + ' ' + approvers.levelTwoData.lastName : null,
                approver3: approvers?.levelThreeData ? approvers.levelThreeData.firstName + ' ' + approvers.levelThreeData.lastName : null,
                approver4: approvers?.levelFourData ? approvers.levelFourData.firstName + ' ' + approvers.levelFourData.lastName : null
              };
            })
        )
      },
      error: (error: any) => {
        this.toastr.error(error?.message);
      },
    });
  }

  onConfirmDelete(vacationId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onDelete(vacationId);
      }
    });
  }

  fetchVacationById(id: any){
    this.vacationService.fetchVacationById(id).subscribe({
      next: (data) => {
        this.vacationData = data;
      },
      error: (error) => {
        this.toastr.error("Error in fetching leave data", 'Error')
      }
    })
  }

  onEdit(vacationId: any, employeeId: any) {
    this.fetchVacationById(vacationId);

    if(this.vacationData){
      const dialogRef = this.dialog.open(EditVacationDialog, {
        disableClose: true,
        data:{ vacationId: vacationId, employeeId: employeeId, vacationData: this.vacationData } 
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          this.fetchVacations();
          this.toastr.success('Vacation updated successfully!', 'Success');
        }
      });
    }
  }

  onDelete(vacationId: any) {
    this.vacationService.removeVacationRequest(vacationId).subscribe({
      next: (data) => {
        this.fetchVacations();
        this.toastr.success('Vacation request deleted successfully', 'Success')
      },
      error: (error) => {
        this.toastr.error('Error deleting leave request', 'Error')
      }
    })
  }

  onRowClicked(event: any) {
    const employeeId = event.data.id;
    this.route.navigate([`/vacation-approvals/${employeeId}`]);
  }
}
