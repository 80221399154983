import { Component, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { PaymentsItemService } from '../../features/payments/services/payments.service';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PAYMENT_MODES } from '../../helpers/helper-file';

@Component({
  selector: 'app-payments-made',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatInputModule, AgGridAngular],
  templateUrl: './payments-made.component.html',
  styleUrl: './payments-made.component.scss',
})
export class PaymentsMadeComponent {
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
  public rowData: any[] = [];
  private searchSubject = new Subject<string>();
  paymentModeList: any = PAYMENT_MODES;
  selectedModes: string[] = [];
  selectedCount: number = 0;

  constructor(
    private router: Router,
    private itemService: PaymentsItemService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.fetchItems();
    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchItems(searchTerm);
      });
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(UploadPaymentsMadeDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'refresh') {
        this.fetchItems();
      }
    });
  }

  onCellClicked(params: any) {
    // this.router.navigate([`payments-made/payments-made-details`]);
    const id = params.data?.id;
    this.router.navigate([`payments-made/payments-made-details`], {
      queryParams: { id: id },
    });
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  columnDefs = [
    {
      headerName: 'Date',
      field: 'data',
      flex: 1,
    },
    { headerName: 'Payment', field: 'paymentNo', flex: 1 },
    { headerName: 'Reference No', field: 'referenceNo', flex: 1 },
    { headerName: 'Customer Name', field: 'name', flex: 1 },
    { headerName: 'Invoice', field: 'invoice', flex: 1 },
    { headerName: 'Mode', field: 'mode', flex: 1 },
    { headerName: 'Amount', field: 'amount', flex: 1 },
  ];

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  fetchItems(search: string = '', filter: any = { paymentType: 'Bill' }) {
    this.itemService.fetchPayments(search, filter).subscribe({
      next: (items) => {
        this.rowData = items.map((item: any) => {
          return {
            id: item?.id,
            data:
              this.datePipe.transform(item?.paymentDate, 'MM/dd/YYYY') || '--',
            paymentNo: item?.paymentNo || '--',
            referenceNo: item?.referenceNo || '--',
            name: item?.partner?.displayName || '--',
            invoice: item?.paymentInfo?.[0]?.invoice?.invoiceNo || '--',
            mode: item?.paymentMode || '--',
            amount: item?.amount || '--',
          };
        });
        // Update pagination options based on the rowData length
        this.paginationPageSizeSelector =
          PaginationHelper.getPaginationPageSizeOptions(this.rowData);
      },
    });
  }

  onApplyFilters() {
    this.fetchItems('', {
      paymentType: 'Bill',
      paymentMode: this.selectedModes,
    });
    this.toggleDropdown();
  }

  toggleMode(mode: string) {
    if (this.selectedModes.includes(mode)) {
      this.selectedModes = this.selectedModes.filter((m) => m !== mode);
    } else {
      this.selectedModes.push(mode);
    }
    this.selectedCount = this.selectedModes?.length;
  }

  clearFilters() {
    this.selectedModes = [];
    this.fetchItems('', { paymentType: 'Bill', paymentMode: [] });
    this.toggleDropdown();
    this.selectedCount = 0;
  }
}

@Component({
  selector: 'upload-payments-made-dialog',
  templateUrl: 'upload-payments-made-dialog.html',
  styleUrls: ['./payments-made.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadPaymentsMadeDialog {
  file: any = null;
  url: any = null;

  constructor(
    private toastr: ToastrService,
    private itemService: PaymentsItemService,
    private dialogRef: MatDialogRef<UploadPaymentsMadeDialog>
  ) {}

  ngOnInit() {
    document.addEventListener('dragover', this.preventDefaultBehavior);
    document.addEventListener('drop', this.preventDefaultBehavior);
  }

  preventDefaultBehavior(event: DragEvent) {
    event.preventDefault();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer?.files.length) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }

  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.file = file;
    }
  }

  uploadDocuments(csvFile: any) {
    this.itemService.bulkCreatePayment(csvFile).subscribe({
      next: (response) => {
        if (response == true) {
          this.toastr.success('Bulk updated successfully!');
          this.dialogRef.close('refresh');
        }
      },
      error: (error) => {
        this.toastr.error(error.message, 'Bulk updated failed');
        this.dialogRef.close();
      },
    });
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  onUpload() {
    this.uploadDocuments(this.file);
  }

  ngOnDestroy() {
    document.removeEventListener('dragover', this.preventDefaultBehavior);
    document.removeEventListener('drop', this.preventDefaultBehavior);
  }
}
