import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GET_ALL_PROJECTS,
  GET_BRANCH_NAMES,
  GET_EMPLOYEES,
  GET_SINGLE_PROJECT,
} from '../graphql/queries/projects.query';
import {
  CREATE_PROJECT,
  CREATE_PROJECT_RESOURCE,
  CREATE_PROJECT_TASK,
  DELET_PROJECT,
  DELET_PROJECT_RESOURCE,
  DELET_PROJECT_TASK,
  UPDATE_PROJECT,
} from '../graphql/mutations/projects.mutation';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private apollo: Apollo) {}
  getEmployees(search: string, filter: {}): Observable<any> {
    return this.apollo
      .use('hrms')
      .query<any>({
        query: GET_EMPLOYEES,
        variables: {
          search,
          filter,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data?.getEmployees || []));
  }

  createProject(
    createProjectModuleInput: any,
    projectTasks: any,
    projectResources: any
  ): Observable<any> {
    return this.apollo
      .use('project')
      .mutate({
        mutation: CREATE_PROJECT,
        variables: {
          createProjectModuleInput: createProjectModuleInput,
          projectTasks: projectTasks,
          projectResources: projectResources,
        },
      })
      .pipe(map((result: any) => result.data.createProjectModel || []));
  }

  deleteProject(id: any): Observable<any> {
    return this.apollo
      .use('project')
      .mutate({
        mutation: DELET_PROJECT,
        variables: {
          id,
        },
      })
      .pipe(map((result: any) => result.data.deleteProject || []));
  }

  getBranches(): Observable<any[]> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({ query: GET_BRANCH_NAMES, fetchPolicy: 'network-only' })
      .valueChanges.pipe(map((result) => result?.data?.branches || []));
  }

  getAllProjects(search: any): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_ALL_PROJECTS,
        variables: { search },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.listProjects || []));
  }

  getOneProject(id: number): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_SINGLE_PROJECT,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.projectDetail || []));
  }

  updateProject(
    updateProjectModuleInput: any,
    projectTasks: any,
    projectResources: any
  ): Observable<any> {
    return this.apollo
      .use('project')
      .mutate({
        mutation: UPDATE_PROJECT,
        variables: {
          updateProjectModuleInput: updateProjectModuleInput,
          projectTasks: projectTasks,
          projectResources: projectResources,
        },
      })
      .pipe(map((result: any) => result.data.editProject || []));
  }
  createProjectResource(
    createProjectResourceModuleInput: any
  ): Observable<any> {
    return this.apollo
      .use('project')
      .mutate({
        mutation: CREATE_PROJECT_RESOURCE,
        variables: {
          createProjectResourceModuleInput: createProjectResourceModuleInput,
        },
      })
      .pipe(map((result: any) => result.data.createProjectResource || []));
  }

  createProjectTasks(createTaskInput: any): Observable<any> {
    return this.apollo
      .use('project')
      .mutate({
        mutation: CREATE_PROJECT_TASK,
        variables: {
          createTaskInput: createTaskInput,
        },
      })
      .pipe(map((result: any) => result.data.createProjectTask || []));
  }
  deleteProjectResource(id: any): Observable<any> {
    return this.apollo
      .use('project')
      .mutate({
        mutation: DELET_PROJECT_RESOURCE,
        variables: {
          id,
        },
      })
      .pipe(map((result: any) => result.data.deleteProjectResource || []));
  }
  deleteProjectTask(id: any): Observable<any> {
    return this.apollo
      .use('project')
      .mutate({
        mutation: DELET_PROJECT_TASK,
        variables: {
          id,
        },
      })
      .pipe(map((result: any) => result.data.deleteProjectTask || []));
  }
}
