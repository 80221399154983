import { Component, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { PurchaseOrdereService } from '../../features/purchase-order/services/purchase-orderservice';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CreditDebitNoteService } from '../../features/credit-debit-note/services/credit-debit-note.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-purchase-order-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    ReactiveFormsModule
  ],
  templateUrl: './purchase-order-details.component.html',
  styleUrl: './purchase-order-details.component.scss'
})
export class PurchaseOrderDetailsComponent {
  allOrders: any[] = [];
  OrderData: any = {};
  purchaseOderId: any;
  orderDetails: any;
  selectedTabIndex = 0; // Default to the first tab
  commentForm: FormGroup;
  allComments: any
  isCommentsOpen = false;
  public loading: boolean = true;
  private searchSubject = new Subject<string>();
  readonly dialog = inject(MatDialog);
  constructor(
    private router: Router,
    private purchaseOrderService: PurchaseOrdereService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private itemService: CreditDebitNoteService
  ) { }

  ngOnInit() {

    this.fetchAllPurchaseOrder();
    this.route.queryParamMap.subscribe((queryParams) => {
      this.purchaseOderId = queryParams.get('id');
      console.log('Query parameter "id":', this.purchaseOderId);
      
      if (this.purchaseOderId) {
        this.fetchPurchaseById();
      }
    });

      this.listAllComments();
      this.commentForm = this.fb.group({comment: ['', Validators.required]});

      this.searchSubject.pipe(debounceTime(600), distinctUntilChanged()).subscribe((searchTerm) => {
        this.fetchAllPurchaseOrder(searchTerm);
    });
  }

  toggleComments(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
      this.listAllComments();
    }
    this.isCommentsOpen = !this.isCommentsOpen;
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
}
  onMarkAsCanceled(id: any){
    const purchaseId = +id;
    if (id) {
      this.purchaseOrderService.markAsCanceled(purchaseId).subscribe({
        next:  (resp) => {
          if(resp?.data?.markAsCancelled?.id){
            this.router.navigate([`/purchase-order`]);
            this.toastr.success('Success.');
          }
        },
        error: (error) => {
          this.toastr.error(error.message || 'Failed to change.');
        }
      })
    }
  }

  listAllComments() {
    const orderId = +this.purchaseOderId;
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const conditions = {
      employeeId: employeeId,
      refId: orderId,
      type: 'PurchaseOrder',
      activityType: 'Comment',
    }
    this.purchaseOrderService.listAllPurchaseOrderComments(conditions).subscribe({
      next: (response) => {
        this.allComments = response;
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }


  onCreateComment() {
    const orderId = +this.purchaseOderId;
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const createCommentInput = {
      employeeId: employeeId,
      refId: orderId,
      type: 'PurchaseOrder',
      activityType: 'Comment',
      note: this.commentForm.value.comment,

    }

    this.itemService.createcomment(createCommentInput).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Comment added successfully!', 'Success');
          this.commentForm.reset();
          if (data) {
            this.listAllComments();
          }
          this.isCommentsOpen = !this.isCommentsOpen;
        }
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    });
  }

  onViewDocument() {
    const url = this.orderDetails?.purchaseUrl;
    console.log("url", url);

    const token = localStorage.getItem('AUTH_TOKEN');
    fetch(url, {
      method: 'GET',
      headers: {
        'authorization': token ? `Bearer ${token}` : ''
      }
    }).then((response: any) => response.blob()).then((blob: any) => {
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    }).catch((error: any) => {
      console.error('Error:', error)
    });
  }

  onEdit() {
    this.router.navigate([`/purchase-order/purchase-order-edit/${this.purchaseOderId}`]);
  }
  fetchPurchaseById() {


    this.purchaseOrderService.fetchPurchaseOrder(Number(this.purchaseOderId)).subscribe({
      next: (data) => {
        this.orderDetails = data;
        if (this.purchaseOderId) {
          const targetId = +this.purchaseOderId;
          this.selectedTabIndex = this.allOrders.findIndex((c: any) => {
            return c.id === targetId;
          });
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  fetchAllPurchaseOrder(search: string = '') {
    this.loading = true;
    this.purchaseOrderService.fetchAllPurchaseOrder(search, false).subscribe({
      next: (orders) => {
        this.allOrders = orders;
        if(this.purchaseOderId){
          const targetId = +this.purchaseOderId;                    
          this.selectedTabIndex = this.allOrders.findIndex((c: any) => {
            return c.id === targetId;
        });
        if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
        }
    } else {
        this.selectedTabIndex = 0;
    }
    this.OrderData = this.allOrders[this.selectedTabIndex] || {};
    this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching all purchase-orders:', err);
      },
    })

  }
  
  navigateTo(route: string) {
    this.router.navigate([route]);
  }



  onMarkAsIssued(id: any) {
    const purchaseId = +id;
    if (id) {
      this.purchaseOrderService.markAsIssued(purchaseId).subscribe({
        next: (res) => {
          this.fetchAllPurchaseOrder();
          if (res?.data?.markAsIssued?.id) {
            const id = res?.data?.markAsIssued?.id;
            console.log(id);

            this.router.navigate([`/purchase-order`]);
            this.toastr.success('Success.');
          }
        },
        error: (error) => {
          this.toastr.error(error.message || 'Failed to change.');
        }
      });
    }
    else {
      this.toastr.error('Could not find Id');
    }
  }

  convertToBill(id: any) {
    const purchaseId = +id;
    if (id) {
      this.purchaseOrderService.convertToBill(purchaseId).subscribe({
        next: (res) => {
          this.fetchAllPurchaseOrder();
          const purchaseData = res?.data;
          if (purchaseData) {
            this.router.navigate([`/purchase-order`]);
            this.toastr.success('Success.');
          }
        },
        error: (error) => {
          this.toastr.error(error.message || 'Failed to change.');
        }
      });
    }
    else {
      this.toastr.error('Could not find Id');
    }
  }

  navigateToSendMail(): void {
    const selectedQuote = this.allOrders[this.selectedTabIndex];
    const purchaseId = selectedQuote.id;
    // this.router.navigate([`purchase-order/purchase-order-details/${purchaseId}`]);
    this.router.navigate([`purchase-order/purchase-order-details/mail`], {
      queryParams: { purchaseId },
    });
  }

  selectTab(index: number) {
    this.selectedTabIndex = index;
    this.OrderData = this.allOrders[index];
    const selectedOrder = this.allOrders[index];
    this.router.navigate(['purchase-order/purchase-order-details'], {
      queryParams: { id: this.OrderData.id },
    });
    // if (selectedOrder) {
    //   this.router.navigate([`/purchase-order/purchase-order-details/${selectedOrder.id}`]);
    // }
  }
  openDialog() {
    const dialogRef = this.dialog.open(UploadPurchaseOrderDetailsDialog);
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onDelete(purchaseOrderId: any) {
    const id = +purchaseOrderId;
    this.purchaseOrderService.removePurchaseOrder(id).subscribe({
      next: () => {
        this.fetchAllPurchaseOrder();
        this.toastr.success('Deleted successfully.');
      },
      error: (error) => {
        this.toastr.error(error.message || 'Failed to delete.');
      },
    });
  }
}



@Component({
  selector: 'upload-purchase-order-dialog',
  templateUrl: 'upload-purchase-order-dialog.html',
  styleUrls: ['./purchase-order-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadPurchaseOrderDetailsDialog {

  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }



}
