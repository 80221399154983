import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastr: ToastrService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'An error occurred';

        if (error.status === 401 && error.error.exp === 'token expired') {
          // this.toastr.warning('Your session has expired. Please log in again.');
          this.authService.handleInvalidToken();
          errorMessage = 'Your session has expired. Please log in again.';
        } else if (error.error && error.error.message) {
          console.log('Error:', error.error.message);
        }
        console.error('Auth Interceptor Error:', error);
        return throwError(() => error);
      })
    );
  }
}
