import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { COMMENTS, FETCH_PARTNER, LIST_PARTNERS, PARTNER_DETAILS } from '../graphql/query/customer.query';
import { CREATE_COMMENT, CREATE_CONTACT, CREATE_DOCUMENT, CREATE_PARTNER, REMOVE_CONTACT, REMOVE_DOCUMENT, REMOVE_PARTNER, UPDATE_CONTACT, UPDATE_PARTNER } from '../graphql/mutation/customer.mutation';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private apollo: Apollo) { }

  fetchPartners(search: any): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: LIST_PARTNERS,
        variables:
        {
          search
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.partners || [])
      );
  }

  fetchPartnerById(id: any): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: FETCH_PARTNER,
        variables:
        {
          id
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.partner || [])
      );
  }

  partnerDetails(id: any): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: PARTNER_DETAILS,
        variables:
        {
          id
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.partnerDetails || [])
      );
  }

  createPartner(
    createPartnerInput: any,
    createContactInputs: any,
    createAddressInputs: any,
    createRemarkInput: any,
    createDocumentInputs: any,
    employeeId: any,
  ): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: CREATE_PARTNER,
      variables: {
        createPartnerInput,
        ...(createContactInputs?.length > 0 && { createContactInputs }),
        ...(createAddressInputs?.length > 0 && { createAddressInputs }),
        ...(createDocumentInputs?.length > 0 && { createDocumentInputs }),
        ...(Object.keys(createRemarkInput).length && { createRemarkInput }),
        employeeId
      },
    }).pipe(
      map((result: any) => result.data.createPartner || [])
    );
  }

  updatePartner(
    updatePartnerInput: any,
    updateContactInputs: any,
    updateAddressInputs: any,
    updateRemarkInput: any,
    employeeId: any,
    updateDocumentInputs: any,
  ): Observable<any> {
    console.log('inputs:', updateRemarkInput);

    return this.apollo.use('project').mutate({
      mutation: UPDATE_PARTNER,
      variables: {
        updatePartnerInput,
        ...(updateContactInputs?.length > 0 && { updateContactInputs }),
        ...(updateAddressInputs?.length > 0 && { updateAddressInputs }),
        employeeId,
        ...(Object.keys(updateRemarkInput).length && { updateRemarkInput }),
        // ...(Object.keys(updateDocumentInput).length && { updateDocumentInput })
        ...(updateDocumentInputs?.length > 0 && { updateDocumentInputs }),
      },
    }).pipe(
      map((result: any) => result.data.updatePartner || [])
    );
  }

  removePartner(id: any): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: REMOVE_PARTNER,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    }).pipe(
      map((result: any) => result.data.removePartner || [])
    );
  }

  removeDocument(id: any): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: REMOVE_DOCUMENT,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    }).pipe(
      map((result: any) => result.data.removeDocument || [])
    );
  }

  createDocument(createDocumentInput: any): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: CREATE_DOCUMENT,
      variables: {
        createDocumentInput
      },
      fetchPolicy: 'network-only'
    }).pipe(
      map((result: any) => result.data.createDocument || [])
    );
  }

  createComment(createCommentInput: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_COMMENT,
      variables: {
        createCommentInput
      },
      fetchPolicy: 'network-only'
    }).pipe(
      map((result: any) => result.data.createComment || [])
    );
  }

  createContact(createContactInput: any): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: CREATE_CONTACT,
      variables: {
        createContactInput
      },
      fetchPolicy: 'network-only'
    }).pipe(
      map((result: any) => result.data.createContact || [])
    );
  }

  updateContact(updateContactInput: any): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: UPDATE_CONTACT,
      variables: {
        updateContactInput
      },
      fetchPolicy: 'network-only'
    }).pipe(
      map((result: any) => result.data.updateContact || [])
    );
  }

  removeContact(id: any): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: REMOVE_CONTACT,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    }).pipe(
      map((result: any) => result.data.removeContact || [])
    );
  }

  comments(conditions: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: COMMENTS,
        variables:
        {
          conditions
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.comments || [])
      );
  }

  bulkCreateCustomer(file: File): Observable<any> {
    const token = localStorage.getItem('AUTH_TOKEN');
    const formData = new FormData();
    formData.append('operations', JSON.stringify({
      query: `
          mutation bulkCreatePartners($file: Upload!) {
            bulkCreatePartners(file: $file)
          }
          `,
      variables: { file: null }
    }));

    formData.append('map', JSON.stringify({ '0': ['variables.file'] }));
    formData.append('0', file);

    return from(
      fetch(environment.url.projectModuleUrl, {
        method: 'POST',
        body: formData,
        headers: {
          'authorization': token ? `Bearer ${token}` : '',
          'x-apollo-operation-name': 'uploadFile'
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(result => result.data?.bulkCreatePartners || result)
    );
  }

}