<section class="cmn-innerpage-wrapper banking">
    <div class="head">
        <div class="search-wrapper">
            <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)"/>
        </div>
        <!-- <button class="cmn-upload-btn" (click)="openDialog()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload</span>
            </button> -->
        <div class="cmn-add-transaction-dropdown" *appHasPermission="['1', Permission.Create]">
            <form class="white-custom-mat-select">
                <mat-form-field>
                    <!-- [(ngModel)]="selectedTransaction"  -->
                    <mat-select (selectionChange)="toggleAddTransaction($event)" placeholder="Add Transactions">
                        <ng-container *ngFor="let status of ['Out', 'In']">
                            <mat-option disabled class="dropdown-label">Money {{ status }}</mat-option>
                            <ng-container *ngFor="let category of categories">
                                <mat-option
                                    *ngIf="status === 'Out' ? category?.categoryStatus !== 'In' : category?.categoryStatus === 'In'"
                                    [value]="{ name: category?.categoryName, id: category?.id }" style="text-transform: capitalize;">
                                    {{ category?.categoryName }}
                                </mat-option>
                            </ng-container>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>
    </div>
    <div class="sub-head">
        <!-- <div class="branch-wrapper">
                <form>
                    <mat-form-field>
                        <mat-select [(value)]="selectedOption">
                            <mat-option value="today"> All Accounts </mat-option>
                            <mat-option value="week"> Active Accounts </mat-option>
                            <mat-option value="week"> Inactive Accounts </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div> -->
    </div>
    <div class="cash-details-wrapper">
        <div class="cmn-box cash-box">
            <div class="img-wrapper">
                <img src="assets/images/cash.svg" alt="Cash Balance">
            </div>
            <div class="detail-box">
                <h2>AED <span>{{ transactionData?.netValue }}</span></h2>
                <h3>Amount In Munawel</h3>
            </div>
        </div>
        <!-- <div class="cmn-box bank-box">
            <div class="img-wrapper">
                <img src="assets/images/bank.svg" alt="Bank Balance">
            </div>
            <div class="detail-box">
                <h2 *ngIf="transactionAmount?.length > 0">{{ transactionAmount[0]?.offsetAccount?.accountName }}</h2>
                <h3>Associated Branches</h3>
            </div>
        </div> -->
    </div>
    <div class="inner-content">
        <div class="banking-details-tab-wrapper">
            <button class="tab-custom-button" [class.active]="selectedTabIndex === 0" (click)="selectTab(0)">
                <h2> All Transactions</h2>
                <h3 *ngIf="transactionAmount?.length > 0">In {{ transactionAmount[0]?.offsetAccount?.accountName }}</h3>
            </button>
            <button class="tab-custom-button" [class.active]="selectedTabIndex === 1" (click)="selectTab(1)">
                <h2> <span>{{ transactionData?.uncategorizedTransactionsCount }}</span> Uncategorized Transactions
                </h2>
                <h3>From Bank Statements</h3>
            </button>
            

        </div>
        <div class="inner-tab-content-wrapper">
            <div class="banking-table-wrapper" *ngIf="selectedTabIndex === 1 ">
                <table *ngIf="statementAmount && statementAmount?.length > 0; else noData">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Statement Details</th>
                            <th>Deposits</th>
                            <th>Withdrawals</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let statement of statementAmount">
                        <tr (click)="toggleCategorize($event, statement)" style="cursor: pointer;">
                            <td>{{ statement?.date | date: 'dd/MM/YYYY' }}</td>
                            <td>
                                <p style="display: block; line-height: 1.5">Reference#:
                                    {{statement?.referenceNumber}}</p>
                                <p style="display: block; line-height: 1.5">Description:
                                    {{statement?.description}}</p>
                                <p style="display: block; line-height: 1.5">Payee:
                                    {{statement?.payee}}</p>
                            </td>
                            <td>{{ statement?.flow === 'Deposit' && statement?.amount ? 'AED ' + statement?.amount : '-'
                                }}</td>
                            <td>{{ statement?.flow === 'Withdraw' && statement?.amount ? 'AED ' + statement?.amount :
                                '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="banking-table-wrapper" *ngIf="selectedTabIndex === 0">
                <table *ngIf="transactionAmount && transactionAmount?.length > 0; else noData">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Reference#</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Deposits</th>
                            <th>Withdrawals</th>
                            <!-- <th>Running Balance</th> -->
                        </tr>
                    </thead>
                    <tbody *ngFor="let transaction of transactionAmount">
                        <tr (click)="toggleAllTransaction($event, transaction)" style="cursor: pointer;">
                            <td>{{ transaction?.date | date: 'dd/MM/YYYY' }}</td>
                            <td>{{ transaction?.referenceNumber || '-' }}</td>
                            <td>
                                <div>
                                    <h3 style="text-transform: capitalize;">{{ transaction?.transactionCategory?.categoryName || '-' }}</h3>
                                    <!-- <h4>{{ transaction?.offsetAccount?.accountType || '-' }}</h4> -->
                                </div>
                            </td>
                            <td> {{ transaction?.transactionStatus || '-' }} </td>
                            <td>{{ transaction?.deposit ? 'AED ' + transaction?.deposit : '-' }}</td>
                            <td>{{ transaction?.withdrawal ? 'AED ' + transaction?.withdrawal : '-' }}</td>
                            <!-- <td>{{ transaction?.runningBalance ? 'AED ' + transaction?.runningBalance : '-' }}</td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ng-template #noData>
            <div class="no-data-message">
                No data found.
            </div>
        </ng-template>
    </div>

    <!-- Categorize Manually -->
    <div [ngClass]="{ show: showUncategorize }" class="banking-side-dialog" #dropdown>
        <button class="close-dropdown" (click)="toggleCategorize($event)"></button>
        <div class="common-noimage-tab-wrapper">
            <mat-tab-group animationDuration="0ms">
                <!-- Mat Tab One -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <h2 class="leave-tab-title">Match Transactions</h2>
                    </ng-template>
                    <ng-container
                        *ngIf="(matchedData?.bestMatches?.length || 0) > 0 || (matchedData?.possibleMatches?.length || 0) > 0; else noMatch">
                        <div class="banking-sidedialog-content-wrapper seperate-scrollbar">
                            <ng-container *ngIf="matchedData?.bestMatches?.length > 0">
                                <div class="content-head">
                                    <div class="left-set">
                                        <h2>Best Matches</h2>
                                        <h3>Transactions upto 26 Aug 2024</h3>
                                    </div>
                                </div>
                                <!-- common height and scroll removed -->
                                <div class="banking-tab-inner-content">
                                    <!-- ngFor -->
                                    <div class="content-group" *ngFor="let data of matchedData?.bestMatches">
                                        <div class="left-set">
                                            <h4>Bill for AED {{ data?.amount || '--' }}</h4>
                                            <h5>Dated {{ data?.date | date: 'dd MMMM y' }}</h5>
                                            <h5>{{ data?.description || '--' }}</h5>
                                            <h5><span class="hyper-span">Ref# {{ data?.referenceNumber || '--' }}</span>
                                            </h5>
                                        </div>
                                        <div class="right-set" *appHasPermission="['1', Permission.Create]">
                                            <div class="submit-btn-wrapper">
                                                <!-- <button class="cmn-cancel-btn" (click)="toggleCategorize($event)">Cancel</button> -->
                                                <button class="cmn-next-btn"
                                                    (click)="onBestMatch(data?.id)">Match</button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ngFor -->
                                </div>
                            </ng-container>
                            <ng-container *ngIf="matchedData?.possibleMatches?.length > 0">
                                <div class="content-head">
                                    <div class="left-set">
                                        <h2>Possible Matches</h2>
                                        <h3>Transactions upto 26 Aug 2024</h3>
                                    </div>
                                    <div class="right-set">
                                        <span class="hyper-span" (click)="selectAll()">Select All</span>
                                    </div>
                                </div>
                                <!-- common height and scroll removed -->
                                <div class="banking-tab-inner-content">
                                    <!-- ngFor -->
                                    <div class="content-group" *ngFor="let data of matchedData?.possibleMatches">
                                        <div class="left-set">
                                            <h4>Bill for AED {{ data?.amount || '--' }}</h4>
                                            <h5>Dated {{ data?.date | date: 'dd MMMM y' }}</h5>
                                            <h5>{{ data?.description || '--' }}</h5>
                                            <h5><span class="hyper-span">Ref# {{ data?.referenceNumber || '--' }}</span>
                                            </h5>
                                        </div>
                                        <div class="right-set">
                                            <div class="check-wrapper">
                                                <div class="check-box">
                                                    <label class="checkbox-wrapper">
                                                        <input type="checkbox" [value]="data?.id"
                                                            [checked]="transactionIds.includes(data?.id)"
                                                            (change)="toggleTransaction(data, $event)" />Select
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ngFor -->
                                </div>
                            </ng-container>
                            <!-- <div class="submit-btn-wrapper">
                                <h1 class="leave-tab-title">Total Amount</h1>
                                <h1 class="leave-tab-red">AED {{ totalAmount | number: '1.2-2' }}</h1>
                            </div> -->
                            <div class="submit-btn-wrapper">
                                <h5 *ngIf="isCreate" (click)="onCreate()"><span class="hyper-span">+ Create New
                                        Transaction</span></h5>
                                <h5 *ngIf="isCreate">Pending : <span class="hyper-span-green">AED {{ pendingAmount |
                                        number: '1.2-2' }}</span></h5>
                                <!-- <h5 *ngIf="isCreate">Pending : <span class="hyper-span-green">AED {{ (totalAmount -
                                        statement?.amount) | number: '1.2-2' }}</span></h5> -->
                            </div>
                            <div class="submit-btn-wrapper" *appHasPermission="['1', Permission.Create]">
                                <button class="cmn-cancel-btn" (click)="toggleCategorize($event)">Cancel</button>
                                <button class="cmn-next-btn" (click)="onMatch()">Match</button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noMatch>
                        <h2 class="leave-tab-title">No match found</h2>
                    </ng-template>
                </mat-tab>

                <!-- Mat Tab Two -->
                <mat-tab *appHasPermission="['1', Permission.Create]">
                    <ng-template mat-tab-label>
                        <h2 class="leave-tab-title">Categorize Manually</h2>
                    </ng-template>
                    <div class="banking-sidedialog-content-wrapper">
                        <div class="banking-tab-inner-content-form">
                            <form [formGroup]="categorizeForm">
                                <div class="manually-form-wrapper">
                                    <div class="form-wrapper">
                                        <label for="">Category<span class="red">*</span></label>
                                        <mat-form-field>
                                            <mat-select (selectionChange)="selectionChangeCategory($event)"
                                                placeholder="Add Transactions" formControlName="category">
                                                <ng-container *ngFor="let category of categories">
                                                    <mat-option
                                                        [value]="{ flow: category?.categoryStatus, id: category?.id }"
                                                        style="text-transform: capitalize;">
                                                        {{ category?.categoryName }}
                                                    </mat-option>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="
                                        categorizeForm.get('category')!.touched &&
                                        categorizeForm.get('category')!.hasError('required')">Category is required.
                                        </mat-error>
                                    </div>
                                    <div class="form-wrapper">
                                        <label for="">Date <span class="red">*</span></label>
                                        <mat-form-field>
                                            <input matInput [matDatepicker]="picker" placeholder="Choose date"
                                                formControlName="date" />
                                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                        <mat-error *ngIf="
                                        categorizeForm.get('date')!.touched &&
                                        categorizeForm.get('date')!.hasError('required')">Date is required.
                                        </mat-error>
                                    </div>
                                    <div class="form-wrapper amount-wrapper">
                                        <label for="">Amount <span class="red">*</span></label>
                                        <h6>AED {{manualAmount | number: '1.2-2' }}*</h6>
                                    </div>
                                    <div class="form-wrapper">
                                        <label for="">Reference#</label>
                                        <mat-form-field>
                                            <input matInput placeholder="REF-0023" formControlName="reference" />
                                        </mat-form-field>
                                    </div>
                                    <div class="form-wrapper">
                                        <label for="">Description</label>
                                        <mat-form-field>
                                            <textarea matInput placeholder="Enter description"
                                                formControlName="description"></textarea>
                                        </mat-form-field>
                                    </div>

                                    <!-- <div class="form-wrapper upload-file-group">
                                        <input #fileInput type="file" style="display: none" />
                                        <button class="cmn-upload-btn" (click)="fileInput.click()">
                                            <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                                            <span>Upload File</span>
                                        </button>
                                        <div class="uploaded-file">
                                            <h6>
                                                file-name.pdf
                                                <img src="assets/images/icons/icon-close.svg" alt="Close Icon" />
                                            </h6>
                                        </div>
                                    </div> -->

                                </div>
                            </form>
                        </div>
                        <div class="submit-btn-wrapper">
                            <button class="cmn-cancel-btn">Cancel</button>
                            <button class="cmn-next-btn" (click)="onCategorizeManually()">Save</button>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <!-- Categorize Manually -->

    <!-- Transaction Details-->
    <div [ngClass]="{ show: showAllTransaction }" class="banking-side-dialog" #dropdown>
        <button class="close-dropdown" (click)="toggleAllTransaction($event)"></button>
        <div class="common-noimage-tab-wrapper">
            <mat-tab-group animationDuration="0ms">
                <!-- Mat Tab One -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <h2 class="leave-tab-title">Transaction Details</h2>
                    </ng-template>
                    <div class="all-transaction-sidedialog-content-wrapper">
                        <div class="sub-head">
                            <ul>
                                <li (click)="onEdit()" *ngIf="
                                transactionDetail?.transactionStatus !== 'Matched' ||
                                transactionDetail?.transactionStatus !== 'Categorized'
                                 ">
                                 <!-- <img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit"> Edit</li> -->

                                 <ng-container *appHasPermission="['1', Permission.Edit]">
                                    <img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit" />
                                    Edit
                                  </ng-container>
                                  </li>
                                <li *appHasPermission="['1', Permission.Delete]" (click)="onConfirmDelete()"><img src="assets/images/icons/icon-delete-li.svg"
                                        alt="Delete"> Delete</li>
                                <li (click)="onUnmatch()" *ngIf="transactionDetail?.transactionStatus === 'Matched'">
                                    Unmatch</li>
                                <li (click)="onUncategorized()"
                                    *ngIf="transactionDetail?.transactionStatus === 'Categorized'">Uncategorize</li>

                            </ul>
                        </div>
                        <div *ngIf="!edit">
                            <div class="type-head">
                                <div>
                                    <h2>AED {{transactionDetail?.amount | number: '1.2-2'}}</h2>
                                    <h3>On {{ transactionDetail?.date | date: 'dd MMM y' }}</h3>
                                </div>
                                <span>{{transactionDetail?.transactionCategory?.categoryName}}</span>
                            </div>
                            <div class="type-head" style="margin-top: 20px;">
                                <div>
                                    <h3>Reference# : {{transactionDetail?.referenceNumber}}</h3>
                                    <h3>Transaction Status : {{ transactionDetail?.transactionStatus }}</h3>
                                    <h3>{{ transactionDetail?.flow }}</h3>
                                    <h3>Account Type : {{ transactionDetail?.offsetAccount?.accountType }}</h3>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="edit">
                            <app-update-banking-details [transactionDetail]="transactionDetail"
                                (transactionUpdated)="toggleAllTransaction($event)"></app-update-banking-details>
                        </div>
                        <!-- <div class="transaction-table-wrapper">
                            <table>
                                <tr>
                                    <td>Customer</td>
                                    <td>Bank Charges</td>
                                    <td>Received Via</td>
                                </tr>
                                <tr class="bold border">
                                    <td>Laura Dizler</td>
                                    <td>AED 0.00</td>
                                    <td>Cash</td>
                                </tr>
                                <tr>
                                    <td>Invoice Details</td>
                                    <td></td>
                                    <td>Payment</td>
                                </tr>
                                <tr class="bold">
                                    <td>IN-0012455</td>
                                    <td></td>
                                    <td>AED 3,500.00</td>
                                </tr>
                                <tr class="bold">
                                    <td>AED 0.00</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr class="border">
                                    <td>02/06/2024</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr class="title">
                                    <td>Journal</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Account</td>
                                    <td>Debit</td>
                                    <td>Credit</td>
                                </tr>
                                <tr class="bold">
                                    <td>Accounts Receivable</td>
                                    <td>AED 0.00</td>
                                    <td>AED 3,500.00</td>
                                </tr>
                                <tr class="bold border">
                                    <td>Personal (Current)</td>
                                    <td>AED 3,500.00</td>
                                    <td>AED 0.00</td>
                                </tr>
                                <tr class="bold">
                                    <td></td>
                                    <td>AED 3,500.00</td>
                                    <td>AED 3,500.00</td>
                                </tr>
                            </table>
                        </div> -->
                    </div>


                </mat-tab>
                <!-- Mat Tab Two -->
                <mat-tab *appHasPermission="['1', Permission.Create]">
                    <ng-template mat-tab-label>
                        <h2 class="leave-tab-title">Comments & History</h2>
                    </ng-template>
                    <div class="all-transaction-sidedialog-content-wrapper">
                        <form [formGroup]="commentForm" (ngSubmit)="onComment()">
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <textarea matInput placeholder="Add Your Comment Here..."
                                        formControlName="comments"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="btn-wrapper">
                                <button class="cmn-apply-btn" type="submit">Comment</button>
                            </div>
                            <div class="timelinne-wrapper">
                                <!-- ngFor -->
                                <div class="timeline-box" *ngFor="let comment of comments">
                                    <div class="timeline-head">
                                        <ul>
                                            <li>{{comment?.employee?.firstName || '--'}}</li>
                                            <li>{{comment?.createdAt | date: 'dd/MM/YYYY'}}</li>
                                        </ul>
                                        <h3>{{comment?.createdAt | date: 'hh:mm a'}}</h3>
                                    </div>
                                    <div class="timeline-content">
                                        <h4>{{comment?.note || '--'}}</h4>
                                    </div>
                                </div>
                                <!-- ngFor -->
                            </div>
                        </form>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <!-- Transaction Details -->

    <!-- Add Transaction -->
    <div [ngClass]="{ show: showAddTransaction}" class="banking-side-dialog" #dropdown>
        <button class="close-dropdown" (click)="closeDropdown()"></button>
        <div class="common-noimage-tab-wrapper">
            <!-- <h2 class="leave-tab-title">{{ getDisplayName(selectedTransaction) }}</h2> -->
            <div class="banking-sidedialog-content-wrapper">
                <div class="banking-tab-inner-content-form">
                    <form [formGroup]="transactionForm">
                        <h2 style="text-transform: capitalize; font-weight: 400; margin-bottom: 20px;">
                            {{selectedTransaction}}</h2>
                        <div class="manually-form-wrapper">
                            <!-- selectedTransaction === 'transfer to another account' || -->
                            <div class="form-wrapper" *ngIf="
                                    selectedTransaction === 'card payment' ||
                                    selectedTransaction === 'owner drawings'||
                                    selectedTransaction === 'transfer from another account' ||
                                    selectedTransaction === 'other income' || 
                                    selectedTransaction === 'owner contribution'
                                    ">
                                <label for="">From Account <span class="red">*</span></label>
                                <mat-form-field>
                                    <mat-select placeholder="Select Account" formControlName="fromAccount">
                                        <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
                                            {{ account?.accountName }} {{ account?.accountType === 'Bank' ? ' - ' + account?.accountCode : ''}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="transactionForm.get('fromAccount')?.hasError('required')
                                    && transactionForm.get('fromAccount')?.touched
                                    ">
                                        From Account is required.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                                    <!-- selectedTransaction === 'transfer from another account'  -->
                            <div class="form-wrapper" *ngIf="
                                    selectedTransaction === 'transfer to another account' ||
                                    selectedTransaction === 'card payment' 
                                    ">
                                <label for="">To Account<span class="red">*</span></label>
                                <mat-form-field>
                                    <mat-select placeholder="Select Account" formControlName="chartOfAccount">
                                        <ng-container *ngIf="selectedTransaction === 'card payment'; else accountsList">
                                            <!-- Loop over creditCards if it's a card payment -->
                                            <mat-option *ngFor="let card of creditCards" [value]="card.id">
                                                {{ card?.accountName }}
                                            </mat-option>
                                        </ng-container>
                                        <!-- Default loop over chartOfAccounts -->
                                        <ng-template #accountsList>
                                            <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
                                                {{ account?.accountName }} {{ account?.accountType === 'Bank' ? ' - ' + account?.accountCode : ''}}
                                            </mat-option>
                                        </ng-template>
                                    </mat-select>

                                    <mat-error *ngIf="transactionForm.get('chartOfAccount')?.hasError('required')">
                                        To account is required.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-wrapper" *ngIf="
                                selectedTransaction === 'Interest income' ||
                                selectedTransaction === 'other income' ||
                                selectedTransaction === 'owner contribution'
                                ">
                                <label for="">Received Via</label>
                                <mat-form-field>
                                    <mat-select placeholder="Select Method" formControlName="receivedVia">
                                        <mat-option value="Cash">Cash</mat-option>
                                        <mat-option value="Cheque">Cheque</mat-option>
                                        <mat-option value="UPI">UPI</mat-option>
                                        <mat-option value="Credit Card">Credit Card</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-wrapper" *ngIf="selectedTransaction === 'vendorAdvance'">
                                <label for="">TDS</label>
                                <mat-form-field>
                                    <mat-select placeholder="Select a Tax" formControlName="tds">
                                        <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
                                            {{ account?.accountName }} {{ account?.accountType === 'Bank' ? ' - ' + account?.accountCode : ''}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-wrapper" *ngIf="selectedTransaction === 'vendorAdvance'">
                                <label for="">Payment#</label>
                                <mat-form-field>
                                    <input matInput placeholder="Enter Payment" formControlName="payment" />
                                </mat-form-field>
                            </div>
                            <div class="form-wrapper" *ngIf="
                                selectedTransaction === 'vendorAdvance' ||
                                selectedTransaction === 'creditNoteRefund' || 
                                selectedTransaction === 'paymentRefund'
                                ">
                                <label for="">Paid Via</label>
                                <mat-form-field>
                                    <mat-select placeholder="Select a Payment" formControlName="paid">
                                        <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
                                            {{ account?.accountName }} {{ account?.accountType === 'Bank' ? ' - ' + account?.accountCode : ''}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-wrapper" *ngIf="selectedTransaction === 'vendorAdvance'">
                                <label for="">Deposit To</label>
                                <mat-form-field>
                                    <mat-select placeholder="Deposit To" formControlName="deposit">
                                        <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
                                            {{ account?.accountName }} {{ account?.accountType === 'Bank' ? ' - ' + account?.accountCode : ''}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-wrapper">
                                <label>Date <span class="red">*</span></label>
                                <mat-form-field>
                                    <input matInput [matDatepicker]="picker1" formControlName="date"
                                        placeholder="Choose Date" (dateInput)="onDateChange($event)">
                                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                    <mat-error *ngIf="transactionForm.get('date')?.hasError('required')">
                                        Date is required.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!-- amount -->
                            <div class="form-wrapper">
                                <label for="">Amount<span class="red">*</span></label>
                                <mat-form-field>
                                    <input matInput placeholder="Enter amount" formControlName="amount" />
                                    <mat-error *ngIf="transactionForm.get('amount')?.hasError('required')">
                                        Amount is required.
                                    </mat-error>
                                    <mat-error *ngIf="
                                    transactionForm.get('amount')!.hasError('pattern')">
                                        Please enter a valid amount.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!-- reference -->
                            <div class="form-wrapper">
                                <label for="">Reference#</label>
                                <mat-form-field>
                                    <input matInput placeholder="Enter reference#" formControlName="invoice" />
                                </mat-form-field>
                            </div>
                            <!-- description -->
                            <div class="form-wrapper">
                                <label for="">Description</label>
                                <mat-form-field>
                                    <textarea matInput placeholder="Enter description"
                                        formControlName="description"></textarea>
                                </mat-form-field>
                            </div>
                            <!-- attach file -->
                            <!-- <div class="form-wrapper upload-file-group" 
                                *ngIf="
                                selectedTransaction === 'expense' ||
                                selectedTransaction === 'transfer to another account' ||
                                selectedTransaction === 'cardPayment'  ||
                                selectedTransaction === 'ownerDrawings'
                                ">
                                    <label for="">Attach Receipt</label>
                                    <input #fileInput type="file"  formControlName = "file" (change)="onFileSelected($event)" style="display: none" />
                                    <button class="cmn-upload-btn" (click)="fileInput.click()">
                                        <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                                        <span>Upload File</span>
                                    </button>
                                    <div class="uploaded-file" *ngIf="file">
                                        <h6>
                                            {{file?.name}}
                                            <img src="assets/images/icons/icon-close.svg" alt="Close Icon" (click)="removeFile()"/>
                                        </h6>
                                    </div>

                                </div> -->
                        </div>
                    </form>
                </div>
                <div class="submit-btn-wrapper">
                    <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
                    <button class="cmn-next-btn" (click)="onSave()">Save</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Transaction -->
</section>