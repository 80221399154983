import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';
import { CreditDebitNoteService } from '../../features/credit-debit-note/services/credit-debit-note.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { fetch_PDF } from '../../helpers/helper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-debit-note-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    ReactiveFormsModule

  ],
  templateUrl: './debit-note-details.component.html',
  styleUrl: './debit-note-details.component.scss',
})
export class DebitNoteDetailsComponent {
  allItems: any[] = [];
  itemId: any;
  itemData: any = {};
  isCommentsOpen = false;
  commentForm : FormGroup;
  comment: any;
  journalData: any; 
  
  private searchSubject = new Subject<string>();

  constructor(
    private router: Router,
    private itemService: CreditDebitNoteService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb : FormBuilder
  ) {}

  ngOnInit(): void {
    this.fetchNotes();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchNotes(searchTerm);
      });

    this.route.queryParams.subscribe((params) => {
      const id = params['id'];
      this.itemId = +id;
    });
    this.commentForm = this.fb.group({comment: ['']});
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  openDebitNoteCreditApplyDialog() {
    const dialogDebitNoteCreditApplyDialogItemRef = this.dialog.open(
      DebitNoteCreditApplyDialog
    );
  }

  selectedOption = 'today';

  selectedTabIndex = 0; // Default to the first tab

  selectTab(index: number) {
    this.selectedTabIndex = index;
    this.itemData = this.allItems[index];
    this.journalData = this.itemData?.journalData;
    if (this.itemData) {
      this.router.navigate(['credit-note/credit-note-details'], {
        queryParams: { id: this.itemData.id },
      });
    }
  }
  calculateTotalDebit(entries: any[]): number {
    return entries?.reduce((total, entry) => total + (entry.debit || 0), 0) || 0;
  }
  
  calculateTotalCredit(entries: any[]): number {
    return entries?.reduce((total, entry) => total + (entry.credit || 0), 0) || 0;
  }
  

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(UploadDebitNoteDialog);
  }

  navigateToMail(): void {
    const noteId = this.itemData.id;
    this.router.navigate([`debit-note/debit-note-details/mail`], {
      queryParams: { noteId },
    });
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  fetchNotes(search: string = '') {
    this.itemService.fetchNotes('Debit', search).subscribe({
      next: (items) => {
        this.allItems = items;
        if (this.allItems.length > 0) {
          this.selectTab(0);
        }

        if (this.itemId) {
          const targetId = +this.itemId;
          this.selectedTabIndex = this.allItems.findIndex((c: any) => {
            return c.id === targetId;
          });
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
      },
      error: (err) => {
        console.error('Error fetching all items:', err);
      },
    });
  }

  downloadPdf(): void {
    const noteId = this.itemData.id
    const type = 'Debit';
    this.itemService.generateNotePdf(noteId, type).subscribe({
      next: (items) => {
        const url = items;
        const fileName = 'Debit-note';
        fetch_PDF(url, fileName);
      },
      error: (err) => {
        this.toastr.error(err.message, 'failed');
      },
    });
  }

  toggleComments(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isCommentsOpen = !this.isCommentsOpen;
  }

  createComment(): void {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee ?. employeeData ?. id;
    const createCommentInput = {
      employeeId: employeeId, 
      refId:this.itemData.id, 
      type: 'DebitNote', 
      activityType: 'Comment', 
      note: this.commentForm.value.comment,
    };
    this.itemService.createcomment(createCommentInput).subscribe({
      next: (data) => {
          if (data) {
              this.toastr.success('Comment added successfully!', 'Success');
              this.commentForm.reset();
          }
      },
      error: (error) => {
          console.error(error, 'Error');
      }
  });

  }

  
}

@Component({
  selector: 'upload-debit-note-dialog',
  templateUrl: 'upload-debit-note-dialog.html',
  styleUrls: ['./debit-note-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadDebitNoteDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}

@Component({
  selector: 'debit-note-apply-credit-dialog',
  templateUrl: 'debit-note-apply-credit-dialog.html',
  styleUrls: ['./debit-note-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
})
export class DebitNoteCreditApplyDialog {}
