
    <section class="cmn-innerpage-wrapper purchase-order">
        <div class="head">
            <div class="search-wrapper" *appHasPermission="['14', Permission.List]">
                <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)" />
            </div>
            <button class="cmn-add-btn" *appHasPermission="['14', Permission.Create]" (click)="navigateTo('purchase-order/purchase-order-new')">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New  Purchase
            </button>
        </div>
        <div class="inner-content" *appHasPermission="['14', Permission.List]">
            <ag-grid-angular style="width: 100%;" [rowData]="rowData" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [suppressRowClickSelection]="true"
                [pagination]="true" [paginationPageSize]="paginationPageSize"
                [paginationPageSizeSelector]="paginationPageSizeSelector" [class]="themeClass"  (cellClicked)="onCellClicked($event)"/>

                <!-- <button (click)="navigateTo('/purchase-order-details')">Details</button> -->
        </div>
    </section>

