import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as selectPermissions from '../../core/permissions/permission.selector';
import { Permission } from '../services/permissions';

@Directive({
  selector: '[appHasPermission]',
  standalone: true, // Mark the directive as standalone
})
export class PermissionDirective implements OnDestroy {
  private destroy$ = new Subject<void>();

  // @Input('appHasPermission') set hasPermission([moduleId, permission]: [string, Permission]) {
  //   this.store
  //     .select(selectPermissions.hasPermission(moduleId, permission))
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((hasPermission: boolean) => {
  //       this.viewContainerRef.clear();
  //       if (hasPermission) {
  //         this.viewContainerRef.createEmbeddedView(this.templateRef);
  //       }
  //     });
  // }

  @Input('appHasPermission') set hasPermission(input: string | string[] | [string, any]) {
    if (typeof input === 'string') {
      // Handle single module ID
      this.checkModuleId(input);
    } else if (Array.isArray(input) && input.length === 2) {
      // Handle moduleId and permission tuple (moduleId, permission)
      const [moduleId, permission] = input;
      this.checkModuleAndPermission(moduleId, permission);
    } else if (Array.isArray(input)) {
      // Handle array of module IDs
      this.checkModuleIds(input);
    } else {
      console.warn('Invalid appHasPermission input:', input);
      this.viewContainerRef.clear();
    }
  }

  constructor(
    private store: Store,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}


  private checkModuleId(moduleId: string): void {
    this.store
      .select(selectPermissions.hasModuleId(moduleId))
      .pipe(takeUntil(this.destroy$))
      .subscribe((exists: boolean) => {
        this.viewContainerRef.clear();
        if (exists) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      });
  }

  private checkModuleIds(moduleIds: string[]): void {
    let hasPermission = false;
  
    // Check if any module ID exists in the provided array
    moduleIds.forEach(moduleId => {
      this.store
        .select(selectPermissions.hasModuleId(moduleId))
        .pipe(takeUntil(this.destroy$))
        .subscribe((exists: boolean) => {
          if (exists) {
            hasPermission = true; // If any module ID exists
            this.viewContainerRef.clear();
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        });
    });
  }

  private checkModuleAndPermission(moduleId: any, permission: any): void {
    this.store
      .select(selectPermissions.hasPermission(moduleId, permission))
      .pipe(takeUntil(this.destroy$))
      .subscribe((hasPermission: boolean) => {
        this.viewContainerRef.clear();
        if (hasPermission) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
