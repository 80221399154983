<section class="cmn-innerpage-wrapper send-mail">
  <div class="send-mailer">
    <form [formGroup]="emailForm" (ngSubmit)="sendEmail()">
      <div class="mail-form-head">
        <div class="form-box">
          <label for="">From</label>
          <div class="input-box">
            <input type="text" disabled value="admin@munawel.com" />
          </div>
        </div>
        <div class="form-box">
          <label for="">Send To</label>
          <div class="input-box">
            <div class="email-input-container">
              <div class="email-chips">
                <span *ngFor="let email of toEmails" class="email-chip">
                  {{ email }}
                  <button type="button" (click)="removeEmail(email, 'to')">
                    ×
                  </button>
                </span>
              </div>
              <input formControlName="toEmailControl" (keyup.enter)="addEmail('to')" placeholder="Add email" />
            </div>
          </div>
        </div>
        <div class="form-box with-bcc">
          <label for="">Cc</label>
          <div class="input-box">
            <div class="email-input-container">
              <div class="email-chips">
                <span *ngFor="let email of ccEmails" class="email-chip">
                  {{ email }}
                  <button type="button" (click)="removeEmail(email, 'cc')">
                    ×
                  </button>
                </span>
              </div>
              <input formControlName="ccEmailControl" (keyup.enter)="addEmail('cc')" placeholder="Add email" />
            </div>
          </div>
          <!-- <button class="add-bcc" (click)="toggleBcc()">Bcc</button> -->
        </div>
        <!-- <div class="form-box" *ngIf="showBcc">
          <label for="">Bcc</label>
          <div class="input-box">
            <div class="email-input-container">
              <div class="email-chips">
                <span *ngFor="let email of bccEmails" class="email-chip">
                  {{ email }}
                  <button type="button" (click)="removeEmail(email, 'bcc')">
                    ×
                  </button>
                </span>
              </div>
              <input
                formControlName="bccEmailControl"
                (keyup.enter)="addEmail('bcc')"
                placeholder="Add email"
              />
            </div>
          </div>
        </div> -->
        <div class="form-box">
          <label for="">Subject</label>
          <div class="input-box">
            <!-- <h3>{{ invoiceData?.invoiceNo }} {{ invoiceData?.subject }}</h3> -->
            <input type="text" id="subject" formControlName="subject" />
          </div>
        </div>
      </div>
      <quill-editor [formControl]="invoiceTemplateControl"></quill-editor>

      <div class="bottom-content">
        <div class="check-box" *ngIf="invoiceData?.invoiceUrl">
          <label class="checkbox-wrapper">
            <input type="checkbox" (change)="toggleInvoice($event)" [checked]="isAttachInvoice" />
            Attach Invoice PDF
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="upload-file-group">
          <input #fileInput type="file" (change)="onFileSelected($event)" style="display: none" multiple
            accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx" />
          <button type="button" class="cmn-upload-btn" (click)="fileInput.click()">
            <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
            <span>Upload File</span>
          </button>
          <div class="uploaded-file isAttachInvoice" *ngIf="isAttachInvoice && invoiceData?.invoiceUrl">
            <h6>
              {{invoiceData?.invoiceNo || "___"}}
            </h6>
          </div>
          <div class="uploaded-file" *ngIf="documentFileNames?.length">
            <h6 *ngFor="let fileName of documentFileNames; let i = index">
              {{ fileName || "___" }}
              <img src="assets/images/icons/icon-close.svg" alt="Close Icon" (click)="removeFile(i)" />
            </h6>
          </div>
        </div>

      </div>

      <div class="submit-btn-wrapper">
        <button class="cmn-cancel-btn" type="button" (click)="navigateToDetail()">Cancel</button>
        <button class="cmn-next-btn" type="submit" [disabled]="isLoading">
          {{ isLoading ? 'Sending...' : 'Send' }}
        </button>
      </div>
    </form>
  </div>
</section>