<div class="employee-tab-wrapper">
  <mat-tab-group animationDuration="0ms">
    <!-- Mat Tab One -->
    <mat-tab>
      <ng-template mat-tab-label>
        <h2 class="cmn-tab tab-1">Personal Info</h2>
      </ng-template>
      <app-personal-info-profile [employeeData]="employeeData"></app-personal-info-profile>
    </mat-tab>
    <!-- Mat Tab Two -->
    <mat-tab>
      <ng-template mat-tab-label>
        <h2 class="cmn-tab tab-2">Professional Info</h2>
      </ng-template>
      <app-professional-info-profile [employeeData]="employeeData"></app-professional-info-profile>
    </mat-tab>
    <!-- Mat Tab Three -->
    <mat-tab>
      <ng-template mat-tab-label>
        <h2 class="cmn-tab tab-3">Documents</h2>
      </ng-template>
      <app-documents-profile [employeeData]="employeeData"></app-documents-profile>
    </mat-tab>
    <!-- Mat Tab Four -->
    <mat-tab>
      <ng-template mat-tab-label>
        <h2 class="cmn-tab tab-4">Branch Access</h2>
      </ng-template>
      <app-branch-access-profile [employeeData]="employeeData"></app-branch-access-profile>
    </mat-tab>
    <!-- Mat Tab Five -->
    <mat-tab>
      <ng-template mat-tab-label>
        <h2 class="cmn-tab tab-5">Salary Details</h2>
      </ng-template>
      <app-salary-details-profile [employeeData]="employeeData"></app-salary-details-profile>
    </mat-tab>
    <!-- Mat Tab Six -->
    <mat-tab>
      <ng-template mat-tab-label>
        <h2 class="cmn-tab tab-6">Career History</h2>
      </ng-template>
      <app-career-history  [employeeData]="employeeData"></app-career-history>
    </mat-tab>
  </mat-tab-group>
</div>