import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from, map } from 'rxjs';
import { ADMIN_ADDRESS, ALL_PURCHASE_ORDER, CURRENCY_LIST, FETCH_PURCHASE_ORDER, GENERATE_PURCHASE_ORDER_PDF, GET_ALL_WORK_ORDER, LIST_PURCHASE_ORDER_COMMENTS } from '../graphql/query/purchase-order-query';
import { CONVERT_TO_BILL, CREATE_PURCHASE_ORDER, MARK_AS_CANCELED_PURCHASEORDER, MARK_AS_ISSUED, REMOVE_PURCHASE_ORDER, UPDATE_PURCHASE_ORDER } from '../graphql/mutations/purchase-order-mutation';


@Injectable({
  providedIn: 'root',
})
export class PurchaseOrdereService {
  constructor(private apollo: Apollo) {}

  // munawel-accounts
  fetchAllPurchaseOrder(search: string, isLocal: boolean): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        variables: {
          search: search,
          isLocal: isLocal,
        },
        query: ALL_PURCHASE_ORDER,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.purchaseOrders));
  }
  // munawel-accounting
  fetchPurchaseOrder(id: number) {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        variables: {
          id: id,
        },
        query: FETCH_PURCHASE_ORDER,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.purchaseOrder));
  }

  fetchallworkorders(search: string){
    return this.apollo
    .use('project')
    .watchQuery<any>({
      query: GET_ALL_WORK_ORDER,
      variables: {
        search: search
      },
      fetchPolicy: 'no-cache',
    })
    .valueChanges.pipe(map((response) => response?.data));
  }

  // munawel-project
  fetchAdminAdress() {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: ADMIN_ADDRESS,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response?.data?.adminAddress));
  }
  // munawel-accounting
  updatePurchaseOrder(
    updatePurchaseOrderInput: any,
    updatePurchaseItemInput: any
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: UPDATE_PURCHASE_ORDER,
      variables: {
        updatePurchaseOrderInput: updatePurchaseOrderInput,
        updatePurchaseItemInput: updatePurchaseItemInput,
      },
    });
  }
    // munawel-accounting
  createpurchaseorder(
    createPurchaseOrderInput: any,
    createPurchaseItemInput: any[]
  ) {
    return this.apollo
      .use('accounts')
      .mutate<any>({
        mutation: CREATE_PURCHASE_ORDER,
        variables: {
          createPurchaseOrderInput: createPurchaseOrderInput,
          createPurchaseItemInput: createPurchaseItemInput,
        },
        fetchPolicy: 'no-cache',
      });
  }
    
  //munawel-accounting
  generatepurchasepdf(id: number){
    return this.apollo.use('accounts')
    .watchQuery<any>({
      query: GENERATE_PURCHASE_ORDER_PDF,
      variables: {
        purchaseId: id
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      map((response) => response?. data)
    );
  }


    //Munawel-accounting
    listAllPurchaseOrderComments(conditions: any){
      return this.apollo.use('accounts').watchQuery<any>({
        query: LIST_PURCHASE_ORDER_COMMENTS,
        variables:{
          conditions: conditions
        }
      }).valueChanges.pipe(map((response) => response?. data ?.  comments
      ))
    }

  // munawel-accounting
  listcurrencies(search: string, filter: any ){
    return this.apollo.use('accounts').watchQuery<any>({
      query: CURRENCY_LIST,
      variables:{
        search: search,
        filter: filter
      }
    }).valueChanges.pipe(map((response) => response?.data?.currencies));
  }

  // munawel-accounting
  removePurchaseOrder(id: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_PURCHASE_ORDER,
      fetchPolicy: 'no-cache',
      variables: {
        id: id,
      },
    });
  }

    // munawel-accounting
    markAsIssued(id: number): Observable<any> {
      return this.apollo.use('accounts').mutate({
        mutation: MARK_AS_ISSUED,
        fetchPolicy: 'no-cache',
        variables: {
          id: id,
        },
      });
    }

    // munawel-accounting
    markAsCanceled(id: number): Observable<any> {
      return this.apollo.use('accounts').mutate({
        mutation: MARK_AS_CANCELED_PURCHASEORDER,
        fetchPolicy: 'no-cache',
        variables: {
          id: id,
        },
      });
    }


    //munawel-accounting
    convertToBill(id: number){
      return this.apollo.use('accounts').mutate({
        mutation: CONVERT_TO_BILL,
        fetchPolicy: 'no-cache',
        variables: {
          id: id,
        },
      });
    }


}