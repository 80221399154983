import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { customEmailValidator } from '../../shared/services/validations';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup;
  successMessage: string | null = null;
  errorMessage: string | null = null;
  loading: boolean = false;

  private forgotPasswordApiUrl = `${environment.authUrl}/auth/forgotPassword`;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private http: HttpClient
  ) {
    this.initForm();
  }

  private initForm(): void {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, customEmailValidator()]],
    });
  }

  onSubmit() {
    if (this.forgotPasswordForm.valid) {
      this.loading = true;
      const email = this.forgotPasswordForm.value.email;
      this.http
        .post<{ message: string }>(this.forgotPasswordApiUrl, { email })
        .subscribe({
          next: (response) => {
            this.successMessage =
              response.message || 'Please check your email for the reset link.';
            this.toastr.success(this.successMessage, 'Success');
            this.errorMessage = null;
            this.loading = false;
          },
          error: (error: HttpErrorResponse) => {
            console.error('Forgot password error response:', error);
            if (error.error && error.error.message) {
              this.errorMessage = error.error.message;
            } else {
              this.errorMessage =
                'Failed to send reset link. Please try again.';
            }
            this.toastr.error(
              this.errorMessage || 'Something went wrong',
              'Error'
            );
            this.successMessage = null;
            this.loading = false;
          },
        });
    }
  }
}
