import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  inject,
  HostListener,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { MatOptionModule } from '@angular/material/core';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';
import * as selectPermissions from '../../core/permissions/permission.selector';
import { Store } from '@ngrx/store';
import { LocationServices } from '../../features/locations/services/locations.service';

@Component({
  selector: 'app-branch',
  standalone: true,
  imports: [
    AgGridAngular,
    MatButtonModule,
    MatDialogModule,
    PermissionDirective,
  ],
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent {
  public viewPortSize: boolean = false;
  public rowData: any[] = [];
  Permission = Permission;

  public columnDefs: ColDef[] = [
    {
      field: 'locationName',
      headerName: 'Location Name',
      flex: this.viewPortSize ? 1 : 0,
    },
    {
      field: 'longitude',
      headerName: 'Longitude',
      flex: this.viewPortSize ? 1 : 0,
    },
    {
      field: 'latitude',
      headerName: 'Latitude',
      flex: this.viewPortSize ? 1 : 0,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: this.viewPortSize ? 1 : 0,
      cellRenderer: (params: any) => {
        const div = document.createElement('div');
        div.style.display = 'flex';
        div.style.alignItems = 'center';
        div.style.gap = '20px';

        const createButton = (iconSrc: string, onClick: () => void) => {
          const span = document.createElement('span');
          span.style.cursor = 'pointer';
          span.style.display = 'flex';
          span.innerHTML = `<img src="${iconSrc}"/>`;
          span.addEventListener('click', onClick);
          return span;
        };
        this.store
          .select(selectPermissions.hasPermission('31', Permission.Edit))
          .subscribe((canEdit) => {
            if (canEdit) {
              div.appendChild(
                createButton('assets/images/icons/icon-edit.svg', () =>
                  this.openEditDialog(params?.data)
                )
              );
            }
          });
        this.store
          .select(selectPermissions.hasPermission('31', Permission.Delete))
          .subscribe((canDelete) => {
            if (canDelete) {
              div.appendChild(
                createButton('assets/images/icons/icon-delete.svg', () =>
                  this.onDeleteLocation(params.data?.id)
                )
              );
            }
          });
        return div;
      },
      floatingFilter: false,
      filter: false,
    },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10];
  public themeClass: string = 'ag-theme-quartz';
  public loading: boolean = true;
  public error: any = null;

  private searchSubject = new Subject<string>();
  profileName: any;

  constructor(
    private locationService: LocationServices,
    private toastr: ToastrService,
    private store: Store
  ) {
    const loggedInUser: any = localStorage.getItem('loggedInUser');
    const userData = JSON.parse(loggedInUser);
    this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit() {
    this.fetchLocationsData();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchLocationsData(searchTerm);
      });

    this.checkViewportSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {
    this.columnDefs = [
      {
        field: 'locationName',
        headerName: 'Location Name',
        flex: this.viewPortSize ? 1 : 0,
      },

      {
        field: 'longitude',
        headerName: 'Longitude',
        flex: this.viewPortSize ? 1 : 0,
      },
      {
        field: 'latitude',
        headerName: 'Latitude',
        flex: this.viewPortSize ? 1 : 0,
      },

      {
        field: 'action',
        headerName: 'Action',
        // flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const div = document.createElement('div');
          div.style.display = 'flex';
          div.style.alignItems = 'center';
          div.style.gap = '20px';

          const createButton = (iconSrc: string, onClick: () => void) => {
            const span = document.createElement('span');
            span.style.cursor = 'pointer';
            span.style.display = 'flex';
            span.innerHTML = `<img src="${iconSrc}"/>`;
            span.addEventListener('click', onClick);
            return span;
          };
          // div.appendChild(createButton('assets/images/icons/icon-edit.svg', () => this.openEditDialog(params ?. data)));
          // if(this.profileName === 'admin'){
          // div.appendChild(createButton('assets/images/icons/icon-delete.svg', () => this.onDeleteBranch(params.data ?. id)));
          // }
          this.store
            .select(selectPermissions.hasPermission('31', Permission.Edit))
            .subscribe((canEdit) => {
              if (canEdit) {
                div.appendChild(
                  createButton('assets/images/icons/icon-edit.svg', () =>
                    this.openEditDialog(params?.data)
                  )
                );
              }
            });
          this.store
            .select(selectPermissions.hasPermission('31', Permission.Delete))
            .subscribe((canDelete) => {
              if (canDelete) {
                div.appendChild(
                  createButton('assets/images/icons/icon-delete.svg', () =>
                    this.onDeleteLocation(params.data?.id)
                  )
                );
              }
            });
          return div;
        },
        floatingFilter: false,
        filter: false,
      },
    ];
  }

  onDeleteLocation(locationId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteLocationById(locationId);
      }
    });
  }

  private deleteLocationById(locationId: number): void {
    this.locationService.removeLocationById(locationId).subscribe({
      next: () => {
        this.toastr.success('Location deleted successfully!', 'Success');
        this.fetchLocationsData();
      },
      error: () => {
        this.toastr.error(
          'Failed to delete Location. Please try again.',
          'Error'
        );
      },
    });
  }

  private fetchLocationsData(search: string = '') {
    this.loading = true;
    this.locationService.fetchLocations(search).subscribe({
      next: (locations) => {
        let inProgressCount = 0;

        // Sort the departments by 'id' in descending order so the newest branch comes first
        this.rowData = locations
          .map((location: any) => {
            const projectsInProgress = Array.isArray(location?.projects)
              ? location.projects.filter(
                  (project: any) => project.status === 'In-Progress'
                )
              : [];

            inProgressCount += projectsInProgress.length;

            return {
              id: location?.id || '--',
              locationName: location?.locationName || '--',
              longitude: location?.longitude || '--',
              latitude: location?.latitude || '--',
            };
          })
          .sort((a: any, b: any) => b.id - a.id);

        this.paginationPageSizeSelector =
          PaginationHelper.getPaginationPageSizeOptions(this.rowData);

        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  readonly dialog = inject(MatDialog);

  openConfirm() {
    const dialogRef = this.dialog.open(ConfirmDialog);
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddLocationDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.fetchLocationsData();
      }
    });
  }

  openEditDialog(departmentId: any) {
    const dialogRef = this.dialog.open(AddLocationDialog, {
      disableClose: true,
      data: departmentId,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.fetchLocationsData();
      }
    });
  }
}

const gridDiv = document.querySelector<HTMLElement>('#myGrid')!;

@Component({
  selector: 'add-branch-dialog',
  templateUrl: './add-locations.component.html',
  styleUrls: ['./locations.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    CommonModule,
  ],
})
export class AddLocationDialog implements OnInit {
  @Output() branchAdded = new EventEmitter<any>();
  locationForm!: FormGroup;
  countries: any[] = [];
  locationsData: any;
  loading: boolean = false;

  constructor(
    private locationService: LocationServices,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<AddLocationDialog>,
    private http: HttpClient,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.locationsData = this.data;
    }
    this.initForm();
    // this.loadCountries();
    // this.generateBranchCode();
    // this.loadGoogleMapsScript().then(() => this.initMap());
  }

  initForm(): void {
    this.locationForm = this.fb.group({
      id: [this.locationsData?.id || ''],
      locationName: [
        this.locationsData?.locationName || '',
        Validators.required,
      ],
      longitude: [this.locationsData?.longitude?.toString() || ''],
      latitude: [this.locationsData?.latitude?.toString() || ''],
    });
  }

  // loadCountries(): void {
  //     this.http.get<any[]>('assets/json/countries.json').subscribe((data) => {
  //         this.countries = data;
  //     }, (error) => {
  //         console.log('Error loading country data----', error);
  //     });
  // }

  onAddLocation(): void {
    this.locationForm.markAllAsTouched();
  
    if (this.locationForm.valid) {
      this.loading = true;
  
      if (!this.locationsData) {
        const { locationName, longitude, latitude } = this.locationForm.value;
  
        const createLocationInput = {
          locationName,
          longitude: longitude?.toString() || '', 
          latitude: latitude?.toString() || '',
        };
  
        this.locationService.createLocation(createLocationInput).subscribe(
          (response) => {
            this.loading = false;
            this.toastr.success('Location added successfully!', 'Success', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true,
            });
            this.dialogRef.close('success');
          },
          (error) => {
            this.loading = false;
            this.handleError(error, 'Failed to add location. Please try again.');
          }
        );
      } else {
        const locationsData = {
          ...this.locationForm.value,
          longitude: this.locationForm.value.longitude?.toString() || '',
          latitude: this.locationForm.value.latitude?.toString() || '',
        };
  
  
        this.locationService.updateLocation(locationsData).subscribe(
          (response) => {
            this.loading = false;
            this.toastr.success('Location updated successfully!', 'Success', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true,
            });
            this.dialogRef.close('success');
          },
          (error) => {
            this.loading = false;
            this.handleError(error, 'Failed to update location. Please try again.');
          }
        );
      }
    }
  }
  
  // Helper method to handle errors
  private handleError(error: any, defaultMessage: string): void {
    let errorMessage = defaultMessage;
    if (error.error?.message) {
      errorMessage = error.error.message;
    } else if (error.message) {
      errorMessage = error.message;
    }
  
    this.toastr.error(errorMessage, 'Error', {
      timeOut: 5000,
      progressBar: true,
      closeButton: true,
    });
  }
  

  isMapVisible = false; 
  openGoogleMaps() {
    this.isMapVisible = true; 
    this.loadGoogleMapsScript().then(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            // Update form fields with current location
            this.locationForm.patchValue({
              latitude: latitude,
              longitude: longitude,
            });

            // Initialize map with the current location
            this.initMap(latitude, longitude);
          },
          (error) => {
            console.error('Error fetching location:', error);
            alert(
              'Unable to retrieve your location. Please enable location services.'
            );
          }
        );
      } else {
        alert('Geolocation is not supported by this browser.');
      }
    });
  }

  async loadGoogleMapsScript(): Promise<void> {
    return new Promise((resolve) => {
      if ((window as any).google && (window as any).google.maps) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      //   need to provide a valid api key below
      script.src =
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyDTahXyxuGYOJdAi8_CSC3lLGL9sZcebek&libraries=places';
        // 'https://maps.googleapis.com/maps/api/js?sensor=false&callback=myMap';
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      document.head.appendChild(script);
    });
  }

  async initMap(lat: number, lng: number) {
    const { Map, InfoWindow } = await (window as any).google.maps.importLibrary('maps');
    const { AdvancedMarkerElement } = await (window as any).google.maps.importLibrary('marker');
  
    const map = new Map(document.getElementById('map'), {
      center: { lat, lng },
      zoom: 14,
      mapId: '4504f8b37365c3d0',
    });
  
    const infoWindow = new InfoWindow();
    const draggableMarker = new AdvancedMarkerElement({
      map,
      position: { lat, lng },
      gmpDraggable: true,
      title: 'Drag to update location',
    });
  
    // Update form fields on marker drag end
    draggableMarker.addListener('dragend', () => {
      const newPosition = draggableMarker.position;
      this.locationForm.patchValue({
        latitude: newPosition.lat,
        longitude: newPosition.lng,
      });
  
      // Update InfoWindow content
      infoWindow.close();
      infoWindow.setContent(`Pin dropped: ${newPosition.lat}, ${newPosition.lng}`);
      infoWindow.open(draggableMarker.map, draggableMarker);
    });
  }
  
}
