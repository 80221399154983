import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { filter } from 'rxjs';

@Component({
    selector: 'app-projects',
    standalone: true,
    imports: [
        CommonModule,
        AgGridAngular,
    ],
    templateUrl: './projects.component.html',
    styleUrl: '../../employee-details.component.scss'
})
export class ProjectsComponent {

    public themeClass: string = 'ag-theme-quartz';

    public rowData: any[] | null = [
        {
            no: 1,
            project: "Amongus - Discovery Phase",
            start: "Feb 01, 2023",
            finish: "Mar 05, 2023",
            status: "Completed",
        },
        {
            no: 1,
            project: "Amongus - Discovery Phase",
            start: "Feb 01, 2023",
            finish: "Mar 05, 2023",
            status: "In Process",
        },
    ];
    public columnDefs: ColDef[] = [
        { headerName: 'Sl. No.', field: "no", },
        { headerName: 'Project Name', field: "project", flex: 1 },
        { headerName: 'Start Date', field: "start", flex: 1 },
        { headerName: 'Finish Date', field: "finish", flex: 1 },
        {
            headerName: 'Status',
            field: 'status',
            flex: 1,
            cellRenderer: (params: any) => {
                const status = params.data.status;
                const statusClass = status === "Completed" ? 'completed' : ''; // Apply class if "On Leave"
                return `<span class="${statusClass}">${status}</span>`;
            }
        },
    ];
    public defaultColDef: ColDef = {
        filter: "agTextColumnFilter",
        floatingFilter: true,
    };
    public paginationPageSize = 25;
    public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];

}
