import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { RolesService } from '../../features/roles/services/roles.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as PermissionActions from '../../core/permissions/permission.action';

@Component({
  selector: 'app-roles-management',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    FormsModule
  ],
  templateUrl: './roles-management.component.html',
  styleUrl: './roles-management.component.scss'
})
export class RolesManagementComponent {
  isDropdownOpen = false;
  filter: any = {};
  modules: any[] = [];
  search: any;
  permissions: any;
  assignedModule: any;
  moduleId: any;
  profileId: any;
  selectedPermissionIds: number[] = [];
  isToggleOn: boolean = false;
  permissionNames: any[] = [];
  permissionMap: any = {};
  permissionMapId: any = {};
  // isChildDropdownOpen: boolean;
  dropdownState: { [key: number]: boolean } = {};
  mainDropdownState: { [key: number]: boolean } = {};
  childModules: any;
  permissionsState: { [key: number]: { isToggleOn: boolean } } = {};  // Track toggle state for each module
  profileName: any= '';


  constructor(
    private rolesService: RolesService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private store: Store,

  ) { }

  ngOnInit() {
    // this.getModules();
    // this.getPermissions();
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      console.log(id);

      if (id) {
        this.profileId = id;
        this.fetchProfile(Number(id));
        this.getPermissionsById(this.profileId);
        this.getPermissions();
      }
    })
  }

  toggleDropdown(event?: MouseEvent, moduleId?: any) {
    if (event) {
      event.stopPropagation();
    }
    if (moduleId) {
      this.moduleId = moduleId;
      this.markAssignedPermissions(this.moduleId)
    }
    this.mainDropdownState[moduleId] = !this.mainDropdownState[moduleId];
    this.markAssignedPermissions(moduleId);
  }

  toggleChildDropdown(event?: Event, moduleId?: any): void {
    const mouseEvent = event as MouseEvent;
    if (mouseEvent) {
      mouseEvent?.stopPropagation();
    }
    if (moduleId) {
      this.moduleId = moduleId;
      this.markAssignedPermissions(moduleId)
    }
    this.dropdownState[moduleId] = !this.dropdownState[moduleId];
    this.markAssignedPermissions(moduleId)
  }

  fetchProfile(id: any) {
    this.rolesService.profile(id).subscribe({
      next: (data) => {
        this.profileName = data?.profileName;
        this.getModules();
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  getModules() {
    this.rolesService.modules().subscribe({
      next: (data) => {
        if (this.profileName.toLowerCase() === 'admin') {
          this.modules = this.checkUserType(data, 36);
        } else {
          this.modules = data;
        }
        this.childModules = data?.childModules;
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  checkUserType(array: any, roleId: number) {
    return (array.filter((el: any) => el.parentModuleId !== roleId))
  }

  getPermissions() {
    this.rolesService.permissions(this.search).subscribe({
      next: (data) => {
        this.permissions = data;
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  getPermissionsById(id: any) {
    if (id) {
      this.rolesService?.getModuleData(Number(id)).subscribe({
        next: (data) => {
          this.assignedModule = data;
          this.populatePermissionMap()
        },
        error: (error) => {
          console.error(error, 'Error');
        }
      })
    }
  }

  populatePermissionMap() {
    this.permissionMap = { ...this.permissionMap };
    this.permissionMapId = { ...this.permissionMapId };

    this.assignedModule?.forEach((module: any) => {
      const permissions = module.rolesAndPermissions?.[0]?.permissions || [];
      const activePermissions = permissions.filter((perm: any) => perm.status === 1);

      this.permissionMap[module.id] = permissions
        .filter((perm: any) => perm?.status === 1) // Only include active permissions
        .map((perm: any) => perm.permissionName)
        .sort((a: string, b: string) => a.localeCompare(b));; // Extract permission names

      this.permissionMapId[module.id] = permissions
        .filter((perm: any) => perm?.status === 1) // Only include active permissions
        .map((perm: any) => perm.id);

      this.permissionsState[module.id] = {
        isToggleOn: activePermissions.length > 0 && activePermissions.length === permissions.length,
      };
    });
  }


  markAssignedPermissions(moduleId: number) {
    if (!this.assignedModule || this.assignedModule.length === 0) {
      console.error('No assigned module data available.');
      return;
    }

    const rolesAndPermissions = this.assignedModule;
    const module = rolesAndPermissions.find((mod: any) => mod.id === moduleId);

    if (!module) {
      console.error(`Module with ID ${moduleId} not found.`);
      return;
    }
    const rolesAndPermissionsForModule = module.rolesAndPermissions;

    if (!rolesAndPermissionsForModule || rolesAndPermissionsForModule.length === 0) {
      console.error(`No roles and permissions found for module ID ${moduleId}.`);
      return;
    }

    console.log('rolesAndPermissionsForModule', rolesAndPermissionsForModule);

    // Find the role within the rolesAndPermissions array
    const role = rolesAndPermissionsForModule.find(
      (rolePermission: any) => {
        rolePermission.profileId === this.profileId;
        return rolePermission;
      }
      // Assuming profileId is used to identify roles
    );
    if (role && role.permissions) {
      const assignedPermissionIds = role.permissions
        .filter((perm: any) => perm?.status === 1)
        .map((perm: any) => perm.id);

      this.permissions = this.permissions.map((permission: any) => ({
        ...permission,
        checked: assignedPermissionIds.includes(permission.id), // Add or update `checked`
      }
      )
      );
      this.selectedPermissionIds = this.permissions
        .filter((permission: any) => permission.checked)
        .map((permission: any) => permission.id);

    } else {
      console.error('Role not found or no permissions assigned.');
      this.permissions = this.permissions.map((permission: any) => ({
        ...permission,
        checked: false,
      }));
    }
  }

  togglePermission(permissionId: number, event: Event, moduleId: number): void {
    const checkbox = event.target as HTMLInputElement;

    // Update the checked state of the specific permission
    const permission = this.permissions.find((p: any) => p.id === permissionId);
    if (permission) {
      permission.checked = checkbox?.checked;
    }

    // Recalculate the isToggleOn state for the module based on the permissions

    // const allPermissionsChecked = this.permissions.every((p: any) => p.checked);
    // this.permissionsState[moduleId] = {
    //   isToggleOn: allPermissionsChecked
    // };

    // Update the selectedPermissionIds based on the new checked state
    if (checkbox?.checked) {
      this.selectedPermissionIds.push(permissionId);
    } else {
      this.selectedPermissionIds = this.selectedPermissionIds.filter(id => id !== permissionId);
    }

  // Ensure permission List is only added if any permission is checked
  const anyPermissionChecked = this.permissions.some((p: any) => p.checked);
  if (anyPermissionChecked) {
    if (!this.selectedPermissionIds.includes(4)) {
      this.selectedPermissionIds.push(4);
    }
  } else {
    this.selectedPermissionIds = this.selectedPermissionIds.filter(id => id !== 4);
  }
  }

  toggleAllPermissions(event: Event, moduleId: number): void {
    const toggleSwitch = event.target as HTMLInputElement;
    const isChecked = toggleSwitch.checked;

    this.permissionsState[moduleId] = {
      isToggleOn: isChecked,
    };
    this.permissions = this.permissions.map((permission: any) => ({
      ...permission,
      checked: isChecked,
    }));

    if (isChecked) {
      // Add all permission IDs from the current permissions list
      this.selectedPermissionIds = Array.from(new Set([
        ...this.selectedPermissionIds,
        ...this.permissions.map((permission: any) => permission.id),
      ]));
    } else {
      // Remove all permission IDs from the current permissions list
      this.selectedPermissionIds = this.selectedPermissionIds.filter(
        (id) => !this.permissions.some((permission: any) => permission.id === id)
      );
    }
    this.moduleId = moduleId;
    this.onApply();

  }


  onApply() {
    // if(this.selectedPermissionIds.length === 0){
    //   if (this.dropdownState[this.moduleId]) {
    //     this.dropdownState[this.moduleId] = false;
    //   }
    //   if (this.mainDropdownState[this.moduleId]) {
    //     this.mainDropdownState[this.moduleId] = false;
    //   }
    //     return;
    // }
    this.rolesService.updateRolesPermissions(this.profileId, this.selectedPermissionIds, String(this.moduleId)).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Permission updated successfully', 'Success');

          // Update localStorage rolesAndPermision
          const rolesAndPermision = JSON.parse(localStorage.getItem('rolesAndPermision') || '[]');
          const moduleIndex = rolesAndPermision.findIndex((module: any) => module.moduleId === this.moduleId);

          if (moduleIndex !== -1) {
            rolesAndPermision[moduleIndex].permissions = this.selectedPermissionIds.map((id: number) => {
              return this.permissions.find((permission: any) => permission.id === id);
            });
            localStorage.setItem('rolesAndPermision', JSON.stringify(rolesAndPermision));
          }

          if (this.dropdownState[this.moduleId]) {
            this.dropdownState[this.moduleId] = false;
          }
          if (this.mainDropdownState[this.moduleId]) {
            this.mainDropdownState[this.moduleId] = false;
          }

          this.store.dispatch(PermissionActions.setPermissions({ permissions: this.permissionMapId }));
          this.getModules();
          this.getPermissions();
          this.getPermissionsById(this.profileId);
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }
}

