import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PermissionState } from './permission.reducer';

export const selectPermissionState = createFeatureSelector<PermissionState>('permissions');

export const selectPermissions = createSelector(
  selectPermissionState,
  (state: PermissionState) => state.permissions
);

export const selectMyProfile = createSelector(
  selectPermissionState,
  (state: PermissionState) => state.myProfile
);

export const selectHeaderTitle = createSelector(
  selectPermissionState,
  (state: PermissionState) => state.headerTitle
);


export const hasModuleId = (moduleId: any) =>
  createSelector(selectPermissions, (permissions) =>{
    return !!permissions[moduleId] // Return true if module ID exists
    }
  );

export const hasPermission = (moduleId: string, permissionId: number) =>
  createSelector(selectPermissions, (permissions: any) => {
    return permissions[moduleId]?.includes(permissionId) || false;
  }
);

 
