<div class="exit-tab-content-wrapper">
  <form [formGroup]="employeeExitForm" (ngSubmit)="onSubmit()">
    <div class="exit-form-wrapper">
      <div class="form-wrapper">
        <label for="">Exit Date</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker5"
            formControlName="exitDate"
            (dateChange)="onExitDateChange($event.value)"
            placeholder="Exit Date"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker5"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker5></mat-datepicker>
          <mat-error
            *ngIf="
              employeeExitForm.get('exitDate')!.touched &&
              employeeExitForm.get('exitDate')!.hasError('required')
            "
            >Exit Date is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Employee Exit Type</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Employee Exit Type"
            formControlName="exitType"
          >
            <mat-option
              *ngFor="let status of employeeExitTypes"
              [value]="status.value"
            >
              {{ status.label }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              employeeExitForm.get('exitType')!.touched &&
              employeeExitForm.get('exitType')!.hasError('required')
            "
            >Employee Exit Type is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper full-width">
        <label for="">Reason for Exit</label>
        <mat-form-field>
          <textarea
            matInput
            placeholder="Enter The Reason for Exit"
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="6"
            formControlName="exitReason"
          ></textarea
          ><mat-error
            *ngIf="
              employeeExitForm.get('exitReason')!.touched &&
              employeeExitForm.get('exitReason')!.hasError('required')
            "
            >Exit Reason is required.</mat-error
          >
        </mat-form-field>
      </div>
      <!-- <div class="form-wrapper">
        <label for="">Exit Interview Date</label>
        <mat-form-field appearance="fill">
          <input
            matInput
            [matDatepicker]="interviewPicker"
            formControlName="interviewDate"
            [disabled]="!exitDate"
            [matDatepickerFilter]="interviewDateFilter"
            placeholder="Exit Interview Date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="interviewPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #interviewPicker></mat-datepicker>
          <mat-error
            *ngIf="employeeExitForm.get('interviewDate')?.errors?.['required']"
          >
            Exit Interview date is required.
          </mat-error>
        </mat-form-field>
      </div> -->
      <div class="form-wrapper">
        <label for="">Exit Interview Date</label>
        <mat-form-field appearance="fill">
          <input
            matInput
            [matDatepicker]="interviewPicker"
            formControlName="interviewDate"
            [matDatepickerFilter]="interviewDateFilter"
            placeholder="Exit Interview Date"
          />
          <mat-datepicker-toggle matSuffix [for]="interviewPicker"></mat-datepicker-toggle>
          <mat-datepicker #interviewPicker></mat-datepicker>
          <mat-error *ngIf="employeeExitForm.get('interviewDate')?.hasError('required')">
            Exit Interview date is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper gratuity-input">
        <label for="">Gratuity</label>
        <mat-form-field appearance="fill">
          <input
            matInput
            placeholder="Gratuity"
          />
        </mat-form-field>
        <span class="gratuity-btn" (click)="toggleDropdown($event)"><img src="assets/images/icons/icon-info.svg" alt="Info"></span>
        <!-- <button
        class="cmn-filter-btn"
        (click)="toggleDropdown($event)"
        [ngClass]="{ 'class-clicked': isDropdownOpen }"
      >
        <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
      </button> -->
      <div
        [ngClass]="{ show: isDropdownOpen }"
        class="gratuity-popup"
        #dropdown >
      
        <button
          class="close-dropdown"
          (click)="toggleDropdown($event)"
        ></button>
          <h6 class="cmn-popup-heading">
            Example Calculation
          </h6>
            <div class="gratuity-box">
              <h3>Scenario:</h3>
              <ul>
                  <li>Basic Salary: AED 7,000</li>
                  <li>Years of Service: 6 years</li>
              </ul>
              <ul>
                  <li>
                      <h4>First 5 Years:</h4>
                      <h5>(7,000/30) * 21 * 5 = 24, 500AED</h5>
                  </li>
                  <li>
                      <h4>Beyond 5 Years:</h4>
                      <h5>(7,000/30) * 30 * 1 = 7, 000AED</h5>
                  </li>
                  <li>
                      <h4>Total Gratuity:</h4>
                      <h5>24,500 + 7,000 = 31,500AED
                      </h5>
                  </li>
              </ul>
          </div>
      </div>
      </div>
      <div class="form-wrapper full-width">
        <label for="">Interview Notes</label>
        <mat-form-field>
          <textarea
            matInput
            placeholder="Enter the Interview Notes"
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="6"
            formControlName="interviewNotes"
          ></textarea
          ><mat-error
            *ngIf="
              employeeExitForm.get('interviewNotes')!.touched &&
              employeeExitForm.get('interviewNotes')!.hasError('required')
            "
            >Interview Note is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Clearance Status</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Clearance Status"
            formControlName="clearanceStatus"
          >
            <mat-option
              *ngFor="let status of clearanceStatusData"
              [value]="status.value"
            >
              {{ status.label }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              employeeExitForm.get('clearanceStatus')!.touched &&
              employeeExitForm.get('clearanceStatus')!.hasError('required')
            "
            >Clearance Status is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Clearance Date</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker7"
            formControlName="clearanceDate"
            placeholder="Clearance Date"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker7"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker7></mat-datepicker>
          <mat-error
            *ngIf="
              employeeExitForm.get('clearanceDate')!.touched &&
              employeeExitForm.get('clearanceDate')!.hasError('required')
            "
            >Clearance Date is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Settlement Status</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Settlement Status"
            formControlName="settlementStatus"
          >
            <mat-option
              *ngFor="let status of settlementStatusData"
              [value]="status.value"
            >
              {{ status.label }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              employeeExitForm.get('settlementStatus')!.touched &&
              employeeExitForm.get('settlementStatus')!.hasError('required')
            "
            >Settlement Status is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Settlement Amount</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Settlement Amount"
            formControlName="settlementAmount"
            type="number"
          />
          <mat-error
            *ngIf="
              employeeExitForm.get('settlementAmount')!.touched &&
              employeeExitForm.get('settlementAmount')!.hasError('required')
            "
            >Settlement Amount is required.</mat-error
          ><mat-error
          *ngIf="
            employeeExitForm.get('settlementAmount')?.hasError('nonNegative')
          "
        >Settlement Amount cannot be negative. </mat-error
        >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Settlement Date</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker8"
            formControlName="settlementDate"
            placeholder="Settlement Date"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker8"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker8></mat-datepicker>
          <mat-error
            *ngIf="
              employeeExitForm.get('settlementDate')!.touched &&
              employeeExitForm.get('settlementDate')!.hasError('required')
            "
            >Settlement Date is required.</mat-error
          >
        </mat-form-field>
      </div>
      <!-- <div class="form-wrapper">
        <label for="">Last Working Day</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker9"
            formControlName="lastWorkingDay"
            placeholder="Last Working Day"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker9"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker9></mat-datepicker>
          <mat-error
            *ngIf="
              employeeExitForm.get('lastWorkingDay')!.touched &&
              employeeExitForm.get('lastWorkingDay')!.hasError('required')
            "
            >Last working day is required.</mat-error
          >
        </mat-form-field>
      </div> -->
      <div class="form-wrapper">
        <label for="">Last Working Day</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker9"
            formControlName="lastWorkingDay"
            placeholder="Last Working Day"
            [matDatepickerFilter]="lastWorkingDayFilter"
          />
          <mat-datepicker-toggle matIconSuffix [for]="picker9"></mat-datepicker-toggle>
          <mat-datepicker #picker9></mat-datepicker>
          <mat-error *ngIf="employeeExitForm.get('lastWorkingDay')?.hasError('required')">
            Last working day is required.
          </mat-error>
          <mat-error *ngIf="employeeExitForm.hasError('lastWorkingDayBeforeInterviewDate')">
            Last working day cannot be before the exit interview date.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Feedback Score</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Feedback Score"
            formControlName="feedbackScore"
            type="number"
          />
          <mat-error
            *ngIf="
              employeeExitForm.get('feedbackScore')!.touched &&
              employeeExitForm.get('feedbackScore')!.hasError('required')
            "
          >
            Feedback score is required.
          </mat-error>
          <mat-error
            *ngIf="
              employeeExitForm.get('feedbackScore')!.touched &&
              employeeExitForm.get('feedbackScore')!.hasError('min')
            "
          >
            Feedback score cannot be less than 0.
          </mat-error>
          <mat-error
            *ngIf="
              employeeExitForm.get('feedbackScore')!.touched &&
              employeeExitForm.get('feedbackScore')!.hasError('max')
            "
          >
            Feedback score cannot be more than 10.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="checkins-wrapper">
      <h2>Check Ins</h2>
      <div class="checkins-list">
        <label class="checkbox-wrapper">
          <input type="checkbox" formControlName="isAssetReturned" />Have the
          company's assets been handed over?
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="checkins-list">
        <label class="checkbox-wrapper">
          <input type="checkbox" formControlName="isNoticePeriod" />Has the
          employee completed their notice period?
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="checkins-list">
        <label class="checkbox-wrapper">
          <input type="checkbox" formControlName="isExitInterview" />Has the
          employee completed the exit interview?
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="checkins-list">
        <label class="checkbox-wrapper">
          <input type="checkbox" formControlName="isAccountsDisabled" />Has the
          employee's account been disabled?
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="checkins-list">
        <label class="checkbox-wrapper">
          <input type="checkbox" formControlName="isManagerApproval" />Has the
          manager given approval?
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="checkins-list">
        <label class="checkbox-wrapper">
          <input type="checkbox" formControlName="isHrApproval" />Has HR given
          approval?
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="checkins-list">
        <label class="checkbox-wrapper">
          <input type="checkbox" formControlName="isFinalApproval" />Has final
          approval been granted?
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="checkins-list">
        <label class="checkbox-wrapper">
          <input type="checkbox" formControlName="isRehireEligibility" />Is the
          employee eligible for rehire?
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="checkins-list">
        <label class="checkbox-wrapper">
          <input type="checkbox" formControlName="isVehicleStatus" />Has the
          employee completed the vehicle return process?
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="checkins-list">
        <label class="checkbox-wrapper">
          <input type="checkbox" formControlName="isToolStatus" />Have all
          company tools been returned by the employee?
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div class="documents-wrapper">
      <h2>Documents</h2>
      <div class="upload-wrapper">
        <div class="cmn-upload-box">
          <h5>
            Upload <span>Resignation Letter</span> &
            <span>Manager/Supervisor</span> Clearence
          </h5>
          <h3>Drag and drop your file here</h3>
          <h4>or</h4>
          <input
            #fileInput
            type="file"
            accept=".csv, .pdf, .jpg, .jpeg, .png, .txt"
            (change)="onFileSelected($event)"
            style="display: none"
          />
          <button
            class="cmn-choose-file-btn"
            (click)="fileInput.click()"
            type="button"
          >
            <img
              src="assets/images/icons/icon-add-color.svg"
              alt="Icon Add"
            />Choose a File
          </button>
        </div>
      </div>
      <!-- Display existing file name and link if it exists -->
      <div class="uploaded-file" *ngIf="existingFileUrl">
        <h6>
          <a [href]="existingFileUrl" target="_blank" rel="noopener noreferrer">
            {{ existingFileUrl.split("/").pop() }}
            <!-- Display the file name -->
          </a>
          <img
            src="assets/images/icons/icon-close.svg"
            alt="Close Icon"
            (click)="removeFile()"
          />
        </h6>
      </div>

      <!-- Display newly selected file -->
      <div class="uploaded-file" *ngIf="csvFile">
        <h6>
          {{ csvFile.name }}
          <img
            src="assets/images/icons/icon-close.svg"
            alt="Close Icon"
            (click)="removeFile()"
          />
        </h6>
      </div>
    </div>
    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
      <button class="cmn-next-btn" type="submit">
        {{ isEditMode ? "Update" : "Submit" }}
      </button>
    </div>
  </form>
</div>
