import { gql } from 'apollo-angular';

export const GET_PROFIT_LOSS_REPORT = gql`
  query getProfitLoss($startDate: DateTime!, $endDate: DateTime!) {
    getProfitLoss(startDate: $startDate, endDate: $endDate) {
      operatingIncome {
        total
        details {
          accountName
          amount
        }
      }
      costOfGoodsSold {
        total
        details {
          accountName
          amount
        }
      }
      expenses {
        total
        details {
          accountName
          amount
        }
      }
      nonOperatingIncome {
        total
        details {
          accountName
          amount
        }
      }
      nonOperatingExpense {
        total
        details {
          accountName
          amount
        }
      }
      grossProfit
      operatingProfit
      totalNonOperatingExpense
      netProfitOrLoss
    }
  }
`;

export const GET_CASH_FLOW_REPORT = gql`
  query cashFlowStatement($startDate: DateTime!, $endDate: DateTime!) {
    cashFlowStatement(startDate: $startDate, endDate: $endDate) {
      beginningCashBalance
      operatingActivities {
        total
        details {
          accountName
          amount
        }
      }
      investingActivities {
        total
        details {
          accountName
          amount
        }
      }
      financingActivities {
        total
        details {
          accountName
          amount
        }
      }
      netChangeInCash
      endingCashBalance
    }
  }
`;

export const GET_BALANCE_SHEET_REPORT = gql`
  query getBalanceSheet($startDate: DateTime!, $endDate: DateTime!) {
    getBalanceSheet(startDate: $startDate, endDate: $endDate) {
      cash {
        total
        details {
          accountName
          amount
        }
      }
      bank {
        total
        details {
          accountName
          amount
        }
      }
      accountsReceivable {
        total
        details {
          accountName
          amount
        }
      }
      otherCurrentAssets {
        total
        details {
          accountName
          amount
        }
      }
      totalCurrentAssets
      fixedAssets {
        total
        details {
          accountName
          amount
        }
      }
      totalAssets
      currentLiabilities {
        total
        details {
          accountName
          amount
        }
      }
      longTermLiabilities {
        total
        details {
          accountName
          amount
        }
      }
      totalLiabilities
      equities {
        total
        details {
          accountName
          amount
        }
      }
      totalEquities
      totalLiabilitiesAndEquities
    }
  }
`;

export const GET_SALES_BY_ITEM_REPORT = gql`
  query salesByItem($startDate: String, $endDate: String) {
    salesByItem(startDate: $startDate, endDate: $endDate) {
      items {
        itemName
        skuCode
        quantitySold
        amount
        averagePrice
      }
      totalQuantity
      totalAmount
    }
  }
`;

export const GET_SALES_BY_CUSTOMER_REPOTT = gql`
  query salesByCustomer($startDate: String, $endDate: String) {
    salesByCustomer(startDate: $startDate, endDate: $endDate) {
      salesByCustomer {
        partnerId
        partnerName
        totalSales
        totalInvoices
      }

      totalInvoiceCount
      totalSalesAmount
    }
  }
`;

export const GET_ACCOUNT_TYPE_SUMMARY = gql`
  query getAccountTypesSummary($startDate: String, $endDate: String) {
    getAccountTypesSummary(startDate: $startDate, endDate: $endDate) {
      accountTypeSummary {
        groupName
        groupNameFormatted
        accountsList {
          accountType
          accountTypeFormatted
          accountTypeId
          debit
          debitFormatted
          credit
          creditFormatted
          values {
            debit
            credit
            debitFormatted
            creditFormatted
          }
        }
      }
      id
      accountType
      debit
      credit
      reportType
      fromDateFormatted
      toDateFormatted
    }
  }
`;

export const GET_JOURNAL_REPORT = gql`
  query journalsReport($startDate: String, $endDate: String) {
    journalsReport(startDate: $startDate, endDate: $endDate) {
      journalDate
      journalId
      description
      entries {
        accountName
        debit
        credit
      }
      totalDebit
      totalCredit
    }
  }
`;

export const GET_GENERAL_LEDGER_REPORT = gql`
  query journalsLedgerReport($startDate: String, $endDate: String) {
    journalsLedgerReport(startDate: $startDate, endDate: $endDate) {
      journalDate
      journalId
      entries {
        accountName
        debit
        credit
        partnerDisplayName
      }
    }
  }
`;

export const GET_CUSTOMER_BALANCE_SUMMARY_REPORT = gql`
  query customerBalanceSummary($startDate: DateTime, $endDate: DateTime) {
    customerBalanceSummary(startDate: $startDate, endDate: $endDate) {
      summary {
        customerName
        invoicedAmount
        amountReceived
        closingBalance
      }
      totals {
        totalInvoicedAmount
        totalAmountReceived
        totalClosingBalance
      }
    }
  }
`;

export const PAYMENT_RECEIVED_REPORT = gql`
  query paymentReceivedReport($startDate: DateTime!, $endDate: DateTime!) {
    paymentReceivedReport(startDate: $startDate, endDate: $endDate) {
      paymentNo
      referenceNo
      customerName
      notes
      date
      depositTo
    }
  }
`;

export const GET_PAYMENT_MADE_REPORT = gql`
  query getPaymentMadeReports($startDate: String!, $endDate: String!) {
    getPaymentMadeReports(startDate: $startDate, endDate: $endDate) {
      paymentNo
      paymentMode
      billDate
      billNo
      note
      paidThrough
      status
      vendorName
    }
  }
`;

export const VENDOR_BALANCE_SUMMARY_REPORT = gql`
  query getVendorBalanceSummary($startDate: String!, $endDate: String!) {
    getVendorBalanceSummary(startDate: $startDate, endDate: $endDate) {
      summary {
        vendorName
        billedAmount
        amountPaid
        closingBalance
      }
      totals {
        totalBilledAmount
        totalAmountPaid
        totalClosingBalance
      }
    }
  }
`;

export const TRIAL_BALANCE_REPORT = gql`
  query getTrialBalanceReport($startDate: String!, $endDate: String!) {
    getTrialBalanceReport(startDate: $startDate, endDate: $endDate) {
      trialBalanceData {
        category
        accounts {
          accountName
          accountType
          # openingBalance
          netCredit
          netDebit
          # closingBalance
        }
        totalDebit
        totalCredit
        # openingBalance
        # closingBalance
      }
      totalDebits
      totalCredits
      isBalanced
    }
  }
`;

export const PETTYCASH_SUMMARY_REPORT = gql`
  query getPettyCashSummary($startDate: String!, $endDate: String!) {
    getPettyCashSummary(startDate: $startDate, endDate: $endDate) {
      projectSummary {
        projectId
        totalAmount
        projectDetails {
          id
          projectName
        }
      }
      expenseSummary {
        employeeId
        totalAmount
        employeeDetails {
          id
          firstName
          lastName
        }
      }
      totalInflow
    }
  }
`;

export const GET_INVOICE_AGING_REPORT = gql`
  query getInvoiceAgingReport($daysFilter: String!) {
    getInvoiceAgingReport(daysFilter: $daysFilter) {
      invoiceId
      invoiceNo
      customerName
      amount
      dueDate
      status
    }
  }
`;

export const GET_VENDOR_INVOICE_AGING_REPORT = gql`
  query getBillAgingReport($daysFilter: String!) {
    getBillAgingReport(daysFilter: $daysFilter) {
      billId
      billNo
      vendorName
      amount
      dueDate
      status
    }
  }
`;
