<div class="tab-inner-content">
  <h2>Profit & Loss</h2>
  <h5>
    From {{ startDate | date : "MM/d/YYYY" }} to
    {{ endDate | date : "MM/d/YYYY" }}
  </h5>
  <table>
    <tr>
      <th>Account</th>
      <th></th>
      <th>Total</th>
    </tr>
    <tr class="title">
      <td>Operating Income</td>
      <td></td>
      <td></td>
    </tr>
    <ng-container *ngFor="let item of profitLossData?.operatingIncome?.details">
      <tr class="child">
        <td>{{ item.accountName }}</td>
        <td></td>
        <td>{{ item.amount | number }}</td>
      </tr>
    </ng-container>
    <tr class="sub-total">
      <td>Total for Operating Income</td>
      <td></td>
      <td>{{ profitLossData?.operatingIncome?.total | number }}</td>
    </tr>
    <tr class="title">
      <td>Cost of Goods Sold</td>
      <td></td>
      <td></td>
    </tr>
    <ng-container *ngFor="let item of profitLossData?.costOfGoodsSold?.details">
      <tr class="child">
        <td>{{ item.accountName }}</td>
        <td></td>
        <td>{{ item.amount | number }}</td>
      </tr>
    </ng-container>
    <tr class="sub-total">
      <td>Total for Cost of Goods Sold</td>
      <td></td>
      <td>{{ profitLossData?.costOfGoodsSold?.total | number }}</td>
    </tr>
    <tr class="total">
      <td></td>
      <td>Gross Profit</td>
      <td>{{ profitLossData?.grossProfit | number }}</td>
    </tr>
    <tr class="title">
      <td>Operating Expense</td>
      <td></td>
      <td></td>
    </tr>
    <ng-container *ngFor="let item of profitLossData?.expenses?.details">
      <tr class="child">
        <td>{{ item.accountName }}</td>
        <td></td>
        <td>{{ item.amount | number }}</td>
      </tr>
    </ng-container>
    <tr class="sub-total">
      <td>Total for Operating Expense</td>
      <td></td>
      <td>{{ profitLossData?.expenses?.total | number }}</td>
    </tr>
    <tr class="total">
      <td></td>
      <td>Operating Profit</td>
      <td>{{ profitLossData?.operatingProfit | number }}</td>
    </tr>
    <tr class="title">
      <td>Non Operating Income</td>
      <td></td>
      <td></td>
    </tr>
    <ng-container
      *ngFor="let item of profitLossData?.nonOperatingIncome?.details"
    >
      <tr class="child">
        <td>{{ item.accountName }}</td>
        <td></td>
        <td>{{ item.amount | number }}</td>
      </tr>
    </ng-container>
    <tr class="sub-total">
      <td>Total for Non Operating Income</td>
      <td></td>
      <td>{{ profitLossData?.nonOperatingIncome?.total | number }}</td>
    </tr>
    <tr class="title">
      <td>Non Operating Expense</td>
      <td></td>
      <td></td>
    </tr>
    <ng-container
      *ngFor="let item of profitLossData?.nonOperatingExpense?.details"
    >
      <tr class="child">
        <td>{{ item.accountName }}</td>
        <td></td>
        <td>{{ item.amount | number }}</td>
      </tr>
    </ng-container>
    <tr class="sub-total">
      <td>Total for Non Operating Expense</td>
      <td></td>
      <td>{{ profitLossData?.nonOperatingExpense?.total | number }}</td>
    </tr>
    <tr class="total">
      <td></td>
      <td>Net Profit/Loss</td>
      <td>{{ profitLossData?.netProfitOrLoss | number }}</td>
    </tr>
  </table>
</div>
