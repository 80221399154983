import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EmployeeService } from '../../features/employees/services/employee.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loginApiUrl = `${environment.authUrl}/auth/login`;
  private isAuthenticated = new BehaviorSubject<boolean>(false);
  private token: string | null = null;
  private isHandlingInvalidToken = false;
  branchData: any[];

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private employeeService: EmployeeService,
  ) {
   }

  login(email: string, password: string): Observable<any> {
    return this.http.post(this.loginApiUrl, { email, password }).pipe(
      tap((response: any) => {
        localStorage.setItem('loggedInUser', JSON.stringify(response.user));
        localStorage.setItem('rolesAndPermision', JSON.stringify(response.transformedData));
        if (response.access_token) {
          localStorage.setItem('AUTH_TOKEN', response.access_token);
          this.isAuthenticated.next(true);
        }
      }),
      catchError((error) => {
        let errorMessage = 'An unknown error occurred';
        if (error.message) {
          errorMessage = error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  logoutFn() {
    this.router.navigate(['/login']);
    localStorage.removeItem('AUTH_TOKEN');
    localStorage.removeItem('loggedInUser');
    sessionStorage.removeItem('AUTH_TOKEN');
    sessionStorage.removeItem('loggedInUser');
    this.isAuthenticated.next(false);
    // window.location.reload();
  }

  logout() {
    this.toastr.success('You have been logged out', 'Session Ended');
    this.logoutFn();
  }

  isLoggedIn(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  setToken(token: string) {
    this.token = token;
  }

  getToken(): string | null {
    return this.token;
  }

  // handleInvalidToken() {
  //   this.logoutFn();
  //   this.toastr.error(
  //     'Your session has expired. Please log in again.',
  //     'Invalid Token'
  //   );
  // }

  isTokenExpired(token: string): boolean {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const currentTime = Math.floor(Date.now() / 1000);
      return payload.exp < currentTime;
    } catch (error) {
      return true; // Treat token as expired if decoding fails
    }
  }

  handleInvalidToken() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  getLoggedInUser(): any {
    const user = localStorage.getItem('loggedInUser');
    return user ? JSON.parse(user) : null;
  }

  getSelectedBranchId(): any {
    const branchID = localStorage.getItem('selectedBranchId');
    return branchID;
  }
}
