import { Component, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormArray,
} from '@angular/forms';
import { CurrencyAdjustmentService } from '../../features/currency-adjustments/services/currency-adjustment.service';
import { ChartAccountsService } from '../../features/chart-of-accounts/services/chart-accounts.service';
import { CustomerService } from '../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ExpenseServices } from '../../features/expenses/services/expenses.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateClientDialog } from '../invoice-new/dialog-components/create-customer-dialog/create-client-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../features/projects/services/projects.service';
import { BankingService } from '../../features/banking/services/banking.service';
import { PettyCashService } from '../../features/petty-cash/services/petty-cash.service';
import { PAYMENT_MODES } from '../../helpers/helper-file';
import { PaymentsItemService } from '../../features/payments/services/payments.service';
import { UploadFileService } from '../../shared/services/file-upload.service';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { PreferenceService } from '../../shared/services/preference.service';

@Component({
  selector: 'app-petty-cash',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
  ],
  templateUrl: './petty-cash.component.html',
  styleUrl: './petty-cash.component.scss'
})
export class AddPettyCashComponent {

  expenseForm: FormGroup;
  currencyData: any;
  formArrayInitialized = false;
  today: Date = new Date();
  chartOfAccounts: any = [];
  vendorsList: any = [];
  customerList: any = [];
  readonly dialog = inject(MatDialog);
  private searchSubject = new Subject<string>();
  loading: boolean = false;
  pettyCashId: number | null = null;
  allProjects: any = [];
  filterEmployees: any = [];
  bankId: any;
  categoryId: any;
  paymentModeList: any = PAYMENT_MODES;
  getAccountList: any[] = [];
  employees: any;
  pettyCashDetail: any;
  file: any;
  fileUrl: any = '';
  // pettyCashData: any;
  dataFileName: any;
  dataFileUrl: any;
  expenseCategoryId: any;
  totalAmount: any;
  pettyNo: any;
  projectSearchControl = new FormControl();
  search: any;

  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private expenseService: ExpenseServices,
    private datePipe: DatePipe,
    private pettyCashService: PettyCashService,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private itemService: PaymentsItemService,
    private documentUploadService: UploadFileService,
    private bankingService: BankingService,
    private preferenceService: PreferenceService,
  ) {
    this.expenseForm = new FormGroup({
      projectId: new FormControl('', [Validators.required]),
      employeeId: new FormControl('', [Validators.required]),
      flow: new FormControl('', [Validators.required]),
      category: new FormControl('Project', [Validators.required]),
      // pettyCashNo: new FormControl('', [Validators.required]),
      paymentMode: new FormControl('Cash'),
      pettyCashNumber: new FormControl('', [Validators.required]),
      accountId: new FormControl({ value: null, disabled: true }),
      chequeNumber: new FormControl(''),
      chequeDate: new FormControl(''),
      fileUrl: new FormControl(''),
      expenses: new FormArray([]), // Initialize as an empty FormArray
    });

    this.handleProjectValidation();
  }

  ngOnInit() {
    // this.preferenceCode();
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.pettyCashId = +id;
        this.initializeExpenseForm();
        this.loadSinglePettyCash(+id);
      } else {
        this.preferenceCode();
        this.addExpenseRow();
      }
    });

    this.projectSearchControl.valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged()
    )
    .subscribe((searchText) => {
      this.search = searchText;
      this.fetchAllProjects(searchText);
    });
    this.loadEmployeeTypeData();
    this.fetchAllProjects(this.search);
    this.fetchGetAccount();
    this.getTotalAmountForExpenseCategory();

    this.expenseForm.get('flow')?.valueChanges.subscribe((newFlow) => {
      if (newFlow === 'Out' && this.expenses.length > 1) {
        this.expenses.removeAt(1); // Remove extra row, keep first one
      }
    });
  }

  handleProjectValidation() {
    this.expenseForm.get('category')?.valueChanges.subscribe((value) => {
      const projectControl = this.expenseForm.get('projectId');
      
      if (value === 'Project') {
        projectControl?.setValidators([Validators.required]);
      } else {
        projectControl?.clearValidators();
      }
  
      projectControl?.updateValueAndValidity();
    });
  }
  

  initializeExpenseForm() {
    this.expenseForm = new FormGroup({
      projectId: new FormControl({ value: '', disabled: !!this.pettyCashId }, [Validators.required]),
      employeeId: new FormControl({ value: '', disabled: !!this.pettyCashId }, [Validators.required]),
      flow: new FormControl({ value: '', disabled: !!this.pettyCashId }, [Validators.required]),
      category: new FormControl({ value: '', disabled: !!this.pettyCashId }, [Validators.required]),
      // pettyCashNo: new FormControl('', [Validators.required]),
      pettyCashNumber: new FormControl('', [Validators.required]),
      paymentMode: new FormControl(''),
      accountId: new FormControl({ value: null, disabled: true }),
      chequeNumber: new FormControl(''),
      chequeDate: new FormControl(''),
      fileUrl: new FormControl(''),
      expenses: new FormArray([]),
    });
  }

  private fetchGetAccount() {
    this.itemService.fetchDepositTo('', false, true)?.subscribe({
      next: (getAccount: any) => {
        this.getAccountList = getAccount;
        const accountName = getAccount.find(
          (account: any) => account.accountName.toLowerCase() === 'petty cash'
        );
        console.log(accountName);
        const accountId = accountName ? accountName.id : null;
        this.expenseForm.patchValue({
          accountId: accountId
        })
      },
      error: (error: any) => console.error(error),
    });
  }

  //  onRemoveDocument(id: any): void {
  //   if (id) {
  //     const dialogRef = this.dialog.open(ConfirmDialog);
  //     dialogRef.afterClosed().subscribe((result) => {
  //       if (result === true) {
  //         this.dataFileName = '';
  //         this.dataFileUrl = ''
  //       }
  //     });
  //   }
  // }

  loadSinglePettyCash(pettyCashId: number): void {
    this.pettyCashService.fetchPettyCashById(pettyCashId).subscribe(async (data) => {
      try {
        const expenseData = data;
        if (expenseData) {
          // this.pettyCashData = expenseData;
          // this.dataFileName = expenseData?.fileName;
          // this.dataFileUrl = expenseData?.fileUrl;
          this.fetchEmployee(expenseData?.projectId);
          console.log('expenseData', expenseData);
          const item = {
            date: expenseData.date,
            description: expenseData.description,
            amount: expenseData.amount,
          };
          this.updateExpenseRow(item);

          this.expenseForm.patchValue({
            projectId: expenseData?.projectId,
            employeeId: expenseData?.employeeId,
            flow: expenseData?.flow,
            category: expenseData?.category,
            // pettyCashNo: expenseData?.pettyCashNo,
            paymentMode: expenseData?.paymentMode,
            pettyCashNumber: expenseData?.pettyCashNumber,
            accountId: expenseData?.accountId,
            chequeNumber: expenseData?.chequeNumber,
            chequeDate: expenseData?.chequeDate,
            fileUrl: expenseData?.fileUrl,
          });
        }
      } catch (error) {
        this.router.navigate(['/petty-cash']);
        console.error('Error loading employee data:', error);
      }
    });
  }

  dateFilter = (d: Date | null): boolean => {
    const currentDate = d || new Date();
    return currentDate <= this.today;
  };

  fetchEmployee(id: any) {
    this.filterEmployees = [];
    if(id){
      this.projectService.getOneProject(Number(id)).subscribe({
        next: (data: any) => {
          data?.projectResources.forEach((emp: any) => {
            this.filterEmployees.push({
              id: emp?.employeeId,
              name: emp?.employeeData?.firstName + ' ' + emp?.employeeData?.lastName,
            });
          })
        },
        error: (error) => {
          console.log('the error is ', error);
          this.toastr.error(error?.message);
        },
      });
    }
  }

  loadEmployeeTypeData() {
    this.projectService.getAllEmployees('').subscribe({

      next: (employeeTypes) => {
        this.employees = employeeTypes;
      },
      error: (error) => console.error('Error fetching employee types:', error),
    });
  }

  fetchAllProjects(search: any) {
    this.projectService.getAllProjects(search).subscribe({
      next: (data: any) => {
        this.allProjects = data;
      },
      error: (error) => {
        console.log('the error is ', error);
        this.toastr.error(error?.message);
      },
    });
  }

  fetchDetails(projectId: any) {
    const category = this.expenseForm.get('category')?.value;
    let id;
    if(this.expenseForm.get('category')?.value === 'Project'){
       id = projectId;
    } else {
       id = this.expenseForm.get('employeeId')?.value
    }
    this.pettyCashService.getProjectBasedPettyCashes(category,String(id)).subscribe({
      next: (response: any) => {
        this.pettyCashDetail = response;
        console.log('pettyCashDetail', this.pettyCashDetail);
      },
      error: (error: any) => {
        this.toastr.error(error.message, 'Error');
      },
    });
  }

  
  preferenceCode() {
    this.preferenceService.preferenceCodes('', 'Petty Cash').subscribe({
      next: (response: any) => {
        console.log(response);
        
        response.forEach((prefix: any) => {
          this.pettyNo = prefix?.sequenceCode  ;
        })
        const prefix = `${this.pettyNo}`;
        this.expenseForm.patchValue({ pettyCashNumber: prefix });
      },
      error: (error: any) => {
        console.error(error, 'Error');
      },
    });
  }

  get expenses(): FormArray {
    return this.expenseForm.get('expenses') as FormArray;
  }

  onItemizeRemove(): void {
    if (!this.formArrayInitialized) {
      this.formArrayInitialized = true;
      // this.addExpenseRow();
    } else {
      this.formArrayInitialized = false;
      // this.removeAllExpenses();
      this.addExpenseRow();
    }
  }
  addExpenseRow() {
    const expenseGroup = new FormGroup({
      date: new FormControl('', Validators.required),
      description: new FormControl('', [Validators.maxLength(500)]),
      amount: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\d+$/),
      ]),
    });

    this.expenses.push(expenseGroup);
  }

  updateExpenseRow(data: any) {
    const expenseGroup = new FormGroup({
      date: new FormControl(data?.date ?? '', Validators.required),
      description: new FormControl(data?.description ?? '', [
        Validators.maxLength(500),
      ]),
      amount: new FormControl(data?.amount ?? '', [
        Validators.required,
        Validators.pattern(/^\d+$/), // Validates positive integers only
      ]),
    });

    this.expenses.push(expenseGroup);
  }

  removeFile(): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.file = '';

        const input = document.querySelector('input[type="file"]') as HTMLInputElement;
        if (input) {
          input.value = '';
        }
      }
    });
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const allowedTypes = ['application/pdf'];

      if (!this.file) {
        this.file = [];
      }

      if (allowedTypes.includes(file.type)) {
        // this.documentFileName = file.name;
        this.file = file;
      } else {
        // File type is not allowed, reset the input and show an error message
        // this.documentFileName = undefined;
        this.file = undefined;
        this.toastr.error('Cant upload this file');
        
        // Reset the file input field
        const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
        if (fileInput) {
          fileInput.value = ''; // Clears the file input field
        }
      }

       // Store file for the respective expense item
    }
  }



  removeExpenseRow(index: number) {
    const itemData = this.expenses.at(index) as FormGroup;
    const values = itemData?.value;
    if (values?.id) {
      this.expenseService.removeExpenseItem(values?.id).subscribe({
        next: (response) => {
          this.expenses.removeAt(index);
        },
        error: (error) => {
          this.toastr.error(error.message || 'Failed to remove item');
          this.loading = false;
        },
      });
    } else {
      this.expenses.removeAt(index);
    }
  }

  onViewDocument(url: any) {
    const token = localStorage.getItem('AUTH_TOKEN');
    fetch(url, {
      method: 'GET',
      headers: {
        'authorization': token ? `Bearer ${token}` : '',
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      })
      .catch(error => console.error('Error:', error));
  }


  onSubmit(): void {
    this.loading = true;
    if (this.expenseForm.invalid) {
      this.expenseForm.markAllAsTouched();
      this.toastr.warning('Please fill mandatory fields!', 'Warning');
      this.loading = false;
      return;
    }

    let expenseItems = this.expenseForm.value?.expenses || [];
    const values = this.expenseForm.value;
    this.loading = true;

    const uploadFileAndSubmit = () => {
      if (!this.file) {
        // No file to upload, proceed directly with submission
        this.submitPettyCash(expenseItems, values);
        return;
      }
    
      this.documentUploadService.uploadDocument(this.file, 'petty-cash').subscribe({
        next: (url) => {
          if (url) {
            this.fileUrl = url.url; // Assign uploaded URL
            values.fileUrl = this.fileUrl; // Ensure values contain fileUrl
          }
          this.submitPettyCash(expenseItems, values); // Proceed with submission after upload
        },
        error: () => {
          this.toastr.error('File upload failed');
          this.loading = false;
        },
      });
    };

    uploadFileAndSubmit(); // Start uploading files
  }

  private submitPettyCash(expenseItems: any[], values: any): void {
    this.expenseForm.get('accountId')?.enable();
    // Format dates and remove file property from items
    expenseItems = expenseItems.map(item => ({
      ...item,
      date: this.datePipe.transform(item.date, 'yyyy-MM-dd'),
    }));
    
    let createPettyCashInput: any;
    this.expenseForm.get('flow')?.enable();
    this.expenseForm.get('category')?.enable();
    if (this.pettyCashId) {
      const formattedExpenseItem = expenseItems?.length
        ? {
          date: expenseItems[0]?.date, // Assuming only the first item is relevant
          description: expenseItems[0]?.description,
          amount: expenseItems[0]?.amount,
        }
        : null;
      createPettyCashInput = {
        id: this.pettyCashId,
        ...formattedExpenseItem
      };

      console.log(createPettyCashInput);
      
      this.pettyCashService.updatePettyCash(createPettyCashInput).subscribe({
        next: () => {
          this.toastr.success('Petty cash updated successfully');
          console.log('values.flow',this.expenseForm.value.flow);
          this.loading = false;
            this.router.navigate(['/petty-cash']);
        },
        error: (error) => {
          this.toastr.error(error.message);
        },
        complete: () => (this.loading = false),
      });
    } else {
      let createPettyCashInput = {
        projectId: values.category === 'Project' ? values.projectId : null,
        employeeId: values.employeeId,
        flow: values.flow,
        category: values.category,
        accountId: values.accountId,
        paymentMode: values.paymentMode,
        pettyCashNumber: values.pettyCashNumber,
        chequeNumber: Number(values.chequeNumber),
        chequeDate: this.datePipe.transform(values.chequeDate, 'yyyy-MM-dd'),
        fileUrl: values?.fileUrl,
        expenseItems,
      };
      this.pettyCashService.createPettyCash(createPettyCashInput).subscribe({

        next: (data) => {
          this.toastr.success('Petty cash created successfully');
          const pettyId = data?.data?.createPettyCash?.[0]?.id;
          if(values.flow === 'In'){
            this.fetchTrasactionCategories();
            this.createTransaction(pettyId);
          } else {
            this.router.navigate(['/petty-cash']);
          }
          console.log('data',data?.data?.createPettyCash?.[0]?.id);
          if (pettyId) {
            
            this.pettyCashService
              .generatePettyCashPdf(Number(pettyId))
              .subscribe({
                next: (pdfResponse) => {
                  console.log('PDF generated successfully', pdfResponse);
                  const url = pdfResponse;
                  const token = localStorage.getItem('AUTH_TOKEN');
                  fetch(url, {
                    method: 'GET',
                    headers: {
                      'authorization': token ? `Bearer ${token}` : ''
                    }
                  }).then((response: any) => response.blob()).then((blob: any) => {
                    // const url = URL.createObjectURL(blob);
                    // window.open(url, '_blank');
                    const blobUrl = URL.createObjectURL(blob);
                    const a = document.createElement('a'); // Create a hidden <a> element
                    a.href = blobUrl;
                    a.download = `petty-cash-${pettyId}.pdf`; // Set the filename
                    document.body.appendChild(a);
                    a.click(); // Trigger the download
                    document.body.removeChild(a); // Clean up
                    URL.revokeObjectURL(blobUrl); // Revoke the object URL
                  }).catch((error: any) => {
                    console.error('Error:', error)
                  });
                  this.loading = false;
                  this.router.navigate(['/petty-cash'])
                },
                error: (pdfError) => {
                  this.loading = false; 
                  console.error('Error generating PDF:', pdfError);
                  this.toastr.error(
                    pdfError.message || 'Failed to generate PDF.'
                  );
                },
              });
          } else {
            console.error('Petty cash Id is missing');
          }
        },
        error: (error) => {
          this.toastr.error(error.message);
        },
        complete: () => (this.loading = false),
      });
    }
  }

  fetchTrasactionCategories() {
    this.bankingService.transactionCategories().subscribe({
      next: (data) => {
        console.log('data',data);
        
        if (data) {
          console.log('inside category');
          
          // Find the category with categoryName "expense"
          const expenseCategory = data.find(
            (category: any) => category.categoryName.toLowerCase() === 'expense'
          );
  
          // Store the ID in a variable
          this.expenseCategoryId = expenseCategory ? expenseCategory.id : null;
  
          console.log('Expense Category ID:', this.expenseCategoryId);
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }
  getTotalAmountForExpenseCategory() {
    this.pettyCashService.getTotalAmountForExpenseCategory().subscribe({
      next: (data) => {
        console.log('data',data?.finalBalance);
        this.totalAmount = data?.finalBalance;
      },

      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }


  createTransaction(pettyCashId: any) {
    const transactionData = {
      refId: pettyCashId,
      refTableType: 'Pettycash',
      date: this.datePipe.transform(this.expenseForm.value.expenses[0].date, 'yyyy-MM-dd'),
      transactionStatus: 'Manual',
      referenceNumber: `Utilized for ${this.pettyCashDetail?.pettyCashes[0]?.projectData?.projectName}`,
      amount: this.expenseForm.value.expenses[0].amount,
      description: this.expenseForm.value.expenses[0].description,
      flow: 'Credit',
      offsetAccountId: (Number(this.expenseForm.value.accountId)),
      transactionCategoryId: Number(this.expenseCategoryId),
    }

    console.log('transactionData',transactionData);
    

    // this.bankingService.createTransaction(transactionData).subscribe({
    //   next: (data) => {
    //     this.router.navigate(['/petty-cash']);
    //     this.loading = false;
    //   },
    //   error: (error) => {
    //     this.toastr.error(error, 'Error')
    //     this.loading = false;
    //   }
    // })
  }



  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    console.log('onSearchonSearchonSearch', input);

    this.searchSubject.next(searchTerm);
  }

  onProjectSelected(event: MatSelectChange) {
    const selectedCustomerId = event.value;
    this.expenseForm.get('projectId')?.setValue(selectedCustomerId);
    this.expenseForm.get('employeeId')?.setValue(null);
    this.fetchEmployee(selectedCustomerId);
    this.fetchDetails(selectedCustomerId);
  }

  onClearForm(): void {
    this.expenseForm.reset();
    this.router.navigate(['/petty-cash'])
  }
}
