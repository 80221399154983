<section class="cmn-innerpage-wrapper debit-note">
    <div class="head">
        <div class="search-wrapper" *appHasPermission="['18', Permission.List]">
            <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)" />
        </div>
        <button class="cmn-add-btn"  *appHasPermission="['18', Permission.Create]" (click)="navigateTo('/debit-note/debit-note-new')">
            <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New Debit Note
        </button>
    </div>
    <div class="inner-content" *appHasPermission="['18', Permission.List]">
        <ag-grid-angular style="width: 100%;" [rowData]="rowData" [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [suppressRowClickSelection]="true"
            [pagination]="true" [paginationPageSize]="paginationPageSize"
            [paginationPageSizeSelector]="paginationPageSizeSelector" [class]="themeClass"
            (cellClicked)="onCellClicked($event)" />
    </div>
</section>