import gql from 'graphql-tag';

export const CREATE_LOCATION = gql`
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(createLocationInput: $input) {
      id
      locationName
      latitude
      longitude
    }
  }
`;


  
export const UPDATE_LOCATION = gql`
  mutation updateLocation($updateLocationInput: UpdateLocationInput!) {
    updateLocation(updateLocationInput: $updateLocationInput)  {
      id
      locationName
      latitude
      longitude
  }
  }
`;

export const REMOVE_LOCATION_BY_ID = gql`
mutation removeLocation($id: Int!) {
  removeLocation(id: $id){
   id
  }
}
`;
