<mat-dialog-content>
    <section class="invoice-apply-credits-wrapper">
        <h2 class="cmn-popup-title">Apply Credits to Invoice</h2>
        <button class="cmn-close" mat-dialog-close></button>


        <div class="form-main-content-wrapper">

            <div class="top-content-with-icon">
                <div>
                    <div>
                        <img src="../../../assets/images/cash.svg" alt="">
                    </div>
                    <div>
                        <h5>{{noteDetails?.id}}</h5>
                        <p>Credit Note#</p>
                    </div>
                </div>
                <div>
                    <div>
                        <img src="../../../assets/images/cah-gray.svg" alt="">
                    </div>
                    <div>
                        <h5>{{noteDetails?.balance}}</h5>
                        <p>Available Credits</p>
                    </div>
                </div>
            </div>

            <ul>
                <li>
                    <h6>Invoice#</h6>
                    <p>IN-000124</p>
                </li>
                <li>
                    <h6>Invoice Date</h6>
                    <p>28/08/2024</p>
                </li>
                <li>
                    <h6>Invoice Amount</h6>
                    <p>AED 3,000.00</p>
                </li>
                <li>
                    <h6>Invoice Balance</h6>
                    <p>Duration</p>
                </li>
                <li>
                    <h6>Credits to Apply</h6>
                    <div class="form-wrapper">

                        <mat-form-field>
                            <input matInput />
                        </mat-form-field>
                    </div>
                </li>
                <li>
                    <div>
                        <h6>Amount to Credit </h6>
                        <p>: &nbsp;&nbsp; 0.00</p>
                    </div>
                    <div>
                        <h6>Invoice Balance Due</h6>
                        <p>: &nbsp;&nbsp; AED 300.00</p>

                    </div>
                </li>
            </ul>



        </div>

        <div class="btn-wrapper">
            <button class="cmn-cancel-btn ">
                Cancel
            </button>
            <button class="cmn-next-btn ">
                Save
            </button>
        </div>
    </section>
</mat-dialog-content>