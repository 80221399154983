import { Component, inject, Input, SimpleChanges } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CurrencyAdjustmentService } from '../../features/currency-adjustments/services/currency-adjustment.service';
import { ChartAccountsService } from '../../features/chart-of-accounts/services/chart-accounts.service';
import { CustomerService } from '../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ExpenseServices } from '../../features/expenses/services/expenses.service';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../features/projects/services/projects.service';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { CreateClientDialog } from '../invoice-new/dialog-components/create-customer-dialog/create-client-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { WorkOrderService } from '../../features/workOrder/services/workOrder.service';
import { UploadFileService } from '../../shared/services/file-upload.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { noHtmlSpecialChars, noSpaceAtStartOrEnd } from '../../helpers/helper';
import { ListBillingAddressDialog } from '../invoice-new/dialog-components/list-billing-address-dialog/list-billing-address-dialog.component';
import { BillingAddressDialog } from '../invoice-new/dialog-components/add-billing-address-dialog/billing-address-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PreferenceService } from '../../shared/services/preference.service';
import { PartnerTypesEnum } from '../../helpers/helper-file';

@Component({
  selector: 'app-work-order-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule,
  ],
  templateUrl: './work-order-new.component.html',
  styleUrl: './work-order-new.component.scss',
})
export class WorkOrderNewComponent {
  public arr = [1, 2, 3, 4, 5, 6, 7];
  workOrderForm: FormGroup;
  private searchSubject = new Subject<string>();
  projectManagerData: any[] = [];
  projectEngineerData: any[] = [];
  projectProcurementData: any[] = [];
  projectdesignerData: any[] = [];
  projectEstimaterData: any[] = [];
  private searchProjectManagerSubject = new Subject<string>();
  private searchProjectEngineerSubject = new Subject<string>();
  private searchProjectDesignerSubject = new Subject<string>();
  private searchProjectProcurementSubject = new Subject<string>();
  private searchProjectEstimaterSubject = new Subject<string>();
  private searchSubjectProject = new Subject<string>();
  customerList: any = [];
  allProjects: any = [];
  readonly dialog = inject(MatDialog);
  employeeData: any[] = [];
  today: Date = new Date();
  startDate: any | null = null;
  attachmentType: string | null = null;
  workOrderId: any = null;
  workOrderData: any = null;
  customerAddress: any = [];
  saveDraftStatus: boolean = false;
  totalPrice: any = 0;
  totalDiscount: any = 0;
  selectedFile: any[] = [];
  loading: boolean = false;
  fileDetailsArray: {
    fileName: string;
    fileUrl: string;
    id?: number | null;
    file: any;
  }[] = [];
  isDisabled: boolean = true;
  fileNotError: boolean = false;
  draftLoading: boolean = false;
  uploadedFileUrl: any = null;
  selectedCustomer: any = null;
  discount: number = 0;
  totalAmont: any;
  tax: string;
  workOrderNumber: any;
  workOrderSow: any;
  totalDiscountAmount: number = 0;
  totalTaxAmount: number = 0;
  private partnerTypes = PartnerTypesEnum;
  manuelFileCreation: any = null;

  minDate: Date = new Date();

  constructor(
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private invoiceService: InvoiceService,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private workorderService: WorkOrderService,
    private documentUploadService: UploadFileService,
    private http: HttpClient,
    private preferenceService: PreferenceService
  ) {
    this.workOrderSow = [];
    this.workOrderForm = new FormGroup({
      workOrderNo: new FormControl('', [Validators.required]),
      clientId: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      projectId: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      pContactNo: new FormControl('', [Validators.required]),
      sow: new FormControl('', [Validators.required]),
      quoteRef: new FormControl('', [Validators.required]),
      clientConfRef: new FormControl('', [
        noHtmlSpecialChars,
        noSpaceAtStartOrEnd,
      ]),
      duration: new FormControl('', [noHtmlSpecialChars, noSpaceAtStartOrEnd]),
      commencementDate: new FormControl(''),
      startDate: new FormControl(''),
      completionDate: new FormControl(''),
      projectManager: new FormControl(''),
      projectEngineer: new FormControl(''),
      projectDesigner: new FormControl(''),
      procurementIncharge: new FormControl(''),
      costEstimater: new FormControl(''),
      fileType: new FormControl(''),
      fileUrl: new FormControl(''),
    });
  }

  async ngOnInit() {
    this.fetchAllProjects(null);
    // this.preferenceCode();
    await this.fetchPartners();
    this.loadEmployeeTypeData('', 'all');
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.workOrderId = +id;
        this.loadWorkorder(+id);
      }
    });

    this.loadEmployeeTypeData('', 'manager');
    this.loadEmployeeTypeData('', 'engineer');
    this.loadEmployeeTypeData('', 'procurement');
    this.loadEmployeeTypeData('', 'estimater');
    this.loadEmployeeTypeData('', 'designer');

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchPartners(searchTerm);
      });

    this.searchProjectManagerSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.loadEmployeeTypeData(searchTerm, 'manager');
      });

    this.searchProjectEngineerSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.loadEmployeeTypeData(searchTerm, 'engineer');
      });

    this.searchProjectDesignerSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.loadEmployeeTypeData(searchTerm, 'designer');
      });

    this.searchProjectProcurementSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.loadEmployeeTypeData(searchTerm, 'procurement');
      });

    this.searchProjectEstimaterSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.loadEmployeeTypeData(searchTerm, 'estimater');
      });

    this.searchSubjectProject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchAllProjects(searchTerm);
      });
  }

  preferenceCode() {
    this.preferenceService.preferenceCodes('', 'Work Order').subscribe({
      next: (response) => {
        response.forEach((prefix: any) => {
          this.workOrderNumber = prefix?.sequenceCode;
        });
        // const randomSuffix = Math.floor(1000 + Math.random() * 9000);
        // this.prefix = `${this.quoteNumber + randomSuffix}`;
        this.workOrderForm.patchValue({ workOrderNo: this.workOrderNumber });
      },
      error: (error) => {
        console.error(error, 'Error');
      },
    });
  }
  onFileSelected(event: Event, fileInput: HTMLInputElement): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const newFiles = Array.from(input.files);
      if (this.fileDetailsArray.length + newFiles.length > 1) {
        this.toastr.warning('You can only upload 1 file.');
        return;
      }
      newFiles.forEach((newFile) => {
        const allowedExtensions = ['pdf', 'doc', 'jpg', 'jpeg', 'png'];
        const fileExtension = newFile.name.split('.').pop()?.toLowerCase();
        if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
          this.toastr.warning('Only PDF, DOC, and image files are allowed.');
          return;
        }
        // Prevent duplicates based on name and size
        if (
          !this.fileDetailsArray.some((file) => file.fileName === newFile.name)
        ) {
          this.fileDetailsArray.push({
            id: null,
            fileName: newFile.name,
            fileUrl: URL.createObjectURL(newFile),
            file: newFile,
          });
        }
      });
      this.selectedFile = newFiles;
      fileInput.value = '';
    }
  }

  openFile(url: any) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  getBlobFile(url: string) {
    const token = localStorage.getItem('AUTH_TOKEN'); // Get token from localStorage
    const headers = new HttpHeaders({
      Authorization: token ? `Bearer ${token}` : '',
    });
    let urlBlob = '';
    this.http.get(url, { headers, responseType: 'blob' }).subscribe(
      (blob) => {
        urlBlob = URL.createObjectURL(blob);
      },
      (error) => {
        console.error('Error downloading PDF', error);
      }
    );
    return urlBlob;
  }

  handleRemoveFile() {
    this.fileDetailsArray = [];
  }

  async loadWorkorder(id: number) {
    this.workorderService.getWorkOrderById(id).subscribe({
      next: (details) => {
        // if (details?.partnerId) {
        //   this.onCustomerSelected(details?.partnerId);
        // }
        let fileTypeVal: any = '';
        let fileUrlVal: any = '';
        if (details?.attachedFiles?.length) {
          const singleFile = details?.attachedFiles[0];
          if (singleFile?.type !== 'MANUAL') {
            fileTypeVal = singleFile.type;
            this.attachmentType = singleFile.type;
            const fileUrl = this.workOrderForm.get('fileUrl');
            if (singleFile.type === 'DRAWING') {
              fileUrlVal = singleFile?.fileUrl;
              fileUrl?.setErrors({ required: true });
            } else {
              this.fileDetailsArray = [
                {
                  fileName: singleFile?.fileName || '',
                  fileUrl: this.getBlobFile(singleFile?.fileUrl),
                  id: singleFile?.id,
                  file: null,
                },
              ];
              fileUrl?.setErrors(null);
            }
          } else {
            this.manuelFileCreation = singleFile;
          }
        }
        this.workOrderData = details;
        // Clear array before adding new items and variables for proper calculation
        this.workOrderSow = [];
        this.totalDiscountAmount = 0;
        this.totalTaxAmount = 0;
        this.workOrderData?.workOrderSOWs?.forEach((el: any) => {
          this.totalDiscountAmount = this.totalDiscountAmount + el?.discount;
          this.totalTaxAmount = this.totalTaxAmount + el?.tax;
          this.workOrderSow.push({
            id: el?.id,
            workOrderId: el?.workOrderId,
            order: el?.order,
            quantity: el?.quantity,
            unit: el?.unit,
            rate: el?.rate,
            discount: el?.discount,
            tax: el?.tax,
            netPrice: el?.netPrice,
            subTotal: el?.subTotal,
            totalAmount: el?.totalAmount,
          });
        });
        this.workOrderForm.patchValue({
          workOrderNo: details?.workOrderNo || this.workOrderNumber,
          clientId: details?.partnerId,
          projectId: details?.projectId,
          pContactNo: details?.partnerData?.displayName,
          sow: details?.SOW,
          quoteRef: details?.quoteData?.quoteCode,
          clientConfRef: details?.clientRef,
          duration: details?.duration,
          commencementDate: details.commencementDate
            ? new Date(details.commencementDate)
            : null,
          startDate: details.startDate ? new Date(details.startDate) : null,
          completionDate: details.completionDate
            ? new Date(details.completionDate)
            : null,
          projectManager: details?.projectManagerId,
          projectEngineer: details?.projectInchargeId,
          projectDesigner: details?.projectDesigner,
          procurementIncharge: details?.procurementIncharge,
          costEstimater: details?.estimator,
          customerNotes: details?.customerNote,
          termsAndConditions: details?.termsAndConditions,
          fileType: fileTypeVal,
          fileUrl: fileUrlVal,
        });
        this.totalPrice = details?.workOrderSOWs?.length
          ? details?.workOrderSOWs?.reduce(
              (sum: any, item: any) => sum + Number(item.netPrice),
              0
            )
          : 0;

        this.totalDiscount = details?.workOrderSOWs?.length
          ? details?.workOrderSOWs?.reduce(
              (sum: any, item: any) => sum + Number(item.discount),
              0
            )
          : 0;

        this.tax = details?.workOrderSOWs?.length
          ? details?.workOrderSOWs?.reduce(
              (sum: any, item: any) => sum + Number(item.tax),
              0
            )
          : 0;

        this.totalAmont = this.totalPrice + Number(this.tax);

        if (details?.partnerId) {
          this.onChangeCustomer(details?.partnerId);
          this;
          const obj = {
            id: details?.partnerData?.id,
            name: details?.partnerData?.displayName,
            addresses: details?.partnerData?.addresses,
          };
          this.customerAddress = details?.partnerData?.addresses?.length
            ? obj
            : [];
        }
      },

      error: (error) => {
        if (error?.message === 'No match found') {
          this.router.navigate(['/work-order']);
        }
      },
    });
  }

  dateFilter = (date: Date | null): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Remove time component
    return date ? date >= today : false;
  };

  startDateFilter = (date: Date | null): boolean => {
    const commenceMent = this.workOrderForm.get('commencementDate')?.value;
    if (commenceMent) {
      if (!date) {
        return true;
      }
      return date >= commenceMent;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Remove time component
    return date ? date >= today : false;
  };

  handleStartDateChange(d: Date | null) {
    let day = d ? d : new Date();
    this.startDate = d;
    const completionDate = this.workOrderForm.get('completionDate')?.value;
    if (completionDate && completionDate < day) {
      this.workOrderForm.get('completionDate')?.reset();
    }
  }

  handleCommoncementDateChange(d: Date | null) {
    let day = d ? d : new Date();
    const completionDate = this.workOrderForm.get('startDate')?.value;
    if (completionDate && completionDate < day) {
      this.workOrderForm.get('startDate')?.reset();
    }
  }

  completionDateFilter = (date: Date | null): boolean => {
    if (!date || !this.startDate) {
      return true;
    }
    return date >= this.startDate;
  };

  onCustomerSelected(event: MatSelectChange) {
    const selectedCustomerId = event.value;
    this.selectedCustomer = event.value;
    this.workOrderForm.get('customerId')?.setValue(selectedCustomerId);
    this.workOrderForm.get('projectId')?.setValue('');
    this.onChangeCustomer(selectedCustomerId);
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  onProjectManagerSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchProjectManagerSubject.next(searchTerm);
  }

  onProjectEngineerSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchProjectEngineerSubject.next(searchTerm);
  }

  onProjectDesignerSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchProjectDesignerSubject.next(searchTerm);
  }

  onProjectProcurementSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchProjectProcurementSubject.next(searchTerm);
  }

  onProjectEstimaterSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchProjectEstimaterSubject.next(searchTerm);
  }

  onSearchProject(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubjectProject.next(searchTerm);
  }

  async fetchPartners(search: string = '') {
    await this.invoiceService
      .fetchCustomers(search, this.partnerTypes.Customer)
      .subscribe({
        next: (partners) => {
          this.customerList = partners
            // .filter((data: any) => data.partnerType === 'Customer')
            .map((data: any) => ({
              id: data.id || '--',
              name: data.displayName || '--',
              addresses: data.addresses || [],
              projects: data?.partnerProjects,
            }));
        },
        error: (error) => console.error(error),
      });
  }

  fetchAllProjects(search: any | null) {
    this.projectService.getAllProjects(search).subscribe({
      next: (data: any) => {
        this.allProjects = data;
      },
      error: (error) => {
        console.log('the error is ', error);
        this.toastr.error(error?.message);
      },
    });
  }

  private loadEmployeeTypeData(
    search: string,
    type:
      | 'manager'
      | 'engineer'
      | 'estimater'
      | 'procurement'
      | 'designer'
      | 'all'
  ) {
    this.projectService.getAllEmployees(search).subscribe({
      next: (employeeTypes) => {
        this.employeeData = employeeTypes;
        if (type === 'manager') {
          this.projectManagerData = employeeTypes;
        } else if (type === 'engineer') {
          this.projectEngineerData = employeeTypes;
        } else if (type === 'procurement') {
          this.projectProcurementData = employeeTypes;
        } else if (type === 'estimater') {
          this.projectEstimaterData = employeeTypes;
        } else if (type === 'designer') {
          this.projectdesignerData = employeeTypes;
        } else if (type === 'all') {
          this.employeeData = employeeTypes;
        }
      },
      error: (error) => console.error('Error fetching employee types:', error),
    });
  }

  openCreateClientDialog() {
    const dialogRef = this.dialog.open(CreateClientDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
      }
    });
  }

  onSelectType(event: MatSelectChange) {
    this.attachmentType = event.value;
    const fileUrl = this.workOrderForm.get('fileUrl');
    if (event.value == 'DRAWING') {
      console.log(' insde ');

      fileUrl?.setValidators([Validators.required]);
      // fileUrl?.setErrors({ required: true });
    } else {
      fileUrl?.clearValidators();
    }
  }

  onChangeCustomer(id: number) {
    const customerId = id;

    const filterData = this.customerList?.find(
      (el: any) => el?.id == customerId
    );

    if (filterData) {
      this.customerAddress = filterData;
      this.allProjects = filterData?.projects;
      this.workOrderForm.get('pContactNo')?.setValue(filterData?.name);
    }
  }

  onNavigate(url: string) {
    this.router.navigate([url]);
  }

  handleDraft(): void {
    if (this.workOrderData.invalid) {
      this.workOrderData.markAllAsTouched();
      this.toastr.warning('Please fill mandatory fields!', 'Warning');
      return;
    }
    if (this.workOrderForm.invalid) {
      this.workOrderForm.markAllAsTouched();
      this.toastr.warning('Please fill mandatory fields!', 'Warning');
      return;
    }
    const values = this.workOrderForm.value;
    this.onSubmitApiCall('DRAFT', 'work-order/work-order-details');
  }

  handleSaveAndSent(): void {
    if (this.workOrderData.invalid) {
      this.workOrderData.markAllAsTouched();
      this.toastr.warning('Please fill mandatory fields!', 'Warning');
      return;
    }
    const values = this.workOrderForm.value;
    this.onSubmitApiCall('OPEN', 'work-order/send-mail');
  }

  onSubmitApiCall(status: any, navigateTo: string) {
    const values = this.workOrderForm.value;
    this.workOrderForm.value;
    const attachmentFiles = [];
    try {
      const updateWorkOrderInput = {
        partnerId: values?.clientId,
        projectId: values?.projectId,
        procurementIncharge: values?.procurementIncharge,
        estimator: values?.costEstimater,
        projectDesigner: values?.projectDesigner,
        projectInchargeId: values?.projectEngineer,
        projectManagerId: values?.projectManager,
        workOrderStatus: status ? 'DRAFT' : 'COMPLETED',
        workOrderNo: values?.workOrderNo,
        addressId: this.customerAddress?.addresses[0]?.id ?? null,
        quoteId: this.workOrderData?.quoteId ?? null,
        startDate: values?.startDate
          ? this.datePipe.transform(values.startDate, 'yyyy-MM-dd')
          : null,
        SOW: values?.sow,
        clientRef: values?.clientConfRef ?? '',
        commencementDate: values?.commencementDate
          ? this.datePipe.transform(values.commencementDate, 'yyyy-MM-dd')
          : null,
        completionDate: values?.completionDate
          ? this.datePipe.transform(values.completionDate, 'yyyy-MM-dd')
          : null,
        duration: values?.duration,
        id: +this?.workOrderId,
      };

      if (values?.fileType === 'DRAWING' && this.manuelFileCreation) {
        const tempUrl = this.manuelFileCreation;
        const obj = {
          id: tempUrl?.id,
          workOrderId: tempUrl?.workOrderId,
          type: values?.fileType,
          fileName: tempUrl?.fileName,
          fileUrl: values?.fileUrl,
        };
        attachmentFiles.push(obj);
      }

      const workOrderItems = this?.workOrderData?.workOrderSOWs?.map(
        (el: any) => ({
          id: el?.id,
          workOrderId: el?.workOrderId,
          order: el?.order,
          quantity: el?.quantity,
          unit: el?.unit,
          rate: el?.rate,
          discount: el?.discount,
          tax: el?.tax,
          netPrice: el?.netPrice,
        })
      );

      if (this.fileDetailsArray?.length) {
        let uploadedFileUrl: any = null;
        this.documentUploadService
          .uploadDocument(this.fileDetailsArray[0]?.file, 'items')
          .subscribe({
            next: (response) => {
              uploadedFileUrl = response?.url;
              const UpdateWorkOrderAttachmentInput = {
                id: this.workOrderData?.attachedFiles[0]?.id,
                fileUrl: response?.url,
                fileName: this.fileDetailsArray[0]?.fileName,
                type: values?.fileType,
                workOrderId: +this?.workOrderId,
              };
              this.loading = true;
              this.workorderService
                .updateWorkOrder(
                  updateWorkOrderInput,
                  workOrderItems,
                  UpdateWorkOrderAttachmentInput
                )
                .subscribe({
                  next: (response) => {
                    this.loading = false;
                    this.draftLoading = false;
                    this.toastr.success('Work Order Updated successfully');
                    console.log(
                      '/work-order/work-order-details?id=' + this?.workOrderId
                    );
                    this.router.navigate([
                      '/work-order/work-order-details?id=' + this?.workOrderId,
                    ]);
                    this.router.navigate([`${navigateTo}`], {
                      queryParams: { id: this?.workOrderId },
                    });
                  },
                  error: (error) => {
                    this.loading = false;
                    this.draftLoading = false;
                    this.toastr.error(
                      error.message || 'Failed to create work order.'
                    );
                  },
                });
            },
            error: (error) => {
              this.loading = false;
              this.draftLoading = false;
              this.toastr.error(error, 'File upload failed');
            },
          });
      } else {
        this.loading = true;
        this.workorderService
          .updateWorkOrder(
            updateWorkOrderInput,
            workOrderItems,
            attachmentFiles?.length ? attachmentFiles : null
          )
          .subscribe({
            next: (response) => {
              this.loading = false;
              this.draftLoading = false;
              this.toastr.success('Work Order Updated successfully');
              //this.router.navigate(['/work-order/work-order-details?id=' + this?.workOrderId]);
              //const workOrderId = response.id;
              //if (workOrderId) {
              this.router.navigate([`${navigateTo}`], {
                queryParams: { id: this?.workOrderId },
              });
              //}
            },
            error: (error) => {
              this.loading = false;
              this.draftLoading = false;
              this.toastr.error(
                error.message || 'Failed to create work order.'
              );
            },
          });
      }
    } catch (error) {
      console.log('the error is', error);
    }
  }

  // address section

  openSelectBillingAddressDialog(customerId: any) {
    const selectedCustomerId = customerId;
    const type = 'BILLING';

    const dialogRef = this.dialog.open(ListBillingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });

    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        console.log('the address is ', address);
        // this.selectedBillingAddress = address;
      }
    });
  }

  openBillingAddressDialog(type: any) {
    const selectedClientId = this.workOrderForm.get('clientId')?.value;
    this.dialog.open(BillingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }
}
