import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ALL_PURCHASE_ORDER, FETCH_GRN, FETCH_GRN_BY_ID } from '../graphql/queries/grn.query';
import { CREATE_GRN, DELETE_GRN, UPDATE_GRN } from '../graphql/mutation/grn.mutation';

@Injectable({
  providedIn: 'root',
})
export class GRNServices {
  constructor(private apollo: Apollo) {}

  listGoodsReceived(search: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: FETCH_GRN,
        variables: { search },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.listGoodsReceived || []));
  }

  goodsReceived(id: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .query<any>({
        query: FETCH_GRN_BY_ID,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data?.goodsReceived || []));
  }

  createGoodsReceivedWithItems(createGoodsReceivedInput: any, grnItems: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_GRN,
      variables: {
        createGoodsReceivedInput,
        grnItems
      },
    });
  }

  editGoodsReceived(updateGoodsReceivedInput: any, grnItems: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: UPDATE_GRN,
      variables: {
        updateGoodsReceivedInput,
        grnItems
      },
    });
  }
  
  deleteGoodsReceived(id: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: DELETE_GRN,
      variables: {
        id
      },
    });
  }

  fetchAllPurchaseOrder(vendorId: string, purchaseStatuses: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        variables: {
          vendorId,
          purchaseStatuses
        },
        query: ALL_PURCHASE_ORDER,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.purchaseOrders));
  }
}
