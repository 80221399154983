import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_DESIGNATIONS } from '../graphql/queries/designation.query';
import {
  CREATE_DESIGNATION,
  REMOVE_DESIGNATION_BY_ID,
  UPDATE_DESIGNATION,
} from '../graphql/mutation/designation.mutation';

@Injectable({
  providedIn: 'root',
})
export class DesignationService {
  constructor(private apollo: Apollo) {}

  fetchDesignations(search: string = '', status: number = 1): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_DESIGNATIONS,
        variables: { search, status },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.designations || []));
      
  }

  createDesignations(createBranchInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: CREATE_DESIGNATION,
      variables: {
        input: createBranchInput,
      },
    });
  }

  updateDesignation(designationData: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: UPDATE_DESIGNATION,
      variables: {
        updateDesignationInput: designationData,
      },
    });
  }

  // Remove branch
  removeDesignationByid(id: number): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: REMOVE_DESIGNATION_BY_ID,
      variables: { id },
    });
  }
}
