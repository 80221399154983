
    <section class="cmn-innerpage-wrapper vacation-approvals">
        <div class="head">
            <h2>Vacation Request</h2>
            <button class="cmn-filter-btn" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }"><img src="assets/images/icons/icon-timeline.svg"
                    alt="Icon Timeline"> Show Timeline</button>
            <!-- Filter Popup -->
            <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <h6 class="cmn-popup-heading">Timeline</h6>
                <div class="timelinne-wrapper">
                    <div class="timeline-box" *ngFor="let timeline of timelines">
                        <div class="timeline-head" >
                            <ul>
                                <li>{{timeline?.activityType}}</li>
                                <li>{{timeline?.createdAt | date: 'dd/MM/YYYY'}}</li>
                            </ul>
                            <h3>{{ timeline?.createdAt | date: 'hh:mm a' }}
                            </h3>
                        </div>
                        <div class="timeline-content">
                            <h4>{{ timeline?.note }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Filter Popup -->
        </div>
        <div class="content-wrapper">
                <div class="employee-details">
                    <div class="box">
                        <h3>Name</h3>
                        <h4>{{vacationData?.employee?.firstName}} {{vacationData?.employee?.lastName}}</h4>
                    </div>
                    <div class="box">
                        <h3>Designation</h3>
                        <h4>{{vacationData?.employee?.designation?.designationName}}</h4>
                    </div>
                    <div class="box">
                        <h3>From</h3>
                        <h4>{{vacationData?.startDate | date: 'MMMM dd yyyy'}}</h4>
                    </div>
                    <div class="box">
                        <h3>To</h3>
                        <h4>{{vacationData?.endDate | date: 'MMMM dd yyyy'}}</h4>
                    </div>
                    <div class="box">
                        <h3>Remarks</h3>
                        <h4>{{vacationData?.employeeComment}}</h4>
                    </div>
                </div>
<div class="approver-details">
                    <h3>Approvers</h3>
                    <div class="approver-box" *ngFor="let approver of vacationApprovers">
                        <div class="approver-item" *ngIf ="approver?.levelOneData">
                            <div class="approver">
                                <img src="/assets/images/profile.png" alt="">
                                <h4>{{approver?.levelOneData?.firstName}} {{approver?.levelOneData?.lastName}}</h4>
                            </div>
                            <h5 [ngClass]="{
                                'green': levelOneApproverStatus === 'APPROVED',
                                'yellow': levelOneApproverStatus === 'PENDING',
                                'red': levelOneApproverStatus === 'REJECTED'
                              }">
                                {{ levelOneApproverStatus }}
                              </h5>
                        </div>
                        <div class="approver-item" *ngIf ="approver?.levelTwoData">
                            <div class="approver">
                                <img src="/assets/images/profile.png" alt="">
                                <h4>{{approver?.levelTwoData?.firstName}} {{approver?.levelTwoData?.lastName}}</h4>
                            </div>
                            <h5 [ngClass]="{
                                'green': levelTwoApproverStatus === 'APPROVED',
                                'yellow': levelTwoApproverStatus === 'PENDING',
                                'red': levelTwoApproverStatus === 'REJECTED'
                              }">
                                {{ levelTwoApproverStatus }}
                              </h5>
                        </div>
                        <div class="approver-item" *ngIf ="approver?.levelThreeData">
                            <div class="approver">
                                <img src="/assets/images/profile.png" alt="">
                                <h4>{{approver?.levelThreeData?.firstName}} {{approver?.levelThreeData?.lastName}}</h4>
                            </div>
                            <h5 [ngClass]="{
                                'green': levelThreeApproverStatus === 'APPROVED',
                                'yellow': levelThreeApproverStatus === 'PENDING',
                                'red': levelThreeApproverStatus === 'REJECTED'
                              }">
                                {{ levelThreeApproverStatus }}
                              </h5>
                        </div>
                        <div class="approver-item" *ngIf ="approver?.levelFourData">
                            <div class="approver">
                                <img src="/assets/images/profile.png" alt="">
                                <h4>{{approver?.levelFourData?.firstName}} {{approver?.levelFourData?.lastName}}</h4>
                            </div>
                            <h5 [ngClass]="{
                                'green': levelFourApproverStatus === 'APPROVED',
                                'yellow': levelFourApproverStatus === 'PENDING',
                                'red': levelFourApproverStatus === 'REJECTED'
                              }">
                                {{ levelFourApproverStatus }}
                              </h5>
                        </div>
                    </div>
                </div>
        </div>
        <div class="submit-btn-wrapper"  
        *ngIf="
        levelOneApproverStatus === 'PENDING' || 
        levelTwoApproverStatus === 'PENDING' || 
        levelThreeApproverStatus === 'PENDING' || 
        levelFourApproverStatus === 'PENDING'
        ">
            <button class="cmn-reject-btn" (click)="onReject()">Reject</button>
            <button class="cmn-next-btn" type="submit" (click)="onApprove()">Approve</button>
        </div>

        <div class="leave-history-wrapper">
            <h3>Vacation History</h3>
            <app-vacation-history></app-vacation-history>
        </div>
    </section>
