import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { LeaveService } from '../../../../features/leave/services/leave.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-leave-history',
  standalone: true,
  imports: [
    CommonModule,
    AgGridAngular,
  ],
  templateUrl: './leave-history.component.html',
  styleUrl: '../../leave-approvals.component.scss',
  providers: []
})
export class LeaveHistoryComponent {
  @Input() employeeId: any;
  filter: any = null;
  public rowData: any[] | null = null;

  constructor(
    private leaveService: LeaveService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    // this.fetchLeaves();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['employeeId']) {
      this.fetchLeaves(this.employeeId);
    }
  }

  columnDefs = [

    { headerName: 'Type', field: 'type', flex: 1, },
    { headerName: 'Duration', field: 'duration', flex: 1 },
    { headerName: 'Days', field: 'days', flex: 1 },
    { headerName: 'Reporting Manager', field: 'reportingManager', flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      cellRenderer: (params: any) => {
        const status = params.data.status;
        const statusClass = status === "Pending" ? 'on-leave' : 'working';
        return `<span class="${statusClass}">${status}</span>`;
      }
    },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  fetchLeaves(employeeId: any) {
    this.filter = { employeeId: employeeId };
    this.leaveService.fetchLeaves(this.filter).subscribe({
      next: (data) => {
        this.rowData = data
          .map((data: any) => {
            return {
              type: data?.leaveType || '--',
              duration: this.datePipe.transform(data?.startDate, 'MMM dd') + ' - ' + this.datePipe.transform(data?.endDate, 'MMM dd') || '--',
              days: data?.totalLeaveDays + ' days' || '--',
              reportingManager: data?.reportingManager 
              ? `${data.reportingManager.firstName || ''} ${data.reportingManager.lastName || ''}`.trim() || '--' 
              : '--',
              status: data?.leaveStatus || '--',
            };
          });
      },
      error: (error) => {
        this.toastr.error("Error in fetching leave", 'Error')
      }
    })
  }
}