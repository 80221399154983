import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import {
  MatExpansionModule,
  MatExpansionPanel,
} from '@angular/material/expansion';
import { TooltipPosition, MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
// import * as PermissionSelectors from '../../core/permissions/permission.selector';
import * as PermissionActions from '../../core/permissions/permission.action';
import { EmployeeService } from '../../features/employees/services/employee.service';
import { AuthService } from '../services/auth.service';
import { selectPermissions } from '../permissions/permission.selector';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatTooltipModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
  isCollapsed = false;

  // @Input() isCollapsed: boolean = false; // Determines whether tooltips are shown

  mattabnumber = 0;
  currentPath: string = '';
  allowedPath = [
    '/dashboard',
    '/inventory ',
    '/banking',
    '/branch',
    '/branch',
    '/assets',
    '/reports',
    '/roles',
    '/configure',
  ];

  @Input() sidebarData: any;
  @Output() triggerSidebarAction = new EventEmitter<void>();
  @Input() toggleSidebar!: () => void;

  // Using ViewChildren to select all expansion panels
  @ViewChildren(MatExpansionPanel)
  expansionPanels: QueryList<MatExpansionPanel>;
  profileName: any;
  // canViewInventory$: Observable<boolean>;
  hasInventoryPermission$: Observable<boolean>;
  public permissionIds: any = [];
  loggedInUser: any;
  permissions: any;

  constructor(
    private router: Router,
    private store: Store,
    private employeeService: EmployeeService,

    private authService: AuthService
  ) { }

  ngOnInit() {

    this.hasInventoryPermission$ = this.store.select(selectPermissions).pipe(
      map((permissions: any) => [1, 2, 3, 4, 5].some((id) => permissions.includes(id)))
    );

    console.log('resolved permission', this.hasInventoryPermission$);
    
    // console.log('ngOnInit:', this.sidebarData);
    this.currentPath = this.router.url;
    const savedTab = localStorage.getItem('panelState');
    this.mattabnumber = savedTab ? JSON.parse(savedTab) : 0;
    console.log('Current Path:', this.currentPath);
    if (this.allowedPath.includes(this.currentPath)) {
      this.mattabnumber = 0;
    }
    this.loggedInUser = this.authService.getLoggedInUser();
    this.fetchProfile();
    const loggedInUser: any = localStorage.getItem('loggedInUser');
    const userData = JSON.parse(loggedInUser);
    this.profileName = userData?.profileData?.profileName;
    console.log('profileName', this.profileName);
  }

  onPanelClick() {
    if (window.innerWidth > 768) {
      this.triggerSidebarAction.emit();
    }
  }

  private fetchProfile() {
    this.employeeService
      .getUserRoleById(this.loggedInUser?.profileData?.id)
      .subscribe({
        next: (profile) => {
          this.permissions = profile;
          this.permissions.rolePermissions.map((permission: any) => {
            if (permission?.status === 1) {
              this.permissionIds.push(permission?.permission?.id);
            }
          });
          console.log('this.permissionIds', this.permissionIds);
          this.store.dispatch(
            PermissionActions.setPermissions({
              permissions: this.permissionIds,
            })
          );
        },
        error: (error) => console.error(error),
      });
  }

  // Toggle all expansion panels
  toggleExpansionPanel() {
    this.expansionPanels.forEach((panel) => {
      if (panel.expanded) {
        panel.close();
      }
    });
  }

  navigateTo(route: string) {
    this.router.navigate([route]);

    if (window.innerWidth < 993) {
      this.toggleSidebar();
    }

    // if (matnumber) {
    //   this.mattabnumber = matnumber
    //   localStorage.setItem('panelState', JSON.stringify(this.mattabnumber));
    // } else {
    //   matnumber = 0
    //   localStorage.setItem('panelState', JSON.stringify(0));
    // }
  }

  isRouteActive(route: string): boolean {
    // return this.router.isActive(route, true);
    return this.router.url.startsWith(route);
  }

  isRouteActive2(routes: string[]): boolean {
    const currentRoute = this.router.url;
    return routes.some((path) => this.router.url.startsWith(path));
  }
}
