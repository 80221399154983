<mat-dialog-content class="mat-typography">
  <section class="add-branch-dialog-wrapper">
    <h2 class="cmn-popup-title">{{branchData?.id ? 'Edit':'Add'}} Branch</h2>
    <button class="cmn-close" mat-dialog-close></button>
    <form [formGroup]="branchForm" (ngSubmit)="onAddBranch()">
      <div class="branch-form-wrapper">
        <div class="form-wrapper">
          <label for="">Branch Name <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter Name" formControlName="branchName" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error
                *ngIf="branchForm.get('branchName')!.touched && branchForm.get('branchName')!.hasError('required')">Branch
                name is required.</mat-error>
            </span>
          </div>
        </div>
        <div class="form-wrapper">
          <label for="">Address <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter Address" formControlName="address" />

          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error
                *ngIf="branchForm.get('address')!.touched && branchForm.get('address')!.hasError('required')">Address is
                required.</mat-error>
            </span>
          </div>
        </div>
        <div class="form-wrapper">
          <label for="">City <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter City Name" formControlName="city" maxlength="30" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="branchForm.get('city')!.touched && branchForm.get('city')!.hasError('required')">City is
                required.</mat-error>
            </span>
          </div>
        </div>
        <div class="form-wrapper">
          <label for="">State <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter State Name" formControlName="state" maxlength="30" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="branchForm.get('state')!.touched && branchForm.get('state')!.hasError('required')">State
                is required.</mat-error>
            </span>
          </div>
        </div>
        <div class="form-wrapper">
          <label for="">Country <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Select country" formControlName="country">
              @for (country of countries; track country) {
              <mat-option [value]="country.name">{{country.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error
                *ngIf="branchForm.get('country')!.touched && branchForm.get('country')!.hasError('required')">Country is
                required.</mat-error>
            </span>
          </div>
        </div>
        <div class="form-wrapper">
          <label for="">Zip Code <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter Zip Code" formControlName="zipCode" maxlength="9" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="branchForm.get('zipCode')!.touched && branchForm.get('zipCode')!.hasError('required')">
                Zip code is required.
              </mat-error>
            </span>
            <span>
              <mat-error *ngIf="branchForm.get('zipCode')!.touched && branchForm.get('zipCode')!.hasError('minlength')">
                Zip code must be at least 5 digits long.
              </mat-error>
            </span>
            <span>
              <mat-error *ngIf="branchForm.get('zipCode')!.touched && branchForm.get('zipCode')!.hasError('maxlength')">
                Zip code cannot be more than 9 digits long.
              </mat-error>
            </span>
          </div>
        </div>

        <div class="form-wrapper">
          <label for="">Phone Number<span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter Number" formControlName="phoneNumber" minlength="7" maxlength="15" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error
                *ngIf="branchForm.get('branchCode')!.touched && branchForm.get('branchCode')!.hasError('required')">Phone
                number is required.</mat-error>
            </span>
            <span>
              <mat-error *ngIf="
                branchForm.get('phoneNumber')!.touched &&
                branchForm.get('phoneNumber')!.hasError('invalidNumber')
              ">Please enter a valid phone number</mat-error>
            </span>
            <span>
              <mat-error *ngIf="
                branchForm.get('phoneNumber')!.touched &&
                branchForm.get('phoneNumber')!.hasError('minlength')
              ">Phone number must be at least 7 digits long.</mat-error>
            </span>
            <span>
              <mat-error *ngIf="
                branchForm.get('phoneNumber')!.touched &&
                branchForm.get('phoneNumber')!.hasError('maxlength')
              ">Phone number cannot be more than 15 digits long.</mat-error>
            </span>
          </div>
        </div>
        <div class="form-wrapper">
          <label for="">Email Address <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter email id" formControlName="email" />
          </mat-form-field>
          <div class="custom-error" *ngIf="
          branchForm.get('email')?.invalid &&
          branchForm.get('email')?.touched">
            <span>
              <mat-error *ngIf="branchForm.get('email')!.touched && branchForm.get('email')!.hasError('required')">Email
                is required.
              </mat-error>
            </span>
            <span>
              <mat-error *ngIf="branchForm.get('email')?.errors?.['invalidEmail']">
                Please enter a valid email address.
              </mat-error>
            </span>
          </div>
        </div>
        <div class="form-wrapper">
          <label for="">Branch Code <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter Branch Code" formControlName="branchCode" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error
                *ngIf="branchForm.get('branchCode')!.touched && branchForm.get('branchCode')!.hasError('required')">Branch
                code is required.</mat-error>
            </span>
          </div>
        </div>
        <div class="form-wrapper">
          <label for="">License Code
            <span class="red">*</span>
          </label>
          <mat-form-field>
            <input matInput placeholder="Enter License Code" formControlName="licenseCode" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error
                *ngIf="branchForm.get('licenseCode')!.touched && branchForm.get('licenseCode')!.hasError('required')">Branch
                code is required.</mat-error>
            </span>
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <!-- <button type="submit" class="cmn-popup-btn">
          {{branchData?.id ? 'Save':'Add Branch'}}
        </button> -->
        <button class="cmn-next-btn" type="submit" [disabled]="loading">
          <span *ngIf="!loading">{{branchData?.id ? 'Save':'Add Branch'}}</span>
          <span *ngIf="loading">Loading...</span>
        </button>
      </div>
    </form>
  </section>
</mat-dialog-content>