<section class="cmn-innerpage-wrapper quotation-details">
  <div class="head">
    <div class="search-wrapper">
      <input type="text" class="cmn-header-search" placeholder="Search"  (input)="onSearch($event)"/>
    </div>
    <!-- <button class="cmn-upload-btn" (click)="openDialog()">
      <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
      <span>Upload</span>
    </button> -->
    <button
      class="cmn-add-btn"
      (click)="navigateTo('/quotation/quotation-new')"
    >
      <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New
      Quotation
    </button>
    <!-- <button
      class="cmn-filter-btn"
      (click)="toggleDropdown($event)"
      [ngClass]="{ 'class-clicked': isDropdownOpen }"
    >
      <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
    </button> -->
    <!-- Filter -->
    <div
      [ngClass]="{ show: isDropdownOpen }"
      class="common-dropdown filter-position"
      #dropdown
    >
      <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
      <form>
        <h6 class="cmn-popup-heading">Filter <span>Clear All</span></h6>
        <div class="filter-inner-content">
          <h2>Branch</h2>
          <div class="check-wrapper">
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" />
                test
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="btn-wrapper">
          <button class="cmn-apply-btn" type="submit">Apply</button>
        </div>
      </form>
    </div>
    <!-- Filter -->
  </div>
  <div class="sub-head">
    <div class="flex-sub-head">
      <ul>
        <li class="main">All Quotes</li>
        <li class="edit" (click)="navigateToSendMail(selectedQuote?.id)">
          <img src="assets/images/icons/icon-send.svg" alt="Mail" /> Send to
          mail
        </li>
        <li class="edit" (click)="toggleComments($event)">
          <img src="assets/images/icons/icon-comment.svg" alt="Comment" />
          Comments & History
        </li>
        <li class="edit" (click)="navigateToEdit(selectedQuote?.id)">
          <img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit" />
          Edit
        </li>
        <!-- <li class="edit">
          <img src="assets/images/icons/icon-record.svg" alt="Record" /> Record
          Payment
        </li> -->
      </ul>
      <!-- Comments Dropdown -->
      <div
        [ngClass]="{ show: isCommentsOpen }"
        class="common-dropdown filter-position"
        #dropdown2
      >
        <button
          class="close-dropdown"
          (click)="toggleComments($event)"
        ></button>
        <form [formGroup]="commentsForm" (ngSubmit)="onCommentSubmit()">
          <h6 class="cmn-popup-heading">Comments</h6>
          <div class="filter-inner-content">
            <div class="form-wrapper">
              <mat-form-field>
                <textarea
                  matInput
                  placeholder="Add Your Comment Here..."
                  formControlName="comments"
                ></textarea>
                <mat-error
                  *ngIf="
                    commentsForm.get('comments')!.touched &&
                    commentsForm.get('comments')!.hasError('required')
                  "
                >
                  Comment is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="btn-wrapper">
              <button class="cmn-apply-btn" type="submit" [disabled]="loading">
                {{ loading ? "Submitting..." : "Submit" }}
              </button>
            </div>
            <div class="timelinne-wrapper">
              <!-- ngFor -->
              <div class="timeline-box" *ngFor="let comment of commentsList">
                <div class="timeline-head">
                  <ul>
                    <li>{{ comment?.employee?.firstName }}</li>
                    <li>{{ comment.createdAt | date : "dd/MM/yyyy" }}</li>
                  </ul>
                  <h3>{{ comment.createdAt | date : "hh:mm a" }}</h3>
                </div>
                <div class="timeline-content">
                  <h4>{{ comment?.note }}</h4>
                </div>
              </div>
              <!-- ngFor -->
            </div>
          </div>
        </form>
      </div>
      <!-- Comments Dropdown -->
      <div class="branch-wrapper">
        <form>
          <mat-form-field>
            <mat-select
              [(value)]="selectedQuote"
              (selectionChange)="onQuoteSelectionChange($event.value)"
              placeholder="Select quote"
            >
              <mat-option
                *ngFor="let quote of parentQuoteData?.quoteData"
                [value]="quote"
              >
                {{ quote.quoteCode || "--" }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>
    <div class="mat-menu-wrapper">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item type="button" type="button" (click)="quoteMarkAsSent()">
          <span>Mark as Sent</span>
        </button>
        <button mat-menu-item type="button" (click)="reviseNewQuote()">
          <span>Revise New Quote</span>
        </button>
        <!-- <button mat-menu-item>
          <span>Credit Available</span>
        </button>
        <button mat-menu-item>
          <span>Create Credit Note</span>
        </button>
        <button mat-menu-item>
          <span>Credit Applied</span>
        </button>
        <button mat-menu-item>
          <span>Clone</span>
        </button> -->
        <button
          mat-menu-item
          type="button"
          (click)="onDeleteQuoteById(selectedQuote?.id)"
        >
          <span>Delete</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="manual-journal-details-tab-wrapper">
    <div class="tab-left">
      <div class="tab-box-main-wrapper">
        <button
          *ngFor="let parentQuote of allParentQuotes; let i = index"
          class="tab-custom-button"
          [class.active]="selectedTabIndex === i"
          (click)="selectTab(i)"
        >
          <div class="inn-box">
            <h2>{{ parentQuote.parentCode }}</h2>
            <h3>{{ parentQuote.quoteCode }}</h3>
          </div>
          <div class="inn-box">
            <h6>{{ parentQuote.quoteDate | date : "dd MMM yyyy" }}</h6>
            <h5>{{ parentQuote?.projectData?.projectName }}</h5>
          </div>
          <!-- <div class="inn-box">
            <h4 class="grey">Draft</h4>
          </div> -->
        </button>
      </div>
    </div>
    <div class="manual-journal-details-content-wrapper">
      <div *ngIf="selectedQuote">
        <div class="chart-tab-content">
          <div class="tab-main-container">
            <div class="tab-inner">
              <div class="note-header">
                <div class="left-set">
                  <h4>{{ parentQuoteData?.partnerData?.displayName }}</h4>
                  <!-- <h3>United Arab Emirates</h3>
                  <h3>Abu Dhabi 12345</h3> -->
                </div>
                <div class="right-set">
                  <h2>QUOTE</h2>
                  <h3>{{ parentQuoteData.quoteCode }}</h3>
                  <h3>{{ selectedQuote.quoteDate | date : "dd MMM yyyy" }}</h3>
                </div>
              </div>

              <div class="journal-details">
                <div class="left-set">
                  <div class="details-box">
                    <h4>TRN No.</h4>
                    <h5>:</h5>
                    <h6>{{ selectedQuote.TRN }}</h6>
                  </div>
                  <div class="details-box">
                    <h4>SOW</h4>
                    <h5>:</h5>
                    <h6>{{ selectedQuote.SOW }}</h6>
                  </div>
                </div>
                <div class="right-set">
                  <div class="details-box">
                    <h4>Reference</h4>
                    <h5>:</h5>
                    <h6>{{ selectedQuote.refNumber }}</h6>
                  </div>
                </div>
              </div>
              <div class="crdit-note-page-table">
                <table>
                  <tr>
                    <!-- <th>Sr. No</th> -->
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Unit</th>
                    <th>Price</th>
                    <th>Discount</th>
                    <th>Net Amount</th>
                  </tr>
                  <tr *ngFor="let item of selectedQuote?.quoteItems">
                    <!-- <td>#1</td> -->
                    <td>{{ item.description }}</td>
                    <td>{{ item.quantity || "--" }}</td>
                    <td>{{ item.unit || "--" }}</td>
                    <td>{{ item.rate || "--" }}</td>
                    <td>{{ item.discount || "--" }}</td>
                    <td>{{ item.amount || "--" }}</td>
                  </tr>
                </table>
              </div>
              <div class="page-footer-content">
                <div class="left-set">
                  <div class="details-box">
                    <h4>Exclusion :</h4>
                    <div
                      *ngFor="let exclusion of selectedQuote?.quoteExclusions"
                    >
                      <h6>{{ exclusion?.exclusion }}</h6>
                    </div>
                  </div>
                </div>
                <div class="right-set">
                  <div class="details-box">
                    <h4>Sub total</h4>
                    <h5>:</h5>
                    <h6>{{ selectedQuote.subTotal }}</h6>
                  </div>
                  <div class="details-box">
                    <h4>Tax</h4>
                    <h5>:</h5>
                    <h6>{{ selectedQuote.tax }}</h6>
                  </div>
                  <div class="details-box">
                    <h4>Total</h4>
                    <h5>:</h5>
                    <h6>{{ selectedQuote.totalAmount }}</h6>
                  </div>
                  <!-- <div class="details-box">
                    <h4>Credits Used</h4>
                    <h5>:</h5>
                    <h6>AED 40</h6>
                  </div> -->
                  <!-- <div class="details-box bold">
                    <h4>Credits Remaining</h4>
                    <h5>:</h5>
                    <h6>AED 590</h6>
                  </div> -->
                </div>
              </div>

              <div class="terms-conditions">
                <h4>Terms & Conditions :</h4>
                <h6>
                  {{ selectedQuote?.termsCondition || "--" }}
                </h6>
              </div>

              <!-- <div class="signature-wrapper">
                <div class="box">
                  <h4>Contract Manager :</h4>
                  <h6>Manager Name</h6>
                </div>
                <div class="box">
                  <h4>Managing Director :</h4>
                  <h6>Director Name</h6>
                </div>
              </div> -->

              <!-- <p class="signature">Authorized Signature <span></span></p> -->

              <!-- <span class="published-note"
                ><img src="assets/images/img-draft.svg" alt="Draft"
              /></span> -->
            </div>
          </div>

          <div class="quotation-footer-wrapper">
            <div class="flex-parent">
              <!-- <div class="form-wrapper">
                <label for="">Notes</label>
                <mat-form-field>
                  <textarea matInput placeholder="Reason for rejection">
                  </textarea>
                </mat-form-field>
              </div>
              <div class="uploaded-file">
                <h6>
                  file-name.pdf
                  <img
                    src="assets/images/icons/icon-close.svg"
                    alt="Close Icon"
                  />
                </h6>
              </div> -->
            </div>
            <!-- <div class="submit-btn-wrapper">
              <button
                class="cmn-next-btn"
                type="button"
                (click)="openQuotaionApprovalDialog()"
              >
                Confirm Approval
              </button>
            </div> -->
            <div class="submit-btn-wrapper">
              <ng-container *ngIf="selectedQuote?.status === 'ACCEPTED'; else confirmButton">
                <button class="cmn-next-btn" type="button" disabled>
                  Confirmed
                </button>
              </ng-container>
              <ng-template #confirmButton>
                <button
                  class="cmn-next-btn"
                  type="button"
                  (click)="openQuotaionApprovalDialog()"
                >
                  Confirm Approval
                </button>
              </ng-template>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
