import {CommonModule} from "@angular/common";
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {AddressTypeEnum} from "../../../../helpers/helper-file";
import {HttpClient} from "@angular/common/http";
import {CustomerService} from "../../../../features/customers/services/customer.service";
import {ActivatedRoute} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {InvoiceService} from "../../../../features/invoice/services/invoice.service";

@Component({
    selector: 'billing-address-dialog',
    templateUrl: 'billing-address-dialog.component.html',
    styleUrls: ['../../invoice-new.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        CommonModule,
        MatInputModule,
        MatSelectModule,
    ]
})
export class BillingAddressDialog implements OnInit {
    billingAddressForm : FormGroup;
    @Input()customerData : any = {};
    @Output()formSubmit = new EventEmitter<any>();
    search : string | undefined;
    filter : {};
    currencyData : any;
    file : any;
    customerId : any;
    countries : any[] = [];
    addressType = AddressTypeEnum;
    loading : boolean = false;

    constructor(private fb : FormBuilder, private http : HttpClient, private customerService : CustomerService, private route : ActivatedRoute, private toastr : ToastrService, private dialogRef : MatDialogRef < BillingAddressDialog >, private invoiceService : InvoiceService, @Inject(MAT_DIALOG_DATA)public data : {
        clientId: number;
        type: any
    }) {
        this.customerId = data.clientId;
    }

    ngOnInit(): void {
        this.initForm();
        this.populateFormWithCustomerData();
        this.loadCountries();
        this.fetchPartnerById();
    }

    initForm() {
        this.billingAddressForm = this.fb.group({
            id: [''],
            attention: [''],
            addressType: ['BILLING'],
            addressLine1: [''],
            addressLine2: [''],
            city: [
                '',
                [Validators.maxLength(15)]
            ],
            state: [
                '',
                [Validators.maxLength(10)]
            ],
            country: [''],
            pinCode: [
                '',
                [
                    Validators.maxLength(8), Validators.pattern(/^\d+$/)
                ]
            ],
            fax: [
                '',
                [
                    Validators.maxLength(15), Validators.pattern(/^\d+$/)
                ]
            ],
            phone: [
                '',
                [
                    Validators.minLength(10), Validators.maxLength(15), Validators.pattern(/^\d+$/) // Ensures only digits are allowed
                ]
            ]
        });
    }


    loadCountries(): void {
        this.http.get<any[]>('assets/json/countries.json').subscribe((data) => {
            this.countries = data;
        }, (error) => {
            console.log('Error loading country data----', error);
        });
    }

    fetchPartnerById() {
        this.customerService.fetchPartnerById(Number(this.customerId)).subscribe({
            next: (data) => {
                this.customerData = data;
                console.log(this.customerData);
                const type = this.data ?. type;
                if (type != 'add') {
                    this.populateFormWithCustomerData();
                }
            },
            error: (error) => {
                console.error(error, 'Error');
            }
        });
    }

    populateFormWithCustomerData() {
        console.log(this.customerData);

        if (this.customerData && this.customerData.addresses) {
            this.customerData.addresses.map((address : any) => {
                if (address.addressType === 'BILLING') {
                    this.billingAddressForm.patchValue({
                        id: Number(address.id),
                        attention: address.attention || '',
                        addressLine1: address.addressLine1 || '',
                        addressLine2: address.addressLine2 || '',
                        city: address.city || '',
                        state: address.state || '',
                        country: address.country || '',
                        pinCode: address.pinCode || '',
                        fax: address.fax || '',
                        phone: address.phone || ''
                    });
                }
            });
        }
    }

    onCancel() {
        this.billingAddressForm.reset();
    }

    onSubmitBillingAddress() {
        if (this.billingAddressForm.valid) {
            const value = this.billingAddressForm.value;
            const createAddressData = {
                partnerId: this.customerId,
                attention: value.attention,
                addressType: this.addressType.billing,
                addressLine1: value.addressLine1,
                addressLine2: value.addressLine2,
                city: value.city,
                state: value.state,
                country: value.country,
                pinCode: value.pinCode,
                phone: value.phone,
                fax: value.fax
            };
            const updateAddressData = {
                partnerId: this.customerId,
                attention: value.attention,
                addressType: this.addressType.billing,
                addressLine1: value.addressLine1,
                addressLine2: value.addressLine2,
                city: value.city,
                state: value.state,
                country: value.country,
                pinCode: value.pinCode,
                phone: value.phone,
                fax: value.fax,
                id: value.id
            };
            this.loading = true;
            if (value.id) {
                console.log('Updating address with id:', value.id);
                this.invoiceService.updateAddress({
                    ... updateAddressData,
                    id: value.id
                }).subscribe({
                    next: () => {
                        this.loading = false;
                        this.toastr.success('Address updated successfully');
                        this.dialogRef.close();
                    },
                    error: (error) => {
                        this.loading = false;
                        this.toastr.error(error.message || 'Failed to update address.');
                    }
                });
            } else {
                this.invoiceService.createAddress(createAddressData).subscribe({
                    next: () => {
                        this.loading = false;
                        this.toastr.success('Address created successfully');
                        this.dialogRef.close();
                    },
                    error: (error) => {
                        this.loading = false;
                        this.toastr.error(error.message || 'Failed to create address.');
                    }
                });
            }
        } else {
            this.billingAddressForm.markAllAsTouched();
            this.toastr.warning("Please fill in all required fields.");
        }
    }


}
