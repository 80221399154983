import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_FACE_ID_DEVICES } from '../graphql/queries/faceid-devices.query';

@Injectable({
  providedIn: 'root',
})
export class FaceidDeviceServices {
  constructor(private apollo: Apollo) {}

  fetchFaceIdDevices(): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_FACE_ID_DEVICES,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((result) => result.data?.getAllDevices || [])
      );
  }
}
