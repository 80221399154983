import { gql } from "apollo-angular";

// munawel-accounting
export const REMOVE_PURCHASE_ORDER = gql`
  mutation markPurchaseOrderAsRemoved($id: Int!) {
    markPurchaseOrderAsRemoved(id: $id)
  }
`;
// munawel-accounting
export const MARK_AS_CANCELED_PURCHASEORDER = gql`
  mutation markAsCancelled($id: Int!) {
    markAsCancelled(id: $id) {
      id
    }
  }
`;


// munawel-accounting
export const CREATE_PURCHASE_ORDER = gql`
  mutation CreatePurchaseOrder(
    $createPurchaseOrderInput: CreatePurchaseOrderInput!
    $createPurchaseItemInput: [CreatePurchaseItemInput!]!
  ) {
    createPurchaseOrder(
      createPurchaseOrderInput: $createPurchaseOrderInput
      createPurchaseItemInput: $createPurchaseItemInput
    ) {
      id
      vendorId
      currencyId
      subTotal
      tax
      adjustment
       subTotal
      totalPrice
      totalPrice
    }
  }
`;
// munawel-accounting
export const UPDATE_PURCHASE_ORDER = gql`
  mutation updatePurchaseOrder(
    $updatePurchaseOrderInput: UpdatePurchaseOrderInput!
    $updatePurchaseItemInput: [CreatePurchaseItemInput!]
  ) {
    updatePurchaseOrder(
      updatePurchaseOrderInput: $updatePurchaseOrderInput
      updatePurchaseItemInput: $updatePurchaseItemInput
    ) {
      id
      vendorId
    }
  }
`;
// munawel-accounting
export const MARK_AS_ISSUED = gql`
  mutation markAsIssued($id: Int!) {
    markAsIssued(id: $id){
    id
    }
  }
`;

// munawel-accounting
export const CONVERT_TO_BILL = gql`
  mutation convertToBill($id: Int!) {
    convertToBill(id: $id) {
      id
    }
  }
`;

// munawel-accounting
export const CREATE_COMMENT = gql`
  mutation createComment($createCommentInput: CreateCommentInput!) {
    createComment(createCommentInput: $createCommentInput) 
    {
    id
  }
  }
`;

