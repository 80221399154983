<section class="header-wrapper" [ngClass]="{ collapsedClass: sidebarData }">
  <!-- <div class="left-set">
    <h2 class="cmn-title">
      {{ breadcrumbs.length ? breadcrumbs[0]?.name : "Dashboard" }}
    </h2>
    <div class="cmn-sub-title">
      <ul>
        <li *ngFor="let breadcrumb of breadcrumbs; let i = index">
          <a
            [routerLink]="breadcrumb.path"
            *ngIf="i < breadcrumbs.length - 1"
            >{{ breadcrumb.name }}</a
          >
          <span *ngIf="i === breadcrumbs.length - 1">{{
            breadcrumb.name
          }}</span>
        </li>
      </ul>
      <h6 class="back" *ngIf="shouldShowBackButton()" (click)="goBack()">
        <img src="assets/images/icons/icon-back.svg" alt="Icon Back" />
        Back
      </h6>
    </div>
  </div> -->

  <div class="right-set">
    <div class="branch-wrapper">
      <h3>Branch</h3>
      <form [formGroup]="branchForm">
        <mat-form-field>
          <mat-select placeholder="Select Branch" formControlName="branchName">
            <mat-option *ngFor="let branch of branchData" [value]="branch?.id">
              {{ branch?.branchName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <!-- <div class="right-combo-wrapper">
      <div class="notification-wrapper">
        <div class="notification-box active">
          <img src="assets/images/icons/notification.svg" alt="Notification">
        </div>
      </div>
      <div class="profile-wrapper">
        <button (click)="toggleDropdown($event)" [ngClass]="{'class-clicked': isDropdownOpen}">
          <div class="profile-icon">
            <img src="assets/images/profile.png" alt="Profile">
          </div>
          <div class="details">
            <h2>Robert</h2>
            <h3>Admin</h3>
          </div>
        </button>
      </div>
    </div> -->
    <div class="right-combo-wrapper">
      <div class="notification-wrapper">
        <div class="notification-box active" (click)="notificationRoute()">
          <img src="assets/images/icons/notification.svg" alt="Notification" />
        </div>
      </div>
      <div class="profile-wrapper">
        <button
          (click)="toggleDropdown($event)"
          [ngClass]="{ 'class-clicked': isDropdownOpen }"
        >
          <div class="profile-icon">
            <img
              [src]="
                profilePicture
                  ? profilePicture
                  : 'assets/images/dummy-profile-image.jpg'
              "
              alt="Profile Photo"
            />
          </div>
          <div class="details">
            <h2>
              {{ userData?.firstName }}
              {{ userData?.lastName }}
            </h2>
            <h3>{{ userData?.designation?.designationName }}</h3>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div [ngClass]="{ show: isDropdownOpen }" class="profile-items" #dropdown>
    <ul>
      <li (click)="handleDropdownClick($event)">
        <img src="assets/images/icons/icon-user.svg" alt="User Icon" /> My
        Profile
      </li>
      <li class="logout" (click)="logout()">
        <img src="assets/images/icons/icon-logout.svg" alt="User Logout" />
        Logout
      </li>
    </ul>
  </div>
</section>
