
    <section class="cmn-innerpage-wrapper leave-grand">
        <label class="radio-label">Employee Type</label>
        <!-- Format Selection -->
        <div class="type-selection">
            <label class="radiobtn-wrapper">
                <input type="radio" name="exportFormat" />
                <span class="radiomark"></span>
                Grant for all Employees
            </label>
            <label class="radiobtn-wrapper">
                <input type="radio" name="exportFormat" />
                <span class="radiomark"></span>
                Grant for newly joined employees
            </label>
        </div>
        <div class="leave-grand-inputs">
            <div class="form-wrapper">
                <label for="">Periodicity</label>
                <mat-form-field>
                    <mat-select placeholder="Monthly">
                        <mat-option value="Single">Single</mat-option>
                        <mat-option value="Married">Married</mat-option>
                        <mat-option value="Divorced">Divorced</mat-option>
                        <mat-option value="Widowed">Widowed</mat-option> </mat-select>
                </mat-form-field>
            </div>
            <div class="form-wrapper">
                <label for="">Period</label>
                <mat-form-field>
                    <mat-select placeholder="Select period">
                        <mat-option value="Single">Single</mat-option>
                        <mat-option value="Married">Married</mat-option>
                        <mat-option value="Divorced">Divorced</mat-option>
                        <mat-option value="Widowed">Widowed</mat-option> </mat-select>
                </mat-form-field>
            </div>
            <div class="form-wrapper">
                <label for="">Leave Scheme</label>
                <mat-form-field>
                    <mat-select placeholder="General Scheme">
                        <mat-option value="Single">Single</mat-option>
                        <mat-option value="Married">Married</mat-option>
                        <mat-option value="Divorced">Divorced</mat-option>
                        <mat-option value="Widowed">Widowed</mat-option> </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="grand-leave-table">
            <table>
                <tr class="no-border">
                    <th>Leave Type</th>
                    <th>Upcoming Auto Grant Date</th>
                    <th>Maximum Leaves Granted</th>
                    <th>Approve</th>
                </tr>
                <tr>
                    <td>Loss of Pay</td>
                    <td>28/08/2024</td>
                    <td>5 Days</td>
                    <td>
                        <label class="checkbox-wrapper">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>Loss of Pay</td>
                    <td>28/08/2024</td>
                    <td>5 Days</td>
                    <td>
                        <label class="checkbox-wrapper">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>Loss of Pay</td>
                    <td>28/08/2024</td>
                    <td>5 Days</td>
                    <td>
                        <label class="checkbox-wrapper">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
            </table>
        </div>
        <div class="submit-btn-wrapper">
            <button class="cmn-cancel-btn">Cancel</button>
            <button class="cmn-next-btn">Save</button>
        </div>
    </section>
