import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GET_ACCOUNT_TYPE_SUMMARY,
  GET_BALANCE_SHEET_REPORT,
  GET_CASH_FLOW_REPORT,
  GET_CUSTOMER_BALANCE_SUMMARY_REPORT,
  GET_GENERAL_LEDGER_REPORT,
  GET_JOURNAL_REPORT,
  GET_PROFIT_LOSS_REPORT,
  GET_SALES_BY_CUSTOMER_REPOTT,
  GET_SALES_BY_ITEM_REPORT,
  PAYMENT_RECEIVED_REPORT,
  VENDOR_BALANCE_SUMMARY_REPORT,
  PETTYCASH_SUMMARY_REPORT,
  GET_PAYMENT_MADE_REPORT,
  TRIAL_BALANCE_REPORT,
  GET_INVOICE_AGING_REPORT,
  GET_VENDOR_INVOICE_AGING_REPORT
} from '../graphql/querries/reports.query';
import {
  EXPORT_ACCOUNT_TYPE_SUMMARY_TO_CSV,
  EXPORT_BALANCE_SHEET_TO_CSV,
  EXPORT_CASH_FLOW_TO_CSV,
  EXPORT_CUSTOMER_BALANCE_SUMMARY_TO_CSV,
  EXPORT_GENERAL_LEDGER_TO_CSV,
  EXPORT_JOURNALS_REPORT_TO_CSV,
  EXPORT_PAYMENT_MADE_TO_CSV,
  EXPORT_PAYMENT_RECEIVED_TO_CSV,
  EXPORT_PETTY_CASH_SUMMARY_TO_CSV,
  EXPORT_PROFIT_LOSS_TO_CSV,
  EXPORT_SALES_BY_CUSTOMER_TO_CSV,
  EXPORT_SALES_BY_ITEM_TO_CSV,
  EXPORT_VENDOR_BALANCE_SUMMARY_TO_CSV
} from '../graphql/mutations/reports.mutation';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private apollo: Apollo) {}

  //export sales by customer report
  exportSalesByCustomerToCSV(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('project')
      .mutate<any>({
        mutation: EXPORT_SALES_BY_CUSTOMER_TO_CSV,
        variables: {
          startDate,
          endDate,
        },
      })
      .pipe(map((result) => result.data?.exportSalesByCustomerToCSV));
  }

  //export sales by item report
  exportSalesByItemToCSV(startDate: string, endDate: string): Observable<any> {
    return this.apollo
      .use('project')
      .mutate<any>({
        mutation: EXPORT_SALES_BY_ITEM_TO_CSV,
        variables: {
          startDate,
          endDate,
        },
      })
      .pipe(map((result) => result.data?.exportSalesByItemToCSV));
  }

  //export customer balance summary
  exportCustomerBalanceSummaryToCSV(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('project')
      .mutate<any>({
        mutation: EXPORT_CUSTOMER_BALANCE_SUMMARY_TO_CSV,
        variables: {
          startDate,
          endDate,
        },
      })
      .pipe(map((result) => result.data?.exportCustomerBalanceSummaryToCSV));
  }

  //export profit loss
  exportProfitLossToCSV(startDate: string, endDate: string): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate<any>({
        mutation: EXPORT_PROFIT_LOSS_TO_CSV,
        variables: {
          startDate,
          endDate,
        },
      })
      .pipe(map((result) => result.data?.exportProfitLossToCSV));
  }

  //export payment received
  exportPaymentReceivedToCSV(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate<any>({
        mutation: EXPORT_PAYMENT_RECEIVED_TO_CSV,
        variables: {
          startDate,
          endDate,
        },
      })
      .pipe(map((result) => result.data?.exportPaymentReceivedToCSV));
  }

  //export payment made
  exportPaymentMadeToCSV(
    startDate: string,
    endDate: string,
    fields: any
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate<any>({
        mutation: EXPORT_PAYMENT_MADE_TO_CSV,
        variables: {
          startDate,
          endDate,
          fields,
        },
      })
      .pipe(map((result) => result.data?.exportPaymentReceivedToCSV));
  }

  //export balance sheet
  exportBalanceSheetToCSV(startDate: string, endDate: string): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate<any>({
        mutation: EXPORT_BALANCE_SHEET_TO_CSV,
        variables: {
          startDate,
          endDate,
        },
      })
      .pipe(map((result) => result.data?.exportBalanceSheetToCSV));
  }

  //export cash flow
  exportCashFlowToCSV(startDate: string, endDate: string): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate<any>({
        mutation: EXPORT_CASH_FLOW_TO_CSV,
        variables: {
          startDate,
          endDate,
        },
      })
      .pipe(map((result) => result.data?.exportCashFlowToCSV));
  }

  //export petty cash summary 
  exportPettyCashSummary(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate<any>({
        mutation: EXPORT_PETTY_CASH_SUMMARY_TO_CSV,
        variables: {
          startDate,
          endDate,
        },
      })
      .pipe(map((result) => result.data?.exportPettyCashSummary));
  }

  //export vendor balance
  exportVendorBalanceSummary(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate<any>({
        mutation: EXPORT_VENDOR_BALANCE_SUMMARY_TO_CSV,
        variables: {
          startDate,
          endDate,
        },
      })
      .pipe(map((result) => result.data?.exportVendorBalanceSummary));
  }

  //export account type summary
  exportAccountTypesSummaryToCSV(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate<any>({
        mutation: EXPORT_ACCOUNT_TYPE_SUMMARY_TO_CSV,
        variables: {
          startDate,
          endDate,
        },
      })
      .pipe(map((result) => result.data?.exportAccountTypesSummaryToCSV));
  }

  //export general ledger
  exportJournalsLedgerToCSV(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate<any>({
        mutation: EXPORT_GENERAL_LEDGER_TO_CSV,
        variables: {
          startDate,
          endDate,
        },
      })
      .pipe(map((result) => result.data?.exportJournalsLedgerToCSV));
  }

  //export Journal report
  exportJournalsReportToCSV(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate<any>({
        mutation: EXPORT_JOURNALS_REPORT_TO_CSV,
        variables: {
          startDate,
          endDate,
        },
      })
      .pipe(map((result) => result.data?.exportJournalsReportToCSV));
  }

  fetchProfitLossReport(startDate: string, endDate: string): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_PROFIT_LOSS_REPORT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data.getProfitLoss || []));
  }

  fetchCashFlowReport(startDate: string, endDate: string): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_CASH_FLOW_REPORT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.cashFlowStatement || [])
      );
  }

  fetchBalanceSheet(startDate: string, endDate: string): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_BALANCE_SHEET_REPORT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.getBalanceSheet || [])
      );
  }

  fetchSalesByItem(startDate: string, endDate: string): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_SALES_BY_ITEM_REPORT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data.salesByItem || []));
  }

  fetchsalesByCustomer(startDate: string, endDate: string): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_SALES_BY_CUSTOMER_REPOTT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.salesByCustomer || [])
      );
  }

  fetchAccountTypeSummaryReport(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_ACCOUNT_TYPE_SUMMARY,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.getAccountTypesSummary || [])
      );
  }

  fetchJournalReport(startDate: string, endDate: string): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_JOURNAL_REPORT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data.journalsReport || []));
  }

  fetchGeneralLedgerReport(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_GENERAL_LEDGER_REPORT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.journalsLedgerReport || [])
      );
  }

  fetchCustomerBalanceSummaryReport(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_CUSTOMER_BALANCE_SUMMARY_REPORT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.customerBalanceSummary || [])
      );
  }

  getInvoiceAgingReport(
    daysFilter: string,
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_INVOICE_AGING_REPORT,
        variables: { daysFilter },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.getInvoiceAgingReport || [])
      );
  }

  getVendorInvoiceAgingReport(
    daysFilter: string,
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_VENDOR_INVOICE_AGING_REPORT,
        variables: { daysFilter },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.getBillAgingReport || [])
      );
  }

  fetchPaymentReceivedReport(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: PAYMENT_RECEIVED_REPORT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.paymentReceivedReport || [])
      );
  }

  fetchPaymentMadeReport(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_PAYMENT_MADE_REPORT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.getPaymentMadeReports || [])
      );
  }

  fetchVendorBalanceReport(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: VENDOR_BALANCE_SUMMARY_REPORT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.getVendorBalanceSummary || [])
      );
  }

  getTrialBalanceReport(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: TRIAL_BALANCE_REPORT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.getTrialBalanceReport || [])
      );
  }

  fetchPettyCash(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: PETTYCASH_SUMMARY_REPORT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.getPettyCashSummary || [])
      );
  }
}



