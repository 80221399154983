import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CustomerService } from '../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-warehouse-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
  ],
  templateUrl: './warehouse-new.component.html',
  styleUrl: './warehouse-new.component.scss',
})
export class WarehouseNewComponent {
  newWarehouseForm: FormGroup;
  vendorData: any = {};
  addressDetails: any;
  contactDetails: any;
  remarkDetails: any;
  documentDetails: any = {};
  vendorId: any;
  fileName: any;
  contacts: any;
  contactPersons: any;
  validationError: boolean = false;
  documentArray: any[] = [];

  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.initForm();
    this.route.paramMap.subscribe((params) => {
      this.vendorId = params.get('id');
    });
  }

  initForm() {
    if (this.vendorData && this.contacts) {
      console.log('inside', this.vendorData);

      this.newWarehouseForm = this.fb.group({
        id: Number(this.vendorId),
        contact: [this.contacts.salutation],
        name: [this.contacts.name || ''],
        code: [this.contacts.code || ''],
        contactNumber: [
          this.vendorData.contactNumber || '',
          [
            Validators.minLength(8),
            Validators.maxLength(15),
            Validators.pattern('^[0-9]+$'),
          ],
        ],
      });
    } else {
      this.newWarehouseForm = this.fb.group({
        customerType: ['Bussiness', Validators.required],
        contact: ['', Validators.required],
        name: ['', [Validators.maxLength(50)]],
        code: ['', [Validators.maxLength(50)]],
        contactNumber: [
          '',
          [
            Validators.minLength(8),
            Validators.maxLength(15),
            Validators.pattern('^[0-9]+$'),
          ],
        ],
      });
    }
  }

  async onSubmit() {
    this.validationError = false;
    if (this.newWarehouseForm.invalid) {
      this.newWarehouseForm.markAllAsTouched();
      this.validationError = true;
      this.toastr.warning('Please fill mandatory fields!', 'Warning');
      return;
    }
  }

  onCancel() {
    this.newWarehouseForm.reset();
  }
}
