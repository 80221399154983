import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  inject,
  HostListener,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { ExpenseServices } from '../../features/expenses/services/expenses.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';

@Component({
  selector: 'app-expense',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatInputModule, AgGridAngular, PermissionDirective],
  templateUrl: './expense.component.html',
  styleUrl: './expense.component.scss',
})
export class ExpenseComponent {
  public loading: boolean = true;
  public error: any = null;
  expenseServices: any;
  public rowData: any;
  // private searchSubject = new Subject<string>();
  public viewPortSize: boolean = false;
  columnDefs: { headerName: string; field: string; flex: number; }[];
  Permission = Permission;

  constructor(
    private expenseService: ExpenseServices,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {

    this.columnDefs = [
      {
        headerName: 'Expence Account',
        field: 'accountName',
        flex: this.viewPortSize ? 1 : 0,
      },
      { headerName: 'Reference', field: 'reference', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Vendor Name', field: 'vendorName', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Paid Through', field: 'paidThrough', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Customer Name', field: 'customerName', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Status',
        field: 'status',
        flex: this.viewPortSize ? 1 : 0,
      },
      { headerName: 'Amount', field: 'amount', flex: this.viewPortSize ? 1 : 0 },
    ];
  }

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  ngOnInit() {
    this.fetchExpensesData();

    // this.searchSubject.pipe(debounceTime(600), distinctUntilChanged()).subscribe((searchTerm) => {
    //     this.fetchExpensesData(searchTerm);
    // });

    this.checkViewportSize();
  }



  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(UploadExpenseDialog);
  }

  private fetchExpensesData() {
    this.loading = true;
    this.expenseService.fetchExpenses().subscribe({
      next: (expenses: any) => {
        let inProgressCount = 0;

        // Sort the designations by 'id' in descending order so the newest branch comes first
        this.rowData = expenses
          .map((expense: any) => {
            const projectsInProgress = Array.isArray(expense?.projects)
              ? expense.projects.filter(
                (project: any) => project.status === 'In-Progress'
              )
              : [];

            inProgressCount += projectsInProgress.length;

            return {
              accountName:
                expense?.expenseItems?.length > 0
                  ? expense?.expenseItems
                    ?.map((el: any) => el?.chartOfAccountData?.accountName)
                    .join(', ')
                  : expense?.accountData?.accountName || '--',
              reference: expense?.invoiceNo || '--',
              vendorName: expense?.vendorData?.displayName || '--',
              paidThrough: expense?.paidThroughData?.accountName || '--',
              customerName: expense?.customerData?.displayName || '--',
              id: expense?.id || '--',
              amount: expense?.expenseItems?.length
                ? expense?.expenseItems?.reduce(
                  (sum: any, item: any) => sum + item.amount,
                  0
                )
                : expense?.amount || '--',
              status: expense?.expenseStatus || '--',
            };
          })
          .sort((a: any, b: any) => b.id - a.id);
        // Sort by ID in descending order

        // Update pagination options based on the rowData length
        this.paginationPageSizeSelector =
          PaginationHelper.getPaginationPageSizeOptions(this.rowData);

        this.loading = false;
      },
      error: (error: any) => {
        this.error = error;
        this.loading = false;
      },
    });
  }

  onCellClicked(params: any) {
    // const billId = params.data?.id;
    this.router.navigate([`/expense/expense-details/${params.data?.id}`]);
  }
}

@Component({
  selector: 'upload-expense-dialog',
  templateUrl: 'upload-expense-dialog.html',
  styleUrls: ['./expense.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadExpenseDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}
