
    <section class="cmn-innerpage-wrapper chart-accounts">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" />
            </div>
            <button class="cmn-add-btn" (click)="navigateTo('chart-of-accounts/new-chart-of-accounts')">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon"/> New Account
            </button>
        </div>
        <div class="sub-head">
            <ul>
                <li class="main">All Accounts</li>
                <li class="edit" (click)="onEdit()"><img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="Edit"> Edit</li>
            </ul>
        </div>
        <div class="chart-accounts-tab-wrapper">
            <div class="tab-left" style="overflow-y: scroll;">
                <mat-tab-group animationDuration="0ms" [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)="onTabChange($event)">
                    <mat-tab label="Profile" *ngFor="let account of chartAccounts; let i = index">
                        <ng-template mat-tab-label>
                            <h2>{{ account?.accountName }}</h2>
                            <h3>{{ account?.accountType }}</h3>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        
            <div class="chart-main-content-wrapper">
                <div *ngIf="accountData">
                    <div class="chart-tab-content">
                        <h4>AED {{ accountData?.amount || 0 }} <span>Closing Balance</span></h4>
                        <p>Description: {{ accountData?.description || '--' }}</p>
                        <h5>Recent Transactions</h5>
                        <table *ngIf="accountData?.JournalEnteries?.length > 0 ; else noData">
                            <tr>
                                <th>Date</th>
                                <th>Transaction Details</th>
                                <th>Type</th>
                                <th>Debit</th>
                                <th>Credit</th>
                            </tr>
                            <tr *ngFor="let account of accountData.JournalEnteries">
                                <td>{{ account.createdAt | date: 'dd/MM/YYYY' || '--' }}</td>
                                <td>{{ account.description || '--' }}</td>
                                <td>{{ accountData.accountType || '--'  }}</td>
                                <td>{{ account.debit ? ('AED ' + account.debit) : '--' }}</td>
                                <td>{{ account.credit ? ('AED ' + account.credit) : '--' }}</td>
                            </tr>
                        </table>
                        <ng-template #noData>
                            <div class="no-data-message">
                                No data found.
                              </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="manual-journal-details-tab-wrapper">
            <div class="tab-left">
                <div class="tab-box-main-wrapper">
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 0" (click)="selectTab(0)">
                        <div class="inn-box">
                            <h2>Petty Cash</h2>
                            <h3>Cash</h3>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 1" (click)="selectTab(1)">
                        <div class="inn-box">
                            <h2>Petty Cash</h2>
                            <h3>Cash</h3>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 0" (click)="selectTab(0)">
                        <div class="inn-box">
                            <h2>Petty Cash</h2>
                            <h3>Cash</h3>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 0" (click)="selectTab(0)">
                        <div class="inn-box">
                            <h2>Petty Cash</h2>
                            <h3>Cash</h3>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 0" (click)="selectTab(0)">
                        <div class="inn-box">
                            <h2>Petty Cash</h2>
                            <h3>Cash</h3>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 0" (click)="selectTab(0)">
                        <div class="inn-box">
                            <h2>Petty Cash</h2>
                            <h3>Cash</h3>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 0" (click)="selectTab(0)">
                        <div class="inn-box">
                            <h2>Petty Cash</h2>
                            <h3>Cash</h3>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 0" (click)="selectTab(0)">
                        <div class="inn-box">
                            <h2>Petty Cash</h2>
                            <h3>Cash</h3>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 0" (click)="selectTab(0)">
                        <div class="inn-box">
                            <h2>Petty Cash</h2>
                            <h3>Cash</h3>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 0" (click)="selectTab(0)">
                        <div class="inn-box">
                            <h2>Petty Cash</h2>
                            <h3>Cash</h3>
                        </div>
                    </button>

                </div>
            </div>
            <div class="manual-journal-details-content-wrapper">
                <div *ngIf="selectedTabIndex === 0">
                    <div class="chart-tab-content">
                        <table>
                            <tr>
                                <th>Date</th>
                                <th>Transaction Details</th>
                                <th>Type</th>
                                <th>Debit</th>
                                <th>Credit</th>
                            </tr>
                            <tr>
                                <td>14/08/2024</td>
                                <td>Dans Design </td>
                                <td>Customer Payment</td>
                                <td>AED 1,210</td>
                                <td>AED 950</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 1">
                    <div class="chart-tab-content">
                        content 2
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 2">
                    <div class="chart-tab-content">
                        content 3
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 3">
                    <div class="chart-tab-content">
                        content 4
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 4">
                    <div class="chart-tab-content">
                        content 5
                    </div>
                </div>
            </div>
        </div> -->
    </section>
