import { Component, HostListener, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ManualJournalService } from '../../features/manual-journals/services/manual-journal.service';
import { JournalStatusEnum } from '../../../assets/journalStatus';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';
import * as selectPermissions from '../../core/permissions/permission.selector';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-manual-journals',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatInputModule, AgGridAngular, PermissionDirective],
  templateUrl: './manual-journals.component.html',
  styleUrls: ['./manual-journals.component.scss'],
})
export class ManualJournalsComponent implements OnInit {
  public viewPortSize: boolean = false;
  public rowData: any[] | null = null;
  public loading: boolean = true;
  public error: any = null;
  private searchSubject = new Subject<string>();
  Permission = Permission;

  readonly dialog = inject(MatDialog);

  public journalStatuses = Object.values(JournalStatusEnum); // Get all journal statuses from the enum
  public selectedStatuses: JournalStatusEnum[] = []; // Track selected statuses
  columnDefs: ({ headerName: string; field: string; flex: number; cellRenderer?: undefined; floatingFilter?: undefined; filter?: undefined; } | { field: string; headerName: string; flex: number; cellRenderer: (params: any) => HTMLDivElement; floatingFilter: boolean; filter: boolean; })[];
  // profileName: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {
    this.columnDefs = [
      { headerName: 'Date', field: 'date', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Journal Number', field: 'journalNo', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Currency Code', field: 'currencyCode', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Amount', field: 'amount', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Status', field: 'status', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Notes', field: 'notes', flex: this.viewPortSize ? 1 : 0 },
      {
        field: 'action',
        headerName: 'Actions',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.alignItems = 'center';
          container.style.gap = '20px';

          const editIcon = document.createElement('img');
          editIcon.src = 'assets/images/icons/icon-edit.svg';
          editIcon.style.cursor = 'pointer';
          editIcon.setAttribute('data-action', 'edit');

          const deleteIcon = document.createElement('img');
          deleteIcon.src = 'assets/images/icons/icon-delete.svg';
          deleteIcon.style.cursor = 'pointer';
          deleteIcon.setAttribute('data-action', 'delete');
          // container.appendChild(editIcon);
          // if(this.profileName === 'admin'){
          // container.appendChild(deleteIcon);
          // }
          this.store.select(selectPermissions.hasPermission('31', Permission.Edit)).subscribe((canEdit) => {
            if (canEdit) {
              container.appendChild(editIcon);            }
          });
        this.store.select(selectPermissions.hasPermission('31', Permission.Delete)).subscribe((canDelete) => {
            if (canDelete) {
              container.appendChild(deleteIcon);            }
          });
          return container;
        },
        floatingFilter: false,
        filter: false,
      },
    ];
  }

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };

  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10];
  public themeClass: string = 'ag-theme-quartz';

  constructor(
    private router: Router,
    private journalService: ManualJournalService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private store: Store,
  ) { 
    // const loggedInUser: any = localStorage.getItem('loggedInUser');
    // const userData = JSON.parse(loggedInUser);
    // this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit(): void {
    this.fetchJournalData();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchJournalData(searchTerm);
      });

    this.checkViewportSize();
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  applyFilter(event: Event) {
    event.preventDefault();

    this.fetchJournalData();
    this.toggleDropdown();
  }

  clearFilter() {
    this.selectedStatuses = [];
    this.fetchJournalData();
  }

  onStatusChange(status: JournalStatusEnum, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;

    if (isChecked) {
      this.selectedStatuses.push(status);
    } else {
      this.selectedStatuses = this.selectedStatuses.filter((s) => s !== status);
    }
  }

  private fetchJournalData(search: string = '', filter: any = {}) {
    this.loading = true;

    const filterCriteria = {
      ...filter,
      status: this.selectedStatuses.length
        ? this.selectedStatuses[0]
        : undefined,
    };

    this.journalService.fetchManualJournals(search, filterCriteria).subscribe({
      next: (journals) => {
        this.rowData = journals.map((journal: any) => {
          return {
            id: journal?.id,
            date: this.datePipe.transform(journal?.date, 'dd/MM/yyyy'),
            journalNo: journal?.journalNo,
            amount: Number(this.findSum(journal?.journalEnteries)).toFixed(2),
            status: journal?.status,
            currencyCode: journal?.currencyData?.currencyCode,
            notes: journal?.notes ? (journal.notes.length > 20 ? journal.notes.substring(0, 20) + '...' : journal.notes) : '',
          };
        });

        // Update pagination options based on the rowData length
        if (this.rowData) {
          this.paginationPageSizeSelector = PaginationHelper.getPaginationPageSizeOptions(this.rowData);
        } else {
          this.paginationPageSizeSelector = [];
        }

        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching journals:', err);
        this.loading = false;
      },
    });
  }

  findSum(journalEnteries: any[]) {
    return journalEnteries.reduce((total, entry) => {
      return total + entry?.debit;
    }, 0);
  }

  onCellClicked(params: any) {
    const jounalId = params.data?.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.openEdit(params?.data);
      } else if (action === 'delete') {
        this.onDeleteJournal(params?.data);
      }
    } else {
      const jounalId = params.data?.id;
      this.router.navigate([`manual-journals/detail/${jounalId}`]);
    }
  }

  openEdit(journalData: any) {
    const journalId = journalData?.id;
    if (journalId) {
      this.router.navigate([`/manual-journals/edit/${journalId}`]);
    }
  }


  onDeleteJournal(journalData: any): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.removeJournal(journalData?.id);
      }
    });
  }

  private removeJournal(jounalId: number): void {
    this.journalService.removeJournal(jounalId).subscribe({
      next: () => {
        this.toastr.success('Journal removed successfully.');
        this.fetchJournalData();
      },
      error: () => {
        this.toastr.error(
          'Failed to remove journal. Please try again.',
          'Error'
        );
      },
    });
  }

  // onRowClicked(event: any) {
  //   const journalId = event.data.id;
  //   const rowIndex = event.rowIndex;
  //   this.router.navigate([`manual-journals/detail/${journalId}`]);
  // }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
