import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  PAYMENT_MODES,
  PAYMENT_TERMS,
  PaymentTypesEnum,
} from '../../helpers/helper-file';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { addDays, addMonths, endOfMonth } from 'date-fns';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { EmployeeService } from '../../features/employees/services/employee.service';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { nonNegativeValidator } from '../../shared/services/validations';
import { UploadFileService } from '../../shared/services/file-upload.service';
import { CreateClientDialog } from './dialog-components/create-customer-dialog/create-client-dialog.component';
import { BillingAddressDialog } from './dialog-components/add-billing-address-dialog/billing-address-dialog.component';
import { ShippingAddressDialog } from './dialog-components/add-shipping-address-dialog/shipping-address-dialog.component';
import { ListBillingAddressDialog } from './dialog-components/list-billing-address-dialog/list-billing-address-dialog.component';
import { ListShippingAddressDialog } from './dialog-components/list-shipping-address-dialog/list-shipping-address-dialog.component';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { PreferenceService } from '../../shared/services/preference.service';

@Component({
  selector: 'app-invoice-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule,
  ],
  templateUrl: './invoice-new.component.html',
  styleUrl: './invoice-new.component.scss',
})
export class InvoiceNewComponent implements OnInit {
  public arr = [1, 2, 3, 4, 5, 6, 7];
  filter: {} = {};
  search: string = '';
  loading: boolean = false;
  invoiceForm: FormGroup;
  invoiceData: any;
  invoiceId: number | null = null;
  public partnersData: any[] = [];
  public projectData: any[] = [];
  public itemData: any[] = [];
  public employeesData: any[] = [];
  public depositAccountData: any[] = [];
  readonly dialog = inject(MatDialog);
  paymentModes = PAYMENT_MODES;
  paymentTermsValue = PAYMENT_TERMS;
  isManualDateChange = true;
  private searchSubject = new Subject<string>();
  public selectedBillingAddress: any = {};
  public selectedShippingAddress: any = {};
  showIsPaymentCheckbox = false;
  showPaymentTable = false;
  public billingAddressList: any[];
  public shippingAddressList: any[];
  selectedCustomerId: any;

  public filteredItemData: any[] = [];

  file: any = null;
  csvFile: File[] = [];
  url: string = '';
  fileDetailsArray: {
    fileName: string;
    fileUrl: string;
    id?: number | null;
    // isNew: boolean;
  }[] = [];

  @ViewChild('fileInput') fileInput!: ElementRef;
  currentFiles: File[] = [];

  private status: 'DRAFT' | 'SENT' = 'SENT';
  prefix: any;
  invoiceNo: any;

  constructor(
    private invoiceService: InvoiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private itemService: InventoryItemService,
    private documentUploadService: UploadFileService,
    private preferenceService: PreferenceService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.preferenceCode();
    this.fetchPartners();
    this.fetchAccountsDeposit();
    this.fetchItems();

    this.invoiceForm.get('partnerId')?.valueChanges.subscribe((partnerId) => {
      this.setAddresses(partnerId);
    });

    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.invoiceId = +id;
        this.loadInvoiceData();
      }
    });

    if (!this.invoiceId) {
      this.addItem();
      // this.addPayment();
    }

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchPartners(searchTerm);
      });

    this.invoiceForm.get('paymentTerm')?.valueChanges.subscribe((term) => {
      this.updateDueDate();
    });

    this.invoiceForm.get('invoiceDate')?.valueChanges.subscribe(() => {
      this.updateDueDate();
    });

    this.invoiceForm.get('dueDate')?.valueChanges.subscribe((selectedDate) => {
      if (this.isManualDateChange && selectedDate) {
        this.invoiceForm
          .get('paymentTerm')
          ?.setValue('Custom', { emitEvent: false });
      }
      this.isManualDateChange = true;
    });

    this.invoiceForm
      .get('dueDate')
      ?.setValidators([
        Validators.required,
        this.dueDateBeforeInvoiceDateValidator.bind(this),
      ]);

    this.items.valueChanges.subscribe(() => {
      this.getTotalAmount();
    });
  }

  initForm(): void {
    this.invoiceForm = this.fb.group({
      id: [this.invoiceData?.id || ''],
      partnerId: [this.invoiceData?.partnerId, Validators.required],
      shippingAddressId: this.selectedShippingAddress.id,
      billingAddressId: this.selectedBillingAddress.id,
      projectModelId: [this.invoiceData?.projectModelId],
      invoiceNo: [this.invoiceData?.invoiceNo || this.prefix, Validators.required],
      orderNo: [this.invoiceData?.orderNo],
      invoiceDate: [
        this.invoiceData?.invoiceDate || new Date(),
        Validators.required,
      ],
      paymentTerm: [this.invoiceData?.paymentTerm, Validators.required],
      dueDate: [this.invoiceData?.dueDate, Validators.required],
      subject: [''],
      items: this.fb.array([]),
      payments: this.fb.array([]),
      invoiceAttachments: this.fb.array([]),
      customerNote: [''],
      isPayment: [false],
    });
  }

  preferenceCode() {
    this.preferenceService.preferenceCodes('', 'Invoice').subscribe({
      next: (response) => {
        response.forEach((prefix: any) => {
          this.invoiceNo = prefix?.prefix;
        })
        const randomSuffix = Math.floor(1000 + Math.random() * 9000);
        this.prefix = `${this.invoiceNo + randomSuffix}`;
        this.invoiceForm.patchValue({ invoiceNo: this.prefix });
      },
      error: (error) => {
        console.error(error, 'Error');
      },
    });
  }

  dueDateBeforeInvoiceDateValidator(control: any) {
    const invoiceDate = this.invoiceForm.get('invoiceDate')?.value;
    const dueDate = control.value;

    if (invoiceDate && dueDate) {
      if (new Date(dueDate) < new Date(invoiceDate)) {
        return { dueDateBeforeInvoiceDate: true };
      }
    }
    return null;
  }

  updateDueDate(): void {
    const term = this.invoiceForm.get('paymentTerm')?.value;
    const invoiceDate = this.invoiceForm.get('invoiceDate')?.value
      ? new Date(this.invoiceForm.get('invoiceDate')?.value)
      : new Date();

    let dueDate: Date | null = null;

    switch (term) {
      case 'Net 15':
        dueDate = addDays(invoiceDate, 15);
        break;
      case 'Net 30':
        dueDate = addDays(invoiceDate, 30);
        break;
      case 'Net 45':
        dueDate = addDays(invoiceDate, 45);
        break;
      case 'Net 60':
        dueDate = addDays(invoiceDate, 60);
        break;
      case 'Due On Receipt':
        dueDate = invoiceDate;
        break;
      case 'Due end of the month':
        dueDate = endOfMonth(invoiceDate);
        break;
      case 'Due end of next month':
        dueDate = endOfMonth(addMonths(invoiceDate, 1));
        break;
      case 'Custom':
        dueDate = null;
        break;
      default:
        dueDate = null;
    }

    if (dueDate) {
      this.isManualDateChange = false;
      this.invoiceForm.get('dueDate')?.setValue(dueDate);
    }
  }

  loadInvoiceData(): void {
    if (this.invoiceId !== null) {
      this.invoiceService.fetchInvoiceById(this.invoiceId).subscribe({
        next: (data) => {
          this.invoiceData = data;
          this.invoiceForm.patchValue({

            partnerId: this.invoiceData.partnerData.id,
            shippingAddressId: this.invoiceData.shippingAddressId,
            billingAddressId: this.invoiceData.billingAddressId,
            projectModelId: this.invoiceData.projectModelId,
            invoiceNo: this.invoiceData.invoiceNo,
            orderNo: this.invoiceData.orderNo,
            invoiceDate: this.invoiceData.invoiceDate
              ? new Date(Number(this.invoiceData.invoiceDate))
              : null,
            paymentTerm: this.invoiceData.paymentTerm,
            dueDate: this.invoiceData.dueDate
              ? new Date(Number(this.invoiceData.dueDate))
              : null,
            subject: this.invoiceData.subject,
            customerNote: this.invoiceData.customerNote,
            isPayment: this.invoiceData.isPayment,

          });

          this.setAddresses(this.invoiceData.partnerId);
          this.selectedCustomerId = this.invoiceData.partnerData.id;

          if (data.invoiceItems) {
            data.invoiceItems.forEach((item: any) => {
              const itemForm = this.fb.group({
                id: [item.id || null],
                itemId: [item.itemId, Validators.required],
                itemName: [item.itemData.itemName],
                isManual: [item.isManual],
                quantity: [item.quantity, Validators.required],
                rate: [item.rate, Validators.required],
                discount: [item.discount, Validators.pattern(/^\d+(\.\d+)?$/)],
                amount: [
                  item.amount,
                  [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)],
                ],
              });

              this.items.push(itemForm);
              this.calculateAmount(itemForm);

              // Handle dynamic validation based on `isManual`
              itemForm.get('isManual')?.valueChanges.subscribe((isManual) => {
                const itemIdControl = itemForm.get('itemId');
                if (isManual) {
                  itemIdControl?.clearValidators();
                  itemIdControl?.setValue(null);
                } else {
                  itemIdControl?.setValidators(Validators.required);
                }
                itemIdControl?.updateValueAndValidity();
              });

              // Sync rate and quantity when itemId changes
              itemForm
                .get('itemId')
                ?.valueChanges.subscribe(() => this.calculateAmount(itemForm));
              itemForm
                .get('rate')
                ?.valueChanges.subscribe(() => this.calculateAmount(itemForm));
              itemForm
                .get('quantity')
                ?.valueChanges.subscribe(() => this.calculateAmount(itemForm));
              itemForm
                .get('discount')
                ?.valueChanges.subscribe(() => this.calculateAmount(itemForm));
            });
          }

          if (data.invoiceAttachments) {
            data.invoiceAttachments.forEach((attachment: any) => {
              this.fileDetailsArray.push({
                id: attachment.id,
                fileName: attachment.fileName,
                fileUrl: attachment.fileUrl,
              });
            });
          }

          if (this.invoiceData.payments) {
            this.invoiceData.payments.forEach((payment: any) => {
              this.addPayment();
              const paymentGroup = this.payments.at(this.payments.length - 1);
              paymentGroup.patchValue({
                paymentMode: payment.paymentMode,
                depositId: payment.depositId,
                amount: payment.amount,
              });
            });
          }
        },
        error: (error) => console.error(error),
      });
    }
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  onItemSearch(event: Event): void {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;

    if (searchTerm) {
      this.fetchItems(searchTerm);
    } else {
      this.filteredItemData = this.itemData;
    }
  }

  onSelectItem(event: MatAutocompleteSelectedEvent): void {
    const selectedItem = this.itemData.find(
      (item) => item.id === event.option.value
    );
    if (selectedItem) {
      const itemGroup = this.items.at(this.items.length - 1);
      itemGroup.patchValue({
        itemId: selectedItem.id,
        itemName: selectedItem.itemName,
        isManual: false,
      });
    }
  }

  addItem(): void {
    const itemGroup = this.fb.group({
      itemId: [null],
      itemName: ['', Validators.required], // Add itemName field
      isManual: [true], // Mark as manual by default
      quantity: [
        '1',
        [
          Validators.required,
          Validators.pattern(/^\d+(\.\d+)?$/),
          nonNegativeValidator,
        ],
      ],
      rate: ['0', [Validators.required, nonNegativeValidator]],
      discount: [
        0,
        [
          Validators.pattern(/^\d+(\.\d{1,2})?$/), // Allows decimal with up to 2 digits
          nonNegativeValidator,
          Validators.max(100),
        ],
      ],
      amount: [0, [Validators.required, nonNegativeValidator]],
    });

    this.items.push(itemGroup);

    itemGroup.get('isManual')?.valueChanges.subscribe((isManual) => {
      const itemIdControl = itemGroup.get('itemId');
      if (isManual) {
        itemIdControl?.clearValidators();
        itemIdControl?.setValue(null); // Set itemId to null when manual
      } else {
        itemIdControl?.setValidators(Validators.required); // Set back to required when not manual
      }
      itemIdControl?.updateValueAndValidity();
    });

    itemGroup.get('itemId')?.valueChanges.subscribe((itemId) => {
      const selectedItem = this.itemData.find((item) => item.id === itemId);
      if (selectedItem) {
        itemGroup.patchValue({
          rate: selectedItem.sellingPrice.toString(),
          quantity: '1',
        });
        this.calculateAmount(itemGroup);
      }
      itemGroup.get('itemId')?.updateValueAndValidity();
    });

    // Trigger validation checks after adding the group
    itemGroup.updateValueAndValidity();
    this.items.updateValueAndValidity();

    // Recalculate amount on rate, quantity, or discount changes
    itemGroup
      .get('rate')
      ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
    itemGroup
      .get('quantity')
      ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
    itemGroup
      .get('discount')
      ?.valueChanges.subscribe(() => this.calculateAmount(itemGroup));
  }

  // onCustomerSelected(event: MatSelectChange) {
  //   const selectedCustomerId = event.value;
  //   this.selectedCustomerId = selectedCustomerId;
  // }

  onCustomerSelected(event: MatSelectChange): void {
    const selectedCustomerId = event.value;
    this.selectedCustomerId = event.value;
    const selectedPartner = this.partnersData.find(
      (partner) => partner.id === selectedCustomerId
    );

    if (selectedPartner && selectedPartner.partnerProjects) {
      this.projectData = selectedPartner.partnerProjects.map(
        (project: any) => ({
          id: project.id,
          projectName: project.projectName,
          projectCode: project.projectCode,
          totalProjectCost: project.totalProjectCost,
          description: project.description,
        })
      );
    } else {
      this.projectData = [];
    }
  }

  onPaymentTermChange(term: string): void {
    const invoiceDate =
      this.invoiceForm.get('invoiceDate')?.value || new Date();
    let dueDate: Date | null = null;

    switch (term) {
      case 'Net 15':
        dueDate = addDays(invoiceDate, 15);
        break;
      case 'Net 30':
        dueDate = addDays(invoiceDate, 30);
        break;
      case 'Net 45':
        dueDate = addDays(invoiceDate, 45);
        break;
      case 'Net 60':
        dueDate = addDays(invoiceDate, 60);
        break;
      case 'Due On Receipt':
        dueDate = invoiceDate;
        break;
      case 'Due end of the month':
        dueDate = endOfMonth(invoiceDate);
        break;
      case 'Due end of next month':
        dueDate = endOfMonth(addMonths(invoiceDate, 1));
        break;
      case 'Custom':
        dueDate = null;
        break;
      default:
        dueDate = null;
    }

    if (dueDate) {
      this.isManualDateChange = false;
      this.invoiceForm.get('dueDate')?.setValue(dueDate);
    }
  }

  get items(): FormArray {
    return this.invoiceForm.get('items') as FormArray;
  }

  get invoiceAttachments(): FormArray {
    return this.invoiceForm.get('invoiceAttachments') as FormArray;
  }

  get payments(): FormArray {
    return this.invoiceForm.get('payments') as FormArray;
  }

  getTotalAmount(): number {
    return this.items.controls.reduce((total, itemGroup) => {
      return total + (itemGroup.get('amount')?.value || 0);
    }, 0);
  }

  calculateAmount(itemGroup: FormGroup): void {
    const rate = parseFloat(itemGroup.get('rate')?.value || '0');
    const quantity = parseFloat(itemGroup.get('quantity')?.value || '0');
    const discount = itemGroup.get('discount')?.value || 0;
    let amount = rate * quantity;
    if (discount > 0) {
      amount -= (amount * discount) / 100;
    }
    itemGroup.patchValue({ amount });
  }

  addPayment(): void {
    const paymentData = this.fb.group({
      paymentMode: [''],
      depositId: [''],
      amount: [0, Validators.pattern(/^\d+(\.\d+)?$/)],
    });
    this.payments.push(paymentData);
  }

  setAddresses(partnerId: number): void {
    const selectedPartner = this.partnersData.find((p) => p.id === partnerId);

    if (selectedPartner) {
      if (
        Array.isArray(selectedPartner.addresses) &&
        selectedPartner.addresses.length > 0
      ) {
        this.selectedBillingAddress =
          selectedPartner.addresses.find(
            (addr: any) => addr.addressType === 'BILLING'
          ) || null;
        this.selectedShippingAddress =
          selectedPartner.addresses.find(
            (addr: any) => addr.addressType === 'SHIPPING'
          ) || null;

        this.invoiceForm.patchValue({
          billingAddressId: this.selectedBillingAddress?.id || null,
          shippingAddressId: this.selectedShippingAddress?.id || null,
        });
      } else {
        console.error(
          `No addresses found or addresses is not an array for partnerId: ${partnerId}`
        );
      }
    } else {
      console.error(`Partner with ID ${partnerId} not found in partnersData.`);
    }
  }

  getUpdateInvoiceItemInput(): any[] {
    return this.items.controls.map((itemGroup) => {
      const id = itemGroup.get('id')?.value;
      const itemId = itemGroup.get('itemId')?.value;
      const isManual = itemGroup.get('isManual')?.value;
      const name = itemGroup.get('itemName')?.value;
      const quantity = itemGroup.get('quantity')?.value;
      const rate = itemGroup.get('rate')?.value;
      const discount = itemGroup.get('discount')?.value;
      const amount = itemGroup.get('amount')?.value;

      const existingInvoiceItem = this.invoiceData?.invoiceItems?.find(
        (invoiceItem: any) => invoiceItem.itemId === itemId
      );

      return {
        id: existingInvoiceItem ? existingInvoiceItem.id : null, // Existing item gets id; new item gets null
        itemId: itemId || null,
        name: name || (existingInvoiceItem ? existingInvoiceItem.name : ''),
        isManual: !!isManual,
        quantity,
        rate,
        discount,
        amount,
      };
    });
  }

  saveAsDraft(): void {
    this.status = 'DRAFT';
    this.createInvoice('invoice/invoice-details');
  }

  saveAndSend(): void {
    this.status = 'DRAFT';
    this.createInvoice('invoice/invoice-details/mail');
  }

  createInvoice(navigateTo: string): void {
    if (this.invoiceForm.invalid) {
      this.invoiceForm.markAllAsTouched();
      this.toastr.warning('Please fill in all required fields.');
      return;
    } else {
      const formValues = this.invoiceForm.value;

      const createInvoiceInput = {
        partnerId: formValues.partnerId,
        billingAddressId: formValues.billingAddressId,
        shippingAddressId: formValues.shippingAddressId,
        projectModelId: formValues.projectModelId,
        invoiceNo: formValues.invoiceNo,
        orderNo: formValues.orderNo,
        invoiceDate: formValues.invoiceDate,
        dueDate: formValues.dueDate,
        paymentTerm: formValues.paymentTerm,
        subject: formValues.subject,
        customerNote: formValues.customerNote,
        isPayment: formValues.isPayment,
      };

      const createInvoiceItemInput = this.invoiceForm.value.items.map(
        (item: any) => {
          if (item.isManual) {
            return {
              itemId: null,
              name: item.itemName,
              isManual: true,
              quantity: item.quantity,
              rate: item.rate,
              discount: item.discount,
              amount: item.amount,
            };
          } else {
            return {
              itemId: item.itemId,
              isManual: false,
              quantity: item.quantity,
              rate: item.rate,
              discount: item.discount,
              amount: item.amount,
            };
          }
        }
      );

      const createInvoiceAttachmentInput = this.fileDetailsArray
        .filter((file) => !file.id)
        .map((file) => ({
          // id: null,
          fileName: file.fileName,
          fileUrl: file.fileUrl,
        }));

      const updateInvoiceAttachmentInput = this.fileDetailsArray.map(
        (file) => ({
          id: file.id || null,
          fileName: file.fileName,
          fileUrl: file.fileUrl,
        })
      );

      console.log(
        'createInvoiceAttachmentInput:',
        createInvoiceAttachmentInput
      );
      console.log(
        'updateInvoiceAttachmentInput:',
        updateInvoiceAttachmentInput
      );

      const updateInvoiceInput = {
        id: this.invoiceId,
        ...createInvoiceInput,
      };

      const updateInvoiceItemInput = this.getUpdateInvoiceItemInput();
      this.loading = true;
      if (this.invoiceId) {
        this.invoiceService
          .updateInvoice(
            updateInvoiceInput,
            updateInvoiceItemInput,
            updateInvoiceAttachmentInput
          )
          .subscribe({
            next: (response) => {
              this.loading = false;
              this.toastr.success('Invoice updated successfully.');
              const invoiceId = response?.data?.updateInvoice?.id;
              if (invoiceId) {
                this.invoiceService
                  .generateInvoicePdf(invoiceId)
                  .subscribe({
                    next: (pdfResponse) => {
                      console.log('PDF generated successfully', pdfResponse);
                      // this.router.navigate([
                      //   `${navigateTo}/${invoiceId}`,
                      // ]);
                      this.router.navigate([`${navigateTo}`], {
                        queryParams: { invoiceId },
                      });
                    },
                    error: (pdfError) => {
                      console.error('Error generating PDF:', pdfError);
                      this.toastr.error(
                        pdfError.message || 'Failed to generate PDF.'
                      );
                    },
                  });
                // this.router.navigate([`${navigateTo}/${invoiceId}`]);
              } else {
                console.error('Invoice ID is missing');
              }
            },
            error: (error) => {
              this.loading = false;
              console.error('Error updating invoice:', error);
              this.toastr.error(error.message || 'Failed to update invoice.');
            },
          });
      } else {
        this.invoiceService
          .createInvoice(
            createInvoiceInput,
            createInvoiceItemInput,
            createInvoiceAttachmentInput
          )
          .subscribe({
            next: (response) => {
              this.toastr.success('Invoice created successfully');
              // this.router.navigate([navigateTo]);
              const invoiceId = response?.data?.createInvoice?.id;
              if (invoiceId) {
                this.invoiceService
                  .generateInvoicePdf(invoiceId)
                  .subscribe({
                    next: (pdfResponse) => {
                      console.log('PDF generated successfully', pdfResponse);
                      // this.router.navigate([
                      //   `${navigateTo}/${invoiceId}`,
                      // ]);
                      this.router.navigate([`${navigateTo}`], {
                        queryParams: { invoiceId },
                      });
                    },
                    error: (pdfError) => {
                      console.error('Error generating PDF:', pdfError);
                      this.toastr.error(
                        pdfError.message || 'Failed to generate PDF.'
                      );
                    },
                  });
              } else {
                console.error('Invoice ID is missing');
              }
              this.loading = false;
            },
            error: (error) => {
              this.toastr.error(error.message || 'Failed to create invoice.');
              this.loading = false;
            },
          });
      }
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const newFiles = Array.from(input.files);

      if (this.fileDetailsArray.length + newFiles.length > 10) {
        this.toastr.warning('You can only upload a maximum of 10 files.');
        return;
      }

      newFiles.forEach((newFile) => {
        const allowedExtensions = ['pdf', 'doc', 'jpg', 'jpeg', 'png'];
        const fileExtension = newFile.name.split('.').pop()?.toLowerCase();

        if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
          this.toastr.warning('Only PDF, DOC, and image files are allowed.');
          return;
        }

        // Prevent duplicates based on name and size
        if (
          !this.fileDetailsArray.some((file) => file.fileName === newFile.name)
        ) {
          this.fileDetailsArray.push({
            id: null,
            fileName: newFile.name,
            fileUrl: '',
          });
        }
      });

      this.uploadDocuments(newFiles);
    }
  }

  uploadDocuments(files: File[]): void {
    files.forEach((file: File) => {
      this.documentUploadService.uploadDocument(file, 'items').subscribe({
        next: (response) => {
          const uploadedFile = this.fileDetailsArray.find(
            (f) => f.fileName === file.name
          );
          if (uploadedFile) {
            uploadedFile.fileUrl = response.url;
          }
        },
        error: (error) => {
          this.toastr.error(error, 'File upload failed');
        },
      });
    });
    this.fileInput.nativeElement.value = '';
  }

  removeFile(index: number): void {
    const file = this.fileDetailsArray[index];

    if (file.id) {
      this.onRemoveitemAttachment(file.id);
    } else {
      // New file - simply remove from array
      this.fileDetailsArray.splice(index, 1);
    }
  }

  onCancel(): void {
    this.invoiceForm.reset();
    this.router.navigate(['/invoice']);
  }

  onRemoveitemAttachment(invoiceAttachmentId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((response) => {
      if (response === true) {
        this.invoiceService
          .removeInvoiceAttachmentById(invoiceAttachmentId)
          .subscribe({
            next: () => {
              this.fileDetailsArray = this.fileDetailsArray.filter(
                (file) => file.id !== invoiceAttachmentId
              );
            },
            error: () => {
              this.toastr.error('Failed to remove invoice attachment.');
            },
          });
      }
    });
  }

  onRemoveInvoiceItem(invoiceItemId: number, index: number): void {
    if (invoiceItemId) {
      const dialogRef = this.dialog.open(ConfirmDialog);
      dialogRef.afterClosed().subscribe((response) => {
        if (response === true) {
          this.removeItem(invoiceItemId, index);
        }
      });
    } else {
      console.error('Item ID is null or undefined');
      this.items.removeAt(index);
    }
  }

  private removeItem(invoiceItemId: number, index: number): void {
    this.invoiceService.removeInvoiceItemById(invoiceItemId).subscribe({
      next: () => {
        console.log('Invoice item removed successfully');
        this.items.removeAt(index);
      },
      error: () => {
        console.log('Failed to remove invoice item.');
      },
    });
  }

  private fetchPartners(search: string = '') {
    this.invoiceService.fetchClients(search).subscribe({
      next: (partners) => {
        this.partnersData = partners
          .filter((data: any) => data.partnerType === 'Customer')
          .map((data: any) => ({
            id: data.id || '--',
            name: data.displayName || '--',
            addresses: data.addresses || [],
            partnerProjects: data.partnerProjects || [],
          }));
        this.billingAddressList = partners
          .flatMap((partner: any) => partner.addresses || [])
          .filter((address: any) => address.addressType === 'BILLING');
        this.shippingAddressList = partners
          .flatMap((partner: any) => partner.addresses || [])
          .filter((address: any) => address.addressType === 'SHIPPING');
      },
      error: (error) => console.error(error),
    });
  }

  private fetchItems(search: string = '', filter: any = {}) {
    const updatedFilter = {
      ...filter,
      isSalesActive: true,
    };

    this.itemService.fetchItems(search, updatedFilter).subscribe({
      next: (items) => {
        this.itemData = items;
        this.filteredItemData = items;
      },
      error: (error) => console.error(error),
    });
  }

  private fetchAccountsDeposit(search: string = '') {
    this.invoiceService.fetchAccountsToDeposit(search).subscribe({
      next: (accounts) => {
        this.depositAccountData = accounts;
      },
      error: (error) => console.error(error),
    });
  }

  openCreateClientDialog() {
    const dialogRef = this.dialog.open(CreateClientDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
      }
      this.fetchPartners();
    });
  }

  openBillingAddressDialog(type: any) {
    const selectedClientId = this.invoiceForm.get('partnerId')?.value;
    this.dialog.open(BillingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }

  openShippingAddressDialog(type: any) {
    const selectedClientId = this.invoiceForm.get('partnerId')?.value;
    this.dialog.open(ShippingAddressDialog, {
      width: '400px',
      data: {
        clientId: selectedClientId,
        type,
      },
    });
  }

  openSelectBillingAddressDialog() {
    const selectedCustomerId = this.selectedCustomerId;
    const type = 'BILLING';

    const dialogRef = this.dialog.open(ListBillingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });

    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        this.selectedBillingAddress = address;
        this.invoiceForm.patchValue({ billingAddressId: address.id });
      }
    });
  }

  openSelectShippingAddressDialog() {
    const selectedCustomerId = this.selectedCustomerId;
    const type = 'SHIPPING';

    const dialogRef = this.dialog.open(ListShippingAddressDialog, {
      data: {
        selectedCustomerId,
        type,
      },
    });
    dialogRef.afterClosed().subscribe((address) => {
      if (address) {
        console.log;
        this.selectedShippingAddress = address;
        this.invoiceForm.patchValue({ shippingAddressId: address.id });
      }
    });
  }
}
