import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { VacationService } from '../../../../features/vacation/services/vacation.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-vacation-history',
  standalone: true,
  imports: [
    CommonModule,
    AgGridAngular,
  ],
  templateUrl: './vacation-history.component.html',
  styleUrl: '../../vacation-approvals.component.scss',
  providers: []
})
export class VacationHistoryComponent {
  public rowData: any[] | null = null;
  vacationId: any;
  filter: {};
  employeeId: any;

  constructor(
    private vacationService: VacationService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.vacationId = id;
      this.fetchVacation(id);
    }
  }

  columnDefs = [
    {
      headerName: 'Start Date',
      field: 'startDate',
      flex: 1,
    },
    { headerName: 'End Date', field: 'endDate', flex: 1 },
    { headerName: 'Total Days', field: 'totalDays', flex: 1 },
    { headerName: 'Notes', field: 'comment', flex: 1 },
    {
      headerName: 'Approvers',
      flex: 1,
      cellRenderer: (params: any) => {
        const approver1 = params.data?.approver1 ?
          `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.approver1}</p>` : '';
        const approver2 = params.data?.approver2 ?
          `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.approver2}</p>` : '';
        const approver3 = params.data?.approver3 ?
          `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.approver3}</p>` : '';
        const approver4 = params.data?.approver4 ?
          `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.approver4}</p>` : '';

        return `
              <div>
                ${approver1}
                ${approver2}
                ${approver3}
                ${approver4}
              </div>
            `;
      },
    }
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';


  fetchVacation(id: any){
    this.vacationService.fetchVacationById(Number(id)).subscribe({
      next: (data) => {
        this.employeeId = data?.employeeId;
        this.fetchEmployeeVacations(this.employeeId);
      },
      error: (error) => {
        this.toastr.error("Error in fetching leave",'Error')
      }
    })
}

  fetchEmployeeVacations(employeeId: any) {
    this.vacationService.employeeVacationRequests(Number(employeeId), this.filter).subscribe({
      next: (data) => {
        console.log(data);

        this.rowData = data
          .map((data: any) => {
            const approvers = data?.employee?.employeeApprovers[0] || null;
            return {
              id: data?.id || '--',
              employeeId: data?.employeeId || '--',
              startDate: this.datePipe.transform((data?.startDate), 'dd/MM/YYYY'),
              endDate: this.datePipe.transform((data?.endDate), 'dd/MM/YYYY'),
              totalDays: data?.totalDays || '--',
              comment: data?.employeeComment || '--',
              approver1: approvers?.levelOneData ? approvers.levelOneData.firstName + ' ' + approvers.levelOneData.lastName : null,
              approver2: approvers?.levelTwoData ? approvers.levelTwoData.firstName + ' ' + approvers.levelTwoData.lastName : null,
              approver3: approvers?.levelThreeData ? approvers.levelThreeData.firstName + ' ' + approvers.levelThreeData.lastName : null,
              approver4: approvers?.levelFourData ? approvers.levelFourData.firstName + ' ' + approvers.levelFourData.lastName : null
            };
          });
      },
      error: (error: any) => {
        this.toastr.error(error?.message);
      },
    });
  }
}