
    <section class="cmn-innerpage-wrapper leave-policy">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" />
            </div>
            <button class="cmn-filter-btn" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }">
                <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
            </button>
            <!-- Filter Popup -->
            <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <div>
                    <h2>Branch</h2>
                    <div formArrayName="branches" class="check-wrapper">
                        <div class="check-box">
                            <label class="checkbox-wrapper">
                                <input type="checkbox"/>
                                test
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <h2>Employee Type</h2>
                    <div formArrayName="employeeTypes" class="check-wrapper">
                        <div class="check-box">
                            <label class="checkbox-wrapper">
                                <input type="checkbox"/>
                                test
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="submit">Apply</button>
                    </div>
                </div>
            </div>
            <!-- Filter Popup -->
            <button class="cmn-add-btn" (click)="navigateTo('/leave-grant')">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Grant Leave
            </button>

        </div>
        <div class="inner-content">
            <ag-grid-angular style="width: 100%;" [rowData]="rowData" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [suppressRowClickSelection]="true"
                [pagination]="true" [paginationPageSize]="paginationPageSize"
                [paginationPageSizeSelector]="paginationPageSizeSelector" [class]="themeClass" />
        </div>
    </section>
