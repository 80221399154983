import gql from 'graphql-tag';

export const CREATE_GRN = gql`
  mutation createGoodsReceivedWithItems($createGoodsReceivedInput: CreateGoodsReceivedInput!, $grnItems: [CreateGrnitemInput]) {
    createGoodsReceivedWithItems(createGoodsReceivedInput: $createGoodsReceivedInput, grnItems: $grnItems) {
    id
    goodsReceivedDate
    grnNumber
    grnStatus
    status
    createdAt
    updatedAt
    deletedAt
    receivedById
  }
  }
`;

export const UPDATE_GRN = gql`
  mutation editGoodsReceived($updateGoodsReceivedInput: UpdateGoodsreceivedInput!, $grnItems: [UpdateGrnitemInput]) {
    editGoodsReceived(updateGoodsReceivedInput: $updateGoodsReceivedInput, grnItems: $grnItems)  {
    id
    goodsReceivedDate
    grnNumber
    grnStatus
    status
    createdAt
    updatedAt
    deletedAt
  }
  }
`;

export const DELETE_GRN = gql`
  mutation deleteGoodsReceived($id: Int!) {
    deleteGoodsReceived(id: $id)
  }
`;