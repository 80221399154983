<ag-grid-angular
  style="width: 100%"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [rowSelection]="rowSelection"
  [suppressRowClickSelection]="true"
  [pagination]="true"
  [paginationPageSize]="paginationPageSize"
  [paginationPageSizeSelector]="paginationPageSizeSelector"
  [class]="themeClass"
  [getRowHeight]="getRowHeight"
  (rowClicked)="onRowClicked($event)"
/>

<!-- <button (click)="openDialog()">Dialog</button> -->
