<div class="common-noimage-tab-wrapper">
    <mat-tab-group (selectedTabChange)="onTabChange($event)" animationDuration="0ms">
        <!-- Mat Tab One -->
        <mat-tab>
            <ng-template mat-tab-label>
                <h2 class="leave-tab-title">Leave History</h2>
            </ng-template>
            <div class="leave-history-table">
                <app-leave-history *ngIf="activeTabIndex === 0" #leaveHistory></app-leave-history>
            </div>

        </mat-tab>
        <!-- Mat Tab Two -->
        <mat-tab>
            <ng-template mat-tab-label>
                <h2 class="leave-tab-title">Apply For Leave</h2>
            </ng-template>
            <div class="leave-history-table">
                <app-leave-apply></app-leave-apply>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <h2 class="leave-tab-title">Holiday Calendar</h2>
            </ng-template>
            <div class="leave-history-table">
                <app-holiday-list *ngIf="activeTabIndex === 2" #holidayCalendar></app-holiday-list>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>