import gql from 'graphql-tag';

export const UPDATE_CONFIGURE = gql`
  mutation updatePreferenceCode($id: Int!, $updatePreferenceCodeInput: UpdatePreferenceCodeInput!) {
    updatePreferenceCode(id: $id, updatePreferenceCodeInput: $updatePreferenceCodeInput) {
    id
    category
    prefix
    status
    createdAt
    updatedAt
    deletedAt
  }
  }
`;

