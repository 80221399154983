import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { GENERATE_PETTY_CASH_PDF, LIST_PETTY_CASH, LIST_PETTY_CASH_BY_ID, PETTY_CASH_DETAIL, TOTAL_AMOUNT_PETTY_CASH } from '../graphql/queries/petty-cash.query';
import { CREATE_PETTY_CASH, REMOVE_PETTY_CASH, UPDATE_PETTY_CASH } from '../graphql/mutation/petty-cash.mutation';

@Injectable({
  providedIn: 'root',
})
export class PettyCashService {
  constructor(private apollo: Apollo) { }

  fetchPettyCash(search: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .query<any>({
        query: LIST_PETTY_CASH,
        variables:
        {
          search
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((result) => result.data?.pettyCashes || [])
      );
  }

  fetchPettyCashById(id: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: LIST_PETTY_CASH_BY_ID,
        variables:
        {
          id
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.pettyCash || [])
      );
  }

  getProjectBasedPettyCashes(category: any, id: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: PETTY_CASH_DETAIL,
        variables:
        {
          category,
          id
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.getPettyCashesByCategory || [])
      );
  }

  getTotalAmountForExpenseCategory(): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: TOTAL_AMOUNT_PETTY_CASH,
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.getTotalAmountForExpenseCategory || [])
      );
  }

  
  generatePettyCashPdf(pettyCashId: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .query<any>({
        query: GENERATE_PETTY_CASH_PDF,
        variables: {
          pettyCashId: pettyCashId,
        },
        fetchPolicy: 'network-only'
      })
      .pipe(map((response) => response.data?.generatePettyCashPdf));
     
  }

  createPettyCash(createPettyCashInput: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_PETTY_CASH,
      variables: {
        createPettyCashInput,
      },
    });
  }

  updatePettyCash(updatePettyCashInput: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: UPDATE_PETTY_CASH,
      variables: {
        updatePettyCashInput,
      },
    });
  }

  removePettyCash(id: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_PETTY_CASH,
      variables: {
        id,
      },
    });
  }
 
}