import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-salary-details-profile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './salary-details-profile.html',
  styleUrl: '../../../../employee-details.component.scss',
})
export class SalaryDetailsProfileComponent {
  @Input() employeeData: any;
}
