import { Component, Inject, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';

import { FormBuilder, Validators } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
  selector: 'app-item-purchase-order-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    AgGridAngular,
    PermissionDirective,
    MatOptionModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    NgxMatSelectSearchModule,
  ],
  templateUrl: './item-purchase-order-details.component.html',
  styleUrl: './item-purchase-order-details.component.scss',
})
export class PurchaseOrderItemDetailsComponent implements OnInit {
  itemId: any;
  itemData: any = {};
  allItems: any[] = [];
  public loading: boolean = true;
  private searchSubject = new Subject<string>();
  public Permission: any = Permission;
  isNewRequest: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private itemService: InventoryItemService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.isNewRequest = params['newRequest'] === 'true';
    });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }
  sentRequest() {
    this.isNewRequest = false;
  }

  onCellClicked(event: any) {
    this.isNewRequest = false; // Show p tag with status
  }

  getStatusClass(status: string): string {
    return status === 'Completed' ? 'text-completed' : 'text-pending';
  }

  addItem(): void {
    const itemGroup = this.fb.group({
      selectItem: [null],
      itemName: [''], // Optional for manual entry
      quantity: [
        0,
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
        ],
      ],
      rate: [
        0,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
        ],
      ],
      discount: [0, [Validators.min(0), Validators.max(100)]],
      total: [0, [Validators.required, Validators.min(0)]],
      subTotal: [0, [Validators.min(0), Validators.pattern(/^\d+(\.\d+)?$/)]],
      vatAmount: [0],
      netPrice: [''],
    });
  }

  readonly dialog = inject(MatDialog);

  rowData = [
    {
      itemName: 'Ultratech Cement',
      skuCode: 'CM12034562',
      rate: 'AED 2,500',
      unit: 'Kg',
    },
    {
      itemName: 'Ultratech Cement',
      skuCode: 'CM12034562',
      rate: 'AED 2,500',
      unit: 'Kg',
    },
  ];

  columnDefs = [
    {
      headerName: 'Name',
      field: 'itemName',
      flex: 1,
    },
    { headerName: 'SKU', field: 'skuCode', flex: 1 },
    { headerName: 'Rate', field: 'rate', flex: 1 },
    { headerName: 'Unit', field: 'unit', flex: 1 },
  ];
  addedRowData = [
    {
      space: 'Lorem',
      stack: 'AAB',
      row: '32236',
      column: '2563',
    },
    {
      space: 'Lorem',
      stack: 'AAB',
      row: '32236',
      column: '2563',
    },
    {
      space: 'Lorem',
      stack: 'AAB',
      row: '32236',
      column: '2563',
    },
  ];
  addedColumnDefs = [
    {
      headerName: 'Space',
      field: 'space',
      flex: 1,
    },
    { headerName: 'Stack', field: 'stack', flex: 1 },
    { headerName: 'Row', field: 'row', flex: 1 },
    { headerName: 'Column', field: 'column', flex: 1 },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
}
