import { Component, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../core/services/auth.service';
import { passwordStrengthValidator } from '../../../../shared/services/validations';

@Component({
  selector: 'app-security',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './security.component.html',
  styleUrl: '../../employee-details.component.scss',
})
export class SecurityComponent implements OnInit {
  changePasswordForm: FormGroup;
  successMessage: string | null = null;
  errorMessage: string | null = null;
  loading: boolean = false;
  userId: number;

  private changePasswordUrl = `${environment.authUrl}/auth/resetPassword`;

  // Separate signals for each password field
  hideCurrentPassword = signal(true);
  hideNewPassword = signal(true);
  hideConfirmPassword = signal(true);

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.authService.getLoggedInUser();
    const loggedInUser = this.authService.getLoggedInUser();
    this.userId = loggedInUser.id;
  }

  private initForm(): void {
    this.changePasswordForm = this.fb.group(
      {
        currentPassword: ['', Validators.required],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            passwordStrengthValidator,
          ],
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: this.passwordMatchValidator,
      }
    );
  }

  hide = signal(true);
  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }

  passwordMatchValidator(group: FormGroup) {
    const newPassword = group.get('newPassword')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    return newPassword === confirmPassword ? null : { passwordMismatch: true };
  }

  // Separate methods to toggle visibility for each field
  toggleCurrentPasswordVisibility(event: MouseEvent) {
    this.hideCurrentPassword.set(!this.hideCurrentPassword());
    event.stopPropagation();
  }

  toggleNewPasswordVisibility(event: MouseEvent) {
    this.hideNewPassword.set(!this.hideNewPassword());
    event.stopPropagation();
  }

  toggleConfirmPasswordVisibility(event: MouseEvent) {
    this.hideConfirmPassword.set(!this.hideConfirmPassword());
    event.stopPropagation();
  }

  onSubmit() {
    if (this.changePasswordForm.hasError('passwordMismatch')) {
      this.toastr.error(
        'New Password and Confirm Password do not match',
        'Error'
      );
    }

    if (this.changePasswordForm.valid) {
      const newPassword = this.changePasswordForm.value.newPassword;
      const currentPassword = this.changePasswordForm.value.currentPassword;
      this.loading = true;
      this.http
        .post(this.changePasswordUrl, {
          password: newPassword,
          id: this.userId,
          currentPassword: currentPassword,
        })
        .subscribe({
          next: (response: any) => {
            this.successMessage = 'Password changed successfully.';
            this.errorMessage = null;
            this.toastr.success(response.message || this.successMessage);
            this.loading = false;
          },
          error: (error: HttpErrorResponse) => {
            console.error('Error occured: ', error);
            if (
              error.error &&
              error.error.message &&
              error.error.message === "Password Doesn't Match"
            ) {
              this.errorMessage = 'Incorrect current password';
            } else if (error.error && error.error.message) {
              this.errorMessage = error.error.message;
            } else {
              this.errorMessage =
                'Failed to change password. Please try again.';
            }
            this.toastr.error(
              this.errorMessage || 'Something went wrong',
              'Error'
            );
            this.successMessage = null;
            this.loading = false;
          },
        });
    }
  }

  onCancel(): void {
    this.changePasswordForm.reset();
  }
}
