import { Component , Inject, Optional} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.html',
  styleUrls: ['./confirm-dialog.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class ConfirmDialog {
  title: string;
  message: string;
  confirmText: string;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: { title?: string; message?: string; confirmText?: string }
  ) {
     this.title = data?.title || 'Confirm Delete?';
     this.message = data?.message || 'Are you sure you want to delete?';
     this.confirmText = data?.confirmText || 'Delete';
  }

  onCancel(): void {
    this.dialogRef.close(false); 
  }

  // Close the dialog with 'Delete'
  onDelete(): void {
    this.dialogRef.close(true);
  }
}
