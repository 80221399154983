import { gql } from 'apollo-angular';

export const GET_INVOICES = gql`
  query invoices($search: String) {
    invoices(search: $search) {
      id
      projectModelId
      quoteId
      journalId
      journalData {
        id
        date
        journalNo
        reference
        notes
        currencyId
        inCash

        journalEnteries {
          id
          chartOfAccountId
          ChartOfAccount {
            id
            accountName
          }
          journalId
          partnerId
          description
          debit
          credit
        }
      }
      partnerId
      partnerData {
        id
        partnerType
        customerType
        displayName
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
        primaryContactId
      }
      billingAddressId
      billingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      shippingAddressId
      shippingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      invoiceNo
      orderNo
      invoiceDate
      paymentTerm
      paymentStatus
      reason
      dueDate
      subject
      customerNote
      isPayment
      subTotal
      invoiceUrl
      tax
      adjustment
      totalPrice
      balance
      status
      createdAt
      updatedAt
      deletedAt
      invoiceTemplate {
        id
        templateName
        templateSubject
        templateBody
        templateType
      }
      paymentInfos {
        id
        paymentId
        refType
        refId
        amount
        createdAt
        updatedAt
      }
      invoiceAttachments {
        id
        invoiceId
        fileUrl
        fileName
        createdAt
        updatedAt
        deletedAt
      }
      invoiceItems {
        amount
        id
        discount
        invoiceId
        description
        itemId
        itemData {
          id
          itemType
          skuCode
          imageUrl
          isSalesActive
          isPurchaseActive
          quantity
          unit
          itemName
          sellingPrice
          salesAccount
          salesDescription
          status
          notes
          costPrice
          purchaseAccount
        }
        quantity
        rate
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROJECTS = gql`
  query getProjectDropDownItems {
    getProjectDropDownItems {
      id
      projectCode
      projectName
      totalProjectCost
      description
      startDate
      endDate
      status
      budget
      threshold
      ApprovedQuoteDocumentURL
      proofDocument
      billingMethod
      costBudget
      revenueBudget
      branchId
    }
  }
`;

export const GET_ACCOUNTS_DEPOSIT = gql`
  query getChartOfAccountDropDownItems($search: String) {
    getChartOfAccountDropDownItems(search: $search) {
      id
      accountType
      accountName
      accountCode
      description
      amount
      parentAccountId
      isSubAccount
      isEditable
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_CLIENTS = gql`
  query getPartnersDropDownItems($search: String) {
    getPartnersDropDownItems(search: $search) {
      id
      partnerType
      customerType
      displayName
      companyName
      paymentTerm
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      primaryContactId
      projects {
        id
        projectCode
        projectName
        totalProjectCost
      }
      partnerProjects {
        id
        projectCode
        projectName
        totalProjectCost
        description
      }
      primaryContact {
        id
        salutation
        firstName
        lastName
        email
        phone
        mobile
        primaryContact
        status
        partnerId
      }
      partnerProjects {
        id
        projectCode
        projectName
        totalProjectCost
        description
        __typename
      }
      contacts {
        id
        salutation
        firstName
        lastName
        email
        phone
        mobile
        primaryContact
        status
        partnerId
      }
      addresses {
        id
        addressType
        addressLine1
        addressLine2
        attention
        city
        country
        fax
        state
        phone
      }
    }
  }
`;

export const GET_INVOICE_BY_ID = gql`
  query invoice($id: Int!) {
    invoice(id: $id) {
      id
      projectModelId
      partnerData {
        id
        partnerType
        customerType
        displayName
        email
        phone
        mobile
      }
      billingAddressId
      billingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      shippingAddressId
      shippingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      invoiceNo
      invoiceAttachments {
        id
        invoiceId
        fileUrl
        fileName
        createdAt
        updatedAt
        deletedAt
      }
      invoiceItems {
        amount
        id
        discount
        invoiceId
        description
        itemId
        itemData {
          id
          itemType
          skuCode
          imageUrl
          isSalesActive
          isPurchaseActive
          quantity
          unit
          itemName
          sellingPrice
          salesAccount
          salesDescription
          status
          notes
          costPrice
          purchaseAccount
        }
        quantity
        rate
        createdAt
        updatedAt
      }
      journalData {
        id
        date
        journalNo
        reference
        notes
        currencyId
        inCash
        journalEnteries {
          id
          chartOfAccountId
          ChartOfAccount {
            id
            accountName
          }
          journalId
          partnerId
          description
          debit
          credit
        }
      }
      orderNo
      invoiceDate
      paymentTerm
      paymentStatus
      reason
      dueDate
      subject
      customerNote
      isPayment
      subTotal
      invoiceUrl
      tax
      adjustment
      totalPrice
      balance
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_INVOICE_DASHBOARD = gql`
  query getInvoicesDashboard {
    getInvoicesDashboard {
      totalOutstandingReceivables
      dueToday
      dueWithin30Days
      overdueInvoice
    }
  }
`;
export const GENERATE_INVOICE_PDF = gql`
  query generateInvoicePdf($invoiceId: Int!) {
    generateInvoicePdf(invoiceId: $invoiceId)
  }
`;
export const GET_INVOICE_DETAILS = gql`
  query getInvoiceDetails($id: Int!) {
    getInvoiceDetails(id: $id) {
      id
      projectModelId
      partnerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        contacts {
          id
          salutation
          firstName
          lastName
          email
          phone
          mobile
          primaryContact
          status
          partnerId
        }
        primaryContact {
          id
          salutation
          firstName
          lastName
          email
          phone
          mobile
          primaryContact
          status
          partnerId
        }
      }
      billingAddressId
      shippingAddressId
      invoiceNo
      orderNo
      invoiceDate
      paymentTerm
      paymentStatus
      reason
      dueDate
      subject
      customerNote
      isPayment
      subTotal
      tax
      adjustment
      totalPrice
      balance
      status
      createdAt
      updatedAt
      deletedAt
      invoiceUrl
      invoiceTemplate {
        id
        templateName
        templateSubject
        templateBody
        templateType
      }
    }
  }
`;

export const MARK_AS_SENT = gql`
  mutation updateInvoiceToMarkAsSent($id: Int!, $employeeId: Int) {
    updateInvoiceToMarkAsSent(id: $id, employeeId: $employeeId) {
      id
    }
  }
`;
