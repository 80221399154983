import { gql } from "apollo-angular";

export const CHART_ACCOUNTS = gql`
  query chartOfAccounts($accountType: [String!], $search: String, $parentAccountId: Int, $isSubAccount: Int) {
    chartOfAccounts(accountType: $accountType, search: $search, parentAccountId: $parentAccountId, isSubAccount: $isSubAccount)  {
    id
    accountType
    accountName
    accountCode
    description
    parentAccountId
    parentAccountData {
      id
      accountType
      accountName
      accountCode
      description
      parentAccountId
      # parentAccountData
      isSubAccount
      createdAt
      updatedAt
      deletedAt
      # JournalEnteries
    }
    isSubAccount
    isEditable
    createdAt
    updatedAt
    deletedAt
    JournalEnteries {
      id
      chartOfAccountId
      # ChartOfAccount
      journalId
      # Journal
      partnerId
      # Partner
      description
      debit
      credit
    }
  }
  }
`;

export const CHART_ACCOUNTS_BY_ID = gql`
  query chartOfAccount($id: Int!) {
    chartOfAccount(id: $id){
    id
    accountType
    accountName
    accountCode
    amount
    description
    parentAccountId
    parentAccountData {
      id
      accountType
      accountName
      accountCode
      description
      parentAccountId
      isSubAccount
      createdAt
      updatedAt
      deletedAt
    }
    isSubAccount
    createdAt
    updatedAt
    deletedAt
    JournalEnteries{
        id
        chartOfAccountId
        journalId
        partnerId
        Partner{
          displayName
          partnerType
        }
        description
        debit
        credit
        createdAt
      }
  }
  }
`;

export const ACCOUNTS = gql`
  query accounts($accountType: String, $search: String) {
    accounts(accountType: $accountType, search: $search) {
    id
    accountName
    accountType
    balance
    createdAt
    updatedAt
    deletedAt
  }
  }
`;
