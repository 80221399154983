import { Component, Inject, inject, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';
import { QuotationService } from '../../features/quotation/services/quotation.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { PaymentsItemService } from '../../features/payments/services/payments.service';
import { QUOTESTATUS } from '../../helpers/helper-file';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'app-quotation-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './quotation-details.component.html',
  styleUrl: './quotation-details.component.scss',
})
export class QuotationDetailsComponent implements OnInit {
  selectedTabIndex = 0;
  readonly dialog = inject(MatDialog);
  isDropdownOpen = false;
  expenseDetails: any = {};
  detailsLoading: any = false;
  loading: boolean = false;
  allQuotes: any = [];
  quoteData: any = {};
  parentQuoteId: any;
  parentQuoteData: any = {};
  allParentQuotes: any[] = [];
  customerId: any;
  selectedParentQuote: any;
  selectedQuote: any = {};
  private searchSubject = new Subject<string>();

  selectedOption = 'J-000102 - 3';
  selectedOption2 = 'J-000102 - 3';
  isCommentsOpen = false;
  commentsList: any;
  commentsForm: FormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private quoteService: QuotationService,
    private commentService: PaymentsItemService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.fetchAllParentQUotes();

    this.route.paramMap.subscribe((params) => {
      this.parentQuoteId = params.get('id');
      if (this.parentQuoteId)
        if (this.parentQuoteId) {
          const id = +this.parentQuoteId;
          this.quoteService.fetchParentQuoteById(id).subscribe({
            next: (data) => {
              this.customerId = data.partnerData.id;
            },
          });
        }
    });

    this.searchSubject.pipe(debounceTime(600), distinctUntilChanged()).subscribe((searchTerm) => {
      this.fetchAllParentQUotes(searchTerm);
    });

    this.commentsForm = this.fb.group({
      comments: ['', Validators.required],
    });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  selectTab(index: number): void {
    this.selectedTabIndex = index;
    this.parentQuoteData = this.allParentQuotes[index] || {};
    this.selectedQuote = this.parentQuoteData.quoteData?.[0] || {};
    const quoteId = this.parentQuoteData?.id
    // this.router.navigate([`quotation/quotation-details/${this.parentQuoteData?.id}`]);
    this.router.navigate(['quotation/quotation-details'], {
      queryParams: { id: this.parentQuoteData.id },
    });
  }

  initializeFirstQuote(): void {
    if (this.allParentQuotes.length > 0) {
      this.selectedTabIndex = 0;
      this.parentQuoteData = this.allParentQuotes[this.selectedTabIndex];
      this.selectedQuote = this.parentQuoteData.quoteData?.[0] || {};
    }
  }

  onQuoteSelectionChange(selectedQuote: any): void {
    this.selectedQuote = selectedQuote;
  }

  fetchAllParentQUotes(search: string = ''): void {
    this.loading = true;
    this.quoteService.fetchParentQuote(search).subscribe({
      next: (parentQuotes) => {
        this.allParentQuotes = parentQuotes;
        if (this.parentQuoteId) {
          const targetId = +this.parentQuoteId;
          this.selectedTabIndex = this.allParentQuotes.findIndex(
            (c: any) => c.id === targetId
          );
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
        this.parentQuoteData =
          this.allParentQuotes[this.selectedTabIndex] || {};
        this.selectedQuote =
          this.parentQuoteData.quoteData?.[0] || {};
      },
      error: (err) => {
        console.error("Error fetching all parentQUotes:", err);
      },
      complete: () => {
        this.initializeFirstQuote();
        this.loading = false;
      },
    });
  }

  quoteMarkAsSent() {
    const parentQuoteId = +this.parentQuoteId;
    const quoteId = +this.selectedQuote?.id;
    const quoteStatusKey = Object.keys(QUOTESTATUS).find(
      key => QUOTESTATUS[key as keyof typeof QUOTESTATUS] === QUOTESTATUS.SENT
    ); // Extract the key for the status SENT

    if (!quoteStatusKey) {
      this.toastr.error('Invalid quote status');
      return;
    }

    this.quoteService.quoteMarkAsSent(quoteId, parentQuoteId, quoteStatusKey).subscribe({
      next: (response) => {
        this.toastr.success('Quotation sent');
        this.fetchAllParentQUotes()
      },
      error: (error) => {
        this.toastr.error('Failed to sent quotation');
      }
    });
  }

  reviseNewQuote(): void {
    if (!this.parentQuoteId || !this.selectedQuote?.id) {
      this.toastr.warning('Please select a valid parent quote and quote.');
      return;
    }

    this.loading = true;
    const parentId = +this.parentQuoteId
    this.quoteService.reviseQuote(this.selectedQuote, this.selectedQuote.id).subscribe({
      next: (response) => {
        this.loading = false;
        this.toastr.success('Quote revised successfully.');
        const revisedQuoteId = response?.data?.reviseQuote?.id;
        if (revisedQuoteId) {
          this.router.navigate([`quotation/quotation-edit/${revisedQuoteId}`]);
        }
        else {
          this.toastr.error('Failed to get the revised quote ID.');
        }
      },
      error: (err) => {
        this.loading = false;
        console.error('Error revising quote:', err);
        this.toastr.error(err);
      }
    });
  }

  onDeleteQuoteById(quoteId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.removeQuoteById(quoteId);
      }
    });
  }

  private removeQuoteById(quoteId: number): void {
    this.quoteService.removeQuote(quoteId).subscribe({
      next: () => {
        this.toastr.success("Quotation removed successfully.");
        this.fetchAllParentQUotes();
      },
      error: () => {
        this.toastr.error(
          'Failed to remove quotation. Please try again.'
        );
      },
    });
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadQuotationDetailsDialog);
  }

  openQuotaionDialog() {
    const dialogQuotaionRef = this.dialog.open(QuotaionRejectDialog);
  }

  openQuotaionApprovalDialog() {
    if (!this.selectedQuote?.id) {
      this.toastr.warning('Please select a quote to approve.');
      return;
    }

    const dialogQuotaionRef = this.dialog.open(QuotaionApproveDialog, {
      data: { quoteId: this.selectedQuote.id },
    });

    dialogQuotaionRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.success('Approval confirmed.');
      }
    });
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleComments(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    const selectedItem = this.allParentQuotes[this.selectedTabIndex];
    const itemId = selectedItem.id
    this.fetchComments(itemId);
    this.isCommentsOpen = !this.isCommentsOpen;
  }

  navigateToSendMail(id: number) {
    console.log("QUOTE ID------", id)
    console.log("Selected quote id----", this.selectedQuote?.id)
    const quoteId = +id;
    // this.router.navigate([`quotation/send-mail/${quoteId}`]);
    this.router.navigate([`quotation/quotation-details/mail`], {
      queryParams: { quoteId },
    });
  }

  fetchComments(refId: number) {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee.employeeId;

    const value = {
      employeeId: employeeId,
      refId: refId,
      activityType: 'Comment',
      type: 'Quote',
    };
    this.loading = true;
    this.commentService.comments(value).subscribe({
      next: (items) => {
        this.commentsList = items;
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching all comments:', err);
        this.loading = false;
      },
    });
  }

  onCommentSubmit(): void {
    if (this.commentsForm.invalid) {
      this.commentsForm.markAllAsTouched();
      return;
    }

    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee.employeeId;
    const selectedIteam = this.allParentQuotes[this.selectedTabIndex];
    const refId = selectedIteam.id;

    const data = this.commentsForm.value;

    const createCommentInput = {
      employeeId: employeeId,
      refId: refId,
      type: 'Quote',
      activityType: 'Comment',
      note: data?.comments,
    };

    this.commentService.createComment(createCommentInput).subscribe({
      next: () => {
        this.loading = false;
        const selectedIteam = this.allParentQuotes[this.selectedTabIndex];
        const itemId = selectedIteam.id;
        this.fetchComments(itemId);
        this.toastr.success('Comments added');
        this.commentsForm.reset();
      },
      error: (error) => {
        this.loading = false;
        this.toastr.error(error.message || 'Failed to add comments.');
      },
    });
  }

  navigateToEdit(quoteId: string): void {
    console.log("QuoteId---", quoteId);
    if (quoteId) {
      this.router.navigate([`quotation/quotation-edit/${quoteId}`]);
    } else {
      console.error('No quote ID selected');
    }
  }

}

@Component({
  selector: 'upload-quotation-details-dialog',
  templateUrl: 'upload-quotation-details-dialog.html',
  styleUrls: ['./quotation-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadQuotationDetailsDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}

@Component({
  selector: 'quotaion-reject-dialog',
  templateUrl: 'quotation-reject-dialog.html',
  styleUrls: ['./quotation-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class QuotaionRejectDialog { }

@Component({
  selector: 'quotaion-approve-dialog',
  templateUrl: 'quotation-approval-dialog.html',
  styleUrls: ['./quotation-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class QuotaionApproveDialog implements OnInit {
  quoteId: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { quoteId: number },
    private dialogRef: MatDialogRef<QuotaionApproveDialog>,
    private quoteService: QuotationService,
    private toastr: ToastrService,
    private router: Router

  ) { }

  ngOnInit(): void {
    this.quoteId = this.data?.quoteId;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onApprove(): void {
    if (!this.quoteId) {
      this.toastr.error('No quote ID provided.', 'Error');
      return;
    }

    // Call saveWorkOrder with the quoteId
    this.quoteService.saveWorkOrder(this.quoteId).subscribe({
      next: (response) => {
        this.toastr.success('Work order saved successfully.', 'Success');
        this.dialogRef.close(true);
        const createWOrkOrderID = response.data.saveWorkOrder;
        this.router.navigate([
          `work-order/work-order-edit/${createWOrkOrderID}`,
        ]);
      },
      error: (err) => {
        console.error('Error saving work order:', err);
        this.toastr.error('Failed to save work order.', 'Error');
      },
    });
  }
}
