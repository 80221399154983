import { Component, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormArray,
} from '@angular/forms';
import { CurrencyAdjustmentService } from '../../features/currency-adjustments/services/currency-adjustment.service';
import { ChartAccountsService } from '../../features/chart-of-accounts/services/chart-accounts.service';
import { CustomerService } from '../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ExpenseServices } from '../../features/expenses/services/expenses.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateClientDialog } from '../invoice-new/dialog-components/create-customer-dialog/create-client-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../features/projects/services/projects.service';
import { BankingService } from '../../features/banking/services/banking.service';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';
import { PartnerTypesEnum } from '../../helpers/helper-file';

@Component({
  selector: 'app-expense-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    PermissionDirective
  ],
  templateUrl: './expense-new.component.html',
  styleUrl: './expense-new.component.scss',
})
export class ExpenseNewComponent {
  expenseForm: FormGroup;
  currencyData: any;
  formArrayInitialized = false;
  today: Date = new Date();
  chartOfAccounts: any = [];
  vendorsList: any = [];
  customerList: any = [];
  readonly dialog = inject(MatDialog);
  private searchSubject = new Subject<string>();
  loading: boolean = false;
  expenseId: number | null = null;
  allProjects: any = [];
  filterProjects: any = [];
  bankId: any;
  categoryId: any;
  Permission = Permission;
  private partnerTypes = PartnerTypesEnum;

  constructor(
    private currencyService: CurrencyAdjustmentService,
    private chartAccountService: ChartAccountsService,
    private customerService: CustomerService,
    private toastr: ToastrService,
    private expenseService: ExpenseServices,
    private datePipe: DatePipe,
    private invoiceService: InvoiceService,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private bankingService: BankingService,
  ) {
    this.expenseForm = new FormGroup({
      date: new FormControl('', [Validators.required]),
      currencyId: new FormControl('', [Validators.required]),
      paidThrough: new FormControl(''),
      venderId: new FormControl(''),
      invoiceNo: new FormControl('', [
        Validators.pattern(/^IN-\w*$/),
        Validators.maxLength(50),
      ]),
      customerId: new FormControl(''),
      projectId: new FormControl(''),
      isBillable: new FormControl(''),
      expenses: new FormArray([]), // Initialize as an empty FormArray
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const expense = params['expense'];
      this.bankId = expense;
      const category = params['category'];
      this.categoryId = category;
      console.log('bankId', this.bankId);

    })
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.expenseId = +id;
        this.loadSingleExpense(+id);
      } else {
        this.addExpenseRow();
      }
    });
    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchPartners(searchTerm);
      });

    this.fetchCurrencies();
    this.fetchChartAccounts();
    this.fetchPartners();
    this.fetchPartnersAll();
    this.fetchAllProjects();
  }

  loadSingleExpense(expenseId: number): void {
    this.expenseService.getExpenseById(expenseId).subscribe(async (data) => {
      try {
        const expenseData = data;
        if (expenseData) {
          if (expenseData?.expenseItems.length) {
            this.formArrayInitialized = true;
            expenseData?.expenseItems?.map((el: any) => {
              this.updateExpenseRow(el);
            });
          } else {
            this.formArrayInitialized = false;
            const item = {
              accountId: expenseData.accountId,
              description: expenseData.note,
              amount: expenseData.note.amount,
            };
            this.updateExpenseRow(item);
          }
          this.expenseForm.patchValue({
            date: expenseData.date ? new Date(expenseData.date) : null,
            currencyId: expenseData.currencyId,
            paidThrough: expenseData.paidThrough,
            venderId: expenseData.vendorId,
            invoiceNo: expenseData?.invoiceNo,
            customerId: expenseData?.customerId,
            projectId: expenseData?.projectId,
            isBillable: expenseData?.isBillable,
          });
        }
      } catch (error) {
        this.router.navigate(['/expense']);

        console.error('Error loading employee data:', error);
      }
    });
  }

  dateFilter = (d: Date | null): boolean => {
    const currentDate = d || new Date();
    return currentDate <= this.today;
  };

  fetchCurrencies() {
    this.currencyService.fetchCurrencies('', []).subscribe({
      next: (currency) => {
        this.currencyData = currency;
        console.log(this.currencyData);
      },
      error: (error) => {
        this.toastr.error(error?.message);
        console.error(error);
      },
    });
  }

  fetchChartAccounts() {
    this.chartAccountService.chartOfAccounts([], null, null, null).subscribe({
      next: (data) => {
        this.chartOfAccounts = data;
      },
      error: (error) => {
        this.toastr.error(error?.message);
        console.error('Error fetching chart accounts:', error);
      },
    });
  }

  fetchPartnersAll() {
    this.customerService.fetchPartners('').subscribe({
      next: (data: any) => {
        this.vendorsList = data
          .map((data: any) => {
            if (data.partnerType === 'Vendor') {
              return {
                id: data?.id || '--',
                name: data?.displayName || '--',
              };
            }
            return null;
          })
          .filter((item: any) => item !== null);
      },
      error: (error) => {
        console.log('the error is ', error);

        this.toastr.error(error?.message);
      },
    });
  }

  fetchAllProjects() {
    this.projectService.getAllProjects('').subscribe({
      next: (data: any) => {
        this.allProjects = data;
        this.filterProjects = data;
      },
      error: (error) => {
        console.log('the error is ', error);
        this.toastr.error(error?.message);
      },
    });
  }

  private fetchPartners(search: string = '') {
    this.invoiceService.fetchCustomers(search,  this.partnerTypes.Customer).subscribe({
      next: (partners) => {
        this.customerList = partners
          // .filter((data: any) => data.partnerType === 'Customer')
          .map((data: any) => ({
            id: data.id || '--',
            name: data.displayName || '--',
            addresses: data.addresses || [],
          }));

        // this.billingAddressList = partners
        //   .flatMap((partner: any) => partner.addresses || [])
        //   .filter((address: any) => address.addressType === 'BILLING');

        // this.shippingAddressList = partners
        //   .flatMap((partner: any) => partner.addresses || [])
        //   .filter((address: any) => address.addressType === 'SHIPPING');
      },
      error: (error) => console.error(error),
    });
  }

  get expenses(): FormArray {
    return this.expenseForm.get('expenses') as FormArray;
  }

  onItemizeRemove(): void {
    if (!this.formArrayInitialized) {
      this.formArrayInitialized = true;
      // this.addExpenseRow();
    } else {
      this.formArrayInitialized = false;
      this.removeAllExpenses();
      this.addExpenseRow();
    }
  }
  addExpenseRow() {
    const expenseGroup = new FormGroup({
      accountId: new FormControl('', Validators.required),
      description: new FormControl('', [Validators.maxLength(500)]),
      amount: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\d+$/), // Validates positive integers only
      ]),
    });

    this.expenses.push(expenseGroup);
  }

  updateExpenseRow(data: any) {
    const expenseGroup = new FormGroup({
      id: new FormControl(data?.itemId ?? ''),
      accountId: new FormControl(data?.accountId ?? '', Validators.required),
      description: new FormControl(data?.description ?? '', [
        Validators.maxLength(500),
      ]),
      amount: new FormControl(data?.amount ?? '', [
        Validators.required,
        Validators.pattern(/^\d+$/), // Validates positive integers only
      ]),
    });

    this.expenses.push(expenseGroup);
  }

  removeExpenseRow(index: number) {
    const itemData = this.expenses.at(index) as FormGroup;
    const values = itemData?.value;
    if (values?.id) {
      this.expenseService.removeExpenseItem(values?.id).subscribe({
        next: (response) => {
          this.expenses.removeAt(index);
        },
        error: (error) => {
          this.toastr.error(error.message || 'Failed to remove item');
          this.loading = false;
        },
      });
    } else {
      this.expenses.removeAt(index);
    }
  }
  removeAllExpenses() {
    if (this.expenseForm.get('expenses')) {
      this.expenses.clear();
    }
  }

  onSubmit(): void {
    console.log('this.expenseFormthis.expenseForm', this.expenseForm?.value);
    if (this.expenseForm.invalid) {
      this.expenseForm.markAllAsTouched();
      // this.validationError = true;
      this.toastr.warning('Please fill mandatory fields!', 'Warning');
      return;
    }
    const values = this.expenseForm?.value;
    let createExpenseInput: any;
    let expenseItems: any;
    let fileAttachments: any;
    if (this.formArrayInitialized) {
      createExpenseInput = {
        currencyId: values.currencyId,
        isBillable: values.isBillable ? true : false,
        expenseStatus: values.isBillable ? 'Unbilled' : 'NonBillable',
        date: this.datePipe.transform(values.date, 'yyyy-MM-dd'),
        invoiceNo: values.invoiceNo,
        projectId: values.projectId ? values.projectId : null,
        vendorId: values.venderId ? values.venderId : null,
        customerId: values.customerId ? values.customerId : null,
        paidThrough: values?.paidThrough,
        isItemized: 2, // Itemzed for 1
      };
      expenseItems = values?.expenses;
    } else {
      createExpenseInput = {
        accountId: values.expenses[0]?.accountId,
        currencyId: values.currencyId,
        isBillable: values.isBillable ? true : false,
        expenseStatus: values.isBillable ? 'Unbilled' : 'NonBillable',
        date: this.datePipe.transform(values.date, 'yyyy-MM-dd'),
        amount: +values.expenses[0]?.amount,
        note: values.expenses[0]?.description,
        invoiceNo: values.invoiceNo,
        projectId: values.projectId ? values.projectId : null,
        vendorId: values.venderId ? values.venderId : null,
        customerId: values.customerId ? values.customerId : null,
        paidThrough: values?.paidThrough,
        // isItemized: 1, // not itemzed for 1
      };
    }
    this.loading = true;
    if (this.expenseId) {
      createExpenseInput = {
        id: this.expenseId,
        ...createExpenseInput,
      };
      let itemWithId: any = [];
      let itemWithoutId: any = [];
      expenseItems?.map((el: any) => {
        if (el?.id) {
          itemWithId = [...itemWithId, el];
        } else {
          const obj = {
            expenseId: this.expenseId,
            ...el,
          };
          itemWithoutId = [...itemWithoutId, obj];
        }
      });

      this.expenseService
        .updateExpense(
          createExpenseInput,
          itemWithId?.length ? itemWithId : null,
          fileAttachments
        )
        .subscribe({
          next: (response) => {
            this.toastr.success('Expense created successfully');
            this.loading = false;
            if (itemWithoutId?.length > 0) {
              this.expenseService.createExpenseItem(itemWithoutId).subscribe({
                next: (response) => {
                  if (this.bankId) {
                    this.createTransaction(this.expenseId);
                  } else {
                    this.router.navigate(['/expense']);
                  }
                },
                error: (error) => {
                  this.toastr.error(error.message || 'Failed to remove item');
                  this.loading = false;
                },
              });
            } else {
              this.router.navigate(['/expense']);
            }
          },
          error: (error) => {
            this.toastr.error(error.message || 'Failed to create invoice.');
            this.loading = false;
          },
        });
    } else {
      this.expenseService
        .createExpense(createExpenseInput, expenseItems, fileAttachments)
        .subscribe({
          next: (response) => {
            this.toastr.success('Expense created successfully');
            console.log('expense', response);
            
            // if (this.bankId) {
              this.createTransaction(response.id);
            // } else {
              this.router.navigate(['/expense']);
            // }
            this.loading = false;
          },
          error: (error) => {
            this.toastr.error(error.message || 'Failed to create invoice.');
            this.loading = false;
          },
        });
    }
  }

  createTransaction(expenseId: any) {
    const values = this.expenseForm?.value;
    const transactionData = {
      refId: expenseId,
      refTableType: 'Expense',
      date: this.datePipe.transform(this.expenseForm.value.date, 'yyyy-MM-dd'),
      transactionStatus: 'Manual',
      referenceNumber: this.expenseForm.value.invoiceNo,
      amount: this.expenseForm.value.expenses[0].amount,
      description: this.expenseForm.value.expenses[0].description,
      flow: 'Debit',
      offsetAccountId: (Number(this.bankId) || Number(values.expenses[0]?.accountId)),
      transactionCategoryId: Number(this.categoryId),
    }

    console.log('transactionData', transactionData);

    this.bankingService.createTransaction(transactionData).subscribe({
      next: (data) => {
        if(this.bankId){
          this.router.navigate([`/banking/banking-details/${this.bankId}`]);
        } else{
          return;
        }
        this.loading = false;
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
        this.loading = false;
      }
    })
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    console.log('onSearchonSearchonSearch', input);

    this.searchSubject.next(searchTerm);
  }

  openCreateClientDialog() {
    const dialogRef = this.dialog.open(CreateClientDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
      }
    });
  }

  onCustomerSelected(event: MatSelectChange) {
    const selectedCustomerId = event.value;
    this.expenseForm.get('customerId')?.setValue(selectedCustomerId);
    this.expenseForm.get('projectId')?.setValue(null);
    if (selectedCustomerId) {
      this.filterProjects = this.allProjects?.filter(
        (el: any) => el?.partnerId == selectedCustomerId
      );
    }
  }

  onClearForm(): void {
    this.expenseForm.reset();
  }
}
