<mat-dialog-content>
    <section class="add-profile-main-wrapper">
        <h2 class="cmn-popup-title">Add Stock</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <form [formGroup]="addStockForm">
            <div class="add-profile-dialog-wrapper">
                <div class="form-wrapper">
                    <label for="">Quantity</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Stack number" formControlName="quantity" />
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            addStockForm.get('quantity')!.touched &&
                            addStockForm.get('quantity')!.hasError('required')
                      ">Quantity is required.</mat-error>
                        </span>
                        <span>
                            <mat-error *ngIf="addStockForm.get('quantity')!.touched &&
                            addStockForm.get('quantity')!.hasError('spaceError')" class="error">Cannot start or end
                                with a space.
                            </mat-error>
                        </span>
                    </div>
                </div>
                <div class="form-wrapper">
                    <label for="">Unit Price</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Unit Price" formControlName="unitPrice" />
                    </mat-form-field>
                </div>
            </div>
        </form>
        <div class="btn-wrapper">
            <button class="cmn-popup-btn" type="submit">
                Add Stock
            </button>
        </div>

    </section>
</mat-dialog-content>