<div class="tab-inner-content">
  <h2>Balance Sheet</h2>
  <h5>
    From {{ startDate | date : "MM/d/YYYY" }} to
    {{ endDate | date : "MM/d/YYYY" }}
  </h5>
  <table>
    <thead>
      <tr>
        <th>Item</th>
        <th>Details</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let node of getTableRows(data)"
        [ngClass]="getNodeClass(node)"
      >
        <td [ngStyle]="{ 'padding-left': (node.level ?? 0) * 20 + 'px' }">
          {{ node.name }}
        </td>
        <td>{{ node.details }}</td>
      </tr>
    </tbody>
  </table>
</div>
