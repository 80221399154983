import { gql } from "apollo-angular";

export const LIST_PETTY_CASH = gql`
  query pettyCashes($search: String ) {
    pettyCashes(search: $search) {
    id
    projectId
    projectData {
      id
      projectCode
      projectName
    }
    employeeId
    projectEngineerData {
      id
      firstName
      lastName
      middleName
    }
    pettyCashNumber
    category
    flow
    date
    description
    amount
  }
  }
`;

export const LIST_PETTY_CASH_BY_ID = gql`
  query pettyCash($id: Int! ) {
    pettyCash(id: $id) {
    id
    projectId
    accountId
    category
    paidThroughAccount {
      id
      accountType
      accountName
      accountCode
      description
      amount
      accountId
      parentAccountId
      isSubAccount
      isEditable
      status
      createdAt
      updatedAt
      deletedAt
    }
    projectData {
      id
      projectCode
      projectName
      totalProjectCost
      description
    }
    employeeId
    projectEngineerData {
      id
      firstName
      lastName
      middleName
    }
    flow
    date
    description
    paymentMode
    pettyCashNumber
    amount
    chequeNumber
    chequeDate
    status
    fileName
    fileUrl
    createdAt
    updatedAt
  }
  }
`;

export const PETTY_CASH_DETAIL = gql`
  query getPettyCashesByCategory($category: String!, $id: String!) {
    getPettyCashesByCategory(category: $category, id: $id) {
      pettyCashes {
      id
      projectId
      accountId
      category
      employeeId
      flow
      date
      description
      paymentMode
      pettyCashNumber
      amount
      chequeNumber
      chequeDate
      status
      fileName
      fileUrl
      # pettyCashUrl
      createdAt
      updatedAt
    }
    totalInFlow
    totalOutFlow
    balance
  }
  }
`;

export const GENERATE_PETTY_CASH_PDF = gql `
query generatePettyCashPdf($pettyCashId: Int!){
  generatePettyCashPdf(pettyCashId: $pettyCashId)
}`;

export const TOTAL_AMOUNT_PETTY_CASH = gql `
query getTotalAmountForExpenseCategory{
  getTotalAmountForExpenseCategory{
    creditTotal
    debitTotal
    finalBalance
  }
}`;
