<section class="cmn-innerpage-wrapper manual-journal-new">
  <h2>New Journal</h2>
  <form [formGroup]="newJournalForm" (ngSubmit)="onSubmit()">
    <div class="manual-journal-form-wrapper">
      <div class="form-wrapper">
        <label for="">Date  <span class="red">*</span></label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="date"
            placeholder="Select Date"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>

          <mat-error
            *ngIf="
              newJournalForm.get('date')!.touched &&
              newJournalForm.get('date')!.hasError('required')
            "
            >Date is required.</mat-error
          >
        </mat-form-field>
      </div>

      <div class="form-wrapper">
        <label for="">Journal Number <span class="red">*</span></label>
        <mat-form-field>
          <input
            matInput
            placeholder="Journal Number"
            formControlName="journalNo"
            maxlength="25"
          />

          <mat-error
            *ngIf="
              newJournalForm.get('journalNo')!.touched &&
              newJournalForm.get('journalNo')!.hasError('required')
            "
            >Journal Number is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Reference <span class="red">*</span></label>
        <mat-form-field>
          <input
            matInput
            placeholder="Reference number"
            formControlName="reference"
            maxlength="25"
          />
          <!-- <mat-error
            *ngIf="
              newJournalForm.get('reference')!.touched &&
              newJournalForm.get('reference')!.hasError('required')
            "
            >Reference is required.</mat-error
          > -->
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Currency <span class="red">*</span></label>
        <mat-form-field>
          <mat-select
            placeholder="Select Currency"
            formControlName="currencyId"
          >
            <mat-option
              *ngFor="let currency of currencyData"
              [value]="currency.id"
            >
              {{ currency.currencyCode }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              newJournalForm.get('currencyId')!.touched &&
              newJournalForm.get('currencyId')!.hasError('required')
            "
            >Currency is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Narration <span class="red">*</span></label>
        <mat-form-field>
          <textarea
            matInput
            placeholder="max 500 characters"
            formControlName="notes"
            (blur)="onBlur($event)"
          ></textarea>
          <mat-error
            *ngIf="
              newJournalForm.get('notes')!.touched &&
              newJournalForm.get('notes')!.hasError('required')
            "
          >
            Notes is required.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="jounal-type-wrapper">
      <h3>Journal Type</h3>
      <div class="check-box">
        <label class="checkbox-wrapper">
          <input type="checkbox" formControlName="inCash" />Cash based journal
          <span class="checkmark"></span>
        </label>
        <mat-error
          *ngIf="
            newJournalForm.get('inCash')!.touched &&
            newJournalForm.get('inCash')!.hasError('required')
          "
          >Journal type is required.</mat-error
        >
      </div>
    </div>
    <div class="jounal-table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Account</th>
            <th>Description</th>
            <th>Contact</th>
            <th class="value-td">Debits</th>
            <th class="value-td">Credits</th>
          </tr>
        </thead>
        <tbody formArrayName="journalEntries">
          <tr
            *ngFor="let entry of journalEntries.controls; let i = index"
            [formGroupName]="i"
          >
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <mat-select
                    placeholder="Select an account"
                    formControlName="chartOfAccountId"
                  >
                    <mat-option
                      *ngFor="let account of accountData"
                      [value]="account.id"
                    >
                      {{ account.accountName }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      entry.get('chartOfAccountId')!.touched &&
                      entry.get('chartOfAccountId')!.hasError('required')
                    "
                  >
                    Account Name is required.
                  </mat-error>
                </mat-form-field>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="Enter description"
                    formControlName="description"
                  />
                </mat-form-field>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <mat-select
                    placeholder="Select Partner"
                    formControlName="partnerId"
                  >
                    <mat-option
                      *ngFor="let partner of partnersData"
                      [value]="partner.id"
                    >
                      {{ partner.displayName }}
                    </mat-option>
                  </mat-select>
                  <!-- <mat-error
                    *ngIf="
                      entry.get('partnerId')!.touched &&
                      entry.get('partnerId')!.hasError('required')
                    "
                  >
                    Partner is required.
                  </mat-error> -->
                </mat-form-field>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input
                    matInput
                    type="number"
                    formControlName="debit"
                    maxlength="30"
                  />
                  <mat-error
                    *ngIf="entry.get('debit')?.hasError('nonNegative')"
                  >
                    Debit cannot be negative.
                  </mat-error>
                </mat-form-field>
              </div>
            </td>
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input
                    matInput
                    type="number"
                    formControlName="credit"
                    maxlength="30"
                  />
                  <mat-error
                    *ngIf="entry.get('credit')?.hasError('nonNegative')"
                  >
                    Credit cannot be negative.
                  </mat-error>
                </mat-form-field>
              </div>
              <span class="delete">
                <img
                  src="../../../assets/images/icons/icon-close.svg"
                  alt="Delete"
                  (click)="onRemoveJournalEntry(entry.get('id')?.value, i)"
                />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <button type="button" (click)="addJournalEntry()">
        <img src="assets/images/icons/icon-add-color.svg" alt="Add" /> Add
      </button>
      <table class="table-2">
        <tr>
          <td colspan="3">Sub Total</td>
          <td class="value-td">{{ totalDebit | number }}</td>
          <td class="value-td">{{ totalCredit | number }}</td>
        </tr>
        <tr class="main-total">
          <td colspan="3">Total (AED)</td>
          <td class="value-td">{{ totalDebit | number }}</td>
          <td class="value-td">{{ totalCredit | number }}</td>
        </tr>
        <tr class="difference">
          <td colspan="3">Difference</td>
          <td class="value-td">{{ difference | number }}</td>
          <td class="value-td"></td>
        </tr>
      </table>
    </div>

    <div class="documents-wrapper">
      <h2>Documents</h2>
      <div class="upload-wrapper">
        <div class="cmn-upload-box">
          <h4>Upload Documents (optional)</h4>
          <input
            multiple
            #fileInput
            type="file"
            (change)="onFileSelected($event)"
            style="display: none"
            accept=".csv, .pdf, .jpg, .jpeg, .png, .txt, .doc"
          />
          <button
            class="cmn-choose-file-btn"
            (click)="fileInput.click()"
            type="button"
          >
            <img
              src="assets/images/icons/icon-add-color.svg"
              alt="Icon Add"
            />Choose a File
          </button>
          <div class="upload-info">
            <h5>
              You can upload a maximum of 10 files. Please upload a PNG, JPEG,
              DOC, or PDF file.
            </h5>
          </div>
        </div>
      </div>

      <div class="uploaded-file" *ngIf="fileDetailsArray.length > 0">
        <div *ngFor="let file of fileDetailsArray; let i = index">
          <h6>
            {{ file.fileName }}
            <img
              src="assets/images/icons/icon-close.svg"
              alt="Close Icon"
              (click)="removeFile(i)"
            />
          </h6>
        </div>
      </div>
    </div>

    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" type="button" (click)="onCancel()">
        Cancel
      </button>
      <button class="cmn-draft-btn" type="button" (click)="saveAsDraft()">
        Save as Draft
      </button>
      <button class="cmn-next-btn" type="button" (click)="save()">Save</button>
    </div>
  </form>
</section>