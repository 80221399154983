<mat-dialog-content>
    <section class="asset-add-assets-wrapper">
        <h2 class="cmn-popup-title">Dispose Asset</h2>
        <button class="cmn-close" mat-dialog-close></button>


        <ul>
            <li>Power Drill</li>
            <li>123456789</li>
            <li>Munawel Interior Decors</li>
        </ul>

        <div class="form-wrapper grid-form-wrapper">




            <div class="span-2">
                <mat-form-field>
                    <textarea matInput placeholder="Enter item description"></textarea>
                </mat-form-field>
            </div>


            <div class="cmn-Search-select-container">

                <mat-form-field>
                    <mat-select placeholder="Search Client" #singleSelect>
                        <mat-option>
                            <ngx-mat-select-search></ngx-mat-select-search>
                        </mat-option>

                        <mat-option value="Single">Client</mat-option>
                        <mat-option value="Married">Client</mat-option>
                    </mat-select>
                </mat-form-field>


            </div>


            <div class="">
                <mat-form-field>
                    <mat-select placeholder="Due on receipt" formControlName="maritalStatus">
                        <mat-option value="Single">Single</mat-option>
                        <mat-option value="Married">Married</mat-option>
                        <mat-option value="Divorced">Divorced</mat-option>
                        <mat-option value="Widowed">Widowed</mat-option> </mat-select>
                </mat-form-field>
            </div>


        </div>

        <div class="selected-profile-wrapper">
            <div>
                <div class="profile-image">
                    <img src="../../../assets/images/profile.png">
                </div>

                <span>rickson wilson</span>
                <div class="close-btn"><img src="../../../assets/images/icons/icon-close.svg" alt=""></div>
            </div>
        </div>


        <div class="btn-wrapper">

            <button class="cmn-next-btn ">
                Dispose Asset
            </button>

        </div>
    </section>
</mat-dialog-content>