<mat-dialog-content class="mat-typography">
    <section class="set-login-dialog-wrapper">
        <h2 class="cmn-popup-title">Portal Access</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <form [formGroup]="portalAccessForm">
            <div class="branch-form-wrapper">
                <div class="form-wrapper w-full">
                    <label for="">Username <span class="red">*</span></label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Username" formControlName="email"
                            (input)="forceLowercase('email')" />
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                              portalAccessForm.get('email')!.touched &&
                              portalAccessForm.get('email')!.hasError('required')
                            ">Email is required.</mat-error>
                        </span>
                        <span>
                            <mat-error *ngIf="portalAccessForm.get('email')?.errors?.['invalidEmail']">Please enter a
                                valid email
                                address.</mat-error>
                        </span>
                    </div>
                </div>
                <div class="form-wrapper w-full">
                    <label for="">Password</label>
                    <span class="check-password">
                        <div class="check-box">
                            <label class="checkbox-wrapper">
                                <input type="checkbox" (change)="toggleEdit($event)" />Edit
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </span>

                    <mat-form-field>
                        <input matInput placeholder="Enter Password" formControlName="password"
                            [disabled]="isPortalUserValue && !isEditChecked" />
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                              portalAccessForm.get('password')!.touched &&
                              portalAccessForm.get('password')!.hasError('required')
                            ">Password is required.</mat-error>
                        </span>
                    </div>
                </div>
                <div class="form-wrapper w-full">
                    <label for="">Profile <span class="red">*</span></label>
                    <mat-form-field>
                        <mat-select placeholder="Select Profile" formControlName="profileId">
                            <mat-option *ngFor="let profile of profiles"
                                [value]="profile.id">{{profile.profileName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                              portalAccessForm.get('profileId')!.touched &&
                              portalAccessForm.get('profileId')!.hasError('required')
                            ">Profile is required.</mat-error>
                        </span>
                    </div>
                </div>
                <div class="check-wrapper w-full">
                    <h2>Branch <span class="red">*</span></h2>
                    <div class="check-flex-wrapper">
                        <!-- <div class="check-box">
                            <label class="checkbox-wrapper">
                                <input type="checkbox" value="all" id="selectAllCheckbox" (change)="toggleAllBranches($event)" />All
                                <span class="checkmark"></span>
                            </label>
                        </div> -->
                        <div class="check-box" *ngFor="let branch of branches">
                            <label class="checkbox-wrapper">
                                <input type="checkbox" [value]="branch.id"
                                    [checked]="selectedBranches.includes(branch.id)"
                                    (change)="toggleBranch(branch.id, $event)" />
                                {{ branch.branchName }}
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                                portalAccessForm.get('branches')!.touched && 
                                portalAccessForm.get('branches')!.hasError('required')
                            ">At least one branch must be selected.</mat-error>
                        </span>
                    </div>
                </div>

            </div>
            <div class="btn-wrapper">
                <button class="cmn-reject-btn" type="button" *ngIf="isPortalUserValue"
                    (click)="onRemoveAccess(employeeId)">
                    <span *ngIf="!loading">Remove Access</span>
                    <span *ngIf="loading">Loading...</span>
                </button>
                <button class="cmn-next-btn" type="button" (click)="createPortalAccess()">
                    <span *ngIf="!loading">Save</span>
                    <span *ngIf="loading">Loading...</span>
                </button>
            </div>
        </form>
    </section>
</mat-dialog-content>