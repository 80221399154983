<mat-dialog-content>
    <section class="leave-edit-dialog-wrapper">
      <h2 class="cmn-popup-title">Edit Account</h2>
      <button class="cmn-close" mat-dialog-close></button>
      <form [formGroup]="accountEditForm">
      <div>
        <div class="leave-edit-form-wrapper">
            <div class="form-wrapper">
                <label for="">Account type</label>
                <mat-form-field>
                    <mat-select placeholder="Select Account" formControlName="accountType">
                        <mat-option *ngFor="let account of accounts" value={{account?.accountName}}>{{account?.accountName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-wrapper">
                <label for="">Account name</label>
                <mat-form-field>
                    <input matInput placeholder="Enter name" formControlName="accountName"/>
                </mat-form-field>
                <mat-checkbox [checked]= "isSubAccount" (change)="toggleSubAccount($event.checked)">Make this a sub-account</mat-checkbox>
            </div>
               <div class="form-wrapper" *ngIf="isSubAccount">
                <label for="">Parent Account</label>
                <mat-form-field>
                    <mat-select placeholder="Select Account" formControlName="parentAccountId">
                        <mat-option *ngFor="let chartAccount of chartAccounts" [value]="chartAccount?.id">{{chartAccount?.accountName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-wrapper">
                <label for="">Account Code</label>
                <mat-form-field>
                    <input matInput placeholder="Enter code" formControlName="accountCode"/>
                </mat-form-field>
            </div>
            <div class="form-wrapper">
                <label for="">Description</label>
                <mat-form-field>
                    <textarea matInput placeholder="max 500 characters" formControlName="description"></textarea>
                </mat-form-field>
            </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <button type="submit" class="cmn-popup-btn" (click)="onEdit()">Submit</button>
      </div>
    </form>
    </section>
  </mat-dialog-content>