import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PreferenceService {
    constructor(
        private apollo: Apollo
    ) { }

    preferenceCodes(search: any, type: any): Observable<any> {
        return this.apollo
            .use('project')
            .query<any>({
                query: gql`
                    query preferenceCodes($search: String, $type: String) {
                        preferenceCodes(search: $search, type: $type) {
                            id
                            category
                            prefix
                            status
                            createdAt
                            updatedAt
                            deletedAt
                        }
                    }
                `,
                variables: {
                    search,
                    type
                },
                fetchPolicy: 'network-only'
            })
            .pipe(
                map((result) => result.data?.preferenceCodes || [])
            );
    }
    

}
