
    <section class="cmn-innerpage-wrapper payroll-list">
        <div class="head">
            <h2>The Overview page gives you a summary of the current month’s payroll. You can also view details related
                to the Net Payout Amount for the chosen payroll month.</h2>
            <!-- <button class="cmn-settings-btn" (click)="navigateTo('/payroll-settings')">
                <img src="assets/images/icons/icon-settings.svg" alt="Add icon" /> Payroll Settings
            </button> -->
        </div>
        <div class="payroll-tab-wrapper">
            <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedTabIndex"
                (selectedTabChange)="onTabChange($event)">
                <mat-tab *ngFor="let month of months">
                    <ng-template mat-tab-label>
                        <h2 class="cmn-tab" [ngClass]="{'locked': month.isLocked, 'unlocked': month.isUnlocked}">
                            <span>{{month?.name}}</span><span class="sub-text">{{month?.year}}</span>
                        </h2>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="payroll-head">
            <div class="left-set">
                <h2>{{selectedMonth + ' ' + selectedYear }}</h2>
                <h3>Cutoff from {{startDate | date: 'dd MMM yyyy' }} to {{endDate | date: 'dd MMM yyyy' }}</h3>
            </div>
            <div class="right-set">
                <button [ngClass]="{ 'disabled-button': lockInputs }" (click)="initiatePayroll()" [disabled]="(lockInputs)">Initiate Payroll</button>
                <button [ngClass]="{ 'disabled-button': !lockInputs || lockPayroll }" (click)="openDialog()" [disabled]="(!lockInputs || lockPayroll)">Process Payroll</button>
            </div>
        </div>
        <div class="payroll-dashboard">
            <div class="payroll-details">
                <div class="details-wrapper">
                    <div class="details">
                        <h3>Payroll Details</h3>
                        <div class="payroll-info">
                            <div class="info-box">
                                <h4>Net Pay</h4>
                                <h5>AED <span>{{ payrollData?.netPay || '--'}}</span></h5>
                            </div>
                            <div class="info-box">
                                <h4>Gross Pay</h4>
                                <h5>AED <span>{{ payrollData?.grossPay || '--'}}</span></h5>
                            </div>
                            <div class="info-box">
                                <h4>Deductions</h4>
                                <h5>AED <span>{{ payrollData?.deductions || '--' }}</span></h5>
                            </div>
                            <div class="info-box">
                                <h4>Work days</h4>
                                <h5><span>{{ payrollData?.workDays || '--' }}</span></h5>
                            </div>
                        </div>
                        <!-- <div class="graph-info">
                            <h4 class="net-pay">Net Pay</h4>
                            <h4 class="gross-pay">Gross Pay</h4>
                            <h4 class="deduction">Deduction</h4>
                        </div> -->
                    </div>
                    <div class="graph" *ngIf="chartOptions?.series && payrollData; else noData">
                        <apx-chart 
                          [series]="chartOptions.series" 
                          [chart]="chartOptions.chart"
                          [labels]="chartOptions.labels" 
                          [responsive]="chartOptions.responsive"
                          [colors]="chartOptions.fill.colors">
                        </apx-chart>
                      </div>
                      <ng-template #noData>
                        <p>No payroll data available to display the chart.</p>
                      </ng-template>
                </div>
            </div>
            <div class="right-set">
                <div class="employee-details">
                    <h3>Employee Details</h3>
                    <div class="employee-wrap">
                        <div class="box">
                            <h4>Total Employees</h4>
                            <h5>{{ payrollData?.totalEmployees || '--' }}</h5>
                        </div>
                        <div class="box">
                            <h4>Additions</h4>
                            <h5>{{ payrollData?.additions || '--' }}</h5>
                        </div>
                        <div class="box">
                            <h4>Seperations</h4>
                            <h5>{{ payrollData?.seperations || '--' }}</h5>
                        </div>
                    </div>
                </div>
                <div class="payroll-toggle">
                    <div class="toggle-box">
                        <h3>Payroll Inputs</h3>
                        <mat-button-toggle-group aria-label="Lock Inputs" [value]="lockInputs" [disabled]="lockPayroll || lockInputs === null ||isProcessedPayroll || payrollData?.isProcessed">
                            <mat-button-toggle [value]="false"
                                (change)="onToggleLockInputs(false)">Unlock</mat-button-toggle>
                            <mat-button-toggle [value]="true"
                                (change)="onToggleLockInputs(true)">Lock</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div class="toggle-box">
                        <h3>Payroll</h3>
                        <mat-button-toggle-group aria-label="Lock Payroll" [value]="lockPayroll" [disabled]="!lockInputs || lockPayroll === null || !payrollData?.isProcessed">
                            <mat-button-toggle [value]="false"
                                (change)="onToggleLockPayroll(false)">Unlock</mat-button-toggle>
                            <mat-button-toggle [value]="true"
                                (change)="onToggleLockPayroll(true)">Lock</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>

            </div>
        </div>
        <div class="payroll-list-main-wrapper">
            <div class="main-list-head">
                <h3>Employee Salary Details</h3>
                <button (click)="navigateToPayrollList()">View All</button>
            </div>

            <div class="list-head">
                <!-- <div class="search-wrapper">
                    <input type="text" class="cmn-header-search" placeholder="Search" />
                </div>
                <button class="cmn-filter-btn" (click)="toggleDropdown($event)"
                    [ngClass]="{ 'class-clicked': isDropdownOpen }">
                    <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
                </button> -->
                <!-- Filter Popup -->
                <div [ngClass]="{ show: isDropdownOpen }" class="payroll-only-filter-dropdown filter-position"
                    #dropdown>
                    <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                    <form [formGroup]="statusForm">
                        <h2>Payroll Status</h2>
                        <div class="check-wrapper">
                            <div class="check-box">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" formControlName="pending" />
                                    Pending
                                    <span class="checkmark"></span>
                                </label>
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" formControlName="completed" />
                                    Completed
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div class="btn-wrapper">
                            <button class="cmn-apply-btn" (click)="applyFilter()">Apply</button>
                        </div>
                    </form>

                </div>
                <!-- Filter Popup -->
            </div>
            <div class="table-wrap">
                <app-payroll-list-table [filter]="filter" [selectedMonth]= "selectedMonth" [selectedYear]= "selectedYear"></app-payroll-list-table>
            </div>
        </div>
    </section>
