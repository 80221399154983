import { Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { RolesService } from '../../features/roles/services/roles.service';
import { CapitalizePipe } from '../../shared/pipes/capitalize.pipe';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogRef } from '@angular/cdk/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-roles',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    AgGridAngular
  ],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.scss',
  providers: [CapitalizePipe]
})
export class RolesComponent {
  isDropdownOpen = false;
  readonly dialog = inject(MatDialog);
  public rowData: any[] | null = null;
  public viewPortSize: boolean = false;
  search: string;

  constructor(
    private rolesService: RolesService,
    private capitalizePipe: CapitalizePipe,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getProfiles();
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }



  columnDefs = [
    {
      headerName: 'Role Name',
      field: 'profileName',
      flex: 1,

    },
    { headerName: 'Description', field: 'description', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const editIcon = document.createElement('img');
        editIcon.src = 'assets/images/icons/icon-clone.svg';
        editIcon.style.cursor = 'pointer';
        editIcon.setAttribute('data-action', 'edit');
        // editIcon.addEventListener('click', (event) => {
        //   event.stopPropagation();
        //   this.onEdit(params?.data?.id, params?.data);
        // });

        const deleteIcon = document.createElement('img');
        deleteIcon.src = 'assets/images/icons/icon-delete.svg';
        deleteIcon.style.cursor = 'pointer';
        deleteIcon.setAttribute('data-action', 'delete');
        // deleteIcon.addEventListener('click', (event) => {
        //   event.stopPropagation();
        //   this.onConfirmDelete(params?.data?.id);
        // });

        container.appendChild(editIcon);
        container.appendChild(deleteIcon);
        return container;
      },
      floatingFilter: false,
      filter: false,
    },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 20;
  public paginationPageSizeSelector: number[] | boolean = [20, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  getProfiles() {
    this.rolesService.profiles(this.search).subscribe({
      next: (data) => {
        this.rowData = data
          .map((data: any) => {
            return {
              id: data?.id || '--',
              profileName: this.capitalizePipe.transform(data?.profileName) || '--',
              description: data?.description || '--',
              cloneProfile: data?.profileId || '--',
            };
          })
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  openDialog() {
    const dialogRef = this.dialog.open(RolesDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getProfiles();
    });
  }

  onConfirmDelete(id: any): void {
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onDeleteProfile(id);
      }
    });
  }

  onDeleteProfile(id: any) {
    if (id) {
      this.rolesService.removeProfile(Number(id)).subscribe({
        next: (data) => {
          if (data) {
            this.toastr.success('Profile removed successfully', 'Success');
            this.getProfiles();
          }
        },
        error: (error) => {
          this.toastr.error(error, 'Error')
        }
      })
    }
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.search = input.value;
    this.getProfiles();
  }

  cloneProfile(data: any){
    const dialogRef = this.dialog.open(RolesDialog, {
      disableClose: true,
      data: { 
        profileName: data?.profileName,
        profileId: data?.id,
        description: data?.description
       }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getProfiles();
    });
  }
  
  onCellClicked(params: any) {
    const profileId = params.data.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.cloneProfile(params?.data);
      } else if (action === 'delete') {
        this.onConfirmDelete(params?.data?.id);
      }
    } else {
      this.router.navigate([`/roles/roles-management/${profileId}`]);
    }
  }

}


@Component({
  selector: 'add-profile-dialog',
  templateUrl: 'add-profile-dialog.html',
  styleUrls: ['./roles.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule
  ],
})
export class RolesDialog {
  addProfileForm: FormGroup;
  search: any;
  profiles: any;

  constructor(
    private fb: FormBuilder,
    private rolesService: RolesService,
    private dialogRef: DialogRef,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
    
    if(!this.data){
      this.addProfileForm = this.fb.group({
        profileName: ['', Validators.required],
        // profileId: [''],
        description: [''],
      })
    } else {
      this.addProfileForm = this.fb.group({
        profileName: [''],
        profileId: [this.data?.profileId],
        description: ['']
      })
    }
    this.getProfiles();
  }

  getProfiles() {
    this.rolesService.profiles(this.search).subscribe({
      next: (data) => {
       this.profiles  = data;
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  addProfile() {
    if (this.addProfileForm.invalid) {
      this.addProfileForm.markAllAsTouched();
      return;
    }
    if(!this.data){
      const profileData = this.addProfileForm.value;
      this.rolesService.createProfile(profileData).subscribe({
        next: (data) => {
          this.toastr.success('Profile created successfully', 'Success');
          this.dialogRef.close();
        },
        error: (error) => {
          this.toastr.error(error, 'Error')
        }
      })
    } if(this.data){
      const profileData = this.addProfileForm.value;
      const description = profileData?.description
      const profileName = profileData?.profileName
      const profileId = profileData?.profileId
      console.log(profileData);
      
      this.rolesService.cloneProfile(Number(profileId), profileName, description).subscribe({
        next: (data) => {
          this.toastr.success('Profile created successfully', 'Success');
          this.dialogRef.close();
        },
        error: (error) => {
          this.toastr.error(error, 'Error')
        }
      })
    }

  }
}
