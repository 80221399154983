<mat-dialog-content>
    <section class="project-transfer-item-wrapper">
        <h2 class="cmn-popup-title">Item Details</h2>
        <button class="cmn-close" mat-dialog-close></button>


      <div class="form-main-content-wrapper">

            <ul>
                <li>
                    <p>Projects</p>
                    <h6>Dans Interior Design</h6>
                </li>
                <li>
                    <p>Milestone</p>
                    <h6>Interior Painting</h6>
                </li>
                <li>
                    <p>Man Power</p>
                    <h6>Sr. Supervisor</h6>
                </li>
                <li>
                    <p>Duration</p>
                    <h6>28/08/24 - 15/09/24</h6>
                </li>
                <li>
                    <p>Expected Amount</p>
                    <h6>AED 5,000 </h6>
                </li>
                <li>
                    <p>Remarks</p>
                    <h6>Painting for 105 sq.ft on the 2nd floor of the building</h6>
                </li>
            </ul>

            <div class="form-wrapper  grid-wrapper">
    
                <label for="">
                  <span> Transfer To</span>
                    <mat-form-field>
                        <mat-select placeholder="Select Inventory" >
                          <mat-option value="Single">Item 1</mat-option>
                          <mat-option value="Married">Item 2</mat-option>
                        </mat-select>
                      </mat-form-field>
                </label>
                <label for="">
                  <span> Transfer Note</span>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter Transfer note"></textarea>
                    </mat-form-field>
                </label>
    
            </div>
    
      </div>

        <button class="cmn-next-btn ">
            Transfer Item
        </button>
    </section>
</mat-dialog-content>