<mat-dialog-content class="mat-typography">
  <section class="add-branch-dialog-wrapper">
    <h2 class="cmn-popup-title">{{departmentsData?.id ? 'Edit':'Add'}} Department</h2>
    <button class="cmn-close" mat-dialog-close></button>
    <form [formGroup]="departmentForm" (ngSubmit)="onAddBranch()">
      <div class="branch-form-wrapper">
        <div class="form-wrapper">
          <label for="">Department Name</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter Department Name"
              formControlName="departmentName"
            />
            <mat-error
              *ngIf="departmentForm.get('departmentName')!.touched && departmentForm.get('departmentName')!.hasError('required')"
              >Department Name is required.</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="btn-wrapper">
        <button class="cmn-next-btn" type="submit" [disabled]="loading">
          <span *ngIf="!loading">{{departmentsData?.id ? 'Save':'Add Department'}}</span>
          <span *ngIf="loading">Loading...</span>
        </button>
      </div>
    </form>
  </section>
</mat-dialog-content>
