<mat-dialog-content class="mat-typography">
    <section class="payroll-adjustment-dialog-wrapper">
        <h2 class="cmn-popup-title">Add {{data.deduction ? 'Deduction' : 'Bonus'}}</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <!-- form div -->
        <form [formGroup]="addAmountForm">
        <div>
            <div class="payroll-form-wrapper">
                <div class="form-wrapper">
                  <label>{{data.deduction ? 'Deduction Type' : 'Bonus Type'}} <span>*</span></label>
                    <mat-form-field>
                      <input matInput 
                      [placeholder]="data.deduction ? 'Deduction Type' : 'Bonus Type'" 
                      [formControlName]="data.deduction ? 'deductionType' : 'bonusType'" 
                      maxlength = "25"
                       />
                    </mat-form-field>
                    <mat-error *ngIf="
                    addAmountForm.get(data.deduction ? 'deductionType' : 'bonusType')!.touched &&
                    addAmountForm.get(data.deduction ? 'deductionType' : 'bonusType')!.hasError('required')
                  "> {{data.deduction ? 'Deduction' : 'Bonus'}} type is required.</mat-error>
                  <mat-error
                  *ngIf=
                  "addAmountForm.get(data.deduction ? 'deductionType' : 'bonusType')!.touched &&
                  addAmountForm.get(data.deduction ? 'deductionType' : 'bonusType')!.hasError('spaceError')"
                >
                  Cannot start or end with a space.
                </mat-error>
                  </div>
                <div class="form-wrapper">
                  <label>Description</label>
                    <mat-form-field>
                      <textarea matInput placeholder="Type something.." formControlName="description"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                  <label>Amount <span>*</span></label>
                    <mat-form-field>
                        <input matInput placeholder="Amount" formControlName="amount" maxlength = "8"/>
                    </mat-form-field>
                    <mat-error *ngIf="
                    addAmountForm.get('amount')!.touched &&
                    addAmountForm.get('amount')!.hasError('required')
                  "> Amount is required.</mat-error>
                    <mat-error *ngIf="
                    addAmountForm.get('amount')!.touched &&
                    addAmountForm.get('amount')!.hasError('nonNegative')
                  ">
                        Enter a valid amount.
                    </mat-error>
                    <mat-error *ngIf="
                    addAmountForm.get('amount')!.touched &&
                    addAmountForm.get('amount')!.hasError('pattern')
                  ">
                        Only accepts numeric values.
                    </mat-error>
                </div>
            </div>
        </div>
      </form>
        <div class="btn-wrapper">
            <button type="submit" class="cmn-popup-btn" [disabled]="loading" (click)="onSubmit()">
              {{ loading ? "Loading..." : "Submit" }}
            </button>
          </div>
        <!-- form div -->
    </section>
</mat-dialog-content>