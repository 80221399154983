import {
  Component,
  inject,
  HostListener,
  ElementRef,
  OnInit,
  Inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, NumberFilter } from 'ag-grid-community';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { EmployeeService } from '../../features/employees/services/employee.service';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { ImageService } from '../../shared/services/image.service';
import { UploadFileService } from '../../shared/services/file-upload.service';
import { DialogRef } from '@angular/cdk/dialog';
import { FileExportService } from '../../shared/services/file-export.service';
import { AuthService } from '../../core/services/auth.service';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';
import * as selectPermissions from '../../core/permissions/permission.selector';
import { Store } from '@ngrx/store';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Apollo } from 'apollo-angular';
import { PortalAccessDialog } from './portral-access-dialog/portal-access-dialog.component';

@Component({
  selector: 'app-employees',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    AgGridAngular,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    PermissionDirective
  ],
  templateUrl: './employees.component.html',
  styleUrl: './employees.component.scss',
})
export class EmployeesComponent implements OnInit {
  public viewPortSize: boolean = false;

  isDropdownOpen = false;
  isPortalUser: boolean = false;
  selectedCount: number = 0;
  exportLoading: boolean = false;
  isExportOpen = false;
  public rowData: any[] | null = null;
  public loading: boolean = true;
  public error: any = null;
  public branchData: any[] = [];
  public employeeTypeData: any[] = [];
  public designationData: any[] = [];
  private userData: any[];
  exportFormat: string = 'pdf';
  Permission = Permission;

  selectedFields: string[] = [];

  filterForm: FormGroup;
  exportForm: FormGroup;
  userAvatar: any;
  columnDefs: ({ headerName: string; field: string; flex: number; cellRenderer: (params: any) => string; floatingFilter?: undefined; filter?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer?: undefined; floatingFilter?: undefined; filter?: undefined; } | { field: string; headerName: string; flex: number; floatingFilter: boolean; filter: boolean; cellRenderer: (params: any) => HTMLDivElement; })[];
  profileName: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {

    this.columnDefs = [
      {
        headerName: 'Employee Name',
        field: 'employeeName',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          return `
          <div style="display: flex; align-items: center;">
            <img src="${params.data.profilePicture}" alt="Profile Image" style="width: 35px; height: 35px; margin-right: 10px; border-radius: 50%; object-fit: cover;">
            <span>${params.data.employeeName}</span>
          </div>
        `;
        },
      },
      { headerName: 'Employee Code', field: 'employeeCode', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Branch Name', field: 'branchName', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Designation', field: 'designation', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Type', field: 'employeeType', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Status',
        field: 'employeeStatus',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const status = params.data.employeeStatus;
          const statusClass = status === 'Working' ? 'working' : 'on-leave';
          return `<span class="${statusClass}">${status}</span>`;
        },
      },
      {
        field: 'action',
        headerName: 'Actions',
        flex: this.viewPortSize ? 1 : 0,
        floatingFilter: false,
        filter: false,
        cellRenderer: (params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.alignItems = 'center';
          container.style.gap = '20px';

          const deleteIcon = document.createElement('img');
          deleteIcon.src = 'assets/images/icons/icon-delete.svg';
          deleteIcon.style.cursor = 'pointer';
          deleteIcon.setAttribute('matTooltip', 'Delete');
          deleteIcon.addEventListener('click', (event) => {
            event.stopPropagation();
            this.onDeleteEmployee(params.data.employeeId);
          });


          // if(this.profileName === 'admin'){
          //   container.appendChild(deleteIcon);
          // }

          this.store.select(selectPermissions.hasPermission('21', Permission.Delete)).subscribe((canDelete) => {
            if (canDelete) {
              container.appendChild(deleteIcon);
            }
          });

          const setLogin = document.createElement('img');
          setLogin.src = 'assets/images/icons/icon-set-login.svg';
          setLogin.src = 'assets/images/icons/icon-view-login.svg';
          setLogin.style.cursor = 'pointer';
          setLogin.addEventListener('click', (event) => {
            event.stopPropagation();
            this.openPortalAccessDialog(params.data.employeeId);
          });
          container.appendChild(setLogin);

          if (this.profileName === 'admin') {
            container.appendChild(setLogin);
          }

          return container;
        },
      },
    ];
  }

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10];
  public themeClass: string = 'ag-theme-quartz';

  readonly dialog = inject(MatDialog);
  private dataSubscription: Subscription;


  private searchSubject = new Subject<string>();

  constructor(
    private elementRef: ElementRef,
    private employeeService: EmployeeService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private imageService: ImageService,
    private fileExportService: FileExportService,
    private authService: AuthService,
    private store: Store,
  ) {
    this.filterForm = this.fb.group({
      branches: this.fb.array([]),
      employeeTypes: this.fb.array([]),
      employeeExit: [false], // Default to false
    });

    this.exportForm = this.fb.group({
      fields: this.fb.array([]),
    });

    const loggedInUser: any = localStorage.getItem('loggedInUser');
    const userData = JSON.parse(loggedInUser);
    this.userData = userData;
    console.log("Isportal user value:", userData?.employeeData?.isPortalUser);
    this.isPortalUser = userData?.employeeData?.isPortalUser;
    this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit(): void {
    this.loadEmployees();

    this.filterForm = this.fb.group({
      branches: this.fb.array([]),
      employeeTypes: this.fb.array([]),
      employeeExit: [false], // Default to false
    });
    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.loadEmployees(searchTerm);
      });

    this.loadBranchData();
    this.loadEmployeeTypeData();
    this.loaddesignations();
    this.checkViewportSize();

    // this.filterForm.get('employeeExit')?.valueChanges.subscribe(() => {
    //   this.updateSelectedCount();
    // });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  updateSelectedCount(): void {
    const formValue = this.filterForm.value;
    const branchesSelected = formValue.branches.filter(
      (selected: boolean) => selected
    ).length;
    const employeeTypesSelected = formValue.employeeTypes.filter(
      (selected: boolean) => selected
    ).length;

    const employeeExitSelected = formValue.employeeExit ? 1 : 0;

    this.selectedCount = branchesSelected + employeeTypesSelected + employeeExitSelected;
  }

  onCheckboxChange() {
    this.updateSelectedCount();
  }

  clearFilter() {
    this.filterForm.reset({
      branches: [],
      employeeTypes: [],
      employeeExit: false,
    });
    this.selectedCount = 0;
    this.loadEmployees();
    // this.toggleDropdown();
  }


  private loadEmployees(search: string = '', filter: any = { employeeExit: false }) {
    this.loading = true;

    this.employeeService.getEmployees(search, filter).subscribe({
      next: (employees) => {
        const filteredEmployees = employees.filter((employee: any) => {
          const branchMatch =
            !filter.branchNames?.length ||
            filter.branchNames.includes(employee?.branch?.branchName);
          const employeeTypeMatch =
            !filter.employeeTypeNames?.length ||
            filter.employeeTypeNames.includes(employee?.employeeType);

          return branchMatch && employeeTypeMatch;
        });

        const employeePromises = filteredEmployees.map(
          async (employee: any) => {
            let profilePicture =
              employee?.profilePicture ||
              'assets/images/dummy-profile-image.jpg';

            if (employee?.profilePicture) {
              try {
                profilePicture = await this.imageService.getImageAsBase64(
                  employee.profilePicture
                );
              } catch (error) {
                profilePicture = 'assets/images/dummy-profile-image.jpg';
              }
            }

            return {
              employeeName: `${employee?.firstName || '--'} ${employee?.lastName || ''
                }`,
              employeeId: employee?.id || '--',
              employeeCode: employee?.employeeCode || '--',
              profilePicture: profilePicture,
              branchName: employee?.branch?.branchName || '--',
              designation: employee?.designation?.designationName || '--',
              employeeType: employee?.employeeType || '--',
              employeeStatus: employee?.employeeStatus || '--',
              employeeData: employee,
            };
          }
        );

        Promise.all(employeePromises).then((resolvedEmployees) => {
          this.rowData = resolvedEmployees;

          // Dynamically set pagination options based on the data size
          this.paginationPageSizeSelector = PaginationHelper.getPaginationPageSizeOptions(this.rowData);
          this.loading = false;
        });
      },

      error: (error) => {
        this.toastr.error(error.message ||
          'Failed to load employees. Please try again.'
        );
        console.log('Error fetching employees', error);
        this.loading = false;
      },
    });
  }

  onDeleteEmployee(employeeId: number): void {
    const loggedInUser = this.authService.getLoggedInUser();
    const loggedInUserId = loggedInUser.employeeData.id;
    if (employeeId === loggedInUserId) {
      this.toastr.error('You cannot delete your own account.');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteEmployee(employeeId);
      }
    });
  }

  private deleteEmployee(employeeId: number): void {
    this.employeeService.removeEmployee(employeeId).subscribe({
      next: () => {
        this.toastr.success('Employee deleted successfully!');
        this.router.navigate(['/employees']);
        this.loadEmployees();
      },
      error: () => {
        this.toastr.error(
          'Failed to delete employee. Please try again.'
        );
      },
    });
  }

  onRowClicked(event: any) {
    const employeeId = event.data.employeeId;
    this.router.navigate([`/employees/detail/${employeeId}`]);
  }

  get branchesFormArray() {
    return this.filterForm.get('branches') as FormArray;
  }

  // Create a getter for the export form fields array
  get exportFieldsArray() {
    return this.exportForm.get('fields') as FormArray;
  }

  onFieldCheckboxChange(event: any, fieldName: string) {
    const fieldsArray: FormArray = this.exportForm.get('fields') as FormArray;

    if (event.target.checked) {
      fieldsArray.push(new FormControl(fieldName));
    } else {
      const index = fieldsArray.controls.findIndex(
        (control) => control.value === fieldName
      );
      if (index >= 0) {
        fieldsArray.removeAt(index);
      }
    }
  }

  exportEmployees() {
    const selectedFields = this.exportForm.value.fields;

    if (selectedFields.length === 0) {
      this.toastr.error('Please select at least one field to export.');
      return;
    }

    let exportObservable;

    // Choose the export API based on the selected format
    switch (this.exportFormat) {
      case 'pdf':
        exportObservable =
          this.employeeService.exportEmployeesToPDF(selectedFields);
        break;
      case 'xls':
        exportObservable =
          this.employeeService.exportEmployeesToXLSX(selectedFields);
        break;
      case 'csv':
        exportObservable =
          this.employeeService.exportEmployeesToCSV(selectedFields);
        break;
      default:
        this.toastr.error('Invalid export format selected.');
        return;
    }

    this.exportLoading = true;
    // Call the export service method
    exportObservable.subscribe({
      next: (response: Blob) => {
        this.exportLoading = false;
        this.getFile(response, this.exportFormat);
      },
      error: (error) => {
        this.exportLoading = false;
        this.toastr.error(
          'Failed to export employees. Please try again.'
        );
      },
    });
  }

  async getFile(response: any, format: string) {
    try {
      const file = await this.fileExportService.getFileUrlPost(
        response,
        format
      );
    } catch (error) {
      console.error('Error exporting file', error);
    }
  }

  private addControls(formArrayName: string, data: any[]) {
    const formArray = this.filterForm.get(formArrayName) as FormArray;
    formArray.clear();

    data.forEach(() => {
      formArray.push(new FormControl(false));
    });
  }

  private loadBranchData() {
    this.employeeService.getBranches().subscribe({
      next: (branches) => {
        this.branchData = branches;
        this.addControls('branches', this.branchData);
      },
      error: (error) => console.error(error),
    });
  }

  private loadEmployeeTypeData() {
    this.employeeService.getEmployeeTypes().subscribe({
      next: (employeeTypes) => {
        this.employeeTypeData = employeeTypes;
        this.addControls('employeeTypes', this.employeeTypeData);
      },
      error: (error) => console.error('Error fetching employee types:', error),
    });
  }

  private loaddesignations() {
    this.employeeService.getDesignations().subscribe({
      next: (designations) => {
        this.designationData = designations;
        this.addControls('designation', this.designationData);
      },
      error: (error) => console.error('Error fetching designations:', error),
    });
  }
  get employeeExitControl() {
    return this.filterForm.get('employeeExit');
  }

  onSubmit() {
    const selectedBranches = this.filterForm.value.branches
      .map((checked: boolean, i: number) =>
        checked ? this.branchData[i].branchName : null
      )
      .filter((value: string | null) => value !== null);

    const selectedEmployeeTypes = this.filterForm.value.employeeTypes
      .map((checked: boolean, i: number) =>
        checked ? this.employeeTypeData[i].employeeType : null
      )
      .filter((value: string | null) => value !== null);

    const filters = {
      branch: selectedBranches,
      employeeType: selectedEmployeeTypes,
      employeeExit: this.filterForm.value.employeeExit
    };

    this.loadEmployees('', filters);
    this.toggleDropdown();
  }

  navigateToAddEmployee() {
    this.router.navigate(['/add-employee']);
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadEmployeesDialog);
    dialogRef.afterClosed().subscribe((data) => {
      this.loadEmployees();
    });
  }

  openPortalAccessDialog(employeeId: any) {
    console.log("employee id:", employeeId);
    const dialogRef = this.dialog.open(PortalAccessDialog, {
      data: {
        employeeId: employeeId
      }
    });

    dialogRef.afterOpened().subscribe(() => console.log('Dialog opened'));
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleExport(event: MouseEvent) {
    event.stopPropagation();
    this.isExportOpen = !this.isExportOpen;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      this.isExportOpen = false;
    }
  }

  handleDropdownClick(event: MouseEvent) {
    event.stopPropagation(); // Prevents click from propagating to the document
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe(); // Clean up subscription
    }
  }
}

const gridDiv = document.querySelector<HTMLElement>('#myGrid')!;

@Component({
  selector: 'upload-employees-dialog',
  templateUrl: 'upload-employees-dialog.html',
  styleUrls: ['./employees.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class UploadEmployeesDialog {
  file: any = null;
  url: any = null;
  uploadForm: FormGroup;
  loading: boolean = false;

  constructor(
    private documentUploadService: UploadFileService,
    private toastr: ToastrService,
    private employeeService: EmployeeService,
    private dialogRef: DialogRef,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.uploadForm = this.fb.group({
      file: [null, Validators.required],
    });
    document.addEventListener('dragover', this.preventDefaultBehavior);
    document.addEventListener('drop', this.preventDefaultBehavior);
  }

  preventDefaultBehavior(event: DragEvent) {
    event.preventDefault();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer?.files.length) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }

  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
    this.uploadForm.get('file')!.updateValueAndValidity();
  }

  uploadDocuments(csvFile: any) {
    this.loading = true;
    console.log('inside');

    this.employeeService.bulkCreateEmployees(csvFile).subscribe({
      next: (response) => {
        this.loading = false;
        if (response?.data) {
          this.toastr.success('Employees updated successfully!', 'Success');
          this.dialogRef.close();
        } else {
          this.toastr.error(response.errors[0].message, 'Error');
          this.dialogRef.close();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Bulk employee creation failed');
        this.dialogRef.close();
        this.loading = false;
      },
    });
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
    this.uploadForm.get('file')!.updateValueAndValidity();
  }

  onUpload() {
    if (this.uploadForm.invalid && !this.file) {
      this.uploadForm.markAllAsTouched();
      return;
    }
    this.uploadDocuments(this.file);

  }

  ngOnDestroy() {
    document.removeEventListener('dragover', this.preventDefaultBehavior);
    document.removeEventListener('drop', this.preventDefaultBehavior);
  }
}