<section class="cmn-innerpage-wrapper inventory ag-grid-type-1">
  <div class="head">
    <div class="search-wrapper">
      <input type="text" class="cmn-header-search" placeholder="Search" />
    </div>
    <button
      class="cmn-add-btn"
      (click)="navigateTo('/warehouse/warehouse-new')"
    >
      <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add New
    </button>
  </div>
  <div class="inner-content">
    <ag-grid-angular
      style="width: 100%"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowSelection]="rowSelection"
      [suppressRowClickSelection]="true"
      [pagination]="true"
      [paginationPageSize]="paginationPageSize"
      [paginationPageSizeSelector]="paginationPageSizeSelector"
      [class]="themeClass"
      (cellClicked)="onCellClicked($event)"
    />
  </div>
</section>
