<section class="cmn-innerpage-wrapper expense-details">
  <div class="head">
    <div class="search-wrapper">
      <input type="text" class="cmn-header-search" placeholder="Search" />
    </div>
    <button class="cmn-upload-btn" (click)="openDialog()">
      <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
      <span>Upload</span>
    </button>
    <button class="cmn-add-btn" (click)="navigateTo('/expense/expense-new')">
      <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add Expense
    </button>
  </div>
  <div class="sub-head">
    <ul>
      <li class="main">Expenses</li>
      <li class="edit" (click)="onEditNavigateTo('/expense/expense-new')">
        <img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit" /> Edit
      </li>
      <li
        class="edit"
        (click)="navigateTo('/expense/expense-new')"
        *ngIf="expenseDetails?.expenseStatus === 'Unbilled'"
      >
        <img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit" />
        Convert to Invoice
      </li>
    </ul>
    <div class="mat-menu-wrapper">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <!-- <button mat-menu-item>
          <span>Mark as Sent</span>
        </button>
        <button mat-menu-item>
          <span>Credit Available</span>
        </button>
        <button mat-menu-item>
          <span>Create Credit Note</span>
        </button>
        <button mat-menu-item>
          <span>Credit Applied</span>
        </button>
        <button mat-menu-item>
          <span>Clone</span>
        </button> -->
        <button mat-menu-item (click)="onConfirmDelete(expenseDetails?.id)">
          <span>Delete</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="manual-journal-details-tab-wrapper">
    <div class="tab-left">
      <div class="tab-box-main-wrapper">
        <button
          class="tab-custom-button"
          [class.active]="selectedTabIndex === item?.id"
          (click)="selectTab(item?.id)"
          *ngFor="let item of allExpenses"
        >
          <div class="inn-box">
            <h2>{{ item?.paidThroughData?.accountName }}</h2>
          </div>
          <div class="inn-box">
            <h6>{{ item?.date | date : "dd-MM-yyyy" }}</h6>
            <h5>
              {{ item?.currencyData?.currencyCode }}
              <span>{{ calculateAmount(item) }}</span>
            </h5>
          </div>
          <div class="inn-box">
            <h3>{{ item?.customerData?.displayName }}</h3>
            <h4 class="grey">{{ item?.expenseStatus }}</h4>
          </div>
        </button>
        <!-- <button
          class="tab-custom-button"
          [class.active]="selectedTabIndex === 1"
          (click)="selectTab(1)"
        >
          <div class="inn-box">
            <h2>Cost of Goods Sold</h2>
          </div>
          <div class="inn-box">
            <h6>12-7-2024</h6>
            <h5>AED <span>1200</span></h5>
          </div>
          <div class="inn-box">
            <h3>Dan Bizler</h3>
            <h4 class="grey">Unbilled</h4>
          </div>
        </button>
        <button
          class="tab-custom-button"
          [class.active]="selectedTabIndex === 2"
          (click)="selectTab(2)"
        >
          <div class="inn-box">
            <h2>Cost of Goods Sold</h2>
          </div>
          <div class="inn-box">
            <h6>12-7-2024</h6>
            <h5>AED <span>1200</span></h5>
          </div>
          <div class="inn-box">
            <h3>Dan Bizler</h3>
            <h4 class="green">Reimbursed</h4>
          </div>
        </button>
        <button
          class="tab-custom-button"
          [class.active]="selectedTabIndex === 3"
          (click)="selectTab(3)"
        >
          <div class="inn-box">
            <h2>Cost of Goods Sold</h2>
          </div>
          <div class="inn-box">
            <h6>12-7-2024</h6>
            <h5>AED <span>1200</span></h5>
          </div>
          <div class="inn-box">
            <h3>Dan Bizler</h3>
            <h4 class="green">Reimbursed</h4>
          </div>
        </button>
        <button
          class="tab-custom-button"
          [class.active]="selectedTabIndex === 4"
          (click)="selectTab(4)"
        >
          <div class="inn-box">
            <h2>Cost of Goods Sold</h2>
          </div>
          <div class="inn-box">
            <h6>12-7-2024</h6>
            <h5>AED <span>1200</span></h5>
          </div>
          <div class="inn-box">
            <h3>Dan Bizler</h3>
            <h4 class="green">Reimbursed</h4>
          </div>
        </button> -->
      </div>
    </div>
    <div class="manual-journal-details-content-wrapper">
      <div *ngIf="detailsLoading">
        <div class="chart-tab-content">
          <div>Loading....</div>
        </div>
      </div>

      <div *ngIf="!detailsLoading">
        <div class="chart-tab-content">
          <div class="tab-inner">
            <div class="all-transaction-sidedialog-content-wrapper">
              <div class="type-head">
                <div>
                  <h2>
                    {{ expenseDetails?.currencyData?.currencyCode || "AED" }}
                    {{ calculateAmount(expenseDetails) }}
                  </h2>
                  <h3>On {{ expenseDetails?.date | date : "dd MMM yyyy" }}</h3>
                  <h3>{{ expenseDetails?.expenseStatus || "" }}</h3>
                </div>
                <span>{{ expenseDetails?.paidThroughData?.accountName }}</span>
              </div>
              <div class="transaction-table-wrapper">
                <table>
                  <tr>
                    <td>Paid Through</td>
                    <td>Customer</td>
                    <td>Paid To</td>
                  </tr>
                  <tr class="bold border">
                    <td>{{ expenseDetails?.paidThroughData?.accountName }}</td>
                    <td>{{ expenseDetails?.customerData?.displayName }}</td>
                    <td>{{ paidToAccount(expenseDetails) }}</td>
                  </tr>
                  <tr>
                    <td>Invoice Details</td>
                    <td>Note</td>
                    <td>Payment</td>
                  </tr>
                  <tr class="bold" *ngIf="!expenseDetails?.invoiceNo?.length">
                    <td>
                      {{ expenseDetails?.invoiceNo || " " }}
                    </td>
                    <td>{{ expenseDetails?.note }}</td>
                    <td>
                      {{ expenseDetails?.currencyData?.currencyCode || "AED" }}
                      {{ expenseDetails?.amount }}
                    </td>
                  </tr>
                  <tr
                    class="bold"
                    *ngFor="
                      let item of expenseDetails?.expenseItems;
                      let i = index
                    "
                  >
                    <td>
                      {{ i === 0 ? expenseDetails?.invoiceNo : " " }}
                    </td>
                    <td>{{ item?.description || "" }}</td>
                    <td>
                      {{ expenseDetails?.currencyData?.currencyCode || "AED" }}
                      {{ item?.amount }}
                    </td>
                  </tr>
                  <tr class="bold" *ngIf="expenseDetails?.expenseItems?.length">
                    <td>Total</td>
                    <td></td>
                    <td>
                      {{ expenseDetails?.currencyData?.currencyCode || "AED" }}
                      {{ calculateAmount(expenseDetails) }}
                    </td>
                  </tr>
                  <tr class="border">
                    <td>{{ expenseDetails?.date | date : "dd-MM-yyyy" }}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="title">
                    <td>Journal</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Account</td>
                    <td>Debit</td>
                    <td>Credit</td>
                  </tr>
                  <tr class="bold">
                    <td>Accounts Receivable</td>
                    <td>AED 0.00</td>
                    <td>AED 3,500.00</td>
                  </tr>
                  <tr class="bold border">
                    <td>Personal (Current)</td>
                    <td>AED 3,500.00</td>
                    <td>AED 0.00</td>
                  </tr>
                  <tr class="bold">
                    <td></td>
                    <td>AED 3,500.00</td>
                    <td>AED 3,500.00</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="selectedTabIndex === 2">
        <div class="chart-tab-content">content 3</div>
      </div>
      <div *ngIf="selectedTabIndex === 3">
        <div class="chart-tab-content">content 4</div>
      </div>
      <div *ngIf="selectedTabIndex === 4">
        <div class="chart-tab-content">content 5</div>
      </div> -->
    </div>
  </div>
</section>
