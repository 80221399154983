import { gql } from 'apollo-angular';

export const CREATE_QUOTATION = gql`
  mutation createQuotes(
    $createQuoteInput: CreateQuoteInput!
    $quoteItemsInput: [CreateQuoteItemInput!]
    $quoteExclusionInput: [CreateQuoteExclusionInput!]
    $quoteAttachmentsInput: [CreateQuoteAttachmentInput!]
  ) {
    createQuotes(
      createQuoteInput: $createQuoteInput
      quoteItemsInput: $quoteItemsInput
      quoteExclusionInput: $quoteExclusionInput
      quoteAttachmentsInput: $quoteAttachmentsInput
    ) {
      id
      quoteCode
      partnerId
      quoteDate
      expiryDate
      approvalDate
      createdBy
      branchId
      billingAddressId
      shippingAddressId
      refNumber
      projectId
      quoteTitle
      subTotal
      tax
      adjustment
      totalAmount
      customerNote
      additionalNote
      status
    }
  }
`;

export const UPDATE_QUOTATION = gql`
  mutation updateQuote(
    $updateQuoteInput: UpdateQuoteInput!
    $quoteItemsInput: [UpdateQuoteItemInput!]
    $quoteAttachmentsInput: [UpdateQuoteAttachmentInput!]
    $quoteExclusionInput: [UpdateQuoteExclusionInput!]
  ) {
    updateQuote(
      updateQuoteInput: $updateQuoteInput
      quoteItemsInput: $quoteItemsInput
      quoteAttachmentsInput: $quoteAttachmentsInput
      quoteExclusionInput: $quoteExclusionInput
    ) {
      id
      quoteCode
      partnerId
      quoteDate
      expiryDate
      approvalDate
      createdBy
      branchId
      billingAddressId
      shippingAddressId
      refNumber
      projectId
      quoteTitle
      subTotal
      tax
      adjustment
      totalAmount
      customerNote
      additionalNote
      status
    }
  }
`;

export const REMOVE_QUOTE_ITEM_BY_ID = gql`
  mutation removeQuoteItem($id: Int!) {
    removeQuoteItem(id: $id)
  }
`;

export const REMOVE_QUOTE_ATTACHMENT_BY_ID = gql`
  mutation removeQuoteAttachment($id: Int!) {
    removeQuoteAttachment(id: $id)
  }
`;

export const SAVE_WORK_ORDER = gql`
  mutation saveWorkOrder($saveWorkOrder: CreateWorkOrderInput!) {
    saveWorkOrder(saveWorkOrder: $saveWorkOrder)
  }
`;

export const GENERATE_QUOTE_PDF = gql`
  mutation getQuotePDF($quoteId: Int!) {
    getQuotePDF(quoteId: $quoteId){
    success
    message
    url
    fileName
    quote {
      id
      parentQuoteId
      quoteCode
      partnerId
      TRN
      SOW
      quoteDate
      expiryDate
      approvalDate
      createdBy
      refNumber
      projectId
      quoteTitle
      subTotal
      tax
      adjustment
      totalAmount
      paymentTerm
      deliveryTerm
      validity
      termsCondition
      customerNote
      additionalNote
      quoteUrl
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
  }
`;

export const REVISE_QUOTATION = gql`
  mutation reviseQuote($parentQuoteId: Int!, $quoteId: Int!) {
    reviseQuote(parentQuoteId: $parentQuoteId, quoteId: $quoteId) {
      id
      parentQuoteId
      quoteCode
      partnerId
      partnerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
        primaryContactId
      }
      TRN
      SOW
      quoteDate
      expiryDate
      approvalDate
      createdBy
      createdByData {
        id
        employeeCode
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        email
        phoneNumber
        alternatePhoneNumber
        address
        permanentAddress
        dateOfJoining
        employmentType
        employeeType
        employeeStatus
        profilePicture
        maritalStatus
        nationality
        passportNumber
        visaType
        visaIssueDate
        visaExpiryDate
        salary
        bankName
        bankAccountNumber
        IFSCCode
        reportingManagerId
        status
      }
      branchId
      branchData {
        id
        branchName
      }
      billingAddressId
      billingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      shippingAddressId
      shippingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      refNumber
      projectId
      projectData {
        id
        projectCode
        projectName
      }
      quoteTitle
      subTotal
      tax
      adjustment
      totalAmount
      paymentTerm
      deliveryTerm
      validity
      termsCondition
      customerNote
      additionalNote
      quoteUrl
      status
      createdAt
      updatedAt
      deletedAt
      quoteAttachment {
        id
        quoteId
        fileName
        fileUrl
      }
      quoteItems {
        id
        quoteId
        description
        quantity
        unit
        rate
        amount
        discount
        createdAt
        updatedAt
        deletedAt
      }
      quoteExclusions {
        id
        quoteId
        exclusion
        status
      }
      quoteTemplate {
        id
        templateName
        templateSubject
        templateBody
        templateType
      }
      quoteData {
        id
        workOrderStatus
        workOrderNo
        partnerId
        partnerData {
          id
          displayName
        }
        quoteId
        addressId
        addressData {
          id
          addressLine1
          addressLine2
          city
          country
          state
          phone
          pinCode
          fax
        }

        projectId
        projectData {
          id
          projectName
        }
        projectManagerId
        SOW
        clientRef
        commencementDate
        completionDate
        duration
        status
        createdAt
        updatedAt
        workOrderSOWs {
          id
        }
        attachedFiles {
          id
          fileName
          fileUrl
        }
      }
    }
  }
`;

export const REMOVE_PARENT_QUOTE = gql`
  mutation removeParentQuote($id: Int!) {
    removeParentQuote(id: $id)
  }
`;

export const REMOVE_QUOTE = gql`
  mutation removeQuote($id: Int!) {
    removeQuote(id: $id)
  }
`;

export const QUOTE_MARK_AS_SENT = gql`
  mutation updateQuoteStatus(
    $quoteId: Int!
    $parentQuoteId: Int!
    $quoteStatus: QuoteStatus!
  ) {
    updateQuoteStatus(
      quoteId: $quoteId
      parentQuoteId: $parentQuoteId
      quoteStatus: $quoteStatus
    ) {
      id
      parentQuoteId
      parentQuoteData {
        id
        parentCode
        quoteCode
        quoteDate
        partnerId
      }
      quoteCode
      partnerId
      TRN
      SOW
      quoteDate
      expiryDate
      approvalDate
      createdBy
      branchId
      billingAddressId
      shippingAddressId
      refNumber
      projectId
      quoteTitle
      subTotal
      tax
      adjustment
      totalAmount
      paymentTerm
      deliveryTerm
      validity
      termsCondition
      customerNote
      additionalNote
      quoteUrl
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
