<section class="cmn-innerpage-wrapper payment-received-details">
    <div class="head">
        <div class="search-wrapper">
            <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)" />
        </div>
        <button class="cmn-upload-btn" (click)="openDialog()">
            <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
            <span>Upload</span>
        </button>
        <button class="cmn-add-btn" (click)="navigateTo('/payment-received/payment-new')">
            <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New Payments
        </button>
        <button class="cmn-filter-btn" (click)="toggleDropdown($event)" [ngClass]="{ 'class-clicked': isDropdownOpen }">
            <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
            <span *ngIf="selectedCount > 0">{{ selectedCount }}</span>
        </button>
        <!-- Filter -->
        <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
            <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
            <form>
                <h6 class="cmn-popup-heading" (click)="clearFilters()">
                    Filter <span>Clear All</span>
                </h6>
                <div class="filter-inner-content">
                    <h2>Payment Mode</h2>
                    <div class="check-wrapper">
                        <div class="check-box" *ngFor="let mode of paymentModeList">
                            <label class="radio-wrapper">
                                <input type="checkbox" [checked]="selectedModes.includes(mode)"
                                    (change)="toggleMode(mode)" />
                                {{ mode }}
                                <span class="radio-mark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="btn-wrapper">
                    <button class="cmn-apply-btn" (click)="onApplyFilters()">Apply</button>
                </div>
            </form>
        </div>
        <!-- Filter -->
    </div>
    <div class="sub-head">
        <ul>
            <li class="main">All Payment Received</li>
            <li class="edit" (click)="navigateToMail()"><img src="assets/images/icons/icon-send.svg" alt="Mail"> Send to
                mail</li>
            <li class="edit" (click)="toggleDropdownCmd($event)"><img src="assets/images/icons/icon-comment.svg"
                    alt="Comment"> Comments & History</li>
            <li class="edit" (click)="navigateToEdit('/payment-received/payment-new')"><img
                    src="assets/images/icons/icon-edit-accounts.svg" alt="Edit"> Edit</li>
        </ul>
        <!-- Comments Dropdown -->
        <div [ngClass]="{ show: isDropdownOpenCmd }" class="common-dropdown filter-position" #dropdown>
            <button class="close-dropdown" (click)="toggleDropdownCmd($event)"></button>

            <form [formGroup]="commentsForm" (ngSubmit)="onCommentSubmit()">
                <h6 class="cmn-popup-heading">
                    Comments
                </h6>
                <div class="filter-inner-content">
                    <div class="form-wrapper">
                        <mat-form-field>
                            <textarea matInput placeholder="Add Your Comment Here..."
                                formControlName="comments"></textarea>
                            <mat-error *ngIf="
                                    commentsForm.get('comments')!.touched &&
                                    commentsForm.get('comments')!.hasError('required')
                                  ">
                                Comment is required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="submit" [disabled]="isLoading">{{ isLoading ?
                            'Submitting...' :
                            'Submit' }}</button>
                    </div>
                    <div class="timelinne-wrapper">
                        <!-- ngFor -->
                        <div class="timeline-box" *ngFor="let comment of commentsList">
                            <div class="timeline-head">
                                <ul>
                                    <li>{{comment?.employee?.firstName}}</li>
                                    <li>{{ comment.createdAt | date: 'dd/MM/yyyy' }}</li>
                                </ul>
                                <h3>{{ comment.createdAt | date: 'hh:mm a' }}</h3>
                            </div>
                            <div class="timeline-content">
                                <h4>{{comment?.note}}</h4>
                            </div>
                        </div>
                        <!-- ngFor -->
                    </div>
                </div>
            </form>

        </div>
        <!-- Comments Dropdown -->
        <div class="mat-menu-wrapper">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="downloadPdf()">
                    <span>PDF download</span>
                </button>
            </mat-menu>
        </div>
    </div>
    <div class="manual-journal-details-tab-wrapper">
        <div class="tab-left">
            <div class="tab-box-main-wrapper">
                <button *ngFor="let item of allItems; let i = index" class="tab-custom-button"
                    [class.active]="selectedTabIndex === i" (click)="selectTab(i)">
                    <div class="inn-box">
                        <h2>{{ item?.partner?.displayName || "---" }}</h2>
                        <h3> {{ item?.paymentNo || "---" }} </h3>
                    </div>
                    <div class="inn-box">
                        <h6>{{ item?.paymentDate ? (item?.paymentDate | date: 'MM/dd/yyyy') : "---" }}</h6>
                        <h5>AED <span>{{ item?.amount || "---" }}</span></h5>
                    </div>
                    <div class="inn-box">
                        <h4>{{ item?.paymentMode || "---" }}</h4>
                    </div>
                </button>
            </div>
        </div>
        <div class="manual-journal-details-content-wrapper">
            <div *ngIf="itemData && allItems.length > 0">
                <div class="chart-tab-content">
                    <div class="tab-main-container">
                        <div class="tab-inner">
                            <div class="note-header">
                                <div class="left-set">
                                    <h4>Munawel Group</h4>
                                    <h3>Dubai Industrial City, Saih Shuaib 4,</h3>
                                    <h3>United Arab Emirates - 117293</h3>
                                </div>
                                <div class="right-set">
                                    <h2>PAYMENT RECEIPT</h2>
                                    <!-- <h3># J-000102</h3>
                                        <h3>02/08/2024</h3> -->
                                </div>
                            </div>

                            <div class="journal-details">
                                <div class="left-set">
                                    <div class="details-box">
                                        <h4>Bill To </h4>
                                        <h5>:</h5>
                                        <h6>{{ itemData?.partner?.displayName || "---" }}</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Subject</h4>
                                        <h5>:</h5>
                                        <h6>{{ itemData?.partner?.subject || "---" }}</h6>
                                    </div>
                                </div>
                                <div class="right-set">
                                    <div class="receipt-box">
                                        <h4>{{ itemData?.paymentDate ? (itemData?.paymentDate | date: 'MM/dd/yyyy')
                                            : "---" }}</h4>
                                        <h5>Amount received</h5>
                                        <h6>AED {{ itemData?.amount || "---" }}</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="crdit-note-page-table">
                                <h1>Payment for</h1>
                                <table>
                                    <tr>
                                        <th>Invoice Number</th>
                                        <th>Invoice Date</th>
                                        <th>Invoice Amount</th>
                                        <th>Payment Amount</th>
                                    </tr>
                                    <tr *ngFor="let info of itemData?.paymentInfo;">
                                        <td>{{info?.invoice?.orderNo || "___"}}</td>
                                        <td>{{ info?.invoice?.invoiceDate ? (info?.invoice?.invoiceDate | date:
                                            'MM/dd/yyyy')
                                            : "---" }}</td>
                                        <td>AED {{info?.invoice?.totalPrice || "___"}}</td>
                                        <td>AED {{info?.amount || "___"}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="published-note">
                        <!-- <h2>published-note</h2> -->
                        <h2>{{itemData?.note}}</h2>
                        <div class="details-box">
                            <h4>Deposit To </h4>
                            <h5>:</h5>
                            <h6>{{itemData?.deposit?.accountName || "___"}}</h6>
                        </div>
                    </div>
                    <div class="credit-note-footer-table-wrapper">
                        <table>
                            <tr class="title">
                                <td>Journal</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Account</td>
                                <td>Debit</td>
                                <td>Credit</td>
                            </tr>
                            <tr *ngFor="let entry of journalData?.journalEnteries" class="bold">
                                <td>{{ entry.ChartOfAccount?.accountName || 'N/A' }}</td>
                                <td>AED {{ entry.debit | number:'1.2-2' }}</td>
                                <td>AED {{ entry.credit | number:'1.2-2' }}</td>
                        </tr>
                        <!-- <tr class="bold border">
                            <td>Personal (Current)</td>
                            <td>AED 3,500.00</td>
                            <td>AED 0.00</td>
                        </tr> -->
                        <tr class="bold border">
                            <td></td>
                            <td>AED {{ calculateTotalDebit(journalData?.journalEnteries) | number:'1.2-2' }}</td>
                            <td>AED {{ calculateTotalCredit(journalData?.journalEnteries) | number:'1.2-2' }}</td>
                        </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>