import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GET_EXPENSES,
  GET_EXPENSES_BY_ID,
} from '../graphql/queries/expenses.query';
import {
  CREATE_DESIGNATION,
  CREATE_EXPENSE,
  CREATE_EXPENSE_ITEM,
  REMOVE_DESIGNATION_BY_ID,
  REMOVE_EXPENSE,
  REMOVE_EXPENSE_ITEM,
  UPDATE_DESIGNATION,
  UPDATE_EXPENSE,
} from '../graphql/mutation/expenses.mutation';

@Injectable({
  providedIn: 'root',
})
export class ExpenseServices {
  constructor(private apollo: Apollo) {}

  fetchExpenses(): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_EXPENSES,
        // variables: { search },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.expenses || []));
  }

  createDesignations(createBranchInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: CREATE_DESIGNATION,
      variables: {
        input: createBranchInput,
      },
    });
  }

  updateDesignation(designationData: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: UPDATE_DESIGNATION,
      variables: {
        updateDesignationInput: designationData,
      },
    });
  }

  // Remove branch
  removeDesignationByid(id: number): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: REMOVE_DESIGNATION_BY_ID,
      variables: { id },
    });
  }

  getExpenseById(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_EXPENSES_BY_ID,
        variables: {
          id: id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.expense || []));
  }
  
  createExpense(
    createExpenseInput: any,
    expenseItems: any,
    fileAttachments: any
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate({
        mutation: CREATE_EXPENSE,
        variables: {
          createExpenseInput: createExpenseInput,
          expenseItems: expenseItems,
          fileAttachments: fileAttachments,
        },
      })
      .pipe(map((result: any) => result.data.createExpense || []));
  }

  updateExpense(
    updateExpenseInput: any,
    expenseItems: any,
    fileAttachments: any
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate({
        mutation: UPDATE_EXPENSE,
        variables: {
          updateExpenseInput: updateExpenseInput,
          expenseItems: expenseItems,
          fileAttachments: fileAttachments,
        },
      })
      .pipe(map((result: any) => result.data.updateExpense || []));
  }

  removeExpense(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate({
        mutation: REMOVE_EXPENSE,
        variables: {
          id,
        },
      })
      .pipe(map((result: any) => result.data.removeExpense || []));
  }

  removeExpenseItem(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate({
        mutation: REMOVE_EXPENSE_ITEM,
        variables: {
          id,
        },
      })
      .pipe(map((result: any) => result.data.removeExpenseItem || []));
  }

  createExpenseItem(createExpenseItemInputs: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .mutate({
        mutation: CREATE_EXPENSE_ITEM,
        variables: {
          createExpenseItemInputs,
        },
      })
      .pipe(map((result: any) => result.data.createExpenseItems || []));
  }
}
