import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { LeaveService } from '../../../../features/leave/services/leave.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../../../../shared/components/confirm-dialog/confirm-dialog';
import { Router } from '@angular/router';
import { ImageService } from '../../../../shared/services/image.service';
import { LeaveEditDialog } from '../pending/pending.component';

@Component({
  selector: 'app-rejected',
  standalone: true,
  imports: [
    CommonModule,
    AgGridAngular,
  ],
  templateUrl: './rejected.component.html',
  styleUrl: '../../leave-management.component.scss'
})
export class RejectedComponent {
  @Input() searchQuery: string = '';
  @Input() filter: any = [];
  public rowData: any[] | null = null;
  filterObj: any;

  constructor(
    private leaveService: LeaveService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private route: Router,
    private imageService: ImageService,
  ) { }

  ngOnInit() {
    this.fetchRejectedLeaves();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchQuery']) {
      this.fetchRejectedLeaves();
    } else if(changes['filter']) {
      this.fetchRejectedLeaves();
    }
  }

  getRowHeight = (params: any) => {
    const approvers = [
      params.data?.approver1,
      params.data?.approver2,
      params.data?.approver3,
      params.data?.approver4
    ];
    const approverCount = approvers.filter((approver) => !!approver).length;
    return 40 + (approverCount > 1 ? (approverCount - 1) * 20 : 0);
  };


      columnDefs = [
        {
            headerName: 'Applied By',
            field: 'name',
            flex: 1,
            cellRenderer: (params: any) => {
              return `
                <div style="display: flex; align-items: center;">
                  <img src="${params.data.imageUrl}" alt="Profile Image" style="width: 35px; height: 35px; margin-right: 10px; border-radius: 50%; object-fit: cover;">
                  <span>${params.data.name}</span>
                </div>
              `;
            }
          },
        { headerName: 'Leave Type', field: 'type', flex: 1 },
        { headerName: 'Duration', field: 'duration', flex: 1 },
        {
          headerName: 'Reason', flex: 1,
          cellRenderer: (params: any) => {
            const reason1 = params.data?.reason1 ?
              `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.reason1}</p>` : '';
            const reason2 = params.data?.reason2 ?
              `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.reason2}</p>` : '';
            const reason3 = params.data?.reason3 ?
              `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.reason3}</p>` : '';
            const reason4 = params.data?.reason4 ?
              `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.reason4}</p>` : '';
    
            return `
            <div>
              ${reason1}
              ${reason2}
              ${reason3}
              ${reason4}
            </div>
          `;
          },
        },
        {
          headerName: 'Approvers',
          flex: 1,
          cellRenderer: (params: any) => {
            const approver1 = params.data?.approver1 ? 
              `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.approver1}</p>` : '';
            const approver2 = params.data?.approver2 ? 
              `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.approver2}</p>` : '';
            const approver3 = params.data?.approver3 ? 
              `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.approver3}</p>` : '';
            const approver4 = params.data?.approver4 ? 
              `<p style="display: block; line-height: 1.5; color:#00A860;">${params.data.approver4}</p>` : '';
        
            return `
              <div>
                ${approver1}
                ${approver2}
                ${approver3}
                ${approver4}
              </div>
            `;
          },
        },
      ];
    
      public defaultColDef: ColDef = {
        filter: 'agTextColumnFilter',
        floatingFilter: true,
      };
      public rowSelection: 'single' | 'multiple' = 'multiple';
      public paginationPageSize = 10;
      public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
      public themeClass: string = 'ag-theme-quartz';

      readonly dialog = inject(MatDialog);

      fetchRejectedLeaves() {
        this.filterObj = { leaveStatus: 'Rejected', search: this.searchQuery};
        if (Array.isArray(this.filter) && this.filter.length > 0) {
          this.filterObj.leaveType = this.filter;
        }
        this.leaveService.fetchLeaves(this.filterObj).subscribe({
          next: async (data) => {
            this.rowData = await Promise.all( 
              data.map(async (data: any) => {
                let profilePicture;
                  if (data?.employee?.profilePicture) {
                    try {
                      profilePicture = await this.imageService.getImageAsBase64(
                        data?.employee.profilePicture
                      );
                    } catch (error) {
                      profilePicture = 'assets/images/dummy-profile-image.jpg';
                    }
                  } else {
                    profilePicture = 'assets/images/dummy-profile-image.jpg';
                  }
                return {
                  id: data?.id || '--',
                  employeeId: data?.employeeId || '--',
                  name: data?.employee?.firstName + ' ' + data?.employee?.lastName || '--',
                  imageUrl: profilePicture || 'assets/images/dummy-profile-image.jpg',
                  type: data?.leaveType + ' (' + data?.totalLeaveDays + ' days)' || '--',
                  startDate: this.datePipe.transform((data?.startDate), 'yyyy-MM-dd'),
                  endDate: this.datePipe.transform((data?.endDate), 'yyyy-MM-dd'),
                  duration: this.datePipe.transform((data?.startDate), 'MMM d') + ' - ' +
                    this.datePipe.transform((data?.endDate), 'MMM d') || '--',
                    reason1: data?.employee?.employeeApprovers[0]?.levelOneData?.leaveApproval?.comments || '--',
                    ...(data?.employee?.employeeApprovers[0]?.levelTwoData && {
                      reason2: data?.employee?.employeeApprovers[0]?.levelOneData?.leaveApproval?.comments || 'N/A'
                    }),
                    ...(data?.employee?.employeeApprovers[0]?.levelThreeData && {
                      reason3: data?.employee?.employeeApprovers[0]?.levelOneData?.leaveApproval?.comments || 'N/A'
                    }),
                    ...(data?.employee?.employeeApprovers[0]?.levelFourData && {
                      reason4: data?.employee?.employeeApprovers[0]?.levelOneData?.leaveApproval?.comments || 'N/A'
                    }),
                  approver1: data?.employee?.employeeApprovers[0]?.levelOneData?.firstName + ' ' +
                    data?.employee?.employeeApprovers[0]?.levelOneData?.lastName || '--',
    
                  ...(data?.employee?.employeeApprovers[0]?.levelTwoData && {
                    approver2: data?.employee?.employeeApprovers[0]?.levelTwoData?.firstName + ' ' +
                      data?.employee?.employeeApprovers[0]?.levelTwoData?.lastName
                  }),
    
                  ...(data?.employee?.employeeApprovers[0]?.levelThreeData && {
                    approver3: data?.employee?.employeeApprovers[0]?.levelThreeData?.firstName + ' ' +
                      data?.employee?.employeeApprovers[0]?.levelThreeData?.lastName
                  }),
    
                  ...(data?.employee?.employeeApprovers[0]?.levelFourData && {
                    approver4: data?.employee?.employeeApprovers[0]?.levelFourData?.firstName + ' ' +
                      data?.employee?.employeeApprovers[0]?.levelFourData?.lastName
                  })
                };
              })
              );
          },
          error: (error: any) => {
            this.toastr.error(error?.message);
          },
        });
      }

      onRowClicked(event: any) {
        const employeeId = event.data.id;
        this.route.navigate([`leaves/approvals/${employeeId}`]);
      }
}
