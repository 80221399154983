import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class FileExportService {
  constructor(private http: HttpClient, private toastr: ToastrService) {}

  getFileUrlPost(fileUrlString: string, fileFormat: string) {
    const url = fileUrlString;
    // Set up the headers with the token
    const token = localStorage.getItem('AUTH_TOKEN');
    const headers = new HttpHeaders().set('authorization', `Bearer ${token}`);
    return this.http
      .get(
        url,
        { headers, responseType: 'blob' }
      )
      .subscribe({
        next: (blob: Blob) => {
          // Create a URL for the blob data
          const fileURL = URL.createObjectURL(blob);

          // Create an anchor element and trigger download
          const a = document.createElement('a');
          a.href = fileURL;
          a.download = this.getFileName(fileFormat);
          a.click();

          // Revoke the object URL after use
          window.URL.revokeObjectURL(fileURL);

          this.toastr.success('File downloaded successfully!', 'Success');
        },
        error: (error) => {
          console.error('Error occurred while fetching file:', error);
          this.toastr.error('Error fetching file. Please try again.', 'Error');
        },
      });
  }

  getFileName(format: string): string {
    const timestamp = new Date().toISOString();
    return `employees_${timestamp}.${format}`;
  }
}
