import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { VacationService } from '../../../../../../features/vacation/services/vacation.service';
import { ActivatedRoute, Params } from '@angular/router';
import { HolidayCalendarService } from '../../../../../../features/holiday-calendar/services/holiday-calendar.service';

@Component({
  selector: 'app-vacation-apply',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './vacation-apply.component.html',
  styleUrl: '../../../../employee-details.component.scss'
})
export class VacationApplyComponent {
  vacationApplyForm: FormGroup;
  employeeId: any;
  holidays: any;
  today: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private vacationService: VacationService,
    private toastr: ToastrService,
    private holidayService: HolidayCalendarService,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.employeeId = params['id'];
    })
  }

  ngOnInit() {
    this.initForm();
    this.vacationApplyForm.get('startDate')?.valueChanges.subscribe((startDate) => {
      const duration = this.vacationApplyForm.get('duration')?.value;
    
      if (duration === '7' || duration === '14' || duration === '21') {
        this.updateEndDateBasedOnDuration(startDate, parseInt(duration, 10));
      }
    });
    
    this.vacationApplyForm.get('duration')?.valueChanges.subscribe((duration) => {
      const startDate = this.vacationApplyForm.get('startDate')?.value;
    
      if (duration === '7' || duration === '14' || duration === '21') {
        this.updateEndDateBasedOnDuration(startDate, parseInt(duration, 10));
      } else if (duration === 'Custom') {
        this.vacationApplyForm.get('endDate')?.enable();
      }
    });
    
    this.vacationApplyForm.get('endDate')?.valueChanges.subscribe((endDate) => {
      const duration = this.vacationApplyForm.get('duration')?.value;
      const startDate = this.vacationApplyForm.get('startDate')?.value;
    
      if (!startDate || (duration !== '7' && duration !== '14' && duration !== '21')) {
        return;
      }
    
      const calculatedEndDate = this.calculateEndDate(startDate, parseInt(duration, 10));
    
      if (new Date(endDate) > calculatedEndDate) {
        this.toastr.error(`The end date exceeds the ${duration}-day duration.`);
        this.vacationApplyForm.patchValue({ endDate: calculatedEndDate });
      }
    });
    
  }

  initForm() {
    const date = new Date();
    this.vacationApplyForm = this.fb.group({
      employeeId: [''],
      startDate: [date, Validators.required],
      endDate: [{ value: '', disabled: true }, Validators.required],
      requestDate: [''],
      vacationStatus: [''],
      status: [''],
      duration: ['',Validators.required],
      // firstSession: ['', Validators.required],
      // secondSession: ['', Validators.required],
      notes: ['']
    });
  }

  fetchHolidays(){
    this.holidayService.getHolidayCalendarList().subscribe({
      next: (data) => {
        this.holidays = data.map((holiday: any) => new Date(holiday.holidayDate));
      },
      error: (error) => {
        console.error(error,'Error');
      }
    })
  }

  updateEndDateBasedOnDuration(startDate: string, duration: number): void {
    const calculatedEndDate = this.calculateEndDate(startDate, duration);
    this.vacationApplyForm.patchValue({ endDate: calculatedEndDate });
    console.log('this.vacationApplyForm', this.vacationApplyForm.value);
    this.vacationApplyForm.get('endDate')?.disable(); // Disable manual editing for fixed durations

    
  }
  
  calculateEndDate(startDate: string, duration: number): Date {
    const start = new Date(startDate);
    const endDate = new Date(start);
    endDate.setDate(start.getDate() + duration - 1);
    return endDate;
  }
  
  
  dateFilter = (d: Date | null): boolean => {
    this.fetchHolidays();
    const currentDate = d || new Date();
    
    if (!this.holidays || !Array.isArray(this.holidays)) {
      return true; 
    }
    const isHoliday = this.holidays.some(
      (holidayDate: Date) => holidayDate.getTime() === currentDate.getTime()
    );
    
    return !isHoliday;
  };
  

  onApplyVacation() {
    if (this.vacationApplyForm.invalid) {
      this.vacationApplyForm.markAllAsTouched();
      return;
    }

    const startDate = this.datePipe.transform(this.vacationApplyForm.value.startDate, "yyyy-MM-dd");
    const endDate = this.datePipe.transform(this.vacationApplyForm.getRawValue().endDate, "yyyy-MM-dd");
    
    const isDateValid = (startDate && endDate)
      ? (new Date(endDate) >= new Date(startDate)
        ? true
        : this.toastr.error('To date cannot be earlier than from date', 'Error'))
      : this.toastr.error('Choose a valid date', 'Error');

    if (isDateValid === true) {
      this.vacationApplyForm.patchValue({
        startDate: startDate,
        endDate: endDate,
        // requestDate: this.datePipe.transform(new Date(), "yyyy-MM-dd"),
        employeeId: Number(this.employeeId),
        vacationStatus: 'Upcoming',
        status: 'Pending'
      });

      // const vacationData = this.vacationApplyForm.value;

      const vacationData = {
        employeeId: this.vacationApplyForm.value.employeeId,
        startDate: this.vacationApplyForm.value.startDate,
        endDate: endDate,
        vacationStatus: this.vacationApplyForm.value.vacationStatus,
        status: this.vacationApplyForm.value.status,
        note: this.vacationApplyForm.value.notes,
      }
      if (vacationData) {
        this.vacationService.createVacationRequest(vacationData).subscribe(
          (response: any) => {
            console.log(response);
            
            if (response && !response.errors != null) {
              this.toastr.success('Vacation applied successfully!', 'Success');
              this.vacationApplyForm.reset();
            } else if (response?.errors) {
              this.toastr.error(response?.errors[0]?.message, 'Error');
            }
          },
          (error: Error) => {
            this.toastr.error(error?.message, 'Error');
          }
        );
      }
    }

  }

  onCancel() {
    this.vacationApplyForm.reset();
  }
}