<div class="career-history-main-wrapper">
  <div class="warnings-wrapper">
    <div class="head">
      <h2 class="cmn-inner-heading">Warnings</h2>
      <button class="add-btn" (click)="openAddCareerDialog()">
        <img src="assets/images/icons/icon-add-color.svg" alt="Add" /> Add
      </button>
    </div>

    <!-- Check if there are no warnings -->
    <div *ngIf="warnings.length === 0" class="no-data">
      No warnings available.
    </div>

    <div *ngFor="let warning of warnings" class="box-wrapper">
      <div class="warning-box">
        <div class="box">
          <h3>Date Issued</h3>
          <h4>{{ warning.date | date : 'dd MMM yyyy'}}</h4>
        </div>
        <div class="box">
          <h3>Reason</h3>
          <h4>{{ warning.reason }}</h4>
        </div>
        <div class="box">
          <h3>Action</h3>
          <button (click)="openEditDialog(warning.id)">
            <img src="assets/images/icons/icon-edit.svg" alt="Edit" />
          </button>
          <button (click)="onDeleteCareer(warning.id)">
            <img src="assets/images/icons/icon-delete.svg" alt="Delete" />
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="promotion-wrapper">
    <div class="head">
      <h2 class="cmn-inner-heading">Career History</h2>
      <!-- <button class="add-btn" (click)="openAddCareerDialog()">
          <img src="assets/images/icons/icon-add-color.svg" alt="Add" /> Add
        </button> -->
    </div>

    <!-- Check if there are no promotions -->
    <div *ngIf="promotions.length === 0" class="no-data">
      No promotions available.
    </div>

    <div *ngFor="let promotion of promotions" class="box-wrapper">
      <div class="warning-box">
        <div class="box title">
          <h5>Promotion</h5>
        </div>
        <div class="box">
          <h3>New Designation</h3>
          <h4>{{ promotion.currentPosition }}</h4>
        </div>
        <div class="box">
          <h3>Previous Designation</h3>
          <h4>{{ promotion.previousPosition }}</h4>
        </div>
        <div class="box">
          <h3>Date</h3>
          <h4>{{ promotion.date | date : 'dd MMM yyyy'}}</h4>
        </div>
        <div class="box">
          <h3>Action</h3>
          <button (click)="openEditDialog(promotion.id)">
            <img src="assets/images/icons/icon-edit.svg" alt="Edit" />
          </button>
          <button (click)="onDeleteCareer(promotion.id)">
            <img src="assets/images/icons/icon-delete.svg" alt="Delete" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
