import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { LayoutComponent } from '../../../core/layout/layout.component';

@Component({
  selector: 'app-profit-loss',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatTabsModule, MatSelectModule],
  templateUrl: './profit-loss.component.html',
  styleUrl: '../reports.component.scss',
})
export class ProfitLossComponent implements OnChanges {
  @Input() profitLossData: any = {};
  @Input() startDate: string = '';
  @Input() endDate: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['profitLossData']) {
      console.log('Updated profitLossData:', this.profitLossData);
    }
    if (changes['startDate'] || changes['endDate']) {
      console.log(
        `Date Range Updated: From ${this.startDate} to ${this.endDate}`
      );
    }
  }
}

interface TreeNode {
  name: string;
  details: string;
  children?: TreeNode[];
  level?: number;
}

@Component({
  selector: 'app-balance-sheet',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatTabsModule, MatSelectModule],
  templateUrl: './balance-sheet.component.html',
  styleUrl: '../reports.component.scss',
})
export class BalanceSheetComponent implements OnInit, OnChanges {
  @Input() balanceSheetReport: any = {};
  @Input() startDate: string = '';
  @Input() endDate: string = '';
  data: TreeNode[] = [];

  ngOnInit(): void {
    this.initializeData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['balanceSheetReport'] &&
      !changes['balanceSheetReport'].isFirstChange()
    ) {
      this.initializeData();
    }
  }

  private initializeData(): void {
    if (this.balanceSheetReport) {
      console.log('balanceData', this.balanceSheetReport);
      this.data = [
        {
          name: 'Assets',
          details: '',
          children: [
            {
              name: 'Current Assets',
              details: '',
              children: [
                {
                  name: 'Cash',
                  children: this.balanceSheetReport.cash.details.map(
                    (detail: any) => ({
                      name: detail.accountName,
                      details: detail.amount.toString(),
                    })
                  ),
                },
                {
                  name: 'Total for Cash',
                  details: `${this.balanceSheetReport.cash.total}`,
                },
                {
                  name: 'Bank',
                  children: this.balanceSheetReport.bank.details.map(
                    (detail: any) => ({
                      name: detail.accountName,
                      details: detail.amount.toString(),
                    })
                  ),
                },
                {
                  name: 'Total for Bank',
                  details: `${this.balanceSheetReport.bank.total}`,
                },
                {
                  name: 'Accounts Receivable',
                  details: `${this.balanceSheetReport.accountsReceivable.total}`,
                  children:
                    this.balanceSheetReport.accountsReceivable.details.map(
                      (detail: any) => ({
                        name: detail.accountName,
                        details: detail.amount.toString(),
                      })
                    ),
                },
                {
                  name: 'Other Current Assets',
                  children:
                    this.balanceSheetReport.otherCurrentAssets.details.map(
                      (detail: any) => ({
                        name: detail.accountName,
                        details: detail.amount.toString(),
                      })
                    ),
                },
                {
                  name: 'Total for Other Current Assets',
                  details: `${this.balanceSheetReport.otherCurrentAssets.total}`,
                },
              ],
            },
            {
              name: 'Fixed Assets',
              children: this.balanceSheetReport.fixedAssets.details.map(
                (detail: any) => ({
                  name: detail.accountName,
                  details: detail.amount.toString(),
                })
              ),
            },
            {
              name: 'Total for Fixed Assets',
              details: `${this.balanceSheetReport.fixedAssets.total}`,
              children: [],
            },
          ],
        },
        {
          name: 'Liabilities',
          details: '',
          children: [
            {
              name: 'Current Liabilities',
              children: this.balanceSheetReport.currentLiabilities.details.map(
                (detail: any) => ({
                  name: detail.accountName,
                  details: detail.amount.toString(),
                })
              ),
            },
            {
              name: 'Total for Current Liabilities',
              details: `${this.balanceSheetReport.currentLiabilities.total}`,
            },
            {
              name: 'Long Term Liabilities',
              children: this.balanceSheetReport.longTermLiabilities.details.map(
                (detail: any) => ({
                  name: detail.accountName,
                  details: detail.amount.toString(),
                })
              ),
            },
            {
              name: 'Total for Long Term Liabilities',
              details: `${this.balanceSheetReport.longTermLiabilities.total}`,
            },
            {
              name: 'Total for Liabilities',
              details: `${this.balanceSheetReport.totalLiabilities}`,
              children: [],
            },
          ],
        },
        {
          name: 'Equities',
          details: `${this.balanceSheetReport.equities.total}`,
          children: this.balanceSheetReport.equities.details.map(
            (detail: any) => ({
              name: detail.accountName,
              details: detail.amount.toString(),
            })
          ),
        },
        {
          name: 'Total for Equities',
          details: `${this.balanceSheetReport.totalEquities}`,
          children: [],
        },
        {
          name: 'Total for Liabilities and Equities',
          details: `${this.balanceSheetReport.totalLiabilitiesAndEquities}`,
          children: [],
        },
      ];
    }
  }

  getTableRows(nodes: TreeNode[], level: number = 0): TreeNode[] {
    let rows: TreeNode[] = [];
    for (let node of nodes) {
      rows.push({ ...node, level });
      if (node.children && node.children.length > 0) {
        rows = [...rows, ...this.getTableRows(node.children, level + 1)];
      }
    }
    return rows;
  }

  getNodeClass(node: TreeNode): string {
    return node.level === 0
      ? 'title'
      : node.level === 1
      ? 'sub-title'
      : node.level === 2
      ? 'sub-title'
      : 'child';
  }
}

@Component({
  selector: 'app-cash-flow-statement',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatTabsModule, MatSelectModule],
  templateUrl: './cash-flow-statement.component.html',
  styleUrl: '../reports.component.scss',
})
export class CashFlowStatementComponent implements OnChanges {
  @Input() cashFlowReport: any = {};
  @Input() startDate: string = '';
  @Input() endDate: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cashFlowReport']) {
      console.log('Updated cashFlowReport:', this.cashFlowReport);
    }
    if (changes['startDate'] || changes['endDate']) {
      console.log(
        `Date Range Updated: From ${this.startDate} to ${this.endDate}`
      );
    }
  }
}

@Component({
  selector: 'app-sales-by-item',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatTabsModule, MatSelectModule],
  templateUrl: './sales-by-item.component.html',
  styleUrl: '../reports.component.scss',
})
export class SalesByItemComponent implements OnChanges {
  @Input() salesByItemReport: any = {};
  @Input() startDate: string = '';
  @Input() endDate: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['salesByItemReport']) {
      console.log('Updated SalesByItemReport:', this.salesByItemReport);
    }
    if (changes['startDate'] || changes['endDate']) {
      console.log(
        `Date Range Updated: From ${this.startDate} to ${this.endDate}`
      );
    }
  }
}

@Component({
  selector: 'app-sales-by-customer',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatTabsModule, MatSelectModule],
  templateUrl: './sales-by-customer.component.html',
  styleUrl: '../reports.component.scss',
})
export class SalesByCustomerComponent implements OnChanges {
  @Input() salesByCustomReport: any = {};
  @Input() startDate: string = '';
  @Input() endDate: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['salesByCustomReport']) {
      console.log('Updated salesByCustomReport:', this.salesByCustomReport);
    }
    if (changes['startDate'] || changes['endDate']) {
      console.log(
        `Date Range Updated: From ${this.startDate} to ${this.endDate}`
      );
    }
  }
}

@Component({
  selector: 'app-account-type-summary',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatTabsModule, MatSelectModule],
  templateUrl: './account-type-summary.component.html',
  styleUrl: '../reports.component.scss',
})
export class AccountTypeSummary implements OnChanges {
  @Input() accountTypeSummaryReport: any = {};
  @Input() startDate: string = '';
  @Input() endDate: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['accountTypeSummaryReport']) {
      console.log(
        'Updated accountTypeSummaryReport:',
        this.accountTypeSummaryReport
      );
    }
    if (changes['startDate'] || changes['endDate']) {
      console.log(
        `Date Range Updated: From ${this.startDate} to ${this.endDate}`
      );
    }
  }
}

@Component({
  selector: 'app-journal-report',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatTabsModule, MatSelectModule],
  templateUrl: './journal-report.component.html',
  styleUrl: '../reports.component.scss',
})
export class JournalReport implements OnChanges {
  @Input() journalReport: any = {};
  @Input() startDate: string = '';
  @Input() endDate: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['journalReport']) {
      console.log('Updated journalReport:', this.journalReport);
    }
    if (changes['startDate'] || changes['endDate']) {
      console.log(
        `Date Range Updated: From ${this.startDate} to ${this.endDate}`
      );
    }
  }
}

@Component({
  selector: 'app-general-ledger-report',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatTabsModule, MatSelectModule],
  templateUrl: './general-ledger-report.component.html',
  styleUrl: '../reports.component.scss',
})
export class GeneralLedgerReport implements OnChanges {
  @Input() generalLedgerReport: any = {};
  @Input() startDate: string = '';
  @Input() endDate: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['generalLedgerReport']) {
      console.log('Updated generalLedgerReport:', this.generalLedgerReport);
    }
    if (changes['startDate'] || changes['endDate']) {
      console.log(
        `Date Range Updated: From ${this.startDate} to ${this.endDate}`
      );
    }
  }
}

@Component({
  selector: 'app-customer-balance-summary-report',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatTabsModule, MatSelectModule],
  templateUrl: './customer-balance-summary.component.html',
  styleUrl: '../reports.component.scss',
})
export class CustomerBalanceSummaryReport implements OnChanges {
  @Input() customerBalanceSummaryReport: any = {};
  @Input() startDate: string = '';
  @Input() endDate: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['customerBalanceSummaryReport']) {
      console.log(
        'Updated customerBalanceSummaryReport:',
        this.customerBalanceSummaryReport
      );
    }
    if (changes['startDate'] || changes['endDate']) {
      console.log(
        `Date Range Updated: From ${this.startDate} to ${this.endDate}`
      );
    }
  }
}

@Component({
  selector: 'app-payment-received-report',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatTabsModule, MatSelectModule],
  templateUrl: './payment-received-report.component.html',
  styleUrl: '../reports.component.scss',
})
export class PaymentReceivedReport implements OnChanges {
  @Input() paymentReceivedReportrData: any = {};
  @Input() startDate: string = '';
  @Input() endDate: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['paymentReceivedReportrData']) {
      console.log(
        'Updated paymentReceivedReportrData:',
        this.paymentReceivedReportrData
      );
    }
    if (changes['startDate'] || changes['endDate']) {
      console.log(
        `Date Range Updated: From ${this.startDate} to ${this.endDate}`
      );
    }
  }
}

@Component({
  selector: 'app-vendor-balance-summary',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatTabsModule, MatSelectModule],
  templateUrl: './vendor-balance-summary.component.html',
  styleUrl: '../reports.component.scss',
})
export class VendorBalanceSummary implements OnChanges {
  @Input() vendorBalanceSummaryReport: any = {};
  @Input() startDate: string = '';
  @Input() endDate: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['vendorBalanceSummaryReport']) {
      console.log(
        'Updated vendorBalanceSummaryReport:',
        this.vendorBalanceSummaryReport
      );
    }
    if (changes['startDate'] || changes['endDate']) {
      console.log(
        `Date Range Updated: From ${this.startDate} to ${this.endDate}`
      );
    }
  }
}
