import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PREFERENCE_CODES } from '../graphql/queries/configure.query';
import { UPDATE_CONFIGURE } from '../graphql/mutation/configure.mutation';

@Injectable({
  providedIn: 'root',
})
export class ConfigureService {
  constructor(private apollo: Apollo) {}

  preferenceCodes(search: any): Observable<any> {
    return this.apollo
      .use('project')
      .query<any>({
        query: PREFERENCE_CODES,
        variables: {
          search
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data?.preferenceCodes || []));
  }

  updatePreferenceCode(id: any, updatePreferenceCodeInput: any): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: UPDATE_CONFIGURE,
      variables: {
        id,
        updatePreferenceCodeInput
      },
    });
  }
  

}
