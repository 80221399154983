<section class="cmn-innerpage-wrapper payroll-individual">
    <div class="head">
        <div class="employee-head">
            <div class="img-wrapper">
                <img [src]="profilePicture || 'assets/images/dummy-profile-image.jpg'" alt="profile">
            </div>
            <div class="content">
                <h2>
                    {{data?.employees?.firstName + ' ' + data?.employees?.lastName}}
                    <span [routerLink]="['/employees/edit', data?.employeeId]">
                        <img src="assets/images/icons/icon-edit-profile.svg" alt="Edit Profile" />
                        Edit Profile
                    </span>
                </h2>
                <h3>{{ data?.employees?.designation?.designationName || '--' }}</h3>
                <h4>
                    <img src="assets/images/icons/icon-mail.svg" alt="Icon Mail" />
                    {{ data?.employees?.email || '--' }}
                </h4>
            </div>
        </div>
    </div>
    <div class="individual-details-wrapper">
        <div class="details-head">
            <div class="box-parent left-set">
                <div class="box">
                    <h2>Monthly pay</h2>
                    <h3>AED {{data?.basicSalary || '--' }}</h3>
                </div>
                <div class="box">
                    <h2>Deduction amount</h2>
                    <h3>AED {{data?.deductions || '--' }}</h3>
                </div>
                <div class="box">
                    <h2>Gross salary</h2>
                    <h3>AED {{data?.grossPay || '--' }}</h3>
                </div>
            </div>
            <div class="box-parent right-set">
                <div class="box">
                    <h2>Working days</h2>
                    <h3>{{data?.workingDays || '0' }}</h3>
                </div>
                <div class="box">
                    <h2>Attendance</h2>
                    <h3>{{data?.attendance || '0' }}</h3>
                </div>
                <div class="box">
                    <h2>Paid leave</h2>
                    <h3>{{data?.leaves || '0' }}</h3>
                </div>
                <div class="box">
                    <h2>Net</h2>
                    <h3>AED {{data?.netPay || '0.00'}}</h3>
                </div>
            </div>
        </div>

        <div class="addition-btn-wrapper" *ngIf="!data?.payroll?.lockInputs">
            <button (click)="openDialog(true)"><img src="assets/images/icons/icon-add.svg" alt="Add icon" />
                Add Deduction</button>
            <button (click)="openDialog(false)"><img src="assets/images/icons/icon-add.svg" alt="Add icon" />
                Add Bonus</button>
        </div>

        <div class="overflow-details-wrapper">
            <div class="payslip-detail-wrapper">
                <div class="payslip-heading">
                    <img src="assets/images/logo.svg" alt="">
                    <h2>Munawel Group</h2>
                    <h2></h2>
                </div>
                <div class="payslip-details">
                    <div class="left-set">
                        <div class="box">
                            <h2>Name</h2>
                            <h2>:</h2>
                            <h3>{{data?.employees?.firstName + ' ' + data?.employees?.lastName || '--' }}</h3>
                        </div>
                        <div class="box">
                            <h2>Employee code</h2>
                            <h2>:</h2>
                            <h3>{{data?.employees?.employeeCode || '--' }}</h3>
                        </div>
                        <div class="box">
                            <h2>Designation</h2>
                            <h2>:</h2>
                            <h3>{{data?.employees?.designation?.designationName || '--' }}</h3>
                        </div>
                    </div>
                    <div class="right-set">
                        <div class="box">
                            <h2>Pay period</h2>
                            <h2>:</h2>
                            <div>
                                <h3>{{data?.payPeriodStart | date: 'dd MMM YYYY' }}</h3>
                                <h3>{{data?.payPeriodEnd | date: 'dd MMM YYYY' }}</h3>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="custom-table-wrapper">
                    <div class="table-group">
                        <div class="main-table">
                            <div class="split-set">
                                <div class="table-head">
                                    <h3>Earnings</h3>
                                    <h4>Amount</h4>
                                </div>
                                <div class="table-content" *ngFor="let bonus of data?.payrollBonuses">
                                    <h3>{{bonus?.bonusType || '--'}}</h3>
                                    <h4>AED {{bonus?.amount || '0.00'}}</h4>
                                </div>
                            </div>
                            <div class="split-set">
                                <div class="table-head">
                                    <h3>Deduction</h3>
                                    <h4>Amount</h4>
                                </div>
                                <div class="table-content" *ngFor="let deduction of data?.payrollDeductions">
                                    <h3>{{deduction?.deductionType || '--'}}</h3>
                                    <h4>AED {{deduction?.amount || '0.00'}}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="total-table">
                            <h5>Total Salary</h5>
                            <h6>AED {{totalSalary || '0.00'}}</h6>
                            <h5>Total Deduction</h5>
                            <h6>AED {{totalDeduction || '0.00'}}</h6>
                        </div>
                    </div>
                </div>
                <h6 class="net-pay">Net Pay <span>AED {{data?.netPay || '0.00'}}</span></h6>
            </div>
        </div>

    </div>
    <div class="submit-btn-wrapper">
        <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
        <button class="cmn-next-btn" (click)="onProcessPayment()">Proceed Payment</button>
    </div>
</section>