import { Component, HostListener, Inject, inject, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ActivityLogService } from '../../features/activity-logs/services/activity-log.service';
import { CommonModule, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-activity-log',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    AgGridAngular,
  ],
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss'],
})
export class ActivityLogComponent implements OnInit {

  public viewPortSize: boolean = false;
  columnDefs: ({ headerName: string; field: string; flex: number; cellRenderer?: undefined; floatingFilter?: undefined; filter?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer: (params: any) => string; floatingFilter?: undefined; filter?: undefined; } | { field: string; headerName: string; flex: number; cellRenderer: (params: any) => HTMLDivElement; floatingFilter: boolean; filter: boolean; })[];
  profileName: any;
  public rowData: any[] = [];
  public search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // public columnDefs: ColDef[] = [];

  public paginationPageSize = 10;
  public themeClass : string = 'ag-theme-quartz';
  public search: string = '';
  readonly dialog = inject(MatDialog);

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];

  constructor(
    private activityLogService: ActivityLogService,
    private datePipe: DatePipe,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.setAGColumn();
    this.fetchActivityLogs();
    this.checkViewportSize();

    // Subscribe to search with debounceTime
    this.search$.pipe(
      debounceTime(500), // Wait 500ms before firing API request
      distinctUntilChanged() // Only trigger if value is different from last one
    ).subscribe(searchTerm => {
      this.fetchActivityLogs(searchTerm);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {
    this.columnDefs = [
      // { headerName: 'Action Type', field: 'ActionType', flex: 2 },
      { headerName: 'Description', field: 'Description', flex: 3 },
      { headerName: 'Employee', field: 'Employee', flex: 1 },
      { headerName: 'Timestamp', field: 'Timestamp', flex: 2, 
        cellRenderer: (params:any) => {
          const date = new Date(params.value);
          return this.datePipe.transform(date, 'MMM d, y, h:mm a') || '--';
        },
      },
      { headerName: 'IP Address', field: 'IPAddress', flex: 2 },
      // {
      //   headerName: 'Response Time(ms)',
      //   field: 'AdditionalInfo.responseTime',
      //   flex: 1,
      // },
      // {
      //   headerName: 'Status Code',
      //   field: 'AdditionalInfo.statusCode',
      //   flex: 1,
      // },
    ];
  }


  fetchActivityLogs(search: string = '') {
    this.activityLogService.fetchActivityLogs(this.search).subscribe({
      next: (data) => {        
        this.rowData = data.map((log:any) => ({
          id: log.id || '--',
          Employee: log.employeeData?.firstName || '--',
          ActionType: log.ActionType || '--',
          Description: log.mappedKeyName || '--',
          IPAddress: log.IPAddress || '--',
          Timestamp: log.Timestamp || '--',
          // AdditionalInfo: {
          //   responseTime: log.AdditionalInfo?.responseTime ? `${log.AdditionalInfo.responseTime} ms` : '--',
          //   statusCode: log.AdditionalInfo?.statusCode || '--',
          // },
          AdditionalInfo: log?.AdditionalInfo || {} 
        }));
      },
      error: (error) => {
        console.error('Error fetching activity logs:', error);
      },
    });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.search = input.value;
    this.fetchActivityLogs();
  }


  onCellClicked(event: any) {
    console.log("Cell clicked:", event.data); 
    this.openDetailsDialog(event?.data);
  }
  

  openDetailsDialog(log: any) {
    const dialogRef = this.dialog.open(ActivityDetailsDialog, {
      data: log,
      width: '600px'
    });
  }
  
}



// @Component({
//   selector: 'activity-details-dialog',
//   templateUrl: './activity-details-dialog.html',
//   styleUrls: ['./activity-log.component.scss'],
//   standalone: true,
//   imports: [
//     MatDialogModule,
//     MatButtonModule,
//     CommonModule,
//   ],
// })
// export class ActivityDetailsDialog {
  
// }



@Component({
  selector: 'activity-details-dialog',
  templateUrl: './activity-details-dialog.html',
  styleUrls: ['./activity-log.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    CommonModule,
  ],
})
export class ActivityDetailsDialog {
  requestVariablesFormatted: string;
  requestQueryFormatted: string;
  responseFormatted: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.requestVariablesFormatted = this.formatJson(data?.AdditionalInfo?.requestBody?.variables);
    this.requestQueryFormatted = this.formatJson(data?.AdditionalInfo?.requestBody?.query, true);
    // this.responseFormatted = this.formatJson(data?.AdditionalInfo?.responseBody);
    this.responseFormatted = this.extractErrorMessageOrFormatResponse(data?.AdditionalInfo?.responseBody);

    console.log("Formatted Request Variables:", this.requestVariablesFormatted);
    console.log("Formatted Request Query:", this.requestQueryFormatted);
    console.log("Formatted Responses:", this.responseFormatted);
  }

  formatJson(jsonData: any, isQuery: boolean = false): string {
    try {
      if (!jsonData || (typeof jsonData === "object" && Object.keys(jsonData).length === 0)) 
        return "No data available";
  
      if (isQuery) {
        return jsonData.replace(/\\n/g, "\n").trim(); // ✅ Properly format GraphQL query
      }
  
      if (typeof jsonData === "string") {
        return JSON.stringify(JSON.parse(jsonData), null, 2); // ✅ Parse and pretty-print JSON
      }
  
      return JSON.stringify(jsonData, null, 2); // ✅ Pretty-print JSON objects
    } catch (error) {
      console.error("Error formatting JSON:", error);
      return isQuery ? jsonData : "Invalid JSON format"; // ✅ Return query as-is if there's an error
    }
  }

  extractErrorMessageOrFormatResponse(responseBody: string): string {
    try {
      if (!responseBody) return "No response data available";

      const responseJson = JSON.parse(responseBody);

      // Check if there's an error and extract only the error message
      if (responseJson.errors && responseJson.errors.length > 0) {
        return responseJson.errors[0].message || "Unknown error occurred";
      }

      // Otherwise, return full response as formatted JSON
      return JSON.stringify(responseJson, null, 2);
    } catch (error) {
      console.error("Error parsing response body:", error);
      return "Error processing response";
    }
  }
  
}


