import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { RolesService } from '../../features/roles/services/roles.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as PermissionActions from '../../core/permissions/permission.action';

@Component({
  selector: 'app-roles-management',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    FormsModule
  ],
  templateUrl: './roles-management.component.html',
  styleUrl: './roles-management.component.scss'
})
export class RolesManagementComponent {
  isDropdownOpen = false;
  filter: any = {};
  modules: any;
  search: any;
  permissions: any;
  assignedModule: any;
  moduleId: any;
  profileId: any;
  selectedPermissionIds: number[] = [];
  isToggleOn: boolean = false;
  permissionNames: any[] = [];


  constructor(
    private rolesService: RolesService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private store: Store,

  ) { }

  ngOnInit() {
    
    this.getModules();
    this.getPermissions();
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      console.log(id);

      if (id) {
        this.profileId = id;
      }
    })
  }

  toggleDropdown(event?: MouseEvent, moduleId?: any) {
    if (event) {
      event.stopPropagation();
    }
    if (moduleId) {
      this.moduleId = moduleId;
      // this.getPermissionsById(this.moduleId);
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  getModules() {
    this.rolesService.modules(this.filter).subscribe({
      next: (data) => {
        this.modules = data;

        this.modules.map((module: any) => {
          this.getPermissionsById(module?.id);
        })
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  getPermissions() {
    
    this.rolesService.permissions(this.search).subscribe({
      next: (data) => {
        this.permissions = data;
      //   this.permissionNames = this.permissions.filter((permission: any) => permission.checked)
      // .map((permission: any) => permission.permissionName);
      
      // // console.log("Updated Permissions:", this.permissions);
      // console.log(" Permission Names:",this.permissionNames);
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  getPermissionsById(id: any) {
    if (id) {
      this.rolesService.getModuleData(Number(id)).subscribe({
        next: (data) => {
          this.assignedModule = data;
          console.log('data----->', data);

          this.markAssignedPermissions(this.profileId);
        },
        error: (error) => {
          console.error(error, 'Error');
        }
      })
    }
  }
  
  markAssignedPermissions(roleId: number) {
    const rolesAndPermissions = this.assignedModule?.rolesAndPermissions;
  
    const role = rolesAndPermissions?.find((role: any) => role.profileId === Number(roleId));
  
    if (role && role.permissions) {
      const assignedPermissionIds = role.permissions
        .filter((perm: any) => perm?.status === 1)
        .map((perm: any) => perm.id);
      
      console.log('assignedPermissionIds', assignedPermissionIds);
      this.selectedPermissionIds = [...assignedPermissionIds];
      this.isToggleOn = assignedPermissionIds.length > 0;

  
      // Update the permissions list with the checked status
      this.permissions = this.permissions.map((permission: any) => ({
        ...permission,
        checked: assignedPermissionIds.includes(permission.id),
      }));
  
      this.permissionNames = this.permissions.filter((permission: any) => permission.checked)
      .map((permission: any) => permission.permissionName);
      
      console.log("Updated Permissions:", this.permissions);
      console.log(" Permission Names:",this.permissionNames);
    } else {
      console.error("Role not found or no permissions assigned.");
      this.isToggleOn = false; 

    }
  }
  
  togglePermission(permissionId: number, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    
    if (checkbox?.checked) {
      // Add the permission ID if checked
      // if (!this.selectedPermissionIds.includes(permissionId)) {
      //   this.selectedPermissionIds.push(permissionId);
      // }

      if (!this.selectedPermissionIds.includes(permissionId)) {
        this.selectedPermissionIds = [...this.selectedPermissionIds, permissionId];  // Clone and add
      }
    } else {
      // Remove the permission ID if unchecked
      this.selectedPermissionIds = this.selectedPermissionIds.filter((id: number) => id !== permissionId);
    }
  
    console.log("Updated selectedPermissionIds:", this.selectedPermissionIds);
  }

  toggleAllPermissions(event: Event): void {
    const toggleSwitch = event.target as HTMLInputElement;
    this.isToggleOn = toggleSwitch.checked;
  
    console.log(this.isToggleOn);
    
    if (this.isToggleOn) {
      // Turn ON all permissions
      this.selectedPermissionIds = this.permissions.map((perm: any) => perm.id);
      this.permissions = this.permissions.map((permission: any) => ({
        ...permission,
        checked: true,
      }));
    } else {
      // Turn OFF all permissions
      this.selectedPermissionIds = [];
      this.permissions = this.permissions.map((permission: any) => ({
        ...permission,
        checked: false,
      }));
    }
  
    console.log("Toggle State:", this.isToggleOn ? "ON" : "OFF");
    console.log("Updated Permissions:", this.permissions);
    console.log("Selected Permission IDs:", this.selectedPermissionIds);
  }
  
  onApply(){
    const updatedData = {
      profileId: this.profileId, 
      permissionIds: this.selectedPermissionIds, 
      moduleId: String(this.moduleId)
    }
    console.log('updatedData', updatedData);
    
    this.rolesService.updateRolesPermissions(this.profileId, this.selectedPermissionIds, String(this.moduleId)).subscribe({
      next: (data) => {
        if(data){
          this.toastr.success('Permission updated successfully', 'Success');
          this.isDropdownOpen = !this.isDropdownOpen;
          this.isToggleOn = this.selectedPermissionIds.length > 0;
          console.log('after updating', this.isToggleOn);
          this.store.dispatch(PermissionActions.setPermissions({ permissions: this.selectedPermissionIds }));
          this.getModules();
          
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }
}

