
    <section class="cmn-innerpage-wrapper invoice-new">
        <h2 class="cmn-inner-heading">New Payment</h2>
        <form [formGroup]="paymentNewItemForm" (ngSubmit)="onCreatePayment()">
            <div class="invoice-new-form-wrapper">
                <div class="form-wrapper invoice-form-wrapper">
                    <!-- Client Name Section -->
                    <div class="cmn-Search-select-container">
                        <label for="customerName">Customer Name</label>
                        <mat-form-field>
                            <mat-select placeholder="Search Customer" id="customerName" formControlName="customerName"
                                (selectionChange)="onCustomerSelected($event)">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="customerSearchControl"
                                        placeholderLabel="Search Customer"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let partners of partnersList" [value]="partners.id">
                                    {{ partners?.displayName }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="
                  paymentNewItemForm.get('customerName')!.touched &&
                  paymentNewItemForm.get('customerName')!.hasError('required')
                ">
                                Customer Name is required.
                            </mat-error>
                        </mat-form-field>
                        <!-- <button (click)="openCreateClientDialog()">
                            <div>
                                <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add client" />
                            </div>
                            <span>Add client</span>
                        </button> -->
                    </div>

                    <!-- Invoice Number Section -->


                    <!-- Order Number Section -->
                    <div>
                        <label for="amountReceived">Amount Received</label>
                        <mat-form-field>
                            <input matInput placeholder="0.00" id="amountReceived" formControlName="amountReceived" />
                            <mat-error *ngIf="
                  paymentNewItemForm.get('amountReceived')!.touched &&
                  paymentNewItemForm.get('amountReceived')!.hasError('required')
                ">
                                Amount Received is required.
                            </mat-error>
                        </mat-form-field>
                        <label>
                            <input type="checkbox" formControlName="isFullAmount" (change)="toggleFullAmount($event)" />
                            <span>Pay full amount(AED
                                {{fullAmount | number: '1.2-2'}})</span>
                        </label>
                    </div>

                    <div>
                        <label for="bankCharges">Bank Charges(if Any)</label>
                        <mat-form-field>
                            <input matInput placeholder="0.00" id="bankCharges" maxlength="10"
                                formControlName="bankCharges" />
                            <mat-error *ngIf="
                  paymentNewItemForm.get('bankCharges')!.touched &&
                  paymentNewItemForm.get('bankCharges')!.hasError('required')
                ">
                                Bank Charges is required.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Invoice Date Section -->
                    <div>
                        <label for="paymentDate">Payment Date</label>
                        <mat-form-field>
                            <input matInput [matDatepicker]="invoiceDatePicker" placeholder="Select Date"
                                id="invoiceDate" formControlName="paymentDate" [max]="maxDate" />
                            <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #invoiceDatePicker></mat-datepicker>
                            <mat-error *ngIf="
                              paymentNewItemForm.get('paymentDate')!.touched &&
                              paymentNewItemForm.get('paymentDate')!.hasError('required')
                            ">
                                Payment Date is required.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <label for="subject">Payment#</label>
                        <mat-form-field>
                            <input matInput placeholder="PY-XXXX" id="subject" formControlName="payment" />
                            <mat-error *ngIf="
                  paymentNewItemForm.get('payment')!.touched &&
                  paymentNewItemForm.get('payment')!.hasError('required')
                ">
                                Payment is required.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Terms Section -->
                    <div>
                        <label for="paymentTerms">Payment Mode</label>
                        <mat-form-field>
                            <mat-select placeholder="Payment Mode" id="paymentMode" formControlName="paymentMode">
                                <mat-option *ngFor="let mode of paymentModeList" [value]="mode">
                                    {{ mode }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="
                  paymentNewItemForm.get('paymentMode')!.touched &&
                  paymentNewItemForm.get('paymentMode')!.hasError('required')
                ">
                                Payment Mode is required.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Milestone Section -->
                    <div>
                        <label for="milestone">Deposit To</label>
                        <mat-form-field>
                            <mat-select placeholder="Deposit To" id="depositTo" formControlName="depositTo">
                                <mat-option *ngFor="let getAccount of getAccountList" [value]="getAccount?.id">
                                    {{ getAccount?.accountName }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="
                  paymentNewItemForm.get('depositTo')!.touched &&
                  paymentNewItemForm.get('depositTo')!.hasError('required')
                ">
                                Deposit To is required.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <label for="reference">Reference#</label>
                        <mat-form-field>
                            <input matInput placeholder="Reference" id="subject" formControlName="reference" />
                            <mat-error *ngIf="
                  paymentNewItemForm.get('reference')!.touched &&
                  paymentNewItemForm.get('reference')!.hasError('required')
                ">
                                Reference is required.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- <div>
                        <label>Tax-deduction?</label>
                        <div class="label-radio-group">
                            <label>
                                <input type="radio" formControlName="tax" value="no-tax" /> No
                                Tax Deduction
                            </label>
                            <label>
                                <input type="radio" formControlName="tax" value="vat" /> Yes
                                (VAT)
                            </label>
                        </div>
                        
                        <mat-error *ngIf="
                paymentNewItemForm.get('tax')?.touched &&
                paymentNewItemForm.get('tax')?.hasError('required')
              ">
                            Tax is required.
                        </mat-error>
                    </div> -->

                </div>
            </div>

            <div class="item-service-adding-table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Invoice#</th>
                            <th>Invoice Amount</th>
                            <th>Amount Due</th>
                            <th>Payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let partnersInvoice of partnerInvoiceData; let i = index">
                            <td>
                                <h2>{{ partnersInvoice?.invoiceDate ? (partnersInvoice?.invoiceDate | date:
                                    'MM/dd/yyyy')
                                    : "---" }}</h2>
                                <h3>Due Date : {{ partnersInvoice?.dueDate ? (partnersInvoice?.dueDate | date:
                                    'MM/dd/yyyy')
                                    : "---" }}</h3>
                            </td>
                            <td>{{partnersInvoice?.invoiceNo || "___"}}</td>
                            <td>AED {{partnersInvoice?.totalPrice ?? "___"}}</td>
                            <td>AED {{partnersInvoice?.balance ?? "___"}}</td>
                            <td class="input-td">
                                <div class="form-wrapper">
                                    <mat-form-field>
                                        <input matInput placeholder="0.00"
                                            [value]="splitAmounts[i] | number: '1.2-2'" />
                                    </mat-form-field>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">Total :</td>
                            <td>AED {{ totalSplitAmount | number: '1.2-2' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="notes-tatal-amount-container">
                <div class="form-container">
                    <div class="form-wrapper">
                        <label for="">Customer Notes</label>
                        <mat-form-field>
                            <textarea matInput placeholder="Looking forward for your business.."
                                formControlName="note"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div></div>
                <div>
                    <div class="amount-table-div">
                        <div>
                            <h6>Total <span>: {{ totalSplitAmount | number: '1.2-2' }}</span></h6>
                            <h6>Amount used for Payments <span>: {{ usedAmount() | number: '1.2-2' }}</span></h6>
                            <!-- <h6>Amount Refunded <span>: 0.00</span></h6> -->
                            <h6>Amount in Excess <span>: AED {{ calculateExcessAmount() | number: '1.2-2' }}</span></h6>
                        </div>
                    </div>
                </div>
            </div>

            <div class="upload-file-group">
                <h2>Documents</h2>
                <input #fileInput type="file" (change)="onFileSelected($event)" style="display: none" />
                <button type="button" class="cmn-upload-btn" (click)="fileInput.click()">
                    <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                    <span>Upload File</span>
                </button>
                <div class="uploaded-file" *ngIf="documentFileName">
                    <h6>
                        {{documentFileName || "___"}}
                        <img src="assets/images/icons/icon-close.svg" alt="Close Icon" (click)="clearDocument()" />
                    </h6>
                </div>
            </div>

            <div class="submit-btn-wrapper">
                <button class="cmn-cancel-btn" type="reset">Cancel</button>
                <button class="cmn-next-btn" type="submit" [disabled]="isLoading">{{ isLoading ? 'Submitting...' :
                    'Submit' }}</button>
            </div>
        </form>
    </section>
