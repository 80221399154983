import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  // canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
  //   return this.authService.isLoggedIn().pipe(
  //     map(() => {
  //       const authToken = localStorage.getItem('AUTH_TOKEN') || sessionStorage.getItem('AUTH_TOKEN');
  //       const isLoggedIn = !!authToken;

  //       if (isLoggedIn) {
  //         const loggedInUser: any = localStorage.getItem('loggedInUser');
  //         const userData = JSON.parse(loggedInUser);
  //         const profileName = userData?.profileData?.profileName;
  //         const allowedRoles = route.data['allowedRoles'] as Array<string>;

  //         // Check if user's role is in the allowedRoles for this route
  //         if (allowedRoles && !allowedRoles.includes(profileName)) {
  //           this.router.navigate(['/page-not-found']);
  //           return false;
  //         }
  //       }

  //       return isLoggedIn;
  //     }),
  //     tap(isAuthenticated => {
  //       if (!isAuthenticated) {
  //         localStorage.removeItem('AUTH_TOKEN');
  //         sessionStorage.removeItem('AUTH_TOKEN');
  //         this.router.navigate(['/login']);
  //       }
  //     })
  //   );
  // }
  
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      map(() => {
        const authToken = localStorage.getItem('AUTH_TOKEN') || sessionStorage.getItem('AUTH_TOKEN');
        const isLoggedIn = !!authToken;

        if (isLoggedIn) {
          const loggedInUser: any = localStorage.getItem('loggedInUser');
          const userData = JSON.parse(loggedInUser);
          const profileName = userData?.profileData?.profileName;
          const allowedRoles = route.data['allowedRoles'] as Array<string>;

          // Check if user's role is in the allowedRoles for this route
          if (allowedRoles && !allowedRoles.includes(profileName)) {
            this.router.navigate(['/page-not-found']);
            return false;
          }
        }

        return isLoggedIn;
      }),
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          localStorage.removeItem('AUTH_TOKEN');
          sessionStorage.removeItem('AUTH_TOKEN');
          this.router.navigate(['/login']);
        }
      })
    );
  }
}