import { gql } from "apollo-angular";

export const GET_VACATIONS = gql`
  query vacationRequests($filter: VacationRequestFilter) {
    vacationRequests(filter: $filter) {
    id
    employeeId
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      designationId
      designation {
        id
        designationName
        status
      }
      employeeApprovers{
        levelOneData{
          firstName
          lastName
        }
         levelTwoData{
          firstName
          lastName
        }
         levelThreeData{
          firstName
          lastName
        }
         levelFourData{
          firstName
          lastName
        }
         levelFiveData{
          firstName
          lastName
        }
         levelSixData{
          firstName
          lastName
        }
         levelSevenData{
          firstName
          lastName
        }
      }
    }
    startDate
    endDate
    totalDays
    vacationStatus
    status
  }
  }
`;

export const GET_VACATION_BY_ID = gql`
  query vacationRequest($id: Int!) {
    vacationRequest(id: $id) {
    id
    employeeId
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      designationId
      designation {
        id
        designationName
      }
      employeeApprovers{
        levelOne
        levelOneData{
          id
          firstName
          lastName
        }
        levelTwo
        levelTwoData{
          id
          firstName
          lastName
        }
        levelThree
        levelThreeData{
          id
          firstName
          lastName
        }
        levelFour
        levelFourData{
          id
          firstName
          lastName
        }
        levelFive
        levelFiveData{
          id
          firstName
          lastName
        }
        levelSix
        levelSixData{
          id
          firstName
          lastName
        }
        levelSeven
        levelSevenData{
          id
          firstName
          lastName
        }
      }
    }
    # requestDate
    startDate
    note
    endDate
    totalDays
    vacationStatus
    status
  }
  }
`;

export const GET_EMPLOYEE_VACATION = gql`
  query employeeVacationRequests($employeeId: Int!, $filter: VacationRequestFilter) {
    employeeVacationRequests(employeeId: $employeeId, filter: $filter) {
    id
    employeeId
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      designationId
      designation {
        id
        designationName
        status
      }
      employeeApprovers{
        levelOne
        levelOneData{
          id
          firstName
          lastName
        }
        levelTwo
        levelTwoData{
          id
          firstName
          lastName
        }
        levelThree
        levelThreeData{
          id
          firstName
          lastName
        }
        levelFour
        levelFourData{
          id
          firstName
          lastName
        }
        levelFive
        levelFiveData{
          id
          firstName
          lastName
        }
        levelSix
        levelSixData{
          id
          firstName
          lastName
        }
        levelSeven
        levelSevenData{
          id
          firstName
          lastName
        }
      }
    }
    # requestDate
    startDate
    endDate
    totalDays
    note
    vacationStatus
    status
    additionalSalary
  }
  }
`;