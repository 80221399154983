import { Component, Inject, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { ChartAccountsService } from '../../features/chart-of-accounts/services/chart-accounts.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Subject, takeUntil } from 'rxjs';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';

interface TreeNode {
  id: number;
  name: string;
  parentAccountId: string;
  code: string;
  accountType: string;
  parentAccount: string;
  isEditable: boolean;
  children?: TreeNode[];
  level?: number;  // Add the level property as optional
}
@Component({
  selector: 'app-chart-accounts',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    PermissionDirective
  ],
  templateUrl: './chart-accounts.component.html',
  styleUrl: './chart-accounts.component.scss'
})
export class ChartAccountsComponent {
  accountType: any = [];
  search: any;
  parentAccountId: any;
  isSubAccount: any;
  rowData: any;
  data: TreeNode[] = [];
  filterForm: FormGroup;
  filter: any;
  isDropdownOpen = false;
  readonly dialog = inject(MatDialog);
  accountTypes: any;
  searchInputControl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  type: any = '';
  // profileName: any;
  Permission = Permission;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private chartAccountService: ChartAccountsService,
  ) { 
    this.filterForm = this.fb.group({
      accountType: this.fb.array([]),
    });

    // const loggedInUser: any = localStorage.getItem('loggedInUser');
    // const userData = JSON.parse(loggedInUser);
    // this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit() {
    this.fetchChartAccounts();
    this.fetchAccounts();
    this.searchInputControl.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        if (data.length % 3 == 0) {
          this.search = data;
          this.fetchChartAccounts();
        }
      });
  }

  get accountTypesFormArray() {
    return this.filterForm.get('accountType') as FormArray;
  }

  private addControls(formArrayName: string, data: any[] = []) {
    const formArray = this.filterForm.get(formArrayName) as FormArray;
    formArray.clear();
  
    data.forEach(() => {
      formArray.push(new FormControl(false));
    });
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  getTableRows(node: TreeNode, level: number = 0): TreeNode[] {
    let rows: TreeNode[] = [{ ...node, level }];

    if (node.children && node.children.length > 0) {
      for (let child of node.children) {
        rows = [...rows, ...this.getTableRows(child, level + 1)];
      }
    }

    return rows;
  }


  getNodeClass(node: TreeNode): string | string[] {
    // Apply 'child' class for the first child, and 'child2' for the second, based on your requirements
    if (node.level === 0) {
      return 'title';
    } else if (node.level === 1) {
      return 'sub-title';
    } else if (node.level === 2) {
      return 'sub-title';
    } else if (node.level === 3) {
      return 'sub-total';
    }

    // You can add more conditions if necessary
    return '';
  }

  fetchChartAccounts() {
    this.chartAccountService.chartOfAccounts(this.accountType, this.search, this.parentAccountId, this.isSubAccount).subscribe({
      next: (data) => {
        const accountMap = new Map<number, TreeNode>();
        data.forEach((account: any) => {
          const node: TreeNode = {
            id: account.id,
            name: account.accountName,
            parentAccountId: account.parentAccountId,
            code: account.accountCode || '',
            isEditable: account?.isEditable || false,
            accountType: account.accountType || '',
            parentAccount: account.parentAccountData?.accountName || '--',
            level: 0,
            children: []
          };
          accountMap.set(account.id, node);
        });

        accountMap.forEach((node, id) => {
          const account = data.find((a: any) => a.id === id);

          if (account && account.parentAccountId) {
            const parentNode: any = accountMap.get(account.parentAccountId);
            
            if (parentNode) {
              parentNode.children.push(node);
              node.level = parentNode.level + 1;
            }
          }
        });
        this.data = Array.from(accountMap.values()).filter((data: any) =>  data.parentAccountId === null || !accountMap.has(data.parentAccountId));
        // this.data = Array.from(accountMap.values()).filter((data: any) => data && data?.parentAccountId === null);
      },
      error: (err) => {
        console.error('Error fetching chart accounts:', err);
      }
    });
  }

  fetchAccounts() {
    this.chartAccountService.fetchAccounts(this.type, this.search).subscribe({
      next: (accounts: any) => {
        this.accountTypes = accounts;
        this.addControls('accountType', this.accountTypes);
      },
      error: (error) => { 
        console.error(error, 'Error');
      }
    })
  }

  onConfirmDelete(id: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onDelete(id);
      }
    });
  }

  onDelete(id: any) {
    this.chartAccountService.removeChartOfAccount(id).subscribe({
      next: (result) => {
        if (result === true) {
          this.fetchChartAccounts();
          this.toastr.success("Account deleted successfully", 'Success');
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }

  onEdit(id: any) {
    const dialogRef = this.dialog.open(ChartAccountDialog, {
      disableClose: true,
      data: id,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.getLeaveApprovals(this.leaveId,"LEAVE");
        this.toastr.success('Account updated successfully', 'Success');
      }
    });
  }

  onSubmit() {
    const selectedAccountType = this.filterForm.value.accountType
      .map((checked: boolean, i: number) =>
        checked ? this.accountTypes[i].accountType : null
      )
      .filter((value: string | null) => value !== null);
  
    this.accountType = selectedAccountType;
    console.log(selectedAccountType);
    this.fetchChartAccounts();
  }
  
  clearFilter(){
    this.filterForm.reset();
  }

}

@Component({
  selector: 'chart-account-dialog',
  templateUrl: 'chart-account-dialog.component.html',
  styleUrls: ['./chart-accounts.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule
  ],
})
export class ChartAccountDialog {
  // @ViewChild('LeaveApprovalsComponent')LeaveApprovalsComponent: LeaveApprovalsComponent;
  status: boolean;
  approvedStatus: string = '';
  leaveId: number;
  accountEditForm: FormGroup;
  rejectedStatus: string;
  accountId: any;
  accountData: any;
  updateData: any;
  parentAccountId: any;
  isSubAccount: boolean;
  search: any;
  accountType: any;
  accounts: any;
  subAccount: any;
  chartAccounts: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private chartAccountsService: ChartAccountsService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ChartAccountDialog>,
  ) {

   }

  ngOnInit() {
    this.initForm();
    this.accountId = this.data;
    this.fetchAccountById();
    this.fetchAccounts();
    this.fetchChartAccounts();
  }

  initForm() {
    this.accountEditForm = this.fb.group({
      accountType: ['', Validators.required],
      accountName: ['', Validators.required],
      parentAccountId: [''],
      accountCode: ['', Validators.required],
      description: [''],
      isSubAccount: [false],
    });
  }


  fetchAccountById() {
    this.chartAccountsService.chartOfAccountsById(this.accountId).subscribe({
      next: (data) => {
        this.accountData = data;
        this.patchData();
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  patchData() {
    this.accountEditForm.patchValue({
      accountType: this.accountData.accountType,
      accountName: this.accountData.accountName,
      parentAccountId: this.accountData.parentAccountId,
      accountCode: this.accountData.accountCode,
      description: this.accountData.description,
      isSubAccount: this.accountData.isSubAccount,
    })
    if(this.accountData.isSubAccount){
      this.isSubAccount = this.accountData.isSubAccount;
    }
  }

  toggleSubAccount(checked: boolean) {
    this.isSubAccount = checked;
    this.accountEditForm.patchValue({
      isSubAccount: checked
    });
  }

  onEdit() {
    if (this.accountEditForm.value.parentAccountId) {
      this.parentAccountId = Number(this.accountEditForm.value.parentAccountId);
    }
    this.updateData = this.accountEditForm.value;
    this.chartAccountsService.updateChartOfAccount(this.data, this.updateData, this.parentAccountId).subscribe({
      next: (data) => {
        this.dialogRef.close(data);
        // this.LeaveApprovalsComponent.leaveApprovals();
      },
      error: (error) => {
        this.toastr.error(error?.message, 'Error')
      }
    })
  }

  fetchAccounts() {
    this.chartAccountsService.fetchAccounts(this.accountType, this.search).subscribe({
      next: (accounts: any) => {
        this.accounts = accounts;

      },
      error: (error) => { }
    })
  }

  fetchChartAccounts() {
    this.chartAccountsService.chartOfAccounts(this.accountType, this.search, this.parentAccountId, this.subAccount).subscribe({
      next: (data) => {
        this.chartAccounts = data;
      },
      error: (err) => {
        console.error('Error fetching chart accounts:', err);
      }
    });
  }


  
}