import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { FaceidDeviceServices } from '../../features/faceid-devices/services/faceid-devices.service';

@Component({
  selector: 'app-faceid-devices',
  standalone: true,
  imports: [CommonModule, AgGridModule],
  templateUrl: './faceid-devices.component.html',
  styleUrls: ['./faceidDevice.component.scss'],
})
export class FaceidDevices implements OnInit, OnDestroy {
  public rowData: any[] = [];
  public loading: boolean = true;
  public error: any = null;

  public columnDefs: ColDef[] = [
    {
      field: 'deviceName',
      headerName: 'Device Name',
      flex: 1,
    },
    {
      field: 'deviceLoaction',
      headerName: 'Location',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      valueFormatter: (params) => {
        switch (params.value) {
          case 1:
            return 'Active';
          case 2:
            return 'Deleted';
          case 0:
            return 'Inactive';
          default:
            return 'Unknown';
        }
      },
    },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10];

  private destroy$ = new Subject<void>();

  constructor(
    private faceidDeviceService: FaceidDeviceServices,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.fetchFaceidDevices();
  }

  private fetchFaceidDevices() {
    this.loading = true;
    this.faceidDeviceService
      .fetchFaceIdDevices()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (devices) => {
          this.rowData = devices
            .map((device: any) => ({
              id: device?.id || '--',
              deviceName: device?.deviceName || '--',
              deviceLoaction: device?.location?.locationName || '--',
              status: device?.status || '',
            }))
            .sort((a: any, b: any) => b.id - a.id);

          this.paginationPageSizeSelector =
            PaginationHelper.getPaginationPageSizeOptions(this.rowData);
          this.loading = false;
        },
        error: (error) => {
          this.error = error;
          this.toastr.error('Error fetching device data.');
          this.loading = false;
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
