import { gql } from 'apollo-angular';

export const GET_ASSETS_FOR_EMPLOYEE = gql`
query assetsForEmployee {
    assetsForEmployee {
    id
    assetName
    assetType
    serialNumber
    purchaseDate
    purchaseCost
    warrantyExpiryDate
    assetStatus
    condition
    branchId
    createdAt
    updatedAt
    deletedAt
    branch {
      id
      branchName
      address
      city
      state
      country
      zipCode
      phoneNumber
      branchCode
      isHeadOffice
      email
      createdAt
      updatedAt
      deletedAt
    }
    assignedTo
    assignedData {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      departmentId
      designationId
      reportingManagerId
      branchId
    }
    vendorId
    vendorData {
      id
      vendorName
      companyName
      email
      phoneNumber
      mobile
      address
      city
      state
      zipCode
      country
      currency
      paymentTerms
      status
    }
  }
}
`;
