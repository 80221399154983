
    <section class="cmn-innerpage-wrapper vacation-management">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)"/>
            </div>
            <button class="cmn-filter-btn" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }">
                <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
            </button>
            <!-- Filter Popup -->
            <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <div>
                  <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
                    <h6 class="cmn-popup-heading">Filter <span (click)="clearFilter()">Clear All</span></h6>
                    <h2>Vacation Status</h2>
                    <div class="check-wrapper">
                      <div class="check-box">
                        <label class="checkbox-wrapper">
                          <input type="checkbox" formControlName="Pending" />
                          Pending
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="check-box">
                        <label class="checkbox-wrapper">
                          <input type="checkbox" formControlName="Approved" />
                          Approved
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="check-box">
                        <label class="checkbox-wrapper">
                          <input type="checkbox" formControlName="Rejected" />
                          Rejected
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="btn-wrapper">
                      <button class="cmn-apply-btn" type="submit">Apply</button>
                  </div>
                  </form>
                </div>
                  
                    <!-- <h2>Employee Type</h2>
                    <div formArrayName="employeeTypes" class="check-wrapper">
                        <div class="check-box">
                            <label class="checkbox-wrapper">
                                <input type="checkbox" />
                                test
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div> -->

                </div>
            </div>
            <!-- Filter Popup -->
        <!-- </div> -->
        <div class="inner-content">
            <div class="common-noimage-tab-wrapper">
                <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
                    <!-- Mat Tab One -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Summary</h2>
                        </ng-template>
                        <div class="leave-history-table">
                            <app-summary [searchQuery]="searchQuery" [filter]="filter"></app-summary>
                        </div>


                    </mat-tab>
                    <!-- Mat Tab Two -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Upcoming Vacation</h2>
                        </ng-template>
                        <div class="leave-history-table">
                            <app-upcoming-vacation [searchQuery]="searchQuery" [filter]="filter" *ngIf="activeTabIndex === 1" #upcomingVacation></app-upcoming-vacation>
                        </div>
                    </mat-tab>
                    <!-- Mat Tab Three -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Current Vacation</h2>
                        </ng-template>
                        <div class="leave-history-table">
                          <app-current-vacation [searchQuery]="searchQuery" [filter]="filter" *ngIf="activeTabIndex === 2" #currentVacation></app-current-vacation>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
           
        </div>
    </section>
