<section class="reports-main-section" *appHasPermission="['35', Permission.List]">
  <div class="reports-list-wrapper">
    <!-- <div class="search-wrapper">
        <input type="text" class="cmn-header-search" placeholder="Search" />
      </div> -->
    <div class="reports-tab-wrapper">
      <mat-tab-group animationDuration="0ms" [(selectedIndex)]="selectedTabIndex"
        (selectedTabChange)="onTabChange($event)">
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Profit & Loss</h2>
          </ng-template>
        </mat-tab>
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Cash Flow Statement</h2>
          </ng-template>
        </mat-tab>
        <!-- <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Trial Balance</h2>
          </ng-template>
        </mat-tab> -->
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Balance Sheet</h2>
          </ng-template>
        </mat-tab>
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Sales by Item</h2>
          </ng-template>
        </mat-tab>
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Sales by Customer</h2>
          </ng-template>
        </mat-tab>
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Account Type Summary</h2>
          </ng-template>
        </mat-tab>
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Journal Report</h2>
          </ng-template>
        </mat-tab>
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>General Ledger Report</h2>
          </ng-template>
        </mat-tab>
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Customer Balance Summary</h2>
          </ng-template>
        </mat-tab>
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Payment Received Report</h2>
          </ng-template>
        </mat-tab>
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Payment Made Report</h2>
          </ng-template>
        </mat-tab>
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Vendor Balance Summary</h2>
          </ng-template>
        </mat-tab>
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Petty Cash Report</h2>
          </ng-template>
        </mat-tab>
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Aging Report for Invoice</h2>
          </ng-template>
        </mat-tab>
        <mat-tab label="Profile">
          <ng-template mat-tab-label>
            <h2>Aging Report for Vendor Invoice</h2>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div class="reports-main-content-wrapper">
    <div class="head-wrapper">
      <div class="head">
        <h4>
          <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
          Filters
        </h4>

        <div class="branch-wrapper" *ngIf="selectedTabIndex !== 13 && selectedTabIndex !== 14">
          <h3>Date Range</h3>
          <div class="form-wrapper">
            <form [formGroup]="dateRangeForm">
              <mat-form-field>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate placeholder="Start date" formControlName="startDate"
                    (dateInput)="onDateChange($event, 'start', selectedTabIndex)" />
                  <input matEndDate placeholder="End date" formControlName="endDate"
                    (dateInput)="onDateChange($event, 'end', selectedTabIndex)" />
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </form>
          </div>
        </div>

        <div *ngIf="selectedTabIndex === 13 || selectedTabIndex === 14">
          <form [formGroup]="dateRangeForm">
            <mat-form-field>
              <mat-select placeholder="Choose days" [value]="daysFilter" (selectionChange)="onDaysFilterChange($event)">
                <mat-option *ngFor="let day of dayTypes" [value]="day.value">
                  {{ day.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>

      </div>
      <div *ngIf="selectedTabIndex !== 13 && selectedTabIndex !== 14">
        <button class="cmn-export-btn" (click)="toggleExport($event)">
          <img src="assets/images/icons/icon-export.svg" alt="Filter Icon" />
          <!-- <span>Export</span> -->
        </button>
        <div [ngClass]="{ show: isExportOpen }" class="common-dropdown export-position" #export>
          <button class="close-dropdown" (click)="toggleExport($event)"></button>
          <h2>Export Table</h2>
          <h2>File Type</h2>
          <!-- Format Selection -->
          <div class="format-selection">
            <!-- <label class="radiobtn-wrapper">
                <input
                  type="radio"
                  name="exportFormat"
                  value="pdf"
                  [(ngModel)]="exportFormat"
                />
                <span class="radiomark"></span>
                PDF
              </label>
              <label class="radiobtn-wrapper">
                <input
                  type="radio"
                  name="exportFormat"
                  value="xls"
                  [(ngModel)]="exportFormat"
                />
                <span class="radiomark"></span>
                XLS
              </label> -->
            <label class="radiobtn-wrapper">
              <input type="radio" name="exportFormat" value="csv" [(ngModel)]="exportFormat" />
              <span class="radiomark"></span>
              CSV
            </label>
          </div>

          <div class="btn-wrapper">
            <button class="cmn-apply-btn" type="submit" (click)="exportReports()">
              Export
            </button>
            <!-- <button class="cmn-apply-btn" type="submit" [disabled]="exportLoading" (click)="exportReports()">
              {{ exportLoading ? "Exporting..." : "Export" }}
          </button> -->
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedTabIndex === 0">
      <app-profit-loss [profitLossData]="profitLossData" [startDate]="startDate" [endDate]="endDate">></app-profit-loss>
    </div>
    <div *ngIf="selectedTabIndex === 1">
      <app-cash-flow-statement [cashFlowReport]="cashFlowReport" [startDate]="startDate" [endDate]="endDate">
      </app-cash-flow-statement>
    </div>
    <!-- <div *ngIf="selectedTabIndex === 2">
      <app-trial-balance-statement [trialBalanceReport]="trialBalanceReport" [startDate]="startDate"
        [endDate]="endDate">
      </app-trial-balance-statement>
    </div> -->
    <div *ngIf="selectedTabIndex === 2">
      <app-balance-sheet [balanceSheetReport]="balanceSheetReport" [startDate]="startDate"
        [endDate]="endDate"></app-balance-sheet>
    </div>
    <div *ngIf="selectedTabIndex === 3">
      <app-sales-by-item [salesByItemReport]="salesByItemReport" [startDate]="startDate"
        [endDate]="endDate"></app-sales-by-item>
    </div>
    <div *ngIf="selectedTabIndex === 4">
      <app-sales-by-customer [salesByCustomReport]="salesByCustomReport" [startDate]="startDate"
        [endDate]="endDate"></app-sales-by-customer>
    </div>
    <div *ngIf="selectedTabIndex === 5">
      <app-account-type-summary [accountTypeSummaryReport]="accountTypeSummaryReport" [startDate]="startDate"
        [endDate]="endDate"></app-account-type-summary>
    </div>
    <div *ngIf="selectedTabIndex === 6">
      <app-journal-report [journalReport]="journalReport" [startDate]="startDate" [endDate]="endDate">
      </app-journal-report>
    </div>
    <div *ngIf="selectedTabIndex === 7">
      <app-general-ledger-report [generalLedgerReport]="generalLedgerReport" [startDate]="startDate"
        [endDate]="endDate"></app-general-ledger-report>
    </div>
    <div *ngIf="selectedTabIndex === 8">
      <app-customer-balance-summary-report [customerBalanceSummaryReport]="customerBalanceSummaryReport"
        [startDate]="startDate" [endDate]="endDate"></app-customer-balance-summary-report>
    </div>
    <div *ngIf="selectedTabIndex === 9">
      <app-payment-received-report [paymentReceivedReportrData]="paymentReceivedReportrData" [startDate]="startDate"
        [endDate]="endDate"></app-payment-received-report>
    </div>
    <div *ngIf="selectedTabIndex === 10">
      <app-payment-made-report [paymentMadeReportData]="paymentMadeReportData" [startDate]="startDate"
        [endDate]="endDate"></app-payment-made-report>
    </div>
    <div *ngIf="selectedTabIndex === 11">
      <app-vendor-balance-summary [vendorBalanceSummaryReport]="vendorBalanceSummaryReport" [startDate]="startDate"
        [endDate]="endDate"></app-vendor-balance-summary>
    </div>
    <div *ngIf="selectedTabIndex === 12">
      <app-pettycash-report [pettyCashReport]="pettyCash" [startDate]="startDate"
        [endDate]="endDate"></app-pettycash-report>
    </div>
    <div *ngIf="selectedTabIndex === 13">
      <app-aging-invoice [agingInvoiceReport]="agingInvoiceReport" [daysFilter]="daysFilter"></app-aging-invoice>
    </div>
    <div *ngIf="selectedTabIndex === 14">
      <app-aging-vendor-invoice [agingVendorInvoice]="agingVendorInvoiceReport" [daysFilter]="daysFilter">
      </app-aging-vendor-invoice>
    </div>
  </div>
</section>