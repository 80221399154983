<section class="cmn-innerpage-wrapper invoice-new">
    <h2 class="cmn-inner-heading">
        {{ noteId ? "Update Debit Note" : "New Debit Note"}}
    </h2>
    <form [formGroup]="FormGroupItems" (ngSubmit)="onSubmit()">
        <div class="invoice-new-form-wrapper">
            <div class="form-wrapper invoice-form-wrapper">
                <!-- Client Name Section -->
                <div class="cmn-Search-select-container">
                    <label for="clientName">Vendor Name</label>
                    <mat-form-field>
                        <mat-select placeholder="Vendor Name" id="clientName" formControlName="customerName" [ngClass]="{
                'mat-select-error':
                  FormGroupItems.get('customerName')!.touched &&
                  FormGroupItems.get('customerName')!.hasError('required')
              }" (selectionChange)="onCustomerSelected($event)">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="customerSearchControl"
                                    placeholderLabel="Search Vendor"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let partners of partnersList" [value]="partners.id">
                                {{ partners?.displayName }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="
                FormGroupItems.get('customerName')!.touched &&
                FormGroupItems.get('customerName')!.hasError('required')
              ">
                            Vendor Name is required.
                        </mat-error>
                    </mat-form-field>
                    <!-- <button (click)="openCreateClientDialog()">
                        <div>
                          <img
                            src="../../../assets/images/icons/icon-add-color.svg"
                            alt="Add client"
                          />
                        </div>
                        <span>Add Vendor</span>
                      </button> -->
                </div>

                <!-- Invoice Number Section -->
                <div>
                    <label for="invoiceNumber">Debit Note#</label>
                    <mat-form-field>
                        <input matInput placeholder="IN-000212" id="invoiceNumber" formControlName="creditNoteNo" />
                        <mat-error *ngIf="
                FormGroupItems.get('creditNoteNo')!.touched &&
                FormGroupItems.get('creditNoteNo')!.hasError('required')
              ">
                            Debit Note is required.
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Order Number Section -->
                <div>
                    <label for="reference">Reference#</label>
                    <mat-form-field>
                        <input matInput placeholder="Reference number" id="reference" formControlName="reference" />
                        <mat-error *ngIf="FormGroupItems.get('reference')?.hasError('maxlength')">
                            Reference cannot exceed 10 characters
                          </mat-error>
                    </mat-form-field>
                </div>

                <!-- Invoice Date Section -->
                <div>
                    <label for="creditNoteDate">Debit Note Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="invoiceDatePicker" placeholder="Select Date"
                            id="creditNoteDate" formControlName="creditNoteDate" />
                        <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #invoiceDatePicker></mat-datepicker>
                        <mat-error *ngIf="
                FormGroupItems.get('creditNoteDate')!.touched &&
                FormGroupItems.get('creditNoteDate')!.hasError('required')
              ">
                            Debit Note Date is required.
                        </mat-error>
                    </mat-form-field>
                </div>


                <div>
                    <label for="subject">Subject</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Subject" id="subject" formControlName="subject" />
                        <mat-error *ngIf="
                FormGroupItems.get('subject')!.touched &&
                FormGroupItems.get('subject')!.hasError('required')
              ">
                            Subject is required.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="billing-shipping-address-wrapper" *ngIf="selectedCustomerId">
            <div>
                <div class="header-wrapper">
                    <h5>Billing Address</h5>
                    <div (click)="openSelectBillingAddressDialog()">
                        <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="" />
                    </div>
                </div>
                <div *ngIf="selectedBillingAddress">
                    <h6>{{ selectedBillingAddress.attention }}</h6>
                    <ul>
                        <li>{{ selectedBillingAddress.addressLine1 }}</li>
                        <li>{{ selectedBillingAddress.addressLine2 }}</li>
                        <li>{{ selectedBillingAddress.city }}</li>
                        <li>{{ selectedBillingAddress.state }}</li>
                        <li>{{ selectedBillingAddress.country }}</li>
                        <li>{{ selectedBillingAddress.phone }}</li>
                    </ul>
                </div>
                <button type="button" (click)="openBillingAddressDialog('add')">
                    <span>Add Address</span>
                </button>
            </div>

            <div>
                <div class="header-wrapper">
                    <h5>Shipping Address</h5>
                    <div (click)="openSelectShippingAddressDialog()">
                        <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="" />
                    </div>
                </div>
                <div *ngIf="selectedShippingAddress">
                    <h6>{{ selectedShippingAddress.attention }}</h6>
                    <ul>
                        <li>{{ selectedShippingAddress.addressLine1 }}</li>
                        <li>{{ selectedShippingAddress.addressLine2 }}</li>
                        <li>{{ selectedShippingAddress.city }}</li>
                        <li>{{ selectedShippingAddress.state }}</li>
                        <li>{{ selectedShippingAddress.country }}</li>
                        <li>{{ selectedShippingAddress.phone }}</li>
                    </ul>
                </div>
                <button type="button" (click)="openShippingAddressDialog('add')">
                    <span>Add Address</span>
                </button>
            </div>
        </div>

        <div class="item-service-adding-table-wrapper">
            <table>
                <thead>
                    <tr>
                        <th>Item/Service</th>
                        <th>Account</th>
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Discount</th>
                        <th>Amount</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody formArrayName="items">
                    <tr *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
                        <td>
                            <div class="form-wrapper">
                              <div>
                                <mat-form-field>
                                  <input
                                    matInput
                                    placeholder="Select or Type Item"
                                    [matAutocomplete]="auto"
                                    formControlName="itemName"
                                    (input)="onItemSearch($event)"
                                  />
                                  <mat-autocomplete
                                    #auto="matAutocomplete"
                                    (optionSelected)="onSelectItem($event)"
                                  >
                                    <mat-option
                                      *ngFor="let item of filteredItemData"
                                      [value]="item.id"
                                    >
                                      {{ item.itemName }}
                                    </mat-option>
                                  </mat-autocomplete>
                                  <mat-error
                                    *ngIf="item.get('itemName')?.errors?.['required']"
                                  >
                                    Item is required.
                                  </mat-error>
                                </mat-form-field>
                              </div>
                            </div>
                          </td>

                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <mat-select placeholder="Enter Account" formControlName="account">
                                        <mat-option *ngFor="let acc of accountData" [value]="acc.id">
                                            {{ acc.accountName }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="item.get('account')?.hasError('required')">
                                        Account is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </td>

                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput type="number" placeholder="Quantity" formControlName="quantity" />
                                    <mat-error *ngIf="item.get('quantity')?.hasError('required')">
                                        Quantity is required
                                    </mat-error>
                                    <mat-error *ngIf="item.get('quantity')?.hasError('min')">
                                        Quantity must be at least 1
                                    </mat-error>
                                    <mat-error *ngIf="item.get('quantity')?.hasError('pattern')">
                                        quantity must be a valid number.
                                      </mat-error>
                                </mat-form-field>
                            </div>
                        </td>

                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput type="number" placeholder="Rate" formControlName="rate" />
                                    <mat-error *ngIf="item.get('rate')?.hasError('required')">
                                        Rate is required
                                    </mat-error>
                                    <mat-error *ngIf="item.get('rate')?.hasError('min')">
                                        Rate cannot be negative
                                    </mat-error>
                                    <mat-error *ngIf="item.get('rate')?.hasError('pattern')">
                                      Rate must be a valid number (only digits and up to 2 decimal places allowed).
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </td>

                        <td>
                            <div class="form-wrapper">
                                <div class="split-parent">
                                    <div class="left-set">
                                        <mat-form-field>
                                            <input matInput type="number" placeholder="Discount"
                                                formControlName="discount" />
                                            <mat-error *ngIf="item.get('discount')?.hasError('min')">
                                                Discount cannot be negative
                                            </mat-error>
                                            <mat-error *ngIf="
                                                item.get('discount')?.hasError('max') &&
                                                item.get('discount')?.touched
                                              ">Discount cannot be greater than 100%.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="right-set">
                                        <mat-form-field>
                                            <mat-select placeholder="%">
                                                <!-- Optional: Add logic for percentage or fixed value -->
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </td>

                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput type="number" placeholder="Total" formControlName="total" />
                                    <mat-error *ngIf="item.get('total')?.hasError('required')">
                                        Total is required
                                    </mat-error>
                                    <mat-error *ngIf="item.get('total')?.hasError('min')">
                                        Total cannot be negative
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </td>

                        <td>
                            <img src="../../../assets/images/icons/icon-close.svg" alt="Remove"
                                (click)="removeItem(i)" />
                        </td>
                    </tr>
                </tbody>

            </table>
            <button type="button" (click)="addItem()">
                <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add" (click)="addItem()" />
                Add
            </button>
        </div>

        <!-- <div class="quotation-table-container">

            <div>
                <table>

                    <thead>
                        <tr>
                            <th>

                            </th>
                            <th>Item/Service</th>
                            <th>Description</th>
                            <th>Unit</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Discount</th>
                            <th>Amount</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr *ngFor="let item of arr">
                            <td>{{item}}</td>
                            <td>Floor Cleaning</td>
                            <td>Cleaning</td>
                            <td>pcs</td>
                            <td>1</td>
                            <td><sub>aed</sub>150</td>
                            <td>5%</td>
                            <td><sub>aed</sub>150</td>
                            <td><img src="../../../assets/images/icons/icon-edit-accounts.svg" alt=""></td>
                        </tr>


                    </tbody>
                </table>
            </div>

        </div> -->

        <div class="notes-tatal-amount-container">
            <div class="form-container">
                <div class="form-wrapper">
                    <label for="customerNotes">Vendor Notes</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter your notes here..."
                            formControlName="customerNote"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="termsConditions">Terms & Conditions</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter the terms & conditions"
                            formControlName="termsConditions"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="amount-table-div">
                <div>
                    <h6>Discount <span>: AED -50.00</span></h6>
                    <h6>VAT amount <span>: AED +50.00</span></h6>
                    <h6>Adjustment <span>: AED 0.00</span></h6>
                </div>
                <div>
                    <h4>Total(AED) <span>: {{ getTotalAmount() }}</span></h4>
                </div>
            </div>
        </div>

        <div class="submit-btn-wrapper">
            <button class="cmn-cancel-btn" type="reset">Cancel</button>
            <button class="cmn-draft-btn" type="submit">Save as Draft</button>
            <button class="cmn-next-btn" type="submit">Save as Open</button>
        </div>
    </form>
</section>