export const environment = {
  production: false,
  authUrl: 'https://api-access-gateway.techlabz.in/munawel-be-staging',
  url: {
    defaultUrl:
      'https://api-access-gateway.techlabz.in/munawel-be-staging/graphql',
    hrmsModuleUrl:
      'https://api-access-gateway.techlabz.in/munawel-hrms-staging/graphql',
    accountsUrl:
      'https://api-access-gateway.techlabz.in/munawel-accounting-staging/graphql',
    projectModuleUrl:
      'https://api-access-gateway.techlabz.in/munawel-project-staging/graphql',
    communicationModuleUrl:
      'https://api-access-gateway.techlabz.in/munawel-communication-staging/graphql',
  },
};
