<div class="profile-inner-main-wrapper">
  <div class="details-wrapper">
    <h2>Basic Salary</h2>
    <h3 *ngIf="employeeData?.employeeSalaries?.length > 0">
      {{employeeData?.employeeSalaries[0]?.basicSalary}}
    </h3>
  </div>
  <div class="details-wrapper">
    <h2>House Rent Allowance</h2>
    <h3 *ngIf="employeeData?.employeeSalaries?.length > 0">
      {{employeeData?.employeeSalaries[0]?.hra}}
    </h3>
  </div>
  <div class="details-wrapper">
    <h2>Allowances</h2>
    <h3 *ngIf="employeeData?.employeeSalaries?.length > 0">
      {{employeeData?.employeeSalaries[0]?.allowances}}
    </h3>
  </div>
  <div class="details-wrapper">
    <h2>Deductions</h2>
    <h3 *ngIf="employeeData?.employeeSalaries?.length > 0">
      {{employeeData?.employeeSalaries[0]?.deductions}}
    </h3>
  </div>
  <div class="details-wrapper">
    <h2>Gross Salary</h2>
    <h3 *ngIf="employeeData?.employeeSalaries?.length > 0">
      {{employeeData?.employeeSalaries[0]?.grossSalary}}
    </h3>
  </div>
  <div class="details-wrapper">
    <h2>Net Salary</h2>
    <h3 *ngIf="employeeData?.employeeSalaries?.length > 0">
      {{employeeData?.employeeSalaries[0]?.netSalary}}
    </h3>
  </div>
</div>
