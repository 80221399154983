<section class="cmn-innerpage-wrapper grn-details">
    <div class="head">
        <div class="search-wrapper">
            <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)"/>
        </div>
        <!-- <button class="cmn-upload-btn" (click)="openDialog()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload</span>
            </button> -->
        <button class="cmn-add-btn" (click)="navigateTo('/grn/grn-new')">
            <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New GRN
        </button>
        <!-- <button class="cmn-filter-btn" (click)="toggleDropdown($event)" [ngClass]="{ 'class-clicked': isDropdownOpen }">
            <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
        </button> -->
        <!-- <span *ngIf="selectedCount > 0">{{ selectedCount }}</span> -->
        <!-- Filter -->
        <!-- <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <form>
                    <h6 class="cmn-popup-heading">
                        Filter <span>Clear All</span>
                    </h6>
                    <div class="filter-inner-content">
                        <h2>Branch</h2>
                        <div class="check-wrapper">
                            <div class="check-box">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" />
                                    test
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="submit">Apply</button>
                    </div>
                </form>
            </div> -->
        <!-- Filter -->
    </div>
    <div class="sub-head">
        <ul>
            <li class="main">All GRN’s</li>
            <li class="edit"><img src="assets/images/icons/icon-send.svg" alt="Mail"> Send to mail</li>
            <li class="edit" (click)="toggleComments($event)"><img src="assets/images/icons/icon-comment.svg"
                    alt="Apply"> Comments & History</li>
            <li class="edit" (click)="onEdit()"><img src="assets/images/icons/icon-edit-accounts.svg"
                    alt="Edit"> Edit</li>
        </ul>
        <!-- Comments Dropdown -->
        <div [ngClass]="{ show: isCommentsOpen }" class="common-dropdown filter-position" #dropdown2>
            <button class="close-dropdown" (click)="toggleComments($event)"></button>
            <form [formGroup]="mailForm" (ngSubmit)="onMail()">
                <h6 class="cmn-popup-heading">
                    Comments
                </h6>
                <div class="filter-inner-content">
                    <div class="form-wrapper">
                        <mat-form-field>
                            <textarea matInput placeholder="Add Your Comment Here..." formControlName="mails"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="submit">Comment</button>
                    </div>
                    <div class="timelinne-wrapper"  *ngFor="let mail of mails">
                        <!-- ngFor -->
                        <div class="timeline-box">
                            <div class="timeline-head">
                                <ul>
                                    <li>{{mail?.employee?.firstName || '--'}}</li>
                                        <li>{{mail?.createdAt | date: 'dd/MM/YYYY'}}</li>
                                </ul>
                                <h3>{{mail?.createdAt | date: 'hh:mm a'}}</h3>
                            </div>
                            <div class="timeline-content">
                                <h4>{{mail?.note || '--'}}</h4>
                            </div>
                        </div>
                        <!-- ngFor -->
                    </div>
                </div>
            </form>
        </div>
        <!-- Comments Dropdown -->
        <div class="mat-menu-wrapper">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onConfirmDelete()">
                    <span>Delete</span>
                </button>
            </mat-menu>
        </div>
    </div>
    <div class="manual-journal-details-tab-wrapper">
        <div class="tab-left">
            <div class="tab-box-main-wrapper" *ngFor="let grn of allGrns; let i = index">
                <button class="tab-custom-button" [class.active]="selectedTabIndex === i" (click)="selectTab(i)">
                    <div class="inn-box">
                        <h2>{{grn?.purchaseOrderData?.customer?.displayName || '--'}}</h2>
                        <h3>{{grn?.grnNumber || '--'}}</h3>
                    </div>
                    <div class="inn-box">
                        <h6>{{grn?.goodsReceivedDate | date: 'dd-MM-YYYY'}}</h6>
                        <h5>AED <span>{{grn?.purchaseOrderData?.totalPrice || '0.00'}}</span></h5>
                    </div>
                    <div class="inn-box">
                        <h4 [ngClass]="grn?.grnStatus === 'Draft' ? 'red' : 'green'">
                            {{grn?.grnStatus || '--'}}
                          </h4>
                    </div>
                </button>
            </div>
        </div>
        <div class="manual-journal-details-content-wrapper">
            <div>
                <div class="chart-tab-content">
                    <div class="tab-inner">
                        <div class="note-header">
                            <div class="left-set">
                                <h4>{{grnData?.purchaseOrderData?.shippingAddressData?.addressLine1}}</h4>
                                <h3>{{grnData?.purchaseOrderData?.shippingAddressData?.addressLine2}}</h3>
                                <h3>{{grnData?.purchaseOrderData?.shippingAddressData?.city}}
                                    {{grnData?.purchaseOrderData?.shippingAddressData?.state}}
                                    {{grnData?.purchaseOrderData?.shippingAddressData?.country}}</h3>
                            </div>
                            <div class="right-set">
                                <h2>GRN</h2>
                                <h3># {{grnData?.grnNumber || '--'}}</h3>
                                <h3>{{grnData?.goodsReceivedDate | date: 'dd/MM/YYYY' }}</h3>
                            </div>
                        </div>

                        <div class="journal-details">
                            <div class="left-set">
                                <div class="details-box">
                                    <h4>Received By </h4>
                                    <h5>:</h5>
                                    <h6>{{grnData?.receivedByData?.firstName + ' ' + grnData?.receivedByData?.lastName || '--'}}</h6>
                                </div>
                                <!-- <div class="details-box">
                                        <h4>Subject</h4>
                                        <h5>:</h5>
                                        <h6>Regarding Purchase</h6>
                                    </div> -->
                            </div>
                            <div class="right-set">
                                <div class="details-box">
                                    <h4>Reference</h4>
                                    <h5>:</h5>
                                    <h6>{{grnData?.purchaseOrderData?.purchaseOrderNo}}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="crdit-note-page-table">
                            <table>
                                <tr>
                                    <th>Item/Service</th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <!-- <th>Quantity left</th> -->
                                    <th>Item Status</th>
                                    <th>Remark</th>
                                </tr>
                                <tr *ngFor="let item of grnData?.purchaseOrderData?.purchaseItems">
                                    <td>{{itemName || '--'}}</td>
                                    <td>{{item?.description || '--'}}</td>
                                    <td>{{balanceQuantity || '--'}}</td>
                                    <!-- <td>{{item?.quantity || '--'}}</td> -->
                                    <td>{{grnItemStatus || '--'}}</td>
                                    <td>{{remark || '--'}} </td>
                                </tr>
                            </table>
                        </div>

                        <p class="signature">Authorized Signature <span></span></p>
                        <span class="published-note" *ngIf="grnData?.grnStatus === 'Completed'"><img
                                src="assets/images/img-closed.svg" alt="Open"></span>
                        <span class="published-note" *ngIf="grnData?.grnStatus === 'Draft'"><img
                                src="assets/images/img-draft.svg" alt="Draft"></span>
                        <!-- <span class="published-note" *ngIf="grnData?.grnStatus === 'Paid'"><img
                                src="assets/images/img-overdue.svg" alt="Overdue"></span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>