import { Component, Inject, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { Observable, Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';

import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { noSpaceAtStartOrEnd } from '../../helpers/helper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
  selector: 'app-return-request-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    AgGridAngular,
    PermissionDirective,
    MatOptionModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    NgxMatSelectSearchModule
  ],
  templateUrl: './return-request-details.component.html',
  styleUrl: './return-request-details.component.scss',
})
export class ReturnRequestDetailsComponent implements OnInit {
  selectedTabIndex = 0; // Default to the first tab
  itemId: any;
  itemData: any = {};
  allItems: any[] = [];
  public loading: boolean = true;
  private searchSubject = new Subject<string>();
  public Permission: any = Permission;
  isNewRequest: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private itemService: InventoryItemService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.fetchAllItems();
    this.route.paramMap.subscribe((params) => {
      this.itemId = params.get('id');
      if (this.itemId) {
        this.fetchAllItems();
        const id = +this.itemId;
        this.itemService.fetchItemById(id).subscribe({
          next: (data) => {
            console.log(data);
          },
        });
      }
    });
    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchAllItems(searchTerm);
      });
      this.route.queryParams.subscribe(params => {
        this.isNewRequest = params['newRequest'] === 'true';
      });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }
  sentRequest() {
    this.isNewRequest = false;
  }
  fetchAllItems(search: string = '', filter: any = {}) {
    this.loading = true;
    this.itemService.fetchItems(search, filter).subscribe({
      next: (items) => {
        this.allItems = items;
        if (this.itemId) {
          const targetId = +this.itemId;
          this.selectedTabIndex = this.allItems.findIndex((c: any) => {
            return c.id === targetId;
          });
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
        this.itemData = this.allItems[this.selectedTabIndex];
      },
      error: (err) => {
        console.error('Error fetching all items:', err);
      },
    });
  }

  navigateToAddNew() {
    this.router.navigate(['/warehouse/warehouse-new']);
  }
  openApprovalRejectDialog() {
      const dialogRef = this.dialog.open(RejectApprovalDialog);
    }

  onCellClicked(event: any) {
    this.isNewRequest = false; // Show p tag with status
  }

  navigateToEditPage(): void {
    const selectedJournal = this.allItems[this.selectedTabIndex];
    this.itemId = selectedJournal.id;

    this.router.navigate([`inventory/inventory-edit/${this.itemId}`]);
  }

  getStatusClass(status: string): string {
    return status === 'Completed' ? 'text-completed' : 'text-pending';
  }

  addItem(): void {
    const itemGroup = this.fb.group({
      selectItem: [null],
      itemName: [''], // Optional for manual entry
      // account: ['', Validators.required],
      quantity: [
        0,
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
        ],
      ],
      rate: [
        0,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
        ],
      ],
      discount: [0, [Validators.min(0), Validators.max(100)]],
      total: [0, [Validators.required, Validators.min(0)]],
      subTotal: [0, [Validators.min(0), Validators.pattern(/^\d+(\.\d+)?$/)]],
      vatAmount: [0],
      netPrice: [''],
    });
  }

  readonly dialog = inject(MatDialog);

  rowData = [
    {
      itemName: 'Ultratech Cement',
      skuCode: 'CM12034562',
      rate: 'AED 2,500',
      unit: 'Kg',
    },
    {
      itemName: 'Ultratech Cement',
      skuCode: 'CM12034562',
      rate: 'AED 2,500',
      unit: 'Kg',
    },
  ];

  columnDefs = [
    {
      headerName: 'Name',
      field: 'itemName',
      flex: 1,
    },
    { headerName: 'SKU', field: 'skuCode', flex: 1 },
    { headerName: 'Rate', field: 'rate', flex: 1 },
    { headerName: 'Unit', field: 'unit', flex: 1 },
  ];
  addedRowData = [
    {
      space: 'Lorem',
      stack: 'AAB',
      row: '32236',
      column: '2563',
    },
    {
      space: 'Lorem',
      stack: 'AAB',
      row: '32236',
      column: '2563',
    },
    {
      space: 'Lorem',
      stack: 'AAB',
      row: '32236',
      column: '2563',
    },
  ];
  addedColumnDefs = [
    {
      headerName: 'Space',
      field: 'space',
      flex: 1,
    },
    { headerName: 'Stack', field: 'stack', flex: 1 },
    { headerName: 'Row', field: 'row', flex: 1 },
    { headerName: 'Column', field: 'column', flex: 1 },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
}


@Component({
  selector: 'reject-approval-dialog',
  templateUrl: 'reject-approval-dialog.html',
  styleUrls: ['./return-request-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
})
export class RejectApprovalDialog {
  addStorageForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log(this.data);

    if (!this.data) {
      this.addStorageForm = this.fb.group({
        space: ['', [Validators.required, noSpaceAtStartOrEnd]],
      });
    } else {
      this.addStorageForm = this.fb.group({
        space: ['', [Validators.required, noSpaceAtStartOrEnd]],
      });
    }
  }
}
