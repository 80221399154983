import { gql } from 'apollo-angular';

export const GET_MANUAL_JOURNALS = gql`
  query journals($search: String, $filter: JournalFilter) {
    journals(search: $search, filter: $filter) {
      id
      date
      journalNo
      reference
      notes
      currencyId
      currencyData {
        id
        currencyCode
        currencyName
        currencySymbol
        exchangeRate
        status
        isPrimary
        createdAt
        updatedAt
        deletedAt
      }
      inCash
      status
      createdAt
      updatedAt
      deletedAt
      journalEnteries {
        id
        chartOfAccountId
        ChartOfAccount {
          id
          accountType
          accountName
          accountCode
          description
        }
        journalId
        partnerId
        Partner {
          id
          partnerType
          customerType
          displayName
          email
          phone
          mobile
        }
        description
        debit
        credit
      }
      journalAttachments {
        id
        journalId
        fileName
        fileUrl
      }
    }
  }
`;

export const GET_PARTNERS = gql`
  query partners($search: String) {
    partners(search: $search) {
      id
      partnerType
      customerType
      displayName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      primaryContactId
      primaryContact {
        id
        salutation
        firstName
        lastName
        email
        phone
        mobile
        primaryContact
        status
        partnerId
      }
      contacts {
        id
        salutation
        firstName
        lastName
        email
        phone
        mobile
        primaryContact
        status
        partnerId
      }
    }
  }
`;

export const GET_PARTNER_BY_ID = gql`
  query partner($id: Int!) {
    partner(id: $id) {
      id
      partnerType
      customerType
      displayName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      primaryContactId
    }
  }
`;

export const GET_JOURNAL_BY_ID = gql`
  query journal($id: Int!) {
    journal(id: $id) {
      id
      date
      journalNo
      reference
      notes
      currencyId
      currencyData {
        id
        currencyCode
        currencyName
        currencySymbol
        exchangeRate
        status
        isPrimary
        createdAt
        updatedAt
        deletedAt
      }
      inCash
      status
      createdAt
      updatedAt
      deletedAt
      journalEnteries {
        id
        chartOfAccountId
        ChartOfAccount {
          id
          accountType
          accountName
          accountCode
          description
          parentAccountId
          isSubAccount
        }
        journalId
        partnerId
        Partner {
          id
          partnerType
          customerType
          displayName
          email
          phone
          mobile
          currency
          emirateID
          openingBalance
          paymentStatus
          status
        }
        description
        debit
        credit
      }
      journalAttachments {
        id
        journalId
        fileName
        fileUrl
      }
    }
  }
`;
