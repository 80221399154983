
    <section class="cmn-innerpage-wrapper invoice-new">
        <h2 class="cmn-inner-heading">{{billData ? 'Edit' : 'New'}} Vendor Invoice</h2>
        <form [formGroup]="newBillForm">
            <div class="invoice-new-form-wrapper">
                <div class="form-wrapper invoice-form-wrapper">

                    <!-- Client Name Section -->
                    <div class="cmn-Search-select-container">
                        <label for="vendorName">Vendor Name <span class="red">*</span></label>
                        <mat-form-field>
                            <mat-select [formControl]="vendorControl" placeholder="Search Vendor"
                                formControlName="partnerId">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="vendorSearchControl"
                                        placeholderLabel="Search vendor"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let vendor of filteredVendors" [value]="vendor.id">
                                    {{ vendor.displayName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button [routerLink]="['/vendors/vendor-new']">
                            <div><img src="../../../assets/images/icons/icon-add-color.svg"
                                alt="Add client"></div>
                            <span>Add Vendor</span>
                          </button>
                        <div *ngIf="
                        newBillForm.get('partnerId')!.invalid &&
                        newBillForm.get('partnerId')!.touched
                        " class="error">
                            Vendor is required.
                        </div>
                    </div>


                    <!-- Invoice Number Section -->
                    <div>
                        <label for="invoiceNumber">Vendor Invoice# <span class="red">*</span></label>
                        <mat-form-field>
                            <input matInput placeholder="Enter Bill No" formControlName="billNo" maxlength="15" />
                        </mat-form-field>
                        <div *ngIf="
                        newBillForm.get('billNo')!.invalid &&
                        newBillForm.get('billNo')!.touched
                        " class="error">
                        Vendor invoice number is required.
                        </div>
                    </div>

                    <!-- Order Number Section -->
                    <div>
                        <label for="orderNumber">Order Number</label>
                        <mat-form-field>
                            <input matInput placeholder="Enter Order No" formControlName="orderNo" maxlength="15" />
                        </mat-form-field>
                    </div>

                    <!-- Invoice Date Section -->
                    <div>
                        <label for="invoiceDate">Vendor Invoice Date <span class="red">*</span></label>
                        <mat-form-field>
                            <input matInput [matDatepicker]="invoiceDatePicker" placeholder="Select Date"
                                formControlName="billDate" [matDatepickerFilter]="dateFilter"/>
                            <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #invoiceDatePicker></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="
                        newBillForm.get('billDate')!.invalid &&
                        newBillForm.get('billDate')!.touched
                        " class="error">
                        Vendor invoice date is required.
                        </div>
                    </div>
                    <!-- Terms Section -->
                    <div>
                        <label for="paymentTerms">Payment Terms</label>
                        <mat-form-field>
                            <mat-select placeholder="Due on receipt" id="paymentTerms" formControlName="paymentTerm"
                                (selectionChange)="onPaymentTermChange($event.value)">
                                <mat-option *ngFor="let term of paymentTermsValue" [value]="term">
                                    {{ term }}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="newBillForm.get('paymentTerm')!.touched && newBillForm.get('paymentTerm')!.hasError('required')">
                                Payment Term is required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <label for="dueDate">Due Date</label>
                        <mat-form-field>
                            <input matInput [matDatepicker]="dueDatePicker" placeholder="Select Date"
                                formControlName="dueDate" id="dueDate" />
                            <mat-datepicker-toggle matIconSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #dueDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <!-- Subject Section -->
                    <div>
                        <label for="subject">Subject</label>
                        <mat-form-field>
                            <textarea matInput placeholder="Invoice subject" formControlName="subject"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>

        <div class="item-service-adding-table-wrapper">

            <table>
                <thead>
                    <tr>
                        <th>
                            Item/Service
                        </th>
                        <!-- <th>
                            Amount </th> -->
                        <th>
                            Quantity </th>
                        <th>
                            Rate </th>
                        <!-- <th>
                            Customer Details </th> -->
                        <th>
                            Amount </th>
                        <th>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr [formGroup]="itemServiceForm">
                        <td>
                            <div class="form-wrapper">
                                <div>
                                    <mat-form-field>
                                        <mat-select placeholder="Select Item" formControlName="itemId">
                                            <mat-option *ngFor="let item of items" [value]="item?.id">
                                                {{ item?.itemName }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="itemServiceForm.get('itemId')?.hasError('required')">
                                            Select an item.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                            </div>
                        </td>
                        <!-- <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <mat-select placeholder="Account" formControlName="accountName">
                                        <mat-option *ngFor="let account of accounts" [value]="account.accountName">
                                            {{ account.accountName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </td> -->
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="quantity" formControlName="quantity" maxlength="6" pattern="^[0-9]*$"/>
                                    <mat-error *ngIf="itemServiceForm.get('quantity')?.hasError('positiveNumber')">
                                        Quantity must be greater than zero.
                                    </mat-error>
                                    <mat-error *ngIf="itemServiceForm.get('quantity')?.hasError('maxlength')">
                                        Quantity cannot exceed 6 digits.
                                      </mat-error>
                                      <mat-error *ngIf="itemServiceForm.get('quantity')?.hasError('pattern')">
                                        Only numeric values are allowed..
                                      </mat-error>
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="rate" formControlName="rate"
                                        [value]="itemValue?.sellingPrice" />
                                </mat-form-field>
                                <mat-error *ngIf="itemServiceForm.get('rate')?.hasError('pattern')">
                                    Only numeric values are allowed..
                                  </mat-error>
                            </div>
                        </td>
                        <!-- <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <mat-select placeholder="Search Customer" formControlName="customerName">
                                        <mat-option *ngFor="let customer of customers" [value]="customer.displayName">
                                            {{ customer.displayName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                        </td> -->
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="100" formControlName="amount" readonly />
                                </mat-form-field>
                            </div>
                        </td>
                        <td (click)="onAddItem()">
                            <img src="../../../assets/images/icons/icon-add-color.svg" alt="">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="quotation-table-container" *ngIf="addedItems.length > 0">

            <div>
                <table>

                    <thead>
                        <tr>
                            <th>Item/Service</th>
                            <!-- <th>Account</th> -->
                            <th>Quantity</th>
                            <th>Rate</th>
                            <!-- <th>Customer Details</th> -->
                            <th>Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody *ngIf="billData">
                        <tr *ngFor="let item of billData.billItems; let i = index">
                            <td>{{ item?.itemData?.itemName }}</td>
                            <!-- <td>{{ item.accountName }}</td> -->
                            <td>{{ item?.quantity }}</td>
                            <td>{{ item?.rate }}</td>
                            <!-- <td>{{ item.customerName }}</td> -->
                            <td>{{ item?.amount }}</td>
                            <td>
                                <img src="assets/images/icons/icon-delete.svg" alt="Delete Item"
                                    style="text-align: left;" (click)="onRemoveItem(i, item?.id)">
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!billData">
                        <tr *ngFor="let item of addedItems; let i = index">
                            <td>{{ item?.itemName }}</td>
                            <!-- <td>{{ item.accountName }}</td> -->
                            <td>{{ item?.quantity }}</td>
                            <td>{{ item?.rate }}</td>
                            <!-- <td>{{ item.customerName }}</td> -->
                            <td>{{ item?.amount }}</td>
                            <td>
                                <img src="assets/images/icons/icon-delete.svg" alt="Delete Item"
                                    style="text-align: left;" (click)="onRemoveAddedItem(i)">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>

        <div class="notes-tatal-amount-container">
            <form [formGroup]="notesForm">
                <div class="form-container">
                    <div class="form-wrapper">
                        <label for="customerNotes">Notes</label>
                        <mat-form-field>
                            <textarea matInput placeholder="Enter your notes here..."
                                formControlName="notes"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="upload-file-group">
                        <h2>Documents</h2>
                        <input #fileInput type="file" style="display: none" formControlName="fileUrl"
                            (change)="onFileSelected($event)" />
                        <button class="cmn-upload-btn" (click)="fileInput.click()">
                            <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                            <span>Upload File</span>
                        </button>
                        <div class="uploaded-file" *ngIf="billData?.billAttachments?.length > 0">
                            <h6 *ngFor="let document of billData?.billAttachments">
                                <span (click)="onViewDocument(document?.fileUrl)" style="cursor: pointer">
                                    {{ document?.fileName }}
                                </span>
                                <img src="assets/images/icons/icon-close.svg" alt="Close Icon"
                                    (click)="onRemoveDocument(document?.id)" />
                            </h6>
                        </div>
                        <div class="uploaded-file" *ngIf="billData">
                        <h6 *ngIf="uploadedName">
                            <span *ngIf="uploadedName" (click)="onViewDocument(fileUrl)" style="cursor: pointer">
                                {{ uploadedName }}
                            </span>
                            <img src="assets/images/icons/icon-close.svg" alt="Close Icon"
                                (click)="removeFile()" />
                        </h6>
                        </div>
                        <div class="uploaded-file" *ngIf="fileName && !billId">
                            <h6>
                                <span (click)="onViewDocument(fileUrl)" style="cursor: pointer">
                                    {{ fileName }}
                                </span>
                                <img src="assets/images/icons/icon-close.svg" alt="Close Icon" (click)="removeFile()" />
                            </h6>
                        </div>
                    </div>
                </div>
            </form>
            <div class="amount-table-div">
                <div>
                    <!-- <h6>Discount <span>: AED -50.00</span></h6> -->
                    <h6>Sub Total<span>: AED {{subTotal}}</span></h6>
                    <!-- <h6>Adjustment <span>: AED 0.00</span></h6> -->
                </div>
                <div>
                    <h4>Total(AED) <span>: AED {{totalPrice}}</span></h4>
                </div>
            </div>
        </div>

        <div class="submit-btn-wrapper" *ngIf="!billData && !billId">
            <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
            <button class="cmn-draft-btn" (click)="onSaveAsDraft()">Save as Draft</button>
            <button class="cmn-next-btn" type="submit" (click)="onSaveAsOpen()">Save as Open</button>
        </div>
        <div class="submit-btn-wrapper" *ngIf="billId && billData">
            <button class="cmn-cancel-btn">Cancel</button>

            <!-- Save as Draft button (displayed always) -->
            <button class="cmn-draft-btn" *ngIf="billData?.billStatus === 'Open'" (click)="onSaveAsDraft()">
                Save as Draft
            </button>

            <!-- Save button, displayed when billStatus is 'Draft' -->
            <button class="cmn-draft-btn" *ngIf="billData?.billStatus === 'Draft'" (click)="onSaveAsOpen()">
                Save as Open
            </button>

            <!-- Save button, displayed when billStatus is 'Open' -->
            <button class="cmn-next-btn" *ngIf="billData?.billStatus === 'Open'" (click)="onSaveAsOpen()">
                Save
            </button>

            <button class="cmn-next-btn" *ngIf="billData?.billStatus === 'Draft'" (click)="onSaveAsDraft()">
                Save
            </button>


        </div>


    </section>
