import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { Apollo } from "apollo-angular";
import { EmployeeService } from "../../../features/employees/services/employee.service";
import { ToastrService } from "ngx-toastr";
import { customEmailValidator } from "../../../shared/services/validations";

@Component({
  selector: 'portal-access-dialog',
  templateUrl: './portal-access-dialog.html',
  styleUrls: ['../employees.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
  ],
})
export class PortalAccessDialog implements OnInit {
  portalAccessForm: FormGroup;
  employeeId: number;
  loading: boolean = false;
  isPortalUserValue: boolean = false;
  employeeData: any;
  profiles: any[] = [];
  branches: any[] = [];
  selectedBranches: string[] = [];
  isEditChecked: boolean = false;

  constructor(
    private fb: FormBuilder,
    private apollo: Apollo,
    private employeeService: EmployeeService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<PortalAccessDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.portalAccessForm = this.fb.group({
      email: ['', [Validators.required, customEmailValidator()]],
      password: [{ value: '', disabled: true }],
      profileId: ['', Validators.required],
      branches: [[], Validators.required],
    });
  }

  ngOnInit(): void {
    this.fetchProfiles();
    this.fetchBranches();

    if (this.data) {
      this.employeeId = this.data.employeeId;
      this.employeeService.getEmployeeById(this.employeeId).subscribe({
        next: (data) => {
          this.employeeData = data;
          this.isPortalUserValue = this.employeeData?.isPortalUser;

          this.portalAccessForm.patchValue({
            email: this.employeeData?.email || '',
            profileId: this.employeeData?.user?.profileId || '',
            branches: this.employeeData?.user?.branchIds || []
          });

          this.selectedBranches = this.employeeData?.user?.branchIds || [];

          if (!this.isPortalUserValue) {
            this.isEditChecked = true;
            this.toggleEdit({ target: { checked: true } });
          }
        }
      });
    }
  }


  createPortalAccess(): void {
    if (this.portalAccessForm.invalid) {
      this.toastr.warning('Please fill all required fields.');
      this.portalAccessForm.markAllAsTouched();
    } else {
      const formValue = this.portalAccessForm.value;
      console.log("Portal access form values:", formValue);

      const passwordValue = this.isEditChecked ? formValue.password : null;

      const createPortalUserInput = {
        employeeId: this.employeeData?.id,
        isPortalUser: true,
        email: formValue.email,
        password: passwordValue,
        profileId: formValue.profileId,
        branchIds: [...this.selectedBranches]
      };

      this.loading = true;
      if (this.isPortalUserValue) {
        this.employeeService.updatePortalAccess(createPortalUserInput).subscribe({
          next: (response) => {
            this.loading = false;
            this.toastr.success('Portal access updated successfully.');
            this.dialogRef.close();
          }, error: (error) => {
            this.loading = false;
            this.toastr.error(error.message || 'Failed to update portal access.');
          }
        });
      } else {
        this.employeeService.createPortalAccess(createPortalUserInput).subscribe({
          next: (response) => {
            this.loading = false;
            console.log('API Response:', response);
            if (response) {
              this.toastr.success('Portal access created successfully.');
              this.dialogRef.close();
            } else {
              this.toastr.error('Unexpected response from API.');
            }
          },
          error: (error) => {
            this.loading = false;
            console.error('Error creating portal access.', error);
            this.toastr.error(error.message || 'Failed to create portal access.');
          }
        });
      }
    }
  }

  onRemoveAccess(employeeId: number): void {
    this.employeeService.removePortalAccess(employeeId).subscribe({
      next: () => {
        this.toastr.success('Portal access removed successfully.')
        this.dialogRef.close();
      }, error: (error) => {
        this.toastr.error(error.message || 'Failed to remove portal access.')
        console.error('Error removing portal access', error);
      }
    })
  }

  fetchProfiles() {
    this.employeeService.getUserRoles().subscribe({
      next: (roles) => {
        console.log("user roles:", roles);
        this.profiles = roles;
      }
    })
  }

  fetchBranches() {
    this.employeeService.getBranches().subscribe({
      next: (branches) => {
        this.branches = branches.filter(branch => branch.id !== 0);
      }
    })
  }

  toggleAllBranches(event: any) {
    if (!event || !event.target) return;

    if (event.target.checked) {
      this.selectedBranches = this.branches.map(branch => branch.id);
    } else {
      this.selectedBranches = [];
    }

    this.portalAccessForm.patchValue({ branches: this.selectedBranches });
  }

  toggleBranch(branchId: string, event: any) {
    if (!event || !event.target) return;

    if (event.target.checked) {
      if (!this.selectedBranches.includes(branchId)) {
        this.selectedBranches = [...this.selectedBranches, branchId]; // Ensure immutability
      }
    } else {
      this.selectedBranches = this.selectedBranches.filter(id => id !== branchId);
    }

    // Update form control value
    this.portalAccessForm.patchValue({ branches: this.selectedBranches });

    // Update "Select All" checkbox state
    const allSelected = this.selectedBranches.length === this.branches.length;
    const allCheckbox = document.getElementById('selectAllCheckbox') as HTMLInputElement;
    if (allCheckbox) {
      allCheckbox.checked = allSelected;
    }
    console.log("Updated Selected Branches:", this.selectedBranches);
  }

  forceLowercase(controlName: string): void {
    const control = this.portalAccessForm.get(controlName);
    if (control) {
      const value = control.value || '';
      control.setValue(value.toLowerCase(), { emitEvent: false })
    }
  }

  toggleEdit(event: any): void {
    this.isEditChecked = event.target.checked;
    const passwordControl = this.portalAccessForm.controls['password'];

    if (!this.isPortalUserValue || this.isEditChecked) {
      passwordControl.enable();
      passwordControl.setValidators(Validators.required);
    } else {
      passwordControl.disable();
      passwordControl.setValue(null);
      passwordControl.clearValidators();
    }

    passwordControl.updateValueAndValidity();
  }

}