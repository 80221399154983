import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-leave-grant',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatSelectModule
  ],
  templateUrl: './leave-grant.component.html',
  styleUrl: './leave-grant.component.scss'
})
export class LeaveGrantComponent {

}
