import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { WorkOrderService } from '../../features/workOrder/services/workOrder.service';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'app-work-order',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatInputModule, AgGridAngular],
  templateUrl: './work-order.component.html',
  styleUrl: './work-order.component.scss',
})
export class WorkOrderComponent {
  private searchSubject = new Subject<string>();
  constructor(
    private router: Router,
    private workorderService: WorkOrderService,
    private toastr: ToastrService
  ) {}

  rowData = [];

  columnDefs = [
    {
      headerName: 'Work Order Number',
      field: 'workOrderNo',
      flex: 1,
    },
    { headerName: 'Customer Name', field: 'customerName', flex: 1 },
    { headerName: 'SOW', field: 'sow', flex: 1 },
    { headerName: 'Project Name', field: 'projectName', flex: 1 },
    { headerName: 'Project Manager', field: 'projectManager', flex: 1 },
    { headerName: 'Project Engineer', field: 'projectEngineer', flex: 1 },
    { headerName: 'Project Designer', field: 'projectDesigner', flex: 1 },
    // {
    //   headerName: 'Status',
    //   field: 'status',
    //   flex: 1,
    //   cellRenderer: (params: any) => {
    //     const status = params.data.status;
    //     const statusClass = status === 'On Leave' ? 'on-leave' : 'working'; // Apply class if "On Leave"
    //     return `<span class="${statusClass}">${status}</span>`;
    //   },
    // },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const editIcon = document.createElement('img');
        editIcon.src = 'assets/images/icons/icon-edit.svg';
        editIcon.style.cursor = 'pointer';
        editIcon.setAttribute('data-action', 'edit');

        const deleteIcon = document.createElement('img');
        deleteIcon.src = 'assets/images/icons/icon-delete.svg';
        deleteIcon.style.cursor = 'pointer';
        deleteIcon.setAttribute('data-action', 'delete');
        container.appendChild(editIcon);
        container.appendChild(deleteIcon);
        return container;
      },
      floatingFilter: false,
      filter: false,
    },
  ];

  ngOnInit() {
    this.fetchWorkOrders();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchWorkOrders(searchTerm);
      });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  private fetchWorkOrders(search: string = '') {
    this.workorderService.getAllWorkOrder(search).subscribe({
      next: (orders: any) => {
        // Sort the designations by 'id' in descending order so the newest branch comes first
        this.rowData = orders
          .map((order: any) => {
            return {
              workOrderNo: order?.workOrderNo || '--',
              customerName: order?.partnerData?.displayName || '--',
              projectName: order.projectData?.projectName || '--',
              projectManager:
                `${order.projectManagerData?.firstName || ''} ${
                  order.projectManagerData?.lastName || ''
                }` || '--',
              projectEngineer:
                `${order.projectInchargeData?.firstName || '--'} ${
                  order.projectInchargeData?.lastName || ''
                }` || '--',
              id: order?.id || '--',
              projectDesigner:
                `${order.projectDesignerData?.firstName || '--'} ${
                  order.projectDesignerData?.lastName || ''
                }` || '--',
            };
          })
          .sort((a: any, b: any) => b.id - a.id);
      },
      error: (error: any) => {
        // this.error = error;
        // this.loading = false;
      },
    });
  }

  onCellClicked(params: any) {
    const workOrderId = params.data?.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.onEdit(workOrderId);
      } else if (action === 'delete') {
        this.onConfirmDelete(workOrderId);
      }
    } else {
      this.router.navigate([`/work-order/work-order-details/${workOrderId}`]);
    }
  }

  onConfirmDelete(id: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onDelete(id);
      }
    });
  }
  onDelete(id: any) {
    this.workorderService.removeWorkOrder(id).subscribe({
      next: (result) => {
        if (result) {
          this.toastr.success('Work Order deleted successfully', 'Success');
          this.fetchWorkOrders();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      },
    });
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }
  onEdit(id: any) {
    this.router.navigate([`/work-order/work-order-edit/${id}`]);
  }

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(UploadWorkOrderDialog);
  }

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
}

@Component({
  selector: 'upload-work-order-dialog',
  templateUrl: 'upload-work-order-dialog.html',
  styleUrls: ['./work-order.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadWorkOrderDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}
