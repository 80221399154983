import { gql } from 'apollo-angular';

export const GET_ONE_WORK_ORDER = gql`
  query workOrder($id: Int!) {
    workOrder(id: $id) {
      id
      workOrderStatus
      workOrderNo
      partnerId
      partnerData {
        id
        displayName
        addresses {
          id
          attention
          addressType
          addressLine1
          addressLine2
          city
          state
          country
          pinCode
          phone
          fax
        }
      }
      projectId
      projectData {
        id
        projectName
      }
      SOW
      clientRef
      duration
      commencementDate
      completionDate
      projectManagerId
      projectManagerData {
        id
        firstName
        lastName
        middleName
        status
      }
      projectInchargeId
      projectInchargeData {
        id
        firstName
        lastName
        middleName
        status
      }
      projectDesigner
      projectDesignerData {
        id
        firstName
        lastName
        middleName
        status
      }
      procurementIncharge
      procurementInchargeData {
        id
        firstName
        lastName
        middleName
        status
      }
      estimator
      estimatorData {
        id
        firstName
        lastName
        middleName
        status
      }
      quoteId
      quoteData {
        id
        quoteCode
        quoteItems {
          amount
          description
          unit
          quantity
          rate
          amount
        }
      }
      addressId
      addressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      status
      createdAt
      updatedAt
      workOrderSOWs {
        id
        workOrderId
        order
        quantity
        unit
        rate
        netPrice
        status
      }
      attachedFiles {
        id
        workOrderId
        type
        fileName
        fileUrl
      }
    }
  }
`;

export const GET_ALL_WORK_ORDER = gql`
  query workOrders($search: String) {
    workOrders(search: $search) {
      id
      workOrderStatus
      workOrderNo
      partnerId
      partnerData {
        id
        displayName
      }
      projectId
      projectData {
        id
        projectName
      }
      SOW
      clientRef
      duration
      commencementDate
      completionDate
      projectManagerId
      projectManagerData {
        id
        firstName
        lastName
        middleName
        status
      }
      projectInchargeId
      projectInchargeData {
        id
        firstName
        lastName
        middleName
        status
      }
      projectDesigner
      projectDesignerData {
        id
        firstName
        lastName
        middleName
        status
      }
      procurementIncharge
      procurementInchargeData {
        id
        firstName
        lastName
        middleName
        status
      }
      estimator
      estimatorData {
        id
        firstName
        lastName
        middleName
        status
      }
      quoteId
      quoteData {
        id
        quoteCode
        quoteItems {
          amount
          description
          unit
          quantity
          rate
          amount
        }
      }
      addressId
      addressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      status
      createdAt
      updatedAt
      workOrderSOWs {
        id
        workOrderId
        order
        quantity
        unit
        rate
        netPrice
        status
      }
      attachedFiles {
        id
        workOrderId
        type
        fileName
        fileUrl
      }
    }
  }
`;

export const GENERATE_PDF = gql`
  query generateWorkOrderPdf($workOrderId: Int!, $withPrice: Boolean!) {
    generateWorkOrderPdf(workOrderId: $workOrderId, withPrice: $withPrice)
  }
`;
