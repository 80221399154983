import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(time: string): string {
    if (!time) return '';
    const [hour, minute] = time.split(':');
    let hours = parseInt(hour, 10);
    const suffix = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; 
    return `${this.padZero(hours)}:${minute} ${suffix}`;
  }

  padZero(time: number): string {
    return time < 10 ? '0' + time : time.toString();
  }
}
