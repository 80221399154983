import { REMOVE_JOURNAL, REMOVE_JOURNAL_ATTACHMENT_BY_ID } from './../graphql/mutations/manual-journals.mutation';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import {
  GET_JOURNAL_BY_ID,
  GET_MANUAL_JOURNALS,
  GET_PARTNERS,
} from '../graphql/querries/manual-journals.query';
import {
  CREATE_JOURNAL,
  REMOVE_JOURNAL_ENTRY_BY_ID,
  UPDATE_JOURNAL,
} from '../graphql/mutations/manual-journals.mutation';

@Injectable({
  providedIn: 'root',
})
export class ManualJournalService {
  constructor(private apollo: Apollo) {}

  //fetch manual journals
  fetchManualJournals(search: string , filter: {}): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_MANUAL_JOURNALS,
        variables: { search, filter },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data.journals));
  }

  //create journal
  createJournal(
    createJournalInput: any,
    createJournalEnteryInput: any[],
    createJournalAttachmentInput: any[]
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_JOURNAL,
      variables: {
        createJournalInput: createJournalInput,
        createJournalEnteryInput: createJournalEnteryInput,
        createJournalAttachmentInput: createJournalAttachmentInput,
      },
    });
  }

  updateJournal(
    journalInput: any,
    createJournalEntriesInput: any[],
    updateJournalEntriesInput: any[],
    createJournalAttachmentsInput: any[],
    updateJournalAttachmentsInput: any[]
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: UPDATE_JOURNAL,
      variables: {
        updateJournalInput: journalInput,
        createJournalEntriesInput,
        updateJournalEntriesInput,
        createJournalAttachmentsInput,
        updateJournalAttachmentsInput,
      },
    });
  }

  //fetch partners
  fetchPartners(search: string = ''): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_PARTNERS,
        variables: { search },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data.partners));
  }

  //get journal by id
  getJournalById(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_JOURNAL_BY_ID,
        variables: { id: id },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.journal || []));
  }

  //remove journal entry by id
  removeJournalEntryById(id: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_JOURNAL_ENTRY_BY_ID,
      variables: { id },
    });
  }

  //remove journal attachment by id
  removeJournalAttachmentById(id: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_JOURNAL_ATTACHMENT_BY_ID,
      variables: { id },
    });
  }

  //remove journal
  removeJournal(id: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_JOURNAL,
      variables: { id },
    });
  }
}
