import { gql } from 'apollo-angular';

export const GET_HOLIDAY_CALENDAR = gql`
  query holidayCalendars($holidayDate: String, $year: String, $search: String) {
    holidayCalendars(holidayDate: $holidayDate, year: $year, search: $search) {
      id
      holidayName
      holidayDate
      holidayType
      isMoon
      status
      description
    }
  }
`;

export const GET_HOLIDAY_CALENDAR_WITH_PAGINATION = gql`
  query findAllHolidayCalendarsWithPagination(
    $limit: Float!
    $offset: Float!
    $holidayDate: String
    $year: String
    $search: String
  ) {
    findAllHolidayCalendarsWithPagination(
      limit: $limit
      offset: $offset
      search: $search
      holidayDate: $holidayDate
      year: $year
    ) {
      holidayCalendars {
        id
        holidayName
        holidayDate
        holidayType
        isMoon
        status
        description
      }
      total
      limit
      offset
    }
  }
`;
