import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import {MatRippleModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {EmployeeService} from '../../../../features/employees/services/employee.service';
import {
    bankAccountValidator,
    drivingLicenseValidator,
    emiratesIdValidator,
    molNumberValidator,
    passportValidator,
    swiftCodeValidator,
    uaeIbanValidator,
    uaeVisaNumberValidator,
    visaValidator
} from '../../../../shared/services/validations';
import {Router} from '@angular/router';

@Component({
    selector: 'app-documents',
    standalone: true,
    imports: [
        CommonModule,
        MatTabsModule,
        MatRippleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    templateUrl: './documents.component.html',
    styleUrl: '../../add-employee.component.scss'
})
export class DocumentsComponent implements OnInit,
OnChanges {
    documentForm : FormGroup;
    public visaTypeData : any[] = [];
    today : Date = new Date();

    // Disable all dates before today
    dateFilter = (d : Date | null): boolean => {
        const currentDate = d || new Date();
        return currentDate >= this.today;
    };

    // Disable all dates after today
    issuedDateFilter = (d : Date | null): boolean => {
        const currentDate = d || new Date();
        return currentDate <= this.today;
    };

    @Input()employee : any = {};
    @Input()isEditMode : boolean = false;
    @Input()employeeData : any = {};
    @Output()formSubmit = new EventEmitter<any>();

    constructor(private fb : FormBuilder, private employeeService : EmployeeService, private router : Router, private datePipe : DatePipe) {
        this.documentForm = this.fb.group({
            visaType: [
                '', Validators.required
            ],
            visaNumber: [
                '',
                [
                    Validators.required, uaeVisaNumberValidator(),
                ],
            ],
            visaIssueDate: [
                null,
                [
                    Validators.required, this.issueDateValidator
                ]
            ],
            visaExpiryDate: [
                null,
                [
                    Validators.required, this.expiryDateValidator
                ]
            ],
            passportNumber: [
                '',
                [
                    Validators.required, passportValidator()
                ]
            ],
            passportIssueDate: [
                null,
                [
                    Validators.required, this.issueDateValidator
                ]
            ],
            passportExpiryDate: [
                null,
                [
                    Validators.required, this.expiryDateValidator
                ]
            ],
            emiratesIDNo: [
                '',
                [
                    Validators.required, emiratesIdValidator()
                ]
            ],
            drivingLicenseNumber: [
                '', drivingLicenseValidator(),
            ],
            laborCardExpiryDate: [
                null,
                [
                    Validators.required, this.expiryDateValidator
                ]
            ],
            bankName: [
                '',
                [
                    Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/),
                ],
            ],
            accountNumber: [
                '',
                [
                    Validators.required, bankAccountValidator()
                ]
            ],
            SWIFTCode: [
                '', swiftCodeValidator()
            ],
            MOLNo: [
                '',
                [molNumberValidator()]
            ],
            IFSCCode: [
                '',
                [
                    uaeIbanValidator()
                ]
            ]
        });
    }

    getFormattedDate(controlName : string): string {
        const date = this.documentForm.get(controlName) ?. value;
        return date ? this.datePipe.transform(date, 'dd MMM yyyy')! : '';
    }

    // Custom validator for expiry dates
    expiryDateValidator : ValidatorFn = (control : AbstractControl): ValidationErrors | null => {
        const date = control.value as Date;
        return this.dateFilter(date) ? null : {
            invalidExpiryDate: true
        };
    };

    // Custom validator for issue dates
    issueDateValidator : ValidatorFn = (control : AbstractControl): ValidationErrors | null => {
        const date = control.value as Date;
        return this.issuedDateFilter(date) ? null : {
            invalidIssueDate: true
        };
    };

    ngOnChanges(changes : SimpleChanges): void {
        if (changes['employee']) {
            if (this.isEditMode && this.employee) {
                this.populateFormWithEmployeeData();
            }
        }
    }

    populateFormWithEmployeeData() {
        if (this.employee) {
            console.log('employee---', this.employee);
            this.documentForm.patchValue({
                visaType: this.employee.visaType || '',
                visaNumber: this.employee.visaNumber || '',
                visaIssueDate: this.employee.visaIssueDate ? new Date(Number(this.employee.visaIssueDate)) : null,
                visaExpiryDate: this.employee.visaExpiryDate ? new Date(Number(this.employee.visaExpiryDate)) : null,
                passportNumber: this.employee.passportNumber || '',
                passportIssueDate: this.employee.passportIssueDate ? new Date(Number(this.employee.passportIssueDate)) : null,
                passportExpiryDate: this.employee.passportExpiryDate ? new Date(Number(this.employee.passportExpiryDate)) : null,
                emiratesIDNo: this.employee.emiratesIDNo || '',
                drivingLicenseNumber: this.employee.drivingLicenseNumber,
                laborCardExpiryDate: this.employee.laborCardExpiryDate ? new Date(Number(this.employee.laborCardExpiryDate)) : null,

                bankName: this.employee.bankName || '',
                accountNumber: this.employee.bankAccountNumber || '',
                SWIFTCode: this.employee.SWIFTCode || '',
                MOLNo: this.employee.MOLNo || '',
                IFSCCode: this.employee.IFSCCode || ''
            });
        } else {
            console.error('Employee is undefined or null');
        }
    }

    ngOnInit(): void {
        this.loadVisaTypeData();
    }

    private loadVisaTypeData() {
        this.employeeService.getVisaTypes().subscribe({
            next: (visaTypes) => {
                this.visaTypeData = visaTypes;
            },
            error: (error) => console.error(error)
        });
    }

    onNext(): void {
        if (this.documentForm.invalid) {
            this.documentForm.markAllAsTouched();
            console.log('Form is Invalid');
        } else {
            console.log('document Info Submitted-----', this.documentForm.value);
            this.formSubmit.emit(this.documentForm.value);
        }
    }

    onCancel(): void { // this.documentForm.reset();
        this.router.navigate(['/employees']);
    }
}
