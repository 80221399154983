<mat-dialog-content>
    <section class="upload-employees-wrapper">
      <h2 class="cmn-popup-title">Upload Employee Payroll</h2>
      <button class="cmn-close" mat-dialog-close></button>
      <form [formGroup]="uploadForm">
      <div class="cmn-upload-box" (dragover)="onDragOver($event)" 
      (drop)="onDrop($event)">
        <img src="assets/images/upload-image.png" alt="Upload Image" />
        <h3>Drag and drop your file here</h3>
        <h4>or</h4>
        <input
          #fileInput
          type="file"
          formControlName="file"
          (change)="onFileSelected($event)"
          style="display: none"
          accept=".csv"
        />
        <button class="cmn-choose-file-btn" (click)="fileInput.click()">
          <img
            src="assets/images/icons/icon-add-color.svg"
            alt="Icon Add"
          />Choose a File
        </button>
      </div>
      <div class="uploaded-file" *ngIf="file" >
        <h6>
          {{file?.name}}
          <img
            src="assets/images/icons/icon-close.svg"
            alt="Close Icon"
            (click)="removeFile()"
          />
        </h6>
      </div>
      <div class="upload-info">
        <h5>CSV Format only supported</h5>
        <a
          href="assets/sample-files/employee-payroll.csv"
          class="download-sample"
          download="employee-payroll.csv"
          >Download Sample CSV</a
        >
      </div>
      <div
      *ngIf="
    (uploadForm.get('file')!.invalid &&
    uploadForm.get('file')!.touched)
   && !file?.name"
      class="error"
    >
      Select a file.
    </div>
      <button class="cmn-upload-submit-btn active" (click)="initiatePayroll()">
        Initiate Payroll
      </button>
      </form>
    </section>
  </mat-dialog-content>