import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerService } from '../../../../features/customers/services/customer.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-remarks',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './remarks.component.html',
  styleUrl: '../../vendor-new.component.scss'
})
export class RemarksComponent {
  remarksForm: FormGroup;
  @Input() vendorData: any = {};
  @Output() formSubmit = new EventEmitter<any>();
  vendorId: any;
  
  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService,
    private route: ActivatedRoute,
  ){}

  ngOnInit() {
    this.initForm();
    this.route.paramMap.subscribe(params => {
      this.vendorId = params.get('id');
      if (this.vendorId) {
        this.fetchPartnerById();
        }
      });
  }

  initForm() {
    this.remarksForm = this.fb.group({
      remarkContent: ['']
    });
  }

  fetchPartnerById() {
    this.customerService.fetchPartnerById(Number(this.vendorId)).subscribe({
      next: (data) => {
        this.vendorData = data;
        this.populateFormWithVendorData();
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  populateFormWithVendorData(){
    if(this.vendorData){
      this.vendorData.remarks.map((remark: any) => {
        this.remarksForm = this.fb.group({
          id: Number(remark.id),
          remarkContent: [remark.remarkContent || '']
        });
      })
    }
  }

  onSubmit(){
    this.formSubmit.emit(this.remarksForm.value);
  }

  onCancel(){
    this.remarksForm.reset();
  }

}
