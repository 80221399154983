import { Component, OnInit, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../features/customers/services/customer.service';

@Component({
    selector: 'app-invoice-details',
    standalone: true,
    imports: [
        CommonModule,
        LayoutComponent,
        MatInputModule,
        MatTabsModule,
        MatMenuModule,
        MatIconModule,
        MatSelectModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    templateUrl: './invoice-details.component.html',
    styleUrl: './invoice-details.component.scss'
})
export class InvoiceDetailsComponent implements OnInit {
    selectedTabIndex = 0;
    invoiceId: any;
    customerId: any;
    invoiceData: any = {};
    dataInvoice: any = {};
    allInvoices: any[] = [];
    commentForm: FormGroup;
    comments: any;
    journalData: any;
    public loading: boolean = true;
    private searchSubject = new Subject<string>();

    constructor(private datePipe: DatePipe, private router: Router, private invoiceService: InvoiceService, private route: ActivatedRoute, private toastr: ToastrService, private customerService: CustomerService, private fb: FormBuilder) { }

    ngOnInit(): void {
        this.fetchAllInvoices();
        this.route.paramMap.subscribe((queryParams) => {
            this.invoiceId = queryParams.get('id');
            if (this.invoiceId) {
                const id = + this.invoiceId;
                this.invoiceService.fetchInvoiceById(id).subscribe({
                    next: (data) => {
                        this.customerId = data.partnerData.id;
                        this.invoiceData = data;
                    }
                });
            }

        });
        this.searchSubject.pipe(debounceTime(600), distinctUntilChanged()).subscribe((searchTerm) => {
            this.fetchAllInvoices(searchTerm);
        });
        this.commentForm = this.fb.group({ comments: [''] });
    }

    navigateTo(route: string) {
        this.router.navigate([route]);
    }

    navigateToRecordPayment(customerId: number, invoiceId: number) {
        this.router.navigate(['/payment-received/payment-new'], {
            queryParams: {
                customerId,
                invoiceId
            }
        });
    }

    navigateToSendMail(): void {
        const selectedInvoice = this.allInvoices[this.selectedTabIndex];
        const invoiceId = selectedInvoice.id;
        // this.router.navigate([`invoice/send-mail/${invoiceId}`]);
        this.router.navigate([`invoice/invoice-details/mail`], {
            queryParams: { invoiceId },
        });
    }

    onSearch(event: Event) {
        const input = event.target as HTMLInputElement;
        const searchTerm = input.value;
        this.searchSubject.next(searchTerm);
    }
    downloadPDF() {
        if (this.invoiceData && this.invoiceData.invoiceUrl) {
            window.open(this.invoiceData.invoiceUrl, '_blank');
        } else {
            this.toastr.error("Invoice PDF URL not available");
        }
    }

    onViewDocument() {
        const url = this.invoiceData.invoiceUrl;
        const token = localStorage.getItem('AUTH_TOKEN');
        fetch(url, {
            method: 'GET',
            headers: {
                'authorization': token ? `Bearer ${token}` : ''
            }
        }).then((response: any) => response.blob()).then((blob: any) => {
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
        }).catch((error: any) => {
            console.error('Error:', error)
        });
    }

    fetchComments() {
        const loginEmployee: any = localStorage.getItem('loggedInUser');
        const employee = JSON.parse(loginEmployee);
        const employeeId = employee?.employeeData?.id;
        const conditions = {
            employeeId: employeeId,
            refId: Number(this.invoiceId),
            type: 'Invoice',
            activityType: 'Comment'
        };
        this.customerService.comments(conditions).subscribe({
            next: (data) => {
                if (data) {
                    this.comments = data;
                }
            },
            error: (error) => {
                console.error(error, 'Error');
            }
        });
    }

    onComment() {
        const loginEmployee: any = localStorage.getItem('loggedInUser');
        const employee = JSON.parse(loginEmployee);
        const employeeId = employee?.employeeData?.id;
        const commentData = {
            employeeId: employeeId,
            refId: Number(this.invoiceId),
            type: 'Invoice',
            activityType: 'Comment',
            note: this.commentForm.value.comments
        };
        this.customerService.createComment(commentData).subscribe({
            next: (data) => {
                if (data) {
                    this.toastr.success('Comment added successfully!', 'Success');
                    this.commentForm.reset();
                    this.fetchComments();
                }
            },
            error: (error) => {
                console.error(error, 'Error');
            }
        });
    }

    getSubTotal(): number {
        if (!this.invoiceData?.invoiceItems)
            return 0;



        return this.invoiceData.invoiceItems.reduce((sum: number, item: any) => {
            const amount = item?.amount || 0;
            return sum + amount;
        }, 0);
    }

    fetchAllInvoices(search: string = '') {
        this.loading = true;
        this.invoiceService.fetchInvoices(search).subscribe({
            next: (invoices) => {
                this.allInvoices = invoices;

                if (this.invoiceId) {
                    const targetId = + this.invoiceId;
                    this.selectedTabIndex = this.allInvoices.findIndex((c: any) => {
                        return c.id === targetId;
                    });
                    if (this.selectedTabIndex === -1) {
                        this.selectedTabIndex = 0;
                    }
                } else {
                    this.selectedTabIndex = 0;
                }
                this.invoiceData = this.allInvoices[this.selectedTabIndex] || {};
                this.journalData = this.invoiceData.journalData || {};
            },
            error: (err) => {
                console.error('Error fetching all items:', err);
            }
        });
    }

    openInvoiceApplyCredits() {
        const dialogInvoiceApplyCreditsItemRef = this.dialog.open(InvoiceApplyCredits);
    }

    selectTab(index: number) {
        this.selectedTabIndex = index;
        this.invoiceData = this.allInvoices[index];
        this.journalData = this.invoiceData.journalData;
        if (this.invoiceData) {
            this.router.navigate(['invoice/invoice-details'], {
                queryParams: { id: this.invoiceData.id },
            });
        }
    }

    getDebitTotal(): number {
        return this.journalData?.journalEnteries?.reduce((sum: any, entry: any) => sum + (entry.debit || 0), 0) || 0;
    }

    getCreditTotal(): number {
        return this.journalData?.journalEnteries?.reduce((sum: any, entry: any) => sum + (entry.credit || 0), 0) || 0;
    }

    navigateToEditPage(): void {
        const selectedInvoice = this.allInvoices[this.selectedTabIndex];
        const invoiceId = selectedInvoice.id;
        this.router.navigate([`invoice/invoice-edit/${invoiceId}`]);
    }

    readonly dialog = inject(MatDialog);

    openDialog() {
        const dialogRef = this.dialog.open(UploadInvoiceDetailsDialog);
    }

    isDropdownOpen = false;
    isCommentsOpen = false;

    toggleDropdown(event?: MouseEvent) {
        if (event) {
            event.stopPropagation();
        }
        this.isDropdownOpen = !this.isDropdownOpen;
    }
    markASSent() {
        const loginEmployee: any = localStorage.getItem('loggedInUser');
        const employee = JSON.parse(loginEmployee);
        const employeeId = employee.employeeId;
        const id = + this.invoiceId;
        this.invoiceService.markAsSent(id, employeeId).subscribe({
            next: (response) => {
                this.toastr.success('Invoice sent successfully');
                this.fetchAllInvoices()
            },
            error: (error) => {
                this.toastr.error('Failed to sent invoice');
            }
        });
    }

    toggleComments(event?: MouseEvent) {
        if (event) {
            event.stopPropagation();
        }
        this.isCommentsOpen = !this.isCommentsOpen;
    }
}

@Component({
    selector: 'upload-invoice-details-dialog',
    templateUrl: 'upload-invoice-details-dialog.html',
    styleUrls: ['./invoice-details.component.scss'],
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatButtonModule]
})
export class UploadInvoiceDetailsDialog {
    file: any = null;

    onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const file = input.files[0];
            this.file = file;
        }
    }
}

@Component({
    selector: 'invoice-apply-credits-dialog',
    templateUrl: 'invoice-apply-credits-dialog.html',
    styleUrls: ['./invoice-details.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
    ]
})
export class InvoiceApplyCredits { }
