<section class="sidebar-wrapper" [ngClass]="{ collapsedClass: sidebarData }">
  <div class="header">
    <img src="assets/images/logo.svg" alt="Logo" />
  </div>
  <div class="nav-items-wrapper">
    <ul class="main-nav-list">
      <li
        class="nav"
        [ngClass]="{ 'active-class': isRouteActive('/dashboard') }"
        (click)="navigateTo('/dashboard')"
      >
        <img
          src="assets/images/icons/icon-dashboard-2.svg"
          alt="icon Dashboard"
          matTooltip="Dashboard"
          matTooltipPosition="right"
        />
        <h3>Dashboard</h3>
      </li>
      <!-- <li
        class="nav"
        [ngClass]="{ 'active-class': isRouteActive('/inventory') }"
        (click)="navigateTo('/inventory')"
        *appHasPermission="'2'"
      >
        <img
          src="assets/images/icons/icon-inventory-2.svg"
          alt="icon Inventory"
          matTooltip="Inventory"
          matTooltipPosition="right"
        />
        <h3>Inventory</h3>
      </li> -->
      <li
        class="nav dropdown-nav"
        [ngClass]="{
          'active-class': isRouteActive2([
            '/inventory',
            '/warehouse',
            '/request-item',
            '/return-request',
            '/item-purchase-order',
          ])
        }"
      >
        <mat-accordion>
          <mat-expansion-panel [expanded]="mattabnumber == 1">
            <mat-expansion-panel-header (click)="onPanelClick()">
              <mat-panel-title>
                <img
                  src="assets/images/icons/icon-inventory-2.svg"
                  alt="icon Inventory"
                  matTooltip="Inventory"
                  matTooltipPosition="right"
                />
                <h3>Inventory</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sub-nav">
              <li
                [ngClass]="{ active: isRouteActive('/inventory') }"
                (click)="navigateTo('/inventory')"
              >
                Items
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/warehouse') }"
                (click)="navigateTo('/warehouse')"
              >
                Warehouse
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/request-item') }"
                (click)="navigateTo('/request-item')"
              >
                Request Item
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/return-request') }"
                (click)="navigateTo('/return-request')"
              >
                Return Request
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/item-purchase-order') }"
                (click)="navigateTo('/item-purchase-order')"
              >
                Purchase Order Item
              </li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </li>
      <li
        class="nav dropdown-nav"
        [ngClass]="{
          'active-class': isRouteActive2([
            '/customers',
            '/customer-new',
            '/quotation',
            '/quotation-new',
            '/quotation/quotation-details',
            '/projects',
            '/project-inventory',
            '/add-project-inventory',
            '/work-order',
            '/work-order-details',
            '/work-order/work-order-details',
            '/work-order-new',
            '/invoice',
            '/invoice-new',
            '/invoice-details',
            '/payment-received/payment-new',
            '/payment-received',
            '/payment-received/payment-received-details',
            '/credit-note',
            '/credit-note-details',
            '/credit-note/credit-note-new'
          ])
        }"
        *appHasPermission="['4', '5', '6', '7', '8', '9', '10']"
      >
        <mat-accordion>
          <mat-expansion-panel [expanded]="mattabnumber == 1">
            <mat-expansion-panel-header (click)="onPanelClick()">
              <mat-panel-title>
                <img
                  src="assets/images/icons/icon-sales.svg"
                  alt="icon Sales"
                  matTooltip="Sales"
                  matTooltipPosition="right"
                />
                <h3>Sales</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sub-nav">
              <!-- <li [ngClass]="{ active: isRouteActive('/customers') }" (click)="navigateTo('/customers')"
                *ngIf="['admin', 'sales', 'project'].includes(profileName)">Customers</li>
              <li [ngClass]="{ active: isRouteActive('/quotation') }" (click)="navigateTo('/quotation')"
                *ngIf="['admin', 'sales', 'project', 'account','quote-new'].includes(profileName)">Quotes</li>
              <li [ngClass]="{ active: isRouteActive('/projects') }" (click)="navigateTo('/projects')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Project</li>
              <li [ngClass]="{ active: isRouteActive('/work-order') }" (click)="navigateTo('/work-order')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Work Order</li>
              <li [ngClass]="{ active: isRouteActive('/invoice') }" (click)="navigateTo('/invoice')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Invoice</li>
              <li [ngClass]="{ active: isRouteActive('/payment-received') }" (click)="navigateTo('/payment-received')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Payment Received</li>
              <li [ngClass]="{ active: isRouteActive('/credit-note') }" (click)="navigateTo('/credit-note')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Credit Note</li>  -->
              <li
                [ngClass]="{ active: isRouteActive('/customers') }"
                (click)="navigateTo('/customers')"
                *appHasPermission="'4'"
              >
                Customers
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/quotation') }"
                (click)="navigateTo('/quotation')"
                *appHasPermission="'5'"
              >
                Quotes
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/projects') }"
                (click)="navigateTo('/projects')"
                *appHasPermission="'6'"
              >
                Project
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/work-order') }"
                (click)="navigateTo('/work-order')"
                *appHasPermission="'7'"
              >
                Work Order
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/invoice') }"
                (click)="navigateTo('/invoice')"
                *appHasPermission="'8'"
              >
                Invoice
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/payment-received') }"
                (click)="navigateTo('/payment-received')"
                *appHasPermission="'9'"
              >
                Payment Received
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/credit-note') }"
                (click)="navigateTo('/credit-note')"
                *appHasPermission="'10'"
              >
                Credit Note
              </li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </li>
      <li class="nav dropdown-nav" [ngClass]="{
        'active-class': isRouteActive2([
          '/vendors',
          '/vendor-details',
          '/vendor-new',
          '/expense',
          '/expense-details',
          '/expense-new',
          '/purchase-order',
          '/purchase-order-details',
          '/purchase-order-new',
          '/vendor-invoice',
          '/vendor-invoice-new',
          '/vendor-invoice-details',
          '/goods-received-note',
          '/grn-details',
          '/grn-new',
          '/payments-made',
          '/payments-made-details',
          '/payments-made/bill-payment-new',
          '/debit-note',
          '/debit-note-details',
          '/debit-note/debit-note-new',
        ])
      }" *appHasPermission="['12', '13', '14', '15', '16', '17', '18']">
      <!-- }" *ngIf="['admin', 'account', 'sales', 'project','purchase manager'].includes(profileName)"> -->
        <mat-accordion>
          <mat-expansion-panel [expanded]="mattabnumber == 2">
            <mat-expansion-panel-header (click)="onPanelClick()">
              <mat-panel-title>
                <img
                  src="assets/images/icons/icon-purchase.svg"
                  alt="icon Purchase"
                  matTooltip="Purchase"
                  matTooltipPosition="right"
                />
                <h3>Purchase</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sub-nav">
              <li [ngClass]="{ active: isRouteActive('/vendors') }" (click)="navigateTo('/vendors')"
              *appHasPermission=" '12'">Vendors</li>
              <li [ngClass]="{ active: isRouteActive('/expense') }" (click)="navigateTo('/expense')"
              *appHasPermission=" '13'">Expenses</li>
              <li [ngClass]="{ active: isRouteActive('/purchase-order') }" (click)="navigateTo('/purchase-order')"
              *appHasPermission=" '14'">Purchase Order
              </li>
              <li [ngClass]="{ active: isRouteActive('/local-purchase-order') }"
              (click)="navigateTo('/local-purchase-order')">Local Purchase Order</li>
              <li [ngClass]="{ active: isRouteActive('/vendor-invoice') }" (click)="navigateTo('/vendor-invoice')"
              *appHasPermission=" '15'">Vendor Invoice</li>
              <li [ngClass]="{ active: isRouteActive('/goods-received-note') }"
                (click)="navigateTo('/goods-received-note')"
                *appHasPermission="'16'"
              >
                GRN
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/payments-made') }"
                (click)="navigateTo('/payments-made')"
                *appHasPermission="'17'"
              >
                Payment Made
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/debit-note') }"
                (click)="navigateTo('/debit-note')"
                *appHasPermission="'18'"
              >
                Debit Note
              </li>
              <!-- <li [ngClass]="{ active: isRouteActive('/vendors') }" (click)="navigateTo('/vendors')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Vendors</li>
              <li [ngClass]="{ active: isRouteActive('/expense') }" (click)="navigateTo('/expense')"
                *ngIf="['admin', 'account'].includes(profileName)">Expenses</li>
              <li [ngClass]="{ active: isRouteActive('/purchase-order') }" (click)="navigateTo('/purchase-order')"
                *ngIf="['admin', 'sales', 'project','purchase manager', 'account'].includes(profileName)">Purchase Order
              </li>
              <li [ngClass]="{ active: isRouteActive('/vendor-invoice') }" (click)="navigateTo('/vendor-invoice')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Vendor Invoice</li>
              <li [ngClass]="{ active: isRouteActive('/goods-received-note') }"
                (click)="navigateTo('/goods-received-note')"
                *ngIf="['admin', 'sales', 'project'].includes(profileName)">GRN</li>
              <li [ngClass]="{ active: isRouteActive('/payments-made') }" (click)="navigateTo('/payments-made')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Payment Made</li>
              <li [ngClass]="{ active: isRouteActive('/debit-note') }" (click)="navigateTo('/debit-note')"
                *ngIf="['admin', 'sales', 'project', 'account'].includes(profileName)">Debit Note</li> -->
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </li>
      <li
        class="nav"
        [ngClass]="{ 'active-class': isRouteActive('/branch') }"
        (click)="navigateTo('/branch')"
        *appHasPermission="'19'"
      >
        <!-- *ngIf="['admin', 'hr'].includes(profileName)"> -->
        <img
          src="assets/images/icons/icon-branch.svg"
          alt="icon Branch"
          matTooltip="Branch"
          matTooltipPosition="right"
        />
        <h3>Branch</h3>
      </li>
      <li
        class="nav dropdown-nav"
        [ngClass]="{
          'active-class': isRouteActive2([
            '/employees',
            '/add-employee',
            '/attendance-management',
            '/holiday',
            '/leaves',
            '/leave-policy',
            '/payroll',
            '/payroll-list',
            '/vacation-management',
            '/designations',
            '/departments'
          ])
        }"
        *appHasPermission="['21', '22', '23', '24', '25', '26', '27', '28']"
      >
        <!-- }" *ngIf="['admin', 'hr', 'account'].includes(profileName)"> -->
        <mat-accordion>
          <mat-expansion-panel [expanded]="mattabnumber == 3">
            <mat-expansion-panel-header (click)="onPanelClick()">
              <mat-panel-title>
                <img
                  src="assets/images/icons/icon-hrms.svg"
                  alt="icon HRMS"
                  matTooltip="HRMS"
                  matTooltipPosition="right"
                />
                <h3>HRMS</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sub-nav">
              <li
                [ngClass]="{ active: isRouteActive('/employees') }"
                (click)="navigateTo('/employees')"
                *appHasPermission="'21'"
              >
                Employees
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/attendance-management') }"
                (click)="navigateTo('/attendance-management')"
                *appHasPermission="'22'"
              >
                Attendance
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/leaves') }"
                (click)="navigateTo('/leaves')"
                *appHasPermission="'23'"
              >
                Leave
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/payroll') }"
                (click)="navigateTo('/payroll')"
                *appHasPermission="'24'"
              >
                Payroll
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/holiday') }"
                (click)="navigateTo('/holiday')"
                *appHasPermission="'25'"
              >
                Holiday
              </li>
              <!-- <li [ngClass]="{'active': isRouteActive('/leave-policy')}" (click)="navigateTo('/leave-policy')">Leave Policy</li> -->
              <li
                [ngClass]="{ active: isRouteActive('/vacation-management') }"
                (click)="navigateTo('/vacation-management')"
                *appHasPermission="'26'"
              >
                Vacation
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/designations') }"
                (click)="navigateTo('/designations')"
                *appHasPermission="'27'"
              >
                Designations
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/departments') }"
                (click)="navigateTo('/departments')"
                *appHasPermission="'28'"
              >
                Departments
              </li>

              <li [ngClass]="{ active: isRouteActive('/face-id-devices') }" (click)="navigateTo('/face-id-devices')"
              *appHasPermission=" '29'">
                FaceId Devices
              </li>

              <li [ngClass]="{ active: isRouteActive('/locations') }" (click)="navigateTo('/locations')"
              *appHasPermission=" '31'">
                Locations
              </li>
            
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </li>
      <!-- <li class="nav" *ngIf="['admin', 'manager'].includes(profileName)">
        <img
          src="assets/images/icons/icon-approvals.svg"
          alt="icon Approvals"
          matTooltip="Approvals"
          matTooltipPosition="right"
        />
        <h3>Approvals</h3>
      </li> -->
      <li
        class="nav"
        [ngClass]="{ 'active-class': isRouteActive('/assets') }"
        (click)="navigateTo('/assets')"
        *appHasPermission="'34'"
      >
        <img
          src="assets/images/icons/icon-assets.svg"
          alt="icon Assets"
          matTooltip="Assets"
          matTooltipPosition="right"
        />
        <h3>Assets</h3>
      </li>
      <li
        class="nav"
        [ngClass]="{ 'active-class': isRouteActive('/banking') }"
        (click)="navigateTo('/banking')"
        *appHasPermission="'1'"
      >
        <img
          src="assets/images/icons/icon-bank-2.svg"
          alt="icon Banking"
          matTooltip="Banking"
          matTooltipPosition="right"
        />
        <h3>Banking</h3>
      </li>
      <li class="nav" [ngClass]="{ 'active-class': isRouteActive('/petty-cash') }" (click)="navigateTo('/petty-cash')"
      *appHasPermission=" '37'">
        <img src="assets/images/icons/icon-bank-2.svg" alt="icon Banking" matTooltip="Petty Cash"
          matTooltipPosition="right" />
        <h3>Petty Cash</h3>
      </li>

      <li class="nav dropdown-nav" [ngClass]="{
          'active-class': isRouteActive2([
            '/currency-adjustments',
            '/chart-of-accounts',
            '/manual-journals',
            '/configure',
            '/activity-log'
          ])
        }"
        *appHasPermission="['30', '31', '32', '33']"
      >
        <mat-accordion>
          <mat-expansion-panel [expanded]="mattabnumber == 4">
            <mat-expansion-panel-header (click)="onPanelClick()">
              <mat-panel-title>
                <img
                  src="assets/images/icons/icon-accounts.svg"
                  alt="icon Accounts"
                  matTooltip="Accounts"
                  matTooltipPosition="right"
                />
                <h3>Accounts</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sub-nav">
              <li
                [ngClass]="{ active: isRouteActive('/currency-adjustments') }"
                *appHasPermission="'30'"
                (click)="navigateTo('/currency-adjustments')"
              >
                Currency Adjustments
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/manual-journals') }"
                *appHasPermission="'31'"
                (click)="navigateTo('/manual-journals')"
              >
                Manual Journal
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/chart-of-accounts') }"
                *appHasPermission="'32'"
                (click)="navigateTo('/chart-of-accounts')"
              >
                Chart Of Accounts
              </li>
              <li
                [ngClass]="{ 'active-class': isRouteActive('/configure') }"
                (click)="navigateTo('/configure')"
                *appHasPermission="'33'"
              >
                Configure
              </li>
              <li [ngClass]="{ 'active-class': isRouteActive('/activity-log') }" 
              (click)="navigateTo('/activity-log')"
              *appHasPermission=" '33'">
                Activity Logs
              </li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </li>
      <li
        class="nav"
        [ngClass]="{ 'active-class': isRouteActive('/reports') }"
        (click)="navigateTo('/reports')"
        *appHasPermission="'35'"
      >
        <img
          src="assets/images/icons/icon-report.svg"
          alt="icon Report"
          matTooltip="Report"
          matTooltipPosition="right"
        />
        <h3>Reports</h3>
      </li>
      <li
        class="nav"
        [ngClass]="{ 'active-class': isRouteActive('/roles') }"
        (click)="navigateTo('/roles')"
        *appHasPermission="'36'"
      >
        <img
          src="assets/images/icons/icon-roles.svg"
          alt="icon Roles"
          matTooltip="Roles & Permissions"
          matTooltipPosition="right"
        />
        <h3>Roles & Permissions</h3>
      </li>
    </ul>
  </div>
</section>
