import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    inject,
    HostListener
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { DesignationService } from '../../features/designations/services/designations.service';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { MatOptionModule } from '@angular/material/core';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';
import { Store } from '@ngrx/store';
import * as selectPermissions from '../../core/permissions/permission.selector';

@Component({
    selector: 'app-branch',
    standalone: true,
    imports: [
        LayoutComponent, AgGridAngular, MatButtonModule, MatDialogModule, PermissionDirective
    ],
    templateUrl: './designations.component.html',
    styleUrls: ['./designations.component.scss']
})
export class DesignationComponent {
    public viewPortSize: boolean = false;
    public rowData: any[] = [];
    Permission = Permission;
    public columnDefs: ColDef[] = [
        {
            field: 'designationName',
            headerName: 'Designation Name',
            flex: this.viewPortSize ? 1 : 0
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: this.viewPortSize ? 1 : 0,
            cellRenderer: (params: any) => {
                const div = document.createElement('div');
                div.style.display = 'flex';
                div.style.alignItems = 'center';
                div.style.gap = '20px';

                const createButton = (iconSrc: string, onClick: () => void) => {
                    const span = document.createElement('span');
                    span.style.cursor = 'pointer';
                    span.style.display = 'flex';
                    span.innerHTML = `<img src="${iconSrc}"/>`;
                    span.addEventListener('click', onClick);
                    return span;
                };
                // div.appendChild(createButton('assets/images/icons/icon-edit.svg', () => this.openEditDialog(params?.data)));
                // if(this.profileName === 'admin'){
                // div.appendChild(createButton('assets/images/icons/icon-delete.svg', () => this.onDeleteBranch(params.data?.id)));
                // }

                this.store.select(selectPermissions.hasPermission('27', Permission.Edit)).subscribe((canEdit) => {
                    if (canEdit) {
                        div.appendChild(createButton('assets/images/icons/icon-edit.svg', () => this.openEditDialog(params?.data)));
                    }
                  });
                this.store.select(selectPermissions.hasPermission('27', Permission.Delete)).subscribe((canDelete) => {
                    if (canDelete) {
                        div.appendChild(createButton('assets/images/icons/icon-delete.svg', () => this.onDeleteBranch(params.data?.id)));
                    }
                  });


                return div;
            },
            floatingFilter: false,
            filter: false
        },
    ];
    public defaultColDef: ColDef = {
        filter: 'agTextColumnFilter',
        floatingFilter: true
    };
    public rowSelection: 'single' | 'multiple' = 'multiple';
    public paginationPageSize = 10;
    public paginationPageSizeSelector: number[] | boolean = [10];
    public themeClass: string = 'ag-theme-quartz';
    public loading: boolean = true;
    public error: any = null;

    private searchSubject = new Subject<string>();
    // profileName: any;

    constructor(private designationService: DesignationService, private toastr: ToastrService, private store: Store) {
        // const loggedInUser: any = localStorage.getItem('loggedInUser');
        // const userData = JSON.parse(loggedInUser);
        // this.profileName = userData?.profileData?.profileName;
     }

    ngOnInit() {
        this.fetchDesignationData();

        this.searchSubject.pipe(debounceTime(600), distinctUntilChanged()).subscribe((searchTerm) => {
            this.fetchDesignationData(searchTerm);
        });

        this.checkViewportSize();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.checkViewportSize();
    }

    checkViewportSize() {
        if (window.innerWidth > 1200) {
            this.viewPortSize = true;
        } else {
            this.viewPortSize = false;
        }
        this.setAGColumn();
    }

    setAGColumn() {
        this.columnDefs = [
            {
                field: 'designationName',
                headerName: 'Designation Name',
                flex: this.viewPortSize ? 1 : 0
            },
            {
                field: 'action',
                headerName: 'Action',
                // flex: this.viewPortSize ? 1 : 0,
                cellRenderer: (params: any) => {
                    const div = document.createElement('div');
                    div.style.display = 'flex';
                    div.style.alignItems = 'center';
                    div.style.gap = '20px';

                    const createButton = (iconSrc: string, onClick: () => void) => {
                        const span = document.createElement('span');
                        span.style.cursor = 'pointer';
                        span.style.display = 'flex';
                        span.innerHTML = `<img src="${iconSrc}"/>`;
                        span.addEventListener('click', onClick);
                        return span;
                    };
                    // div.appendChild(createButton('assets/images/icons/icon-edit.svg', () => this.openEditDialog(params?.data)));
                    // if(this.profileName === 'admin'){
                    // div.appendChild(createButton('assets/images/icons/icon-delete.svg', () => this.onDeleteBranch(params.data?.id)));
                    // }
                    this.store.select(selectPermissions.hasPermission('27', Permission.Edit)).subscribe((canEdit) => {
                        if (canEdit) {
                            div.appendChild(createButton('assets/images/icons/icon-edit.svg', () => this.openEditDialog(params?.data)));
                        }
                      });
                    this.store.select(selectPermissions.hasPermission('27', Permission.Delete)).subscribe((canDelete) => {
                        if (canDelete) {
                            div.appendChild(createButton('assets/images/icons/icon-delete.svg', () => this.onDeleteBranch(params.data?.id)));
                        }
                      });
                    return div;
                },
                floatingFilter: false,
                filter: false
            },
        ];
    }

    onDeleteBranch(designationId: number): void {
        const dialogRef = this.dialog.open(ConfirmDialog);

        dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
                this.deleteDesignation(designationId);
            }
        });
    }

    private deleteDesignation(designationId: number): void {
        this.designationService.removeDesignationByid(designationId).subscribe({
            next: () => {
                this.toastr.success('Designation deleted successfully!', 'Success');
                this.fetchDesignationData();
            },
            error: () => {
                this.toastr.error('Failed to delete designation. Please try again.', 'Error');
            }
        });
    }

    private fetchDesignationData(search: string = '') {
        this.loading = true;
        this.designationService.fetchDesignations(search).subscribe({
            next: (designations) => {
                let inProgressCount = 0;

                // Sort the designations by 'id' in descending order so the newest branch comes first
                this.rowData = designations.map((designation: any) => {
                    const projectsInProgress = Array.isArray(designation?.projects) ? designation.projects.filter((project
                        : any) => project.status === 'In-Progress') : [];

                    inProgressCount += projectsInProgress.length;

                    return {
                        id: designation?.id || '--',
                        designationName: designation?.designationName || '--',
                    };
                }).sort((a: any, b: any) => b.id - a.id);
                // Sort by ID in descending order

                // Update pagination options based on the rowData length
                this.paginationPageSizeSelector = PaginationHelper.getPaginationPageSizeOptions(this.rowData);

                this.loading = false;
            },
            error: (error) => {
                this.error = error;
                this.loading = false;
            }
        });
    }

    onSearch(event: Event) {
        const input = event.target as HTMLInputElement;
        const searchTerm = input.value;
        this.searchSubject.next(searchTerm);
    }

    readonly dialog = inject(MatDialog);

    openConfirm() {
        const dialogRef = this.dialog.open(ConfirmDialog);
    }

    openDialog() {
        const dialogRef = this.dialog.open(AddBranchDialog, { disableClose: true });

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'success') {
                this.fetchDesignationData();
            }
        });
    }

    openEditDialog(designationId: any) {
        const dialogRef = this.dialog.open(AddBranchDialog, {
            disableClose: true,
            data: designationId
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'success') {
                this.fetchDesignationData();
            }
        });
    }
}

const gridDiv = document.querySelector<HTMLElement>('#myGrid')!;

@Component({
    selector: 'add-branch-dialog',
    templateUrl: './add-designations.html',
    styleUrls: ['./designations.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatOptionModule,
        CommonModule,
    ]
})
export class AddBranchDialog implements OnInit {
    @Output() branchAdded = new EventEmitter<any>();
    designationForm !: FormGroup;
    countries: any[] = [];
    desigantionData: any;
    loading: boolean = false;

    constructor(private designationService: DesignationService, private toastr: ToastrService, private dialogRef: MatDialogRef<AddBranchDialog>, private http: HttpClient, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        if (this.data) {
            this.desigantionData = this.data;
        }
        this.initForm();
        this.loadCountries();
        // this.generateBranchCode();
    }

    initForm(): void {
        this.designationForm = this.fb.group({
            id: [this.desigantionData?.id || ''],
            designationName: [
                this.desigantionData?.designationName || '',
                Validators.required
            ],
        });
    }

    loadCountries(): void {
        this.http.get<any[]>('assets/json/countries.json').subscribe((data) => {
            this.countries = data;
        }, (error) => {
            console.log('Error loading country data----', error);
        });
    }

    onAddBranch(): void {
        if (!this.desigantionData) {
            this.loading = true;
            this.designationForm.markAllAsTouched();
            if (this.designationForm.valid) {
                const desigantionData = {
                    ...this.designationForm.value,
                };
                let {
                    designationName,
                } = this.designationForm.value;

                const createBranchInput = {
                    designationName,
                };
                this.designationService.createDesignations(createBranchInput).subscribe((response) => {
                    this.loading = false;
                    this.toastr.success('Designation added successfully!', 'Success', {
                        timeOut: 5000,
                        progressBar: true,
                        closeButton: true
                    });
                    this.dialogRef.close('success');
                }, (error) => {
                    this.loading = false;
                    let errorMessage = 'Failed to add designation. Please try again.';
                    if (error.error && error.error.message) {
                        errorMessage = error.error.message;
                    } else if (error.message === 'Designation is not created!') {
                        errorMessage = 'Designation already exist with this email!';
                    } else if (error.message) {
                        errorMessage = error.message;
                    }

                    this.toastr.error(errorMessage, 'Error', {
                        timeOut: 5000,
                        progressBar: true,
                        closeButton: true
                    });
                });
            }
        } else {
            this.designationForm.markAllAsTouched();
            if (this.designationForm.valid) {
                this.loading = true;
                const desigantionData = {
                    ...this.designationForm.value,
                };
                this.designationService.updateDesignation(desigantionData).subscribe((response) => {
                    this.loading = false;
                    this.toastr.success('Designation updated successfully!', 'Success', {
                        timeOut: 5000,
                        progressBar: true,
                        closeButton: true
                    });
                    this.dialogRef.close('success');
                }, (error) => {
                    this.loading = false;
                    let errorMessage = 'Failed to update designation. Please try again.';
                    if (error.error && error.error.message) {
                        errorMessage = error.error.message;
                    } else if (error.message) {
                        errorMessage = error.message;
                    }

                    this.toastr.error('Failed to update designation. Please try again.', 'Error', {
                        timeOut: 5000,
                        progressBar: true,
                        closeButton: true
                    });
                });
            }
        }
    }
}
