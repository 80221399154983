import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-professional-info-profile',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './professional-info-profile.html',
    styleUrl: '../../../../employee-details.component.scss'
})
export class ProfessionalInfoProfileComponent {
  @Input() employeeData: any;

}
