
    <section class="cmn-innerpage-wrapper leave-management">
        <div class="head">
            <div class="search-wrapper" *appHasPermission="['23', Permission.List]">
                <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)"/>
            </div>
            <button class="cmn-filter-btn" *appHasPermission="['23', Permission.List]" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }">
                <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
            </button>
            <!-- Filter Popup -->
            <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <div>
                    <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
                      <h6 class="cmn-popup-heading">Filter <span (click)="clearFilter()">Clear All</span></h6>
                      <h2>Leave Type</h2>
                      <div class="check-wrapper">
                        <div class="check-box">
                          <label class="checkbox-wrapper">
                            <input type="checkbox" formControlName="CL" />
                            Casual Leave
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="check-box">
                          <label class="checkbox-wrapper">
                            <input type="checkbox" formControlName="EL" />
                            Earned Leave
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="check-box">
                          <label class="checkbox-wrapper">
                            <input type="checkbox" formControlName="EML" />
                            Emergency Leave
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="check-box">
                          <label class="checkbox-wrapper">
                            <input type="checkbox" formControlName="ML" />
                            Marriage Leave
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="check-box">
                          <label class="checkbox-wrapper">
                            <input type="checkbox" formControlName="LOP" />
                            Loss Of Pay
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="submit">Apply</button>
                    </div>
                    </form>
                  </div>
                  
                    <!-- <h2>Employee Type</h2>
                    <div formArrayName="employeeTypes" class="check-wrapper">
                        <div class="check-box">
                            <label class="checkbox-wrapper">
                                <input type="checkbox" />
                                test
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div> -->

                </div>
            </div>
            <!-- Filter Popup -->
        <!-- </div> -->
        <div class="inner-content" *appHasPermission="['23', Permission.List]">
            <div class="common-noimage-tab-wrapper">
                <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
                  <mat-tab>
                      <ng-template mat-tab-label>
                          <h2 class="leave-tab-title">All</h2>
                      </ng-template>
                      <div class="leave-history-table">
                          <app-all-leaves [searchQuery]="searchQuery" [filter]="filter"></app-all-leaves>
                      </div>
                  </mat-tab>

                    <!-- Mat Tab One -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Pending</h2>
                        </ng-template>
                        <div class="leave-history-table">
                            <app-pending [searchQuery]="searchQuery" [filter]="filter" *ngIf="activeTabIndex === 1" #pendingLeave></app-pending>
                        </div>


                    </mat-tab>
                    <!-- Mat Tab Two -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Approved</h2>
                        </ng-template>
                        <div class="leave-history-table">
                            <app-approved [searchQuery]="searchQuery" [filter]="filter"  *ngIf="activeTabIndex === 2" #approvedLeave></app-approved>
                        </div>
                    </mat-tab>
                    <!-- Mat Tab Three -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Rejected</h2>
                        </ng-template>
                        <div class="leave-history-table">
                            <app-rejected [searchQuery]="searchQuery" [filter]="filter"  *ngIf="activeTabIndex === 3" #rejectedLeave></app-rejected>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
           
        </div>
    </section>
