import {ItemType} from './../../../assets/itemTypes';
import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from '../../core/layout/layout.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {ChartAccountsService} from '../../features/chart-of-accounts/services/chart-accounts.service';
import {InventoryItemService} from '../../features/inventory/services/inventory.service';
import {ToastrService} from 'ngx-toastr';
import {ItemStatusEnum} from '../../../assets/itemStatus';
import {ActivatedRoute, Router} from '@angular/router';
import {nonNegativeValidator, positiveQuantityValidator} from '../../shared/services/validations';
import {InvoiceService} from '../../features/invoice/services/invoice.service';

@Component({
    selector: 'app-inventory-new',
    standalone: true,
    imports: [
        CommonModule,
        LayoutComponent,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    templateUrl: './inventory-new.component.html',
    styleUrl: './inventory-new.component.scss'
})
export class InventoryNewComponent implements OnInit {
    search : string = '';
    accountType : any;
    parentAccountId : any;
    isSubAccount : any;
    itemId : number | null = null;
    hideStatusAndQuantity = false;
    loading : boolean = false;
    inventoryItemForm : FormGroup;
    public itemTypes : {
        label: String;
        value: ItemType
    }[] = [];
    public itemStatuses : {
        label: string;
        value: string
    }[] = [];
    public accountData : any[] = [];
    public vendorData : any[] = [];
    itemData : any;
    unitTypes = [
        {
            label: 'cm',
            value: 'cm'
        },
        {
            label: 'm',
            value: 'm'
        },
        {
            label: 'kg',
            value: 'kg'
        },
        {
            label: 'g',
            value: 'g'
        }, {
            label: 'l',
            value: 'l'
        }, {
            label: 'ml',
            value: 'ml'
        }, {
            label: 'pc',
            value: 'pc'
        }, {
            label: 'box',
            value: 'box'
        }, {
            label: 'ft',
            value: 'ft'
        }, {
            label: 'km',
            value: 'km'
        }, {
            label: 'mg',
            value: 'mg'
        },
    ];

    constructor(private accountService : ChartAccountsService, private itemService : InventoryItemService, private fb : FormBuilder, private toastr : ToastrService, private route : ActivatedRoute, private router : Router, private invoiceService : InvoiceService) {}

    ngOnInit(): void {
        this.loadItemTypes();
        this.loadItemStatus();
        this.fetchAccounts();
        this.initForm();
        this.fetchVendors();

        this.route.paramMap.subscribe((params) => {
            const id = params.get('id');
            if (id) {
                this.itemId = + id;
                this.loadItemData();
            }
        });

        // Add listener for itemType changes
        this.inventoryItemForm.get('itemType') ?. valueChanges.subscribe((itemType) => {
            this.hideStatusAndQuantity = itemType === 'Service';
            this.toggleQuantityValidation(itemType);
        });
    }

    initForm(): void {
        this.inventoryItemForm = this.fb.group({
            id: [this.itemData ?. id || ''],
            itemType: [
                this.itemData ?. itemType || '',
                Validators.required
            ],
            itemName: [
                this.itemData ?. itemName || '',
                Validators.required
            ],
            skuCode: [
                this.itemData ?. skuCode || '',
                Validators.required
            ],
            status: [this.itemData ?. status || null],
            unit: [this.itemData ?. unit || null],
            quantity: [
                this.itemData ?. quantity || 0,
                [
                    Validators.required, Validators.pattern(/^\d+(\.\d+)?$/), positiveQuantityValidator
                ],
            ],
            isSalesActive: [this.itemData ?. isSalesActive || false],
            sellingPrice: [
                this.itemData ?. sellingPrice || 0,
                [
                    Validators.required, Validators.pattern(/^\d+(\.\d+)?$/), nonNegativeValidator,
                ],
            ],
            salesAccountId: [this.itemData ?. salesAccountId || null],
            salesDescription: [this.itemData ?. salesDescription || ''],
            isPurchaseActive: [this.itemData ?. isPurchaseActive || false],
            costPrice: [
                this.itemData ?. costPrice || 0,
                [
                    Validators.required, Validators.pattern(/^\d+(\.\d+)?$/), nonNegativeValidator,
                ],
            ],
            purchaseAccountId: [this.itemData ?. purchaseAccountId || null],
            purchaseDescription: [this.itemData ?. purchaseDescription || ''],
            vendorId: [this.itemData ?. vendorId || null]
        }, {validators: this.atLeastOneActiveValidator});
        this.toggleQuantityValidation(this.inventoryItemForm.get('itemType') ?. value);

        this.toggleSalesFields(this.inventoryItemForm.get('isSalesActive') ?. value || false);
        this.togglePurchaseFields(this.inventoryItemForm.get('isPurchaseActive') ?. value || false);

        this.inventoryItemForm.get('isSalesActive') ?. valueChanges.subscribe((isActive) => {
            this.toggleSalesFields(isActive);
        });

        this.inventoryItemForm.get('isPurchaseActive') ?. valueChanges.subscribe((isActive) => {
            this.togglePurchaseFields(isActive);
        });
    }


    toggleSalesFields(isActive : boolean): void {
        const salesFields = ['sellingPrice', 'salesAccountId', 'salesDescription'];

        salesFields.forEach((field) => {
            const control = this.inventoryItemForm.get(field);
            if (isActive) {
                control ?. enable();
                if (field === 'sellingPrice' || field === 'salesAccountId') {
                    control ?. setValidators([Validators.required, Validators.pattern(/^\d+(\.\d+)?$/), nonNegativeValidator]);
                } else {
                    control ?. clearValidators();
                }
            } else {
                control ?. disable();
                control ?. clearValidators();
                control ?. reset(null); // Clear the field value when disabled
            } control ?. updateValueAndValidity();
        });
    }

    togglePurchaseFields(isActive : boolean): void {
        const purchaseFields = ['costPrice', 'purchaseAccountId', 'purchaseDescription', 'vendorId'];

        purchaseFields.forEach((field) => {
            const control = this.inventoryItemForm.get(field);
            if (isActive) {
                control ?. enable();
                if (field === 'costPrice' || field === 'purchaseAccountId') {
                    control ?. setValidators([Validators.required, Validators.pattern(/^\d+(\.\d+)?$/), nonNegativeValidator]);
                } else {
                    control ?. clearValidators();
                }
            } else {
                control ?. disable();
                control ?. clearValidators();
                control ?. reset(null); // Clear the field value when disabled
            } control ?. updateValueAndValidity();
        });
    }


    toggleQuantityValidation(itemType : string): void {
        const quantityControl = this.inventoryItemForm.get('quantity');
        if (itemType === 'Service') {
            quantityControl ?. clearValidators();
            quantityControl ?. setValidators([Validators.pattern(/^\d+(\.\d+)?$/)]);
        } else {
            quantityControl ?. setValidators([Validators.required, Validators.pattern(/^\d+(\.\d+)?$/), positiveQuantityValidator]);
        } quantityControl ?. updateValueAndValidity();
    }

    private atLeastOneActiveValidator : ValidatorFn = (control : AbstractControl) => {
        const isSalesActive = control.get('isSalesActive') ?. value;
        const isPurchaseActive = control.get('isPurchaseActive') ?. value;
        return isSalesActive || isPurchaseActive ? null : {
            atLeastOneRequired: true
        };
    };

    private loadItemData(): void {
        if (this.itemId !== null) {
            this.itemService.fetchItemById(this.itemId).subscribe({
                next: (data) => {
                    this.itemData = data;
                    this.initForm();
                },
                error: (error) => console.error(error)
            });
        } else {
            console.warn('Item ID is null; cannot load item data.');
        }
    }

    onCreateItem(): void {
        if (this.inventoryItemForm.invalid) {
            this.inventoryItemForm.markAllAsTouched();
            this.toastr.warning('Please fill in all required fields.');
            return;
        }
        
        const itemInputData = {
            itemType: this.inventoryItemForm.value.itemType,
            itemName: this.inventoryItemForm.value.itemName,
            skuCode: this.inventoryItemForm.value.skuCode,
            unit: this.inventoryItemForm.value.unit,
            status: this.inventoryItemForm.value.status,
            quantity: this.inventoryItemForm.value.quantity,
            isSalesActive: this.inventoryItemForm.value.isSalesActive,
            sellingPrice: this.inventoryItemForm.value.isSalesActive ? this.inventoryItemForm.value.sellingPrice : null,
            salesAccountId: this.inventoryItemForm.value.isSalesActive ? this.inventoryItemForm.value.salesAccountId : null,
            salesDescription: this.inventoryItemForm.value.isSalesActive ? this.inventoryItemForm.value.salesDescription : null,
            isPurchaseActive: this.inventoryItemForm.value.isPurchaseActive,
            costPrice: this.inventoryItemForm.value.isPurchaseActive ? this.inventoryItemForm.value.costPrice : null,
            purchaseAccountId: this.inventoryItemForm.value.isPurchaseActive ? this.inventoryItemForm.value.purchaseAccountId : null,
            purchaseDescription: this.inventoryItemForm.value.isPurchaseActive ? this.inventoryItemForm.value.purchaseDescription : null,
            vendorId: this.inventoryItemForm.value.isPurchaseActive ? this.inventoryItemForm.value.vendorId : null
        };
    
        const itemUpdateInput = {
            id: this.itemId,
            ...itemInputData
        };

    
        console.log("Processed inventory form values:", itemUpdateInput);
    
        if (this.itemId) {
            this.itemService.updateItemById(itemUpdateInput).subscribe({
                next: (response) => {
                    this.loading = false;
                    this.toastr.success('Item updated successfully.');
                    const updatedInventoryId = response?.data?.updateItem?.id;
                    if (updatedInventoryId) {
                        this.router.navigate([`inventory/inventory-details/${updatedInventoryId}`]);
                    } else {
                        this.router.navigate(['/inventory']);
                    }
                },
                error: (error) => {
                    this.loading = false;
                    this.toastr.error(error.message || 'Failed to update item.');
                }
            });
        } else {
            this.itemService.createInventoryItem(itemInputData).subscribe((response) => {
                this.toastr.success('Item added successfully');
                const createdItemId = response?.data?.createItem?.id;
                if (createdItemId) {
                    this.router.navigate([`inventory/inventory-details/${createdItemId}`]);
                } else {
                    this.router.navigate(['/inventory']);
                }
            }, (error) => {
                this.toastr.error(error.message || 'Failed to add item. Please try again.');
            });
        }
    }
    

    onCancel(): void {
        this.inventoryItemForm.reset();
        this.router.navigate(['inventory']);
    }
    private loadItemTypes() {
        this.itemTypes = Object.values(ItemType).map((type) => ({label: type, value: type}));
    }

    private loadItemStatus() {
        this.itemStatuses = Object.values(ItemStatusEnum).map((status) => ({label: status.label, value: status.value}));
    }

    private fetchAccounts() {
        this.accountService.chartOfAccounts(this.accountType, this.search, this.parentAccountId, this.isSubAccount).subscribe({
            next: (accounts) => {
                this.accountData = accounts;
            },
            error: (error) => console.error(error)
        });
    }

    private fetchVendors(search : string = '') {
        this.invoiceService.fetchClients(search).subscribe({
            next: (vendors) => {
                this.vendorData = vendors.filter((data : any) => data.partnerType === 'Vendor').map((data : any) => ({
                    id: data ?. id || '--',
                    name: data ?. displayName || '--'
                }));
            }
        });
    }
}
