import { Component, HostListener, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { WorkOrderService } from '../../features/workOrder/services/workOrder.service';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Store } from '@ngrx/store';
import * as selectPermissions from '../../core/permissions/permission.selector';
import { Permission } from '../../shared/services/permissions';


@Component({
  selector: 'app-work-order',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatInputModule, AgGridAngular, PermissionDirective],
  templateUrl: './work-order.component.html',
  styleUrl: './work-order.component.scss',
})
export class WorkOrderComponent {
  public viewPortSize: boolean = false;
  private searchSubject = new Subject<string>();
  Permission = Permission;
  columnDefs: ({ headerName: string; field: string; flex: number; cellRenderer?: undefined; floatingFilter?: undefined; filter?: undefined; } | { field: string; headerName: string; flex: number; cellRenderer: (params: any) => HTMLDivElement; floatingFilter: boolean; filter: boolean; })[];
  // profileName: any;
  constructor(
    private router: Router,
    private workorderService: WorkOrderService,
    private toastr: ToastrService,
    private store: Store,
  ) {
    // const loggedInUser: any = localStorage.getItem('loggedInUser');
    // const userData = JSON.parse(loggedInUser);
    // this.profileName = userData?.profileData?.profileName;
   }

  rowData = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }

  setAGColumn() {

    this.columnDefs = [
      {
        headerName: 'Work Order #',
        field: 'workOrderNo',
        flex: this.viewPortSize ? 1 : 0,
      },
      { headerName: 'Quote', field: 'quoteCode', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Project Name', field: 'projectName', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Customer Name', field: 'customerName', flex: this.viewPortSize ? 1 : 0 },
      // { headerName: 'SOW', field: 'sow', flex: this.viewPortSize ? 1 : 0 },
      // { headerName: 'Project Manager', field: 'projectManager', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Project Engineer', field: 'projectEngineer', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Project Designer', field: 'projectDesigner', flex: this.viewPortSize ? 1 : 0 },
      // {
      //   headerName: 'Status',
      //   field: 'status',
      //   flex: this.viewPortSize ? 1 : 0,
      //   cellRenderer: (params: any) => {
      //     const status = params.data.status;
      //     const statusClass = status === 'On Leave' ? 'on-leave' : 'working'; // Apply class if "On Leave"
      //     return `<span class="${statusClass}">${status}</span>`;
      //   },
      // },
      {
        field: 'action',
        headerName: 'Actions',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.alignItems = 'center';
          container.style.gap = '20px';

          const editIcon = document.createElement('img');
          editIcon.src = 'assets/images/icons/icon-edit.svg';
          editIcon.style.cursor = 'pointer';
          editIcon.setAttribute('data-action', 'edit');
          // container.appendChild(editIcon);
          this.store.select(selectPermissions.hasPermission('7', Permission.Edit)).subscribe((canEdit) => {
            if (canEdit) {
              container.appendChild(editIcon);
            }
          });

          // const deleteIcon = document.createElement('img');
          // deleteIcon.src = 'assets/images/icons/icon-delete.svg';
          // deleteIcon.style.cursor = 'pointer';
          // deleteIcon.setAttribute('data-action', 'delete');

          // // if(this.profileName === 'admin'){
          // //   container.appendChild(deleteIcon);
          // // }
          // this.store.select(selectPermissions.hasPermission('7', Permission.Delete)).subscribe((canDelete) => {
          //   if (canDelete) {
          //     container.appendChild(deleteIcon);
          //   }
          // });
      
          return container;
        },
        floatingFilter: false,
        filter: false,
      },
    ];
  }

  ngOnInit() {
    this.fetchWorkOrders();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchWorkOrders(searchTerm);
      });

    this.checkViewportSize();
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  private fetchWorkOrders(search: string = '') {
    this.workorderService.getAllWorkOrder(search).subscribe({
      next: (orders: any) => {
        // Sort the designations by 'id' in descending order so the newest branch comes first
        this.rowData = orders
          .map((order: any) => {
            return {
              workOrderNo: order?.workOrderNo || '--',
              quoteCode: order?.quoteData?.quoteCode || '--',
              projectName: order.projectData?.projectName || '--',
              customerName: order?.partnerData?.displayName || '--',
              projectManager:
                `${order.projectManagerData?.firstName || ''} ${order.projectManagerData?.lastName || ''
                }` || '--',
              projectEngineer:
                `${order.projectInchargeData?.firstName || '--'} ${order.projectInchargeData?.lastName || ''
                }` || '--',
              id: order?.id || '--',
              projectDesigner:
                `${order.projectDesignerData?.firstName || '--'} ${order.projectDesignerData?.lastName || ''
                }` || '--',
            };
          })
          .sort((a: any, b: any) => b.id - a.id);
      },
      error: (error: any) => {
        // this.error = error;
        // this.loading = false;
      },
    });
  }

  onCellClicked(params: any) {
    const id = params.data?.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.onEdit(id);
      } else if (action === 'delete') {
        this.onConfirmDelete(id);
      }
    } else {
      // this.router.navigate([`/work-order/work-order-details/${id}`]);
      this.router.navigate([`work-order/work-order-details`], {
        queryParams: { id: id },
      });
    }
  }

  onConfirmDelete(id: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onDelete(id);
      }
    });
  }
  onDelete(id: any) {
    this.workorderService.removeWorkOrder(id).subscribe({
      next: (result) => {
        if (result) {
          this.toastr.success('Work Order deleted successfully', 'Success');
          this.fetchWorkOrders();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      },
    });
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }
  onEdit(id: any) {
    this.router.navigate([`/work-order/work-order-edit/${id}`]);
  }

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(UploadWorkOrderDialog);
  }

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
}

@Component({
  selector: 'upload-work-order-dialog',
  templateUrl: 'upload-work-order-dialog.html',
  styleUrls: ['./work-order.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadWorkOrderDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}
