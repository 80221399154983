import { Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { VacationHistoryComponent } from './components/vacation-history/vacation-history.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LeaveService } from '../../features/leave/services/leave.service';
import { VacationService } from '../../features/vacation/services/vacation.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-vacation-approvals',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    VacationHistoryComponent
  ],
  templateUrl: './vacation-approvals.component.html',
  styleUrl: './vacation-approvals.component.scss'
})
export class VacationApprovalsComponent {
  vacationId: any;
  employeeId: any;
  vacationData: any;
  vacationApprovers: any;
  leaveApprovers: any;
  levelOneId: any;
  levelTwoId: any;
  levelThreeId: any;
  levelFourId: any;
  isDropdownOpen = false;
  levelOneApproverStatus: any;
  levelTwoApproverStatus: any;
  levelThreeApproverStatus: any;
  levelFourApproverStatus: any;
  vacationApprovals: any;
  activityType: any;
  search: any;
  timelines: any;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private vacationService: VacationService,
    private leaveService: LeaveService,
  ){}

  ngOnInit(){
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.vacationId = id;
      this.fetchVacation(id);
    }
  }

  fetchVacation(id: any){
    this.vacationService.fetchVacationById(Number(id)).subscribe({
      next: (data) => {
        const type = "VACATION";
        this.employeeId = data?.employee?.id;
        this.getVacationApprovals(data?.id, type);
        this.vacationData = data;
        this.vacationApprovers = data?.employee?.employeeApprovers;
        this.vacationApprovers.map((leaveApprover: any) => {
            this.levelOneId = leaveApprover?.levelOneData?.id;
            this.levelTwoId = leaveApprover?.levelTwoData?.id;
            this.levelThreeId = leaveApprover?.levelThreeData?.id;
            this.levelFourId = leaveApprover?.levelFourData?.id;
        })
      },
      error: (error) => {
        this.toastr.error("Error in fetching leave",'Error')
      }
    })
}

getVacationApprovals(leaveId: any, type: any) {
  this.leaveService.getLeaveApprovals(leaveId, type).subscribe({
    next: (response) => {
      response.map((data: any) => {
        if(this.levelOneId === data?.approverId){
          this.levelOneApproverStatus = data?.approvalStatus;
        } else if (this.levelTwoId === data?.approverId){
          this.levelTwoApproverStatus = data?.approvalStatus;
        } else if (this.levelThreeId === data?.approverId){
          this.levelThreeApproverStatus = data?.approvalStatus;
        } else if (this.levelFourId === data?.approverId){
          this.levelFourApproverStatus = data?.approvalStatus;
        } 
        this.vacationApprovals = response?.vacationApprovals || [];
        
      }) 
      
    },
    error: (error) => {
      this.toastr.error("Error in fetching approvals", 'Error');
    }
  });
}

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
    if(this.isDropdownOpen){
      this.leaveService.fetchTimelines("VACATION", Number(this.vacationId), this.activityType, this.search).subscribe({
        next: (data) => {
          this.timelines = data;
        },
        error: (error) => {
          this.toastr.error("Error in fetching timelines",'Error')
        }
      })
    }
  }

  readonly dialog = inject(MatDialog);


  onReject(){
    const status = false;
    const dialogRef: MatDialogRef<VacationApprovalsDialog> = this.dialog.open(
      VacationApprovalsDialog,
      {
        width: '800px',
        disableClose: true,
        data: {status: status, vacationId: this.vacationId},
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        this.getVacationApprovals(this.vacationId,"VACATION");
        this.toastr.success('Vacation request rejected successfully', 'Success');
      }
    });
  }

  onApprove(){
    const status = true;
    const dialogRef: MatDialogRef<VacationApprovalsDialog> = this.dialog.open(
      VacationApprovalsDialog,
      {
        width: '800px',
        disableClose: true,
        data: {status: status, vacationId: this.vacationId},
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        this.getVacationApprovals(this.vacationId,"VACATION");
        this.toastr.success('Vacation request approved successfully', 'Success');
      }
    });
  }
}



@Component({
  selector: 'vacation-approvals-dialog',
  templateUrl: 'vacation-approvals-dialog.html',
  styleUrls: ['./vacation-approvals.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule
  ],
})
export class VacationApprovalsDialog {
  applyApprovalForm: FormGroup;
  status: boolean;
  approvedStatus: string = '';
  vacationId: number;
  rejectedStatus: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private vacationService: VacationService,
    public dialogRef: MatDialogRef<VacationApprovalsDialog>,
  ){}

  ngOnInit(){
    console.log('ngOnInit',this.data.vacationId);
    
    this.applyApprovalForm = this.fb.group({
      approvalNote: ['', Validators.required],  
    });
    this.status = this.data.status;
    this.approvedStatus = 'APPROVED';
    this.rejectedStatus = 'REJECTED';
    this.vacationId = this.data.vacationId;
  }



  handleClick(){
    if (this.status) {
      this.onStatusUpdate(true); 
    }else {
      this.onStatusUpdate(false); 
    }
  }

  onStatusUpdate(status: any){
    if(status == true) {
      console.log('approve ---->',this.data.vacationId);
      this.vacationService.updateVacationRequestStatus(this.vacationId, this.approvedStatus, this.applyApprovalForm?.value?.approvalNote).subscribe({
        next: (data) => {
          this.dialogRef.close(data);
        },
        error: (error) => {
          this.toastr.error(error?.message, 'Error')
        }
      })
    } else if(status == false) {
      console.log('reject ------>',this.data.vacationId);
      this.vacationService.updateVacationRequestStatus(this.vacationId, this.rejectedStatus, this.applyApprovalForm?.value?.approvalNote).subscribe({
        next: (data) => {
          this.dialogRef.close(data);
        },
        error: (error) => {
          this.toastr.error(error?.message, 'Error')
        }
      })
    }
    }

}