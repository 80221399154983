<div class="tab-inner-content">
  <h2>Payment Received Report</h2>
  <h5>
    From {{ startDate | date : "MM/d/YYYY" }} to
    {{ endDate | date : "MM/d/YYYY" }}
  </h5>

  <table>
    <thead>
      <tr>
        <th>Payment Number</th>
        <th>Date</th>
        <th>Reference Number</th>
        <th>Customer Name</th>
        <th>Notes</th>
        <th>Deposit To</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let report of paymentReceivedReportrData" class="child">
        <td>{{ report.paymentNo }}</td>
        <td>{{ report.date | date : "mediumDate" }}</td>
        <td>{{ report.referenceNo }}</td>
        <td>{{ report.customerName }}</td>
        <td>{{ report.notes }}</td>
        <td>{{ report.depositTo }}</td>
      </tr>
    </tbody>
  </table>
</div>
