import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_LOCATIONS } from '../graphql/locations.queries';
import { CREATE_LOCATION, REMOVE_LOCATION_BY_ID, UPDATE_LOCATION } from '../graphql/locations.mutations';


@Injectable({
  providedIn: 'root',
})
export class LocationServices {
  constructor(private apollo: Apollo) {}

  fetchLocations(search: string = ''): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_LOCATIONS,
        variables: { search },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.locations || []));
      
  }


  createLocation(createLocationInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: CREATE_LOCATION,
      variables: {
        input: createLocationInput,
      },
    });
  }

  updateLocation(locationsData: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: UPDATE_LOCATION,
      variables: {
        updateLocationInput: locationsData,
      },
    });
  }

  removeLocationById(id: number): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: REMOVE_LOCATION_BY_ID,
      variables: { id },
    });
  }
}
