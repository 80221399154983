<mat-dialog-content>
  <section class="quotation-rejection-wrapper">
    <h2 class="cmn-popup-title">Approve quotation</h2>
    <button class="cmn-close" mat-dialog-close></button>
    <p>Are you sure?</p>
    <!-- <div class="form-wrapper">
        <label for="">
          <span>Notes</span>
          <mat-form-field>
            <textarea matInput placeholder="Reason for rejection"></textarea>
          </mat-form-field>
        </label>
      </div> -->

    <div class="flex-btn-wrapper">
      <button class="cmn-reject-btn" type="button" (click)="onCancel()">
        Cancel
      </button>
      <button class="cmn-reject-btn" type="button" (click)="onApprove()">
        Approve
      </button>
    </div>
  </section>
</mat-dialog-content>
