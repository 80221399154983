<section class="accordion-section">

    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Vendor Invoice</mat-panel-title>
                <mat-panel-description>
                    <button class="add-btn" [routerLink]="['/vendor-invoice/vendor-invoice-new']">
                        <img src="assets/images/icons/icon-add-color.svg" alt="Add" /> New
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="table-wrapper" *ngIf="partnerDetails?.partner?.bills.length > 0; else noData">
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Bill#</th>
                            <th>PO Number</th>
                            <th>Vendor Name</th>
                            <th>Amount</th>
                            <th>Balance</th>
                            <th>status</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let partnerDetail of partnerDetails?.partner?.bills">
                        <tr *ngIf="partnerDetail?.status === 1">
                            <td>{{partnerDetail?.billDate | date: 'dd/MM/YYYY'}}</td>
                            <td>{{partnerDetail?.billNo || '--' }}</td>
                            <td>{{partnerDetail?.purchaseOrderData?.purchaseOrderNo || '--' }}</td>
                            <td>{{partnerDetails?.partner?.displayName || '--' }}</td>
                            <td>{{partnerDetail?.totalPrice ? 'AED ' + partnerDetail?.totalPrice : '--'}}</td>
                            <td>{{partnerDetail?.balance ? 'AED ' + partnerDetail?.balance : '--' }}</td>
                            <td class="redTxt">{{partnerDetail?.billStatus || '--'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ng-template #noData>
                <h4>No Vendor Invoice Found</h4>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Bill Payment</mat-panel-title>
                <mat-panel-description>
                    <button class="add-btn" [routerLink]="['/payments-made/bill-payment-new']">
                        <img src="assets/images/icons/icon-add-color.svg" alt="Add" /> New
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="table-wrapper" *ngIf="partnerDetails?.partner?.payments.length > 0; else noData2">
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Payment#</th>
                            <th>Reference No</th>
                            <th>Total Amount</th>
                            <th>Bank Charge</th>
                            <th>Payment Mode</th>
                        </tr>
                    </thead>
                    <tbody  *ngFor="let partnerDetail of partnerDetails?.partner?.payments">
                        <tr>
                            <td>{{partnerDetail?.paymentDate | date: 'dd/MM/YYYY' }}</td>
                            <td>{{partnerDetail?.paymentNo || '--'}}</td>
                            <td>{{partnerDetail?.referenceNo || '--'}}</td>
                            <td>{{partnerDetail?.totalAmount ? 'AED ' + partnerDetail?.totalAmount : '--'}}</td>
                            <td>{{partnerDetail?.bankCharge ? 'AED ' + partnerDetail?.bankCharge : '--'}}</td>
                            <td>{{partnerDetail?.paymentMode || '--'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ng-template #noData2>
                <h4>No Payments Found</h4>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Journals</mat-panel-title>
                <mat-panel-description>
                    <button class="add-btn" [routerLink]="['/manual-journals/add']">
                        <img src="assets/images/icons/icon-add-color.svg"/> New
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="table-wrapper" *ngIf="partnerDetails?.partner?.JournalEnteries.length > 0; else noData3">
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Journal Id</th>
                            <th>Description</th>
                            <th>Debit</th>
                            <th>Credit</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let partnerDetail of partnerDetails?.partner?.JournalEnteries">
                        <tr>
                            <td>{{partnerDetail?.createdAt | date: 'dd/MM/YYYY' }}</td>
                            <td>{{partnerDetail?.Journal?.journalNo || '--'}}</td>
                            <td>{{partnerDetail?.description || '--' }}</td>
                            <td>{{partnerDetail?.debit ? 'AED ' + partnerDetail?.debit : '--'}}</td>
                            <td>{{partnerDetail?.credit ? 'AED ' + partnerDetail?.credit : '--'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ng-template #noData3>
                <h4>No Journals Found</h4>
            </ng-template>
        </mat-expansion-panel>


    </mat-accordion>
</section>