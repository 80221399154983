import { createAction, props } from '@ngrx/store';

export const setPermissions = createAction(
  '[Permission] Set Permissions',
  props<{ permissions: any[] }>()
);

export const clearPermissions = createAction('[Permission] Clear Permissions');

export const setMyProfile = createAction(
  '[Permission] Set My Profile',
  props<{ myProfile: any }>()
);

export const clearMyProfile = createAction('[Permission] Clear My Profile');

export const setHeaderTitle = createAction(
  '[Permission] Set Header Title',
  props<{ headerTitle: string }>()
);

export const clearHeaderTitle = createAction('[Permission] Clear Header Title');
