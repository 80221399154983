<section class="cmn-innerpage-wrapper roles-management">
    <!-- <div class="head">
        <div class="search-wrapper">
            <input type="text" class="cmn-header-search" placeholder="Search" />
        </div>
    </div> -->
    <div class="inner-content">
        <div class="permission-group" *ngFor="let module of modules">
            <h2>{{module?.parentModuleData?.moduleName}}</h2>
            <div class="permission-box" *ngFor="let child of module?.childModules">
                <ng-container *ngIf="module?.childModules.length > 0">
                    <h3>{{child?.moduleName}}</h3>
                    <label class="switch">
                        <!-- <input type="checkbox" [checked]="isToggleOn" (change)="toggleAllPermissions($event,child?.id )"
                            [disabled]="true"> -->
                        <input type="checkbox" [checked]="permissionsState[child?.id]?.isToggleOn"
                            (change)="toggleAllPermissions($event, child?.id)">
                        <span class="slider round"></span>
                    </label>

                    <div class="check-roles-inner-wrapper">
                        <button (click)="toggleChildDropdown($event, child?.id)" class="permission-view-btn"
                            *ngIf="permissionMap[child?.id]?.length > 0; else noPermissions">
                            <span>
                                <span *ngFor="let permission of permissionMap[child?.id]; let i = index">
                                    {{ permission }}<span *ngIf="i < permissionMap[child?.id].length - 1">,</span>
                                </span>
                            </span>
                        </button>
                        <ng-template #noPermissions>
                            <button class="permission-view-btn" (click)="toggleChildDropdown($event, child?.id)">
                                Select permission
                            </button>
                        </ng-template>

                        <div [ngClass]="{ show: dropdownState[child?.id] }" class="roles-checkpopup filter-position"
                            #dropdown>
                            <button class="close-dropdown" (click)="toggleChildDropdown($event, child?.id)"></button>
                            <div class="filter-inner-content">
                                <h2>Permissions</h2>
                                <div class="check-wrapper">
                                    <div class="check-box" *ngFor="let permission of permissions">
                                        <label class="checkbox-wrapper">
                                            <input type="checkbox" [checked]="permission.checked"
                                                (change)="togglePermission(permission.id, $event, child?.id)" />
                                            {{permission?.permissionName}}
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-wrapper">
                                <button class="cmn-apply-btn" (click)="onApply()">Apply</button>
                            </div>
                        </div>
                    </div>

                </ng-container>
            </div>
            <div class="permission-box" *ngIf="module?.childModules.length === 0">
                <h3>{{module?.parentModuleData?.moduleName}}</h3>
                <label class="switch">
                    <!-- <input type="checkbox" [checked]="isToggleOn" (change)="toggleAllPermissions($event, module?.id)"
                        [disabled]="true"> -->

                    <input type="checkbox" [checked]="permissionsState[module?.id]?.isToggleOn"
                        (change)="toggleAllPermissions($event, module?.id)">
                    <span class="slider round"></span>
                </label>
                <div class="check-roles-inner-wrapper">
                    <button (click)="toggleDropdown($event, module?.id)" class="permission-view-btn">
                        <span *ngIf="permissionMap[module?.id]?.length > 0">
                            <span *ngFor="let permission of permissionMap[module?.id]; let i = index">
                                {{permission}}<span *ngIf="i < permissionMap[module?.id]?.length - 1">,</span>
                            </span>
                        </span>
                        <span *ngIf="permissionMap[module?.id]?.length === 0">Select permission</span>
                    </button>
                    <div [ngClass]="{ show: mainDropdownState[module?.id] }" class="roles-checkpopup filter-position" #dropdown>
                        <button class="close-dropdown" (click)="toggleDropdown($event, module?.id)"></button>
                        <!-- <form> -->
                        <div class="filter-inner-content">
                            <h2>Permissions</h2>
                            <div class="check-wrapper">
                                <div class="check-box" *ngFor="let permission of permissions">
                                    <label class="checkbox-wrapper">
                                        <input type="checkbox" [checked]="permission.checked"
                                        (change)="togglePermission(permission.id, $event, module?.id)"  />
                                        {{permission?.permissionName}}
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div class="btn-wrapper">
                            <button class="cmn-apply-btn" (click)="onApply()">Apply</button>
                        </div>
                        <!-- </form> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>