import { gql } from "apollo-angular";

export const LIST_BILLS = gql`
  query bills($search: String ) {
    bills(search: $search) {
    id
    billNo
    orderNo
    billDate
    paymentTerm
    dueDate
    subject
    customerNote
    billPaymentStatus
    subTotal
    tax
    adjustment
    totalPrice
    balance
    billStatus
    status
    createdAt
    updatedAt
    deletedAt
    billItems {
      id
      billId
      itemId
      description
      quantity
      rate
      discount
      amount
      status
      createdAt
      updatedAt
      deletedAt
    }
    billAttachments {
      id
      billId
      fileUrl
      status
      createdAt
      updatedAt
      deletedAt
    }
    projectId
    projectData {
      id
      projectCode
      projectName
      totalProjectCost
      description
      startDate
      endDate
      status
      budget
      threshold
      ApprovedQuoteDocumentURL
      proofDocument
      billingMethod
      costBudget
      revenueBudget
      partnerId
    }
    partnerId
    partnerData {
      id
      partnerType
      customerType
      displayName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      createdAt
      updatedAt
      deletedAt
    }
    billingAddressId
    billingAddressData {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
    shippingAddressId
    shippingAddressData {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
    journalId
    journalData {
      id
      date
      journalNo
      reference
      notes
      currencyId
      inCash
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
  }
`;

export const LIST_BILL = gql`
  query bill($id: Int!) {
    bill(id: $id) {
    id
    billNo
    orderNo
    billDate
    paymentTerm
    dueDate
    subject
    customerNote
    billPaymentStatus
    subTotal
    tax
    adjustment
    totalPrice
    balance
    billStatus
    status
    createdAt
    updatedAt
    deletedAt
    billItems {
      id
      billId
      itemData {
        itemName
        itemType
      }
      description
      quantity
      rate
      discount
      amount
      status
      createdAt
      updatedAt
      deletedAt
    }
    billAttachments {
      id
      billId
      fileUrl
      fileName
      status
      createdAt
      updatedAt
      deletedAt
    }
    projectId
    projectData {
      id
      projectCode
      projectName
      totalProjectCost
      description
      startDate
      endDate
      status
      budget
      threshold
      ApprovedQuoteDocumentURL
      proofDocument
      billingMethod
      costBudget
      revenueBudget
      partnerId
    }
    partnerId
    partnerData {
      id
      partnerType
      customerType
      displayName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      createdAt
      updatedAt
      deletedAt
    }
    billingAddressId
    billingAddressData {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
    shippingAddressId
    shippingAddressData {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
    journalId
    journalData {
      id
      date
      journalNo
      reference
      notes
      currencyId
      inCash
      status
      createdAt
      updatedAt
      deletedAt
    }
    paymentInfos {
      id
      paymentId
      refType
      refId
      amount
      createdAt
      updatedAt
    }
  }
  }
`;