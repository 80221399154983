<mat-dialog-content>
    <section class="add-profile-main-wrapper">
        <h2 class="cmn-popup-title">Add Item Location</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <form [formGroup]="addStorageForm">
            <div class="add-profile-dialog-wrapper">
                <div class="form-wrapper">
                    <label for="">Space</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Space" formControlName="space" />
                    </mat-form-field>
                    <div class="custom-error">
                        <span>
                            <mat-error *ngIf="
                            addStorageForm.get('space')!.touched &&
                            addStorageForm.get('space')!.hasError('required')
                      ">Space is required.</mat-error>
                        </span>
                        <span>
                            <mat-error *ngIf="addStorageForm.get('space')!.touched &&
                            addStorageForm.get('space')!.hasError('spaceError')" class="error">Cannot start or end
                                with a space.
                            </mat-error>
                        </span>
                    </div>
                </div>
                <div class="form-wrapper">
                    <label for="">Stack</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Stack number" formControlName="stack" />
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Row</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter row" formControlName="row" />
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Column</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter column" formControlName="column" />
                    </mat-form-field>
                </div>
            </div>
        </form>
        <div class="btn-wrapper">
            <button class="cmn-popup-btn" type="submit">
                Add Item location
            </button>
        </div>

    </section>
</mat-dialog-content>