import { gql } from 'apollo-angular';

export const PREFERENCE_CODES = gql`
  query preferenceCodes($search: String) {
    preferenceCodes(search: $search){
    id
    category
    prefix
    status
    createdAt
    updatedAt
    deletedAt
  }
  }
`;

