
    <section class="cmn-innerpage-wrapper credit-note">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" />
            </div>
            <button class="cmn-add-btn" (click)="openAssetsAddAssetsDialog()">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add Asset
            </button>
        </div>
        <div class="inner-content">
            <div class="common-noimage-tab-wrapper">
                <mat-tab-group animationDuration="0ms">
                    <!-- Mat Tab One -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Assets Overview</h2>
                        </ng-template>
                        <div class="assets-tab-inner-wrapper">
                            <ag-grid-angular style="width: 100%;" [rowData]="rowData" [columnDefs]="columnDefs"
                                [defaultColDef]="defaultColDef" [rowSelection]="rowSelection"
                                [suppressRowClickSelection]="true" [pagination]="true"
                                [paginationPageSize]="paginationPageSize"
                                [paginationPageSizeSelector]="paginationPageSizeSelector" [class]="themeClass" />
                        </div>
                    </mat-tab>
                    <!-- Mat Tab Two -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Asset Transfer</h2>
                        </ng-template>
                        <div class="assets-tab-inner-wrapper">
                            content 2
                        </div>
                    </mat-tab>
                    <!-- Mat Tab Three -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Asset Disposal</h2>
                        </ng-template>
                        <div class="assets-tab-inner-wrapper">
                            content 3
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>

        </div>

        <button type="button" (click)="openAssetTransferAssetDialog()">Dialog 1</button>
        <button type="button" (click)="openAssetsDisposeAssetDialog()">Dialog 2</button>
        <button type="button" (click)="openAssetsHistoryAssetDialog()">Dialog 3</button>

    </section>
