import { gql } from "apollo-angular";

export const CREATE_PROFILE = gql`
  mutation createProfile( $createProfileInput: CreateProfileInput!) {
    createProfile(createProfileInput: $createProfileInput) 
    {
    id
    profileName
    description
    rolePermissions {
      id
      profileId
      moduleId
      permissionId
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`;

export const UPDATE_PERMISSIONS = gql`
  mutation updateRolesPermissions( $profileId: String!, $permissionIds: [Float!]!, $moduleId: String!) {
    updateRolesPermissions(profileId: $profileId, permissionIds: $permissionIds, moduleId: $moduleId) 
}
`;

export const REMOVE_PROFILE = gql`
  mutation removeProfile( $id: Int!) {
    removeProfile(id: $id) 
}
`;

export const CLONE_PROFILE = gql`
  mutation cloneProfile( $profileId: Int!, $profileName: String!, $description: String!) {
    cloneProfile(profileId: $profileId, profileName: $profileName, description: $description) 
    {
    id
    profileName
    description
    rolePermissions {
      id
      profileId
      profileName
      moduleId
      permissionId

      status
    }

  }
}
`;