import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from, map } from 'rxjs';
import {
  CREATE_INVENTORY_ITEM,
  REMOVE_ITEM_BY_ID,
  UPDATE_ITEM,
} from '../graphql/inventory.mutations';
import { HttpClient } from '@angular/common/http';
import {
  GET_ITEMS,
  GET_ITEMS_PAGINATED,
  GET_ITEM_BY_ID,
  GET_VENDORS,
} from '../graphql/inventory.queries';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InventoryItemService {
  constructor(private http: HttpClient, private apollo: Apollo) {}

  createInventoryItem(createItemInput: any): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: CREATE_INVENTORY_ITEM,
      variables: {
        createItemInput: createItemInput,
      },
    });
  }

  fetchItems(search: string = '', filter: {}): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_ITEMS,
        variables: { search, filter },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.items));
  }

  fetchPaginatedItems( filter: any = {}, limit: number = 10, offset: number = 0): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_ITEMS_PAGINATED,
        variables: { filter, limit, offset },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.findAllItemsWithPagination));
  }

  fetchItemById(id: number): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_ITEM_BY_ID,
        variables: { id: id },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.item));
  }

  removeItemById(id: number): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: REMOVE_ITEM_BY_ID,
      variables: { id },
    });
  }

  updateItemById(updateItemInput: any): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: UPDATE_ITEM,
      variables: {
        updateItemInput: updateItemInput,
      },
    });
  }

  fetchVendors(): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_VENDORS,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data?.getVendorDropdownItems)
      );
  }

    //bulk upload item data
    bulkCreateItem(file: File): Observable<any> {
      const token = localStorage.getItem('AUTH_TOKEN');
      const formData = new FormData();
      formData.append(
        'operations',
        JSON.stringify({
          query: `
        mutation($file: Upload!) {
          bulkCreateItems(file: $file)
        }      
        `,
          variables: { file: null },
        })
      );
  
      formData.append('map', JSON.stringify({ '0': ['variables.file'] }));
      formData.append('0', file);
  
      return from(
        fetch(environment.url.projectModuleUrl, {
          method: 'POST',
          body: formData,
          headers: {
            authorization: token ? `Bearer ${token}` : '',
            'x-apollo-operation-name': 'uploadFile',
          },
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.errors) {
              throw new Error(
                result.errors[0].message || 'Bulk inventory creation failed'
              );
            }
            return result.data?.bulkCreateItems;
          })
      );
    }
}
