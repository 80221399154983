<div class="tab-content-wrapper">
  <form [formGroup]="personalInfoForm" (ngSubmit)="onNext()">
    <div class="image-upload">
      <div class="upload-box">
        <input
          #fileInput
          type="file"
          (change)="onFileSelected($event)"
          style="display: none"
          accept=".png, .jpg"
        />
        <!-- only add "uploaded" class to button if image is uploaded -->
        <button (click)="fileInput.click()" class="uploaded" type="button">
          <div class="img-wrapper">
            <!-- uploaded Image -->
            <!-- {{profilePhoto?.name}} -->
            <img
              [src]="updatedImage ? updatedImage : 'assets/images/profile.png'"
              alt="Profile Photo"
            />
          </div>
          <span class="photo-icon"></span>
        </button>
      </div>
    </div>
    <div class="employee-form-wrapper">
      <div class="form-wrapper">
        <label for="">First Name <span class="red">*</span></label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter First Name"
            formControlName="firstName"
            maxlength="45"
          />
          <mat-error
            *ngIf="
              personalInfoForm.get('firstName')?.invalid &&
              personalInfoForm.get('firstName')?.touched
            "
          >
            <span
              *ngIf="personalInfoForm.get('firstName')?.errors?.['invalidCharacters']"
              >Please enter a valid Name.
            </span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Middle Name</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Middle Name"
            formControlName="middleName"
            maxlength="45"
          />
          <mat-error *ngIf="personalInfoForm.get('middleName')?.invalid">
            <span
              *ngIf="personalInfoForm.get('middleName')?.errors?.['invalidCharacters']"
              >Please enter a valid Name.
            </span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Last Name <span class="red">*</span></label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Last Name"
            formControlName="lastName"
            maxlength="45"
          />
          <mat-error
            *ngIf="
              personalInfoForm.get('lastName')?.invalid &&
              personalInfoForm.get('lastName')?.touched
            "
          >
            <span
              *ngIf="personalInfoForm.get('lastName')?.errors?.['invalidCharacters']"
              >Please enter a valid Name.
            </span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="country">Phone Number STD Code</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Code"
            formControlName="phoneStdCode"
            [(value)]="selectedCountryCode"
          >
            <mat-option
              *ngFor="let country of countryCodes"
              [value]="country.code"
            >
              {{ country.name }} ({{ country.code }})
            </mat-option>
          </mat-select>
          <!-- <mat-error
            *ngIf="personalInfoForm.get('phoneStdCode')?.hasError('required')"
          >
            STD Code is required.
          </mat-error> -->
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Phone Number</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Phone Number"
            formControlName="phoneNumber"
            minlength="7"
            maxlength="15"
          />
          <mat-error
            *ngIf="
              personalInfoForm.get('phoneNumber')!.touched &&
              personalInfoForm.get('phoneNumber')!.hasError('invalidNumber')
            "
            >Please enter a valid phone number</mat-error
          ><mat-error
            *ngIf="
              personalInfoForm.get('phoneNumber')!.touched &&
              personalInfoForm.get('phoneNumber')!.hasError('minlength')
            "
            >Phone Number must be at least 8 digits long.
          </mat-error>
          <mat-error
            *ngIf="
              personalInfoForm.get('phoneNumber')!.touched &&
              personalInfoForm.get('phoneNumber')!.hasError('maxlength')
            "
            >Phone Number cannot be more than 15 digits long.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="country">Native Code</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Code"
            formControlName="nativeStdCode"
            [(value)]="selectedNativeCountryCode"
          >
            <mat-option
              *ngFor="let country of countryCodes"
              [value]="country.code"
            >
              {{ country.name }} ({{ country.code }})
            </mat-option>
          </mat-select>
          <!-- <mat-error
            *ngIf="personalInfoForm.get('nativeStdCode')?.hasError('required')"
          >
            Native Code is required.
          </mat-error> -->
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Home Country Number</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Home Country Number"
            formControlName="alternatePhoneNumber"
            minlength="7"
            maxlength="15"
          />
          <mat-error
            *ngIf="
              personalInfoForm.get('alternatePhoneNumber')!.touched &&
              personalInfoForm
                .get('alternatePhoneNumber')!
                .hasError('invalidNumber')
            "
            >Please enter a valid phone number</mat-error
          ><mat-error
            *ngIf="
              personalInfoForm.get('alternatePhoneNumber')!.touched &&
              personalInfoForm
                .get('alternatePhoneNumber')!
                .hasError('minlength')
            "
            >Phone Number must be at least 8 digits long.
          </mat-error>
          <mat-error
            *ngIf="
              personalInfoForm.get('alternatePhoneNumber')!.touched &&
              personalInfoForm
                .get('alternatePhoneNumber')!
                .hasError('maxlength')
            "
            >Phone Number cannot be more than 15 digits long.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Email Address</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Email Address"
            formControlName="email"
            [readonly]="isEditMode"
            (blur)="checkEmailExistence()"
            (input)="forceLowercase('email')"
          />
          <mat-error
            *ngIf="
              personalInfoForm.get('email')?.invalid &&
              personalInfoForm.get('email')?.touched
            "
          >
            <span
              *ngIf="personalInfoForm.get('email')?.errors?.['invalidEmail']"
            >
              Please enter a valid email address.
            </span>
          </mat-error>
          <mat-error
            *ngIf="personalInfoForm.get('email')?.hasError('emailExists')"
          >
            Email already exists.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-wrapper">
        <label for="">Date of Birth <span class="red">*</span></label>
        <mat-form-field>
            <input
                matInput
                [matDatepicker]="picker"
                formControlName="dateOfBirth"
                [matDatepickerFilter]="dateOfBirthFilter"
                placeholder="Enter date of Birth"
            />
            <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error
                *ngIf="personalInfoForm.get('dateOfBirth')!.touched &&
                personalInfoForm.get('dateOfBirth')!.hasError('required')"
            >Date of Birth is required.</mat-error>
            <mat-error
                *ngIf="personalInfoForm.get('dateOfBirth')!.hasError('invalidDate')"
            >
                Please enter a valid date of birth (at least 15 years ago).
            </mat-error>
        </mat-form-field>
    </div>
    

      <div class="form-wrapper">
        <label for="">Marital Status <span class="red">*</span></label>
        <mat-form-field>
          <mat-select
            placeholder="Select Marital Status"
            formControlName="maritalStatus"
          >
            <mat-option value="Single">Single</mat-option>
            <mat-option value="Married">Married</mat-option>
            <mat-option value="Divorced">Divorced</mat-option>
            <mat-option value="Widowed">Widowed</mat-option> </mat-select
          ><mat-error
            *ngIf="
              personalInfoForm.get('maritalStatus')!.touched &&
              personalInfoForm.get('maritalStatus')!.hasError('required')
            "
            >Marital Status is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Gender <span class="red">*</span></label>
        <mat-form-field>
          <mat-select placeholder="Select Gender" formControlName="gender">
            <mat-option value="Male">Male</mat-option>
            <mat-option value="Female">Female</mat-option>
            <mat-option value="Others">Others</mat-option> </mat-select
          ><mat-error
            *ngIf="
              personalInfoForm.get('gender')!.touched &&
              personalInfoForm.get('gender')!.hasError('required')
            "
            >Gender is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Nationality <span class="red">*</span></label>
        <mat-form-field>
          <mat-select
            placeholder="Select nationality"
            formControlName="nationality"
          >
            @for (country of countries; track country) {
            <mat-option [value]="country.name">{{ country.name }}</mat-option>
            }
          </mat-select>
          <mat-error
            *ngIf="
              personalInfoForm.get('nationality')!.touched &&
              personalInfoForm.get('nationality')!.hasError('required')
            "
            >Country is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Address <span class="red">*</span></label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Address"
            formControlName="address"
          /><mat-error
            *ngIf="
              personalInfoForm.get('address')!.touched &&
              personalInfoForm.get('address')!.hasError('required')
            "
            >Address is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Native Address</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Native Address"
            formControlName="nativeAddress"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" type="button" (click)="onCancel()">
        Cancel
      </button>
      <button class="cmn-next-btn" type="submit">Next</button>
    </div>
  </form>
</div>
