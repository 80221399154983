import { gql } from 'apollo-angular';

export const GET_EXPENSES = gql`
  query expenses {
    expenses {
      id
      date
      accountId
      accountData {
        id
        accountType
        accountName
        accountCode
      }
      paidThrough
      paidThroughData {
        accountCode
        accountName
        id
      }
      amount
      currencyId
      currencyData {
        id
        currencyCode
        currencyName
        currencySymbol
      }
      vendorId
      vendorData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
      }
      customerId
      customerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
      }
      invoiceNo
      note
      isBillable
      projectId
      projectData {
        id
        projectCode
        projectName
        totalProjectCost
        description
        startDate
        endDate
      }
      expenseStatus
      status
      isItemized
      createdAt
      updatedAt
      expenseItems {
        itemId
        expenseId
        accountId
        chartOfAccountData {
          id
          accountType
          accountName
          accountCode
          description
          amount
          parentAccountId
        }
        amount
        description
        quantity
        unitPrice
        status
      }
      attachedFiles {
        attachmentId
        expenseId
        fileName
        fileUrl
        mimeType
        fileSize
        uploadedAt
      }
    }
  }
`;

export const GET_EXPENSES_BY_ID = gql`
  query expense($id: Int!) {
    expense(id: $id) {
      id
      date
      accountId
      accountData {
        id
        accountType
        accountName
        accountCode
      }
      paidThrough
      paidThroughData {
        accountCode
        accountName
        id
      }
      amount
      currencyId
      currencyData {
        id
        currencyCode
        currencyName
        currencySymbol
      }
      vendorId
      vendorData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
      }
      customerId
      customerData {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
      }
      invoiceNo
      note
      isBillable
      projectId
      projectData {
        id
        projectCode
        projectName
        totalProjectCost
        description
        startDate
        endDate
      }
      expenseStatus
      status
      isItemized
      createdAt
      updatedAt
      expenseItems {
        itemId
        expenseId
        accountId
        chartOfAccountData {
          id
          accountType
          accountName
          accountCode
          description
          amount
          parentAccountId
        }
        amount
        description
        quantity
        unitPrice
        status
      }
      attachedFiles {
        attachmentId
        expenseId
        fileName
        fileUrl
        mimeType
        fileSize
        uploadedAt
      }
    }
  }
`;
