
    <section class="cmn-innerpage-wrapper payments-made">
        <div class="head">
            <div class="search-wrapper" *appHasPermission="['17', Permission.List]">
                <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)" />
            </div>
            <button class="cmn-upload-btn" *appHasPermission="['17', Permission.Upload]" (click)="openDialog()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload</span>
            </button>
            <button class="cmn-add-btn" *appHasPermission="['17', Permission.Create]" (click)="navigateTo('/payments-made/bill-payment-new')">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New Payments
            </button>
            <button class="cmn-filter-btn" *appHasPermission="['17', Permission.List]" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }">
                <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
                <span *ngIf="selectedCount > 0">{{ selectedCount }}</span>
            </button>
            <!-- Filter -->
            <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <form>
                    <h6 class="cmn-popup-heading" (click)="clearFilters()">
                        Filter <span>Clear All</span>
                    </h6>
                    <div class="filter-inner-content">
                        <h2>Payment Mode</h2>
                        <div class="check-wrapper">
                            <div class="check-box" *ngFor="let mode of paymentModeList">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" [checked]="selectedModes.includes(mode)"
                                        (change)="toggleMode(mode)" />
                                    {{ mode }}
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="button" (click)="onApplyFilters()">Apply</button>
                    </div>
                </form>
            </div>
            <!-- Filter -->
        </div>
        <div class=" inner-content" *appHasPermission="['17', Permission.List]">
            <ag-grid-angular style="width: 100%;" [rowData]="rowData" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [suppressRowClickSelection]="true"
                [pagination]="true" [paginationPageSize]="paginationPageSize"
                [paginationPageSizeSelector]="paginationPageSizeSelector" [class]="themeClass"
                (cellClicked)="onCellClicked($event)" />
        </div>
    </section>
