import gql from 'graphql-tag';

export const CREATE_DESIGNATION = gql`
  mutation createDesignation($input: CreateDesignationInput!) {
    createDesignation(createDesignationInput: $input) {
      id
    designationName
    status
    createdAt
    updatedAt
    deletedAt
    }
  }
`;

export const UPDATE_DESIGNATION = gql`
  mutation updateDesignation($updateDesignationInput: UpdateDesignationInput!) {
    updateDesignation(updateDesignationInput: $updateDesignationInput)  {
      id
    designationName

    status
    createdAt
    updatedAt
    deletedAt
  }
  }
`;

export const REMOVE_DESIGNATION_BY_ID = gql`
mutation removeDesignation($id: Int!) {
  removeDesignation(id: $id)
}
`;
