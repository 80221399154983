import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-presons',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './contact-persons.component.html',
  styleUrl: '../../client-new.component.scss'
})
export class ContactPersonsComponent {
  contactPersonForm: FormGroup;
  @Input() customerData: any = {};
  @Output() formSubmit = new EventEmitter<any>();
  customerId: string | null;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private toastr: ToastrService,
  ){}
  ngOnInit() {
    this.initForm();
    this.route.paramMap.subscribe(params => {
      this.customerId = params.get('id');
      if (this.customerId) {
        this.fetchPartnerById();
        }
      });
  }

  initForm() {
    this.contactPersonForm = this.fb.group({
      contactPersons: this.fb.array([]) 
    });
    this.addContactPersons(); 
  }

  get contactPersonsArray(): FormArray {
    return this.contactPersonForm.get('contactPersons') as FormArray;
  }

  updateFirstContactPerson(salutation: string, firstName: string, lastName: string, email: string, phone: string, mobile: string) {
    if (this.contactPersonsArray.length > 0) {
      const firstContact = this.contactPersonsArray.at(0) as FormGroup;
      firstContact.get('salutation')?.setValue(salutation);
      firstContact.get('firstName')?.setValue(firstName);
      firstContact.get('lastName')?.setValue(lastName);
      firstContact.get('email')?.setValue(email);
      firstContact.get('phone')?.setValue(phone);
      firstContact.get('mobile')?.setValue(mobile); 
    }
  }

  addContactPersons() {
    const contactPerson = this.fb.group({
      salutation: ['Mr'],
      firstName: [''],
      lastName: [''],
      email: [''],
      phone: [''],
      mobile: [''],
      primaryContact: [this.contactPersonsArray.length === 0],
    });
    this.contactPersonsArray.push(contactPerson);
    contactPerson.get('primaryContact')?.valueChanges.subscribe((isPrimary) => {
      if (isPrimary) {
        this.setPrimaryContactOnly(contactPerson);
      }
    });
  }

  setPrimaryContactOnly(selectedContact: any) {
    this.contactPersonsArray.controls.forEach((contact) => {
      if (contact !== selectedContact) {
        contact.get('primaryContact')?.setValue(false, { emitEvent: false });
      }
    });
    selectedContact.get('primaryContact')?.setValue(true, { emitEvent: false });
  }
  
  fetchPartnerById() {
    this.customerService.fetchPartnerById(Number(this.customerId)).subscribe({
      next: (data) => {
        this.customerData = data;
        this.populateFormWithCustomerData();
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  populateFormWithCustomerData() {
    if (this.customerData && this.customerData.contacts) {
      this.contactPersonsArray.clear();
      this.customerData.contacts.forEach((contact: any) => {
        const contactPerson = this.fb.group({
          id: Number(contact.id),
          salutation: [contact.salutation || ''],
          firstName: [contact.firstName || ''],
          lastName: [contact.lastName || ''],
          email: [contact.email || ''],
          phone: [contact.phone || ''],
          mobile: [contact.mobile || ''],
          primaryContact: [contact.primaryContact || false],
        });
  
        contactPerson.get('primaryContact')?.valueChanges.subscribe((isPrimary) => {
          if (isPrimary) {
            this.setPrimaryContactOnly(contactPerson);
          }
        });
        this.contactPersonsArray.push(contactPerson);
      });
    }
  }

  onRemoveContact(index: number, id: any) {
    this.contactPersonsArray.removeAt(index);
    if(id != null){
      this.customerService.removeContact(id).subscribe({
        next: (data) => {
          if(data){
            this.toastr.success('Contact removed successfully!','Success');;
          }
        },
        error: (error) => {
          this.toastr.error(error,'Error');
        }
      })
    }
  }

  onSubmit() {
    this.formSubmit.emit(this.contactPersonForm.value);
  }

  onCancel(){
    this.contactPersonForm.reset();
  }
}
