
  <section class="cmn-innerpage-wrapper notification">
    <div class="head">
      <h2>All Notifications</h2>
      <!-- <button
        class="cmn-filter-btn"
        (click)="toggleDropdown($event)"
        [ngClass]="{ 'class-clicked': isDropdownOpen }"
      >
        <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
      </button> -->
      <!-- Filter Popup -->
      <div
        [ngClass]="{ show: isDropdownOpen }"
        class="common-dropdown filter-position"
        #dropdown
      >
        <button
          class="close-dropdown"
          (click)="toggleDropdown($event)"
        ></button>
        <div>
          <h2>Leave Type</h2>
          <div class="check-wrapper">
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" formControlName="CL" />
                Casual Leave
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" formControlName="EL" />
                Earned Leave
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" formControlName="EML" />
                Emergency Leave
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" formControlName="ML" />
                Marriage Leave
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" formControlName="LOP" />
                Loss Of Pay
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="btn-wrapper">
            <button class="cmn-apply-btn" type="submit">Apply</button>
          </div>
        </div>
      </div>
    </div>

    <div class="notification-inner-wrapper">
      <div class="notification-box">
        <h3>Budget Approval <span>Just Now</span></h3>
        <div class="content">
          <p>
            Project Interior Design from Luxury Space branch needs budget
            approval for proceeding with the project
          </p>
          <div class="action-wrapper">
            <button class="reject">Reject</button>
            <button class="approve">Approve</button>
          </div>
        </div>
      </div>
      <div class="notification-box">
        <h3>Petty Cash Approval <span>2m ago</span></h3>
        <div class="content">
          <p>
            Project Interior Design from Luxury Space branch needs budget
            approval for proceeding with the project Project Interior Design
            from Luxury Space branch needs budget approval for proceeding with
            the project Project Interior Design from Luxury Space branch needs
            budget approval for proceeding with the project
          </p>
          <div class="action-wrapper">
            <button class="reject">Reject</button>
            <button class="approve">Approve</button>
          </div>
        </div>
      </div>
      <div class="notification-box">
        <h3>Leave Request <span>4hr ago</span></h3>
        <div class="content">
          <p>
            Project Interior Design from Luxury Space branch needs budget
            approval for proceeding with the project
          </p>
          <!-- <div class="action-wrapper">
                        <button class="reject">Reject</button>
                        <button class="approve">Approve</button>
                    </div> -->
        </div>
      </div>
    </div>
  </section>

