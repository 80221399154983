<mat-dialog-content>
    <section class="asset-add-assets-wrapper">
        <form [formGroup]="vehicleForm" (ngSubmit)="onSubmit()">
        <h2 class="cmn-popup-title">Assign Vehicle</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <!-- <ul>
            <li>Vehicle Name: Maruthi Jimny</li>
            <li>Vehicle Number: A 12345</li>
        </ul> -->
        <h3> {{ assetName }}</h3>
        <h4> {{ vehicleNumber }}</h4>
        <!-- <div class="form-wrapper">
            <label for="">Vehicle Name <span class="red">*</span></label>
            <mat-form-field>
                <input matInput placeholder="Vehicle Name" />
            </mat-form-field>
        </div>
        <div class="form-wrapper">
            <label for="">Vehicle Number <span class="red">*</span></label>
            <mat-form-field>
                <input matInput placeholder="Vehicle Number" />
            </mat-form-field>
        </div> -->
        <!-- <div class="form-wrapper">
            <label for="">Assignee <span class="red">*</span></label>
            <mat-form-field>
                <input matInput placeholder="Assignee" formControlName="assignee" type="number" />
            </mat-form-field>
        </div> -->

        <!-- <div class="form-wrapper">
            <label for="assignee">Assignee <span class="red">*</span></label> -->
            <!-- <mat-form-field>
              <mat-select placeholder="Select Assignee" formControlName="assignee">
                <mat-option *ngFor="let assignee of assigneeList" [value]="assignee.id">
                  {{ assignee.firstName }} {{ assignee.middleName }} {{ assignee.lastName }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
           
          <!-- </div> -->

          <div class="form-wrapper">
            <label for="assignee">Employee <span class="red">*</span></label>
            <mat-form-field>
              <mat-select placeholder="Search Employee" formControlName="assignee"
                  (selectionChange)="onDriverSelected($event)">
                  
                  <!-- SEARCH INPUT -->
                  <mat-option>
                      <ngx-mat-select-search [formControl]="employeeSearchControl"
                          placeholderLabel="Search Employee"  [noEntriesFoundLabel]="'No options found'" >
                      </ngx-mat-select-search>
                  </mat-option>
  
                  <!-- DROPDOWN OPTIONS -->
                  <mat-option *ngFor="let employee of assigneeList" [value]="employee.id">
                      {{ employee.firstName }} {{ employee.lastName }}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <mat-error *ngIf="vehicleForm.get('amount')?.touched && vehicleForm.get('amount')?.hasError('required')">
            Employee is required.
          </mat-error>
          </div>
        
        

    
          
        <!-- <div class="form-wrapper">
            <label for="">Task <span class="red">*</span></label>
            <mat-form-field>
                <input matInput placeholder="Task" formControlName="task" type="text"/>
            </mat-form-field>
            <mat-error *ngIf="vehicleForm.get('task')!.touched && 
            vehicleForm.get('task')!.hasError('required')">
                *Task is required.
            </mat-error>
        </div> -->
        <div class="form-wrapper">
            <label for="">Date <span class="red">*</span></label>
            <mat-form-field>
                <input matInput [matDatepicker]="date" placeholder="Select date" formControlName="date" />
                <mat-datepicker-toggle matIconSuffix [for]="date"></mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
              </mat-form-field>
        </div>
        <div class="form-wrapper">
            <label for="">Task <span class="red">*</span></label>
            <mat-form-field>
                <input matInput placeholder="Task title" formControlName="task" type="text" />
              </mat-form-field>
              <mat-error *ngIf="vehicleForm.get('task')?.touched && vehicleForm.get('task')?.hasError('required')">
                Task title is required.
              </mat-error>
        </div>
        <div class="form-wrapper">
            <label for="">Note
                 <!-- <span class="red">*</span> -->
                 </label>
            <mat-form-field>
                <textarea matInput placeholder="Enter description" formControlName="note"></textarea>
            </mat-form-field>
        </div>
        <!-- <div class="form-wrapper grid-form-wrapper">
            <div class="span-2">
                <mat-form-field>
                    <input matInput formControlName="" placeholder="Vehicle" />
                </mat-form-field>
            </div>
            <div class="span-2">
                <mat-form-field>
                    <input matInput formControlName="" placeholder="Assignee" />
                </mat-form-field>
            </div>
            <div class="span-2">
                <mat-form-field>
                    <textarea matInput placeholder="Enter description"></textarea>
                </mat-form-field>
            </div>
        </div> -->
        <!-- <div class="btn-wrapper">
            <button class="cmn-next-btn "  [disabled]="loading">
                Submit
            </button>
        </div> -->
        <div class="btn-wrapper">
            <button class="cmn-next-btn" [disabled]="loading">
                <span *ngIf="!loading">Submit</span>
                <span *ngIf="loading">Loading...</span>
            </button>
        </div>
        
    </form>
    </section>
</mat-dialog-content>