import { gql } from '@apollo/client';

export const GET_LOCATIONS = gql`
  query locations($search: String) {
    locations(search: $search) {
      id
      locationName
      latitude
      longitude
    }
  }
`;
