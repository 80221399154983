
    <section class="cmn-innerpage-wrapper payroll-list">
        <div class="head">
            <!-- <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" />
            </div> -->
            <!-- <button class="cmn-settings-btn" (click)="navigateTo('/payroll-settings')">
                <img src="assets/images/icons/icon-settings.svg" alt="Add icon" /> Payroll Settings
            </button> -->
            <!-- <button class="cmn-filter-btn" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }">
                <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
            </button> -->
            <!-- Filter Popup -->
            <!-- <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <div>
                    <h2>Branch</h2>
                    <div formArrayName="branches" class="check-wrapper">
                        <div class="check-box">
                            <label class="checkbox-wrapper">
                                <input type="checkbox" />
                                test
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <h2>Employee Type</h2>
                    <div formArrayName="employeeTypes" class="check-wrapper">
                        <div class="check-box">
                            <label class="checkbox-wrapper">
                                <input type="checkbox" />
                                test
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="submit">Apply</button>
                    </div>
                </div>
            </div> -->
            <!-- Filter Popup -->
        </div>
        <div class="inner-content">
            <app-payroll-list-table [selectedMonth]="selectedMonth" [selectedYear]="selectedYear"></app-payroll-list-table>
        </div>
    </section>
