<div class="tab-inner-content">
  <h2>Journal Report</h2>
  <h5>
    From {{ startDate | date : "MM/d/YYYY" }} to
    {{ endDate | date : "MM/d/YYYY" }}
  </h5>
  <table>
    <ng-container *ngIf="journalReport?.length > 0; else noData">
      <ng-container *ngFor="let group of journalReport">
        <tr>
          <th>{{ group.journalDate }} (ID: {{ group.journalId }})</th>
          <th>Debit</th>
          <th>Credit</th>
        </tr>
        <tr class="title">
          <td colspan="3">{{ group.description }}</td>
        </tr>
        <ng-container *ngFor="let item of group.entries">
          <tr class="child">
            <td>{{ item.accountName }}</td>
            <td>{{ item.debit | number : "1.2-2" }}</td>
            <td>{{ item.credit | number : "1.2-2" }}</td>
          </tr>
        </ng-container>
        <tr class="sub-total">
          <td>Total</td>
          <td>{{ group.totalDebit | number : "1.2-2" }}</td>
          <td>{{ group.totalCredit | number : "1.2-2" }}</td>
        </tr>
      </ng-container>
    </ng-container>
    <!-- Display this if journalReport is empty -->
    <ng-template #noData>
      <tr>
        <td colspan="3" class="no-data">
          There are no transactions during the selected date range
        </td>
      </tr>
    </ng-template>
  </table>
</div>
