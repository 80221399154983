<div class="tab-inner-content">
  <h2>Petty Cash Report </h2>
  <h5>
    From {{ startDate | date : "MM/d/YYYY" }} to
    {{ endDate | date : "MM/d/YYYY" }}
    <div style="float: right;">AED {{ pettyCashReport?.totalInflow | number:'1.2-2' }}</div>
  </h5>

  <table>
    <tr>
      <th colspan="2">Project</th>
      <th>Total</th>
    </tr>
    <tr *ngFor="let item of pettyCashReport.projectSummary" class="child">
      <td colspan="2">{{ item.projectDetails?.projectName }}</td>
      <td>AED {{ item.totalAmount | number:'1.2-2' }}</td>
    </tr>
    <tr class="sub-total">
      <td>&nbsp;</td>
      <td>Project Amount</td>
      <td>AED {{ pettyCashReport?.projectTotal | number:'1.2-2' }}</td>
    </tr>
  </table>
  <table>
    <tr>
      <th colspan="2">Employee</th>
      <th>Total</th>
    </tr>
    <tr *ngFor="let item of pettyCashReport.expenseSummary" class="child">
      <td colspan="2">{{ item.employeeDetails?.firstName }} {{ item.employeeDetails?.lastName }}</td>
      <td>AED {{ item.totalAmount | number:'1.2-2' }}</td>
    </tr>
    <tr class="sub-total">
      <td>&nbsp;</td>
      <td>Employee Amount</td>
      <td>AED {{ pettyCashReport?.employeeTotal | number:'1.2-2' }}</td>
    </tr>
  </table>
</div>
