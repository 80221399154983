<mat-dialog-content>
  <section class="petty-cash-wrapper">
    <h2 class="cmn-popup-title">Petty Cash Details</h2>
    <button class="cmn-close" mat-dialog-close></button>

    <div class="title">
      <div>
        <h2>{{data?.projectName}}</h2>
      </div>
      <div class="totalBalance">
        <h2>AED {{(pettyCashDetail?.totalInFlow - pettyCashDetail?.totalOutFlow) | number: '1.2-2'}}</h2>
      </div>
    </div>

    <div class="table-wrapper" *ngIf="pettyCashDetail && pettyCashDetail?.pettyCashes?.length; else noData">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Description</th>
            <th>In</th>
            <th>Out</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let petty of pettyCashDetail?.pettyCashes">
            <td>{{ petty?.pettyCashNumber || '--' }}</td>
            <td>{{ petty?.date | date: 'dd/MM/YYYY' }}</td>
            <td>{{ petty?.description ? (petty.description | slice:0:20) + (petty.description.length > 20 ? '...' : '')
              : '--' }}</td>
            <td>
              {{ petty?.flow === 'In' && petty?.amount ? 'AED ' + petty?.amount.toFixed(2) : '-' }}
            </td>
            <td>
              {{ petty?.flow === 'Out' && petty?.amount ? 'AED ' + petty?.amount.toFixed(2) : '-' }}
          </tr>

        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td class="petty-total">{{ pettyCashDetail?.totalInFlow ? 'AED ' + pettyCashDetail?.totalInFlow.toFixed(2) : '-'}}</td>
            <td class="petty-total">{{ pettyCashDetail?.totalOutFlow ? 'AED ' + pettyCashDetail?.totalOutFlow.toFixed(2) : '-'}}</td>
          </tr>
        </tfoot>
      </table>
    </div>

    <ng-container>
    <div class="upload-file-group">
      <h2>Attachments : </h2>
      <div *ngIf="hasAttachments(); else noAttachments">
        <div class="uploaded-file" *ngFor="let cash of pettyCashDetail?.pettyCashes">
            <h6 *ngIf="cash?.fileUrl && cash?.fileName">
                <span (click)="onViewDocument(cash?.fileUrl)" style="cursor: pointer">
                    {{ cash?.fileName }}
                </span>
            </h6>
        </div>
      </div>
    </div>
  </ng-container>


    <ng-template #noAttachments>
      <p>No attachments found.</p>
    </ng-template>

    <ng-template #noData>
      <p>No records found.</p>
    </ng-template>
    <!-- <button class="cmn-upload-submit-btn active" (click)="onClose()">
      <span> Close</span>
    </button> -->
  </section>
</mat-dialog-content>