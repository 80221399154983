export const PAYMENT_MODES = [
  'Cash',
  'Cheque',
  'Bank Transfer',
  'Credit Card',
  'Bank Remittance',
  // 'UPI',
];

export const PAYMENT_TERMS = [
  'Net 15',
  'Net 30',
  'Net 45',
  'Net 60',
  'Due On Receipt',
  'Due end of the month',
  'Due end of next month',
  'Custom',
];

export enum PartnerTypesEnum {
  Customer = 'Customer',
  Vendor = 'Vendor',
}

export enum PaymentTypesEnum {
  INVOICE = 'INVOICE',
  BILL = 'BILL',
}

export enum QUOTESTATUS {
  DRAFT = 'Draft',
  SENT = 'Sent',
  DELETED = 'Deleted',
  DECLINED = 'Declined',
  ACCEPTED = 'Accepted',
  INVOICED = 'Invoiced',
}

export enum AddressTypeEnum {
  billing = 'BILLING',
  shipping = 'SHIPPING',
}

export const MAIL_ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'application/pdf',
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
];
