import { gql } from 'apollo-angular';

export const GET_EMPLOYEES = gql`
  query getEmployees($search: String, $filter: EmployeeFilter) {
    getEmployees(search: $search, filter: $filter) {
      id
      employeeCode
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
    }
  }
`;

export const GET_BRANCH_NAMES = gql`
  query branches($search: String) {
    branches(search: $search) {
      id
      branchName
      address
      city
      state
      email
    }
  }
`;

export const GET_ALL_PROJECTS = gql`
  query listProjects($search: String) {
    listProjects(search: $search) {
      id
      projectCode
      projectName
      totalProjectCost
      description
      revenueBudget
      branchId
      billingMethod
      partnerId
      partner {
        id
        displayName
      }
    }
  }
`;

export const GET_SINGLE_PROJECT = gql`
  query projectDetail($id: Int!) {
    projectDetail(id: $id) {
      id
      projectCode
      projectName
      totalProjectCost
      description
      threshold
      billingMethod
      costBudget
      revenueBudget
      branchId
      costCenterId
      partnerId
      projectTasks {
        id
        taskName
        description
      }
      projectResources {
        id
        projectId
        employeeId
        employeeData {
          email
        }
        status
      }
    }
  }
`;
