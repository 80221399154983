
  <section class="cmn-innerpage-wrapper inventory-details">
    <div class="head">
      <div class="search-wrapper" *appHasPermission="['2', Permission.List]">
        <input
          type="text"
          class="cmn-header-search"
          placeholder="Search"
          (input)="onSearch($event)"
        />
      </div>
      <!-- <button class="cmn-upload-btn" (click)="openDialog()">
        <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
        <span>Upload</span>
      </button> -->
      <div class="btn-wrapper">
        <button class="cmn-add-btn" *appHasPermission="['2', Permission.Create]" (click)="navigateToAddNew()">
          <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New
          Inventory
        </button>
        <button class="add-stock" type="button" (click)="openAddStockDialog()">
          <img src="assets/images/icons/icon-add-color.svg" alt="Add Stock" />
          Add Stock
        </button>
      </div>
    </div>
    <div class="sub-head">
      <ul>
        <li class="main">All Inventory</li>
        <li class="edit" *appHasPermission="['2', Permission.Edit]" (click)="navigateToEditPage()">
          <img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit" />
          Edit
        </li>
        <!-- <li class="edit">
          <img
            src="assets/images/icons/icon-export-accounts.svg"
            alt="Export"
          />
          Export
        </li> -->
      </ul>
    </div>
    <div class="inventory-main-tab-wrapper">
      <div class="tab-left">
        <div class="tab-box-main-wrapper">
          <button
            *ngFor="let item of allItems; let i = index"
            class="tab-custom-button"
            [class.active]="selectedTabIndex === i"
            (click)="selectTab(i)"
          >
            <div class="inn-box">
              <h2>{{ item?.itemName }}</h2>
              <h6>{{ item?.skuCode }}</h6>
            </div>
            <div class="inn-box">
              <h6>{{ item?.quantity }} {{ item?.unit }}</h6>
              <h5>{{ item?.itemType }}</h5>
            </div>
          </button>
        </div>
      </div>
      <div class="inventory-main-tab-content-wrapper">
        <div *ngIf="itemData">
          <div class="chart-tab-content">
            <div class="common-noimage-tab-wrapper">
              <mat-tab-group animationDuration="0ms">
                <!-- Mat Tab One -->
                <mat-tab>
                  <ng-template mat-tab-label>
                    <h2 class="leave-tab-title">Overview</h2>
                  </ng-template>
                  <div class="inventory-tab-inner-content">
                    <!-- Group 1 -->
                    <div class="overview-box">
                      <h3>Item type</h3>
                      <h4>:</h4>
                      <h5>{{ itemData?.itemType }}</h5>
                    </div>
                    <div class="overview-box">
                      <h3>SKU</h3>
                      <h4>:</h4>
                      <h5>{{ itemData?.skuCode }}</h5>
                    </div>
                    <div class="overview-box">
                      <h3>Unit</h3>
                      <h4>:</h4>
                      <h5>{{ itemData?.unit }}</h5>
                    </div>
                    <h6>Sales Information</h6>
                    <div class="overview-box">
                      <h3>Sales Account</h3>
                      <h4>:</h4>
                      <h5>{{ itemData?.salesAccountData?.accountName }}</h5>
                    </div>
                    <div class="overview-box">
                      <h3>Selling Price</h3>
                      <h4>:</h4>
                      <h5>AED {{ itemData?.sellingPrice | number:'1.2-2' }}</h5>
                    </div>
                    <h6>Purchase Information</h6>
                    <div class="overview-box">
                      <h3>Purchase Account</h3>
                      <h4>:</h4>
                      <h5>{{ itemData?.purchaseAccountData?.accountName }}</h5>
                    </div>
                    <div class="overview-box">
                      <h3>Cost Price</h3>
                      <h4>:</h4>
                      <h5>AED {{ itemData?.costPrice | number:'1.2-2' }}</h5>
                    </div>
                    <!-- <div class="overview-box">
                      <h3>Unit</h3>
                      <h4>:</h4>
                      <h5>Kg</h5>
                    </div> -->
                    <!-- <div class="overview-box">
                      <h3>Created Source</h3>
                      <h4>:</h4>
                      <h5>User</h5>
                    </div> -->
                    <!-- Group 3 -->
                  </div>
                </mat-tab>
                <!-- Mat Tab Two -->
                <mat-tab>
                  <ng-template mat-tab-label>
                    <h2 class="leave-tab-title">Transactions</h2>
                  </ng-template>
                  <div class="inventory-tab-inner-content">
                    <ag-grid-angular
                      style="width: 100%;"
                      [rowData]="rowData"
                      [columnDefs]="columnDefs"
                      [defaultColDef]="defaultColDef"
                      [rowSelection]="rowSelection"
                      [suppressRowClickSelection]="true"
                      [pagination]="true"
                      [paginationPageSize]="paginationPageSize"
                      [paginationPageSizeSelector]="paginationPageSizeSelector"
                      [class]="themeClass"
                    />
                  </div>
                </mat-tab>
                <!-- <mat-tab>
                  <ng-template mat-tab-label>
                    <h2 class="leave-tab-title">Transactions</h2>
                  </ng-template>
                  <div class="inventory-tab-inner-content">
                    <ag-grid-angular
                      style="width: 100%"
                      [rowData]="rowData"
                      [columnDefs]="columnDefs"
                      [defaultColDef]="defaultColDef"
                      [rowSelection]="rowSelection"
                      [suppressRowClickSelection]="true"
                      [pagination]="true"
                      [paginationPageSize]="paginationPageSize"
                      [paginationPageSizeSelector]="paginationPageSizeSelector"
                      [class]="themeClass"
                    />
                  </div>
                </mat-tab> -->
              </mat-tab-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

