<mat-dialog-content>
  <section class="invoice-apply-credits-wrapper">
    <h2 class="cmn-popup-title">Apply Credits to Invoice</h2>
    <button class="cmn-close" mat-dialog-close></button>

    <div class="form-main-content-wrapper">
      <div class="top-content-with-icon">
        <div>
          <div>
            <img src="../../../assets/images/cash.svg" alt="" />
          </div>
          <div>
            <h5>{{invoiceDetails?.invoiceNo}}</h5>
            <p>Invoice#</p>
          </div>
        </div>
        <div>
          <div>
            <img src="../../../assets/images/cah-gray.svg" alt="" />
          </div>
          <div>
            <h5>{{invoiceDetails?.totalPrice}}</h5>
            <p>Total Invoice Amount</p>
          </div>
        </div>
        <div>
          <div>
            <img src="../../../assets/images/cah-gray.svg" alt="" />
          </div>
          <div>
            <h5>{{invoiceDetails?.balance}}</h5>
            <p>Invoice Due Amount</p>
          </div>
        </div>
      </div>

      <ul *ngFor="let note of allNotes; let i = index">
        <li>
          <h6>Credit Note#</h6>
          <p>{{ note?.noteNo }}</p>
        </li>
        <li>
          <h6>Credit Note Date</h6>
          <p>{{ note?.noteDate | date: 'dd/MM/YYYY' }}</p>
        </li>
        <li>
          <h6>Credit Amount</h6>
          <p>AED {{ note?.totalPrice | number: '1.2-2' }}</p>
        </li>
        <li>
          <h6>Credit Balance</h6>
          <p>AED {{ note?.balance }}</p>
        </li>
        <li>
          <h6>Credits to Apply</h6>
          <div class="form-wrapper">
            <mat-form-field>
              <input matInput [formControl]="getCreditControl(i)" (input)="calculateAmountToCredit()" type="number"
                min="0" [max]="note?.balance" />
              <mat-error *ngIf="getCreditControl(i).hasError('exceedsNoteBalance')">
                Cannot apply more than the available balance for this note.
              </mat-error>
              <mat-error *ngIf="getCreditControl(i).hasError('exceedsInvoiceAmount')">
                Total applied credits exceed the invoice amount.
              </mat-error>

            </mat-form-field>
          </div>
        </li>
      </ul>

      <div>
        <h6>Credit utilized</h6>
        <p>: &nbsp;&nbsp; {{ totalAmountToCredit | currency: ' AED' }}</p>
      </div>
      <div>
        <h6>Invoice Balance Due</h6>
        <p>
          : &nbsp;&nbsp; {{ calculateInvoiceBalanceDue() | currency: ' AED' }}
        </p>
      </div>
    </div>

    <div class="btn-wrapper">
      <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
      <button class="cmn-next-btn" (click)="applyCreditsToInvoices()" [disabled]="invoiceForm.invalid">
        Apply
      </button>
    </div>
  </section>
</mat-dialog-content>