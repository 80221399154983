import {Component, ViewChild} from "@angular/core";
import {OtherDetailsComponent} from "../../../client-new/components/other-details/other-details.component";
import {AddressComponent} from "../../../client-new/components/address/address.component";
import {ContactPersonsComponent} from "../../../client-new/components/contact-persons/contact-persons.component";
import {RemarksComponent} from "../../../client-new/components/remarks/remarks.component";
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators
} from "@angular/forms";
import {CustomerService} from "../../../../features/customers/services/customer.service";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {customEmailValidator} from "../../../../shared/services/validations";
import {CommonModule} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {ClientNewComponent} from "../../../client-new/client-new.component";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatTabsModule} from "@angular/material/tabs";

@Component({
    selector: 'create-client-dialog',
    templateUrl: 'create-client-dialog.component.html',
    styleUrls: ['../../invoice-new.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        ClientNewComponent,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        MatSelectModule,
        MatTabsModule,
        OtherDetailsComponent,
        AddressComponent,
        ContactPersonsComponent,
        RemarksComponent,
    ]
})


export class CreateClientDialog {
    @ViewChild(OtherDetailsComponent)
    otherDetailsComponent !: OtherDetailsComponent;
    @ViewChild(AddressComponent)addressComponent !: AddressComponent;
    @ViewChild(ContactPersonsComponent)
    contactPersonsComponent !: ContactPersonsComponent;
    @ViewChild(RemarksComponent)remarksComponent !: RemarksComponent;
    newCustomerForm : FormGroup;
    customerData : any = {};
    addressDetails : any;
    contactDetails : any;
    contactPersons : any = [];
    remarkDetails : any;
    documentDetails : any = {};
    fileName : any;
    contacts : any;
    customerId : any;
    validationError : boolean = false;

    constructor(private fb : FormBuilder, public dialogRef : MatDialogRef < CreateClientDialog >, private customerService : CustomerService, private toastr : ToastrService, private route : ActivatedRoute, private router : Router) {}

    ngOnInit() {
        this.initForm();
        this.route.paramMap.subscribe((params) => {
            this.customerId = params.get('id');
            if (this.customerId) {
                this.fetchPartnerById();
            }
        });
    }

    initForm() {
        if (this.customerData && this.contacts) {
            this.newCustomerForm = this.fb.group({
                id: Number(this.customerId),
                customerType: [
                    this.customerData.customerType || '',
                    Validators.required,
                ],
                primaryContact: [this.contacts ?. salutation || 'Mr'],
                firstName: [this.contacts ?. firstName || ''],
                lastName: [this.contacts ?. lastName || ''],
                companyName: [this.customerData.companyName || ''],
                displayName: [
                    this.customerData.displayName || '',
                    Validators.required
                ],
                email: [
                    this.customerData.email || '',
                    [
                        Validators.required, customEmailValidator()
                    ],
                ],
                phone: [
                    this.customerData.phone || '',
                    Validators.required
                ],
                mobile: [
                    this.customerData.mobile || '',
                    Validators.required
                ],
                emiratesId: [this.customerData.emirateId || ''],
                currency: [this.customerData.currency || 'AED'],
                openingBalance: [this.customerData.openingBalance || 0],
                paymentTerm: [this.customerData.paymentTerm || 'Net 30'],
                file: [this.fileName ? this.fileName : null]
            });
        } else {
            this.newCustomerForm = this.fb.group({
                customerType: [
                    'Bussiness', Validators.required
                ],
                primaryContact: ['Mr'],
                firstName: [
                    '',
                    [
                        Validators.required, Validators.maxLength(50)
                    ]
                ],
                lastName: [
                    '',
                    [
                        Validators.required, Validators.maxLength(50)
                    ]
                ],
                companyName: [
                    '',
                    [Validators.maxLength(50)]
                ],
                displayName: [
                    '',
                    [
                        Validators.required, Validators.maxLength(50)
                    ]
                ],
                email: [
                    '',
                    [
                        Validators.required, customEmailValidator(), Validators.maxLength(50)
                    ]
                ],
                phone: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(10), // Minimum length for phone number
                        Validators.maxLength(15), // Maximum length for phone number
                        Validators.pattern('^[0-9]+$'), // Only allows numeric values
                    ],
                ],
                mobile: [
                    '',
                    [
                        Validators.required, Validators.minLength(10), // Minimum length for phone number
                        Validators.maxLength(15), // Maximum length for phone number
                        Validators.pattern('^[0-9]+$'),
                    ],
                ],
                emiratesId: [''],
                currency: [''],
                openingBalance: [0],
                paymentTerm: [''],
                file: [null]
            });
        }

        this.newCustomerForm.valueChanges.subscribe((formValue) => {
            this.contactPersonsComponent.updateFirstContactPerson(formValue.primaryContact, formValue.firstName, formValue.lastName, formValue.email, formValue.phone, formValue.mobile);
        });
    }

    fetchPartnerById() {
        this.customerService.fetchPartnerById(Number(this.customerId)).subscribe({
            next: (data) => {
                this.customerData = data;
                if (this.customerData.documents && this.customerData.documents.length > 0) {
                    this.fileName = this.customerData.documents[0].documentName;
                }
                if (this.customerData.contacts && this.customerData.contacts.length > 0) {
                    this.customerData.contacts.map((contact : any) => {
                        if (contact ?. primaryContact) {
                            this.contacts = contact;
                        }
                    });
                }
                this.initForm();
            },
            error: (error) => {
                console.error(error, 'Error');
            }
        });
    }

    async collectOtherDetails(data : any) { // this.otherDetailsData = data;
        const newCustomerData = {
            ...this.customerData
        };
        const allData = {
            ...this.customerData
        };
        newCustomerData.otherDetails = data;
        this.customerData = newCustomerData;
        console.log('this.customerData', allData);

        if (this.customerData ?. documents ?. length > 0) {
            this.documentDetails = {
                id: Number(this.customerData ?. documents[0] ?. id),
                documentName: data ?. fileData ?. documentName,
                documentUrl: data ?. fileData ?. documentUrl
            };
        } else if (data ?. fileData ?. documentName) {
            this.documentDetails = {
                documentName: data ?. fileData ?. documentName,
                documentUrl: data ?. fileData ?. documentUrl
            };
        } else {
            this.documentDetails = {};
        }
        await this.newCustomerForm.patchValue({emiratesId: this.customerData.otherDetails.emiratesId, currency: this.customerData.otherDetails.currency, openingBalance: this.customerData.otherDetails.openingBalance, paymentTerm: this.customerData.otherDetails.paymentTerm})
    }

    collectAddress(data : any) {
        this.customerData.addressDetails = this.customerData.addressDetails || {};
        this.customerData.addressDetails[data.type] = data.value;

        this.addressDetails = [
            ...(this.customerData.addressDetails.billing ? [this.customerData.addressDetails.billing] : []),
            ...(this.customerData.addressDetails.shipping ? [this.customerData.addressDetails.shipping] : []),
        ];
    }

    async collectContactDetails(data : any) {
        console.log(data);

        data.contactPersons.map((contact : any) => {
            this.contactPersons = data ?. contactPersons;
            if (contact ?. id) {
                this.contactDetails.push(contact);
            }
        });
    }

    collectRemarkDetails(data : any) {
        this.remarkDetails = data;
    }

    async onSubmit() {
        this.validationError = false;
        if (this.newCustomerForm.invalid) {
            this.newCustomerForm.markAllAsTouched();
            this.validationError = true;
            this.toastr.warning('Please fill in all required fields.');
            return;
        }
        await this.otherDetailsComponent.onSubmit();
        this.contactPersonsComponent.onSubmit();
        this.remarksComponent.onSubmit();

        const billingValid = this.addressComponent.onSubmitBilling();
        const shippingValid = this.addressComponent.onSubmitShipping();
        if (! billingValid || ! shippingValid) {
            this.validationError = true;
            this.toastr.warning('Please fill in all required fields.');
            return;
        }

        console.log('onSubmit', this.newCustomerForm.value);

        const customerDetails = {
            partnerType: 'Customer',
            customerType: this.newCustomerForm.value.customerType,
            displayName: this.newCustomerForm.value.displayName,
            companyName: this.newCustomerForm.value.companyName,
            phone: this.newCustomerForm.value.phone,
            email: this.newCustomerForm.value.email,
            mobile: this.newCustomerForm.value.mobile,
            currency: this.newCustomerForm.value.currency,
            emirateID: this.newCustomerForm.value.emiratesId,
            openingBalance: this.newCustomerForm.value.openingBalance,
            paymentTerms: this.newCustomerForm.value.paymentTerms,
            paymentStatus: 'Pending'
        };

        const loginEmployee: any = localStorage.getItem('loggedInUser');
        const employee = JSON.parse(loginEmployee);
        const employeeId = employee ?. employeeData ?. id;
        if (this.customerId && this.customerData) {
            const customerDetails = {
                id: Number(this.customerId),
                partnerType: 'Customer',
                customerType: this.newCustomerForm.value.customerType,
                displayName: this.newCustomerForm.value.displayName,
                companyName: this.newCustomerForm.value.companyName,
                phone: this.newCustomerForm.value.phone,
                email: this.newCustomerForm.value.email,
                mobile: this.newCustomerForm.value.mobile,
                currency: this.newCustomerForm.value.currency,
                emirateID: this.newCustomerForm.value.emiratesId,
                openingBalance: this.newCustomerForm.value.openingBalance,
                paymentTerm: this.newCustomerForm.value.paymentTerm,
                paymentStatus: "Pending"
            };
            if (!this.remarkDetails ?. id) {
                this.remarkDetails = {};
            }
            console.log('this.addressDetails', this.addressDetails);
            this.customerService.updatePartner(customerDetails, this.contactDetails, this.addressDetails, this.remarkDetails, employeeId, this.documentDetails).subscribe({
                next: async (data) => {
                    if (data) {
                        this.toastr.success('Customer updated successfully', 'Success');
                        await this.addNewContactPersons();
                        this.onCancel();
                        
                    this.dialogRef.close('success');
                        // this.router.navigate([`/customers/customer-details/${
                        //         this.customerId
                        //     }`]);
                    }
                },
                error: (error) => this.toastr.error(error, 'Error')
            });

        } else {
            console.log('this.addressDetails', this.addressDetails);
            const newContacts = this.contactPersons.filter((contact : any) => !contact.id);
            this.customerService.createPartner(customerDetails, newContacts, this.addressDetails, this.remarkDetails, this.documentDetails, employeeId).subscribe({
                next: async (data) => {
                    this.toastr.success('Customer created successfully', 'Success');
                    this.onCancel();
                    this.dialogRef.close('success');
                },
                error: (error) => this.toastr.error(error, 'Error')
            });
        }
    }

    async addNewContactPersons() {
        const newContacts = this.contactPersons.filter((contact : any) => !contact.id);
        console.log('newContacts', newContacts);

        if (newContacts && newContacts.length > 0) {
            const validNewContacts = newContacts.filter((contact : any) => contact.firstName && contact.lastName && contact.email);

            validNewContacts.map(async (data : any) => {
                const contactPerson = {
                    ...data,
                    partnerId: Number(this.customerId)
                };
                await this.customerService.createContact(contactPerson).toPromise().then((data) => {
                    if (data) { // this.toastr.success('Contact added successfully!', 'Success');
                    }
                }).catch(error => {
                    this.toastr.error(error, 'Error');
                });
                this.fetchPartnerById();
            });
        } else {
            this.contactDetails.map((data : any) => {
                if (data ?. id) {
                    this.customerService.updateContact(data).toPromise().then((data) => {
                        if (data) { // this.toastr.success('Contact updated successfully!', 'Success');
                        }
                    }).catch(error => {
                        this.toastr.error(error, 'Error');
                    });
                    this.fetchPartnerById();
                }
            });
        }
    }


    onCancel() {
        this.newCustomerForm.reset();
        this.otherDetailsComponent.onCancel();
        this.addressComponent.onCancel();
        this.addressComponent.onCancel();
        this.contactPersonsComponent.onCancel();
        this.remarksComponent.onCancel();
        this.dialogRef.close();
    }
}
