
    <section class="cmn-innerpage-wrapper invoice-new">
        <h2 class="cmn-inner-heading">New Purchase Order</h2>
        <form [formGroup]="purchaseOrderForm" >
        <div class="invoice-new-form-wrapper">
            <div class="form-wrapper invoice-form-wrapper">

                <!-- Client Name Section -->
                <div class="cmn-Search-select-container">
                    <label for="clientName">Vendor Name</label>
                    <mat-form-field>
                        <mat-select placeholder="Search vendor" id="clientName" formControlName="vendorId" >
                            <!-- <mat-option>
                                <ngx-mat-select-search></ngx-mat-select-search>
                            </mat-option> -->
                            <mat-option>
                                <ngx-mat-select-search
                                  [placeholderLabel]="'Search'"
                                  [noEntriesFoundLabel]="'No options found'"
                                  [formControl]="vendorControl"
                                ></ngx-mat-select-search>
                              </mat-option>
                            <mat-option *ngFor="let vendor of vendorsData" [value]="vendor.id" > {{vendor.name}}</mat-option>
                        </mat-select>
                        <mat-error
              *ngIf="
              purchaseOrderForm.get('vendorId')!.touched &&
              purchaseOrderForm.get('vendorId')!.hasError('required')
              "
            >
              *Vendor is required.
            </mat-error>
                    </mat-form-field>
                    <button>
                        <div><img src="../../../assets/images/icons/icon-add-color.svg" alt="Add client"></div>
                        <span>Add vendor</span>
                    </button>
                </div>

                <!-- Invoice Number Section -->
                <div>
                    <label for="purchaseOrderNo"> Purchase Order</label>
                    <mat-form-field>
                        <input matInput placeholder="IN-000212" id="purchaseOrderNo" formControlName="purchaseOrderNo" />
                    </mat-form-field>
                    
                    <mat-error
                    *ngIf="
                    purchaseOrderForm.get('purchaseOrderNo')!.touched &&
                    purchaseOrderForm.get('purchaseOrderNo')!.hasError('required')
                    "
                  >
                    *Purchase order number is required.
                  </mat-error>
                </div>
                <!-- Project Name Section -->
                <!-- <div class="cmn-Search-select-container">
                    <label for="projectName">Project Name</label>
                    <mat-form-field>
                        <mat-select placeholder="Select project" id="projectName">
                            <mat-option>
                                <ngx-mat-select-search></ngx-mat-select-search>
                            </mat-option>
                            <mat-option value="project1">Project 1</mat-option>
                            <mat-option value="project2">Project 2</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->


                <!-- Order Number Section -->
                <!-- <div>
                    <label for="orderNumber">Work Order Number</label>
                    <mat-form-field>
                        <input matInput placeholder="0923423" id="orderNumber" />
                    </mat-form-field>
                </div> -->
                <div>
                    <label for="reference">Reference</label>
                    <mat-form-field>
                        <input matInput placeholder="0923423" id="reference" formControlName="reference"/>
                    </mat-form-field>
                </div>



                <!-- Terms Section -->
                <div>
                    <label for="paymentTerms">Payment Terms</label>
                    <mat-form-field>
                        <mat-select placeholder="Due on receipt" id="paymentTerms" formControlName="paymentTerms">
                            <mat-option  *ngFor="let term of paymentTermsValue" value="term" >{{term}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error
                    *ngIf="
                    purchaseOrderForm.get('paymentTerms')!.touched &&
                    purchaseOrderForm.get('paymentTerms')!.hasError('required')
                    "
                  >
                  *Payment terms is required.
                  </mat-error>
                </div>


                <!-- Milestone Section -->
                <!-- <div>
                    <label for="milestone">Milestone</label>
                    <mat-form-field>
                        <mat-select placeholder="Choose milestone" id="milestone">
                            <mat-option value="milestone1">Milestone 1</mat-option>
                            <mat-option value="milestone2">Milestone 2</mat-option>
                            <mat-option value="milestone3">Milestone 3</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->

                <div>
                    <label for="workorderId">Work order number</label>
                    <mat-form-field>
                        <mat-select placeholder="Select work order number" id="workorderId" formControlName="workorderId">
                            <mat-option  *ngFor="let workorder of workOrders"  [value]="workorder.id" >{{ workorder?.workOrderNo }}</mat-option>
                        </mat-select>
                    </mat-form-field>          
                    <mat-error
                    *ngIf="
                    purchaseOrderForm.get('workorderId')!.touched &&
                    purchaseOrderForm.get('workorderId')!.hasError('required')
                    "
                  >
                  *Work order number is required.
                  </mat-error>
                </div>

                <div>
                    <label for="currency">Currency</label>
                    <mat-form-field>
                        <mat-select placeholder="Select currency" id="currency" formControlName="currency">
                            <mat-option  *ngFor="let currency of allCurrencies"  [value]="currency.id" >{{ currency?.currencyCode }}</mat-option>
                        </mat-select>
                    </mat-form-field>          
                    <mat-error
                    *ngIf="
                    purchaseOrderForm.get('currency')!.touched &&
                    purchaseOrderForm.get('currency')!.hasError('required')
                    "
                  >
                  Currency is required.
                  </mat-error>
                </div>
                <!-- <div>
                    <label for="milestone">Currency</label>
                    <mat-form-field>
                        <input matInput value="AED" id="currency" placeholder="Currency" readonly formControlName="currency" />
                    </mat-form-field>
                    <mat-error
                    *ngIf="
                    purchaseOrderForm.get('currency')!.touched &&
                    purchaseOrderForm.get('currency')!.hasError('required')
                    "
                  >
                  Currency is required.
                  </mat-error>
                </div> -->
                    
                <!-- Due Date Section -->
                <div>
                    <label for="date">Bill Date</label>
                    <mat-form-field>
                      <input 
                      matInput  
                      [value]="currentDate"  
                      [matDatepicker]="currentDatePicker"  
                      id="date"  
                      formControlName="billDate"
                      placeholder="Select date"   />
                       <mat-datepicker-toggle matIconSuffix [for]="currentDatePicker"></mat-datepicker-toggle>
                      <mat-datepicker #currentDatePicker></mat-datepicker>
                    </mat-form-field>
                    <mat-error
                    *ngIf="
                    purchaseOrderForm.get('billDate')!.touched &&
                    purchaseOrderForm.get('billDate')!.hasError('required')
                    "
                  >
                  *Date is required.
                  </mat-error>
                  
                  </div>

            
            <div>
                <label for="dueDate">Expected Delivery Date</label>
                <mat-form-field>
                    <input matInput [matDatepicker]="dueDatePicker" placeholder="Select date" id="dueDate"  formControlName="dueDate" />
                    <mat-datepicker-toggle matIconSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #dueDatePicker></mat-datepicker>
                </mat-form-field> 
                <mat-error
                *ngIf="
                purchaseOrderForm.get('dueDate')!.touched &&
                purchaseOrderForm.get('dueDate')!.hasError('required')
                "
              >
              *Expected delivery date is required.
              </mat-error>
              <mat-error
              *ngIf="
              purchaseOrderForm.get('dueDate')?.hasError('dueDateBeforeBillDate')
              "
            >
            *Expected delivery date cannot be earlier than the Bill Date.
            </mat-error>
            </div>

                  <div>
                    <label for="shipmentPreference">Shipment Preference</label>
                    <mat-form-field>
                      <input
                        matInput
                        placeholder="Shipment preference"
                        id="shipmentPreference"
                        formControlName="shipmentPreference"
                      />
                    </mat-form-field>
                  </div>
            </div>

            <div class="service-address-wrapper">
                <div class="type-selection">
                    <label class="radiobtn-wrapper">
                        <input type="radio" name="address" (click)="toggleOrgainization()" [checked]="!isCustomerSelected" />
                        <span class="radiomark"></span>
                        Organization
                    </label>
                    <label class="radiobtn-wrapper">
                        <input type="radio" name="address" (click)="toggleCustomer()" [checked]="isCustomerSelected"  />
                        <span class="radiomark"></span>
                        Customer
                    </label>
                </div>

                <!-- Customer List -->
                <div class="form-wrapper invoice-form-wrapper" *ngIf="isCustomerSelected === true">
                    <div class="cmn-Search-select-container">
                        <mat-form-field>
                            <mat-select placeholder="Search Client" id="clientName" formControlName="customerId" (selectionChange)="onCustomerChange($event)">
                                <mat-option>
                                    <ngx-mat-select-search></ngx-mat-select-search>
                                </mat-option>
                                <mat-option  *ngFor="let customer of cutomersData" [value]="customer.id">
                                    {{ customer.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="billing-shipping-address-wrapper" >
                    <div >
                        <div class="header-wrapper">
                            <h5>Billing Address</h5>
                            <div (click)="openSelectBillingAddressDialog()">
                                <img src="/assets/images/icons/icon-edit-accounts.svg" alt="" />
                            </div>
                        </div>
                        <div>
                            <h6>{{ selectedBillingAddress?.attention }}</h6>
                            <ul>
                                <li>{{ selectedBillingAddress?.addressLine1 }}</li>
                                <li>{{ selectedBillingAddress?.addressLine2 }}</li>
                                <li>{{ selectedBillingAddress?.city }}</li>
                                <li>{{ selectedBillingAddress?.state}}</li>
                                <li>{{ selectedBillingAddress?.country }}</li>
                                <li>{{ selectedBillingAddress?.phone }}</li>
                                <li>{{ selectedBillingAddress?.fax  }}</li>
                            </ul>
                            <input type="hidden" formControlName="billingAddressId" [value]="selectedBillingAddress?.id"  />            
                            <button (click)="openBillingAddressDialog('add')">
                                <span>Add Address</span>
                              </button>
                            
                        </div>
                    </div>
                    <div>
                        <div class="header-wrapper">
                            <h5>Shipping  Address</h5>
                            <div (click) ="openSelectShippingAddressDialog()">
                                <img src="/assets/images/icons/icon-edit-accounts.svg" alt="" />
                            </div>
                        </div>
                        <div>
                            <h6>{{ selectedShippingAddress?.attention }}</h6>
                            <ul>
                                <li>{{ selectedShippingAddress?.addressLine1 }}</li>
                                <li>{{ selectedShippingAddress?.addressLine2 }}</li>
                                <li>{{ selectedShippingAddress?.city }}</li>
                                <li>{{ selectedShippingAddress?.state  }}</li>
                                <li>{{ selectedShippingAddress?.country  }}</li>
                                <li>{{ selectedShippingAddress?.phone }}</li>
                                <li>{{ selectedShippingAddress?.fax }}</li>
                            </ul>
                            <input type="hidden"  formControlName="shippingAddressId" [value]="selectedShippingAddress?.id" />        
                            <button (click)="openShippingAddressDialog('add')">
                                <span>Add Address</span>
                              </button>
                        </div>
                    </div>
                </div>

            </div>
            <!-- <div class="service-address-wrapper">
                <h5>Service Address</h5>
                <div>
                    <div class="form-label-group" >
                        <label for="">
                            <input type="radio" id="radio" name="radio" (click)="setAdminAddress()" >
                            <span>Organization</span>
                        </label>
                        <div *ngFor="let address of AdminAddressData">
                            <div>
                                <h6>{{ address?.attention }} </h6>
                                <div>
                                    <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="">
                                </div>
                            </div>
    
                            <p>{{ address?.addressLine1 }} {{ address?.addressLine2 }}</p>
                            <p>{{ address?.state }} {{ address?.pinCode }}</p>
                        </div>

                    </div>

                    <div>
                        <label for="">
                            <input type="radio" id="radio1" name="radio" (click)="selectCustomer()">
                            <span>Customer</span>
                        </label>
                    </div>

                    <div class="form-wrapper invoice-form-wrapper">
                    <div class="cmn-Search-select-container" *ngIf="isCustomerSelected">
                        <label for="clientName">Customer Name</label>
                        <mat-form-field>
                            <mat-select placeholder="Search Client" id="clientName">
                                <mat-option>
                                    <ngx-mat-select-search></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let customer of cutomersData" [value]="customer.id">
                                    {{ customer.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                </div>
            </div> -->

        </div>




        <div class="item-service-adding-table-wrapper">
            <table>
                <thead>
                    <tr>
                        <th>
                            Item/Service
                        </th>
                        <th>
                            Account </th>
                        <th>
                            Quantity </th>
                        <th>
                            Rate </th>
                        <th>
                            Discount </th>
                            <th>
                                Sub Total </th>
                        <th>
                            Amount </th>
                        <th>
                        </th>
                    </tr>
                </thead>

                <tbody formArrayName="items">
                    <tr *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
                        <td>
                            <div class="form-wrapper">
                                <div>
                                    <mat-form-field>
                                      <input
                                        matInput
                                        placeholder="Select or Type Item"
                                        [matAutocomplete]="auto"
                                        formControlName="itemName"
                                        (input)="onItemSearch($event)"
                                      />
                                      <mat-autocomplete
                                        #auto="matAutocomplete"
                                        (optionSelected)="onSelectItem($event)"
                                      >
                                        <mat-option
                                          *ngFor="let item of filteredItemData"
                                          [value]="item.id"
                                        >
                                          {{ item.itemName }}
                                        </mat-option>
                                      </mat-autocomplete>
                                      <mat-error
                                        *ngIf="item.get('itemName')?.errors?.['required']"
                                      >
                                        *Item is required.
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                            </div>
                        </td>

                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <mat-select placeholder="Enter Account" formControlName="account">
                                        <mat-option *ngFor="let acc of accountData" [value]="acc.id">
                                            {{ acc.accountName }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="item.get('account')?.hasError('required')">
                                        *Account is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </td>

                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input
                                      matInput
                                      placeholder="quantity"
                                      formControlName="quantity"
                                    />
                                    <mat-error
                                      *ngIf="
                                        item.get('quantity')?.touched &&
                                        item.get('quantity')?.hasError('required')
                                      "
                                    >
                                      *Quantity is required. </mat-error
                                    ><mat-error
                                      *ngIf="item.get('quantity')?.hasError('nonNegative')"
                                    >
                                      *Quantity cannot be negative.
                                    </mat-error>
                                    <mat-error *ngIf="item.get('quantity')?.hasError('pattern')">
                                        *Quantity must be a valid number.
                                      </mat-error>
                                  </mat-form-field>
                            </div>
                        </td>

                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="rate" formControlName="rate" />
                                    <mat-error
                                      *ngIf="
                                        item.get('rate')?.touched &&
                                        item.get('rate')?.hasError('required')
                                      "
                                    >
                                      *Rate is required. </mat-error
                                    ><mat-error *ngIf="item.get('rate')?.hasError('nonNegative')">
                                      *Rate cannot be negative.
                                    </mat-error>
                                    <mat-error *ngIf="item.get('rate')?.hasError('pattern')">
                                      *Rate must be a valid number .
                                    </mat-error>
                                    
                                  </mat-form-field>
                            </div>
                        </td>

                        <td>
                            <div class="form-wrapper">
                                <div class="split-parent">
                                    <div class="left-set">
                                        <mat-form-field>
                                            <input
                                              matInput
                                              placeholder="0.00"
                                              formControlName="discount"
                                              type="number"
                                            />
                                            <mat-error
                                              *ngIf="item.get('discount')?.hasError('nonNegative')"
                                              >*Discount cannot be negative. </mat-error
                                            ><mat-error
                                              *ngIf="
                                                item.get('discount')?.hasError('max') &&
                                                item.get('discount')?.touched
                                              "
                                              >*Discount cannot be greater than 100%.</mat-error
                                            >
                                            <mat-error
                                            *ngIf="item.get('discount')?.hasError('pattern') && item.get('discount')?.touched"
                                            >*Please enter a valid discount amount.</mat-error
                                          >
                                          
                                          </mat-form-field>
                                    </div>
                                    <div class="right-set">
                                        <mat-form-field>
                                            <mat-select placeholder="%">
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="0" formControlName="subTotal"
                                        type="number" />
                                    <mat-error *ngIf="item.get('subTotal')?.hasError('nonNegative')">*sub total
                                        cannot be negative. </mat-error>
                                      <mat-error *ngIf="
                                        item.get('subTotal')?.hasError('pattern') &&
                                        item.get('subTotal')?.touched
                                      ">*Please enter a valid sub total.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">

                                <mat-form-field>
                                    <input
                                      matInput
                                      placeholder="100"
                                      formControlName="total"
                                      type="number"
                                    />
                                    <mat-error
                                      *ngIf="
                                        item.get('total')?.touched &&
                                        item.get('total')?.hasError('required')
                                      "
                                    >
                                      *Total is required.
                                    </mat-error>
                                  </mat-form-field>
                            </div>
                        </td>

                        <td>
                            <img src="../../../assets/images/icons/icon-close.svg" alt="Remove"
                            (click)="removeItem(i)" />
                        </td>
                    </tr>
                </tbody>

            </table>
            <button type="button" (click)="addItem()">
                <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add" />
                Add
            </button>
        </div>

        <!-- <div class="quotation-table-container">
            <div>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Item/Service</th>
                            <th>Description</th>
                            <th>Unit</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Discount</th>
                            <th>Amount</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr *ngFor="let item of arr">
                            <td>{{item}}</td>
                            <td>Floor Cleaning</td>
                            <td>Cleaning</td>
                            <td>pcs</td>
                            <td>1</td>
                            <td><sub>aed</sub>150</td>
                            <td>5%</td>
                            <td><sub>aed</sub>150</td>
                            <td><img src="../../../assets/images/icons/icon-edit-accounts.svg" alt=""></td>
                        </tr>


                    </tbody>
                </table>
            </div>

        </div> -->

        <div class="notes-tatal-amount-container">
            <div class="form-container">
                <div class="form-wrapper">
                    <label for="customerNotes">Customer Notes</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter your notes here..." formControlName="customerNotes"></textarea>
                    </mat-form-field>
                </div>
                <!-- <div class="form-wrapper">
                    <label for="termsConditions">Payment Term</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter the terms & conditions"> The customer pays the invoice within 30 days of the invoice date</textarea>
                    </mat-form-field>
                </div> -->
                <!-- <div class="form-wrapper">
                    <label for="termsConditions">Terms & Conditions</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter the terms & conditions"></textarea>
                    </mat-form-field>
                </div>  -->

            </div>
            <div class="amount-table-div">
                <div>
                    <h6>Sub total<span>: AED {{ getSubTotal().toFixed(2) }}</span></h6>
                    <h6>Discount <span>: AED  {{ getDiscount().toFixed(2) }}</span></h6>
                    <h6>VAT amount <span>: {{ getVAT().toFixed(2) }}</span></h6>
                    
                </div>
                <div>
                    <h4>Total <span>: AED {{ getGrandTotal().toFixed(2) }}</span></h4>
                </div>
            </div>
        </div>



        <!-- <div class="payment-mode-wrapper"> -->
            <!-- Email Connection Section -->
            <!-- <div class="add-email-wrapper">
                <h6>Email Connection</h6>
                <div class="flex-wrapper-container">
                    <div class="email-account-div">
                        <div class="profile-image">
                            <img src="../../../assets/images/profile.png" alt="Profile Image">
                        </div>
                        <span>rikson&#64;gmail.com</span>
                        <div class="close-btn">
                            <img src="../../../assets/images/icons/icon-close.svg" alt="Remove Email">
                        </div>
                    </div>
                    <button>
                        <div>
                            <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add Email">
                        </div>
                        <span>Add email</span>
                    </button>
                </div>
            </div>

        </div> -->


        <div class="submit-btn-wrapper">
            <button class="cmn-cancel-btn" type="submit" (click)="onCancel()">Cancel</button>
            <button class="cmn-draft-btn"  (click)="createPurchaseOrder('purchase-order/purchase-order-details')">Save</button>
            <button class="cmn-next-btn" type="submit">Save and Send</button>
        </div>


    </form>
    </section>
