import { gql } from "apollo-angular";

export const UPDATE_PAYROLL = gql`
  mutation updatePayroll( $updatePayrollInput: UpdatePayrollInput!) {
    updatePayroll(updatePayrollInput: $updatePayrollInput) 
    {
    id
    month
    year
    grossPay
    netPay
    deductions
    workDays
    totalEmployees
    additions
    separations
    lockPayroll
    lockInputs
    isProcessed
    employeePayrolls {
      id
      payrollId
      employeeId
      employees{
        firstName
        lastName
      }
      payPeriodStart
      payPeriodEnd
      basicSalary
      overtimePay
      bonuses
      deductions
      netPay
      paymentDate
      paymentMethod
      payslipURL
    }
  }
}
`;

export const EMPLOYEE_PROCESS_PAYROLL = gql`
  mutation processEmployeePayroll( $id: Int!, $employeeId: Int!, $payrollId: Int!) {
    processEmployeePayroll(id: $id, employeeId: $employeeId, payrollId: $payrollId) 
    {
    id
    payrollId
    employeeId
    payPeriodStart
    payPeriodEnd
  }
}
`;

export const PROCESS_PAYROLL = gql`
  mutation processPayroll($year: Int!, $month: PayrollMonths!) {
    processPayroll(year: $year, month: $month)
}
`;

export const INITIATE_PAYROLL = gql`
  mutation initiatePayroll($year: String!, $month: PayrollMonths!) {
    initiatePayroll(year: $year, month: $month) {
    id
    month
    year
    grossPay
    netPay
    deductions
    workDays
    totalEmployees
    additions
    separations
    lockPayroll
    lockInputs
    isProcessed
    payrollStatus
    employeePayrolls {
      id
      payrollId
      employeeId
      employees{
        id
        firstName
        lastName
      }
      payPeriodStart
      payPeriodEnd
      basicSalary
      overtimePay
      bonuses
      deductions
      netPay
      paymentDate
      paymentMethod
      payslipURL
      employeePayrollStatus
    }
  }
}
`;

export const CREATE_PAYROLL_DEDUCTION = gql`
  mutation createPayrollDeduction($createPayrollDeductionInput: CreatePayrollDeductionInput!) {
    createPayrollDeduction(createPayrollDeductionInput: $createPayrollDeductionInput)
    {
    id
    description
    amount
    employeePayrollId
    employeePayroll {
      id
      payrollId
      employeeId
      payPeriodStart
      payPeriodEnd
      basicSalary
      overtimePay
      bonuses
      deductions
      netPay
      paymentDate
      paymentMethod
      payslipURL
      employeePayrollStatus
    }
  }
}
`;

export const CREATE_PAYROLL_BONUS = gql`
  mutation createPayrollBonus($createPayrollBonusInput: CreatePayrollBonusInput!) {
    createPayrollBonus(createPayrollBonusInput: $createPayrollBonusInput)
    {
    id
    description
    amount
    employeePayrollId
    employeePayroll {
      id
      payrollId
      employeeId
      payPeriodStart
      payPeriodEnd
      basicSalary
      overtimePay
      bonuses
      deductions
      netPay
      paymentDate
      paymentMethod
      payslipURL
      employeePayrollStatus
    }
  }
}
`;