import { Component, Inject, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { VacationService } from '../../../../../../features/vacation/services/vacation.service';
import { ImageService } from '../../../../../../shared/services/image.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-vacation-history',
    standalone: true,
    imports: [
      CommonModule,
      AgGridAngular,
    ],
    templateUrl: './vacation-history.component.html',
    styleUrl: '../../../../employee-details.component.scss'
  })
  export class VacationHistoryComponent {
    public rowData: any[] | null = null;
    filter: any;
  employeeId: number;

    constructor(
      private vacationService: VacationService,
      private imageService: ImageService,
      private toastr: ToastrService,
      private datePipe: DatePipe,
      private route: ActivatedRoute,
    ){}

    ngOnInit() {
      const id = this.route.snapshot.paramMap.get('id');
      if (id) {
        this.employeeId = Number(id);
        this.fetchVacationsById(this.employeeId); 
      }
    }
    
      columnDefs = [
        { headerName: 'Start Date', field: 'startDate', flex: 1 },
        { headerName: 'End Date', field: 'endDate', flex: 1 },
        { headerName: 'Total Days', field: 'totalDays', flex: 1 },
        { headerName: 'Status', field: 'status', flex: 1,
        cellRenderer: (params: any) => {
          const status = params.data?.status;
          const statusClass =
            status === "Waiting for approval" ? 'yellow' : status === "Approved" ? 'green' : status === "Rejected" ? 'red' : '';
          return `<span class="${statusClass}">${status}</span>`;
        }
         },
      ];

      public defaultColDef: ColDef = {
        filter: 'agTextColumnFilter',
        floatingFilter: true,
      };
      public rowSelection: 'single' | 'multiple' = 'multiple';
      public paginationPageSize = 10;
      public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
      public themeClass: string = 'ag-theme-quartz';


      fetchVacationsById(id: any) {
        this.filter = { search: "" }
        this.vacationService.employeeVacationRequests(id, this.filter).subscribe({
          next: async (data) => {
            this.rowData = await Promise.all(
              data
              .map( async (data: any) => {
                const approvers = data?.employee?.employeeApprovers?.[0] || null;
                let profilePicture;
                  if (data?.employee?.profilePicture) {
                    try {
                      profilePicture = await this.imageService.getImageAsBase64(
                        data?.employee.profilePicture
                      );
                    } catch (error) {
                      profilePicture = 'assets/images/dummy-profile-image.jpg';
                    }
                  } else {
                    profilePicture = 'assets/images/dummy-profile-image.jpg';
                  }
                return {
                  id: data?.id || '--',
                  employeeId: data?.employeeId || '--',
                  totalDays: data?.totalDays + ' days' || '--',
                  startDate: this.datePipe.transform((data?.startDate), 'MM/dd/YYYY')  || '--',
                  endDate: this.datePipe.transform((data?.endDate), 'MM/dd/YYYY')  || '--',
                  status: data?.status === 'Pending' ? 'Waiting for approval' : (data?.status === 'Rejected' ? data?.status :  data?.vacationStatus)  || '--',
                };
              })
            )
          },
          error: (error: any) => {
            this.toastr.error(error?.message);
          },
        });
      }

  }






@Component({
  selector: 'edit-vacation-dialog',
  templateUrl: './edit-vacation-dialog.html',
  styleUrls: ['../../../../employee-details.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatSelectModule
  ],
})
export class EditVacationDialog {
  vacationEditForm: FormGroup;
  startDate: any;
  endDate: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private vacationService: VacationService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private dialog: MatDialogRef<EditVacationDialog>,
  ){
    this.startDate = this.datePipe.transform((this.data.vacationData?.startDate),'MM/dd/YYYY');
    this.endDate = this.datePipe.transform((this.data.vacationData?.endDate),'MM/dd/YYYY');

    console.log('data', this.data);
    
  }

  ngOnInit() {
    this.initForm();
    this.vacationEditForm.get('startDate')?.valueChanges.subscribe((startDate) => {
      const duration = this.vacationEditForm.get('duration')?.value;
    
      if (duration === '7' || duration === '14' || duration === '21') {
        this.updateEndDateBasedOnDuration(startDate, parseInt(duration, 10));
      }
    });
    
    this.vacationEditForm.get('duration')?.valueChanges.subscribe((duration) => {
      const startDate = this.vacationEditForm.get('startDate')?.value;
    
      if (duration === '7' || duration === '14' || duration === '21') {
        this.updateEndDateBasedOnDuration(startDate, parseInt(duration, 10));
      } else if (duration === 'Custom') {
        this.vacationEditForm.get('endDate')?.enable();
      }
    });
    
    this.vacationEditForm.get('endDate')?.valueChanges.subscribe((endDate) => {
      const duration = this.vacationEditForm.get('duration')?.value;
      const startDate = this.vacationEditForm.get('startDate')?.value;
    
      if (!startDate || (duration !== '7' && duration !== '14' && duration !== '21')) {
        return;
      }
    
      const calculatedEndDate = this.calculateEndDate(startDate, parseInt(duration, 10));
    
      if (new Date(endDate) > calculatedEndDate) {
        this.toastr.error(`The end date exceeds the ${duration}-day duration.`);
        this.vacationEditForm.patchValue({ endDate: calculatedEndDate });
      }
    });
  }


  initForm() {
    if (this.data) {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
  
      const totalDays = Math.ceil(
        (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1
      );
  
      const duration = ['7', '14', '21'].includes(totalDays.toString()) ? totalDays.toString() : 'Custom';
  
      this.vacationEditForm = this.fb.group({
        id: [this.data?.vacationId || ''],
        employeeId: [this.data?.employeeId || ''],
        startDate: [new Date(this.startDate), Validators.required],
        endDate: [{value: new Date(this.endDate), disabled: true}, Validators.required],
        duration: [duration ,Validators.required],
      });
    }
  }

  updateEndDateBasedOnDuration(startDate: string, duration: number): void {
    const calculatedEndDate = this.calculateEndDate(startDate, duration);
    this.vacationEditForm.patchValue({ endDate: calculatedEndDate });
    console.log('this.vacationApplyForm', this.vacationEditForm.value);
    this.vacationEditForm.get('endDate')?.disable(); // Disable manual editing for fixed durations

    
  }
  
  calculateEndDate(startDate: string, duration: number): Date {
    const start = new Date(startDate);
    const endDate = new Date(start);
    endDate.setDate(start.getDate() + duration - 1);
    return endDate;
  }

  onEdit(){
    if (this.vacationEditForm.invalid) {
      this.vacationEditForm.markAllAsTouched();
      return;
    } else {
      this.vacationEditForm.patchValue({
        startDate: this.datePipe.transform((this.vacationEditForm.value.startDate),'YYYY-MM-dd'),
        endDate: this.datePipe.transform((this.vacationEditForm.getRawValue().endDate),'YYYY-MM-dd')
      })
    // const vacationData = this.vacationEditForm.value;
    const vacationData = {
      id: this.vacationEditForm.value.id,
      employeeId: this.vacationEditForm.value.employeeId,
      startDate: this.vacationEditForm.value.startDate,
      endDate: this.vacationEditForm.getRawValue().endDate,
    }
    if (vacationData) {
      this.vacationService.updateVacationRequest(vacationData).subscribe(
        (response) => {
          if (response) {
            this.dialog.close(response);
            this.vacationEditForm.reset();
          } else if (response?.errors) {
            this.toastr.error(response?.errors[0]?.message, 'Error');
          }
        },
        (error) => {
          this.dialog.close();
          this.toastr.error(error?.message, 'Error');
        }
      );
    }
  }
  }
}