import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { CustomerService } from '../../../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Permission } from '../../../../shared/services/permissions';
import { PermissionDirective } from '../../../../shared/directives/permissions.directive';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../../../../shared/components/confirm-dialog/confirm-dialog';

@Component({
  selector: 'app-transactions',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    RouterModule,
    PermissionDirective
  ],
  templateUrl: './transactions.component.html',
  styleUrl: '../../client-details.component.scss'
})
export class transactionsComponent {
  partnerDetails: any;
  customerId: any;
  Permission = Permission;
  readonly dialog = inject(MatDialog);
  currentBranchId:any
  

  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ){
    this.currentBranchId = localStorage.getItem('selectedBranchId');
  }

  ngOnInit(){

    const redirectData = sessionStorage.getItem('redirectAfterReload');
    if (redirectData) {
        const { route, queryParams } = JSON.parse(redirectData);
        sessionStorage.removeItem('redirectAfterReload');
        this.router.navigate([route], { queryParams });
    }

    this.route.paramMap.subscribe(params => {
      this.customerId = params.get('id');
      if (this.customerId) {
        this.partnerDetailById(Number(this.customerId));
        }
      });
  }

  
  partnerDetailById(id: any) {
    this.customerService.partnerDetails(id, '', '').subscribe({
      next: (data: any) => {
        this.partnerDetails = data;
      },
      error: (error) => {
        this.toastr.error(error?.message);
      },
    });
  }

  // navigateToPaymentNew() {
  //   this.router.navigate(['/invoice/invoice-add'], {
  //     state: { customerId:  this.customerId },
  //   });
  // }

   /** Generic routing function */
   navigateTo(route: string) {
    this.router.navigate([route], { state: { customerId: this.customerId } });
  }

  navigateToInvoiceDetails(id: string) {
        if (!id) return;
    
    this.router.navigate(['/invoice/invoice-details'], {
      queryParams: { invoiceId: id }
    });
  }

  navigateToPaymentDetails(paymentId: string, branchId: number) {
    if (!paymentId) return;
    // Get current route
    const currentRoute = this.router.url;

    // Check if branch needs to be switched
    if (this.currentBranchId !== String(branchId)) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                title: 'Confirm?',
                message: 'Selected payment does not belong to the current branch. Do you want to switch?',
                confirmText: 'Switch Branch'
            }
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
                // Update selected branch ID in local storage
                localStorage.setItem('selectedBranchId', String(branchId));

              // Store target route in sessionStorage before reloading
              sessionStorage.setItem('redirectAfterReload', JSON.stringify({
                route: '/payment-received/payment-received-details',
                queryParams: { id: paymentId }
            }));

                // Hard reload while staying on the same route
                window.location.href = currentRoute;
            }
        });
    } else {
        // If branch is the same, just navigate
        this.router.navigate(['/payment-received/payment-received-details'], {
            queryParams: { id: paymentId }
        });
    }
}
   
}
