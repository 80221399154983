<section class="cmn-innerpage-wrapper project-new">
  <form [formGroup]="projectForm" (ngSubmit)="onSubmit()">
    <h2 class="cmn-inner-heading">
      {{ projectId ? "Project Edit" : "Project New" }}
    </h2>

    <div class="invoice-new-form-wrapper">
      <div class="invoice-form-wrapper">
        <div class="form-wrapper">
          <label for="">Project Name <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter name" formControlName="projName" />
            <mat-error *ngIf="projectForm.get('projName')?.hasError('required')">
              Project name is required
            </mat-error>
            <mat-error *ngIf="
                projectForm.get('projName')?.hasError('noHtmlSpecialChars')
              ">
              HTML special characters like &lt;, &gt;, &, ", and ' are not
              allowed
            </mat-error>
            <mat-error *ngIf="projectForm.get('projName')?.hasError('spaceError')">
              Cannot start or end with a space.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Project Code <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="000102" formControlName="projCode" readonly />
            <mat-error *ngIf="projectForm.get('projCode')?.hasError('required')">
              Project code is required
            </mat-error>
            <mat-error *ngIf="projectForm.get('projCode')?.hasError('pattern')">
              Project code must contain only alphanumeric characters
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-wrapper cmn-Search-select-container">
          <label for="">Client Name <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Search Client" #singleSelect formControlName="customerId"
              (selectionChange)="onCustomerSelected($event)">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  (input)="onSearch($event)"
                ></ngx-mat-select-search>
              </mat-option>

              <mat-option *ngFor="let partner of partnersData" [value]="partner.id">
                {{ partner.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="projectForm.get('customerId')?.hasError('required')">
              Client name is required
            </mat-error>
          </mat-form-field>

          <button type="button" (click)="openCreateClientDialog()">
            <div>
              <img src="assets/images/icons/icon-add-color.svg" alt="" />
            </div>
            <span>Add client</span>
          </button>
        </div>
        <div class="form-wrapper">
          <label for="">Billing Method <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Fixed cost for project" formControlName="billingMethod">
              <mat-option value="FixedCostForProject">Fixed Cost For Project
              </mat-option>
              <mat-option value="HourlyRate">Hourly Rate</mat-option>
            </mat-select>
            <mat-error *ngIf="projectForm.get('billingMethod')?.hasError('required')">
              Billing method is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Description</label>
          <mat-form-field>
            <textarea matInput placeholder="Type something.." formControlName="description"></textarea>
            <mat-error *ngIf="projectForm.get('description')?.hasError('required')">
              Description is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Total Project Cost <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter Amount" formControlName="projectCost" type="number" />
            <mat-error *ngIf="projectForm.get('projectCost')?.hasError('required')">
              Total project Cost is required
            </mat-error>
            <mat-error *ngIf="projectForm.get('projectCost')?.hasError('pattern')">
              Please enter a valid cost
            </mat-error>
            <mat-error
              *ngIf="projectForm.get('projectCost')?.hasError('nonNegative')"
            >
              Price cost cannot be negative
            </mat-error>
            <mat-error
              *ngIf="projectForm.get('projectCost')?.hasError('maxLimit')"
            >
              Price cost cannot exceed the maximum allowed value
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Cost center <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Choose branch" formControlName="costCenter">
              <mat-option *ngFor="let branch of branchesList" [value]="branch.id">
                {{ branch.branchName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="projectForm.get('costCenter')?.hasError('required')">
              Cost center is required
            </mat-error>
            <mat-error *ngIf="projectForm.get('costCenter')?.hasError('nonNegative')">
              Cost center cannot be negative
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <h2 class="cmn-inner-heading">Budget</h2>

    <div class="invoice-new-form-wrapper">
      <div class="invoice-form-wrapper">
        <div class="form-wrapper">
          <label for="">Cost Budget <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter amount" formControlName="costBudget" type="number" />
            <mat-error *ngIf="projectForm.get('costBudget')?.hasError('required')">
              Cost budget is required
            </mat-error>
            <mat-error *ngIf="projectForm.get('costBudget')?.hasError('pattern')">
              Cost budget must be a valid number
            </mat-error>
            <mat-error
              *ngIf="projectForm.get('costBudget')?.hasError('maxLimit')"
            >
              Cost Budget cannot exceed the maximum allowed value
            </mat-error>
            <mat-error *ngIf="projectForm.get('costBudget')?.hasError('nonNegative')">
              Cost budget cannot be negative
            </mat-error>
            <mat-error *ngIf="
                projectForm
                  .get('costBudget')
                  ?.hasError('costBudgetExceedsProjectCost')
              ">
              Cost budget cannot be higher than the project cost
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Revenue Budget <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter amount" formControlName="revenuBudget" type="number" />
            <mat-error *ngIf="projectForm.get('revenuBudget')?.hasError('required')">
              Revenue budget is required
            </mat-error>
            <mat-error *ngIf="
                projectForm.get('revenuBudget')?.hasError('pattern') &&
                !projectForm.get('revenuBudget')?.hasError('required')
              ">
              Revenue budget must be a valid number
            </mat-error>
            <mat-error
              *ngIf="
                projectForm.get('revenuBudget')?.hasError('nonNegative') &&
                !projectForm.get('revenuBudget')?.hasError('required')
              "
            >
              Revenue Budget cannot be negative
            </mat-error>
            <mat-error
              *ngIf="
                projectForm.get('revenuBudget')?.hasError('maxLimit') &&
                !projectForm.get('revenuBudget')?.hasError('required')
              ">
              Revenue budget cannot exceed the maximum allowed value
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Threshold <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter amount" formControlName="threshold" />
            <mat-error *ngIf="projectForm.get('threshold')?.hasError('required')">
              Threshold is required
            </mat-error>
            <mat-error *ngIf="
                projectForm.get('threshold')?.hasError('invalidPercentage') &&
                !projectForm.get('threshold')?.hasError('required')
              ">
              Threshold must be a valid percentage (0-100) with up to 2 decimal
              places
            </mat-error>
          </mat-form-field>
          <span
            class="blue"
            *ngIf="
              actualCost &&
              !projectForm.get('threshold')?.hasError('invalidPercentage') &&
              !projectForm.get('threshold')?.hasError('required')
            "
          >
            Alloted: {{ actualCost }}</span
          >
        </div>
      </div>
    </div>

    <section class="dynamic-form-wrapper">
      <div class="main-content-wrapper">
        <h3>Users</h3>
        <div class="wrapper-container" formArrayName="users">
          <div class="form-wrapper flex-wrapper" *ngFor="let user of users.controls; let i = index" [formGroupName]="i">
            <div>
              <div class="indexWrapper">
                <span>{{ i + 1 }}</span>
                <!-- <label for="">
                  <span>Users</span>
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Enter  Name"
                      formControlName="user"
                    />
                  </mat-form-field>
                </label> -->
                <div class="form-wrapper cmn-Search-select-container">
                  <label for="">Users <span class="red">*</span></label>
                  <mat-form-field>
                    <mat-select placeholder="Search Users" #singleSelect formControlName="user"
                      (selectionChange)="onEployeeChange($event, i)">
                      <mat-option>
                        <ngx-mat-select-search
                          placeholderLabel="Search"
                          (input)="onSearchEmployee($event)"
                        ></ngx-mat-select-search>
                      </mat-option>

                      <mat-option *ngFor="let employee of employeeData" [value]="employee.id">
                        {{ employee.firstName }} {{ employee.lastName }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="user.get('user')?.hasError('required')">
                      User is required
                    </mat-error>
                    <mat-error *ngIf="user.get('user')?.hasError('customError')">
                      User is already taken
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-wrapper span-2">
                <label>
                  Email <span class="red">*</span> </label>

                <mat-form-field>
                  <input matInput placeholder="Enter email address" formControlName="email" readonly />
                  <mat-error *ngIf="user.get('email')?.hasError('required')">
                    Email is required
                  </mat-error>
                  <mat-error *ngIf="
                        user.get('email')?.hasError('invalidEmail') &&
                        !user.get('email')?.hasError('required')
                      ">
                    Please enter a valid email address
                  </mat-error>
                  <mat-error *ngIf="
                        user.get('email')?.hasError('emailExists') &&
                        !user.get('email')?.hasError('required')
                      ">
                    Email already taken
                  </mat-error>
                </mat-form-field>

              </div>
            </div>
            <label class="btnWrapper">
              <button type="button" (click)="addUsersRow()">
                <img src="../../../assets/images/icons/icon-add-color.svg" alt="" />
              </button>
              <button type="button" (click)="removeUsersRow(i)" *ngIf="users.controls.length > 1">
                <img src="assets/images/icons/icon-close.svg" alt="" />
              </button>
            </label>
          </div>
          <!-- <div class="content-wrapper">
            <div class="column-1">
              <div>
                <span>S.No</span>
                <h6>1</h6>
              </div>
              <div>
                <span>User</span>
                <h6>Rickson</h6>
              </div>
            </div>
            <div class="span-2">
              <span>Email</span>
              <h6>rickson&#64;gmail.com</h6>
            </div>
            <button>
              <img src="assets/images/icons/icon-close.svg" alt="" />
            </button>
          </div> -->
        </div>
      </div>
    </section>

    <section class="dynamic-form-wrapper">
      <div class="main-content-wrapper">
        <h3>Project Tasks</h3>
        <div class="wrapper-container" formArrayName="projectTasks">
          <div class="form-wrapper flex-wrapper" *ngFor="let tasks of projectTasks.controls; let i = index"
            [formGroupName]="i">
            <div>
              <div class="indexWrapper">
                <span>{{ i + 1 }}</span>
                <label for="">
                  <span>Task <span class="red">*</span></span>
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Enter Task"
                      formControlName="milestone"
                    />
                    <mat-error
                      *ngIf="tasks.get('milestone')?.hasError('required')"
                    >
                      Task is required
                    </mat-error>
                    <mat-error
                      *ngIf="tasks.get('milestone')?.hasError('pattern')"
                    >
                      Task exceed the limit
                    </mat-error>
                  </mat-form-field>
                </label>
              </div>
              <label for="" class="span-2">
                <span>Description</span>
                <mat-form-field>
                  <textarea matInput placeholder="Description" formControlName="description"></textarea>
                  <mat-error *ngIf="
                      tasks.get('description')?.hasError('noHtmlSpecialChars')
                    ">
                    HTML special characters like &lt;, &gt;, &, ", and ' are not
                    allowed
                  </mat-error>
                </mat-form-field>
              </label>
            </div>
            <label class="btnWrapper">
              <button type="button" (click)="addProjectTasksRow()">
                <img src="../../../assets/images/icons/icon-add-color.svg" alt="" />
              </button>
              <button type="button" (click)="removeProjectTasksRow(i)" *ngIf="projectTasks.controls.length > 1">
                <img src="assets/images/icons/icon-close.svg" alt="" />
              </button>
            </label>
          </div>
          <!-- <div class="content-wrapper">
            <div class="column-1">
              <div>
                <span>S.No</span>
                <h6>1</h6>
              </div>
              <div>
                <span>User</span>
                <h6>Rickson</h6>
              </div>
            </div>
            <div class="span-2">
              <span>Email</span>
              <h6>rickson&#64;gmail.com</h6>
            </div>
            <button>
              <img src="assets/images/icons/icon-close.svg" alt="" />
            </button>
          </div> -->
        </div>
      </div>
    </section>

    <div class="submit-btn-wrapper">
      <button type="button" class="cmn-cancel-btn" (click)="onClearForm()">
        Cancel
      </button>
      <button class="cmn-next-btn">Save</button>
    </div>
  </form>
</section>