<mat-dialog-content class="mat-typography">
    <section class="gratuity-dialog-wrapper">
        <h2 class="cmn-popup-title">Branch</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <div class="gratuity-box">
            <h3>Scenario:</h3>
            <ul>
                <li>Basic Salary: AED 7,000</li>
                <li>Years of Service: 6 years</li>
            </ul>
            <ul>
                <li>
                    <h4>First 5 Years:</h4>
                    <h5>(7,000/30) * 21 * 5 = 24,500 AED</h5>
                </li>
                <li>
                    <h4>First 5 Years:</h4>
                    <h5>(7,000/30) * 21 * 5 = 24,500 AED</h5>
                </li>
                <li>
                    <h4>First 5 Years:</h4>
                    <h5>(7,000/30) * 21 * 5 = 24,500 AED</h5>
                </li>
            </ul>
        </div>
    </section>
  </mat-dialog-content>