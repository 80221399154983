import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from, map } from 'rxjs';
import {
  GET_CURRENCIES,
  GET_CURRENCY_BY_ID,
  GET_EXCHANGE_LOG_BY_CURRENCY,
} from '../graphql/querries/currency-adjustment.query';
import { HttpClient } from '@angular/common/http';
import {
  CREATE_CURRENCY,
  REMOVE_CURRENCY_BY_ID,
  UPDATE_CURRENCY_ADJUSTMENT,
} from '../graphql/mutations/currency-adjustment.mutation';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CurrencyAdjustmentService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  private currencyCode: string | null = null;

  //fetch all currencies
  fetchCurrencies(search: string = '', filter: {}): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_CURRENCIES,
        variables: { search, filter },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.currencies));
  }

  //fetch currency by id
  fetchCurrencyById(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_CURRENCY_BY_ID,
        variables: { id: id },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.currency));
  }

  //fetch exchange log by currency
  fetchExchangeLog(currencyId: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_EXCHANGE_LOG_BY_CURRENCY,
        variables: { currencyId: currencyId },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data?.getExchangeRateLogByCurrency)
      );
  }

  //create currency
  createCurrency(createCurrencyInput: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_CURRENCY,
      variables: {
        createCurrencyInput: createCurrencyInput,
      },
    });
  }

  //update currency
  updateCurrencyById(updateCurrencyInput: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: UPDATE_CURRENCY_ADJUSTMENT,
      variables: {
        updateCurrencyInput: updateCurrencyInput,
      },
    });
  }

  //Remove currency by id
  removeCurrencyById(id: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_CURRENCY_BY_ID,
      variables: { id },
    });
  }

  //bulk upload currency
  bulkCreateCurrency(file: File): Observable<any> {
    const token = localStorage.getItem('AUTH_TOKEN');
    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query: `
      mutation($file: Upload!) {
        bulkCreateCurrency(file: $file)
      }      
      `,
        variables: { file: null },
      })
    );

    formData.append('map', JSON.stringify({ '0': ['variables.file'] }));
    formData.append('0', file);

    return from(
      fetch(environment.url.accountsUrl, {
        method: 'POST',
        body: formData,
        headers: {
          authorization: token ? `Bearer ${token}` : '',
          'x-apollo-operation-name': 'uploadFile',
        },
      })
        .then((response) => response.json())
        .then((result) => result)
    );
  }
}
