import { gql } from 'apollo-angular';

export const CREATE_PROJECT = gql`
  mutation createProjectModel(
    $createProjectModuleInput: CreateProjectModelInput!
    $projectTasks: [CreateProjectTaskModuleInput]
    $projectResources: [CreateProjectResourceModuleInput]
  ) {
    createProjectModel(
      createProjectModuleInput: $createProjectModuleInput
      projectTasks: $projectTasks
      projectResources: $projectResources
    ) {
      id
      projectCode
      projectName
      totalProjectCost
      description
      startDate
      endDate
      status
      budget
      threshold
      billingMethod
      costBudget
      revenueBudget
      branchId
      branchData {
        id
        branchName
        address
        city
        state
      }
      costCenterId
      constCenterBranchData {
        id
        branchName
        address
      }
      partnerId
      partner {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
      }
      projectTasks {
        id
        taskName
        description
        status
        projectId
        createdAt
      }
    }
  }
`;

export const DELET_PROJECT = gql`
  mutation deleteProject($id: Int!) {
    deleteProject(id: $id)
  }
`;

export const UPDATE_PROJECT = gql`
  mutation editProject(
    $updateProjectModuleInput: UpdateProjectModuleInput!
    $projectTasks: [UpdateProjectTaskModuleInput]
    $projectResources: [UpdateProjectResourceModuleInput]
  ) {
    editProject(
      updateProjectModuleInput: $updateProjectModuleInput
      projectTasks: $projectTasks
      projectResources: $projectResources
    ) {
      id
      projectCode
      projectName
      totalProjectCost
      description
      startDate
      endDate
      status
      budget
      threshold
      billingMethod
      costBudget
      revenueBudget
      branchId
      branchData {
        id
        branchName
        address
        city
        state
      }
      costCenterId
      constCenterBranchData {
        id
        branchName
        address
      }
      partnerId
      partner {
        id
        partnerType
        customerType
        displayName
        paymentTerm
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
      }
      projectTasks {
        id
        taskName
        description
        status
        projectId
        createdAt
      }
    }
  }
`;

export const CREATE_PROJECT_RESOURCE = gql`
  mutation createProjectResourceBulk(
    $createProjectResourceModuleInput: [CreateProjectResourceModuleInput!]!
  ) {
    createProjectResourceBulk(
      createProjectResourceModuleInput: $createProjectResourceModuleInput
    ) 
  }
`;

export const CREATE_PROJECT_TASK = gql`
  mutation createBulkProjectTask(
    $createProjectTaskModuleInput: [CreateProjectTaskModuleInput!]!
  ) {
    createBulkProjectTask(createProjectTaskModuleInput: $createProjectTaskModuleInput)
  }
`;

export const DELET_PROJECT_RESOURCE = gql`
  mutation deleteProjectResource($id: Int!) {
    deleteProjectResource(id: $id)
  }
`;

export const DELET_PROJECT_TASK = gql`
  mutation deleteProjectTask($id: Int!) {
    deleteProjectTask(id: $id)
  }
`;
