import { gql } from 'apollo-angular';

export const GET_BRANCHES = gql`
  query branches($search: String) {
    branches(search: $search) {
      id
      branchName
      address
      city
      state
      country
      zipCode
      phoneNumber
      branchCode
      isHeadOffice
      email
      createdAt
      updatedAt
      deletedAt
      employee {
        id
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        email
        phoneNumber
        alternatePhoneNumber
        address
        permanentAddress
        dateOfJoining
        employmentType
        employeeType
        employeeStatus
        profilePicture
        maritalStatus
        nationality
        passportNumber
        visaType
        visaIssueDate
        visaExpiryDate
        salary
        bankName
        bankAccountNumber
        IFSCCode
        departmentId
      }
    }
  }
`;
