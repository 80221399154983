import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function trnValidator(control: AbstractControl): ValidationErrors | null {
    const trn = control.value;

    // Check if TRN is 15 digits long and numeric
    if (trn && /^\d{15}$/.test(trn)) {
        return null; // TRN is valid
    }

    return { invalidTRN: true }; // TRN is invalid
}


export function customEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.value;
      if (!email) {
        // If the field is empty, no validation error
        return null;
      }
  
      // Regular expression to match a standard email pattern
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const valid = emailRegex.test(email);
  
      // Return an error object if invalid, otherwise null
      return valid ? null : { invalidEmail: true };
    };
  }
  

export function maxValidator(maxValue: number): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const value = control.value;
        if (value != null && value > maxValue) {
            return {maxCTCExceeded: true}; // Error key to identify this validation
        }
        return null;
    };
}

export function swiftCodeValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const value = control.value as string;

        const upToNineDigitsPattern = /^\d{1,9}$/;

        const isValid = upToNineDigitsPattern.test(value);

        return isValid ? null : {
            invalidSwiftCode: true
        };
    };
}

// export function uaeIbanValidator(): ValidatorFn {
//     return(control : AbstractControl) : ValidationErrors | null => {
//         const ibanPattern = /^AE\d{2}\d{3}\d{16}$/;
//         const isValid = ibanPattern.test(control.value);
//         return isValid ? null : {
//             invalidIban: true
//         };
//     };
// }

export function uaeIbanValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        const ibanPattern = /^AE\d{2}\d{3}\d{16}$/;

        // If the value is empty (null, undefined, or ''), skip validation
        if (value === null || value === undefined || value === '') {
            return null; // Treat empty value as valid
        }

        const isValid = ibanPattern.test(value);
        return isValid ? null : { invalidIban: true }; // Validate only if the value is non-empty
    };
}

export function molNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const molNumber = control.value;

        // Skip validation if the value is empty
        if (!molNumber) {
            return null;
        }

        // Regex to match 1 to 9 digits
        const molPattern = /^\d{1,9}$/;
        const isValid = molPattern.test(molNumber);

        return isValid ? null : {
            invalidMolNumber: true
        };
    };
}


export function nonNegativeValidator(control: AbstractControl): ValidationErrors |null {
    const value = control.value;

    if (value === null || value === undefined || value === '') {
        return null;
    }

    return value < 0 ? {
        nonNegative: true
    } : null;
}

export const positiveQuantityValidator: ValidatorFn = (control : AbstractControl) => {
    const value = control.value;
    return value > 0 ? null : {
        positiveQuantity: true
    };
};

export function alphabeticValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => { // const valid = /^[a-zA-Z]+$/.test(control.value);
        const valid = /^[a-zA-Z\s]+$/.test(control.value);
        return valid ? null : {
            invalidCharacters: {
                value: control.value
            }
        };
    };
}

export function middleNameValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const value = control.value;
        if (! value) { // If the value is empty, it's valid
            return null;
        }
        // Check if the value contains only alphabetic characters
        const alphabeticRegex = /^[A-Za-z\s]+$/;
        return alphabeticRegex.test(value) ? null : {
            invalidCharacters: true
        };
    };
}


// export function numbersOnlyValidator(): ValidatorFn {
//     return(control : AbstractControl) : ValidationErrors | null => {
//         const value = control.value;
//         if (value && !/^\d+$/.test(value)) {
//             return {invalidNumber: true}; // Error if value contains non-digit characters
//         }
//         return null; // Valid input
//     };
// }

export function numbersOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (value === null || value === undefined || value === '') {
            return null; // Treat empty value as valid
        }
        if (!/^\d+$/.test(value)) {
            return { invalidNumber: true }; // Error if value contains non-digit characters
        }
        return null; // Valid input
    };
}

export function uaeVisaNumberValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const value = control.value;
        // Check if value has 1 to 15 characters, allowing only digits and '/'
        const visaPattern = /^[\d/]{1,15}$/;

        if (value && ! visaPattern.test(value)) {
            return {invalidVisaNumber: true}; // Error if invalid
        }
        return null; // Valid visa number
    };
}

export function employeeCodeValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const value = control.value;
        // Regex to allow alphanumeric characters, hyphens, and underscores
        const isValid = /^[a-zA-Z0-9-_]+$/.test(value);
        return isValid ? null : {
            invalidEmployeeCode: true
        }; // Error if invalid
    };
}

export function phoneNumberValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const valid = /^[0-9\s-]*$/.test(control.value);
        return valid ? null : {
            invalidPhoneNumber: true
        };
    };
}

// Validator to ensure the passport number is alphanumeric and has 6 to 9 characters
export function passportNumberValidator(): ValidatorFn {
    return(control : AbstractControl) : {
        [key : string]: any
    } | null => {
        const passportNumberRegex = /^[A-Z0-9]{6,9}$/i; // Adjust based on country-specific rules
        const valid = passportNumberRegex.test(control.value);
        return valid ? null : {
            invalidPassportNumber: {
                value: control.value
            }
        };
    };
}

export function passportValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const passportNumber = control.value;

        if (! passportNumber) {
            return {required: true};
        }
        const passportRegex = /^[A-Z0-9]{6,15}$/i;

        const isValid = passportRegex.test(passportNumber);
        return isValid ? null : {
            invalidPassportNumber: {
                value: passportNumber
            }
        };
    };
}

// visa number validation
export function visaValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const visaNumber = control.value;

        if (! visaNumber) {
            return {required: true};
        }
        const visaRegex = /^[A-Z0-9]{6,15}$/i;

        const isValid = visaRegex.test(visaNumber);
        return isValid ? null : {
            invalidVisaNumber: {
                value: visaNumber
            }
        };
    };
}

// emirates id number validations
export function emiratesIdValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const emiratesId = control.value;

        if (! emiratesId) {
            return {required: true};
        }

        // Regular expression pattern to match the format: 784-XXXX-XXXXXXX-X
        const emiratesIdRegex = /^784-\d{4}-\d{7}-\d{1}$/;

        const isValid = emiratesIdRegex.test(emiratesId);
        return isValid ? null : {
            invalidEmiratesId: {
                value: emiratesId
            }
        };
    };
}

// validations for driving license number
export function drivingLicenseValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const licenseNumber = control.value;

        if (! licenseNumber) {
            return null;
        }

        // Generic regex to match typical driving license formats (alphanumeric, 5-20 characters)
        const licenseRegex = /^[A-Z0-9\s-_]{5,20}$/i;

        const isValid = licenseRegex.test(licenseNumber);
        return isValid ? null : {
            invalidDrivingLicense: {
                value: licenseNumber
            }
        };
    };
}

// for alphabets and digits
export function alphanumericValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const valid = /^[a-zA-Z0-9]+$/.test(control.value);
        return valid ? null : {
            invalidCharacters: {
                value: control.value
            }
        };
    };
}

export function alternatePhoneValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const value = control.value;
        if (! value || value.trim() === '') {
            return null;
        }

        // Allow digits, spaces, and hyphens only
        const valid = /^[0-9\s-]+$/.test(value);
        return valid ? null : {
            invalidNumber: {
                value
            }
        };
    };
}

// General Bank Account Validator
export function bankAccountValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const accountNumber = control.value;

        if (! accountNumber) {
            return {required: true};
        }
        const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;
        const bbanRegex = /^[A-Z0-9]{8,34}$/;

        const isValidIBAN = ibanRegex.test(accountNumber);
        const isValidBBAN = bbanRegex.test(accountNumber);
        return isValidIBAN || isValidBBAN ? null : {
            invalidBankAccount: {
                value: accountNumber
            }
        };
    };
}

export function passwordStrengthValidator(control: AbstractControl): ValidationErrors |null {
    const password = control.value;

    if (! password) 
        return null;
    


    // Return null if no password is entered

    const hasUpperCase = /[A-Z]+/.test(password);
    const hasLowerCase = /[a-z]+/.test(password);
    const hasNumeric = /[0-9]+/.test(password);
    // const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]+/.test(password);
    const validLength = password.length >= 6;

    const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && validLength;

    return ! passwordValid ? {
        passwordStrength: true
    } : null;
}

export function positiveNumberValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const value = control.value;
        return value > 0 ? null : {
            positiveNumber: true
        };
    };
}
