<mat-dialog-content>
    <section class="invoice-apply-credits-wrapper">
        <h2 class="cmn-popup-title">Apply Vendor Credits to Vendor Invoice</h2>
        <button class="cmn-close" mat-dialog-close></button>


        <div class="form-main-content-wrapper">

            <div class="top-content-with-icon">
                <div>
                    <div>
                        <img src="../../../assets/images/cash.svg" alt="">
                    </div>
                    <div>
                        <h5>{{noteDetails?.noteNo}}</h5>
                        <p>Credit Note#</p>
                    </div>
                </div>
                <div>
                    <div>
                        <img src="../../../assets/images/cah-gray.svg" alt="">
                    </div>
                    <div>
                        <h5>{{noteDetails?.balance}}</h5>
                        <p>Available Credits</p>
                    </div>
                </div>
            </div>

            <ul *ngFor="let invoice of allInvoices; let i = index">
                <li>
                    <h6>Invoice#</h6>
                    <p>{{ invoice?.invoiceNo }}</p>
                </li>
                <li>
                    <h6>Invoice Date</h6>
                    <p>{{ invoice?.invoiceDate | date: 'dd/MM/YYYY' }}</p>
                </li>
                <li>
                    <h6>Invoice Amount</h6>
                    <p>AED {{ invoice?.totalPrice }}</p>
                </li>
                <li>
                    <h6>Invoice Balance</h6>
                    <p>AED {{ invoice?.balance }}</p>
                </li>
                <li>
                    <h6>Credits to Apply</h6>
                    <div class="form-wrapper">
                      <mat-form-field>
                        <input
                          matInput
                          [formControl]="getCreditControl(i)"
                          (input)="calculateAmountToCredit()"
                          type="number"
                          min="0"
                          [max]="invoice.balance"
                        />
                        <mat-error *ngIf="getCreditControl(i).hasError('max') && getCreditControl(i).hasError('exceedsAvailableCredits')">
                          Cannot apply more than the available balance, and total applied credits exceed available credits.
                        </mat-error>
                        <mat-error *ngIf="getCreditControl(i).hasError('max') && !getCreditControl(i).hasError('exceedsAvailableCredits')">
                          Cannot apply more than the available balance.
                        </mat-error>
                        <mat-error *ngIf="!getCreditControl(i).hasError('max') && getCreditControl(i).hasError('exceedsAvailableCredits')">
                          Total applied credits exceed available credits.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </li>
                  

            </ul>



            <div>
                <h6>Amount to Credit</h6>
                <p>: &nbsp;&nbsp; {{ totalAmountToCredit | currency: ' AED' }}</p>
            </div>
            <div>
                <h6>Invoice Balance Due</h6>
                <p>: &nbsp;&nbsp; {{ calculateInvoiceBalanceDue() | currency: ' AED' }} </p>

            </div>

        </div>

        <div class="btn-wrapper">
            <button class="cmn-cancel-btn ">
                Cancel
            </button>
            <button class="cmn-next-btn " (click)="applyCreditsToInvoices()">
                Save
            </button>
        </div>
    </section>
</mat-dialog-content>