import { AbstractControl, ValidationErrors } from '@angular/forms';
import { delay, map, of } from 'rxjs';

export function pymentGenerateNo(): string {
  const timestampPart = Date.now().toString().slice(-3);
  const randomPart = Math.floor(100 + Math.random() * 900);
  return `PY-${timestampPart}${randomPart}`;
}

export function loggedInUserId(): string | null {
  const loginEmployee = localStorage.getItem('loggedInUser');
  if (loginEmployee) {
    try {
      const employee = JSON.parse(loginEmployee);
      return employee?.employeeId || null;
    } catch (error) {
      console.error('Error parsing loggedInUser from localStorage:', error);
      return null;
    }
  }
  return null;
}

export function generatedNo(pre: string): string {
  const timestampPart = Date.now().toString().slice(-3);
  const randomPart = Math.floor(100 + Math.random() * 900);
  return `${pre}-${timestampPart}${randomPart}`;
}

export function fetch_PDF(url: string, fileName: string): void {
  const token = localStorage.getItem('AUTH_TOKEN');
  const headers = {
    authorization: token ? `Bearer ${token}` : '',
  };

  fetch(url, { headers })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Unauthorized');
      }
      return response.blob();
    })
    .then((blob) => {
      const blobUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = `${fileName}.pdf`;
      downloadLink.click();
      window.open(blobUrl, '_blank');
      setTimeout(() => URL.revokeObjectURL(blobUrl), 1000);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}

// Custom validator for no HTML special characters
export function noHtmlSpecialChars(
  control: AbstractControl
): ValidationErrors | null {
  const forbidden = /[<;>]/.test(control.value);
  return forbidden ? { noHtmlSpecialChars: true } : null;
}

export function noSpaceAtStartOrEnd(
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value || '';
  if (value.trim() !== value) {
    return { spaceError: true };
  }
  return null;
}

// Custom validator for negative numbers
export function nonNegativeNumber(
  control: AbstractControl
): ValidationErrors | null {
  return control.value >= 0 ? null : { nonNegative: true };
}

// Custom validator for projectCost and costBudget range
export function maxLimitValidator(max: number): any {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value <= max ? null : { maxLimit: true };
  };
}

// Custom validator for percentages
export function percentageValidator(
  control: AbstractControl
): ValidationErrors | null {
  const isValid =
    /^[0-9]{1,2}(\.[0-9]{1,2})?$/.test(control.value) && +control.value <= 100;
  return isValid ? null : { invalidPercentage: true };
}

export function generateProjectCode(prefix: string): string {
  const maxLength = 10;
  const uniquePartLength = maxLength - prefix.length;

  const uniquePart = Math.random().toString().substr(2, uniquePartLength);
  return prefix + uniquePart;
}

export function emailValidator(
  control: AbstractControl
): ValidationErrors | null {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const isValid = emailRegex.test(control.value);
  return isValid ? null : { invalidEmail: true };
}

// export function checkEmailExist(control: AbstractControl) {
//   console.log('controlcontrolcontrol', control);
// }

export function emailExistsValidator(existingEmails: string[]) {
  console.log('existingEmails---------------', existingEmails);
  const emails = existingEmails?.map((el: any) => el?.email);
  console.log('emails', emails);

  return (control: AbstractControl) => {
    if (!control.value) {
      return of(null); // If no value, no validation error
    }
    return of(
      emails?.find((el) => el.toLowerCase() === control.value.toLowerCase())
        ? { emailExists: true }
        : null
    );
  };
}
