import { gql } from 'apollo-angular';

export const GET_DESIGNATIONS = gql`
  query designations($search: String, $status: Int) {
    designations(search: $search, status: $status) {
      id
    designationName
    status
    createdAt
    updatedAt
    deletedAt
    }
  }
`;
