<section class="cmn-innerpage-wrapper invoice-new">
  <h2 class="cmn-inner-heading">{{grnData ? 'Edit' : 'New'}} GRN</h2>
  <form [formGroup]="newGRNForm">
    <div class="invoice-new-form-wrapper">

      <div class="form-wrapper invoice-form-wrapper">

        <!-- Vendor Name Section -->
        <div class="cmn-Search-select-container" *ngIf="!grnData">
          <label for="vendorName">Vendor Name <span class="red">*</span></label>
          <mat-form-field>
            <mat-select [formControl]="vendorControl" placeholder="Search Vendor" formControlName="vendorName"
              (selectionChange)="onVendorChange($event)">
              <mat-option>
                <ngx-mat-select-search [formControl]="vendorSearchControl"
                [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No options found'"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let vendor of filteredVendors" [value]="vendor.id">
                {{ vendor.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              newGRNForm.get('vendorName')!.invalid &&
              newGRNForm.get('vendorName')!.touched
              " >Vendor is required.</mat-error>
            </span>
          </div>
          <button *appHasPermission="['12', Permission.Create]" [routerLink]="['/vendors/vendor-new']">
            <div><img src="../../../assets/images/icons/icon-add-color.svg" alt="Add client"></div>
            <span>Add Vendor</span>
          </button>
        </div>
        <div *ngIf="grnData">
          <label for="grnNumber">Vendor Name <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter GRN Number" id="grnNumber" formControlName="vendorName" readonly />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              newGRNForm.get('vendorName')!.invalid &&
              newGRNForm.get('vendorName')!.touched
              " >Vendor name is required.</mat-error>
            </span>
          </div>
        </div>


        <!-- GRN Number Section -->
        <div>
          <label for="grnNumber">GRN Number <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter GRN Number" id="grnNumber" formControlName="grnNumber" readonly/>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              newGRNForm.get('grnNumber')!.invalid &&
              newGRNForm.get('grnNumber')!.touched
              " >GRN Number is required.</mat-error>
            </span>
          </div>
        </div>

        <!-- Order Number Section -->

        <div class="cmn-Search-select-container" *ngIf="!grnData">
          <label for="purchaseOrderId">PO Number<span class="red">*</span></label>
          <mat-form-field>
            <mat-select [formControl]="purchaseControl" placeholder="Search Purchase Order"
              formControlName="purchaseOrderId" (selectionChange)="onPOChange($event)">
              <mat-option>
                <ngx-mat-select-search [formControl]="purchaseSearchControl"
                [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No options found'"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let purchase of filteredPurchase" [value]="purchase.id">
                {{ purchase.purchaseOrderNo }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              newGRNForm.get('purchaseOrderId')!.invalid &&
              newGRNForm.get('purchaseOrderId')!.touched
              " >
                Purchase order is required.
              </mat-error>
            </span>
          </div>
          <button *appHasPermission="['14', Permission.Create]" [routerLink]="['/purchase-order/purchase-order-new']">
            <div><img src="../../../assets/images/icons/icon-add-color.svg" alt="Add client"></div>
            <span>Add purchase order</span>
          </button>
        </div>

        <div *ngIf="grnData">
          <label for="purchaseOrderId">Reference PO Number<span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter PO Number" id="purchaseOrderId" formControlName="purchaseOrderId"
              readonly />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              newGRNForm.get('purchaseOrderId')!.invalid &&
              newGRNForm.get('purchaseOrderId')!.touched
              " >Purchase order is required.</mat-error>
            </span>
          </div>
        </div>

        <!-- <div>
            <label for="purchaseOrderId">Reference Purchase Order Number</label>
            <mat-form-field>
              <input matInput placeholder="Enter Purchase Order Number" id="purchaseOrderId" formControlName="purchaseOrderId"/>
            </mat-form-field>
          </div> -->

        <!-- Invoice Date Section -->
        <div>
          <label for="goodsReceivedDate">GRN Date<span class="red">*</span></label>
          <mat-form-field>
            <input matInput [matDatepicker]="invoiceDatePicker" placeholder="Select GRN Date" id="goodsReceivedDate"
              formControlName="goodsReceivedDate" />
            <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #invoiceDatePicker></mat-datepicker>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              newGRNForm.get('goodsReceivedDate')!.invalid &&
              newGRNForm.get('goodsReceivedDate')!.touched
              " >GRN Date is required.</mat-error>
            </span>
          </div>
        </div>

        <div>
          <label for="workOrderNumber">Reference Work Order Number<span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Enter Work Order Number" id="workOrderNumber"
              formControlName="workOrderNumber" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
              newGRNForm.get('workOrderNumber')!.invalid &&
              newGRNForm.get('workOrderNumber')!.touched
              " >Work Order Number is required.</mat-error>
            </span>
          </div>
        </div>

        <!-- Terms Section -->
        <!-- <div>
            <label for="paymentTerms">Payment Terms</label>
            <mat-form-field>
              <mat-select placeholder="Due on receipt" id="paymentTerms">
                <mat-option value="Due on receipt">Due on receipt</mat-option>
                <mat-option value="Net 30">Net 30</mat-option>
                <mat-option value="Net 60">Net 60</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->

        <!-- Due Date Section -->
        <!-- <div>
            <label for="dueDate">Shipment Preference</label>
            <mat-form-field>
              <input matInput [matDatepicker]="dueDatePicker" placeholder="Select Date" id="dueDate" />
              <mat-datepicker-toggle matIconSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #dueDatePicker></mat-datepicker>
            </mat-form-field>
          </div> -->

        <!-- Subject Section -->
        <!-- <div>
            <label for="subject">Project Name</label>
            <mat-form-field>
              <input matInput placeholder="Invoice subject" id="subject" />
            </mat-form-field>
          </div> -->

      </div>

      <!-- <div class="service-address-wrapper">
            <h5>Delivery Address</h5>
            <div>
                <div class="form-label-group" >
                    <label for="">
                        <input type="radio" id="radio" name="radio">
                        <span>Organization</span>
                    </label>

                    <div>
                        <h6>Dans Designs

                        </h6>
                        <div>
                            <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="">
                        </div>
                    </div>

                    <p>United Arab Emirates</p>
                    <p>Abu Dhabi 12345</p>
                </div>
                <div>
                    <label for="">
                        <input type="radio" id="radio1" name="radio">
                        <span>Customer</span>
                    </label>
                </div>
            </div>
        </div> -->

    </div>


    <div class="quotation-table-container" *ngIf="items.value?.length > 0">
      <div>
        <table>
          <thead>
            <tr>
              <th>Item/Service</th>
              <th>Description</th>
              <th>Rate</th>
              <th>Ordered Quantity</th>
              <th>Quantity Left</th>
              <!-- <th>Rate</th>
              <th>Amount</th> -->
              <th>Quantity</th>
              <th>Status</th>
              <th>Remark</th>
              <th></th>
            </tr>
          </thead>

          <!-- <tbody *ngIf="!grnData"> -->
          <tbody formArrayName="items">
            <tr *ngFor="let item of items.controls; let i = index">
              <td>{{ item?.value?.itemName }}</td>
              <!-- <td>{{  item.get('itemName')?.value  }}</td> -->
              <td>{{ item?.value?.description || '--' }}</td>
              <td>{{ item?.value?.rate | number: '1.2-2' }}</td>
              <td>{{ item?.value?.orderedQuantity }}</td>
              <td>{{ item?.value?.totalQuantity }}</td>
              <!-- <td>{{ item?.value?.rate }}</td>
              <td>{{ item?.value?.amount }}</td> -->
              <td [formGroupName]="i">
                <mat-form-field>
                  <input matInput formControlName="quantity" placeholder="Quantity" maxlength="6" pattern="^[0-9]*$" />
                </mat-form-field>
                <div class="custom-error">
                  <span>
                    <mat-error *ngIf="item.get('quantity')?.hasError('lessThanZero')">Quantity cannot be less than or
                      equal to 0.</mat-error>
                  </span>
                  <span>
                    <mat-error *ngIf="item.get('quantity')?.hasError('exceedsTotal')">Quantity cannot exceed total
                      quantity.</mat-error>
                  </span>
                  <span>
                    <mat-error *ngIf="item.get('quantity')?.hasError('required')">Quantity is required.</mat-error>
                  </span>
                  <span>
                    <mat-error *ngIf="item.get('quantity')?.hasError('pattern')">Only numeric values are
                      allowed.</mat-error>
                  </span>
                </div>
              </td>
              <td [formGroupName]="i">
                <mat-form-field>
                  <mat-select formControlName="itemStatus" placeholder="Select Status">
                    <mat-option value="Open">Open</mat-option>
                    <mat-option value="Partial">Partial</mat-option>
                    <mat-option value="Completed">Completed</mat-option>
                    <mat-option value="Damaged">Damaged</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field>
                  <textarea matInput placeholder="Type something.." formControlName="remark"></textarea>
                </mat-form-field>
              </td>
            </tr>
          </tbody>
          <!-- <tbody>
            <tr formArrayName="items" *ngFor="let item of items">
              <td>{{ item?.value?.itemName }}</td>
              <td>{{ item?.value?.description }}</td>
              <td>{{ item?.value?.totalQuantity }}</td>
              <td [formGroupName]="i">
                <mat-form-field>
                  <input matInput formControlName="quantity" placeholder="Quantity" maxlength="6" pattern="^[0-9]*$" />
                  <mat-error *ngIf="item.get('quantity')?.hasError('lessThanZero')">
                    Quantity cannot be less than 0.
                  </mat-error>
                  <mat-error *ngIf="item.get('quantity')?.hasError('exceedsTotal')">
                    Quantity cannot exceed total quantity.
                  </mat-error>
                  <mat-error *ngIf="item.get('quantity')?.hasError('required')">
                    Quantity is required.
                  </mat-error>
                  <mat-error *ngIf="item.get('quantity')?.hasError('pattern')">
                    Only numeric values are allowed.
                  </mat-error>
                </mat-form-field>
              </td>
              <td [formGroupName]="i">
                <mat-form-field>
                  <mat-select formControlName="itemStatus" placeholder="Select Status">
                    <mat-option value="Open">Open</mat-option>
                    <mat-option value="Partial">Partial</mat-option>
                    <mat-option value="Completed">Completed</mat-option>
                    <mat-option value="Damaged">Damaged</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field>
                  <textarea matInput placeholder="Type something.." formControlName="remark"></textarea>
                </mat-form-field>
              </td>
            </tr>
          </tbody> -->
        </table>
      </div>
    </div>
  </form>

  <div class="notes-tatal-amount-container" *ngIf="subTotal && totalPrice">
    <div class="amount-table-div">
      <div>
        <h6>Sub Total<span>: AED {{subTotal | number: '1.2-2'}}</span></h6>
      </div>
      <div>
        <h4>Total(AED) <span>: AED {{totalPrice | number: '1.2-2'}}</span></h4>
      </div>
    </div>
  </div>


  <!--   
      <div class="payment-mode-wrapper">
  
     
      
        <div class="add-email-wrapper">
          <h6>Email Connection</h6>
          <div class="flex-wrapper-container">
            <div class="email-account-div">
              <div class="profile-image">
                <img src="../../../assets/images/profile.png" alt="Profile Image">
              </div>
              <span>rikson&#64;gmail.com</span>
              <div class="close-btn">
                <img src="../../../assets/images/icons/icon-close.svg" alt="Remove Email">
              </div>
            </div>
            <button>
              <div>
                <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add Email">
              </div>
              <span>Add email</span>
            </button>
          </div>
        </div>
      
      </div> -->


  <!-- <div class="submit-btn-wrapper">
        <button class="cmn-cancel-btn">Cancel</button>
      
        <button class="cmn-next-btn" type="submit" (click)="onSave()">Save</button>
      </div> -->
  <div class="submit-btn-wrapper">
    <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
    <button class="cmn-draft-btn" (click)="onSaveAsDraft()">Save as Draft</button>
    <button class="cmn-next-btn" type="submit" (click)="onCreateGRN()">{{grnData ? 'Update' : 'Create'}} GRN</button>
  </div>



</section>