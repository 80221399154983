import { Component, Inject, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { Subject } from 'rxjs';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { noSpaceAtStartOrEnd } from '../../helpers/helper';

@Component({
  selector: 'app-warehouse-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    AgGridAngular,
    PermissionDirective,
  ],
  templateUrl: './warehouse-details.component.html',
  styleUrl: './warehouse-details.component.scss',
})
export class WarehouseDetailsComponent implements OnInit {
  selectedTabIndex = 0; // Default to the first tab
  itemId: any;
  itemData: any = {};
  allItems: any[] = [];
  public loading: boolean = true;
  private searchSubject = new Subject<string>();
  public Permission: any = Permission;

  constructor(
    private router: Router,
    private itemService: InventoryItemService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.itemId = params.get('id');
    });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  navigateToAddNew() {
    this.router.navigate(['/warehouse/warehouse-new']);
  }

  navigateToEditPage(): void {
    const selectedJournal = this.allItems[this.selectedTabIndex];
    this.itemId = selectedJournal.id;

    this.router.navigate([`inventory/inventory-edit/${this.itemId}`]);
  }

  selectTab(index: number) {
    this.selectedTabIndex = index;
    this.itemData = this.allItems[index];
    if (this.itemData) {
      this.router.navigate([
        `inventory/inventory-details/${this.itemData?.id}`,
      ]);
    }
  }
  openAddStorageDialog() {
    const dialogRef = this.dialog.open(AddStorageDialog);
  }
  readonly dialog = inject(MatDialog);

  rowData = [
    {
      itemName: 'Ultratech Cement',
      skuCode: 'CM12034562',
      rate: 'AED 2,500',
      unit: 'Kg',
    },
    {
      itemName: 'Ultratech Cement',
      skuCode: 'CM12034562',
      rate: 'AED 2,500',
      unit: 'Kg',
    },
  ];

  columnDefs = [
    {
      headerName: 'Name',
      field: 'itemName',
      flex: 1,
    },
    { headerName: 'SKU', field: 'skuCode', flex: 1 },
    { headerName: 'Rate', field: 'rate', flex: 1 },
    { headerName: 'Unit', field: 'unit', flex: 1 },
  ];
  addedRowData = [
    {
      space: 'Lorem',
      stack: 'AAB',
      row: '32236',
      column: '2563',
    },
    {
      space: 'Lorem',
      stack: 'AAB',
      row: '32236',
      column: '2563',
    },
    {
      space: 'Lorem',
      stack: 'AAB',
      row: '32236',
      column: '2563',
    },
  ];
  addedColumnDefs = [
    {
      headerName: 'Space',
      field: 'space',
      flex: 1,
    },
    { headerName: 'Stack', field: 'stack', flex: 1 },
    { headerName: 'Row', field: 'row', flex: 1 },
    { headerName: 'Column', field: 'column', flex: 1 },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
}

@Component({
  selector: 'add-location-dialog',
  templateUrl: 'add-location-dialog.html',
  styleUrls: ['./warehouse-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
})
export class AddStorageDialog {
  addStorageForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log(this.data);

    if (!this.data) {
      this.addStorageForm = this.fb.group({
        space: ['', [Validators.required, noSpaceAtStartOrEnd]],
      });
    } else {
      this.addStorageForm = this.fb.group({
        space: ['', [Validators.required, noSpaceAtStartOrEnd]],
      });
    }
  }
}
