import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { LeaveHistoryComponent } from './components/leave-history/leave-history.component';
import { LeaveApplyComponent } from './components/leave-apply/leave-apply.component';
import { HolidayListComponent } from '../../../holiday/holiday-list/holiday-list.component';

@Component({
  selector: 'app-leaves',
  standalone: true,
  imports: [
    CommonModule,
    LeaveHistoryComponent,
    LeaveApplyComponent,
    MatTabGroup,
    MatTabsModule,
    HolidayListComponent
  ],
  templateUrl: './leaves.component.html',
  styleUrl: '../../employee-details.component.scss',
})
export class LeavesComponent {
  @ViewChild('leaveHistory') leaveHistoryComponent: any;
  @ViewChild('holidayCalendar') HolidayComponent: HolidayListComponent;
  public themeClass: string = 'ag-theme-quartz';
  public paginationPageSize = 25;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  activeTabIndex = 0; 

  constructor( ) { }

  ngOnInit() {
  }

  onTabChange(event: MatTabChangeEvent) {
    console.log(event.index);
    
    if (event.index === 0) {
      this.activeTabIndex = 0;
        if (this.leaveHistoryComponent) {
          this.leaveHistoryComponent.fetchLeaves();
        }
    }
    else if (event.index === 2) {
      this.activeTabIndex = 2;
        if (this.HolidayComponent) {
          console.log(this.HolidayComponent);
          this.HolidayComponent.fetchHolidayCalendar();
        }
    }
  }

}
