<mat-dialog-content>
    <section class="vacation-edit-dialog-wrapper">
        <h2 class="cmn-popup-title"> Edit Vacation</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <form [formGroup]="vacationEditForm">
            <div class="vacation-form-wrapper">
                <div class="form-wrapper">
                    <label for="">From Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="pickerFrom" formControlName="startDate" placeholder="Choose date"/>
                        <mat-datepicker-toggle matIconSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                        <mat-datepicker #pickerFrom></mat-datepicker>
                    </mat-form-field>
                    <div
                    *ngIf="
                      vacationEditForm.get('startDate')!.invalid &&
                      vacationEditForm.get('startDate')!.touched
                    "
                    class="error"
                  >
                    From date is required.
                  </div>
                </div>
                <div class="form-wrapper">
                  <label for="">Duration <span class="red">*</span></label>
                  <mat-form-field>
                    <mat-select placeholder="Select duration" formControlName="duration">
                      <mat-option value="7">7 days</mat-option>
                      <mat-option value="14">14 days</mat-option>
                      <mat-option value="21">21 days</mat-option>
                      <mat-option value="Custom">Custom</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div
                  *ngIf="
                  vacationEditForm.get('duration')!.invalid &&
                  vacationEditForm.get('duration')!.touched
                  "
                  class="error"
                >
                Duration is required.
                </div>
                </div>
                <div class="form-wrapper">
                    <label for="">To Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="pickerTo" formControlName="endDate" placeholder="Choose date"/>
                        <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
                        <mat-datepicker #pickerTo></mat-datepicker>
                    </mat-form-field>
                    <div
                    *ngIf="
                      vacationEditForm.get('endDate')!.invalid &&
                      vacationEditForm.get('endDate')!.touched
                    "
                    class="error"
                  >
                    To date is required.
                  </div>
                </div>
                <div class="form-wrapper">
                    <label for="">Notes</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Type something.."></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="btn-wrapper">
                <button class="cmn-popup-btn" (click)="onEdit()">
                  Submit 
                </button>
              </div>
        </form>
    </section>
</mat-dialog-content>