import { gql } from 'apollo-angular';

export const FETCH_GRN = gql`
  query listGoodsReceived ($search: String) {
    listGoodsReceived(search: $search)  {
    id
    goodsReceivedDate
    grnNumber
    grnStatus
    status
    createdAt
    updatedAt
    deletedAt
    purchaseOrderId
    purchaseOrderData {
      id
      vendorId
      purchaseOrderNo
      reference
      dueDate
      billDate
      paymentTerms
      status
      billStatus
      purchaseStatus
      tax
      totalPrice
      subTotal
      customer{
      id
      displayName
      }
      project {
      projectName
      }
    }
  }
  }
`;

export const FETCH_GRN_BY_ID = gql`
  query goodsReceived ($id: Int!) {
    goodsReceived(id: $id)  {
    id
    goodsReceivedDate
    grnNumber
    grnStatus
    status
    createdAt
    updatedAt
    deletedAt
    goodsReceivedItems {
      id
      quantity
      grnItemStatus
      status
      remark
      purchaseItemData{
        id
        quantity
        itemId
        balanceQuantity
        item{
        itemName
        }
        description
      }
    } 
    receivedById
    receivedByData {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
    }
    purchaseOrderId
    purchaseOrderData {
      id
      vendorId
      purchaseOrderNo
      reference
      dueDate
      billDate
      purchaseUrl
      totalPrice
      subTotal
      purchaseItems{
        id
        quantity
        description
        balanceQuantity
      }
      workorderData{
        workOrderNo
      }
        customer{
        displayName
        }
      shippingAddressData{
             addressLine1
            addressLine2
            city
            state
           country
     }
}

  }
  }
`;

export const ALL_PURCHASE_ORDER = gql`
  query purchaseOrders($vendorId: Int, $purchaseStatuses: [PurchaseStatus!]) {
    purchaseOrders(vendorId: $vendorId, purchaseStatuses: $purchaseStatuses){
    id
    vendorId
    vendor {
      id
      partnerType
      customerType
      displayName
      paymentTerm
      companyName
      email
      phone
    }
    purchaseOrderNo
  }
  }
`;