import { gql } from "apollo-angular";

export const CREATE_CHART_ACCOUNTS = gql`
  mutation createChartOfAccount(
    $accountType: String!, 
    $accountName: String!,  
    $accountCode: String!,  
    $description: String, 
    $parentAccountId: Int, 
    $isSubAccount: Boolean
  
    ) {
    createChartOfAccount( createChartOfAccountInput: {
      accountType: $accountType,
      accountName: $accountName,
      accountCode: $accountCode,
      description: $description,
      parentAccountId: $parentAccountId,
      isSubAccount: $isSubAccount,
    }
      ) {
    id
    accountType
    accountName
    accountCode
    description
    parentAccountId
    parentAccountData {
      id
      accountType
      accountName
      accountCode
      description
      parentAccountId
      isSubAccount
      createdAt
      updatedAt
      deletedAt
    }
    isSubAccount
    createdAt
    updatedAt
    deletedAt
    JournalEnteries {
      id
      chartOfAccountId
      journalId
      partnerId
      description
      debit
      credit
    }
  }
}
`;

export const REMOVE_CHART_ACCOUNTS = gql`
  mutation removeChartOfAccount($id: Int!) {
    removeChartOfAccount(id: $id)
}
`;

export const UPDATE_CHART_ACCOUNTS = gql`
  mutation updateChartOfAccount(    
    $id: Int!,
    $accountType: String, 
    $accountName: String,  
    $accountCode: String,  
    $description: String, 
    $parentAccountId: Int, 
    $isSubAccount: Boolean) {
    updateChartOfAccount(
      updateChartOfAccountInput: {
      id: $id
      accountType: $accountType,
      accountName: $accountName,
      accountCode: $accountCode,
      description: $description,
      parentAccountId: $parentAccountId,
      isSubAccount: $isSubAccount,
    }) {
    id
    accountType
    accountName
    accountCode
    description
    parentAccountId
    parentAccountData {
      id
      accountType
      accountName
      accountCode
      description
      parentAccountId
      isSubAccount
      createdAt
      updatedAt
      deletedAt
    }
    isSubAccount
    createdAt
    updatedAt
    deletedAt
    JournalEnteries {
      id
      chartOfAccountId
      description
      debit
      credit
    }
  }
}
`;