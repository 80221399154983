import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { QuillModule } from 'ngx-quill';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Location } from '@angular/common';
import { MAIL_ALLOWED_FILE_TYPES } from '../../helpers/helper-file';
import { SendToMailService } from '../../features/send-to-mail/services/send-to-mail.service';

@Component({
  selector: 'app-send-mail',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    QuillModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
  ],
  templateUrl: './send-mail.component.html',
  styleUrl: './send-mail.component.scss',
})
export class SendMailComponent implements OnInit {
  invoiceId: number;
  invoiceData: any = {};
  invoiceTemplateControl = new FormControl();
  emailForm: FormGroup;

  documentFile: File[] = [];
  documentFileNames: string[] = [];

  isAttachInvoice: boolean = true;
  isLoading: boolean = false;

  allowedFileTypes: string[] = MAIL_ALLOWED_FILE_TYPES;

  constructor(
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private router: Router,
    private sendMailService: SendToMailService
  ) {
    this.emailForm = this.fb.group({
      toEmailControl: ['', [Validators.required, Validators.email]],
      ccEmailControl: ['', Validators.email],
      bccEmailControl: ['', Validators.email],
      subject: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.invoiceId = +id;
        this.getInvoiceDetails();
      }
    });
  }

  toEmails: string[] = [];
  ccEmails: string[] = [];
  bccEmails: string[] = [];

  toEmailControl = new FormControl('');
  ccEmailControl = new FormControl('');
  bccEmailControl = new FormControl('');

  showBcc = false;

  toggleInvoice(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.isAttachInvoice = input.checked;
  }

  private getInvoiceDetails(): void {
    if (this.invoiceId !== null) {
      this.invoiceService.getInvoiceDetails(this.invoiceId).subscribe({
        next: (data) => {
          this.invoiceData = data;
          this.toEmails = [this.invoiceData?.partnerData?.email];
          this.invoiceTemplateControl.setValue(
            this.invoiceData?.invoiceTemplate?.templateBody
          );
          this.emailForm.patchValue({
            subject:
              this.invoiceData?.invoiceNo + ' ' + this.invoiceData?.subject,
          });

          if (
            this.invoiceData.partnerData.primaryContact &&
            this.invoiceData.partnerData.primaryContact.email
          ) {
            this.ccEmails = [this.invoiceData.partnerData.primaryContact.email];
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const filesArray = Array.from(input.files);
      const validFiles = filesArray.filter((file) =>
        this.allowedFileTypes.includes(file.type)
      );

      if (validFiles.length < filesArray.length) {
        alert('Some files were not accepted due to unsupported file types.');
      }

      this.documentFileNames.push(...validFiles.map((file) => file.name));
      this.documentFile.push(...validFiles);
    }
  }

  removeFile(index: number): void {
    this.documentFileNames.splice(index, 1);
    this.documentFile.splice(index, 1);
  }

  clearDocument(fileName: string): void {
    this.documentFileNames = this.documentFileNames.filter(
      (name: string) => name !== fileName
    );
    this.documentFile = this.documentFile.filter(
      (name: any) => name !== fileName
    );
  }

  sendEmail() {
    this.isLoading = true;
    const body = this.invoiceTemplateControl?.value;
    const fromEmail = 'admin@munawel.com';
    const templateId = 2;

    // console.log('Form values:', this.emailForm.value);

    // Ensure to filter out any empty or invalid emails
    const toEmails = [
      ...this.toEmails,
      ...this.emailForm.value.toEmailControl
        .split(',')
        .map((email: any) => email.trim())
        .filter((email: any) => this.isValidEmail(email)),
    ];

    const ccEmails = [
      ...this.ccEmails,
      ...this.emailForm.value.ccEmailControl
        .split(',')
        .map((email: any) => email.trim())
        .filter((email: any) => this.isValidEmail(email)),
    ];

    const subject = this.emailForm.value?.subject;

    this.sendMailService
      .sendInvoiceMail(
        this.invoiceId,
        toEmails,
        ccEmails,
        subject,
        body,
        this.documentFile,
        this.isAttachInvoice
      )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          console.log('Email sent successfully:', result);
          this.toastr.success('Email sent successfully');

          const loginEmployee: any = localStorage.getItem('loggedInUser');
          const employee = JSON.parse(loginEmployee);
          const employeeId = employee.employeeId;

          this.invoiceService.markAsSent(this.invoiceId, employeeId).subscribe({
            next: () => {
              this.isLoading = false;
            },
            error: (error) => {
              this.isLoading = false;
              this.toastr.error(error.message || 'Failed to update item.');
            },
          });

          this.router.navigate([`invoice/invoice-details/${this.invoiceId}`]);
        },
        error: (error) => {
          this.isLoading = false;
          console.error('Error sending email:', error);
          this.toastr.error('Failed to send email');
        },
      });
  }

  addEmail(type: 'to' | 'cc' | 'bcc') {
    let control;
    let emailList;

    switch (type) {
      case 'to':
        control = this.toEmailControl;
        emailList = this.toEmails;
        break;
      case 'cc':
        control = this.ccEmailControl;
        emailList = this.ccEmails;
        break;
      case 'bcc':
        control = this.bccEmailControl;
        emailList = this.bccEmails;
        break;
      default:
        return;
    }

    // Only proceed if control.value is defined and not empty
    const email = control?.value?.trim();

    if (email && this.isValidEmail(email) && !emailList.includes(email)) {
      emailList.push(email); // Add the new email to the list
      console.log(`${type.toUpperCase()} Emails:`, emailList); // Log for debugging
      control.reset(); // Clear the input field after adding
    } else if (email && !this.isValidEmail(email)) {
      console.error('Invalid email format:', email);
    }
  }

  removeEmail(email: string, type: 'to' | 'cc' | 'bcc') {
    let emailList;

    switch (type) {
      case 'to':
        emailList = this.toEmails;
        break;
      case 'cc':
        emailList = this.ccEmails;
        break;
      case 'bcc':
        emailList = this.bccEmails;
        break;
    }

    const index = emailList.indexOf(email);
    if (index >= 0) {
      emailList.splice(index, 1);
    }
  }

  toggleBcc() {
    this.showBcc = !this.showBcc;
  }

  private isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  navigateToDetail() {
    this.router.navigate([`invoice/invoice-details/${this.invoiceId}`]);
    // this.location.back();
  }
}
