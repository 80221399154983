import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  CHART_ACCOUNTS,
  COMMENTS,
  GET_INVOICE_BY_CUSTOMER_OR_VENDOR,
  GET_ITEMS,
  GET_NOTE_DETAILS,
  GET_PAID_INVOICES_BY_VENDOR,
  GET_PARTNERS,
  NOTE,
  NOTES,
} from '../graphql/credit-debit-note.queries';
import {
  APPLY_TO_INVOICE,
  CHANGE_NOTE_STATUS,
  CREATE_COMMENT,
  CREATE_NOTE,
  GENERATE_NOTE_PDF,
  REMOVE_NOTE,
  REMOVE_NOTE_ITEM,
  SEND_NOTE_MAIL,
  UPDATE_NOTE,
} from '../graphql/credit-debit-note.mutations';

@Injectable({
  providedIn: 'root',
})
export class CreditDebitNoteService {
  constructor(private http: HttpClient, private apollo: Apollo) {}

  // munawel-project
  fetchPartners(search: string, type: string): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        variables: {
          search: search,
          partnerType: type,
        },
        query: GET_PARTNERS,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((response) => response.data?.getPartnersDropDownItems)
      );
  }

  // munawel-accounts
  fetchNotes(type: string, search: string): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        variables: {
          noteType: type,
          search: search,
        },
        query: NOTES,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.notes));
  }

  fetchinvoicesbycustomerorvendor(id: number, partnerType: string){
    return this.apollo
    .use('accounts')
    .watchQuery<any>({
      variables: {
        partnerId: id,
        partnerType: partnerType
      },
      query: GET_INVOICE_BY_CUSTOMER_OR_VENDOR,
      fetchPolicy: 'no-cache',
    })
    .valueChanges.pipe(map((response) => response?.data?. getInvoiceByCustomerOrVendor));
  }
  
  // munawel-accounts
  fetchNote(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        variables: {
          id: id,
        },
        query: NOTE,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.note));
  }

  //fetch paid invoices by vendor
  fetchPaidInvoicesByVendor(partnerId: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .query<any>({
        query: GET_PAID_INVOICES_BY_VENDOR,
        variables: {
          partnerId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((response) => response?.data?.getPaidVendorInvoice));
  }

  fetchItems(search: string = '', filter: {}): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_ITEMS,
        variables: { search, filter },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.items));
  }

  // munawel-accounting
  removeNote(id: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_NOTE,
      fetchPolicy: 'no-cache',
      variables: {
        id: id,
      },
    });
  }
  

  // munawel-accounting
  removeNoteItem(id: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_NOTE_ITEM,
      fetchPolicy: 'no-cache',
      variables: {
        id: id,
      },
    });
  }
  
  // munawel-accounting
  createcomment(createCommentInput: any ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_COMMENT,
      fetchPolicy: 'no-cache',
      variables: {
        createCommentInput: createCommentInput
      }
    }).pipe(
      map((result: any) => result.data.createComment || [])
    );
  }
  // munawel-accounting
  updatenote(updateNoteInput: any , noteItems: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: UPDATE_NOTE,
      fetchPolicy: 'no-cache',
      variables: {
        updateNoteInput: updateNoteInput,
        noteItems: noteItems
      }
    })
  }

  // munawel-accounting
  changestatusnote(noteId: number, newStatus: string ){
    return this.apollo.use('accounts').mutate({
      mutation: CHANGE_NOTE_STATUS,
      fetchPolicy: 'no-cache',
      variables: {
        noteId: noteId,
        newStatus: newStatus
      }
    })
  }

  // munawel-accounting
  createnote(createNoteInput: any, noteItems: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_NOTE,
      fetchPolicy: 'no-cache',
      variables: {
        createNoteInput: createNoteInput,
        noteItems: noteItems,
      },
    });
  }

  applytoinvoice(applyNoteInvoice: any):  Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: APPLY_TO_INVOICE,
      fetchPolicy: 'no-cache',
      variables: {
        applyNoteInvoice: applyNoteInvoice,
      },
    });
  }

  // munawel-accounts
  chartOfAccounts(
    accountType: any,
    search: any,
    parentAccountId: any,
    isSubAccount: any
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: CHART_ACCOUNTS,
        variables: {
          accountType,
          search,
          parentAccountId,
          isSubAccount,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.chartOfAccounts || []));
  }

  // munawel-accounts
  getNoteDetails(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_NOTE_DETAILS,
        variables: { id: id },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.getNoteDetails));
  }

  sendNoteMail(
    noteId: any,
    to: any[],
    cc: any[],
    subject: any,
    body: any,
    attachment?: any,
    isAttachment?: boolean
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: SEND_NOTE_MAIL,
      variables: {
        noteId: noteId,
        to: to,
        cc: cc,
        subject: subject,
        body: body,
        attachment: attachment,
        isAttachment: isAttachment,
      },
      context: {
        headers: {
          'x-apollo-operation-name': 'file-upload',
          'apollo-require-preflight': 'true',
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  generateNotePdf(noteId: any, noteType: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GENERATE_NOTE_PDF,
        fetchPolicy: 'no-cache',
        variables: {
          noteId: noteId,
          noteType: noteType,
        },
      })
      .valueChanges.pipe(map((response) => response.data?.generateNotePdf));
  }

    comments(conditions: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: COMMENTS,
        variables: { conditions: conditions },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.comments));
  }


      //Munawel-accounting
    listAllComments(conditions: any): Observable<any> {
      return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: COMMENTS,
        variables:
        {
          conditions: conditions
        },
        fetchPolicy: 'no-cache'
      }).valueChanges.pipe(map(response => response?.data?.comments || []))
    }
}
