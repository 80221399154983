import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_ATTENDANCE, GET_ATTENDANCE_BY_ID, GET_ATTENDANCE_WITH_PAGINATION } from '../graphql/query/attendance.queries';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AttendanceService {
  constructor(private apollo: Apollo) { }

  fetchAttendance(search: string = '', filter: {}): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_ATTENDANCE,
        variables: { search, filter },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.attendances || [])
      );
  }

  findAllAttendancesWithPagination(limit: any, offset: any, search: string = '', filter: {}): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_ATTENDANCE_WITH_PAGINATION,
        variables: {
          limit,
          offset,
          search,
          filter
        },
      })
      .valueChanges.pipe(
        map((result) => result.data?.findAllAttendancesWithPagination || [])
      );
  }

  fetchAttendanceById(search: string = '', filter: {}): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_ATTENDANCE_BY_ID,
        variables: { search, filter },
      })
      .valueChanges.pipe(
        map((result) => result.data?.attendance || [])
      );
  }

  bulkCreateAttendance(file: File): Observable<any> {
    const token = localStorage.getItem('AUTH_TOKEN'); 
    const formData = new FormData();
    formData.append('operations', JSON.stringify({
      query: `
      mutation bulkCreateAttendances($file: Upload!) {
        bulkCreateAttendances(file: $file)
      }
      `,
      variables: { file: null }
    }));
  
    formData.append('map', JSON.stringify({ '0': ['variables.file'] }));
    formData.append('0', file);
  
    return from(
      fetch(environment.url.hrmsModuleUrl, {
        method: 'POST',
        body: formData, 
        headers: {
          'authorization': token ? `Bearer ${token}` : '', 
          'x-apollo-operation-name': 'uploadFile'
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(result => result.data?.bulkCreateAttendances || result)
    );
  }
}
