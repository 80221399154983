import { gql } from 'apollo-angular';

export const GET_EMPLOYEES = gql`
  query getEmployees($search: String, $filter: EmployeeFilter) {
    getEmployees(search: $search, filter: $filter) {
      id
      employeeCode
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      MOLNo
      WPSNo
      departmentId
      department {
        id
        departmentName
        status
        createdAt
        updatedAt
        deletedAt
      }
      designationId
      designation {
        id
        designationName
        status
        createdAt
        updatedAt
        deletedAt
      }
      reportingManagerId
      reportingManager {
        id
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        email
        phoneNumber
        alternatePhoneNumber
        address
        permanentAddress
        dateOfJoining
        employmentType
        employeeType
        employeeStatus
        profilePicture
        maritalStatus
        nationality
        passportNumber
        visaType
        visaIssueDate
        visaExpiryDate
        salary
        bankName
        bankAccountNumber
        IFSCCode
        departmentId
        designationId
        reportingManagerId
        branchId
      }
      branchId
      branch {
        id
        branchName
        address
        city
        state
        country
        zipCode
        phoneNumber
        branchCode
        isHeadOffice
        email
        createdAt
        updatedAt
        deletedAt
      }
      leaves {
        id
        employeeId
        leaveType
        startDate
        endDate
        totalLeaveDays
        leaveStatus
        employeeComment
        supportingDocuments
        createdAt
        updatedAt
        deletedAt
      }
      vacationRequests {
        id
        employeeId
        requestDate
        startDate
        endDate
        status
        additionalSalary
      }
      employeeDocuments {
        id
        employeeId
        documentType
        documentNumber
        issueDate
        expiryDate
        issuingAuthority
        filePath
        isVerified
        remarks
      }
      user {
        id
        email
        employeeId
        profileId
        profileData {
          profileName
        }
      }
      employeeSalaries {
        id
        employeeId
        basicSalary
        hra
        allowances
        deductions
        grossSalary
        netSalary
        remarks
      }
      employeeExits {
        id
        employeeId
        exitDate
        exitType
        gratuity
        exitReason
        isNoticePeriod
        isExitInterview
        interviewDate
        interviewNotes
        isAssetReturned
        clearanceStatus
        clearanceDate
        settlementStatus
        settlementAmount
        settlementDate
        exitDocuments
        isAccountsDisabled
        lastWorkingDay
        isManagerApproval
        isHrApproval
        isFinalApproval
        feedbackScore
        isRehireEligibility
      }
      employeePayrolls {
        id
        payrollId
        employeeId
        payPeriodStart
        payPeriodEnd
        basicSalary
        overtimePay
        bonuses
        deductions
        netPay
        paymentDate
        paymentMethod
        payslipURL
      }
    }
  }
`;

export const GET_EMPLOYEES_LIST = gql`
  query getEmployeeList($search: String, $filter: EmployeeFilter) {
    getEmployeeList(search: $search, filter: $filter) {
      id
      employeeCode
      firstName
      lastName
      profilePicture
      middleName
      dateOfBirth
      isPortalUser
      gender
      email
      status
      employeeType
      employeeStatus
      phoneNumber
      department {
        id
        departmentName
        __typename
      }
      designation {
        id
        designationName
        __typename
      }
      branch {
        id
        branchName
        __typename
      }
      employeeExits {
        id
      }
      __typename
    }
  }
`;

export const GET_EMPLOYEE_SALARY_BY_ID = gql`
  query employeeSalary($id: Int!) {
    employeeSalary(id: $id) {
      id
      employeeId
      basicSalary
      hra
      allowances
      deductions
      grossSalary
      netSalary
      remarks
    }
  }
`;

export const GET_EMPLOYEE_APPROVERS = gql`
  query getEmployeeApprovers {
    getEmployeeApprovers {
      id
      employeeCode
      firstName
      lastName
      middleName
    }
  }
`;

export const GET_EMPLOYEE_CAREER = gql`
  query employeeCareer($id: Int!) {
    employeeCareer(id: $id) {
      id
      employeeId
      employee {
        id
        employeeCode
        firstName
        lastName
        email
      }
      type
      currentPosition
      previousPosition
      date
      reason
    }
  }
`;

export const GET_EMPLOYEE_CAREER_BY_ID = gql`
  query getEmployeeCareer($id: Int!) {
    getEmployeeCareer(id: $id) {
      id
      employeeCode
      firstName
      lastName
      middleName
      email
      employeeCareerData {
        id
        employeeId
        type
        currentPosition
        previousPosition
        date
        reason
      }
    }
  }
`;

export const GET_EMPLOYEE_EXIT_BY_ID = gql`
  query employeeExit($id: Int!) {
    employeeExit(id: $id) {
      id
      employeeId
      exitDate
      exitType
      gratuity
      exitReason
      isNoticePeriod
      isExitInterview
      interviewDate
      interviewNotes
      isAssetReturned
      clearanceStatus
      clearanceDate
      settlementStatus
      settlementAmount
      settlementDate
      exitDocuments
      isAccountsDisabled
      lastWorkingDay
      isManagerApproval
      isHrApproval
      isFinalApproval
      feedbackScore
      isRehireEligibility
    }
  }
`;

export const GET_EMPLOYEE_BY_ID = gql`
  query getEmployee($id: Int!) {
    getEmployee(id: $id) {
      id
      employeeCode
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      employeeApprovers {
        id
        employeeId
        type
        levelOne
        levelOneData {
          id
          firstName
          lastName
        }
        levelTwo
        levelTwoData {
          id
          firstName
          lastName
        }
        levelThree
        levelThreeData {
          id
          firstName
          lastName
        }
        levelFour
        levelFourData {
          id
          firstName
          lastName
        }
        levelFive
        levelFiveData {
          id
          employeeCode
          firstName
          lastName
          middleName
        }
        levelSix
        levelSixData {
          id
          employeeCode
          firstName
          lastName
          middleName
        }
        levelSeven
        levelSevenData {
          id
          employeeCode
          firstName
          lastName
          middleName
        }
        createdAt
        updatedAt
        deletedAt
      }
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      nativeAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      passportIssueDate
      MOLNo
      WPSNo
      SWIFTCode
      phoneStdCode
      nativeStdCode
      passportExpiryDate
      emiratesIDNo
      laborCardExpiryDate
      drivingLicenseNumber
      promotionDate
      previousDesignation
      warningLetterIssuedDate
      warningLetterReasons
      visaType
      visaNumber
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      status
      departmentId
      department {
        id
        departmentName
        status
        createdAt
        updatedAt
        deletedAt
      }
      employeeCareerData {
        id
        employeeId
        type
        currentPosition
        previousPosition
        date
        reason
      }
      designationId
      designation {
        id
        designationName
      }
      reportingManagerId
      branchId
      branch {
        id
        branchName
        address
        city
        state
      }
      vacationRequests {
        id
        employeeId
        requestDate
        startDate
        endDate
        totalDays
        vacationStatus
        status
        additionalSalary
      }
      employeeDocuments {
        id
        employeeId
        documentType
        documentNumber
        issueDate
        expiryDate
        issuingAuthority
        filePath
        isVerified
        remarks
      }
      employeeSalaries {
        id
        employeeId
        basicSalary
        hra
        allowances
        deductions
        grossSalary
        netSalary
        remarks
      }
      employeeExits {
        id
        employeeId
        exitDate
        exitType
        gratuity
        exitReason
        isNoticePeriod
        isExitInterview
        interviewDate
        interviewNotes
        isAssetReturned
        clearanceStatus
        clearanceDate
        isVehicleStatus
        isToolStatus
        settlementStatus
        settlementAmount
        settlementDate
        exitDocuments
        isAccountsDisabled
        lastWorkingDay
        isManagerApproval
        isHrApproval
        isFinalApproval
        feedbackScore
        isRehireEligibility
      }
      employeePayrolls {
        id
        payrollId
        employeeId
        payPeriodStart
        payPeriodEnd
        basicSalary
        overtimePay
        bonuses
        deductions
        netPay
        paymentDate
        paymentMethod
        payslipURL
        employeePayrollStatus
      }
      user {
        id
        email
        password
        firstName
        lastName
        status
        resetToken
        profileId
        profileData {
          id
          profileName
          description
        }
        employeeId
        branchIds
      }
    }
  }
`;

export const GET_EMPLOYEE_APPROVER = gql`
  query getEmployeeApprover($id: Int!) {
    getEmployeeApprover(id: $id) {
      id
      employeeCode
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      employeeApprovers {
        id
        employeeId
        type
        levelOne
        levelOneData {
          id
          firstName
          lastName
        }
        levelTwo
        levelTwoData {
          id
          firstName
          lastName
        }
        levelThree
        levelThreeData {
          id
          firstName
          lastName
        }
        levelFour
        levelFourData {
          id
          firstName
          lastName
        }
        levelFive
        levelFiveData {
          id
          employeeCode
          firstName
          lastName
          middleName
        }
        levelSix
        levelSixData {
          id
          employeeCode
          firstName
          lastName
          middleName
        }
        levelSeven
        levelSevenData {
          id
          employeeCode
          firstName
          lastName
          middleName
        }
        createdAt
        updatedAt
        deletedAt
      }
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      nativeAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      passportIssueDate
      passportExpiryDate
      emiratesIDNo
      laborCardExpiryDate
      drivingLicenseNumber
      promotionDate
      previousDesignation
      warningLetterIssuedDate
      warningLetterReasons
      visaType
      visaNumber
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      status
      departmentId
    }
  }
`;

export const GET_BRANCH_NAMES = gql`
  query branches($search: String) {
    branches(search: $search) {
      id
      branchName
      address
      city
      state
      country
      zipCode
      phoneNumber
      branchCode
      isHeadOffice
      email
    }
  }
`;

export const GET_EMPLOYEE_TYPES = gql`
  query getEmployeeTypes {
    getEmployeeTypes {
      employeeType
    }
  }
`;

export const GET_DESIGNATIONS = gql`
  query designations($search: String, $status: Int) {
    designations(search: $search, status: $status) {
      id
      designationName
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_DEPARTMENTS = gql`
  query departments($search: String, $status: Int) {
    departments(search: $search, status: $status) {
      id
      departmentName
      status
    }
  }
`;

export const GET_EMPLOYEE_EMAIL_EXISTENCE = gql`
  query getEmployeeByEmail($email: String!) {
    getEmployeeByEmail(email: $email)
  }
`;

export const GET_USER_ROLES = gql`
  query profiles($search: String) {
    profiles(search: $search) {
      id
      profileName
      description
      rolePermissions {
        id
        profileId
        moduleId
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_USER_ROLE_BY_ID = gql`
  query profile($id: Int!) {
    profile(id: $id) {
      id
      profileName
      description
      rolePermissions {
        moduleId
        permissionId
        status
      }
    }
  }
`;
