import { Component, HostListener, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { CustomerService } from '../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { UploadClientDetailsDialog } from '../client-details/client-details.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { PermissionDirective } from '../../shared/directives/permissions.directive';
import { Permission } from '../../shared/services/permissions';
import * as selectPermissions from '../../core/permissions/permission.selector';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-clients',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    AgGridAngular,
    ReactiveFormsModule,
    FormsModule,
    PermissionDirective
  ],
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.scss'
})
export class ClientsComponent {
  public viewPortSize: boolean = false;
  search: any;
  public rowData: any[] | null = null;
  readonly dialog = inject(MatDialog);
  searchInputControl: FormControl = new FormControl();
  columnDefs: ({ headerName: string; field: string; flex: number; cellRenderer: (params: any) => string; floatingFilter?: undefined; filter?: undefined; } | { headerName: string; field: string; flex: number; cellRenderer?: undefined; floatingFilter?: undefined; filter?: undefined; } | { field: string; headerName: string; flex: number; cellRenderer: (params: any) => HTMLDivElement; floatingFilter: boolean; filter: boolean; })[];
  // profileName: any;
  Permission = Permission;

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private toastr: ToastrService,
    private store: Store,
  ) {
    // const loggedInUser: any = localStorage.getItem('loggedInUser');
    // const userData = JSON.parse(loggedInUser);
    // this.profileName = userData?.profileData?.profileName;
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadClientDetailsDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.fetchPartners();
    });
  }

  ngOnInit() {
    this.fetchPartners();
    this.searchInputControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.search = value;
        this.fetchPartners();
      });

    this.checkViewportSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.setAGColumn();
  }


  setAGColumn() {

    this.columnDefs = [
      {
        headerName: 'Customer Name',
        field: 'name',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          return `
          <div style="display: flex; align-items: center;">
            <span>${params.data.name}</span>
          </div>
        `;
        }
      },
      { headerName: 'Company Name', field: 'company', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Email', field: 'email', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Work Phone', field: 'phone', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Receivables', field: 'receivables', flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          return `
        <div style="display: flex; align-items: center;">
          <span>AED ${params.data.receivables}</span>
        </div>
      `;
        }
      },
      {
        headerName: 'Unused Credits', field: 'credits', flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          return `
        <div style="display: flex; align-items: center;">
          <span>AED ${params.data.credits}</span>
        </div>
      `;
        }
      },
      {
        field: 'action',
        headerName: 'Actions',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.alignItems = 'center';
          container.style.gap = '20px';

          const editIcon = document.createElement('img');
          editIcon.src = 'assets/images/icons/icon-edit.svg';
          editIcon.style.cursor = 'pointer';
          editIcon.setAttribute('data-action', 'edit');

          this.store.select(selectPermissions.hasPermission('4', Permission.Edit)).subscribe((canEdit) => {
            if (canEdit) {
              container.appendChild(editIcon);
            }
          });
          // container.appendChild(editIcon);

          const deleteIcon = document.createElement('img');
          deleteIcon.src = 'assets/images/icons/icon-delete.svg';
          deleteIcon.style.cursor = 'pointer';
          deleteIcon.setAttribute('data-action', 'delete');


          // if (this.profileName === 'admin') {
          //   container.appendChild(deleteIcon);
          // }

          this.store.select(selectPermissions.hasPermission('4', Permission.Delete)).subscribe((canDelete) => {
            if (canDelete) {
              container.appendChild(deleteIcon);
            }
          });
      
          return container;
        },
        floatingFilter: false,
        filter: false,
      },
    ];
  }
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';


  onCellClicked(params: any) {
    const customerId = params.data?.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.onEdit(customerId);
      } else if (action === 'delete') {
        this.onConfirmDelete(customerId);
      }
    } else {
      this.router.navigate([`/customers/customer-details/${customerId}`]);
    }
  }

  fetchPartners() {
    this.customerService.fetchPartners(this.search).subscribe({
      next: (data: any) => {
        this.rowData = data
          .map((data: any) => {
            if (data.partnerType === 'Customer') {
              return {
                id: data?.id || '--',
                name: data?.displayName || '--',
                company: data?.companyName || '--',
                email: data?.email || '--',
                phone: data?.phone || '--',
                invoices: data?.totalInvoiceAmount || '0.00',
                receivables: data?.totalReceivableAmount || '0.00',
                credits: data?.totalCreditAmount || '0.00',
              };
            }
            return null;
          })
          .filter((item: any) => item !== null);
      },
      error: (error) => {
        this.toastr.error(error?.message);
      },
    });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.search = input.value;
    this.fetchPartners();
  }

  onConfirmDelete(id: number): void {
    console.log(id);

    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onDelete(id);
      }
    });
  }

  onDelete(id: any) {
    this.customerService.removePartner(id).subscribe({
      next: (result) => {
        if (result) {
          this.toastr.success("Customer deleted successfully", 'Success');
          this.fetchPartners();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error')
      }
    })
  }

  onEdit(id: any) {
    this.router.navigate([`/customers/customer-edit/${id}`]);
  }

}



@Component({
  selector: 'upload-clients-dialog',
  templateUrl: 'upload-clients-dialog.html',
  styleUrls: ['./clients.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadClientsDialog {

  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }

}
