import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { LeaveService } from '../../../../../../features/leave/services/leave.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params } from '@angular/router';
import { UploadFileService } from '../../../../../../shared/services/file-upload.service';
import { HolidayCalendarService } from '../../../../../../features/holiday-calendar/services/holiday-calendar.service';

@Component({
  selector: 'app-leave-apply',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    TextFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  templateUrl: './leave-apply.component.html',
  styleUrl: '../../../../employee-details.component.scss'
})

export class LeaveApplyComponent {
  leaveApplyForm: FormGroup;
  csvFile: File[] = [];
  employeeId: number;
  url: string = '';
  today: Date = new Date();
  holidays: Date[] = [];

  dateFilter = (d: Date | null): boolean => {
    if (!this.holidays.length) {
      return true; 
    }

    const selectedDate = d ? new Date(d) : new Date();
    selectedDate.setHours(0, 0, 0, 0);

    const isWeekend = selectedDate.getDay() === 0 || selectedDate.getDay() === 6; 

    const isHoliday = this.holidays.some(holiday => {
      const holidayDate = new Date(holiday);
      holidayDate.setHours(0, 0, 0, 0);
      return holidayDate.getTime() === selectedDate.getTime();
    });
    return !(isHoliday || isWeekend);
  };

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private leaveService: LeaveService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private holidayService: HolidayCalendarService,
    private documentUploadService: UploadFileService,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.employeeId = params['id'];
    })

    
  }

  ngOnInit() {
    this.initForm();
    this.fetchHolidays();
  }

  initForm() {
    this.leaveApplyForm = this.fb.group({
      employeeId: [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      leaveType: ['', Validators.required],
      employeeComment: [''],
      supportingDocumentFile: [],
      // firstSession: ['', Validators.required],
      // secondSession: ['', Validators.required],
    });
  }

  removeFile(index: any) {
    this.csvFile.splice(index, 1);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.csvFile.push(file);
    }
    this.uploadDocuments(this.csvFile);
  }

  uploadDocuments(csvFile: any) {
    console.log(csvFile);
    const file: any = [csvFile];
    csvFile.forEach((file: any) => {
      this.documentUploadService.uploadDocument(file, "leaves").subscribe({
        next: (url) => {
          this.url = url;
          file.url = url;
          console.log('file', file);

        },
        error: (error) => {
          console.log(error);
          this.toastr.error(error, 'File upload failed');
        },
      });
    });

    console.log(this.csvFile);
    
  }

  
  onViewDocument(file: any) {
    const token = localStorage.getItem('AUTH_TOKEN');
    const url = file?.url?.url;
    fetch(url, {
      method: 'GET',
      headers: {
        'authorization': token ? `Bearer ${token}` : '',
      }
    })
    .then(response => response.blob())
    .then(blob => {
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    })
    .catch(error => console.error('Error:', error));
  }
  
  fetchHolidays(){
    this.holidayService.getHolidayCalendarList().subscribe({
      next: (data) => {
        this.holidays = data.map((holiday: any) => new Date(holiday.holidayDate));
      },
      error: (error) => {
        console.error(error,'Error');
      }
    })
  }

  onApplyLeave() {
    if (this.leaveApplyForm.invalid) {
      this.leaveApplyForm.markAllAsTouched();
      return;
    }

    const startDate = this.datePipe.transform(this.leaveApplyForm.value.startDate, "yyyy-MM-dd");
    const endDate = this.datePipe.transform(this.leaveApplyForm.value.endDate, "yyyy-MM-dd");

    const isDateValid = (startDate && endDate)
      ? new Date(endDate) >= new Date(startDate)
        ? true
        : this.toastr.error('To date cannot be earlier than from date', 'Error')
      : this.toastr.error('Choose a valid date', 'Error');

    if (isDateValid === true) {
      this.leaveApplyForm.patchValue({
        startDate: startDate,
        endDate: endDate,
        employeeId: Number(this.employeeId),
      });

      const leaveData = this.leaveApplyForm.value;
      const csvFile = this.csvFile ? this.csvFile : [];
      if (leaveData) {
        this.leaveService.createLeave(leaveData, csvFile).subscribe(
          (response) => {
            if (response && !response.errors) {
              this.toastr.success('Leave applied successfully!', 'Success');
              this.leaveApplyForm.reset();
              this.csvFile = [];
            } else if (response?.errors) {
              this.toastr.error(response?.errors[0]?.message, 'Error');
            }
          },
          (error) => {
            this.toastr.error(error?.message, 'Error');
          }
        );
      }
    }

  }

  onCancel() {
    this.leaveApplyForm.reset();
  }
}
