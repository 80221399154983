<section class="cmn-innerpage-wrapper invoice-new">
  <h2 class="cmn-inner-heading">
    {{ invoiceId ? "Update Invoice" : "New Invoice" }}
  </h2>
  <form [formGroup]="invoiceForm">
    <div class="invoice-new-form-wrapper">
      <div class="form-wrapper invoice-form-wrapper">
        <div class="cmn-Search-select-container">
          <label for="clientName">Customer Name <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Search Customer" formControlName="partnerId"
              (selectionChange)="onCustomerSelected($event)">
              <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No customers found'"
                  (input)="onSearch($event)"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let partner of partnersData" [value]="partner.id">
                {{ partner.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
                  invoiceForm.get('partnerId')!.touched &&
                  invoiceForm.get('partnerId')!.hasError('required')
                ">Customer is required.</mat-error>
            </span>
          </div>
          <button *appHasPermission="['4', Permission.Create]" (click)="openCreateClientDialog()">
            <div>
              <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add client" />
            </div>
            <span>Add customer</span>
          </button>
        </div>

        <div>
          <label for="">Work order <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Select work order" formControlName="workOrderId"
              (selectionChange)="onWorkOrderSelected($event)">
              <mat-option *ngFor="let workOrder of workOrderData" [value]="workOrder.id">
                {{ workOrder.workOrderNo }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
                  invoiceForm.get('workOrderId')!.touched &&
                  invoiceForm.get('workOrderId')!.hasError('required')
                ">Work order is required.</mat-error>
            </span>
          </div>
        </div>

        <div>
          <label for="">Quotation <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Quotation" [value]="quoteCode || ''" [readonly]="true" />
          </mat-form-field>
        </div>

        <div>
          <label for="">Project Name</label>
          <mat-form-field>
            <input matInput placeholder="Project" [value]="projectName || ''" [readonly]="true" />
          </mat-form-field>
        </div>

        <div>
          <label for="">Branch Name <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Branch" [value]="branchName || ''" [readonly]="true" />
          </mat-form-field>
          <div class="custom-error">
            <span><mat-error *ngIf="
                  invoiceForm.get('branchId')!.touched &&
                  invoiceForm.get('branchId')?.hasError('branchInvalid')
                ">
                Branch is required
              </mat-error>
            </span>
          </div>
        </div>

        <div>
          <label for="paymentTerms">Payment Term</label>
          <mat-form-field>
            <input matInput placeholder="Payment Term" id="paymentTerm" formControlName="paymentTerm" />
          </mat-form-field>
        </div>

        <div>
          <label for="invoiceNumber">Invoice Number <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Invoice Number" id="invoiceNumber" formControlName="invoiceNo" readonly
              maxlength="35" />
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
                  invoiceForm.get('invoiceNo')!.touched &&
                  invoiceForm.get('invoiceNo')!.hasError('required')
                ">Invoice Number is required.</mat-error>
            </span>
          </div>
        </div>

        <div>
          <label for="orderNumber">LPO Number</label>
          <mat-form-field>
            <input matInput placeholder="LPO Number" id="orderNumber" formControlName="orderNo" maxlength="20" />
          </mat-form-field>
        </div>

        <div>
          <label for="invoiceDate">Invoice Date <span class="red">*</span></label>
          <mat-form-field>
            <input matInput [matDatepicker]="invoiceDatePicker" placeholder="Select Date" id="invoiceDate"
              formControlName="invoiceDate" />
            <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #invoiceDatePicker></mat-datepicker>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
                  invoiceForm.get('invoiceDate')!.touched &&
                  invoiceForm.get('invoiceDate')!.hasError('required')
                ">
                Invoice Date is required.
              </mat-error>
            </span>
          </div>
        </div>

        <div>
          <label for="dueDate">Due Date</label>
          <mat-form-field>
            <input matInput [matDatepicker]="dueDatePicker" placeholder="Select Date" id="dueDate"
              formControlName="dueDate" />
            <mat-datepicker-toggle matIconSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #dueDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div>
          <label for="subject">Subject</label>
          <mat-form-field>
            <input matInput placeholder="Invoice subject" id="subject" formControlName="subject" maxlength="250" />
          </mat-form-field>
        </div> <div>
          <label for="subject">Attention <span class="red">*</span></label>
          <mat-form-field>
            <input matInput placeholder="Invoice Attention" id="attention" formControlName="attention" maxlength="250" />
          </mat-form-field><div class="custom-error">
            <span>
              <mat-error *ngIf="
                  invoiceForm.get('attention')!.touched &&
                  invoiceForm.get('attention')!.hasError('required')
                ">
                Attention is required.
              </mat-error>
            </span>
          </div>
        </div>
        <div class="cmn-Search-select-container">
          <label for="bankName">Bank details <span class="red">*</span></label>
          <mat-form-field>
            <mat-select placeholder="Search Bank" formControlName="bankId">
              <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No banks found'"
                  (input)="onBankSearch($event)"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let bank of bankData" [value]="bank.id">
                {{ bank.bankName }} {{ bank.accountCode!="" ? "("+ bank.accountCode +")" : "" }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-error">
            <span>
              <mat-error *ngIf="
                  invoiceForm.get('bankId')!.touched &&
                  invoiceForm.get('bankId')!.hasError('required')
                ">Bank detail is required.</mat-error>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="billing-shipping-address-wrapper" *ngIf="selectedCustomerId">
      <div>
        <div class="header-wrapper">
          <h5>Billing Address <span class="red">*</span></h5>
          <div (click)="openSelectBillingAddressDialog()">
            <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="" />
          </div>
        </div>
        <div *ngIf="selectedBillingAddress">
          <h6>{{ selectedBillingAddress.attention }}</h6>
          <ul>
            <li>{{ selectedBillingAddress.addressLine1 }}</li>
            <!-- <li>{{ selectedBillingAddress.addressLine2 }}</li> -->
            <li>{{ selectedBillingAddress.city }}</li>
            <li>{{ selectedBillingAddress.state }}</li>
            <li>{{ selectedBillingAddress.country }}</li>
            <li>{{ selectedBillingAddress.phone }}</li>
            <li>{{ selectedBillingAddress.fax }}</li>
          </ul>
        </div>
        <button (click)="openBillingAddressDialog('add')">
          <span>Add Address</span>
        </button>
      </div>

      <div>
        <div class="header-wrapper">
          <h5>Shipping Address <span class="red">*</span></h5>
          <div (click)="openSelectShippingAddressDialog()">
            <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="" />
          </div>
        </div>
        <div *ngIf="selectedShippingAddress">
          <h6>{{ selectedShippingAddress.attention }}</h6>
          <ul>
            <li>{{ selectedShippingAddress.addressLine1 }}</li>
            <!-- <li>{{ selectedShippingAddress.addressLine2 }}</li> -->
            <li>{{ selectedShippingAddress.city }}</li>
            <li>{{ selectedShippingAddress.state }}</li>
            <li>{{ selectedShippingAddress.country }}</li>
            <li>{{ selectedShippingAddress.phone }}</li>
            <li>{{ selectedShippingAddress.fax }}</li>
          </ul>
        </div>
        <button (click)="openShippingAddressDialog('add')">
          <span>Add Address</span>
        </button>
      </div>
    </div>

    <div class="item-service-adding-table-wrapper" formArrayName="items">
      <table>
        <thead>
          <tr>
            <th>Description <span class="red">*</span></th>
            <th>Quantity <span class="red">*</span></th>
            <th>Unit <span class="red">*</span></th>
            <th>Unit Price<span class="red">*</span></th>
            <th>Gross Amount</th>
            <th>Discount</th>
            <th>Net Amount</th>
            <th>VAT(5%)</th>
            <th>Total Amount <span class="red">*</span></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
            <!-- Item description data -->
            <td>
              <div class="form-wrapper">
                <div>
                  <mat-form-field>
                    <input matInput placeholder="Select or Type Item" [matAutocomplete]="auto"
                      formControlName="itemName" (input)="onItemSearch($event)" />
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectItem($event)">
                      <mat-option *ngFor="let item of filteredItemData" [value]="item.id">
                        {{ item.itemName }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <div class="custom-error" *ngIf="item.get('itemName')?.touched">
                    <span>
                      <mat-error *ngIf="item.get('itemName')?.errors?.['required']">
                        Item is required.
                      </mat-error>
                    </span>
                  </div>
                </div>
              </div>
            </td>
            <!-- Quanitity data -->
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="quantity" formControlName="quantity" />
                </mat-form-field>
                <div class="custom-error">
                  <span>
                    <mat-error *ngIf="
                        item.get('quantity')?.touched &&
                        item.get('quantity')?.hasError('required')
                      ">Quantity is required.</mat-error>
                  </span>
                  <span>
                    <mat-error *ngIf="item.get('quantity')?.hasError('nonNegative')">Quantity must be greater than
                      0.</mat-error>
                  </span>
                </div>
              </div>
            </td>
            <!-- unit data -->
            <td>
              <div class="form-wrapper min-width-field">
                <mat-form-field>
                  <mat-select placeholder="Choose unit" formControlName="unit" maxlength="7">
                    <mat-option *ngFor="let unit of unitTypes" [value]="unit.value">
                      {{ unit.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="custom-error">
                  <span>
                    <mat-error *ngIf="
                        item.get('unit')!.touched &&
                        item.get('unit')!.hasError('required')
                      ">Unit is required.</mat-error>
                  </span>
                </div>
              </div>
            </td>
            <!-- Rate data -->
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="rate" formControlName="rate" />
                </mat-form-field>
                <div class="custom-error">
                  <span>
                    <mat-error *ngIf="
                        item.get('rate')?.touched &&
                        item.get('rate')?.hasError('required')
                      ">Rate is required.</mat-error>
                  </span>
                  <span>
                    <mat-error *ngIf="item.get('rate')?.hasError('nonNegative')">Rate cannot be negative.</mat-error>
                  </span>
                </div>
              </div>
            </td>
            <!-- Gross Amount Data -->
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput [value]="getItemGrossAmount(item.value)" readonly />
                </mat-form-field>
              </div>
            </td>
            <!-- Discount data -->
            <td>
              <div class="form-wrapper">
                <div class="split-parent">
                  <div class="left-set">
                    <mat-form-field>
                      <input matInput placeholder="0.00" formControlName="discount" type="number" />
                    </mat-form-field>
                    <div class="custom-error">
                      <span>
                        <mat-error *ngIf="item.get('discount')?.hasError('nonNegative')">Discount cannot be negative.
                        </mat-error>
                      </span>
                      <span>
                        <mat-error *ngIf="
                            item.get('discount')?.hasError('pattern') &&
                            item.get('discount')?.touched
                          ">Please enter a valid discount amount.
                        </mat-error>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <!-- Net amount data -->
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="0.00" readonly formControlName="netAmount" type="number" />
                </mat-form-field>
              </div>
            </td>
            <!-- Vat tax Data -->
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="VAT" formControlName="vat" type="number" (input)="updateItemTotal(i)" />
                </mat-form-field>
                <div class="custom-error">
                  <span>
                    <mat-error *ngIf="
                        item.get('vat')?.touched &&
                        item.get('vat')?.hasError('required')
                      ">VAT is required.</mat-error>
                  </span>
                  <span>
                    <mat-error *ngIf="item.get('vat')?.hasError('nonNegative')">VAT cannot be negative.</mat-error>
                  </span>
                </div>
              </div>
            </td>
            <!-- Total amount data -->
            <td>
              <div class="form-wrapper">
                <mat-form-field>
                  <input matInput placeholder="Total Amount" formControlName="amount" type="number" readonly />
                </mat-form-field>
                <div class="custom-error">
                  <span>
                    <mat-error *ngIf="
                        item.get('amount')?.touched &&
                        item.get('amount')?.hasError('required')
                      ">
                      Amount is required.
                    </mat-error>
                  </span>
                </div>
              </div>
              <span class="delete">
                <img src="../../../assets/images/icons/icon-close.svg" alt="Delete"
                  (click)="onRemoveNewlyAddedItems(i)" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <button type="button" (click)="addItem()">
        <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add" />
        Add
      </button>
    </div>

    <div class="quotation-table-container" *ngIf="invoiceData?.invoiceItems?.length > 0">
      <div>
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>Unit Price</th>
              <th>Gross Amount</th>
              <th>Discount</th>
              <th>Net Amount</th>
              <th>VAT(5%)</th>
              <th>Total Amount</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody *ngIf="invoiceData">
            <tr *ngFor="let item of getCalculatedItems(); let i = index">
              <td>{{ item?.itemData?.itemName }}</td>
              <td>{{ item?.quantity }}</td>
              <td>{{ item?.unit }}</td>
              <td>{{ item?.rate }}</td>
              <td>{{ item?.grossAmount }}</td>
              <td>{{ item?.discount }}</td>
              <td>{{ item?.netAmount }}</td>
              <td>{{ item?.vat }}</td>
              <td>{{ item?.netAmount + item?.vat }}</td>
              <td>
                <img src="assets/images/icons/icon-delete.svg" alt="Delete Item" style="text-align: left"
                  (click)="onRemoveInvoiceItem(i, item?.id)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="notes-tatal-amount-container">
      <div class="form-container">
        <div class="form-wrapper">
          <label for="customerNotes">Customer Notes</label>
          <mat-form-field>
            <textarea matInput placeholder="Enter customer notes" formControlName="customerNote"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="amount-table-div">
        <div>
          <h6>
            SubTotal<span>: AED {{ getSubTotalAmount() | number : "1.2-2" }}</span>
          </h6>
          <h6>
            Discount(-)<span>: AED {{ getTotalDiscount() | number : "1.2-2" }}</span>
          </h6>
          <h6>
            Total Amount<span>: AED
              {{ getSubTotalDiscountDifference() | number : "1.2-2" }}</span>
          </h6>
          <h6>
            VAT (5%)<span>: AED {{ getTotalVat() | number : "1.2-2" }}</span>
          </h6>
        </div>
        <div>
          <h4>
            Net Amount<span>: AED {{ getTotalAmount() | number : "1.2-2" }}</span>
          </h4>
        </div>
      </div>
    </div>

    <div class="documents-wrapper">
      <h2>Documents</h2>
      <div class="upload-wrapper">
        <div class="cmn-upload-box">
          <h4>Upload Documents (optional)</h4>
          <input multiple #fileInput type="file" (change)="onFileSelected($event)" style="display: none"
            accept=".pdf,.jpg,.jpeg,.png,.doc" />
          <button class="cmn-choose-file-btn" (click)="fileInput.click()" type="button">
            <img src="assets/images/icons/icon-add-color.svg" alt="Icon Add" />Choose a File
          </button>
          <div class="upload-info">
            <h5>
              You can upload a maximum of 10 files. Please upload a PNG, JPEG,
              DOC, or PDF file.
            </h5>
          </div>
        </div>
      </div>

      <div class="uploaded-file" *ngIf="fileDetailsArray.length > 0">
        <div *ngFor="let file of fileDetailsArray; let i = index">
          <h6>
            <span (click)="onViewDocument(file?.fileUrl)" style="cursor: pointer">
              {{ file.fileName }}
            </span>
            <img src="assets/images/icons/icon-close.svg" alt="Close Icon" (click)="removeFile(i)" />
          </h6>
        </div>
      </div>
    </div>

    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" type="button" (click)="onCancel()">
        Cancel
      </button>
      <button class="cmn-draft-btn" type="button" (click)="saveAsDraft()" [disabled]="loading">
        <span *ngIf="!loading">Save as Draft</span>
        <span *ngIf="loading">Loading...</span>
      </button>
      <button class="cmn-next-btn" type="button" (click)="saveAndSend()" [disabled]="loading">
        <span *ngIf="!loading">Save and Send</span>
        <span *ngIf="loading">Loading...</span>
      </button>
    </div>
  </form>
</section>