import { Component, HostListener, Inject, OnInit, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { Observable, Subject, combineLatest, debounceTime, distinctUntilChanged, map, tap } from 'rxjs';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { ToastrService } from 'ngx-toastr';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { DialogRef } from '@angular/cdk/dialog';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import * as selectPermissions from '../../core/permissions/permission.selector';
import { PettyCashService } from '../../features/petty-cash/services/petty-cash.service';
import { Permission } from '../../shared/services/permissions';
import { PermissionDirective } from '../../shared/directives/permissions.directive';

@Component({
  selector: 'app-petty-cash',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatInputModule, AgGridAngular, PermissionDirective],
  templateUrl: './petty-cash.component.html',
  styleUrl: './petty-cash.component.scss',
  providers: [Store]
})
export class PettyCashComponent implements OnInit {
  public viewPortSize: boolean = false;
  public rowData: any[] | null = null;
  public loading: boolean = true;
  public error: any = null;
  private searchSubject = new Subject<string>();
  readonly dialog = inject(MatDialog);
  userPermission: any;
  Permission = Permission;
  // permissions$: Observable<any>;
  columnDefs: any[] = [];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10];
  public themeClass: string = 'ag-theme-quartz';
  profileName: any;

  constructor(
    private router: Router,
    private pettyCashService: PettyCashService,
    private toastr: ToastrService,
    private store: Store,
    private datePipe: DatePipe,
  ) {
    const loggedInUser: any = localStorage.getItem('loggedInUser');
    const userData = JSON.parse(loggedInUser);
    this.profileName = userData?.profileData?.profileName;
  }

  ngOnInit(): void {
    this.fetchItems();
    this.searchSubject
      .subscribe((searchTerm) => {
        this.fetchItems(searchTerm);
      });

    this.checkViewportSize();
  }

  checkViewportSize() {
    if (window.innerWidth > 1200) {
      this.viewPortSize = true;
    } else {
      this.viewPortSize = false;
    }
    this.updateColumnDefs();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportSize();
  }

  updateColumnDefs(): void {
    this.columnDefs = [
      { headerName: 'Date', field: 'date', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Petty Cash No', field: 'pettyCashNo', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'Project Name',
        field: 'projectName',
        flex: this.viewPortSize ? 1 : 0
      },
      { headerName: 'Employee', field: 'projectEngineer', flex: this.viewPortSize ? 1 : 0 },
      { headerName: 'Category', field: 'category', flex: this.viewPortSize ? 1 : 0 },
      // { headerName: 'Description', field: 'description', flex: this.viewPortSize ? 1 : 0 },
      {
        headerName: 'In', field: 'credit', flex: this.viewPortSize ? 1 : 0,
        valueFormatter: (params: any) => {
          const value =
            params.value === null || params.value === 0 || params.value === ''
              ? 0
              : parseFloat(params.value);
          return value === 0 ? '' : `AED ${value.toFixed(2)}`;
        },
      },
      {
        headerName: 'Out', field: 'debit', flex: this.viewPortSize ? 1 : 0,
        valueFormatter: (params: any) => {
          const value =
            params.value === null || params.value === 0 || params.value === ''
              ? 0
              : parseFloat(params.value);
          return value === 0 ? '' : `AED ${value.toFixed(2)}`;
        },
      },
      {
        field: 'action',
        headerName: 'Action',
        flex: this.viewPortSize ? 1 : 0,
        cellRenderer: (params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.alignItems = 'center';
          container.style.gap = '20px';

          const editIcon = document.createElement('img');
          editIcon.src = 'assets/images/icons/icon-edit.svg';
          editIcon.style.cursor = 'pointer';
          editIcon.setAttribute('data-action', 'edit');
          // this.canUpdate$.subscribe(canUpdate => {
          //   if (canUpdate) {
          this.store.select(selectPermissions.hasPermission('37', Permission.Edit)).subscribe((canEdit) => {
            if (canEdit) {
              container.appendChild(editIcon);
            }
          })
          //   }
          // })

          const deleteIcon = document.createElement('img');
          deleteIcon.src = 'assets/images/icons/icon-delete.svg';
          deleteIcon.style.cursor = 'pointer';
          deleteIcon.setAttribute('data-action', 'delete');
          // this.canDelete$.subscribe(canDelete => {
          // if (this.profileName === 'admin') {
          this.store.select(selectPermissions.hasPermission('37', Permission.Delete)).subscribe((canDelete) => {
            if (canDelete) {
              container.appendChild(deleteIcon);
            }
            })

            return container;
          },
            floatingFilter: false,
            filter: false
    },
    
    ];
  };

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  fetchItems(search: string = '') {
    console.log('inside fetchItems');

    this.loading = true;
    this.pettyCashService.fetchPettyCash(search).subscribe({
      next: (items) => {
        this.rowData = items
          .map((item: any) => ({
            id: item?.id,
            projectId: item?.projectData?.id,
            employeeId: item?.employeeId,
            pettyCashNo: item?.pettyCashNumber || '--',
            category: item?.category || '--',
            projectName: item?.projectData?.projectName || 'Paid To',
            projectEngineer: item?.projectEngineerData?.firstName && item?.projectEngineerData?.lastName
              ? item.projectEngineerData.firstName + ' ' + item.projectEngineerData.lastName
              : '--',
            date: this.datePipe.transform(item?.date, 'dd/MM/YYYY') || '',
            description: item?.description || '--',
            // amount: item?.amount,
            credit: (item?.flow === 'In' ? item?.amount : ''),
            debit: (item?.flow === 'Out' ? item?.amount : '')
          }))


        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }



  openEdit(itemData: any) {
    const itemId = itemData?.id;
    if (itemId) {
      this.router.navigate([`petty-cash/edit-petty-cash/${itemId}`]);
    }
  }

  onDeleteItem(itemData: any): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.removeItem(itemData?.id);
      }
    });
  }

  private removeItem(itemId: number): void {
    this.pettyCashService.removePettyCash(itemId).subscribe({
      next: () => {
        this.toastr.success('Petty cash removed successfully.');
        this.fetchItems();
      },
      error: (error) => {
        console.log('ERROR', error);
        this.toastr.error(
          error?.message || 'Failed to petty cash. Please try again.'
        );
      },
    });
  }

  onCellClicked(params: any) {
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.openEdit(params?.data);
      } else if (action === 'delete') {
        this.onDeleteItem(params?.data);
      }
    }
    else {
      const projectId = params.data?.projectId;
      const projectName = params.data?.projectName;
      const category = params.data?.category;
      const employeeId = params.data?.employeeId;
      const dialogRef = this.dialog.open(PettyCashDialog,
        {
          data: {
            id: projectId,
            projectName: projectName,
            category: category,
            employeeId: employeeId,
          },
          disableClose: true
        }
      );
      console.log("rowData", this.rowData);
      dialogRef.afterClosed().subscribe((result) => {
        // this.fetchItems();
      });

      // this.openDialog(itemId);
      // this.router.navigate([`inventory/inventory-details/${itemId}`]);
    }
  }


  navigateToAddNew() {
    this.router.navigate(['/petty-cash/add-petty-cash']);
  }


}

@Component({
  selector: 'app-detail-petty-cash',
  templateUrl: 'petty-cash-dialog.html',
  styleUrls: ['../inventory/inventory.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class PettyCashDialog {
  loading: boolean = false;
  projectId: any;
  pettyCashDetail: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private pettyCashService: PettyCashService,
    private dialogRef: MatDialogRef<PettyCashDialog>,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    if (this.data) {
      this.projectId = this.data?.id;
      const employeeId = this.data?.employeeId;
      const category = this.data?.category;
      let id;
      if(category === 'Project'){
        id = this.projectId
      }else {
        id = employeeId
      }
      this.fetchDetails(category, id);
    }
  }

  fetchDetails(category: any, id: any) {
    this.pettyCashService.getProjectBasedPettyCashes(String(category),String(id)).subscribe({
      next: (response: any) => {
        this.pettyCashDetail = response;
        console.log('pettyCashDetail', this.pettyCashDetail);
      },
      error: (error: any) => {
        this.toastr.error(error.message, 'Error');
        this.dialogRef.close();
      },
    });
  }

  hasAttachments(): boolean {
    return this.pettyCashDetail?.pettyCashes?.some((cash: any) => cash.fileUrl && cash.fileName);
  }

  onViewDocument(url: any) {
    const token = localStorage.getItem('AUTH_TOKEN');
    fetch(url, {
      method: 'GET',
      headers: {
        'authorization': token ? `Bearer ${token}` : '',
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      })
      .catch(error => console.error('Error:', error));
  }


  onClose() {
    this.dialogRef.close();
  }

}

