// import { InMemoryCache } from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';
// import { NamedOptions } from 'apollo-angular';
// import { HttpLink } from 'apollo-angular/http';
// import { environment } from '../../../environments/environment';

// export const authLink = setContext((_, { headers }) => {
//   const token = localStorage.getItem('AUTH_TOKEN') ?? '';
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : '',
//     },
//   };
// });

// export const apolloOptions = (httpLink: HttpLink): NamedOptions => {
//   return {
//     default: {
//       cache: new InMemoryCache(),
//       link: authLink.concat(
//         httpLink.create({
//           uri: environment.url.defaultUrl,
//         })
//       ),
//       defaultOptions: { query: { fetchPolicy: 'network-only' } },
//     },
//     hrms: {
//       cache: new InMemoryCache(),
//       link: authLink.concat(
//         httpLink.create({
//           uri: environment.url.hrmsModuleUrl,
//         })
//       ),
//     },
//     accounts: {
//       cache: new InMemoryCache(),
//       link: authLink.concat(
//         httpLink.create({
//           uri: environment.url.accountsUrl,
//         })
//       ),
//     },
//     project: {
//       cache: new InMemoryCache(),
//       link: authLink.concat(
//         httpLink.create({
//           uri: environment.url.projectModuleUrl,
//         })
//       ),
//     },
//     communication: {
//       cache: new InMemoryCache(),
//       link: authLink.concat(
//         httpLink.create({
//           uri: environment.url.communicationModuleUrl,
//         })
//       ),
//     },
//   };
// };


// import { InMemoryCache } from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';
// import { HttpLink } from 'apollo-angular/http';
// import { NamedOptions } from 'apollo-angular';
// import { environment } from '../../../environments/environment';
// import { ApolloLink } from '@apollo/client';
// import { createUploadLink } from 'apollo-upload-client';

// export const authLink = setContext((_, { headers }) => {
//   const token = localStorage.getItem('AUTH_TOKEN') ?? '';
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : '',
//     },
//   };
// });

// export const apolloOptions = (httpLink: HttpLink): NamedOptions => {
//   const uploadLink = createUploadLink({
//     uri: environment.url.defaultUrl,
//   });


//   return {
//     default: {
//       cache: new InMemoryCache(),
//       link: ApolloLink.from([authLink, uploadLink]),
//       defaultOptions: { query: { fetchPolicy: 'network-only' } },
//     },
//     hrms: {
//       cache: new InMemoryCache(),
//       link: ApolloLink.from([
//         authLink,
//         createUploadLink({
//           uri: environment.url.hrmsModuleUrl,
//         }),
//       ]),
//     },
//     accounts: {
//       cache: new InMemoryCache(),
//       link: ApolloLink.from([
//         authLink,
//         createUploadLink({
//           uri: environment.url.accountsUrl,
//         }),
//       ]),
//     },
//     project: {
//       cache: new InMemoryCache(),
//       link: ApolloLink.from([
//         authLink,
//         createUploadLink({
//           uri: environment.url.projectModuleUrl,
//         }),
//       ]),
//     },
//     communication: {
//       cache: new InMemoryCache(),
//       link: ApolloLink.from([
//         authLink,
//         createUploadLink({
//           uri: environment.url.communicationModuleUrl,
//         }),
//       ]),
//     },
//   };
// };
import { InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { HttpLink } from 'apollo-angular/http';
import { NamedOptions } from 'apollo-angular';
import { environment } from '../../../environments/environment';
import { ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { Router } from '@angular/router';
import { inject } from '@angular/core';

const isTokenExpired = (token: string): boolean => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    return payload.exp < currentTime;
  } catch (error) {
    return true;
  }
};

export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('AUTH_TOKEN') ?? '';
  const loginEmployee: any = localStorage.getItem('loggedInUser');
  const employee = JSON.parse(loginEmployee);
  const branchId = employee?.employeeData?.branchId;

  if (token && isTokenExpired(token)) {
    localStorage.clear();
    sessionStorage.clear();

    const router = inject(Router);
    router.navigate(['/login']);
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      branchId: branchId
    },
  };
});

export const apolloOptions = (httpLink: HttpLink): NamedOptions => {
  const uploadLink = createUploadLink({
    uri: environment.url.defaultUrl,
  });

  return {
    default: {
      cache: new InMemoryCache(),
      link: ApolloLink.from([authLink, uploadLink]),
      defaultOptions: { query: { fetchPolicy: 'network-only' } },
    },
    hrms: {
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        authLink,
        createUploadLink({
          uri: environment.url.hrmsModuleUrl,
        }),
      ]),
    },
    accounts: {
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        authLink,
        createUploadLink({
          uri: environment.url.accountsUrl,
        }),
      ]),
    },
    project: {
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        authLink,
        createUploadLink({
          uri: environment.url.projectModuleUrl,
        }),
      ]),
    },
    communication: {
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        authLink,
        createUploadLink({
          uri: environment.url.communicationModuleUrl,
        }),
      ]),
    },
  };
};