<mat-dialog-content>
    <div class="confrim-dialog-wrapper">
      <h2 class="cmn-popup-title">Confirm Punch Out?</h2>
      <button class="cmn-close" mat-dialog-close></button>
      <p>Are you sure you want to punch out?</p>
      <div class="submit-btn-wrapper">
        <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
        <button class="cmn-next-btn" (click)="onPunchOut()">Punch Out</button>
      </div>
    </div>
  </mat-dialog-content>
  